import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Med from '../Assets/Images/med-filled.svg';
import MedWhite from '../Assets/Images/med-white.svg';
import Pat from '../Assets/Images/pat-filled.svg';
import PatWhite from '../Assets/Images/pat-white.svg';
import HomeWhite from '../Assets/Images/home-white.svg';
import HomePage from '../Assets/Images/homePageFilled.svg';
import SlotIcon from '../Assets/Images/slot-filled.svg';
import SlotWhite from '../Assets/Images/slot-white.svg';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
export default function MobileFooter() {
    const location = useLocation();

    const bottomList = [
        {
            route: APP_ROUTES.DASHBOARD,
            text: 'DASHBOARD',
            img: HomePage,
            gimg: HomeWhite
        },
        {
            route: APP_ROUTES.APPOINTMENT_TWO,
            text: 'APPOINTMENTS',
            img: Med,
            gimg: MedWhite,
        },
        {
            route: APP_ROUTES.PATIENTS_LISTING,
            text: 'PATIENTS',
            img: Pat,
            gimg: PatWhite,
        },
        {
            route: APP_ROUTES.DOCTOR_SLOT_STAGE_ONE,
            text: 'SLOTS',
            img: SlotIcon,
            gimg: SlotWhite,
        },
    ]


    return (
        <>
            {
                location.pathname.split('/')[1] == "sharecall" || location.pathname.split('/')[1] == "privacypolicy" || location.pathname.split('/')[1] == "termsofuse" ? null : <div className={`${(location.pathname === APP_ROUTES.SIDEBAR || location.pathname === '/' || location.pathname === APP_ROUTES.LOGIN || location.pathname === APP_ROUTES.FORGOT_PASSWORD) ? 'hidden' : 'lg:hidden block w-full '}`}>
                    <section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
                        <section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow-2xl">
                            <div id="tabs" className="flex justify-around mb-2">
                                {bottomList.map((data, i) => (

                                    <Link key={i} to={data.route} style={{ backgroundColor: location.pathname === data.route ? "#004171" : "#ffff" }} className="w-full content-center  focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                                        <img src={(location.pathname === data.route) ? data.gimg : data.img} className="h-6 w-6 mx-auto mb-1 " />
                                        <span className={`tab tab-home font-rubik ${location.pathname === data.route ? 'text-white ' : 'text-brand-primary '} block text-xs`} style={{ fontSize: "0.6rem" }} >{data.text}</span>
                                    </Link>
                                ))}
                            </div>
                        </section>
                    </section>
                </div >
            }
        </>

    );
}
