/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useState } from 'react'
import close from '../Assets/Images/closeee.svg';
import Dob from '../Assets/Images/calendar.svg'
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import { patientaddmedication, patientMedicationEdit } from '../Redux/Actions/UserprofileActions';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import Autocomplete from "react-autocomplete";
import userprofileservice from '../Redux/services/userprofileservice';

import {
    getmedicalhistory,
    getPatientallergylist,
    getPatientmedicationlist,
} from "../Redux/Actions/UserprofilehealthrecordAction";
function Addmedication(props) {

    const location = useLocation();
    const [addmedication, setaddmedication] = useState([])
    const history = useHistory();
    const [fromDate, setfromDate] = useState("")
    const [fromDateShow, setfromDateShow] = useState("")
    const [toDateShow, setToShow] = useState("")
    const [toDate, settoDate] = useState("")
    const [tempDrugList, setTempDrugList] = useState([]);
    const [val1, setVal1] = useState('');
    const [val, setVal] = useState();
    const dispatch = useDispatch();
    const redirectTo = (event, location) => {
        event.preventDefault();
        history.push(location)
    }
    useEffect(() => {

        if (props?.editmedication) {
            setaddmedication(props?.editmedication)
            setfromDateShow(moment(props?.editmedication?.endCreatedTime).format("MM/DD/yyyy"))
            setToShow(moment(props?.editmedication?.endCreatedTime).format("MM/DD/yyyy"))
        }
    }, []);
    const goBack = () => {
        props.closePopup()
    }

    const handleChange = (e) => {
        setaddmedication({ ...addmedication, [e.target.name]: e.target.value });
    };

    const addmedicationclick = () => {
        if (!addmedication.frequency) {
            return toast("Please Enter Frequency")
        }
        if (!addmedication.dose) {
            return toast("Please Enter dose")
        }
        if (!val) {
            return toast("Please Enter Drug Name")
        }
        if (!fromDateShow) {
            return toast("Please Enter FromDate")
        }
        if (!toDateShow) {
            return toast("Please Enter ToDate")
        }
        if (props?.editmedication) {
            let patient = localStorage.getItem("patientprofile")
            const givenDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
            const data = [{
                "patientCode": patient,
                "drugName": !addmedication.drugName ? props?.editmedication?.drugName : addmedication.drugName,
                "dosage": !addmedication.dosage ? props?.editmedication?.dosage : addmedication.dosage,
                "startCreatedTime": !fromDate ? props?.editmedication?.startCreatedTime : fromDate,
                "endCreatedTime": !toDate ? props?.editmedication?.endCreatedTime : toDate,

                "frequency": !addmedication.frequency ? props?.editmedication?.frequency : addmedication.frequency,
                "dose": !addmedication.dose ? props?.editmedication?.dose : addmedication.dose,
                "adviceRelatedMedicine": !addmedication.adviceRelatedMedicine ? props?.editmedication?.adviceRelatedMedicine : addmedication.adviceRelatedMedicine,
                "givenDate": givenDate,
                "status": 1,
                "id": props?.editmedication?.id,
                "createdBy": patient,
                "modifiedBy": patient,
                "enteredBy": "P",
                // "strength": addmedication.strength,
                "recordFor": addmedication.recordFor,
                //-- -> P means patient and U means User
            }]
            dispatch(patientMedicationEdit(data)).then((result) => {
                dispatch(getPatientmedicationlist(patient));
                props.closePopup()
            })
        } else {
            let patient = localStorage.getItem("patientprofile")
            const givenDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
            const data = [{
                "patientCode": patient,
                "drugName": val,
                "dosage": addmedication.dosage,
                "startCreatedTime": fromDate,
                "endCreatedTime": toDate,
                "givenDate": givenDate,
                "status": 1,
                "createdBy": patient,
                "modifiedBy": patient,
                "enteredBy": "P",
                "frequency": addmedication.frequency,
                "dose": addmedication.dose,
                "adviceRelatedMedicine": addmedication.adviceRelatedMedicine,

                // "strength": addmedication.strength,
                "recordFor": addmedication.recordFor,
                //-- -> P means patient and U means User
                "indication": addmedication.indication,
                "sideEffects": addmedication.sideEffects,
                "compliance": addmedication.compliance,
                "adverseEffects": addmedication.adverseEffects
            }]
            dispatch(patientaddmedication(data)).then((result) => {
                dispatch(getPatientmedicationlist(patient));
                props.closePopup()
            })
        }

    }
    const changeDate = (e) => {
        setfromDate(moment(e).format("YYYY-MM-DD hh:mm:ss"))
        setfromDateShow(moment(e).format("MM/DD/yyyy"))
    }
    const changeToDate = (e) => {
        settoDate(moment(e).format("YYYY-MM-DD hh:mm:ss"))
        setToShow(moment(e).format("MM/DD/yyyy"))

    }
    const onSetVal = (val, e) => {
        if (e.length >= 3) {
            let payload = {};
            payload.drugName = e.toLowerCase();
            payload.userDefined = "N";
            userprofileservice.MedicineDrugListSearch(payload).then(
                (res) => {
                    setTempDrugList(res.data);
                }
            );
        }
        setVal();
        setVal1(e);
    };
    const renderMedicine = (state, val) => {
        return state.drugname.toLowerCase().indexOf(val.toLowerCase()) !== -1;
    };
    const onChangeHandlerD = (e) => {
        setVal(e.drugname);
    }
    return (

        <>
            <ToastContainer />

            <div
                className="justify-center items-center  mt-6 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full sm:w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 sm:left-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-5 mb-4">

                        <div class="flex justify-between mb-2 mt-5">
                            <p class="text-medium font-medium text-2xl font-rubik text-brand-secondary ">{props?.editmedication === '' ? `Add ` : 'Edit '}Medications</p>
                            <div className="flex space-x-6 cursor-pointer">
                                <img src={close} alt="close" class="w-4" onClick={goBack} />
                            </div>
                        </div>

                        <hr classname="border-dash text-black w-10 mt-4 h-20 my-2"></hr>
                        <div>
                            <div >
                                {/* <img src={select} alt="select" class="w-4 ml-3 " /> */}
                                <p class="text-black-900 font-medium text-lg mt-4">Personal Details</p>
                            </div>
                            <div class="grid grid-cols-3 gap-5">
                                {/*<div class="relative">
                                            <div className="flex">
                                                <div class="flex pr-2">
                                                    <p class="text-medium font-medium text-2xl font-rubik text-brand-secondary">
                                                        {props.title}
                                                    </p>
                                                    <div class="h-10 w-40 border border-gray-200 p-2 ml-0  rounded-lg flex space-x-6">
                                                        <select
                                                            id="recordFor" name="recordFor"
                                                            className="w-full   outline-none"
                                                            value={FamilymemberName}
                                                            onChange={(e) => handleChangeFamilymem(e.target.value)}
                                                        >
                                                            <option className="py-1 text-sm text-green-600" value={"self"}>
                                                                Self
                                                            </option>

                                                            {memberList.length === 0 ? (
                                                                <p className="text-center item-center mt-20 mb-20  ">
                                                                    No members
                                                                </p>
                                                            ) : (
                                                                <>
                                                                    {memberList.map((res, i) => (
                                                                        <option
                                                                            className="py-1 text-sm text-green-600"
                                                                            value={res.code}
                                                                        >
                                                                            {res.name}
                                                                        </option>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                            <label for="recordFor" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Record For</label>
                                        </div>*/}
                                {/* <div class="relative lg:-px-5">
                                            <div className="flex">
                                                <input autocomplete="off" id="email" name="email" type="text" class="peer lg:w-full w-38 h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="DD/MM/YYYY" />
                                                <img src={Dob} alt="my photo" className="relative right-5" ></img>
                                            </div>
                                            <label for="email" class="absolute lg:left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Recorded On</label>
                                        </div> */}
                                {/* <div class="relative">
                                    <label for="drugName" class=" text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Drug Name</label>
                                    <div className="flex ">
                                        <input autocomplete="off" id="drugName" name="drugName" type="text" class="peer w-32 lg:w-full md:w-40 lg:text-md text-sm h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Drug Name"
                                            onChange={handleChange} value={addmedication.drugName} />
                                    </div>
                                </div> */}
                                <div class="relative">
                                    {/* <label for="drugName" class=" text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Drug Name</label>
                                            <div className="flex ">
                                                <input autocomplete="off" id="drugName" name="drugName" type="text" class="peer w-32 lg:w-full md:w-40 lg:text-md text-sm h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Drug Name"
                                                    onChange={handleChange} value={addmedication.drugName} />
                                            </div> */}
                                    <label for="drugName" class=" text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Drug Name</label>
                                    <div className="mt-2 pb-2 border-b-2 border-gray-300 focus:outline-none focus:borer-rose-600">

                                        <Autocomplete
                                            inputProps={{ placeholder: 'Select Drug', id: "autoComplete-" }}
                                            value={val ? val : val1}
                                            items={tempDrugList}
                                            id={"autoCompleteed-"}
                                            getItemValue={(item) => item.drugname}
                                            shouldItemRender={renderMedicine}
                                            menuStyle={{ width: "400" }}
                                            renderMenu={(item) => (
                                                <div
                                                    key={item}
                                                    className={
                                                        tempDrugList.length > 0
                                                            ? "dropdown flex flex-col h-40 overflow-auto"
                                                            : "dropdown flex flex-col overflow-auto"
                                                    }
                                                >
                                                    {item}
                                                </div>
                                            )}
                                            renderItem={(item, isHighlighted) => (
                                                <div
                                                    className={`item ${isHighlighted ? "selected-item" : ""
                                                        }`}
                                                >
                                                    {item.drugname}
                                                </div>
                                            )}
                                            onChange={(event, val) => {
                                                onSetVal(event, val);
                                            }}
                                            onSelect={(event, obj) => onChangeHandlerD(obj)}

                                        />
                                    </div>
                                </div>
                                <div class="relative">
                                    <label for="dosage" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Dosage</label>
                                    <div className="flex ">
                                        <input autocomplete="off" id="dosage" name="dosage" type="text" class="peer w-32 lg:w-full md:w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Dosage"
                                            onChange={handleChange} value={addmedication.dosage} />
                                    </div>
                                </div>
                                <div class="relative">
                                    <label for="frequency" class=" text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Frequency</label>

                                    <div className="flex ">
                                        <input autocomplete="off" id="frequency" name="frequency" type="text" class="peer w-32 lg:w-full md:w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Frequency"
                                            onChange={handleChange} value={addmedication.frequency} />
                                    </div>

                                </div>
                                <div class="relative">
                                    <label for="dose" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Duration</label>

                                    <div className="flex ">
                                        <input autocomplete="off" id="dose" name="dose" type="text" class="peer w-32 lg:w-full md:w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Duration"
                                            onChange={handleChange} value={addmedication.dose} />
                                    </div>

                                </div>

                                <div class="relative">
                                    <label for="indication" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Indication</label>
                                    <div className="flex ">
                                        <input autocomplete="off" id="indication" name="indication" type="text" class="peer w-32 lg:w-full md:w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Indication"
                                            onChange={handleChange} value={addmedication.indication} />
                                    </div>
                                </div>
                                <div class="relative">
                                    <label for="sideEffects" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Side effects</label>
                                    <div className="flex ">
                                        <input autocomplete="off" id="sideEffects" name="sideEffects" type="text" class="peer w-32 lg:w-full md:w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Side effects"
                                            onChange={handleChange} value={addmedication.sideEffects} />
                                    </div>
                                </div>
                                <div class="relative">
                                    <label for="compliance" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Compliance</label>
                                    <div className="flex ">
                                        <input autocomplete="off" id="compliance" name="compliance" type="text" class="peer w-32 lg:w-full md:w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Compliance"
                                            onChange={handleChange} value={addmedication.compliance} />
                                    </div>
                                </div>
                                <div class="relative">
                                    <label for="adverseEffects" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Adverse effects</label>
                                    <div className="flex ">
                                        <input autocomplete="off" id="adverseEffects" name="adverseEffects" type="text" class="peer w-32 lg:w-full md:w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Adverse effects"
                                            onChange={handleChange} value={addmedication.adverseEffects} />
                                    </div>
                                </div>
                                {/* <div class="relative lg:px-2">

                                            <div className="flex">
                                                <input autocomplete="off" id="strength" name="strength" type="text" class="peer lg:w-full w-40 h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="Enter strength"
                                                    onChange={handleChange} value={addmedication.strength} />
                                            </div>
                                            <label for="strength" class="absolute lg:left-2 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Strength</label>
                                        </div> */}
                                {/* <div>
                                        <p class="text-black-900 font-medium text-lg mt-2">Are you Taking any Medications?</p>
                                    </div>
                                    <div className=" flex space-x-8">
                                        <div className=" flex space-x-4 items-center py-2">
                                            <input type="radio" class="form-radio mt-1 mr-2" name="accountType" value="personal" />
                                            <div className="text-sm font-medium text-gray-500 font-montserrat">Yes</div>
                                        </div>
                                        <div className=" flex space-x-4 items-center py-2">
                                            <input type="radio" class="form-radio mt-1 mr-2" name="accountType" value="personal" />
                                            <div className="text-sm font-medium text-gray-500 font-montserrat">No</div>
                                        </div>
                                    </div> */}

                                <div class="relative">
                                    <label for="dateOfBirth" class=" text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">From Date</label>
                                    {/* <div className="flex "> */}
                                    <DatePicker
                                        id="fromDate"
                                        name="fromDate"
                                        dropdownMode="select"
                                        showMonthDropdown
                                        showYearDropdown
                                        maxDate={new Date()}
                                        // className="pt-2 text-gray-900 "
                                        dateFormat="dd/MM/yyyy"
                                        value={fromDateShow ? moment(fromDateShow).format("DD/MM/yyyy") : ""}
                                        onSelect={changeDate}
                                        disabledKeyboardNavigation={true}
                                        autoFocus={false}
                                        placeholderText="From Date"
                                        className={
                                            "peer  h-10 w-32 md:w-40 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        }
                                    />
                                    {/* </div> */}
                                </div>
                                {/* <div class="flex justify-between ml-2 py-4 space-x-10"> */}
                                <div class="relative ">
                                    <label for="dateOfBirth" class=" text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">To Date</label>

                                    <DatePicker
                                        id="toDate"
                                        name="toDate"
                                        dropdownMode="select"
                                        showMonthDropdown
                                        showYearDropdown
                                        // maxDate={new Date()}
                                        // className="pt-2 text-gray-900 "
                                        dateFormat="dd/MM/yyyy"
                                        value={toDateShow ? moment(toDateShow).format("DD/MM/yyyy") : ""}
                                        onSelect={changeToDate}
                                        disabledKeyboardNavigation={true}
                                        autoFocus={false}
                                        placeholderText="To Date"
                                        className={
                                            "peer  h-10 w-32 md:w-40  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        }
                                    />
                                </div>

                                {/* </div> */}

                                <div class="relative">
                                    <label for="adviceRelatedMedicine" class=" text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Advice related to medicines by the doctor.</label>

                                    <div className="flex ">
                                        <input autocomplete="off" id="adviceRelatedMedicine" name="adviceRelatedMedicine" type="text" class="peer w-32 lg:w-full md:w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Advice related to medicines"
                                            onChange={handleChange} value={addmedication.adviceRelatedMedicine} />
                                    </div>

                                </div>
                            </div>

                            {/* <div>
                                        <p class="text-black-900 font-medium text-lg mt-2 font-rubik">Are you Taking any Medications?</p>
                                    </div>
                                    <div class="flex space-x-8 py-2">
                                        <div>
                                            <label class="inline-flex items-center">
                                                <input type="checkbox" class="form-checkbox" />
                                                <span class="ml-2">Morning</span>
                                            </label>
                                        </div>
                                        <div>
                                            <label class="inline-flex items-center">
                                                <input type="checkbox" class="form-checkbox" />
                                                <span class="ml-2">Noon</span>
                                            </label>
                                        </div>

                                        <div>
                                            <label class="inline-flex items-center">
                                                <input type="checkbox" class="form-checkbox" />
                                                <span class="ml-2">Night</span>
                                            </label>
                                        </div>

                                    </div> */}

                            {/* 
                                    <div >

                                        <p class="text-black-900 font-medium text-lg mt-2 font-rubik">Are you Taking any Medications?</p>
                                    </div> */}


                            {/* <div class="flex space-x-8 py-2">

                                        <div>
                                            <label class="inline-flex items-center">
                                                <input type="checkbox" class="form-checkbox" />
                                                <span class="ml-2">Before Meal</span>
                                            </label>
                                        </div>


                                        <div>
                                            <label class="inline-flex items-center">
                                                <input type="checkbox" class="form-checkbox" />
                                                <span class="ml-2">After Meal</span>
                                            </label>
                                        </div>
                                    </div> */}
                            <div className="flex justify-end mt-2">
                                <button
                                    onClick={addmedicationclick}
                                    className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2">Save Data </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>








    )
}
export default Addmedication;
