import profile from '../Assets/Images/profilee.svg';
import camera from '../Assets/Images/camera.svg';
import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { getparticulardoctordetails } from '../Redux/Actions/doctAction';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import { Link, useHistory, } from 'react-router-dom';
import { Logout } from '../Redux/Actions/userActions';
import transitions from '@material-ui/core/styles/transitions';


function Sidebar() {

    const history = useHistory();
    const dispatch = useDispatch();
    const particulardoct = useSelector((state) => state.particulardoctor);
    const { particulardoctorData } = particulardoct;

    const loggedInData = useSelector((state) => state.authReducer);

    const { patientData, patientSession, isOpen, index, userData } = loggedInData;



    const [dropdown, setdropdown] = useState(false);
    const [dropdownCB, setdropdownCB] = useState(false)
    const redirectTo = (event, location) => {
        event.preventDefault();
        history.push(location)
    }


    const logout = (e) => {
        localStorage.clear();
        let dataObj = {
            sessionId: patientSession?.id,
            userCode: patientSession?.patientCode
        }
        dispatch(Logout(dataObj));
        history.push(APP_ROUTES.Login)
    };



    const showdropdown = (event) => {
        event.preventDefault();
        setdropdown(!dropdown)
        if(!dropdown){
            setdropdownCB(false)
        }
    }
    const showdropdownCurebay = (event) => {
        event.preventDefault();
        setdropdownCB(true);
    }


    useEffect(() => {
        console.log("admin", loggedInData)

        const conditions = ['/referedto','/referedfrom','ReferClinicList','/ReferDoctorList','/ReferPatientList','/ReferToAdd','/ReferPatient','/ReferClinic','/ReferDoctor']
        const conditions1 = ['ReferClinicList','/ReferDoctorList','/ReferPatientList','/ReferPatient','/ReferClinic','/ReferDoctor']
        //alert(JSON.stringify(history.location.pathname))
        // console.log(conditions.some(el) , )
        console.log("sckjsdbkjbdk")
        setdropdown(false);
        if(conditions.some(el => history.location.pathname.includes(el)))
        {
            setdropdown(true)
        }
        if(conditions1.some(el => history.location.pathname.includes(el)))
        {
            setdropdown(true)
            setdropdownCB(true)
        }

        

        console.log(particulardoctorData);
        dispatch(getparticulardoctordetails(userData.code))
    }, [history.location.pathname]);




    return (
        <>
            <div className="w-full md:w-72 lg:w-full lg:mt-4 md:p-2 ">
                <div className="sidebarr lg:rounded-lg  bg-white w-full px-2  p-3 mb-2 antialiased border border-gray-200 h-auto" >
                    <div onClick={(e) => redirectTo(e, APP_ROUTES.DOCTOR_PROFILE)} className="flex justify-between content-center cursor-pointer">
                        <p  className={`${history.location.pathname==APP_ROUTES.DOCTOR_PROFILE?'text-green-500  text-medium font-medium text-sm font-rubik my-2':'text-brand-secondary text-medium font-medium text-sm font-rubik my-2'}`} >My Profile</p>
                        <svg
                            className="mt-2 h-5 w-auto text-brand-secondary"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>

                    </div>
                    <hr />
                    {/* <div className="flex justify-between content-center ">
                        <p onClick={(e) => redirectTo(e, APP_ROUTES.DOCTOR_PRESCRIPTION)} className='text-brand-lightgreen text-medium font-medium text-sm font-rubik my-2'>Prescriptions</p>
                        <p onClick={(e) => redirectTo(e, APP_ROUTES.DOCTOR_PRESCRIPTION)} className='text-brand-secondary text-medium font-medium text-sm font-rubik my-2'>Prescriptions</p>
                        <svg
                            className="mt-2 h-5 w-auto text-brand-secondary"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>

                    </div> */}
                    <hr />

                    <div onClick={(e) => redirectTo(e, APP_ROUTES.CHANGE_PSWD)} className="flex justify-between content-center cursor-pointer">
                        <p  className={`${history.location.pathname==APP_ROUTES.CHANGE_PSWD?'text-green-500  text-medium font-medium text-sm font-rubik my-2':'text-brand-secondary text-medium font-medium text-sm font-rubik my-2'}`}>Change Password</p>

                        <svg
                            className="mt-2 h-5 w-auto text-brand-secondary"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>



                    </div>
                    <hr />

                    <div onClick={(e) => redirectTo(e, APP_ROUTES.DOCTOR_BILLING)} className="flex justify-between content-center cursor-pointer">
                        <p  className={`${history.location.pathname==APP_ROUTES.DOCTOR_BILLING?'text-green-500  text-medium font-medium text-sm font-rubik my-2':'text-brand-secondary text-medium font-medium text-sm font-rubik my-2'}`}>Billing History</p>

                        <svg
                            className="mt-2 h-5 w-auto text-brand-secondary"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>



                    </div>
                    <hr />

                    <div onClick={(e) => redirectTo(e, APP_ROUTES.DOCTOR_CONSULTATION)} className="flex justify-between content-center cursor-pointer">
                        <p  className={`${history.location.pathname==APP_ROUTES.DOCTOR_CONSULTATION?'text-green-500  text-medium font-medium text-sm font-rubik my-2':'text-brand-secondary text-medium font-medium text-sm font-rubik my-2'}`}>Consultations</p>

                        <svg
                            className="mt-2 h-5 w-auto text-brand-secondary"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>

                    </div>
                    <hr />


                    <div onClick={showdropdown} className="flex justify-between content-center submenu cursor-pointer">
                        <p  className='text-brand-secondary text-medium font-medium text-sm font-rubik my-2 submenu'>Referrals</p>
                        <svg
                            className="mt-2 h-5 w-auto text-brand-secondary submenu"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>

                    </div>
                    <hr />

                    {
                        dropdown &&
                        <div className="relative pl-8">
                            <div x-show="dropdownOpen" className="">
                                <p onClick={(e) => redirectTo(e, APP_ROUTES.REFEREDTO)} className={`${(history.location.pathname==APP_ROUTES.REFEREDTO || history.location.pathname == APP_ROUTES.REFERTOADD)?'text-green-500 text-black-400 font-montserrat text-medium font-medium text-xs my-2 cursor-pointer':'text-black-400 font-montserrat text-medium font-medium text-xs my-2 cursor-pointer'}`}>Referred to</p>
                               
                                <p onClick={(e) => redirectTo(e, APP_ROUTES.REFEREDFROM)} className={`${history.location.pathname==APP_ROUTES.REFEREDFROM?'text-green-500 text-black-400 font-montserrat text-medium font-medium text-xs my-2 cursor-pointer':'text-black-400 font-montserrat text-medium font-medium text-xs my-2 cursor-pointer'}`}>Referred From</p>
                                <p onClick={showdropdownCurebay} className='submenu text-black-400 font-montserrat text-medium font-medium text-xs my-2 cursor-pointer'>Refer to Curebay</p>
                                {dropdownCB &&
                                    <div x-show="dropdownOpen" className="ml-6">
                                        <p onClick={(e) => redirectTo(e, APP_ROUTES.REFERCLINICLIST)} className={`${history.location.pathname==(APP_ROUTES.REFERCLINICLIST ) ||history.location.pathname== "/ReferClinic"?'text-green-500 text-black-400 font-montserrat text-medium font-medium text-xs my-2 cursor-pointer':'text-black-400 font-montserrat text-medium font-medium text-xs my-2 cursor-pointer'}`}>Refer Hospital/Clinic</p>
                                        <p onClick={(e) => redirectTo(e, APP_ROUTES.REFERDOCTORLIST)} className={`${history.location.pathname==(APP_ROUTES.REFERDOCTORLIST )||history.location.pathname== "/ReferDoctor"?'text-green-500 text-black-400 font-montserrat text-medium font-medium text-xs my-2 cursor-pointer':'text-black-400 font-montserrat text-medium font-medium text-xs my-2 cursor-pointer'}`}>Refer Doctor</p>
                                        <p onClick={(e) => redirectTo(e, APP_ROUTES.REFERPATIENTLIST)} className={`${history.location.pathname==(APP_ROUTES.REFERPATIENTLIST )||history.location.pathname== "/ReferPatient"?'text-green-500 text-black-400 font-montserrat text-medium font-medium text-xs my-2 cursor-pointer':'text-black-400 font-montserrat text-medium font-medium text-xs my-2 cursor-pointer'}`}>Refer Patient</p>
                                    </div>
                                }

                            </div>
                        </div>

                    }
                    <div onClick={(e) => redirectTo(e, APP_ROUTES.MAP_HOSPITAL)} className="flex justify-between content-center cursor-pointer">
                        <p  className={`${history.location.pathname==APP_ROUTES.MAP_HOSPITAL?'text-green-500  text-medium font-medium text-sm font-rubik my-2':'text-brand-secondary text-medium font-medium text-sm font-rubik my-2 cursor-pointer'}`} >Map Hospital</p>
                        <svg
                            className="mt-2 h-5 w-auto text-brand-secondary"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>

                    </div>
                    <hr />
                    <div  onClick={(e) => redirectTo(e, APP_ROUTES.MAP_SPECIALITY)} className="flex justify-between content-center cursor-pointer">
                        <p  className={`${history.location.pathname==APP_ROUTES.MAP_SPECIALITY?'text-green-500  text-medium font-medium text-sm font-rubik my-2 cursor-pointer':'text-brand-secondary text-medium font-medium text-sm font-rubik my-2 cursor-pointer'}`} >Map Speciality</p>
                        <svg
                            className="mt-2 h-5 w-auto text-brand-secondary"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>

                    </div>

                    {
                        userData.id &&
                        <>
                            <hr />
                            <div onClick={() => logout()} className="flex justify-between content-center cursor-pointer">
                                <p  className='text-red-600 text-medium font-medium text-sm font-rubik my-2'>Logout </p>

                            </div>
                        </>
                    }


                    {/* <div className="flex justify-between content-center ">
                            <p className='text-red-600 text-medium font-medium text-sm font-rubik my-2'>Logout </p>

                        </div> */}

                </div>
            </div>


        </>
    );
}
export default Sidebar;