import React, { useEffect, useState } from 'react';
import search from '../Assets/Images/doctsearch.svg';
import ProfileSidebar from './profileSidebar';
import ProfileNamebar from './profileNamebar';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingdetails } from '../Redux/Actions/doctAction';
import moment from 'moment';
import MobileBillinghistory from './mobileBillingHistory'
import { CircularProgress } from '@material-ui/core'
import FilterBilling from './FilterBilling';
import { format } from 'date-fns';

function DoctorBillinghistory() {
  const dispatch = useDispatch();
  const loggedInData = useSelector((state) => state.authReducer);
  const { billingList, isLoading } = useSelector((state) => state.doctorconsultationlist);
  const [list, setList] = useState([]);
  console.log("billingList", billingList)
  const { userData } = loggedInData;
  const [showFilterView, setShowFilterBilling] = useState(false)
  
  useEffect(() => {const today = new Date();
    const fromDate = format(today, 'MM/dd/yyyy'); // Correct format for 'MM/DD/YYYY'
    const toDate = format(new Date(today.setDate(today.getDate() - 7)), 'MM/dd/yyyy'); // Correct format for 7 days before today

    const data = {
      userId: userData.code,
      fromDate: toDate,
      toDate: fromDate
    }
    dispatch(getBillingdetails(data)).then((res) => {
      // const data = res.filter(val => val.userId != "")
      // setList(data)
      console.log("lol", res)
      setList(res)
    });
  }, [])


  const onSearch = (text) => {
    let val = text ? text : '';
    // @ts-ignore
    var newArr = billingList
    console.log("as", newArr)
    // @ts-ignore
    let newArray = newArr.filter((data) => JSON.stringify(data).toLowerCase().indexOf(val.toLowerCase()) !== -1);
    setList(newArray)
  }




  // useEffect(() => {
  //   setList(billingList)
  // }, [billingList.length, billingList])
  const openFilterBilling = () => {
    setShowFilterBilling(true);
  }

  const loadFilterBilling = (data) => {
    dispatch(getBillingdetails(data)).then((res) => {
      const data = res.filter(val => val.appointmentId != null)
      setList(res)
    });
  }
  return (
    <>
      {/* 1st row */}

      <div className="lg:block hidden">
        <ProfileNamebar></ProfileNamebar>
      </div>

      {/* 2nd row */}
      <div className="lg:flex hidden justify-between ">
        <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">

          <ProfileSidebar></ProfileSidebar>
        </div>
        {/* table start */}

        <div className="w-11/12 lg:ml-10 ml-4 mt-3 border h-screen overflow-scroll boder-gray-200 rounded-lg bg-white mr-8">

          {/* <div className="flex space-x-3 pt-5 pl-5 pb-5"> */}
          {/* <img src={search} alt="search" className="w-4" />
            <input type="text" onChange={(e) => { onSearch(e.target.value) }} className="text-sm text-brand-secondary font-medium" placeholder="Search or Filter" /> */}

          <div className="flex p-3 justify-end">
            {/* <div className="theme-color menuitem cursor-pointer" onClick={openAddMapHospital}>
                <div className="font-14"><span className="icon-new font-14 cursor-pointer">New</span></div>
              </div>
              <div className="theme-color menuitem pl-5" onClick={openEditMapHospital}>
                <div className="font-14"><span className="icon-view font-14">View</span></div>
              </div> */}
            <div className="theme-color menuitem pl-5" onClick={openFilterBilling}>
              <div className="font-18 ml-5"><span className="icon-filter font-14">Filter</span></div>
            </div>
          </div>
          {/* </div> */}

          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-0">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-0">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y">
                    <thead >
                      <tr>
                        <th scope="col" className="px-8 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          {/* <input id="radio2" type="radio" name="radio" className="hidden" />
                          <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 flex-no-shrink"></span> */}
                          Patient Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Transaction Id
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Type
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Location
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Billed Amount
                        </th>

                      </tr>
                    </thead>
                    {isLoading && list.length === 0 &&
                      <div className="flex justify-center items-center relative left-80" style={{ height: "50vh" }}>
                        <CircularProgress />
                      </div>}
                    {list.filter((x) => x.appointmentId != null).length === 0 && !isLoading ? <span>No billing Details found</span> :
                      <tbody className="bg-white divide-y divide-gray-200">
                        {list.filter((x) => x.appointmentId != null).map((res, i) => (
                          <tr>
                            <td className="px-8 py-4 flex ">

                              {/* <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 mt-1"></span> */}
                              <p className="text-sm font-medium text-gray-600 font-montserrat">{res.patientName}</p>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <p className="text-sm font-medium text-gray-600 font-montserrat">{res.txnId}</p>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <p className="text-sm font-medium text-gray-600 font-montserrat">{res.serviceName}</p>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              <p className="text-sm font-medium text-gray-600 font-montserrat">{res.locationName}</p>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <p className="text-sm font-medium text-gray-600 font-montserrat">{moment(res.serviceTakenDateTime).format("DD/MM/yyyy")}</p>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <p className="text-sm font-medium text-gray-600 font-montserrat">{res.totalAmount}</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showFilterView && (
        <FilterBilling
          closePopup={() => {
            setShowFilterBilling(false);
          }}
          filterData={loadFilterBilling}
          onClose={() => {
            setShowFilterBilling(false);
          }}
        >
        </FilterBilling>
      )}
      <div className="lg:hidden block">
        <MobileBillinghistory />
      </div>
    </>
  )
}
export default DoctorBillinghistory;
