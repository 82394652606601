import React, { useState, useEffect } from 'react'
import curebay from '../Assets/Images/Onlylogo.svg';
import vijay from "../Assets/Images/vijy.svg";
import search from '../Assets/Images/doctsearch.svg';
import share from '../Assets/Images/share-21.svg';
import download from '../Assets/Images/arrow.downloaded.svg';
import more from '../Assets/Images/More .svg';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientdetails, getPatientDocuments, getPatientPHRReportss, getPatientReports } from '../Redux/Actions/patientAction';
import BloodPresure from './BloodPresure';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { viewImage } from '../helper/downloadImage';
import { IMG_URL } from '../config/constant';
import FilterDocument from './FilterDocument';
import moment from 'moment';

function PatientDetails() {


  const params = useParams()
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  const patientdetailslist = useSelector((state) => state.patientdetails);
  const { patientdetailsData } = patientdetailslist;
  const patientreportlist = useSelector((state) => state.patientreport);
  const { patientreportData } = patientreportlist;

  const patientphrreportlist = useSelector((state) => state.patientphr);
  const { patientPHRData } = patientphrreportlist;


  const [percentage, setpercentage] = useState(1);
  const [data, setData] = useState([])
  const [showFilter, setshowfilter] = useState(false);

  useEffect(() => {
    console.log('Patient Detail : ' + JSON.stringify(state));
    dispatch(getPatientdetails(params.patid))
    console.log(patientreportData)
    dispatch(getPatientReports())
    dispatch(getPatientPHRReportss(params.patid))
    console.log(patientdetailsData);
  }, []);

  const filter = (event) => {
    event.preventDefault();
    setshowfilter(true);
  };

  function getAge(dateString) 
{
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age--;
    }
    return age;
}

  const loadDocuments = (data, fromDate, toDate) => {
    if(data)  {
      let payload = {};
      payload.patientCode = params.patid;
      payload.documentRequired = 'Y';
      if(data && data.reportType) {
        payload.reportType = data.reportType;
      }
      if(fromDate) {
        payload.fromDate = moment(fromDate).format("MM/DD/yyyy");
      }
      if(toDate) {
        payload.toDate = moment(toDate).format("MM/DD/yyyy");
      }
      dispatch(getPatientDocuments(payload));
    } else {
      let payload = {
        patientCode: params.patid,
        documentRequired: 'Y'
      }
      dispatch(getPatientDocuments(payload));
    }
    console.log(data);
    setshowfilter(false);
  };


  return (
    <>


      <div>
        <div className="w-full p-5 lg:max-w-full lg:flex lg:pt-6">
          <div className="lg:mx-10 w-full border border-gray-100 bg-white rounded-xl -none p-2 lg:flex flex-col justify-between leading-normal">
            <div className="lg:flex justify-between">
              <div className="flex">
                <div>
                  <img src={ state.patientPhotoName ? IMG_URL + state.patientPhotoName : vijay} alt="profile image" className="w-10 rounded-full h-10 mb-2" />
                </div>

                <div className="mb-4">
                  <div className="pl-3" >
                    <p className="text-sm text-gray-700 font-medium">{state.patientName} </p>
                    <p className="text-xs font-medium text-gray-700">Blood Group :<span className="text-xs pl-1 font-normal text-gray-500">{state.patientBloodGroup}</span></p>
                    {/* <p className="text-xs font-medium text-gray-700">Symptoms:<span className="text-xs pl-1 font-normal text-gray-500">Cough and cold for the past 5 days</span></p> */}
                  </div>
                </div>
              </div>

              <div className="ml-12 mr-4">
                <p className="text-xs font-medium text-gray-700">Gender :<span className="text-xs pl-1 font-normal text-gray-500">{state.patientgender == 'M' ? 'Male' : state.patientgender == 'F' ? 'Female' : 'Others'}</span></p>
                <p className="text-xs font-medium text-gray-700">Age :<span className="text-xs pl-1 font-normal text-gray-500">{getAge(state.patientDOB)}</span></p>
              </div>

              <div className="ml-12 mr-4">
                <p className="text-xs font-medium text-gray-700">Patient ID. :<span className="text-xs pl-1 font-normal text-gray-500">{state.patientMobileNo}</span></p>
                <p className="text-xs font-medium text-gray-700">Hospital :<span className="text-xs pl-1 font-normal text-gray-500">{state.hospitalName}</span></p>
              </div>

              {/*<div className="flex mt-3 items-center justify-around space-x-3">
                  <button className="bg-transparent text-xs text-brand-secondary font-medium  py-2 px-4 border  w-1/2  border-brand-secondary rounded-lg">Refer</button>
                  <div className="lg:hidden border-l h-8"></div>
                  
                  <button className="bg-brand-secondary text-xs  text-white lg:w-full w-1/2 font-medium py-2 px-4 rounded-lg">Follow Up</button>
                </div>*/}


            </div>
          </div>
        </div>


        <div className="lg:p-10 p-5 lg:grid lg:grid-cols-3 lg:gap-4">
          {/* card 2 */}
          {patientdetailsData.slice(0, 1).map((user, i) => (
            <div className=" w-full h-auto mb-4 rounded-lg overflow-hidden w-2/4 h-52 border boder-gray-600 shadow-sm p-4 font-rubik">

              <p className="text-base text-gray-900 font-medium pb-2">General Information</p>
              <div className="flex justify-between">
                <p className="text-sm text-gray-400">Date of birth</p>
                <p className="text-sm text-gray-600">{moment(user.dob).format("DD/MM/yyyy")}</p>
              </div>
              <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Gender</p>
                <p className="text-sm text-gray-600">{user.gender == 'M' ? 'Male' : user.gender == 'F' ? 'Female' : 'Others'}</p>
              </div>
              <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Location</p>
                <p className="text-sm text-gray-600">{user.city}</p>
              </div>
              {/* <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Preferred Language</p>
                <p className="text-sm text-gray-600">{user.pLanguage}</p>
              </div> */}
              <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Phone Number</p>
                <p className="text-sm text-gray-600">{user.mobile}</p>
              </div>
              <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Email ID</p>
                <p className="text-sm text-gray-600">{user.email}</p>
              </div>
            </div>
          ))}
          {/* card 1 */}
          <div className="col-span-2  w-full h-auto rounded-lg overflow-hidden w-2/4 h-52 border boder-gray-600 shadow-sm p-4 font-rubik">
            <div className="flex justify-between pb-2">
              <div className="flex space-x-3" onClick={filter}>
                <img src={search} alt="search" className="w-4" />
                <p className="text-sm text-brand-secondary font-medium">Search or Filter </p>
              </div>
              {/* <Link to={'/doctordetails'}>
                  <p className="text-xs font-medium text-brand-secondary">Add For Consultation</p>
                </Link> */}
            </div>
            <div className="pb-2 border-t "></div>

            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-2">
                  <div className="overflow-scroll h-52  sm:rounded-lg hide-scroll-bar">
                    <table className="min-w-full">
                      <thead className="">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Description
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Type
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Date
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Action
                          </th>
                          {/* <th scope="col" className="relative px-6 py-3">
                <span className="sr-only">Edit</span>
              </th> */}
                        </tr>
                      </thead>

                      <tbody className=" divide-gray-200">

                        {patientPHRData.filter(x => x.docName !== '' && x.docName !== null && x.docName !== 'null').map((user, i) => (
                          <tr key={i}>
                            <td className="pl-6 py-4">
                              <div className="flex break-all">
                                <img src={curebay} alt="curebay" className="h-6 w-6 rounded-full" />
                                <div className="text-xs  font-normal text-gray-400 ml-2">
                                  {user.docName ? user.docName : user.notes}
                                </div>
                              </div>
                            </td>
                            <td className="pl-6 py-4">
                              <div className="text-xs font-normal text-gray-400">{user.reportType}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-xs font-normal text-gray-400">{moment(user.givenDate).format("DD/MM/yyyy h:mm:ss")}</div>
                            </td>
                            <td className="px-6 py-4 flex space-x-4 cursor-pointer">
                              {user.docName ? <img onClick={() => viewImage(user.docName)} src={download} alt="download" /> : ''}
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>


                </div>

              </div>

            </div>



          </div>
        </div>
      </div>




      <BloodPresure></BloodPresure>

      {showFilter ? (
          <FilterDocument
          filterDocuments={loadDocuments}
          closePopup={() => {
            setshowfilter(false);
          }}
          onClose={() => {
            setshowfilter(false);
          }}
          ></FilterDocument>
        ) : null}
    </>
  );
}

export default PatientDetails;