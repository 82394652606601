import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
//import { getparticulardoctordetails } from '../Redux/Actions/doctAction';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import { Link, useHistory, } from 'react-router-dom';
import patientService from "../Redux/services/patientService";


function PatientProfileSidebar(props) {

    const history = useHistory();
    const dispatch = useDispatch();
    const particulardoct = useSelector((state) => state.particulardoctor);
    const { particulardoctorData } = particulardoct;

    const [dropdown, setdropdown] = useState(false);

    const redirectTo = (event, location) => {
        event.preventDefault();
        props.loadItem(location);
        //history.push(location)
    }

    const showdropdown = (event) => {
        event.preventDefault();
        setdropdown(true)
    }
    let sideBardata = []
    if (localStorage.getItem('videoStatusId')) {
        sideBardata = [
            {
                text: 'Health Records',
                routes: [
                    { route: 'medicalhistory', text: 'Medical History' },
                    { route: 'reports', text: 'Reports' },
                    { route: 'prescription', text: 'Prescription' },
                    { route: 'labReport', text: 'Lab Report' },
                ]
            },
            {
                text: 'Vitals',
                route: 'vitals'
            },
            {
                text: 'Physical Examination',
                route: 'physicalExam'
            },
            {
                text: 'Clinical Image',
                route: 'clinicalImage'
            }
        ]
    } else {
        sideBardata = [
            {
                text: 'Health Records',
                routes: [
                    { route: 'medicalhistory', text: 'Medical History' },
                    { route: 'reports', text: 'Reports' },
                    { route: 'prescription', text: 'Prescription' },
                    { route: 'labReport', text: 'Lab Report' },
                ]
            },
            {
                text: 'Vitals',
                route: 'vitals'
            },
            {
                text: 'Physical Examination',
                route: 'physicalExam'
            },
        ]
    }


    const checkOpen = (i) => {
        console.log("indiads")
        console.log(i)
        setIndex(i);
        if (i === 0) {
            console.log("2")
            setshowhealthrecord(true)
            setshoworders(false)

        }
        if (i === 1) {
            console.log("7")
            setshoworders(true)
            setshowhealthrecord(false)


        }
        setOpen(!isOpen)
    }

    const [isOpen, setOpen] = useState(true);
    const [index, setIndex] = useState(false);
    const [showhealthrecord, setshowhealthrecord] = useState(true);
    const [showorders, setshoworders] = useState(true);

    return (
        <>


            <div class="flex space-x-4 ">
                <div class="w-full  mt-4 ">
                    <div class="rounded-lg bg-white shadow-lg bg-white-600 w-max lg:w-full px-2  p-3 mb-2 antialiased border border-gray-200">

                        {sideBardata.map((res, i) => {
                            return (
                                <>
                                    <div key={i} onClick={(e) => { res.route ? redirectTo(e, res.route) : checkOpen(i) }} className="flex justify-between content-center cursor-pointer">
                                        <p className="text-brand-secondary  lg:font-medium font-thin text-lg lg:font-rubik font-montserrat lg:my-2 my-3 cursor-pointer">{res.text}</p>
                                        <svg
                                            class={`${i === index ? 'transform rotate-90 ' : 'transform rotate-0 '} lg:block hidden mt-2 h-5 w-auto text-brand-secondary`}
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>

                                    </div>
                                    {isOpen && showhealthrecord ? <div x-show="dropdownOpen" class="ml-8">
                                        {res?.routes?.map((data, j) => (
                                            <p key={j} onClick={(e) => { redirectTo(e, data.route) }} className="text-brand-secondary font-montserrat lg:font-medium font-thin text-lg my-2 cursor-pointer">{data.text}</p>
                                        ))}
                                    </div> : null}

                                    {isOpen && showorders ? <div x-show="dropdownOpen" class="ml-8">
                                        {res?.routess?.map((data, j) => (
                                            <p key={j} onClick={(e) => { redirectTo(e, data.route) }} className="text-brand-secondary font-montserrat lg:font-medium font-thin text-lg my-2 cursor-pointer">{data.text}</p>
                                        ))}
                                    </div> : null}
                                    <hr />
                                </>
                            )
                        })}

                    </div>
                </div>



            </div>

        </>
    );
}
export default PatientProfileSidebar;