import { Fragment, useEffect, useRef, useState } from "react";
// import { Dialog, Transition } from '@headlessui/react'
import { Dialog } from "primereact/dialog";
import {
  ExclamationIcon,
  SearchIcon,
  XIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { getLabs } from "../";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getLabTest } from "../Redux/Actions/MedicineActions";
import { getparentLabTest } from "../Redux/Actions/MedicineActions";
import priscription from "../Redux/services/priscription";
import { MultiSelect } from "primereact/multiselect";
import Autocomplete from 'react-autocomplete';

export default function LabModal(props) {
  const dispatch = useDispatch();
  const { patient } = props;
  const { labTestList } = useSelector((state) => state.medicinedruglist);
  const userData = useSelector((state) => state.authReducer.userData);
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [parentTest, setparentTest] = useState([]);
  const [childtest, setchildtest] = useState([]);
  const [selectedLabTest, setSelectedLabTest] = useState([]);
  const [enterlabTest, setenterlabTest] = useState("");
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const [parentCode, setParentCode] = useState('');
  const [Val, onSetVal] = useState("")
  useEffect(() => {
    dispatch(getparentLabTest())
      .then((result) => {
        if (result.length > 0) {
          console.log(result.length);
          console.log(result);
          setparentTest(result);
        }
        console.log(parentTest);
      })
      .catch((error) => {
        // setLoader(false)
        // redirectTo();
      });

    // dispatch(getLabTest()).then((result) => {
    //   if(result.length > 0){
    //     let parent = result.filter((data) => data.parentId == 0);
    //      setparentTest([...parentTest,parent]);
    //    }

    // })
    // .catch((error) => {
    //   // setLoader(false)
    //   // redirectTo();
    // });

    dispatch(getLabTest());
    // return () => {
    //   setparentTest([]); // This worked for me
    // };
  }, []);
  const addLabTest = () => {
    const listNew = [];
    if (selectedLabTest.length) {
      for (let s = 0; s < selectedLabTest.length; s++) {
        let test = parentTest.filter((x) => x.code == selectedLabTest[s]);
        console.log(selectedLabTest);
        console.log(test);
        let data = {
          createdBy: userData.code,
          labParentName: test[0].parentDescription,
          labTestDescription: test[0].description,
          labTestId: test[0].code,
          labTestName: test[0].name,
          modifiedBy: userData.code,
          parentLabTestId: parentCode,
          parentLabTestName: test[0].parentDescription,
          patientId: patient.patientId,
          status: 1,
          userId: userData.code,
        };

        let index =
          list.find((x) => x.labTestName === selectedLabTest[s]) || -1;

        if (index === -1) {
          listNew.push(data);
        }
      }

      setList([...list, ...listNew]);
      console.log(list);
      onSetVal("");  // reset parent dropdown
      setSelectedLabTest([]); // reset child dropdown
    }
  };
  const addEnterLabTest = () => {
    const listNew = [];
    let labtest = enterlabTest;
    if (labtest) {
      let data = {
        createdBy: userData.code,
        parentLabTestName: "Other Test",
        labTestDescription: labtest,
        labTestId: null,
        labTestName: labtest,
        modifiedBy: userData.code,
        parentLabTestId: "O1",
        patientId: patient.patientId,
        status: 1,
        userId: userData.code,
      };
      listNew.push(data);
      setList([...list, ...listNew]);
      setenterlabTest("");
    }

    // if (selectedLabTest.length) {
    //   for (let s = 0; s < selectedLabTest.length; s++) {
    //     let test = childtest.filter((x) => x.code == selectedLabTest[s]);
    //     console.log(parentCode);
    //     let data = {
    //       createdBy: userData.code,
    //       labParentName: test[0].parentDescription,
    //       labTestDescription: test[0].description,
    //       labTestId: test[0].code,
    //       labTestName: test[0].name,
    //       modifiedBy: userData.code,
    //       parentLabTestId: parentCode,
    //       patientId: patient.patientId,
    //       status: 1,
    //       userId: userData.code,
    //     };

    //     let index =
    //       list.find((x) => x.labTestName === selectedLabTest[s]) || -1;

    //     if (index === -1) {
    //       listNew.push(data);
    //     }
    //   }

    //   setList([...list, ...listNew]);
    //   console.log(list);
    //   onSetVal("");  // reset parent dropdown
    //   setSelectedLabTest([]); // reset child dropdown
    // }
  };
  const AddLab = (lab) => {
    console.log(lab);
    console.log(labTestList);
    let test = labTestList.filter(
      (x) => x.code == lab.code && x.parentId == lab.parentId
    );
    console.log(test);
    let data = {
      createdBy: userData.code,
      hospitalAddress1: lab.hospitalAddress1,
      hospitalAddress2: lab.hospitalAddress2,
      hospitalAdminEmail: lab.hospitalAdminEmail,
      hospitalAdminName: lab.hospitalAdminName,
      hospitalCity: lab.hospitalCity,
      hospitalContactNumber1: lab.hospitalContactNumber1,
      hospitalContactNumber2: lab.hospitalContactNumber2,
      hospitalCountry: lab.hospitalCountry,
      hospitalEmail: lab.hospitalEmail,
      hospitalEmergencyContactNumber: lab.hospitalEmergencyContactNumber,
      hospitalId: lab.hospitalId,
      hospitalLogo: lab.hospitalLogo,
      hospitalName: lab.hospitalName,
      hospitalPinCode: 0,
      hospitalState: lab.hospitalState,
      hospitalType: lab.hospitalType,
      hospitalWebSite: lab.hospitalWebSite,
      labParentName: lab.labParentName,
      labTestDescription: lab.labTestDescription,
      labTestId: lab.code,
      locationAddress1: lab.locationAddress1,
      locationAddress2: lab.locationAddress2,
      locationCity: lab.locationCity,
      locationDescription: lab.locationDescription,
      locationId: lab.locationId,
      locationName: lab.locationName,
      labTestName: lab.name,
      locationPinCode: 0,
      locationState: lab.locationState,
      modifiedBy: userData.code,
      parentLabTestId: lab.parentId,
      patientId: patient.patientId,
      patientLabTestId: patient.id,
      prescriptionId: patient.id,
      status: 1,
      userId: userData.code,
    };
    //console.log(data);
    //console.log("list",lab)
    console.log("list", list);
    console.log(data);
    let index = list.find((x) => x.labTestName === lab.name);
    //console.log("index",index)
    if (index === undefined) {
      setList([...list, data]);
    }
  };

  const labtestchange = (val) => {
    priscription.ParentLabTestListById(val).then((res) => {
      console.log(res.data);
      setchildtest(res.data);
      setSelectedLabTest([]);
    });
    // labtest1 = labTestList.filter((data) => data.parentTestCode == val.target.value);
    // setchildtest(labtest1);
  };

  const onRemove = (i) => {
    let data = [...list];
    data[i].status = 0;
    //data.splice(i, 1);
    setList(data);
  };

  useEffect(() => {
    if (props.data) {
      setList(props.data);
      console.log("us setlist");
    }
  }, [props.data]);
  const renderUsernameP = (state, val) => {
    console.log("lol", val)
    return (
      state.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
    );
  }
  const onChangeHandler = (obj, e) => {
    console.log(e);
    console.log(obj);
    // setShowAutoComplete(true);
    onSetVal(e.name);
    labtestchange(e.id);
    // if(e.length >= 3) {
    //   onSetVal(e.name);
    //   labtestchange(e.id);
    // }
    // else {
    //   onSetVal(e.name);
    //   labtestchange(e.id);
    // }

  }

  const onChangeSelectHandler = (obj, e) => {
    console.log(e);
    console.log(obj);
    onSetVal(e.name);
    labtestchange(e.id);
    // setShowAutoComplete(false);
    setParentCode(e.code);
    // alert(JSON.stringify(e.name))
    //setVisitData({ ...visitData, ['primaryDiagnosis']: e.description, ['primarySymptoms']: e.termCode })
  }

  const onShowAutoComplete = (e) => {
    // setShowAutoComplete(true);
  }
  return (
    <Dialog
      header="Add Lab Test"
      visible={true}
      // style={{ width: "25vw" }}
      modal
      style={{ width: "35vw" }}
      // className="w-11/12 md:w-1/2 lg:w-5/12 xl:w-3/12"

      // className="w-11/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
      onHide={() => props.onClose(list)}
    >
      {/* <div className="inline-block h-auto w-10/12 sm:w-full lg:w-11/48 align-bottom bg-white rounded-lg text-left  overflow-hidden transform transition-all sm:my-8 sm:align-middle"> */}
      {/* <div className="bg-white px-2 pt-5 pb-4 sm:p-6 sm:pb-4 ">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 sm:mt-0 sm:ml-2 sm:text-left "> */}
      {/* <div className="text-lg leading-6 font-medium flex mb-4">
                    <p></p>
                    <XIcon onClick={() => props.onClose(list)} className="h-6 w-6 text-black-600 ml-24" aria-hidden="true" />
                  </div> */}
      <div className="mt-2 w-full">
        {/* <div className="relative mb-5"> */}
        {/* <div className="flex w-full pt-4">
                    <select
                      autocomplete="off"
                      id="parentTest"
                      name="parentTest"
                      type="text"
                      className=" w-full peer border-b-2 border-gray-300 text-black-300 focus:outline-none focus:borer-rose-600"
                      placeholder="Select parent test"
                      onChange={labtestchange}
                    >
                      <option value="">Select parent test</option>
                      {parentTest.map((res, i) => (
                        <option value={res.id}>{res.name}</option>
                      ))}
                    </select>
                  </div>
                  <label
                    for="parentTest"
                    className="absolute left-0 -top-3.5 text-gray-500 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                  >
                    Parent Test
                  </label> */}
        {/* <div className="relative w-full sm:w-auto ">
          <label for="parentTest" className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Parent Test</label>
          <div className="flex border-b-2 w-full">
            <div className="autocomplete-wrapper">
              <Autocomplete
                id="parentTest"
                name="parentTest"
                className="peer bg-transparent h-10 w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 mt-3"
                value={Val}
                items={parentTest}
                getItemValue={item => item.name}
                shouldItemRender={renderUsernameP}
                renderMenu={item => (
                  <div className="dropdown">
                    {item}
                  </div>
                )}
                renderItem={(item, isHighlighted) =>
                  <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                    {item.name}
                  </div>
                }
                onChange={(event, val) => onSetVal(val)}
                onSelect={(event, obj) => onChangeSelectHandler(event, obj, onSetVal(obj),)}
              // onChange={(handleChange)}
              />
            </div>
            <SearchIcon className="align-right w-5 h-5 mt-1 mr-3" />
          </div>
        </div> */}
        {/* </div> */}
        {/* <div className="mt-2 w-full"> */}
          <div className="relative mb-3 mt-2">
               <MultiSelect
                value={selectedLabTest}
                options={parentTest ? parentTest : []}
                filter
                style={{width:'100%'}}
                panelClassName={{width:'100%'}}
                panelStyle={{width:"100%"}}
                className="flex w-full peer border-b-2 text-gray-500 border-black-500 focus:outline-none focus:borer-rose-600"
                onChange={(e) => setSelectedLabTest(e.value)}
                onHide={addLabTest}
                optionLabel="name"
                id="facilityLocationId"
                name="facilityHospitalName"
                optionValue="code"
                placeholder="Select a Test"
              />
            </div><div className="flex">
      
          </div>
        {/* </div> */}

        <div className="mt-6 w-full">
          <div className="relative mb-3">
            <div className="h-6 w-max pl-2 pr-2 bg-brand-cerulean rounded-2xl flex">
              <span className="cursor-pointer break-words text-xs text-white mt-1 font-medium">If lab tests not available in the list, please type it here.</span>
            </div>
          </div>
        </div>
        {/* <div className="mt-2 w-full">
          <div className="relative mb-5"> */}
        <div className="relative mb-5">
          <div className="flex">
            <input autocomplete="off" id="doctorName" name="doctorName" value={enterlabTest} className="peer bg-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter the Lab Test" onChange={(e) => setenterlabTest(e.target.value)} />
            <span onClick={() => addEnterLabTest()} className="cursor-pointer w-10 mt-4 ml-6 text-brand-secondary font-medium">Add</span>
          </div>
        </div>
        {/* </div>
        </div> */}

        {/* <div className="flex relative w-full">
                      <input autocomplete="off" id="email" onChange={(e) => { search(e.target.value) }} name="email" type="text" className="bg-transparent border rounded text-sm peer h-8 w-full border-gray-300 text-gray-900 p-2" placeholder="Search for test" />
                      <SearchIcon stroke={'gray'} className="h-4 w-4 text-black-600 relative right-5 top-2" aria-hidden="true" />
                    </div>
                    {keyword && <div className="z-50 shadow-lg h-28 overflow-y-auto p-2 mr-4">
                      {originalList && originalList.length > 0 && originalList.map((data, i) => (
                        data && data.name && <div className="flex justify-between">
                          <span className="mt-2 mb-1 text-sm text-gray-400 font-medium w-1/2">{data.name}</span>
                          <p onClick={() => AddLab(data)} className="cursor-pointer text-xs mt-2 mb-1 text-brand-secondary font-medium">+Add Test</p>
                        </div>
                      ))
                      }
                    </div>
                    } */}
        {/* <span className="text-gray-400">Test name</span> */}
        <div className="mt-2">
          {list.length > 0 &&
            <span className="text-gray-primary  text-black font-black font-rubik">Selected Lab Test</span>
          }
          {list.length > 0 &&
            <div className="border-2  h-28 overflow-y-auto">
              {list.length > 0 &&
                list.map((data, i) => (
                  <div key={i} className="w-full px-2">
                    {data.status == 1 ?
                      <div className="flex m-1 justify-between">
                        <div className="text-gray-primary text-gray-400 text-xs font-rubik">
                          {data.labTestName}
                        </div>
                        <div className="h-4 w-4 ml-20">
                          <TrashIcon
                            onClick={() => onRemove(i)}
                            stroke={"#004171"}
                            className="cursor-pointer h-4 w-4"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                      :
                      null}

                  </div>
                ))}
            </div>
          }
          {/* </div>
              </div>
            </div> */}
        </div>
      </div>
      <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          className="w-full inline-flex justify-center rounded-md  shadow-sm px-4 py-2 bg-brand-secondary text-base font-medium text-white  sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => props.onClose(list)}
        >
          Save
        </button>
      </div>
      {/* </div> */}
    </Dialog>
  );
}
