import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { APP_ROUTES } from '../constants/AppRoutes';
import NotFound from "../../NotFound";
import PatientDetails from "../../../components/PatientDetails";
import PatientDetails2 from "../../../components/paitentDetails2";
import BloodPresure from "../../../components/BloodPresure";
import DoctorBillinghistory from "../../../components/DoctorBillinghistory";
import AppointmentOne from "../../../components/AppointmentOne";
import AppointmentTwo from "../../../components/AppointmentTwo";
import DoctorConsultation from "../../../components/DoctorConsultation";
import DoctorPatientrefered from "../../../components/DoctorPatientrefered";
import DoctorPrescription from "../../../components/DoctorPrescription";
import DoctorProfile from "../../../components/DoctorProfile";
import PatientsListing from "../../../components/PatientsListing";
import DoctorDetails from "../../../components/DoctorDetails";
import MedicinePrescription from "../../../components/MedicinePrescription";
import DownloadPrescription from "../../../components/downloadPrescription";
import WeekAppointments from "../../../components/WeekAppointments";
import DoctorSlotStageOne from "../../../components/DoctorSlotStageOne";
import UserLeaveDetails from '../../../components/UserLeaveDetails';
import DiagnopreStep1 from "../../../components/DiagnopreStep1";
import PritamPatil from "../../../components/PritamPatil";
import DiagnopreStep2 from "../../../components/DiagnopreStep2";
import DiagnopreStep3 from "../../../components/DiagnopreStep3";
import ConsulatationReport from "../../../components/ConsultationReport";
import Referedto from "../../../components/Referedto";
import Referedfrom from "../../../components/Referedfrom";
import ReferClinic from "../../../components/ReferClinic";
import ReferClinicList from "../../../components/ReferClinicList";
import ReferDoctorList from "../../../components/ReferDoctorList"
import ReferPatientList from "../../../components/ReferPatientList";
import ReferDoctor from "../../../components/ReferDoctor";
import ReferToAdd from "../../../components/ReferToAdd";
import ReferPatient from "../../../components/ReferPatient";
import Login from "../../../components/Login";
import Sidebar from "../../../components/profileSidebar";
import { useSelector } from "react-redux";
import { checkIsLoggedIn } from "../../../Redux/Actions/userActions";
import VideoCall from "../../../components/videoCall";
import ForgotPassword from "../../../components/forgotPassword";
import MapHospital from "../../../components/MapHospital";
import MapSpeciality from "../../../components/MapSpeciality";
import Notifications from "../../../components/Notifications";
import Changepassword from "../../../components/Changepassword";
import Sharecall from '../../../components/Sharecall'
import { Component } from "react";
import Header from '../../../components/Header';
import PrivacyPolicy from '../../../components/PrivacyPolicy'
import TermsAndConditions from "../../../components/TermsAndConditions";
import DoctorPayment from "../../../components/PayementAndHistory";
import CareSathi from "../../../components/careSathi/careSathi";
const Routes = (props) => {
  return (
    <>
      <Switch >
        <LoginRoute exact path={APP_ROUTES.NOT_FOUND} component={NotFound} ></LoginRoute>
        {/* <Route exact path={APP_ROUTES.PRIVACYPOLICY} component={PrivacyPolicy} ></Route> */}
        <PrivateRoute exact path={APP_ROUTES.PATIENT_DETAILS} component={PatientDetails} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.PATIENT_DETAILS2} component={PatientDetails2} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.BLOOD_PRESURE} component={BloodPresure} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DOCTOR_BILLING} component={DoctorBillinghistory} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.APPOINTMENT_ONE} component={AppointmentOne} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.APPOINTMENT_TWO} component={AppointmentTwo} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DOCTOR_CONSULTATION} component={DoctorConsultation} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DOCTOR_PATIENT} component={DoctorPatientrefered} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DOCTOR_PRESCRIPTION} component={DoctorPrescription} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DOCTOR_PROFILE} component={DoctorProfile} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.PATIENTS_LISTING} component={PatientsListing} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DOCTOR_DETAILS} component={DoctorDetails} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.MAP_HOSPITAL} component={MapHospital} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.MAP_SPECIALITY} component={MapSpeciality} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.MEDICINE_PRESCRIPTION} component={MedicinePrescription} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.WEEK_APPOINTMENTS} component={WeekAppointments} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DOCTOR_SLOT_STAGE_ONE} component={DoctorSlotStageOne} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.PAYMENT_HISTORY} component={DoctorPayment} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.LEAVEDETAILS} component={UserLeaveDetails} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DOWNLOAD} component={DownloadPrescription}></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DIAGNOPRESTEP1} component={DiagnopreStep1} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DIAGNOPRESTEP2} component={DiagnopreStep2} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DIAGNOPRESTEP3} component={DiagnopreStep3} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.CONSULATATION_REPORT} component={ConsulatationReport} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.REFEREDFROM} component={Referedfrom} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.REFERCLINIC} component={ReferClinic} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.REFERCLINICLIST} component={ReferClinicList} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.REFERDOCTORLIST} component={ReferDoctorList} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.REFERPATIENTLIST} component={ReferPatientList} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.REFERDOCTOR} component={ReferDoctor} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.REFERTOADD} component={ReferToAdd} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.REFERPATIENT} component={ReferPatient} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.REFEREDTO} component={Referedto} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.DASHBOARD} component={PritamPatil} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.VIDEO_CALL} component={VideoCall} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.POST_CONSULTATION} component={ConsulatationReport} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.NOTIFICATIONS} component={Notifications}></PrivateRoute>
        <PrivateRoute
          path={APP_ROUTES.CARESATHI}
          component={CareSathi}
        ></PrivateRoute>
        {/* <PrivateRoute exact path={APP_ROUTES.SIDEBAR} component={Sidebar} ></PrivateRoute> */}
        <PrivateRoute exact path={APP_ROUTES.SIDEBAR} component={Sidebar} ></PrivateRoute>
        <PrivateRoute exact path={APP_ROUTES.CHANGE_PSWD} component={Changepassword} ></PrivateRoute>
        <Route exact path={APP_ROUTES.SHARECALL} component={Sharecall} ></Route>
        <LoginRoute exact path={APP_ROUTES.FORGOT_PASSWORD} component={ForgotPassword} ></LoginRoute>
        <LoginRoute exact path={APP_ROUTES.PRIVACYPOLICY} component={PrivacyPolicy} ></LoginRoute>
        <LoginRoute exact path={APP_ROUTES.TERMSOFUSE} component={TermsAndConditions} ></LoginRoute>
        <LoginRoute exact path={APP_ROUTES.Login} component={Login} ></LoginRoute>



      </Switch>
    </>


  )
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkIsLoggedIn() ? (
        <>
          <Header />
          <Component {...props} />
        </>
      ) : (
        <Redirect
          to={{
            pathname: "/"
          }}
        />
      )
    }
  />
);

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Component {...props} />

    }
  />
)


export default Routes;