import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router';
import AgoraUIKit from "agora-react-uikit";

export default function Sharecall(props) {
    const params = useParams()
    const [name, setName] = useState("");
    const [err, setErr] = useState("");
    const [openvideo, setOpenvideo] = useState(false);
    const [url, setUrl] = useState('');
    const history = useHistory();
    const location = useLocation();
    const [rtcPropsId, setRtcPropsId] = useState(null); // Agora RTC properties
    const [videoCall, setVideoCall] = useState(false); // To handle video call start/stop
    const [cameraEnabled, setCameraEnabled] = useState(true);
    const save = (e) => {

        e.preventDefault();

        console.log(props.data)
        if (name.trim() === "") {
            setErr("Please enter the name")
            //alert("required both field");
        } else {
            setOpenvideo(true)
            const header = {
                secretKey: 'CcJYfbsgItHpTQPFr5lg'
            }
            const data = {
                "clientCode": "CCM@@202!",
                "userType": "PARTICIPANT",
                "meetingKey": params.id,
                "memberName": location?.state?.name ? location?.state?.name : name,
                "memberEmail": "test@gmail.com",
                "memberMobile": "1234567890"
            }

            axios.post('https://cb.maggieplus.com/admin-api/client/meeting/authentication', data, { headers: header }).then(result => {
                //setUrl(result.data.response.url)
                //setOpenvideo(true)
                if (params.type == "A") {
                    window.location.href = result.data.response.url + '/1';
                    setName("")
                }else{
                    window.location.href = result.data.response.url;
                    setName("")
                }


                //history.replace({state:{name:name,videoUrl:result.data.response.url}})
            }).catch(err => {
                console.log("Err", err)
            })
            // setupAgora()
        }
    };
    const setupAgora = async () => {
        try {
            const channelId = params?.id ? String(params.id) : "defaultChannel";
            const sanitizedChannelId = channelId.replace(/[^a-zA-Z0-9_-]/g, "");
            const response = await axios.get(
                `https://common-stage.curebay.in/cs/token/generate?channelName=${sanitizedChannelId}&role=subscriber`
            );
            const token = response.data?.data?.token;

            if (!token) {
                throw new Error("Token generation failed or token is missing");
            }

            const rtcProps = {
                appId: "5da64dc39db9467485eeaac6d515f210",
                channel: sanitizedChannelId || "TempChannel",
                token: token,
                uid: response.data?.data?.uid,
                enableAudio: true,
                enableVideo: cameraEnabled, // Toggle camera based on state
            };
            setRtcPropsId(rtcProps);
            setVideoCall(true);
        } catch (error) {
            console.error("Error setting up Agora:", error);
        }
    };

    useEffect(() => {
        //alert(JSON.stringify(location?.state))
        if (location?.state?.name) {
            setOpenvideo(true)
            setUrl(location?.state?.videoUrl)
        }
    }, []);

    const onChangeHandler = (fieldName, value) => {
        if (fieldName === "name") {
            setName(value);
            setErr("")
        }

    }
    const getPatientInitials = (name) => {
        const names = name?.split(" ") || [];
        const initials = names.map((n) => n[0]?.toUpperCase()).join("");
        return initials || "P"; // Fallback to "P" if no initials are found
    };
    const callbacks = {
        EndCall: () => setVideoCall(false),
    };

    return (
        <>

            {openvideo ?
                // <div className="w-full">
                //     {videoCall && rtcPropsId ? (
                //         <div style={{ display: 'flex', width: '100%', height: '80vh' }}>
                //             {cameraEnabled ? (
                //                 <AgoraUIKit
                //                     rtcProps={rtcPropsId}
                //                     callbacks={() => callbacks()}
                //                     style={{ width: '100%', height: '100%' }}
                //                 />
                //             ) : (
                //                 <div
                //                     style={{
                //                         display: "flex",
                //                         justifyContent: "center",
                //                         alignItems: "center",
                //                         width: "100%",
                //                         height: "100%",
                //                         fontSize: "50px",
                //                         backgroundColor: "#ececec",
                //                     }}
                //                 >
                //                     {getPatientInitials(location?.state?.name ? location?.state?.name : name)}
                //                 </div>
                //             )}
                //             {/* {params.type == "V"?<div dangerouslySetInnerHTML={{ 
                // //         __html:
                // //             `<iframe  allow="camera; microphone;" style="width: 100%; height:100vh" src=${url} frameborder="0" allowfullscreen showControls></iframe>`
                // //     }
                // //     } >
                // //     </div> : <div dangerouslySetInnerHTML={{
                // //         __html:
                // //             `<iframe  allow="microphone;" style="width: 100%; height:100vh" src=${url} frameborder="0" allowfullscreen showControls></iframe>`
                // //     }}>
                // //     </div>}

                // // </>
                // // null*/}
                //         </div>
                //     ) : (
                //         <p>Starting video consultation...</p>
                //     )}
                // </div>
                null
                : <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-80 my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*body*/}
                            <div className="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 antialiased justify-between border border-gray-200">
                                <div className="flex pt-2 w-full ">
                                    <div className="w-full">
                                        <form onSubmit={(e) => { save(e) }}>
                                            <div className={"lg:flex justify-between lg:space-x-10 " + (err ? "py-1" : "py-4")}>
                                                <input type="text"
                                                    placeholder="Enter the name"
                                                    value={name}
                                                    onChange={(e) => { onChangeHandler("name", e.target.value) }}
                                                    className="w-full peer bg-transparent h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                />
                                            </div>
                                            {err ? <span className="text-red-900">{err}</span> : null}
                                            <div className="flex justify-end">
                                                <input type="submit" value="Submit"
                                                    className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2 cursor-pointer" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
}
