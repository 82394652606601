import { USER } from "../Constants/userprofileConstants";
import { PRIS } from "../Constants/PriscriptionConstants";

const initialState = {
    doctorprescriptionData: [],
    doctorListData: [],
    isError: false,
    isLoading: false,
    msg: '',
    errMsg: ''
};


const doctorprescriptionReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER.REQUEST_DOCTPRESCRIPTION:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case USER.SUCCESS_DOCTPRESCRIPTION:
            return {
                ...state,
                isLoading: false,
                doctorprescriptionData : payload,
                isError: false,              
            };
        case PRIS.REQUEST_DOCTORLIST :
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };

            case USER.REQ:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case PRIS.SUCCESS_DOCTORLIST:
            return {
                ...state,
                isLoading: false,
                doctorListData : payload,
                isError: false,              
            };
        case PRIS.FAILED_DOCTORLIST :
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };
        default:
            return state;
    }
};

export default doctorprescriptionReducer;