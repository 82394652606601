import React from "react";
import { useSelector } from "react-redux";
import PaymentAndHistoryEmpanelled from "./PaymentAndHistoryEmpanelled";
import PaymentAndHistoryInventory from "./PaymentAndHistoryInventory";
function PayementAndHistory() {
    const loggedInData = useSelector((state) => state.authReducer);
    const { userData } = loggedInData;
    return (
        <>
            <div className="w-full lg:mr-16 lg:mt-5 ">
                {userData && userData.quickConsulted == 1 ?
                    <PaymentAndHistoryEmpanelled />
                    :
                    <PaymentAndHistoryInventory />
                }
            </div >
        </>
    );
}
export default PayementAndHistory;
