

import { REFER } from "../Constants/ReferalConstant";

import referalService from "../services/referalService";


export const ReferalTo = (data) => async (dispatch) => {
    request();
    const res = await referalService.referalTo(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: REFER.REQUEST_REFERTO });
    };

    function success(res) {
        dispatch({ type: REFER.SUCCESS_REFERTO, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: REFER.FAILED_REFERTO,
            payload: err
        });
    };
};

export const ReferalToDoctor = (data) => async (dispatch) => {
    request();
    const res = await referalService.referalToDoctor(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: REFER.REQUEST_REFERTODOCTOR });
    };

    function success(res) {
        dispatch({ type: REFER.SUCCESS_REFERTODOCTOR, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: REFER.FAILED_REFERTODOCTOR,
            payload: err
        });
    };
};
export const ReferalToAdd = (data) => async (dispatch) => {
    request();
    const res = await referalService.refertoadd(data)
    console.log("cr7", res, data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: REFER.REQUEST_REFERTO });
    };

    function success(res) {
        // dispatch({ type: REFER.SUCCESS_REFERTO, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: REFER.FAILED_REFERTO,
        //     payload: err
        // });
    };
};

export const ReferalClinicListData = (data) => async (dispatch) => {
    request();
    const res = await referalService.referalClinicList(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: REFER.REQUEST_REFERCLINICLIST });
    };

    function success(res) {
        dispatch({ type: REFER.SUCCESS_REFERCLINICLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: REFER.FAILED_REFERCLINICLIST,
            payload: err
        });
    };
};


export const ReferalDoctorListData = (data) => async (dispatch) => {
    request();
    const res = await referalService.referalDoctorList(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: REFER.REQUEST_REFERDOCTORLIST });
    };

    function success(res) {
        dispatch({ type: REFER.SUCCESS_REFERDOCTORLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: REFER.FAILED_REFERDOCTORLIST,
            payload: err
        });
    };
};


export const ReferalPatientListData = (data) => async (dispatch) => {
    request();
    const res = await referalService.referalPatientList(data)
    console.log("cr7", res, data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: REFER.REQUEST_REFERPATIENTLIST });
    };

    function success(res) {
        dispatch({ type: REFER.SUCCESS_REFERPATIENTLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: REFER.FAILED_REFERPATIENTLIST,
            payload: err
        });
    };
};

export const ReferalFrom = (data) => async (dispatch) => {
    request();
    const res = await referalService.referalFrom(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: REFER.REQUEST_REFERFROM });
    };

    function success(res) {
        dispatch({ type: REFER.SUCCESS_REFERFROM, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: REFER.FAILED_REFERFROM,
            payload: err
        });
    };
};

export const ReferHospital = (data) => async (dispatch) => {
    request();
    const res = await referalService.referHospitalClinic(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: REFER.REQUEST_REFERFROM });
    };

    function success(res) {
        // dispatch({ type: REFER.SUCCESS_REFERFROM, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: REFER.FAILED_REFERFROM,
        //     payload: err
        // });
    };
};

export const ReferPatientcurebay = (data) => async (dispatch) => {
    request();
    const res = await referalService.referPatientcurebay(data)
    console.log("cr78", data, res)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: REFER.REQUEST_REFERFROM });
    };

    function success(res) {
        // dispatch({ type: REFER.SUCCESS_REFERFROM, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: REFER.FAILED_REFERFROM,
        //     payload: err
        // });
    };
};
