import React, { useEffect, useState } from "react";
import calender from "../Assets/Images/calendar.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import Successfulslotpopup from "./Successfulslotpopup";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { gethospitalclinicList } from "../Redux/Actions/patientAction";
import {
  createnewdoctorslot,
  getSlotList,
  updateDoctorSlot,
} from "../Redux/Actions/DoctorslotAction";
import http from "../Redux/services/http-common";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import axios from "axios";

function DoctorSlotStageOne() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showslotspopup, setshowslotspopup] = useState(false);
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const loggedInData = useSelector((state) => state.authReducer);
  const { userData } = loggedInData;
  const [isEdit, setEdit] = useState("");
  const hospitalcliniclist = useSelector((state) => state.hospitalcliniclist);
  const { hospitalcliniclistData } = hospitalcliniclist;
  const addingdoctorslots = useSelector((state) => state.addingdoctorslots);
  const { doctslotData, slotList, isLoading } = addingdoctorslots;
  const [timeArr, setTimeArr] = useState([]);
  const [isDelete, setDelete] = useState("");
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [todayDate, setTodayDate] = useState(new Date());
  const [selectedEClinic, setselectedEClinic] = useState();
  const [getselectedEclinic, setgetselectedEclinic] = useState([]);

  const [consultationArr, setConsultationArr] = useState([
    5, 10, 15, 20, 25, 30, 40, 45, 50, 55, 60,
  ]);
  const CreateSlots = () => {
    const startAndEndTimes = [];
    for (let i = 0; i < 24; i++) {
      startAndEndTimes.push(
        moment().set({ hour: i, minute: 0 }).format("HH:mm")
      );
    }
    setTimeArr(startAndEndTimes);
  };
  console.log(slotList, "SLOTLIST");
  const [selectedHospital, setSelectedHospital] = useState();

  const [slot, setslot] = useState({
    audio: "N",
    consultationAudioConsFee: 0,
    consultationCurebayPercentage: "0",
    consultationDuration: "15",
    consultationInPersonConsFee: 1000,
    consultationVideoConsFee: 1500,
    createdBy: userData.code,
    fromDate: "",
    fromTime: "00:00",
    inPerson: "N",
    hospitalId: "-1",
    hospitalName: "-1",
    locationId: "",
    modifiedBy: userData.code,
    monday: "N",
    tuesday: "N",
    wednesday: "N",
    thursday: "N",
    friday: "N",
    saturday: "N",
    sunday: "N",
    publicHolidays: "N",
    quickConsultation: userData.quickConsulted == 1 ? "N" : "N",
    status: 1,
    toDate: "",
    toTime: "00:00",
    userId: userData.code,
    video: "N",
    inEclinic: "N",
    eClinicName: "",
    optionalId: "",
  });

  const [mslot, setModifySlot] = useState({
    audio: "N",
    id: "",
    consultationAudioConsFee: 0,
    consultationCurebayPercentage: "0",
    consultationDuration: "15",
    consultationInPersonConsFee: 1000,
    consultationVideoConsFee: 1500,
    createdBy: userData.code,
    fromDate: moment().format("DD/MM/yyyy"),
    fromTime: "00:00",
    inPerson: "N",
    hospitalId: "-1",
    hospitalName: "-1",
    locationId: "string",
    modifiedBy: userData.code,
    monday: "Y",
    tuesday: "Y",
    wednesday: "Y",
    thursday: "Y",
    friday: "Y",
    saturday: "Y",
    sunday: "Y",
    publicHolidays: "Y",
    quickConsultation: userData.quickConsulted == 1 ? "Y" : "N",
    status: 1,
    toDate: moment().format("DD/MM/yyyy"),
    toTime: "10:00",
    userId: userData.code,
    video: "N",
  });

  const selectHospital = (e, val) => {
    let res = JSON.parse(e);
    console.log(res);
    if (val) {
      setslot({
        ...slot,
        hospitalId: res.hospitalId,
        locationId: res.locationId,
      });
      setSelectedHospital(e);
    } else {
      setModifySlot({
        ...mslot,
        hospitalId: res.hospitalId,
        locationId: res.locationId,
      });
    }
  };
  // const selectEclinic = (e, val) => {
  //   let res = JSON.parse(e);
  //   console.log(res);
  //   if (val) {
  //     setslot({
  //       ...slot,
  //       locationCode: res.hospitalId,
  //       hospitalCode: res.locationId,
  //     });
  //     setSelectedHospital(e);
  //   } else {
  //     setModifySlot({
  //       ...mslot,
  //       locationCode: res.hospitalId,
  //       hospitalCode: res.locationId,
  //     });
  //   }
  // };

  const refreshPage = (e) => {
    setTimeout(function () {
      console.log("Data", doctslotData);
      if (doctslotData?.message === "Record is already Available") {
        setsnackbaropen(true);
        if (doctslotData?.details?.length) {
          setsnackbarmessage(doctslotData?.details[0]);
        } else {
          setsnackbarmessage(
            "Record is already booked please blook another slot"
          );
        }
        setshowslotspopup(false);
      } else {
        //window.location.reload(false);
        history.push(APP_ROUTES.DOCTOR_SLOT_STAGE_ONE);
        dispatch(getSlotList(userData.code)).then((result) => {
          setsnackbaropen(true);
          setsnackbarmessage("Slots Created Successfully");
          setshowslotspopup(false);
          setslot({ ...slot, ["fromTime"]: "00:00" });
          setslot({ ...slot, ["toTime"]: "00:00" });
          setFromTime(null);
          setToTime(null);
          setslot({
            audio: "N",
            consultationAudioConsFee: 0,
            consultationCurebayPercentage: "0",
            consultationDuration: "-1",
            consultationInPersonConsFee: 1000,
            consultationVideoConsFee: 1500,
            createdBy: userData.code,
            fromDate: "",
            fromTime: "00:00",
            inPerson: "N",
            hospitalId: "-1",
            hospitalName: "-1",
            locationId: "",
            modifiedBy: userData.code,
            monday: "N",
            tuesday: "N",
            wednesday: "N",
            thursday: "N",
            friday: "N",
            saturday: "N",
            sunday: "N",
            publicHolidays: "N",
            quickConsultation: userData.quickConsulted == 1 ? "N" : "N",
            status: 1,
            toDate: "",
            toTime: "00:00",
            userId: userData.code,
            video: "N",
          });
        });
      }
    }, 4000);
  };

  const getEclinic = async () => {
    const url =
      "https://medicare-api.curebay.com/medicare/location/getAllEclinics";
    try {
      const response = await axios.post(url);
      if (response?.data?.succesObject) {
        setgetselectedEclinic(response.data.succesObject);
      }
    } catch (error) {
      console.error("Error fetching e-clinics data:", error);
    }
  };
  useEffect(() => {
    getEclinic();
  }, []);
  const selectEclinic = (value, isValid) => {
    console.log("Selected E-Clinic:", value);
    if (value) {
      const [locationCode, hospitalCode] = value.split("|");
      if (isValid) {
        setslot({
          ...slot,
          eClinicName: locationCode,
          optionalId: hospitalCode,
        });
      } else {
        setModifySlot({
          ...mslot,
          eClinicName: locationCode,
          optionalId: hospitalCode,
        });
      }
    }
  };

  const onClose = (e) => {
    // e.preventDefault();
    setsnackbaropen(false);
  };

  const handleChange = (e) => {
    setslot({ ...slot, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    CreateSlots();
    dispatch(gethospitalclinicList(userData.code));
    dispatch(getSlotList(userData.code));
    console.log(hospitalcliniclistData);

    if (hospitalcliniclistData.length > 0) {
      setslot({
        ...slot,
        hospitalId: hospitalcliniclistData[0].hospitalId,
        locationId: hospitalcliniclistData[0].locationId,
      });
    }
  }, [userData.code]);

  const addSlotbutton = (e) => {
    e.preventDefault();
    console.log("sadas", slot);
    if (
      slot.fromDate === "" ||
      slot.toDate === "" ||
      !fromTime ||
      !toTime ||
      slot.locationId === "" ||
      slot.locationId === undefined
      
    ) {
      toast("Enter all Mandatory fields");
      return;
    }
    if (slot.inEclinic === "Y" && (
      slot.eClinicName === "" ||
      slot.eClinicName === undefined ||
      slot.optionalId === "" ||
      slot.optionalId === undefined
    )) {
      toast("Enter all eClinic Mandatory fields");
      return;
    }
    if (
      slot.audio === "N" &&
      slot.video === "N" &&
      slot.quickConsultation === "N" &&
      slot.inPerson === "N" &&
      slot.inEclinic === "N"
    ) {
      toast("Please select consultation type");
      return;
    }
    if (Date.parse(slot.toDate) < Date.parse(slot.fromDate)) {
      toast("End Date is lesser than Start date");
      console.log("End Date is lesser than Start date");
      return;
    }
    // if (slot.toTime <= slot.fromTime) {
    //   toast("End time is lesser than Start time");
    //   console.log("End time is lesser than Start time");
    //   return;
    // }
    if (
      slot.sunday === "N" &&
      slot.monday === "N" &&
      slot.tuesday === "N" &&
      slot.wednesday === "N" &&
      slot.thursday === "N" &&
      slot.friday === "N" &&
      slot.saturday === "N"
    ) {
      toast("Please Select Days");
      return;
    }

    slot.fromTime = moment(fromTime).format("HH:mm");
    slot.toTime = moment(toTime).format("HH:mm");
    console.log(slot);
    dispatch(createnewdoctorslot(slot))
      .then((result) => {
        if (!result.message) {
          refreshPage();
          setshowslotspopup(true);

          setSelectedHospital("-1");
          setslot({
            audio: "N",
            consultationAudioConsFee: 0,
            consultationCurebayPercentage: "0",
            consultationDuration: "-1",
            consultationInPersonConsFee: 1000,
            consultationVideoConsFee: 1500,
            createdBy: userData.code,
            fromDate: "",
            fromTime: "00:00",
            inPerson: "N",
            hospitalId: "-1",
            hospitalName: "-1",
            locationId: "",
            modifiedBy: userData.code,
            monday: "N",
            tuesday: "N",
            wednesday: "N",
            thursday: "N",
            friday: "N",
            saturday: "N",
            sunday: "N",
            publicHolidays: "N",
            quickConsultation: userData.quickConsulted == 1 ? "N" : "N",
            status: 1,
            toDate: "",
            toTime: "00:00",
            userId: userData.code,
            video: "N",
          });
        } else {
          toast(result.details[0]);

          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onDayChange = (e, val) => {
    let check = e.target.checked;
    console.log("e.target", e.target.name, check);

    if (val) {
      if (e.target.name === "inEclinic") {
        const updates = {
          inPerson: "N",
          video: "N",
          audio: "N",
          quickConsultation:"N",
          inEclinic: check ? "Y" : "N",
        };
        setslot({ ...slot, ...updates });
        toast('Only In Clinic Slots creation Allowed')
      } else {
        if (["inPerson", "video", "audio"].includes(e.target.name) && check) {
          setslot({ ...slot, [e.target.name]: "Y", inEclinic: "N" });
        } else {
          setslot({ ...slot, [e.target.name]: check ? "Y" : "N" });
        }
      }
    } else {
      setModifySlot({ ...mslot, [e.target.name]: check ? "Y" : "N" });
    }
  };

  const deleteSlot = () => {
    // dispatch(deleteAppointment(id))
    mslot.status = 0;
    return http
      .put(`AppointmentTemplate/${isDelete}`, mslot)
      .then((result) => {
        setDelete("");
        dispatch(getSlotList(userData.code));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const updateSlot = () => {
    dispatch(updateDoctorSlot(mslot));
    refreshPage();
    setshowslotspopup(true);
  };

  const modifySlot = (e) => {
    setModifySlot({ ...mslot, [e.target.name]: e.target.value });
  };
  return (
    <>
      <ToastContainer />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbaropen}
        autoHideDuration={3000}
        onClose={onClose}
        message={<span id="message-id">{snackbarmessage}</span>}
        action={[
          <IconButton
            key="close"
            arial-label="Close"
            color="inherit"
            onClick={onClose}
          >
            X
          </IconButton>,
        ]}
      ></Snackbar>
      <div className="lg:mx-10 mx-4 mb-10 ">
        <div className="my-2">
          <div className="flex flex-between my-2 justify-between">
            <p className="text-xl text-gray-900 font-medium font-rubik">
              Existing Slots
            </p>
            <div className="w-max py-2 px-5 bg-brand-white border border-brand-secondary text-brand-primary rounded-lg flex">
              <span
                onClick={() => history.push(APP_ROUTES.LEAVEDETAILS)}
                className="text-sm font-medium text-brand-primary "
              >
                Leave Details
              </span>
            </div>
          </div>
        </div>
        <div className="border rounded-2xl justify-center w-full overflow-auto hide-scroll-bar">
          {!isLoading && slotList.length === 0 && (
            <span>No slots available.Please Create new slots</span>
          )}
          <div>
            {slotList.map((res, i) => (
              <div>
                {res.status == "1" ? (
                  <div className="overflow-hidden  lg:px-10 px-2 border rounded-2xl lg:mx-10 mx-2 lg:my-6 my-2">
                    <div className="flex">
                      <div class="w-full">
                        <br />
                        <div className="lg:flex justify-between w-full">
                          <div className="lg:no-wrap flex lg:gap-0 gap-16 ">
                            {/* <p className="text-md text-gray-900 font-rubik pl-10 pt-10">Set you weekly hours</p> */}
                            <p className="text-md w-32 text-gray-900 font-rubik">
                              Start Date
                              <div className="flex overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                                {/* <p className=" text-sm font-rubik text-gray-700">01/02/2021</p><img src={calender} alt="calender" className="pl-8" /> */}
                                <DatePicker
                                  disabled={isEdit === i ? false : true}
                                  showMonthDropdown
                                  showYearDropdown
                                  dateFormat={"DD/MM/yyyy"}
                                  value={
                                    isEdit === i
                                      ? moment(mslot.fromDate).format(
                                          "DD/MM/yyyy"
                                        )
                                      : moment(res.fromDate).format(
                                          "DD/MM/yyyy"
                                        )
                                  }
                                  className="bg-transparent w-full"
                                  onChange={(date) => {
                                    setModifySlot({
                                      ...mslot,
                                      ["fromDate"]:
                                        moment(date).format("DD/MM/yyyy"),
                                    });
                                  }}
                                />
                              </div>
                            </p>
                            <p className="text-md lg:w-44 w-32 text-gray-900 font-rubik lg:pl-10 ">
                              End Date
                              <div className="flex overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                                {/* <p className=" text-sm font-rubik text-gray-700">31/12/2021</p><img src={calender} alt="calender" className="pl-8" /> */}
                                <DatePicker
                                  disabled={isEdit === i ? false : true}
                                  showMonthDropdown
                                  showYearDropdown
                                  className="bg-transparent w-full"
                                  dateFormat={"DD/MM/yyyy"}
                                  value={
                                    isEdit === i
                                      ? moment(mslot.toDate).format(
                                          "DD/MM/yyyy"
                                        )
                                      : moment(res.toDate).format("DD/MM/yyyy")
                                  }
                                  onChange={(date) => {
                                    setModifySlot({
                                      ...mslot,
                                      ["toDate"]:
                                        moment(date).format("DD/MM/yyyy"),
                                    });
                                  }}
                                />
                              </div>
                            </p>
                          </div>
                          <div className="lg:no-wrap flex lg:mt-0 mt-2 lg:gap-0 gap-16">
                            <p className="text-md w-32 text-gray-900 font-rubik">
                              Start Time
                              <div className="flex  overflow-hidden bg-white  p-2">
                                {/* <select
                                  disabled={isEdit === i ? false : true}
                                  value={
                                    isEdit === i ? mslot.fromTime : res.fromTime
                                  }
                                  name="fromTime"
                                  className="w-full "
                                  onChange={modifySlot}
                                >
                                  {timeArr.map((res, i) => (
                                    <option key={i} value={res}>
                                      {res}
                                    </option>
                                  ))}
                                </select> */}

                                <input
                                  autocomplete="off"
                                  id="fromTime"
                                  name="fromTime"
                                  type="text"
                                  disabled={isEdit === i ? false : true}
                                  className="w-full px-2 h-9 peer border-2 rounded-md"
                                  placeholder=""
                                  value={
                                    isEdit === i ? mslot.fromTime : res.fromTime
                                  }
                                  onChange={modifySlot}
                                />
                              </div>
                            </p>
                            <p className="text-md w-32 text-gray-900 font-rubik">
                              End Time
                              <div className="flex overflow-hidden  p-2">
                                <input
                                  autocomplete="off"
                                  id="toTime"
                                  name="toTime"
                                  type="text"
                                  disabled={isEdit === i ? false : true}
                                  className="w-full px-2 h-9 peer border-2 rounded-md"
                                  placeholder=""
                                  value={
                                    isEdit === i ? mslot.toTime : res.toTime
                                  }
                                  onChange={modifySlot}
                                />

                                {/* <select
                                  disabled={isEdit === i ? false : true}
                                  value={
                                    isEdit === i ? mslot.toTime : res.toTime
                                  }
                                  name="toTime"
                                  className="w-full"
                                  onChange={modifySlot}
                                >
                                  {timeArr.map((data, i) => (
                                    <option key={i} value={data}>
                                      {data}
                                    </option>
                                  ))}
                                </select> */}
                              </div>
                            </p>
                          </div>
                          {/* <div className="lg:mt-0 mt-2"> */}

                          <p className="text-md  text-gray-900 font-rubik lg:pl-10 lg:mt-0 mt-2">
                            Duration
                            <div className="flex lg:w-32 w-32 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                              <select
                                value={res.consultationDuration}
                                disabled={isEdit === i ? false : true}
                                name="consultationDuration"
                                className="w-full"
                                onChange={handleChange}
                              >
                                <option value="-1">{"Select Duration"}</option>
                                {consultationArr.map((res, i) => (
                                  <option key={i} value={res}>
                                    {res}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </p>

                          <p className="text-md  text-gray-900 font-rubik lg:pl-10">
                            Hospital
                            <div className="flex w-32 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                              <select
                                disabled={isEdit === i ? false : true}
                                value={res.hospitalId}
                                name="hospitalId"
                                className="w-full"
                                onChange={(e) => {
                                  selectHospital(e.target.value, false);
                                }}
                              >
                                <option value="-1" selected>
                                  {"select"}
                                </option>
                                {hospitalcliniclistData.map((res, i) => (
                                  <option key={i} value={res.hospitalId}>
                                    {res.hospitalName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </p>

                          {/* </div> */}
                        </div>
                        <div className="flex flex-wrap mt-2 ml-2 text-brand-secondary gap-2 font-small">
                          <div className="mr-4">
                            <input
                              readOnly={isEdit === i ? false : true}
                              type="checkbox"
                              name="inPerson"
                              onChange={(e) => onDayChange(e, false)}
                              checked={
                                isEdit === i
                                  ? mslot.inPerson === "Y"
                                    ? true
                                    : false
                                  : res.inPerson === "Y"
                                  ? true
                                  : false
                              }
                              className="mr-2"
                            />
                            <label>InPerson</label>
                          </div>
                          <div>
                            <input
                              readOnly={isEdit === i ? false : true}
                              type="checkbox"
                              name="quickConsultation"
                              onChange={(e) => onDayChange(e, false)}
                              checked={
                                isEdit === i
                                  ? mslot.quickConsultation === "Y"
                                    ? true
                                    : false
                                  : res.quickConsultation === "Y"
                                  ? true
                                  : false
                              }
                              className="mr-2"
                              disabled={
                                userData.quickConsulted == 1 ? false : true
                              }
                            />
                            <label>Quick</label>
                          </div>
                          &nbsp;
                          <div>
                            <input
                              readOnly={isEdit === i ? false : true}
                              type="checkbox"
                              name="video"
                              onChange={(e) => onDayChange(e, false)}
                              checked={
                                isEdit === i
                                  ? mslot.video === "Y"
                                    ? true
                                    : false
                                  : res.video === "Y"
                                  ? true
                                  : false
                              }
                              className="mr-2"
                            />
                            <label>Video</label>
                          </div>
                          &nbsp;
                          <div>
                            <input
                              readOnly={isEdit === i ? false : true}
                              type="checkbox"
                              name="audio"
                              onChange={(e) => onDayChange(e, false)}
                              checked={
                                isEdit === i
                                  ? mslot.audio === "Y"
                                    ? true
                                    : false
                                  : res.audio === "Y"
                                  ? true
                                  : false
                              }
                              className="mr-2"
                            />
                            <label>Audio</label>
                          </div>
                          &nbsp;
                          <div>
                            <input
                              readOnly={isEdit === i ? false : true}
                              type="checkbox"
                              name="audio"
                              onChange={(e) => onDayChange(e, false)}
                              checked={
                                isEdit === i
                                  ? mslot.inEclinic === "Y"
                                    ? true
                                    : false
                                  : res.inEclinic === "Y"
                                  ? true
                                  : false
                              }
                              className="mr-2"
                            />
                            <label>In Clinic</label>
                          </div>
                        </div>
                        <hr className="lg:hidden my-2" />
                        <div className="lg:flex justify-between">
                          <div>
                            <div className="lg:flex">
                              <div className="flex flex-wrap my-2 ml-2 text-brand-secondary gap-2 font-small">
                                <div className="mr-4 ">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="monday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.monday === "Y"
                                          ? true
                                          : false
                                        : res.monday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Mon</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="tuesday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.tuesday === "Y"
                                          ? true
                                          : false
                                        : res.tuesday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Tue</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="wednesday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.wednesday === "Y"
                                          ? true
                                          : false
                                        : res.wednesday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Wed</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="thursday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.thursday === "Y"
                                          ? true
                                          : false
                                        : res.thursday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Thu</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="friday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.friday === "Y"
                                          ? true
                                          : false
                                        : res.friday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Fri</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="saturday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.saturday === "Y"
                                          ? true
                                          : false
                                        : res.saturday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Sat</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="sunday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.sunday === "Y"
                                          ? true
                                          : false
                                        : res.sunday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Sun</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lg:flex gap-3 mr-3 mb-2">
                            {/* <button onClick={() => { setEdit(i);  }} className="cursor-pointer text-lg font-medium font-rubik text-brand-secondary rounded-md px-4 border border-brand-secondary" > Edit</button> */}
                            {/* <button
                              onClick={() => {
                                setModifySlot(res);
                                setDelete(res.id);
                              }}
                              className="cursor-pointer bg-brand-secondary w-full font-rubik text-md text-white font-normal rounded-md py-2 px-4"
                            >
                              {" "}
                              Make Inactive
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden opacity-50 lg:px-10 px-2 border rounded-2xl lg:mx-10 mx-2 lg:my-6 my-2">
                    <div className="flex">
                      <div class="w-full">
                        <br />
                        <div className="lg:flex justify-between">
                          <div className="lg:no-wrap flex lg:gap-0 gap-16">
                            {/* <p className="text-md text-gray-900 font-rubik pl-10 pt-10">Set you weekly hours</p> */}
                            <p className="text-md w-32 text-gray-900 font-rubik">
                              Start Date
                              <div className="flex overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                                {/* <p className=" text-sm font-rubik text-gray-700">01/02/2021</p><img src={calender} alt="calender" className="pl-8" /> */}
                                <DatePicker
                                  showMonthDropdown
                                  showYearDropdown
                                  disabled={isEdit === i ? false : true}
                                  dateFormat={"DD/MM/yyyy"}
                                  value={
                                    isEdit === i
                                      ? moment(mslot.fromDate).format(
                                          "DD/MM/yyyy"
                                        )
                                      : moment(res.fromDate).format(
                                          "DD/MM/yyyy"
                                        )
                                  }
                                  className="bg-transparent"
                                  onChange={(date) => {
                                    setModifySlot({
                                      ...mslot,
                                      ["fromDate"]:
                                        moment(date).format("MM/DD/yyyy"),
                                    });
                                  }}
                                />
                              </div>
                            </p>
                            <p className="text-md lg:w-44 w-32 text-gray-900 font-rubik lg:pl-10 ">
                              End Date
                              <div className="flex overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                                {/* <p className=" text-sm font-rubik text-gray-700">31/12/2021</p><img src={calender} alt="calender" className="pl-8" /> */}
                                <DatePicker
                                  disabled={isEdit === i ? false : true}
                                  showMonthDropdown
                                  showYearDropdown
                                  className="bg-transparent"
                                  dateFormat={"MM/DD/yyyy"}
                                  value={
                                    isEdit === i
                                      ? moment(mslot.toDate).format(
                                          "DD/MM/yyyy"
                                        )
                                      : moment(res.toDate).format("DD/MM/yyyy")
                                  }
                                  onChange={(date) => {
                                    setModifySlot({
                                      ...mslot,
                                      ["toDate"]:
                                        moment(date).format("MM/DD/yyyy"),
                                    });
                                  }}
                                />
                              </div>
                            </p>
                          </div>
                          <div className="lg:no-wrap flex lg:mt-0 mt-2 lg:gap-0 gap-16">
                            <p className="text-md w-32  text-gray-900 font-rubik">
                              Start Time
                              <div className="flex overflow-hidden p-2">
                                <input
                                  autocomplete="off"
                                  id="fromTime"
                                  name="fromTime"
                                  type="text"
                                  disabled={isEdit === i ? false : true}
                                  className="w-full px-2 h-9 peer border-2 rounded-md"
                                  placeholder=""
                                  value={
                                    isEdit === i ? mslot.fromTime : res.fromTime
                                  }
                                  onChange={modifySlot}
                                />
                              </div>
                            </p>
                            <p className="text-md w-32  text-gray-900 font-rubik">
                              End Time
                              <div className="flex overflow-hidden p-2">
                                <input
                                  autocomplete="off"
                                  id="toTime"
                                  name="toTime"
                                  type="text"
                                  disabled={isEdit === i ? false : true}
                                  className="w-full px-2 h-9 peer border-2 rounded-md"
                                  placeholder=""
                                  value={
                                    isEdit === i ? mslot.toTime : res.toTime
                                  }
                                  onChange={modifySlot}
                                />
                              </div>
                            </p>
                          </div>
                          {/* <div className="lg:mt-0 mt-2"> */}
                          <p className="text-md text-gray-900 font-rubik lg:pl-10 lg:mt-0 mt-2">
                            Duration
                            <div className="flex lg:w-32 w-32 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                              <select
                                value={res.consultationDuration}
                                disabled={isEdit === i ? false : true}
                                name="consultationDuration"
                                className="w-full"
                                onChange={handleChange}
                              >
                                <option value="-1">{"Select Duration"}</option>
                                {consultationArr.map((res, i) => (
                                  <option key={i} value={res}>
                                    {res}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </p>

                          <p className="text-md text-gray-900 font-rubik lg:pl-10">
                            Hospital
                            <div className="flex w-32 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                              <select
                                disabled={isEdit === i ? false : true}
                                value={res.hospitalId}
                                name="hospitalId"
                                className="w-full"
                                onChange={(e) => {
                                  selectHospital(e.target.value, false);
                                }}
                              >
                                <option disabled value={"-1"}>
                                  {"select"}
                                </option>
                                {hospitalcliniclistData.map((res, i) => (
                                  <option key={i} value={res.hospitalId}>
                                    {res.hospitalName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </p>

                          {/* </div> */}
                        </div>
                        <div className="flex flex-wrap mt-2 ml-2 text-brand-secondary gap-2 font-small">
                          <div>
                            <input
                              readOnly={isEdit === i ? false : true}
                              type="checkbox"
                              name="inPerson"
                              onChange={(e) => onDayChange(e, false)}
                              checked={
                                isEdit === i
                                  ? mslot.inPerson === "Y"
                                    ? true
                                    : false
                                  : res.inPerson === "Y"
                                  ? true
                                  : false
                              }
                              className="mr-2"
                            />
                            <label>In-Person</label>
                          </div>
                          <div>
                            <input
                              readOnly={isEdit === i ? false : true}
                              type="checkbox"
                              name="quickConsultation"
                              onChange={(e) => onDayChange(e, false)}
                              checked={
                                isEdit === i
                                  ? mslot.quickConsultation === "Y"
                                    ? true
                                    : false
                                  : res.quickConsultation === "Y"
                                  ? true
                                  : false
                              }
                              className="mr-2"
                              disabled={
                                userData.quickConsulted == 1 ? false : true
                              }
                            />
                            <label>Quick</label>
                          </div>
                          &nbsp;
                          <div>
                            <input
                              readOnly={isEdit === i ? false : true}
                              type="checkbox"
                              name="video"
                              onChange={(e) => onDayChange(e, false)}
                              checked={
                                isEdit === i
                                  ? mslot.video === "Y"
                                    ? true
                                    : false
                                  : res.video === "Y"
                                  ? true
                                  : false
                              }
                              className="mr-2"
                            />
                            <label>Video</label>
                          </div>
                          &nbsp;
                          <div>
                            <input
                              readOnly={isEdit === i ? false : true}
                              type="checkbox"
                              name="audio"
                              onChange={(e) => onDayChange(e, false)}
                              checked={
                                isEdit === i
                                  ? mslot.audio === "Y"
                                    ? true
                                    : false
                                  : res.audio === "Y"
                                  ? true
                                  : false
                              }
                              className="mr-2"
                            />
                            <label>Audio</label>
                          </div>
                        </div>
                        <hr className="lg:hidden my-2" />
                        <div className="lg:flex justify-between">
                          <div>
                            <div className="lg:flex">
                              <div className="flex flex-wrap my-2 flex-wrap ml-2 text-brand-secondary gap-2 font-small">
                                <div className="mr-4 ">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="monday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.monday === "Y"
                                          ? true
                                          : false
                                        : res.monday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Mon</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="tuesday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.tuesday === "Y"
                                          ? true
                                          : false
                                        : res.tuesday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Tue</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="wednesday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.wednesday === "Y"
                                          ? true
                                          : false
                                        : res.wednesday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Wed</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="thursday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.thursday === "Y"
                                          ? true
                                          : false
                                        : res.thursday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Thu</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="friday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.friday === "Y"
                                          ? true
                                          : false
                                        : res.friday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Fri</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="saturday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.saturday === "Y"
                                          ? true
                                          : false
                                        : res.saturday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Sat</label>
                                </div>
                                <div className="mr-4">
                                  <input
                                    readOnly={isEdit === i ? false : true}
                                    type="checkbox"
                                    name="sunday"
                                    onChange={(e) => onDayChange(e, false)}
                                    checked={
                                      isEdit === i
                                        ? mslot.sunday === "Y"
                                          ? true
                                          : false
                                        : res.sunday === "Y"
                                        ? true
                                        : false
                                    }
                                    className="mr-2"
                                  />
                                  <label>Sun</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lg:flex gap-3 mr-3 mb-2">
                            {/* <button onClick={() => { setEdit(i);  }} className="cursor-pointer text-lg font-medium font-rubik text-brand-secondary rounded-md px-4 border border-brand-secondary" > Edit</button> */}
                            <button className="cursor-pointer bg-gray-400 w-full font-rubik text-md text-white font-normal rounded-md py-2 px-4">
                              Inactive
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          {isLoading && slotList.length === 0 && (
            <div className="flex flex-wrap justify-center mt-5">
              <div className="loader float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-20 w-20" />
            </div>
          )}
        </div>
        {/* add new slots */}
        {/* <div className="mt-4 justify-center w-full">
          <p className="text-xl text-gray-900 font-medium font-rubik">
            Set your availability
          </p>
          <br />
          <div className="lg:flex rounded-2xl border p-4 px-2 md:px-6 lg:px-20  mb-16">
            <div className="w-full">
              <div className="lg:flex justify-between">
                <div className=" flex-wrap lg:no-wrap flex lg:gap-0 gap-2">
                  <p className="text-md w-44 text-gray-900 font-rubik">
                    Start Date
                    <div className="flex overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                      <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        minDate={moment().toDate()}
                        maxDate={slot.toDate}
                        dateFormat={"MM/DD/yyyy"}
                        value={
                          slot.fromDate
                            ? moment(slot.fromDate).format("DD/MM/yyyy")
                            : ""
                        }
                        className="bg-transparent"
                        onChange={(date) => {
                          setslot({
                            ...slot,
                            ["fromDate"]: moment(date).format("MM/DD/yyyy"),
                          });
                          console.log("date", date);
                        }}
                        placeholderText="Select Start Date"
                      />
                    </div>
                  </p>
                  <p className="text-md w-44 text-gray-900 font-rubik lg:pl-10 ">
                    End Date
                    <div className="flex overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                      <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        minDate={moment(slot.fromDate).toDate()}
                        className="bg-transparent"
                        dateFormat={"MM/DD/yyyy"}
                        value={
                          slot.toDate
                            ? moment(slot.toDate).format("DD/MM/yyyy")
                            : ""
                        }
                        onChange={(date) => {
                          setslot({
                            ...slot,
                            ["toDate"]: moment(date).format("MM/DD/yyyy"),
                          });
                        }}
                        placeholderText="Select End Date"
                      />
                    </div>
                  </p>
                </div>
                <div className="lg:no-wrap flex lg:gap-0 gap-16">
                  <p className="text-md w-32 text-gray-900 font-rubik lg:mt-0 mt-2">
                    Start Time
                    <div className="flex overflow-hidden p-2">
                      <Calendar
                        timeOnly
                        showTime
                        hourFormat="24"
                        name="fromTime"
                        className="w-full px-2"
                        value={fromTime}
                        onChange={(e) => setFromTime(e.value)}
                      ></Calendar>
                    </div>
                  </p>
                  <p className="text-md w-32 text-gray-900 font-rubik lg:mt-0 mt-2">
                    End Time
                    <div className="flex overflow-hidden p-2">
                      <Calendar
                        timeOnly
                        showTime
                        hourFormat="24"
                        name="toTime"
                        className="w-full px-2"
                        value={toTime}
                        onChange={(e) => setToTime(e.value)}
                      ></Calendar>
                    </div>
                  </p>
                </div>

                <p className="text-md  text-gray-900 font-rubik lg:pl-10 lg:mt-0 mt-2">
                  Duration
                  <div className="flex lg:w-32 w-32 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                    <select
                      value={slot.consultationDuration}
                      name="consultationDuration"
                      className="w-full"
                      onChange={handleChange}
                    >
                      <option value="-1">{"Select Duration"}</option>
                      {consultationArr.map((res, i) => (
                        <option key={i} value={res}>
                          {res}
                        </option>
                      ))}
                    </select>
                  </div>
                </p>

                <p className="text-md  text-gray-900 font-rubik lg:pl-10 lg:mt-0 mt-2">
                  Hospital
                  <div className="flex lg:w-32 w-32 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                    <select
                      value={selectedHospital}
                      name="hospitalId"
                      className="w-full"
                      onChange={(e) => {
                        selectHospital(e.target.value, true);
                      }}
                    >
                      <option value="-1">{"Select Hospital"}</option>
                      {hospitalcliniclistData.map((res, i) => (
                        <option key={i} value={JSON.stringify(res)}>
                          {res.hospitalName}
                        </option>
                      ))}
                    </select>
                  </div>
                </p>

                {slot.inEclinic === "Y" && (
                  <p className="text-md text-gray-900 font-rubik lg:pl-10 lg:mt-0 mt-2">
                    Select E-Clinic
                    <div className="flex lg:w-32 w-32 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl p-2">
                      <select
                        name="eclinicId"
                        className="w-full"
                        onChange={(e) => {
                          selectEclinic(e.target.value, true);
                        }}
                      >
                        <option value="">Select Eclinic</option>
                        {getselectedEclinic.map((eclinic, i) => (
                          <option
                            key={i}
                            value={`${eclinic.locationName}|${eclinic.hospitalCode}`}
                          >
                            {eclinic.locationName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </p>
                )}
              </div>
              <div className="flex flex-wrap mt-2 ml-2 md:ml-0 lg:ml-2 text-brand-secondary gap-2 font-small">
                <div>
                  <input
                    type="checkbox"
                    name="inPerson"
                    onChange={(e) => onDayChange(e, true)}
                    checked={slot.inPerson === "Y" ? true : false}
                    className="mr-2"
                  />
                  <label>In-Person</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="quickConsultation"
                    onChange={(e) => onDayChange(e, true)}
                    checked={slot.quickConsultation === "Y" ? true : false}
                    className="mr-2"
                    disabled={userData.quickConsulted == 1 ? false : true}
                  />
                  <label>Quick</label>
                </div>
                &nbsp;
                <div>
                  <input
                    type="checkbox"
                    name="video"
                    onChange={(e) => onDayChange(e, true)}
                    checked={slot.video === "Y" ? true : false}
                    className="mr-2"
                  />
                  <label>Video</label>
                </div>
                &nbsp;
                <div>
                  <input
                    type="checkbox"
                    name="audio"
                    onChange={(e) => onDayChange(e, true)}
                    checked={slot.audio === "Y" ? true : false}
                    className="mr-2"
                  />
                  <label>Audio</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="inEclinic"
                    onChange={(e) => onDayChange(e, true)}
                    checked={slot.inEclinic === "Y" ? true : false}
                    className="mr-2"
                  />
                  <label>In Clinic</label>
                </div>
              </div>

              <hr className="lg:hidden my-2" />
              <div className="flex mt-2 ml-2 md:mt-0 md:ml-0 justify-between">
                <div>
                  <div className="lg:flex items-center">
                    <div className="flex my-0 lg:ml-2 flex-wrap text-brand-secondary gap-2 font-small">
                      <div className="mr-4 ">
                        <input
                          type="checkbox"
                          name="monday"
                          onChange={(e) => onDayChange(e, true)}
                          checked={slot.monday === "Y" ? true : false}
                          className="mr-2"
                        />
                        <label>Mon</label>
                      </div>
                      <div className="mr-4">
                        <input
                          type="checkbox"
                          name="tuesday"
                          onChange={(e) => onDayChange(e, true)}
                          checked={slot.tuesday === "Y" ? true : false}
                          className="mr-2"
                        />
                        <label>Tue</label>
                      </div>
                      <div className="mr-4">
                        <input
                          type="checkbox"
                          name="wednesday"
                          onChange={(e) => onDayChange(e, true)}
                          checked={slot.wednesday === "Y" ? true : false}
                          className="mr-2"
                        />
                        <label>Wed</label>
                      </div>
                      <div className="mr-4">
                        <input
                          type="checkbox"
                          name="thursday"
                          onChange={(e) => onDayChange(e, true)}
                          checked={slot.thursday === "Y" ? true : false}
                          className="mr-2"
                        />
                        <label>Thu</label>
                      </div>
                      <div className="mr-4">
                        <input
                          type="checkbox"
                          name="friday"
                          onChange={(e) => onDayChange(e, true)}
                          checked={slot.friday === "Y" ? true : false}
                          className="mr-2"
                        />
                        <label>Fri</label>
                      </div>
                      <div className="mr-4">
                        <input
                          type="checkbox"
                          name="saturday"
                          onChange={(e) => onDayChange(e, true)}
                          checked={slot.saturday === "Y" ? true : false}
                          className="mr-2"
                        />
                        <label>Sat</label>
                      </div>
                      <div className="mr-4">
                        <input
                          type="checkbox"
                          name="sunday"
                          onChange={(e) => onDayChange(e, true)}
                          checked={slot.sunday === "Y" ? true : false}
                          className="mr-2"
                        />
                        <label>Sun</label>
                      </div>
                    </div>
                    <div className="flex justify-start">
                      <button
                        onClick={addSlotbutton}
                        className="bg-brand-secondary font-rubik text-white py-2 px-4 font-small rounded-lg"
                      >
                        Add new Slots
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showslotspopup ? <Successfulslotpopup></Successfulslotpopup> : null}
        </div> */}
      </div>
      {isDelete ? (
        <div className="justify-center mx-2 items-center flex overflow-x-hidden bg-gray-100 opacity-90 fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto  mx-auto max-w-3xl opacity-100 ">
            <div className="border-0 rounded-lg shadow-2xl relative p-10 flex flex-col w-full bg-white opacity-100 outline-none focus:outline-none">
              <span>Do you want to deactivate the published slot ?</span>
              <div className="flex justify-center gap-4 mt-4">
                <button
                  onClick={() => setDelete("")}
                  className="cursor-pointer text-lg font-small border border-brand-secondary px-4 font-rubik text-brand-secondary rounded-md  "
                >
                  No
                </button>
                <button
                  onClick={() => deleteSlot()}
                  className="cursor-pointer bg-brand-secondary  font-rubik text-md text-white font-normal rounded-md py-2 px-4"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
export default DoctorSlotStageOne;
