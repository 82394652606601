import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import { Tag } from "primereact/tag";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import userprofileservice from "../Redux/services/userprofileservice";
import { Dropdown } from "primereact/dropdown";
import { Accordion, AccordionTab } from "primereact/accordion";
function PaymentAndHistoryInventory() {
    const history = useHistory();
    const dispatch = useDispatch();
    const loggedInData = useSelector((state) => state.authReducer);
    const { userData } = loggedInData;
    const [sideBarData, setSideBarData] = useState()
    const [selectedList, setSelectedList] = useState(1)
    const [selectedStatus, setStatus] = useState("A")
    const [sideDialog, setSideDialog] = useState(false);
    const [payemntData, setPaymentData] = useState()
    const [fromDate, setFromDate] = useState(moment().format("yyyy-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));
    useEffect(() => {
        setPaymentData()
        let data = {
            status: selectedStatus,
            userid: userData.code,
            fromDate: fromDate,
            toDate: toDate,
            type: 0
        }
        userprofileservice.getDoctorPayemntDetails(data).then((res) => {
            setPaymentData(res?.data?.data)
        })
    }, [selectedStatus, fromDate, toDate])
    const onSideBarView = (content) => {
        setSideBarData()
        let data = {
            userId: userData.code,
            fromDate: moment(content.fromDate).format("MM/DD/yyyy"),
            toDate: moment(content.toDate).format("MM/DD/yyyy")
        }
        data.type = userData.quickConsulted
        userprofileservice.getDoctorPaymentAmountDetails(data).then((res) => {
            if (res.data) {
                setSideBarData(res?.data?.data)
                setSideDialog(true)
            }
        })
    }
    const ontabChange = (value) => {
        setSelectedList(value)
        if (value === 1) {
            setStatus("A")
        } else {
            setStatus("R")
        }
    }
    const accHeader = (tabContent) => {
        return <>
            <div style={{ display: "flex", marginTop: "7px" }}>
                {/* <div style={{ width: "150px", textAlign: "left" }}>
                    <label>{tabContent.appointmentId}</label>
                </div> */}
                <div style={{ width: "150px", textAlign: 'center' }}>
                    <label>{moment(tabContent.transactionDate).format("MM/DD/yyyy")}</label>
                </div>
                {/* <div style={{ width: "150px", textAlign: "right" }}>
                    <Tag value={tabContent.status === "A" ? 'Approved' : "Rejected"} severity={tabContent.status === "A" ? "success" : "error"}></Tag>
                </div> */}
            </div>
        </>
    }
    return (
        <>
            <ToastContainer />
            <div className="w-full lg:mr-16 lg:mt-5 ">
                <div style={{ height: "700px" }} className="rounded-lg shadow-lg  bg-white-600 w-full align-center p-5 lg:mb-2 mb-16 antialiased justify-between border border-gray-200 mt-2">
                    <div className="flex mb-4">
                        <button
                            type="submit"
                            onClick={() => ontabChange(1)}
                            className={selectedList == 1 ? `bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2` : `bg-white font-rubik text-sm text-brand-secondary font-normal rounded-md py-2 px-3 border border-brand-secondary mr-2`}
                        >
                            Payment Released
                        </button>
                        <button
                            type="submit"
                            onClick={() => ontabChange(2)}
                            className={selectedList == 2 ? `bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2 ml-4` : `bg-white font-rubik text-sm text-brand-secondary font-normal rounded-md py-2 px-3 border border-brand-secondary mr-2 ml-4`}
                        >
                            payment Pending
                        </button>
                        <div className="flex border border-brand-secondary mr-2 ml-4 rounded-md">
                            <DatePicker
                                placeholderText="From Date"
                                value={fromDate ? moment(fromDate).format("yyyy-MM-DD") : ""}
                                className="bg-transparent text-center w-32 mt-2"
                                onChange={(date) => {
                                    setFromDate(moment(date).format("yyyy-MM-DD"));
                                }}
                            />
                            <div className="mt-2">-</div>
                            <DatePicker
                                placeholderText="To Date"
                                value={toDate ? moment(toDate).format("yyyy-MM-DD") : ""}
                                className="bg-transparent text-center w-32 mt-2"
                                onChange={(date) => {
                                    setToDate(moment(date).format("yyyy-MM-DD"));
                                }}
                            />
                        </div>
                        <div className="border border-brand-secondary mr-2 ml-4 rounded-md">
                            {selectedList === 1 ?
                                <Dropdown
                                    value={selectedStatus}
                                    onChange={(e) => setStatus(e.value)}
                                    options={[
                                        { name: "Paid", value: "P" },
                                        { name: "Approved", value: "A" }
                                    ]}
                                    optionLabel="name"
                                    placeholder="Select Status"
                                    className="w-44 mt-1 border border-brand-secondary mr-2 ml-2 rounded-md"
                                />
                                :
                                <Dropdown
                                    value={selectedStatus}
                                    onChange={(e) => setStatus(e.value)}
                                    options={[
                                        { name: "Failed", value: "F" },
                                        { name: "Rejected", value: "R" },
                                        { name: "Pending", value: "PN" }
                                    ]}
                                    optionLabel="name"
                                    placeholder="Select Status"
                                    className="w-44 mt-1 border border-brand-secondary mr-2 ml-2 rounded-md"
                                />
                            }
                        </div>
                    </div>
                    <DataTable
                        value={payemntData}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 25, 50]}
                    >
                        <Column
                            header="Doctor Name"
                            body={(rowbody) => (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ display: "block", marginLeft: "15px" }}>
                                            <div style={{ fontSize: "15px" }}>
                                                Dr. {" "} {userData.firstName}{" "}
                                                {userData.lastName}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        ></Column>
                        <Column
                            field="txnId"
                            header="Transaction ID"
                            body={(rowbody) => (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ display: "block", marginLeft: "15px" }}>
                                            <div style={{ fontSize: "15px" }}>
                                                {rowbody.txnId ? rowbody.txnId : "NA"}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        ></Column>
                        <Column
                            field="toBePaid"
                            header="Total Amount"
                            body={(rowbody) => (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ display: "block", marginLeft: "15px" }}>
                                            <div style={{ fontSize: "15px" }}>
                                                {rowbody.toBePaid.toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        ></Column>
                        <Column
                            header="Payment Date & Time"
                            body={(rowbody) => (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ display: "block", marginLeft: "15px" }}>
                                            <div style={{ fontSize: "15px" }}>
                                                {moment(rowbody.createdDate).format("MM/DD/yyyy")}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        ></Column>
                        <Column
                            field="totalDeduction"
                            header="Commission"
                            body={(rowbody) => (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ display: "block", marginLeft: "15px" }}>
                                            <div style={{ fontSize: "15px" }}>
                                                {rowbody.totalDeduction.toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        ></Column>
                        {/* <Column
                            field="serviceCode"
                            header="Service Code"
                        ></Column> */}
                        <Column
                            field="remark"
                            header="Remark"
                        ></Column>
                        <Column
                            header="Action"
                            body={(rowbody) => (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ fontSize: "12px" }}>
                                            <button
                                                type="submit"
                                                onClick={() => onSideBarView(rowbody)}
                                                className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-6 mr-2"
                                            >
                                                View
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        ></Column>
                    </DataTable>
                </div>
            </div >
            <Sidebar
                visible={sideDialog}
                position="right"
                onHide={() => setSideDialog(false)}
                style={{ width: "580px" }}
            >
                <>
                    {/* {sideBarData && */}
                    <div className="">
                        <div>
                            <h1 style={{ color: "black", marginTop: "0px" }}>
                                {" "}
                                Payment Breakdown {" "}
                            </h1>
                        </div>
                        <div className="mt-10 mb-10">
                            <h3 style={{ color: "black", marginTop: "20px", marginBottom: "20px" }}>
                                Dr. {" "} {userData.firstName}{" "}
                                {userData.lastName}
                            </h3>
                        </div>
                        <div className="mt-3">
                            {sideBarData &&
                                Object.entries(sideBarData).map(
                                    ([key, dataArray], keyIndex) => (
                                        <Accordion key={keyIndex}>
                                            <AccordionTab
                                                header={
                                                    key === "Y" ? "Camp Data" : "Consultation Data"
                                                }
                                            >
                                                <Accordion>
                                                    {dataArray.map((tab, index) => (
                                                        <AccordionTab
                                                            key={index}
                                                            header={accHeader(tab)} >
                                                            <h3 className="my-2">Payment Breakdown</h3>
                                                            <hr />
                                                            <>
                                                                {/* <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                                                                    <div style={{ width: "50%", textAlign: "left" }}>
                                                                        <label>Status</label>
                                                                    </div>
                                                                    <div style={{ width: "50%", textAlign: "right" }}>
                                                                        <Tag value={tab.status === "A" ? 'Approved' : "Rejected"} severity={tab.status === "A" ? "success" : "error"}></Tag>
                                                                    </div>
                                                                </div> */}
                                                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                                                                    <div style={{ width: "50%", textAlign: "left" }}>
                                                                        <label>Appiontment Id</label>
                                                                    </div>
                                                                    <div style={{ width: "50%", textAlign: "right" }}>
                                                                        {tab.appointmentId}
                                                                    </div>
                                                                </div>
                                                                {tab.commissionPercentage &&
                                                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                                                                        <div style={{ width: "50%", textAlign: "left" }}>
                                                                            <label>Commission Percentage</label>
                                                                        </div>
                                                                        <div style={{ width: "50%", textAlign: "right" }}>
                                                                            {tab.commissionPercentage}%
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                                                                    <div style={{ width: "50%", textAlign: "left" }}>
                                                                        <label>Consultation Amount</label>
                                                                    </div>
                                                                    <div style={{ width: "50%", textAlign: "right" }}>
                                                                        INR {tab.consultationAmount}
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                                                                    <div style={{ width: "50%", textAlign: "left" }}>
                                                                        <label>Transaction Number</label>
                                                                    </div>
                                                                    <div style={{ width: "50%", textAlign: "right" }}>
                                                                        {tab.transactionNumber}
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                                                                    <div style={{ width: "50%", textAlign: "left" }}>
                                                                        <label>Transaction Date</label>
                                                                    </div>
                                                                    <div style={{ width: "50%", textAlign: "right" }}>
                                                                        {moment(tab.transactionDate).format("MM/DD/yyyy")}
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                                                                    <div style={{ width: "50%", textAlign: "left" }}>
                                                                        <label>Total Amount</label>
                                                                    </div>
                                                                    <div style={{ width: "50%", textAlign: "right" }}>
                                                                        INR {tab.toBePaid}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </AccordionTab>
                                                    ))}
                                                </Accordion>
                                            </AccordionTab>
                                        </Accordion>
                                    )
                                )}
                        </div >
                    </div>
                    {/* } */}
                </>
            </Sidebar>
        </>
    );
}
export default PaymentAndHistoryInventory;
