export const PAT = {

    REQUEST_PATIENTLIST: "REQUEST_PATIENTLIST",
    SUCCESS_PATIENTLIST: "SUCCESS_PATIENTLIST",
    FAILED_PATIENTLIST: "FAILED_PATIENTLIST",

    REQUEST_PATIENTFAMILYMEMBERLIST: "REQUEST_PATIENTFAMILYMEMBERLIST",
    SUCCESS_PATIENTFAMILYMEMBERLIST: "SUCCESS_PATIENTFAMILYMEMBERLIST",
    FAILED_PATIENTFAMILYMEMBERLIST: "FAILED_PATIENTFAMILYMEMBERLIST",


    REQUEST_APPOINTMENTLIST: "REQUEST_APPOINTMENTLIST",
    SUCCESS_APPOINTMENTLIST: "SUCCESS_APPOINTMENTLIST",
    FAILED_APPOINTMENTLIST: "FAILED_APPOINTMENTLIST",

    REQUEST_APPOINTMENTCOUNT: "REQUEST_APPOINTMENTCOUNT",
    SUCCESS_APPOINTMENTCOUNT: "SUCCESS_APPOINTMENTCOUNT",
    FAILED_APPOINTMENTCOUNT: "FAILED_APPOINTMENTCOUNT",

    REQUEST_PATIENTVITALSDETAILS: "REQUEST_PATIENTVITALSDETAILS",
    SUCCESS_PATIENTVITALSDETAILS: "SUCCESS_PATIENTVITALSDETAILS",
    FAILED_PATIENTVITALSDETAILS: "FAILED_PATIENTVITALSDETAILS",

    REQUEST_APPOINTMENTCOUNTS: "REQUEST_APPOINTMENTCOUNTS",
    SUCCESS_APPOINTMENTCOUNTS: "SUCCESS_APPOINTMENTCOUNTS",
    FAILED_APPOINTMENTCOUNTS: "FAILED_APPOINTMENTCOUNTS",

    REQUEST_UPCOMINGAPPOINTMENTLIST: "REQUEST_UPCOMINGAPPOINTMENTLIST",
    SUCCESS_UPCOMINGAPPOINTMENTLIST: "SUCCESS_UPCOMINGAPPOINTMENTLIST",
    FAILED_UPCOMINGAPPOINTMENTLIST: "FAILED_UPCOMINGAPPOINTMENTLIST",

    REQUEST_UPCOMINGFILTEREDAPPOINTMENTLIST: "REQUEST_UPCOMINGFILTEREDAPPOINTMENTLIST",
    SUCCESS_UPCOMINGFILTEREDAPPOINTMENTLIST: "SUCCESS_UPCOMINGFILTEREDAPPOINTMENTLIST",
    FAILED_UPCOMINGFILTEREDAPPOINTMENTLIST: "FAILED_UPCOMINGFILTEREDAPPOINTMENTLIST",



    REQUEST_UPCOMINGAPPOINTMENTLISTFILTERHOSPITAL: "REQUEST_UPCOMINGAPPOINTMENTLISTFILTERHOSPITAL",
    SUCCESS_UPCOMINGAPPOINTMENTLISTFILTERHOSPITAL: "SUCCESS_UPCOMINGAPPOINTMENTLISTFILTERHOSPITAL",
    FAILED_UPCOMINGAPPOINTMENTLISTFILTERHOSPITAL: "FAILED_UPCOMINGAPPOINTMENTLISTFILTERHOSPITAL",


    REQUEST_HOSPITALCLINICLIST: "REQUEST_HOSPITALCLINICLIST",
    SUCCESS_HOSPITALCLINICLIST: "SUCCESS_HOSPITALCLINICLIST",
    FAILED_HOSPITALCLINICLIST: "FAILED_HOSPITALCLINICLIST",

    REQUEST_PATIENTPHRLIST: "REQUEST_PATIENTPHRLIST",
    SUCCESS_PATIENTPHRLIST: "SUCCESS_PATIENTPHRLIST",
    FAILED_PATIENTPHRLIST: "FAILED_PATIENTPHRLIST",

    REQUEST_PATIENTDOCUMENTS: "REQUEST_PATIENTDOCUMENTS",
    SUCCESS_PATIENTDOCUMENTS: "SUCCESS_PATIENTDOCUMENTS",
    FAILED_PATIENTDOCUMENTS: "FAILED_PATIENTDOCUMENTS",

    REQUEST_POST_CONSULT: "REQUEST_POST_CONSULT",
    SUCCESS_POST_CONSULT: "SUCCESS_POST_CONSULT",
    FAILED_POST_CONSULT: "FAILED_POST_CONSULT"


}
