/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, ShoppingCartIcon, MenuIcon, XIcon, UserCircleIcon } from '@heroicons/react/outline'
import Ambulance from '../Assets/Images/ambulance@2x.svg';
import Sidebar from './profileSidebar';
import profile from '../Assets/Images/profile-1@2x.svg';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import newLogo from '../Assets/Images/newLogo.png'
import { Logout } from '../Redux/Actions/userActions';

const navigation = [
  { name: 'Dashboard', href: APP_ROUTES.DASHBOARD, current: true },
  { name: 'Appointments', href: APP_ROUTES.APPOINTMENT_TWO, current: false },
  { name: 'Patient', href: APP_ROUTES.PATIENTS_LISTING, current: false },
  { name: 'Profile', href: APP_ROUTES.DOCTOR_PROFILE, current: false },
  { name: 'Slots', href: APP_ROUTES.DOCTOR_SLOT_STAGE_ONE, current: false },
  { name: 'Payment History', href: APP_ROUTES.PAYMENT_HISTORY, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



const BrandImage = () => {
  const history = useHistory();
  return (<img
    className="block h-12 w-auto cursor-pointer"
    src={newLogo}
    alt="Workflow"
    onClick={() => history.push('/')}
  />
  )
}

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const UserData = useSelector(state => state.authReducer.userData);
  const [isOpen, setOpen] = useState(-150);
  const [showHeader, setShowHeadr] = useState(false);
  const modalRef = useRef();
  let location = useLocation();

  const loggedInData = useSelector((state) => state.authReducer);

  const { patientSession } = loggedInData;

  const logout = (e) => {
    if (!UserData.id || location.pathname.split('/')[1] == "videoCall") {
    } else {
      localStorage.clear();
      let dataObj = {
        sessionId: patientSession?.id,
        userCode: patientSession?.patientCode
      }
      dispatch(Logout(dataObj));
      history.push(APP_ROUTES.Login)
    }
  };


  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location)
  }
  console.log("userData", UserData)
  console.log("props", props)

  const handleSidebarClick = (event) => {
    // event.preventDefault();
    const classname = JSON.stringify(event.target.className);
    const node = event.target.nodeName;
    console.log(node, "sdlkvsdjb")
    if (node == "svg" || node == "path") {
      console.log("Im herere")
      return;
    } else
      if (!classname.includes("sidebarr") && !classname.includes("submenu") && isOpen == 0) {

        setOpen(-150);
      }
  }

  useEffect(() => {
    window.addEventListener('click',
      handleSidebarClick
    )
    return () => {
      window.removeEventListener('click',
        handleSidebarClick)
    }
  }, [handleSidebarClick])

  useEffect(() => {
    setShowHeadr(localStorage.getItem('loginObj') ? true : false);
  }, [props])

  if (!UserData.id || location.pathname.split('/')[1] == "sharecall") {
    return (
      <div></div>
    )
  }

  return (
    <>
      {<Disclosure as="nav" className="bg-brand-lightblue  sticky top-0 z-50">
        {({ open }) => (
          <>
            <div className="mx-auto sm:px-0 md:px-0 lg:px-6">
              <div className={`relative flex items-center justify-between h-16 lg:shadow-none ${location.pathname === APP_ROUTES.DOCTOR_SEARCH ? 'shadow-none' : 'shadow-lg'}`}>
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/*Mobile menu button*/}
                  <Disclosure.Button className=" menuIcon mainMenum inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {isOpen === 0 ? (
                      <XIcon onClick={() => { setOpen(-150) }} className=" menuIcon block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon onClick={() => { setOpen(0) }} className="menuIcon block h-6 w-6" aria-hidden="true" />
                    )}
                    {/* history.push(APP_ROUTES.SIDEBAR);; */}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center hidden sm:block">
                  <BrandImage />
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-center">
                  <div className="flex-shrink-0 flex items-center sm:hidden">
                    <BrandImage />
                  </div>
                  <div className="lg:block hidden sm:block lg:ml-16 md:ml-2">
                    <div className="flex lg:space-x-8  md:space-x-2">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current ? '' : '',
                            `${location.pathname === item.href && 'text-brand-lightgreen'} px-3 py-2 rounded-md hover:text-brand-lightgreen font-medium text-brand-primary font-rubik text-base`
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                          <p className={classNames(
                            item.current ? '' : '', `${location.pathname === item.href && 'border-brand-lightgreen  border w-7 m-auto'} border border-transparent hover:border hover:border-brand-lightgreen w-7 m-auto`)}></p>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto ml-6 sm:ml-0 lg:ml-6 sm:pr-0 space-x-4">
                  <div className="flex justify-between items-center lg:space-x-3 left-4 relative">
                    {UserData.id && (
                      <div>
                        <Menu
                          as="div"
                          className=" block relative"
                        >
                          {({ open }) => (
                            <>
                              <div>
                                <Menu.Button
                                  className={` p-2 flex text-sm rounded-full focus:outline-none`}
                                >
                                  <span className="sr-only">Open menu</span>
                                  <BellIcon
                                    className="h-7 w-7 text-brand-primary"
                                    onClick={(e) =>
                                      redirectTo(
                                        e,
                                        APP_ROUTES.NOTIFICATIONS
                                      )
                                    }
                                  />
                                </Menu.Button>
                              </div>
                            </>
                          )}
                        </Menu>
                      </div>
                    )}
                  </div>
                  <div>
                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 sm:ml-0 lg:ml-3  relative">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className={`${!UserData.id && 'hidden'} mr-2 sm:p-0 flex text-sm rounded-full focus:outline-none`}>
                              <span className="sr-only">Open menu</span>
                              {/* <img src={`${process.env.REACT_APP_IMG_BASEURL + UserData.photoName}`} alt="" width="70" height="70"   className="profileRound"
                                style={{ borderRadius: "50%" }} /> */}
                              {UserData.photoName ? (
                                <div>
                                  <img
                                    src={`${process.env.REACT_APP_IMG_BASEURL + UserData.photoName}`}
                                    alt="profile img"
                                    className="profileRound"
                                    style={{ borderRadius: "50%" }}
                                  />
                                </div>
                              ) : (
                                <div className="bg-white p-3 w-14 flex justify-center font-rubik  rounded-lg">
                                  <span className="font-bold text-2xl justify-center rounded-lg">
                                    {UserData.firstName.charAt(0)}
                                  </span>
                                </div>
                              )}
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to={APP_ROUTES.DOCTOR_PROFILE}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    Your Profile
                                  </Link>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <Link

                                    onClick={() => { logout() }}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    Sign out
                                  </Link>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>

            </div>
            <div className="flex space-x-4" style={{ transform: `translate(${isOpen}%)`, transition: '0.1s', position: 'absolute', zIndex: '1000', width: '100%', height: '50vh' }} >

              <Sidebar />
            </div>
          </>

        )
        }

      </Disclosure >

      }
    </>
  )
}

export default Header;