export const VITALS = {
   
    REQUEST_VITALSLIST: "REQUEST_VITALSLIST",
    SUCCESS_VITALSLIST: "SUCCESS_VITALSLIST",
    FAILED_VITALSLIST: "FAILED_VITALSLIST",


    REQUEST_ADDVITALS: "REQUEST_ADDVITALS",
    SUCCESS_ADDVITALS: "SUCCESS_ADDVITALS",
    FAILED_ADDVITALS: "FAILED_ADDVITALS",
   
}
