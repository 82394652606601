import http from "./http-common";

class PriscriptionService {

  MedicineDrugList() {
    return http.get(`DrugsInfo/list`)
  }

  AddPrescription(data) {
    return http.post(`Visit/`, data)
  }
  UpdatePrescription(data) {
    return http.put(`Visit/${data.id}`, data)
  }
  doctorList() {
    return http.get(`user/list/filter?roleCode=PROV&status=1`)
  }

  LabTestList() {
    return http.get('LabTests/list');
  }
  ParentLabTestList() {
    return http.get(`DiagnosticsLabTests/list/filter?parentId=0&status=1`);
  }

  ParentLabTestListById(data) {
    return http.get(`LabTestsAndScans/list/filter?parentId=${data}&status=1`);
  }

  addRefer(data) {
    return http.post('Referal/', data);
  }

  getDiagnosis() {
    return http.get('Diagnosis/list?termSetId=ICD10&codeLevel=1&status=1');
  }

  // MedicineDrugListSearch(drugName) {
  //   return http.get(`DrugsInfo/list?drugName=`+drugName)
  // }

  MedicineDrugListSearch(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`DrugsInfo/list?${queryString}`);
  }

  getBase64Img(fileName) {
    return http.get(`/FileData/fileWithByteArray?fileName=${fileName}`);
  }
}

export default new PriscriptionService();