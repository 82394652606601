import profile from '../Assets/Images/profilee.svg';
import camera from '../Assets/Images/camera.svg';
import React, { Component, useState, useEffect } from 'react'
import { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { getparticulardoctordetails } from '../Redux/Actions/doctAction';
import { editProfileDetails } from '../Redux/Actions/doctAction';
import { sendNotification } from '../Redux/Actions/doctAction';
import ProfileSidebar from './profileSidebar';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import { Link } from 'react-router-dom';
import { encodeBase64File } from "../helper/filebase64";
import DatePicker from "react-datepicker";
import ProfileNamebar from './profileNamebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userprofileservice from '../Redux/services/userprofileservice';
import AddMapHospital from './AddMapHospital';
import FilterMapHospital from './FilterMapHospital';
import MobileMapHospital from './mobileMapHospital';


function MapHospital() {

    const [showAddMapHospital, setShowAddMapHospital] = useState(false);
    const [showEditMapHospital, setShowEditMapHospital] = useState(false);
    const [showFilterMapHospital, setShowFilterMapHospital] = useState(false);
    const [editMapHospital, setEditMapHospital] = useState();

    const [isLoading, setIsLoading] = useState(true);

    const [mappedHospitalsList, setMappedHospitalsList] = useState([]);





    useEffect(() => {
        loadMappedHospitals();
    }, []);
    // useEffect(() => {
    //     if (mappedHospitalsList.length) {
    //         selectedMapping()
    //     }
    // }, [mappedHospitalsList.length])
    const loadMappedHospitals = () => {
        setIsLoading(true);
        let userObj = JSON.parse(localStorage.getItem('userObj'));
        console.log(userObj.code);
        const payload = {
            userId: userObj.code,
            status: 1,
            stampRequired: 'Y'
        }
        console.log(payload);
        userprofileservice.getMappedHospitals(payload).then((res) => {
            if (res.data) {
                console.log(res.data);
                setMappedHospitalsList(res.data);
                console.log(JSON.stringify(res.data));
            }
            setIsLoading(false);
        }, (err) => {
            setIsLoading(false);
            console.log(err);
        })
    }

    const openAddMapHospital = () => {
        setShowAddMapHospital(true);
    }

    const openEditMapHospital = () => {
        setShowEditMapHospital(true);
    }

    const openFilterMapHospital = () => {
        setShowFilterMapHospital(true);
    }

    const selectedMapping = (e) => {
        console.log(e.target.value , "dslvbdsjvbdksjvbkj")
        var map = JSON.parse(e.target.value);
        console.log(map["hospitalId"]);

        mappedHospitalsList.forEach(element => {
            if (element.locationId == map["locationId"] && element.hospitalId == map["hospitalId"]) {
                //element.stampRequired = 'Y';
                setEditMapHospital(element);
                console.log(element)
                //setShowEditMapHospital(true);
            }
        });
    }

    const loadFilterMapHospital = (data) => {
        setIsLoading(true);
        console.log(JSON.stringify(data));
        setShowFilterMapHospital(false);
        let userObj = JSON.parse(localStorage.getItem('userObj'));
        let payload = {
            userId: userObj.code,
            stampRequired: 'Y'
        };
        if (data.hospitalId) {
            payload.hospitalId = data.hospitalId;
        }
        if (data.locationId) {
            payload.locationId = data.locationId;
        }
        if (!data.status) {
            payload.status = 1;
        }
        console.log(JSON.stringify(payload));
        userprofileservice.getMappedHospitals(payload).then((res) => {
            console.log(res);
            if (res.data) {
                setMappedHospitalsList(res.data);
                console.log(JSON.stringify(res.data));
            }
            setIsLoading(false);
        }, (err) => {
            setIsLoading(false);
            console.log(err);
        })
    }

    const updateLoad = (location, hospital) => {
        let userObj = JSON.parse(localStorage.getItem('userObj'));
        console.log(userObj.code);
        const payload = {
            userId: userObj.code,
            status: 1,
            stampRequired: 'Y',
            // hospitalId: hospital,
            // locationId: location
        }
        console.log(payload);
        userprofileservice.getMappedHospitals(payload).then((res) => {
            if (res.data) {
                res.data.forEach(element => {
                    if (element.locationId == location && element.hospitalId == hospital) {
                        setEditMapHospital(element)
                    }
                });
            }
        }, (err) => {
            console.log(err);
        })


    }

    return (
        <>
            <div className="lg:block hidden">
                <ProfileNamebar></ProfileNamebar>
            </div>

            {/* 2nd row */}
            <div className="lg:flex hidden justify-between ">
                <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">

                    <ProfileSidebar></ProfileSidebar>
                </div>
                {/* table start */}
                <div className="w-11/12 lg:ml-10 ml-4 mt-3 border boder-gray-200 rounded-lg bg-white mr-8">
                    <div className="flex justify-between">
                        <h1 className="text-medium font-medium text-2xl p-3 font-16 text-brand-secondary font-rubik">
                            Mapped Hospitals
                        </h1>
                        <div className="flex p-3 justify-end">
                            <div className="theme-color menuitem cursor-pointer" onClick={openAddMapHospital}>
                                <div className="font-14"><span className="icon-new font-14 cursor-pointer">New</span></div>
                            </div>
                            <div className="theme-color menuitem pl-5" onClick={openEditMapHospital}>
                                <div className="font-14"><span className="icon-view font-14">View</span></div>
                            </div>
                            <div className="theme-color menuitem pl-5" onClick={openFilterMapHospital}>
                                <div className="font-14"><span className="icon-filter font-14">Filter</span></div>
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-col">
                        <div className="lg:-my-2 overflow-x-auto sm:-mx-6 lg:-mx-0">
                            <div className="lg:py-2 align-middle inline-block min-w-full sm:px-6 lg:px-0">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y ">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="py-3">
                                                    <input id="radio2" type="radio" name="radio" className="hidden" />
                                                </th>
                                                <th scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Hospital Name
                                                </th>
                                                <th scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Location Name
                                                </th>
                                                <th scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Date
                                                </th>
                                                <th scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {mappedHospitalsList.length === 0 ?  <td class="font-medium py-4 text-center text-gray-500" colspan="5">No Data found</td> :
                                            mappedHospitalsList.map((res, i) => (
                                                <tr key={i} className="border-b border-gray-200 pt-">
                                                    <td className="py-2 px-2">
                                                        <input id="radio2" type="radio" name="radio" value={JSON.stringify(res)} onChange={selectedMapping} />
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500 font-montserrat">
                                                            {res.hospitalName}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500 font-montserrat">
                                                            {res.locationName}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500 font-montserrat">
                                                            {moment.utc(res.createdDate).utc().local().format('DD/MM/yyyy hh:mm:ss')}

                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500 font-montserrat">
                                                            {res.status == 1 ? 'Active' : 'Inactive'}
                                                        </div>
                                                    </td>
                                                </tr>

                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading && (
                        <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                    )}
                </div>
            </div>
            <MobileMapHospital/>
            {showAddMapHospital && (
                <AddMapHospital
                    closePopup={() => {
                        setShowAddMapHospital(false);
                        loadMappedHospitals();
                    }}
                    onClose={() => {
                        setShowAddMapHospital(false);
                    }}
                >
                </AddMapHospital>
            )}
            {showEditMapHospital && editMapHospital && (
                <AddMapHospital
                    editData={editMapHospital}
                    updateLoad={updateLoad}
                    closePopup={() => {
                        setShowEditMapHospital(false);
                        loadMappedHospitals();
                    }}
                    onClose={() => {
                        setShowEditMapHospital(false);
                        loadMappedHospitals();
                    }}
                >
                </AddMapHospital>
            )}
            {showFilterMapHospital && (
                <FilterMapHospital
                    closePopup={() => {
                        setShowFilterMapHospital(false);
                    }}
                    filterData={loadFilterMapHospital}
                    onClose={() => {
                        setShowFilterMapHospital(false);
                    }}
                >
                </FilterMapHospital>
            )}
        </>
    )
}

export default MapHospital;