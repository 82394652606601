import {SAVE_VIDEO_CALL_DETAILS} from '../Constants/videoCallConstants'
import {DELETE_VIDEO_URL} from '../Constants/videoCallConstants'
import {ADD_EVENT_LOCATION} from '../Constants/videoCallConstants'
const initialState = {
    callurl: '',
    callType: '',
    upcomingAppointmentDetail : '',
    eventLocation : '',
}

const VideoCallReducer = (state=  initialState , action) =>{
    const {type , payload} = action
    switch(type) {
        case SAVE_VIDEO_CALL_DETAILS:
            return {
                ...state,
                callurl : payload.callurl,
                callType: payload.callType,
                upcomingAppointmentDetail: payload.upcomingAppointmentDetail
            }; 
        case DELETE_VIDEO_URL:
            return{
                ...state,
                callurl: '',
                eventLocation: ''
            }
        case ADD_EVENT_LOCATION:
            return{
                ...state,
                eventLocation: 'VideoBubble'
            }        
            default:
                return state;    
    }
}


export default VideoCallReducer