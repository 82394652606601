import React, { useEffect, useState } from 'react';
import FamilyDropdown from './Familydropdown';
import { useHistory, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import Patientprofilebloodpressurecard from './Patientprofilebloodpressurecard';
import PatientprofileTempraturecard from './PatientprofileTempraturecard';
import Patientprofileheartratecard from './Patientprofileheartratecard';
import Patientprofileoxygencard from './Patientprofileoxygencard';
import oxy from '../Assets/Images/oxygenn.svg';
import icon from '../Assets/Images/Icon 24px.png';
import height from '../Assets/Images/height.png';
import heart from '../Assets/Images/heartt.svg';
import Resporatoryratecard from './Resporatoryratecard';
import Bloodglucosecard from './Bloodglucosecard';
import BMIcard from './BMIcard';
import Weightcard from './Weightcard';
import Heightcard from './Heightcard';
import { useDispatch, useSelector } from 'react-redux';
import { getpatientvitaldetails } from '../Redux/Actions/patientAction';
import AddVital from './AddVitalpopup';
import { Disclosure, Menu, Transition } from "@headlessui/react";
import more from "../Assets/Images/More .svg";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import Allvitalspopup from "./Allvitalspopup";

import playimg from "../Assets/Images/circle-play.svg";
import pauseimg from "../Assets/Images/circle-pause.svg";
import viewimg from "../Assets/Images/circle-view.svg";
import download from "../Assets/Images/download.svg";

function Patientprofileallvitals(props) {


    const history = useHistory();
    const location = useLocation();
    const [patDocList, setPatDocList] = useState(null)
    const [buttonName, setButtonName] = useState("Play");
    const [showviewall, setshowviewall] = useState(false);
    const [resultUrls, setResultUrls] = useState(null);
    const [resultUrlsLung, setResultUrlsLung] = useState(null);
    const [resultUrlsAll, setResultUrlsAll] = useState(null);
    const redirectTo = (event, location) => {
        console.log('enter redirect')
        event.preventDefault();
        history.push(location)
    }

    const [showvitals, setvitals] = useState(false);

    const addvitals = (event, location) => {
        event.preventDefault();
        setvitals(true)
    }

    const dispatch = useDispatch();
    const patientvitalsdetaillist = useSelector((state) => state.patientvitalsdetail);
    const { Patientvitalsdetailsdata } = patientvitalsdetaillist;

    const patientDetails = useSelector((state) => state.particularpatientdetails);

    useEffect(() => {


        let patient = localStorage.getItem("patientprofile")
        if (patient) {
            dispatch(getpatientvitaldetails(patient)).then((res) => {
                if (res.length != 0) {
                    setPatDocList(res[0])
                    if (res[0].resultUrls) {

                        const url = res[0].resultUrls
                        const urlSp = url.split(",");
                        setResultUrlsAll(url)
                        if (urlSp.length == 4) {
                            setResultUrls(urlSp)
                            setResultUrlsLung(null)
                        }
                        if (urlSp.length == 16) {
                            setResultUrls(null)
                            setResultUrlsLung(urlSp)
                        }
                        if (urlSp.length == 20) {
                            setResultUrls(urlSp.slice(0, 4))
                            setResultUrlsLung(urlSp.slice(4, 20))
                        }
                    }
                } else {
                    setPatDocList(null)
                    setResultUrlsAll(null)
                    setResultUrls(null)
                    setResultUrlsLung(null)
                }
            });
        }

    }, [
        dispatch
    ]);

    useEffect(() => {

        let patient = localStorage.getItem("patientprofile")
        console.log("patient", patient)
        if (patient) {
            dispatch(getpatientvitaldetails(patient)).then((res) => {
                if (res.length != 0) {
                    setPatDocList(res[0])
                    if (res[0].resultUrls) {
                        const url = res[0].resultUrls
                        const urlSp = url.split(",");
                        setResultUrlsAll(url)
                        if (urlSp.length == 4) {
                            setResultUrls(urlSp)
                            setResultUrlsLung(null)
                        }
                        if (urlSp.length == 16) {
                            setResultUrls(null)
                            setResultUrlsLung(urlSp)
                        }
                        if (urlSp.length == 20) {
                            setResultUrls(urlSp.slice(0, 4))
                            setResultUrlsLung(urlSp.slice(4, 20))
                        }
                    }
                } else {
                    setPatDocList(null)
                    setResultUrlsAll(null)
                    setResultUrls(null)
                    setResultUrlsLung(null)
                }
            });
        }

    }, [
        localStorage.getItem("patientprofile")
    ]);




    const getVitals = () => {
        setvitals(false)
        let patient = localStorage.getItem("patientprofile");
        dispatch(getpatientvitaldetails(patient)).then((res) => {
            if (res.length != 0) {
                setPatDocList(res[0])
                if (res[0].resultUrls) {
                    const url = res[0].resultUrls
                    const urlSp = url.split(",");
                    setResultUrlsAll(url)
                    if (urlSp.length == 4) {
                        setResultUrls(urlSp)
                        setResultUrlsLung(null)
                    }
                    if (urlSp.length == 16) {
                        setResultUrls(null)
                        setResultUrlsLung(urlSp)
                    }
                    if (urlSp.length == 20) {
                        setResultUrls(urlSp.slice(0, 4))
                        setResultUrlsLung(urlSp.slice(4, 20))
                    }
                }
            } else {
                setPatDocList(null)
                setResultUrlsAll(null)
                setResultUrls(null)
                setResultUrlsLung(null)
            }
        });
    }

    const viewAll = (e) => {
        e.preventDefault();
        setshowviewall(true)
    }



    return (
        <>

            <div class="flex justify-between ">

                <div class="lg:w-12/12 w-full mx-0 lg:mx-16 my-6 ">
                    <div className="flex flex-col">
                        <div className="-my-2 sm:-mx-6 lg:-mx-8">
                            <div className="py-2 px-6 align-middle inline-block min-w-full ">
                                <div className="flex justify-end flex-wrap">
                                    {/* <FamilyDropdown title={'Get Vitals For'} /> */}
                                    <p onClick={addvitals}
                                        class="text-sm text-brand-secondary font-medium mt-4">+ Add New Vitals</p>
                                </div>

                                <div className="lg:flex  lg:space-x-0 grid sm:grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 mt-4 content-center">
                                    <Patientprofilebloodpressurecard saveCallback={getVitals} data={Patientvitalsdetailsdata}></Patientprofilebloodpressurecard>
                                    <PatientprofileTempraturecard saveCallback={getVitals} data={Patientvitalsdetailsdata}></PatientprofileTempraturecard>
                                    <Patientprofileheartratecard saveCallback={getVitals} data={Patientvitalsdetailsdata}></Patientprofileheartratecard>
                                </div>

                                <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 mt-4 justify-between content-center ">
                                    <Patientprofileoxygencard saveCallback={getVitals} data={Patientvitalsdetailsdata}></Patientprofileoxygencard>
                                    <Resporatoryratecard saveCallback={getVitals} data={Patientvitalsdetailsdata}></Resporatoryratecard>
                                    {/* <Bloodglucosecard saveCallback={getVitals} data={Patientvitalsdetailsdata}></Bloodglucosecard> */}
                                    <></>

                                </div>
                                <div class="flex justify-between mt-5 mb-5">
                                    <p class="text-lg font-medium text-brand-secondary">Anthropometry </p>
                                </div>
                                <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 mt-4 justify-between content-center ">
                                    <Weightcard saveCallback={getVitals} data={Patientvitalsdetailsdata}></Weightcard>
                                    <Heightcard saveCallback={getVitals} data={Patientvitalsdetailsdata}></Heightcard>
                                    <BMIcard saveCallback={getVitals} data={Patientvitalsdetailsdata}></BMIcard>
                                </div>

                                {patDocList ?
                                    <>
                                        <div class="flex justify-between  mb-7 mt-15">
                                            {/* {patDocList && patDocList.audioUploadDocName || patDocList.uploadDocumentName ? */}
                                            <p class="text-medium font-medium text-xl font-rubik text-brand-secondary mt-10">Electronic Stethoscope - Auscultation</p>
                                            {/* : null} */}
                                        </div>
                                        {/* {patDocList && patDocList.audioUploadDocName &&
                                            <div className="flex space-x-4 mt-4">
                                                <div class="flex justify-between">
                                                    <p class="text-lg font-medium text-brand-secondary">
                                                        Audio
                                                    </p>
                                                    <div class="flex justify-end space-x-2">
                                                        <div
                                                            className="cursor-pointer"
                                                        >
                                                            <a target="_blank"
                                                                href={
                                                                    IMG_URL + patDocList.audioUploadDocName
                                                                }
                                                            >
                                                                {buttonName == "Play" ?
                                                                    <img className="mr-5 cursor ml-10 mt-1" src={pauseimg} />
                                                                    :
                                                                    <img className="mr-5 cursor ml-10 mt-1" src={playimg} />
                                                                }
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } */}
                                        {patDocList && patDocList.uploadDocumentName &&
                                            <div className="flex space-x-4 mt-4">
                                                <div class="flex justify-between w-52 border border-gray-200 rounded-xl h-10">
                                                    <a target="_blank"
                                                        href={
                                                            process.env.REACT_APP_IMG_BASEURL + patDocList.uploadDocumentName
                                                        }
                                                    >
                                                        <p class="text-lg ml-3 mt-1 font-medium text-brand-secondary">
                                                            View Document
                                                        </p>
                                                    </a>
                                                    {/* <div class="flex justify-end space-x-2">
                                                        <div
                                                            className="cursor-pointer"
                                                        >
                                                            <a target="_blank"
                                                                href={
                                                                    IMG_URL + patDocList.uploadDocumentName
                                                                }
                                                            >
                                                                <img src={viewimg} alt="more" className=" ml-10 w-6 mt-1" />
                                                            </a>
                                                        </div>
                                                    </div> */}
                                                    <>
                                                        <Menu as="div" className="mt-2 relative">
                                                            {({ open }) => (
                                                                <>
                                                                    <div>
                                                                        <Menu.Button
                                                                            className={` flex text-sm rounded-full focus:outline-none`}
                                                                        >
                                                                            <img src={more} alt="more" />
                                                                        </Menu.Button>
                                                                    </div>
                                                                    <Transition
                                                                        show={open}
                                                                        as={Fragment}
                                                                        enter="transition ease-out duration-100"
                                                                        enterFrom="transform opacity-0 scale-95"
                                                                        enterTo="transform opacity-100 scale-100"
                                                                        leave="transition ease-in duration-75"
                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                        leaveTo="transform opacity-0 scale-95"
                                                                    >
                                                                        <Menu.Items
                                                                            static
                                                                            className="origin-top-right absolute right-0 mt-2 w-28 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        >
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <Link
                                                                                        onClick={(e) => setshowviewall(true)}
                                                                                        className="bg-gray-100
                                                                                            block px-4 py-2 text-sm text-gray-700"
                                                                                    >
                                                                                        View All
                                                                                    </Link>
                                                                                )}
                                                                            </Menu.Item>
                                                                        </Menu.Items>
                                                                    </Transition>
                                                                </>
                                                            )}
                                                        </Menu>
                                                    </>
                                                </div>
                                                {/* <Link
                                                    onClick={(e) => setshowviewall(true)}
                                                    className={
                                                        "block px-4 py-2 text-sm text-gray-700"
                                                    }
                                                >
                                                    View All
                                                </Link> */}
                                            </div>
                                        }
                                    </>
                                    : null
                                }
                                {patDocList && patDocList.resultUrls &&
                                    <>
                                        {/* <div class="flex justify-between  mb-7 mt-15"> */}
                                        <div className="grid grid-cols-12 gap-4 mb-5 resp-block">
                                            {/* <div className="img-bg col-span-1">
                                                <img src={wifi} width="23px" />
                                            </div> */}
                                            <div className="col-span-11 manual-border flex resp-block">
                                                <div className="w-6/12 br-1 resp-w-full">

                                                    <div className="flex mt-5 mb-2 px-5">
                                                        <div class="formbold-file-list">
                                                            {resultUrls &&
                                                                <div className="px-6">
                                                                    <p class="heading-font">Heart</p>
                                                                    <div className="lungaudio-auto overflow-auto hide-scroll-bar">
                                                                        {resultUrls.map((res, i) =>
                                                                            <div className="flex mb-2">
                                                                                <div class="formbold-file-list">
                                                                                    <div class="formbold-file-item">
                                                                                        <span class="formbold-file-name">{i + 1 == 1 ? "Aortic" : i + 1 == 2 ? "Tricuspid" : i + 1 == 3 ? "Pulmonary" : i + 1 == 4 ? "Mitral" : ""}</span>
                                                                                        <div className="flex filterinvent-1 ">
                                                                                            <a href={res} target="_blank">
                                                                                                {/* <button onClick={() => handleClick(i, res, "play")}> */}
                                                                                                <img className="ml-3 cursor " src={pauseimg} width="15px" />
                                                                                                {/* </button> */}
                                                                                            </a>
                                                                                            <a href={res} target="_blank">
                                                                                                <img className="ml-3 cursor " src={download} width="15px" />
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {resultUrls == null &&
                                                                <div className="px-6">
                                                                    <p class="heading-font">Heart</p>
                                                                    <div className="lungaudio-auto overflow-auto hide-scroll-bar">
                                                                        <div className="flex mb-2">
                                                                            <div class="formbold-file-list">
                                                                                <div class="formbold-file-item">
                                                                                    <span class="formbold-file-name">No data found</span>
                                                                                    <div className="flex filterinvent-1 ">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* // } */}
                                                </div>
                                                <div className="w-6/12 resp-w-full">
                                                    <div className="flex mt-5 mb-2 px-5">
                                                        <div class="formbold-file-list">
                                                            {resultUrlsLung &&
                                                                <div className="px-6">
                                                                    <p class="heading-font">Lung</p>
                                                                    <div className="lungaudio-auto overflow-auto hide-scroll-bar">
                                                                        {resultUrlsLung.map((res, i) =>
                                                                            <div className="flex mb-2">
                                                                                <div class="formbold-file-list">
                                                                                    <div class="formbold-file-item">
                                                                                        <span class="formbold-file-name">{i + 1 == 1 ? "Anterior 1" : i + 1 == 2 ? "Anterior 2" : i + 1 == 3 ? "Anterior 3" : i + 1 == 4 ? "Anterior 4" : i + 1 == 5 ? "Anterior 5" : i + 1 == 6 ? "Anterior 6" : i + 1 == 7 ? "Lateral 7" : i + 1 == 8 ? "Lateral 8" : i + 1 == 9 ? "Lateral 9" : i + 1 == 10 ? "Lateral 10" : i + 1 == 11 ? "Posterior 11" : i + 1 == 12 ? "Posterior 12" : i + 1 == 13 ? "Posterior 13" : i + 1 == 14 ? "Posterior 14" : i + 1 == 15 ? "Posterior 15" : i + 1 == 16 ? "Posterior 16" : ""}</span>
                                                                                        <div className="flex filterinvent-1 ">
                                                                                            <a href={res} target="_blank">
                                                                                                <img className="ml-3 cursor " src={pauseimg} width="15px" />
                                                                                            </a>
                                                                                            <a href={res} target="_blank">
                                                                                                <img className="ml-3 cursor " src={download} width="15px" />
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {resultUrlsLung == null &&
                                                                <div className="px-6">
                                                                    <p class="heading-font">Lung</p>
                                                                    <div className="lungaudio-auto overflow-auto hide-scroll-bar">
                                                                        <div className="flex mb-2">
                                                                            <div class="formbold-file-list">
                                                                                <div class="formbold-file-item">
                                                                                    <span class="formbold-file-name">No data found</span>
                                                                                    <div className="flex filterinvent-1 ">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* // } */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    {/*<div class="flex justify-end pt-4">

                        <div>
                            <button onClick={(e) => redirectTo(e, APP_ROUTES.DASHBOARD)} className="bg-brand-secondary font-rubik text-white py-3.5 px-6 font-medium rounded-xl mb-8">Back to Dashboard </button>
                        </div>
    </div>*/}
                </div>
            </div>

            {
                showvitals ? <AddVital closePopup={getVitals} ></AddVital> : null
            }

            {showviewall ? (
                <Allvitalspopup
                    // data={Patientvitalsdetailsdata}
                    title={"Documents"}
                    closePopup={() => setshowviewall(!showviewall)}
                ></Allvitalspopup>
            ) : null}



        </>
    );
}

export default Patientprofileallvitals;