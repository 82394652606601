import { PRIS } from "../Constants/PriscriptionConstants";
import { USER } from "../Constants/userprofileConstants";
import { VITALS } from "../Constants/vitalConstants";


const initialState = {
    MedicinelistData: [],
    labTestList: [],
    diagnosisList: [],
    success: false,
    isError: false,
    isLoading: false,
    msg: '',
    errMsg: ''
};


const MedicinelistReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PRIS.REQUEST_MEDICINELIST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case PRIS.SUCCESS_MEDICINELIST:
            return {
                ...state,
                isLoading: false,
                MedicinelistData: payload,
                isError: false,
            };
        case PRIS.FAILED_MEDICINELIST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };
        case PRIS.REQUEST_ADDPRESCRIPTION:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case PRIS.SUCCESS_ADDPRESCRIPTION:
            return {
                ...state,
                isLoading: false,
                isError: false,
            };
        case PRIS.FAILED_ADDPRESCRIPTION:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };
        case PRIS.REQUEST_GETLABTEST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case PRIS.SUCCESS_GETLABTEST:
            return {
                ...state,
                isLoading: false,
                labTestList: payload,
                isError: false,
            };
        case PRIS.FAILED_GETLABTEST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };

        case PRIS.REQUEST_GETDIAGNOSIS:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case PRIS.SUCCESS_GETDIAGNOSIS:
            return {
                ...state,
                isLoading: false,
                diagnosisList: payload,
                isError: false,
            };
        case PRIS.FAILED_GETDIAGNOSIS:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };
        default:
            return state;
    }
};

export default MedicinelistReducer;