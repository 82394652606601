import React, { useState, useEffect, useRef } from 'react'
import curebay from '../Assets/Images/Onlylogo.svg';
import vijay from "../Assets/Images/vijy.svg";
import search from '../Assets/Images/doctsearch.svg';
import share from '../Assets/Images/share-21.svg';
import download from '../Assets/Images/arrow.downloaded.svg';
import more from '../Assets/Images/More .svg';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientdetails, getPatientPHRReportss, getPatientReports } from '../Redux/Actions/patientAction';
import BloodPresure from './BloodPresure';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { viewImage } from '../helper/downloadImage'
import moment from 'moment';
import PatientProfileSidebar from './patientProfileSidebar';
import PatientprofilemyEntry from './patientprofilemyEntry';
import PatientprofileEprescription from './PatientprofileEprescription';
import PatientprofilereactEpresc from './PatientprofilereactEpresc';
import Patientprofileallvitals from './Patientprofileallvitals';
import PatientProfileMedicalHistory from './PatientProfileMedicalHistory';
import PatientPhysicalExmaination from './PatientPhysicalExam';
import PatientClinicalImage from './PatientClinicalImage'
import LabReport from './labReport';
import { IMG_URL } from "../config/constant";
import { Dropdown } from 'react-bootstrap'
import { XIcon, MinusIcon, ArrowsExpandIcon, MenuIcon } from "@heroicons/react/outline";
import MinimizeVideoCall from "./MinimizeVideoCall";
import { Modal } from '@material-ui/core'
function PatientDetails2(props) {


  const params = useParams()
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  const patientdetailslist = useSelector((state) => state.patientdetails);
  const { patientdetailsData } = patientdetailslist;

  const patientphrreportlist = useSelector((state) => state.patientphr);
  const { patientPHRData } = patientphrreportlist;

  const [showDefault, setShowDefault] = useState(false);
  const [showMedicalHistory, setShowMedicalHistory] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showPrescription, setShowPrescription] = useState(false);
  const [showVitals, setShowVitals] = useState(true);
  const [showLabReport, setshowLabReport] = useState(false);
  const [showPhysicalExam, setshowPhysicalExam] = useState(false);
  const [showClinicalImage, setshowClinicalImage] = useState(false);
  const [minimize, setMinimize] = useState(true);
  const [openDialog, setOpenDialog] = useState(false)

  const [percentage, setpercentage] = useState(1);
  const [show, setshow] = useState(true);
  const dialogRef = useRef(null)
  useEffect(() => {
    localStorage.setItem("patientprofile", params.patid || props.state.patientId);
    dispatch(getPatientdetails(params.patid || props.state.patientId))
    dispatch(getPatientReports())
    dispatch(getPatientPHRReportss(params.patid || props.state.patientId))
    localStorage.removeItem('callURL')
    localStorage.removeItem('callTYPE')
    if (location?.state?.callUrl && location?.state?.callType) {
      localStorage.setItem('callURL', location?.state?.callUrl)
      localStorage.setItem('callTYPE', location?.state?.callType)
    }
  }, []);

  const loadItem = (i) => {
    console.log(i);
    if (i == 'medicalhistory') {
      setShowDefault(false);
      setShowMedicalHistory(true);
      setShowReports(false);
      setShowPrescription(false);
      setShowVitals(false);
      setshowLabReport(false);
      setshowPhysicalExam(false);
      setshowClinicalImage(false);
    } else if (i == 'reports') {
      setShowDefault(false);
      setShowMedicalHistory(false);
      setShowReports(true);
      setShowPrescription(false);
      setShowVitals(false);
      setshowLabReport(false);
      setshowPhysicalExam(false);
      setshowClinicalImage(false);
    } else if (i == 'prescription') {
      setShowDefault(false);
      setShowMedicalHistory(false);
      setShowReports(false);
      setShowPrescription(true);
      setShowVitals(false);
      setshowLabReport(false);
      setshowPhysicalExam(false);
      setshowClinicalImage(false);
    } else if (i == 'vitals') {
      setShowDefault(false);
      setShowMedicalHistory(false);
      setShowReports(false);
      setShowPrescription(false);
      setShowVitals(true);
      setshowLabReport(false);
      setshowPhysicalExam(false);
      setshowClinicalImage(false);
    } else if (i == 'labReport') {
      setshowLabReport(true);
      setShowDefault(false);
      setShowMedicalHistory(false);
      setShowReports(false);
      setShowPrescription(false);
      setShowVitals(false);
      setshowPhysicalExam(false);
      setshowClinicalImage(false);
    } else if (i == 'physicalExam') {
      setshowLabReport(false);
      setShowDefault(false);
      setShowMedicalHistory(false);
      setShowReports(false);
      setShowPrescription(false);
      setShowVitals(false);
      setshowClinicalImage(false);
      setshowPhysicalExam(true);
    } else if (i == 'clinicalImage') {
      setshowLabReport(false);
      setShowDefault(false);
      setShowMedicalHistory(false);
      setShowReports(false);
      setShowPrescription(false);
      setShowVitals(false);
      setshowClinicalImage(true);
      setshowPhysicalExam(false);
    }
  }


  const open = () => {
    setshow(true);
  }
  const close = () => {
    var iframes = document.querySelectorAll('iframe');
    for (var i = 0; i < iframes.length; i++) {
      iframes[i].parentNode.removeChild(iframes[i]);
    }
    setshow(false);
  }

  const zoom = (val) => {
    var iframes = document.querySelectorAll('iframe');
    for (var i = 0; i < iframes.length; i++) {
      iframes[i].parentNode.removeChild(iframes[i]);
    }
    props.onHide()
    //setMinimize(val)
  }

  const generalInformation = () => {
    return (
      <>
        {patientdetailsData.slice(0, 1).map((user, i) => (

          <div className=" rounded-lg shadow-lg bg-white lg:bg-white-600 w-full md:w-3/6 lg:w-full px-2  p-3 mb-2 antialiased border border-gray-200">

            <p className="text-base text-gray-900 font-medium pb-2">General Information</p>
            <div className="flex justify-between">
              <p className="text-sm text-gray-400">Date of birth</p>
              <p className="text-sm text-gray-600">{moment(user.dob).format("DD/MM/yyyy")}</p>
            </div>
            <div className="flex justify-between pt-3">
              <p className="text-sm text-gray-400">Gender</p>
              <p className="text-sm text-gray-600">{user.gender == 'M' ? 'Male' : user.gender == 'F' ? 'Female' : 'Others'}</p>
            </div>
            <div className="flex justify-between pt-3">
              <p className="text-sm text-gray-400">Location</p>
              <p className="text-sm text-gray-600">{user.city}</p>
            </div>
            <div className="flex justify-between pt-3">
              <p className="text-sm text-gray-400">Preferred Language</p>
              <p className="text-sm text-gray-600">{user.pLanguage}</p>
            </div>
            <div className="flex justify-between pt-3">
              <p className="text-sm text-gray-400">Phone Number</p>
              <p className="text-sm text-gray-600">{user.mobile}</p>
            </div>
            <div className="flex justify-between pt-3">
              <p className="text-sm text-gray-400">Email ID</p>
              <p className="text-sm text-gray-600">{user.email}</p>
            </div>
          </div>
        ))}
      </>
    )
  }


  return (
    <>


      <div>
        <Modal
          className="flex h-auto items-center justify-center mx-2"
          //  style={{ top: '15%' }}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >

          {generalInformation()}
        </Modal>
        <div className="w-full p-5 lg:max-w-full lg:flex lg:pt-6">
          <div className="lg:mx-10 w-full border border-gray-100 bg-white rounded-xl -none p-2 lg:flex flex-col justify-between leading-normal">

            <div className="flex justify-between">

              <div className="flex">
                <div className="block lg:hidden relative top-4 mr-4" >
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />

                    </Dropdown.Toggle>

                    <Dropdown.Menu className="z-10" >
                      <PatientProfileSidebar
                        patientcode={params.patid || props.state.patientId}
                        loadItem={loadItem}
                      ></PatientProfileSidebar>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  {patientdetailsData[0]?.photoName ? (<div>
                    <img src={IMG_URL + patientdetailsData[0]?.photoName} alt="vijay" className="w-10 profileRound" />
                  </div>) : (<div className="bg-white p-3  flex justify-center font-rubik  rounded-lg">
                    <span className="font-bold text-2xl">
                      {patientdetailsData[0]?.firstName.charAt(0)}
                    </span>
                  </div>)}
                </div>

                <div className="mb-4 p-3">
                  <div className="pl-3" >
                    <p className="text-sm text-gray-700 font-medium">{patientdetailsData[0]?.firstName} {patientdetailsData[0]?.lastName}</p>
                    {/* <p className="text-xs font-medium text-gray-700">Symptoms:<span className="text-xs pl-1 font-normal text-gray-500">Cough and cold for the past 5 days</span></p> */}
                  </div>
                </div>
              </div>

              <div className="md:ml-12 md:mr-4">
                <p className="text-xs font-medium text-gray-700">Patient Mobile No. :<span className="text-xs pl-1 font-normal text-gray-500">{patientdetailsData[0]?.mobile}</span></p>
                <p className="block lg:hidden text-xs font-medium text-brand-secondary text-gray-700" onClick={() => setOpenDialog(true)} >
                  General Information
                </p>
                {/* <p className="text-xs font-medium text-gray-700">Hospital :<span className="text-xs pl-1 font-normal text-gray-500">{patientdetailsData[0]?.hospitalName}</span></p> */}
              </div>



              {/*<div className="flex mt-3 items-center justify-around space-x-3">
                  <button className="bg-transparent text-xs text-brand-secondary font-medium  py-2 px-4 border  w-1/2  border-brand-secondary rounded-lg">Refer</button>
                  <div className="lg:hidden border-l h-8"></div>
                  
                  <button className="bg-brand-secondary text-xs  text-white lg:w-full w-1/2 font-medium py-2 px-4 rounded-lg">Follow Up</button>
                </div>*/}


            </div>
          </div>
        </div>

        <div className="flex justify-between">

          <div class="lg:block hidden w-3/12 ml-6 mt-6">
            <PatientProfileSidebar
              patientcode={params.patid || props.state.patientId}
              loadItem={loadItem}
            ></PatientProfileSidebar>


            {generalInformation()}


            <div className="lg:block hidden w-3/12 ml-6 mt-6">
              {/* card 2 */}

              {/* card 1 */}
              {/*
          <div className="col-span-2  w-full h-auto rounded-lg overflow-hidden w-2/4 h-52 border boder-gray-600 shadow-sm p-4 font-rubik">
            <div className="flex justify-between pb-2">
              <div className="flex space-x-3">
                <img src={search} alt="search" className="w-4" />
                <p className="text-sm text-brand-secondary font-medium">Search or Filter </p>
              </div>
            </div>
            <div className="pb-2 border-t "></div>

            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-2">
                  <div className="overflow-scroll h-52  sm:rounded-lg hide-scroll-bar">
                    <table className="min-w-full">
                      <thead className="">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Description
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Type
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Date
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody className=" divide-gray-200">

                        {patientPHRData.filter(x => x.docName !== '' && x.docName !== null && x.docName !== 'null').map((user, i) => (
                          <tr key={i}>
                            <td className="pl-6 py-4">
                              <div className="flex ">
                                <img src={curebay} alt="curebay" className="h-6 w-6 rounded-full" />
                                <div className="text-xs  font-normal text-gray-400 ml-2">
                                  {user.docName ? user.docName : user.notes}
                                </div>
                              </div>
                            </td>
                            <td className="pl-6 py-4">
                              <div className="text-xs font-normal text-gray-400">{user.reportType}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-xs font-normal text-gray-400">{moment(user.givenDate).format("DD-MM-yyyy h:mm:ss")}</div>
                            </td>
                            <td className="px-6 py-4 flex space-x-4 cursor-pointer">
                              {user.docName ? <img onClick={() => viewImage(user.docName)} src={download} alt="download" /> : ''}
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>


                </div>

              </div>

            </div>



          </div>
                        */}
            </div>

          </div>

          <div className="lg:w-8/12 w-full lg:mr-16 lg:mt-4">
            <div class="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 lg:mb-2 mb-16 antialiased justify-between border border-gray-200 mt-4">
              {showDefault && (
                <BloodPresure patientcode={params.patid || props.state.patientId}></BloodPresure>
              )}
              {showMedicalHistory && (
                <PatientProfileMedicalHistory></PatientProfileMedicalHistory>
              )}
              {showReports && (
                <PatientprofilemyEntry></PatientprofilemyEntry>
              )}
              {showPrescription && (
                // <PatientprofileEprescription></PatientprofileEprescription>
                <PatientprofilereactEpresc></PatientprofilereactEpresc>
              )}
              {showVitals && (
                <Patientprofileallvitals></Patientprofileallvitals>
              )}
              {showPhysicalExam && (
                <PatientPhysicalExmaination></PatientPhysicalExmaination>
              )}
              {showClinicalImage && (
                <PatientClinicalImage></PatientClinicalImage>
              )}
              {showLabReport && (
                <>
                  <div class="relative w-full">
                    <LabReport></LabReport>
                  </div>
                </>

              )}
            </div>
          </div>
        </div>


      </div>

      {show ?
        <div style={{ position: 'fixed', bottom: '0', right: '15px', width: minimize ? '' : '95%' }} >
          {minimize ?
            <div className='cursor-pointer' style={{ position: 'fixed', right: '10px', marginTop: '-13px', fontSize: '20px' }} onClick={() => zoom(false)} ><ArrowsExpandIcon className="h-5 cursor-pointer" /></div> :
            <div className='cursor-pointer' style={{ position: 'fixed', right: '10px', marginTop: '-13px', fontSize: '20px' }} onClick={() => zoom(true)}><MinusIcon className="h-5 cursor-pointer" /></div>}
          {location?.state?.callType == 1 ? <div dangerouslySetInnerHTML={{
            __html:
              minimize ? `<iframe  allow="camera; microphone;" style="width: 100%; height:250px" src=${location?.state?.callUrl} frameborder="0" allowfullscreen showControls></iframe>`
                : `<iframe  allow="camera; microphone;" style="width: 100%; height:80vh" src=${location?.state?.callUrl} frameborder="0" allowfullscreen showControls></iframe>`
          }}>
          </div> : null}
          {location?.state?.callType == 2 ? <div dangerouslySetInnerHTML={{
            __html:
              minimize ? `<iframe  allow="microphone;" style="width: 100%; height:250px" src=${location?.state?.callUrl} frameborder="0" allowfullscreen showControls></iframe>` :
                `<iframe  allow="microphone;" style="width: 100%; height:80vh" src=${location?.state?.callUrl} frameborder="0" allowfullscreen showControls></iframe>`
          }}>
          </div> : null}

          {location?.state?.callType ? <button class="cursor-pointer bg-brand-secondary w-full font-rubik text-md text-white font-normal rounded-md py-2 px-4" onClick={() => close()} >close</button>
            : null}
        </div>



        : <button className="cursor-pointer bg-brand-secondary font-rubik text-md text-white font-normal rounded-md py-2 px-4" style={{ position: 'fixed', bottom: '0', right: '15px', width: '19rem' }} onClick={() => open()} >Open Video</button>}


    </>
  );
}

export default PatientDetails2;