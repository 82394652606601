import React, { useEffect, useState } from 'react'
import search from '../Assets/Images/doctsearch.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReferalTo, ReferalClinicListData, ReferPatientcurebay, ReferalPatientListData } from '../Redux/Actions/RefralAction';
import ProfileSidebar from './profileSidebar';
import ProfileNamebar from './profileNamebar';
import MobileReferTo from './mobileReferTo';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { date } from 'yup';
import moment from "moment";
import ReferSuccessfulpopup from "./ReferSuccessfulpopup";
import MobileReferPatient from './mobileReferPatient';

function ReferPatientList() {
    const history = useHistory();
    const dispatch = useDispatch();
    const loggedInData = useSelector((state) => state.authReducer);
    const { userData } = loggedInData;
    const [list, setList] = useState([])
    const { referredPatientList } = useSelector((state) => state.referalReducer);;
    const [AddViewShow, setAddshow] = useState(false)
    const loginObj = JSON.parse(localStorage.getItem("loginObj"));
    const userDataLogin = loginObj.user;
    const [fromLocation, setfromLocation] = useState({
        fromLocat: "patient"
    })
  
    useEffect(() => {
        const loginObj = JSON.parse(localStorage.getItem("loginObj"));
        const userDataLogin = loginObj.user;
        dispatch(ReferalPatientListData(userDataLogin.code))
    }, []);
    const redirectTo = (event, location) => {
        event.preventDefault();
        history.push(location)
    }
    const onSearch = (text) => {
        let val = text ? text : '';
        // @ts-ignore
        var newArr = referredPatientList
        console.log("lolas", newArr)
        // @ts-ignore
        let newArray = newArr.filter((data) => JSON.stringify(data).toLowerCase().indexOf(val.toLowerCase()) !== -1);
        setList(newArray)
    }

    useEffect(() => {
        if (referredPatientList && referredPatientList.length > 0) {
            onSearch();
        }
    }, [referredPatientList.length])

    const referpatient = (e) => {
        setAddshow(true)
    }
    return (
        <>


            <div className="lg:block hidden">
                <ProfileNamebar></ProfileNamebar>
            </div>
            <ToastContainer />

            {/* 2nd row */}
            <div className="lg:flex hidden justify-between ">
                <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">
                    <ProfileSidebar></ProfileSidebar>
                </div>
                {/* table start */}
                    <div className="w-11/12 lg:ml-10 ml-4 mt-3 border boder-gray-200 rounded-lg bg-white mr-8">
                        <div className="flex justify-end mt-5">

                            <button onClick={(e) => redirectTo(e, APP_ROUTES.REFERPATIENT)} className={`disabled:opacity-50 bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2`}>
                                Refer patient
                            </button>
                        </div>
                        <div className="flex space-x-3 pt-5 pl-5 pb-5">
                            <img src={search} alt="search" className="w-4" />
                            <input type="text" onChange={(e) => { onSearch(e.target.value) }} className="text-sm text-brand-secondary font-medium" placeholder="Search or Filter" />
                        </div>

                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-0">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-0">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg h-96 overflow-y-auto hide-scroll-bar ">
                                        <table className="min-w-full divide-y ">
                                            <thead >
                                                <tr>
                                                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                                                        Patient Name
                                                    </th>
                                                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                                                        Mobile
                                                    </th>
                                                    {/* <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                                                    Action
                                                </th> */}

                                                </tr>
                                            </thead>

                                            {list.length === 0 ? <td class="font-medium py-4 text-center text-gray-500" colspan="2">No Data found</td>  :
                                                <tbody className=" divide-y divide-gray-200 ">

                                                    {list.map((res, i) => (
                                                        <tr key={i}>
                                                            <td className="px-4 py-4 flex ">
                                                                <p className="text-sm font-medium text-gray-600 font-montserrat">{res.referToName ? res.referToName : 'NA'}</p>
                                                            </td>
                                                            <td className="px-4 py-4 break-all text-sm text-gray-500">
                                                                <p className="text-sm w-52 font-medium  text-gray-600 font-montserrat">{res.mobile}</p>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               
            </div>
            <div className="lg:hidden block">
                <MobileReferPatient />
            </div>
            {/* {showSucesspopup ? (
                <ReferSuccessfulpopup FromLocationID={fromLocation}
                    closePopup={() => setshowSucesspopup(!showSucesspopup)}
                ></ReferSuccessfulpopup>
            ) : null} */}
        </>
    )
}
export default ReferPatientList;
