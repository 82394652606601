import http from "./http-common";

class PatientService {

  savePatientAppointment(data) {
    return http.post(`PatientAppointment/`, data);
  }

  generateMaggiePlus(data) {
    const headers = {
      "secretkey": "CcJYfbsgItHpTQPFr5lg"
    }
    // @ts-ignore
    console.log("process.env.REACT_APP_MAGGIEPLUS", process.env.REACT_APP_MAGGIEPLUS)
    // @ts-ignore
    return http.post(process.env.REACT_APP_MAGGIEPLUS, data, { headers: headers });
  }


  getpatientdetails(data) {
    return http.get(`patient/list/filter?code=${data}`);
  }

  getappointmentlist(data) {
    return http.get(`PatientAppointment/list?userId=${data.code}&photoRequired=Y&fromDate=${data.fromDate}&toDate=${data.toDate}`);
  }

  getFilteredAppointments(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`PatientAppointment/list?${queryString}`);
  }

  getFilteredAppointmentsCount(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`PatientAppointment/count?${queryString}`);
  }

  getupcomingappointmentlist(doct, fromDate, toDate) {
    // TODO: Change the status=2 to some join call status code
    return http.get(`PatientAppointment/list?userId=${doct}&photoRequired=Y&fromDate=${fromDate}&toDate=${toDate}`);
  }

  getupcomingappointmentlistfilterhospital(doct, hosp, fromDate, toDate) {
    return http.get(`PatientAppointment/list?userId=${doct}&hospitalId=${hosp}&fromDate=${fromDate}&toDate=${toDate}&status=1`);
  }

  gethospitalsandcliniclist(data) {
    return http.get(`User_Hospital_Location/list?userId=${data}&status=1`);
  }


  getpatientPHRreports(data) {
    return http.get(`PatientDocument/list/filter?patientCode=${data}&documentRequired=Y`);
  }

  getpatientdocuments(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`PatientDocument/list/filter?${queryString}`);
  }


  getPostConsultation(data) {
    return http.get(`Visit/list?appointmentId=${data}&visitSummary=Y&documentRequired=Y&vitalRequired=Y`)
  }
  getPreConsultationIllness(data) {
    return http.get(`PatientIllnessHistory/list/filter?appointmentId=${data}`)
  }
  getBase64Img(fileName) {
    return http.get(`/FileData/fileWithByteArray?fileName=${fileName}`);
  }

  getAppointmentCount(data) {
    return http.get(`PatientAppointment/count?userId=${data.userCode}&fromDate=${data.from}&toDate=${data.to}&status=1`)
  }

  getAppointmentCounts(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`user/UserStatisticsCount?${queryString}`)
  }

  getpatientvitalsdetails(data) {
    return http.get(`PatientVitals/list/filter?patientCode=${data}`);
  }

  requestaccess(data) {
    return http.post(`PatientAppointment/shareAccessNotification/`, data);
  }

  share(data) {
    return http.post(`Visit/shareLink/`, data);
  }

  imagetoData(data) {
    return http.get(`FileData/fileWithByteArray?fileName=${data}`)
  }

  getuserFeesList(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');

    return http.get(`User_Consultation_Fees/list/filter?${queryString}`);
  }
  getmysymptomsList() {
    return http.get(`Symptoms/list`);
  }
  getUserSpList(data) {
    return http.get(`User_Hospital_Location/Users/list?code=${data}&userRoleCode=PROV,SPECIALIST&uniqueUser=Y&status=1`);
  }

  getPackages(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');

    return http.get(`PackageServices/list/filterWithDoctorPackage?${queryString}`);
  }

  getPatientlist(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');

    return http.get(`PatientPackagesDetails/list/filter?${queryString}`);
  }

  campAppoinment(data) {
    return http.post(`PatientAppointment/saveCampAppointment/`, data);
  }
  addPhysicalExamination(data) {
    return http.post('PatientExamination/', data)
  }
  listPhysicalExamination(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`PatientExamination/list?${queryString}`);
  }
  getPatientClinicalImage(id) {
    return http.get(`clinicalImage/${id}`);
  }
  postMedicineOrder(payload) {
    return http.put(`SlotAvailability/map_prescription`, payload)
  }
  getPreMedicine(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`SlotAvailability/getPatientDetails?${queryString}`);
  }
}

export default new PatientService();