import React, { useState, useEffect } from "react";
import vijay from "../Assets/Images/vijy.svg";
import { SearchIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentList } from "../Redux/Actions/patientAction";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { IMG_URL } from "../config/constant";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from 'date-fns';
import Backdrop from '@mui/material/Backdrop';
toast.configure();

function PatientsListing() {
  const history = useHistory();
  const location = useLocation();
  const { text } = location;
  const [loading,setLoading]=useState(false);
  const dispatch = useDispatch();
  const appointmentlist = useSelector((state) => state.appointmentlist);
  const { appointmentlistData, isLoading } = appointmentlist;
  const { userData } = useSelector((state) => state.authReducer);
  const [list, setList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(moment().add(7, 'days').toDate());
  const [searchText, setSearchText] = useState(text ? text : "");

  const refer = (e, doct) => {
    e.preventDefault();
    console.log(doct.patientId);
    history.push({
      pathname: `/patient_details/${doct.patientId}`,
      state: doct,
    });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const newEndDate = moment(date).add(7, 'days').toDate();
    
    if (moment(endDate).isBefore(newEndDate)) {
        setEndDate(newEndDate);
    } else {
        setEndDate(endDate);
    }
  };

  const handleEndDateChange = (date) => {
    if (moment(date).isBefore(startDate, 'day')) {
        toast.error("To Date cannot be earlier than From Date", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
        });
        return;
    }

    if (moment(date).isAfter(moment(startDate).add(15, 'days'), 'day')) {
        toast.error("To Date cannot be more than 15 days ahead of From Date", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
        });
        return;
    }

    setEndDate(date);
  };

  useEffect(() => {
    console.log(appointmentlistData);
    setLoading(true);  // Set loading to true before fetching data

    const today = new Date();
    const fromDate = format(today, 'MM/dd/yyyy');
    const toDate = format(new Date(today.setDate(today.getDate() - 7)), 'MM/dd/yyyy'); 
    
    const payload = {
        code: userData.code,
        fromDate: startDate ? moment(startDate).format('MM/DD/YYYY') : fromDate,
        toDate: endDate ? moment(endDate).format('MM/DD/YYYY') : toDate
    };

    dispatch(getAppointmentList(payload)).then(() => {
        setLoading(false);  // Set loading to false after data is fetched
    }).catch((error) => {
        console.error('Error fetching appointment list:', error);
        setLoading(false);  // Ensure loading is set to false even if there's an error
    });

}, [startDate, endDate]);


  const search = () => {
    let val = searchText ? searchText : text ? text : "";
    console.log("val", searchText);
    var newArr = appointmentlistData;
    console.log("as", newArr);
    let newArray = newArr.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(val.toLowerCase()) !== -1
    );
    setList(newArray);
  };

  useEffect(() => {
    if (appointmentlistData && appointmentlistData.length > 0) {
      search();
    }
  }, [appointmentlistData.length]);

  return (
    <>
     <Backdrop
        sx={{ color: "#5579C6", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 flex-wrap justify-start items-center">
        <div className="overflow-hidden">
          <div>
            <p className="text-xl text-gray-900 font-medium font-rubik">
              Namaste, Dr {userData.firstName} {userData.lastName}
            </p>
          </div>
        </div>

        <div className=" flex flex-row justify-center">
          <div className="bg-white shadow px-2 py-1  rounded-lg flex justify-between w-72 relative top-0 items-center" style={{marginRight:'12px'}}>
            <input
              value={searchText}
              onChange={(e) =>
                setSearchText(e?.target?.value ? e.target.value : " ")
              }
              className="w-full bg-transparent rounded text-sm text-gunsmoke pl-2"
              type="text"
              placeholder="Search for Patient/Hospital"
            />
            <button
              onClick={search}
              className="bg-brand-secondary  rounded text-white w-6 h-6 p-1 absolute right-2"
            >
              <SearchIcon color={"white"} />
            </button>
          </div>

          <div className="lg:flex justify-between lg:w-54 bg-white rounded-lg shadow  w-52" style={{marginLeft:'5px', width:'257px'}}>
            <DatePicker
             
              dateFormat={"MM/dd/yyyy"}
              placeholderText="Select From Date"
              selected={moment(startDate, "MM/DD/yyyy").toDate()}
              className="bg-transparent text-center p-2 w-full"
              onChange={(date) => handleStartDateChange(moment(date).format("MM/DD/yyyy"))}
            />

            <DatePicker
             
             
              dateFormat={"MM/dd/yyyy"}
              placeholderText="Select To Date"
              selected={moment(endDate, "MM/DD/yyyy").toDate()}
              className="bg-transparent text-center p-2 w-full"
              onChange={(date) => handleEndDateChange(moment(date).format("MM/DD/yyyy"))}
            />
          </div>
        </div>
        
        <div className="overflow-hidden ">
          <div className="flex flex-wrap justify-end">
            <p className="text-sm text-gray-900 font-medium font-rubik">
              {moment().format("Do MMMM, hh:mm A")}
            </p>
          </div>
        </div>
      </div>
      
      {isLoading && list.length === 0 && (
        <div
          className="flex justify-center items-center"
          style={{ height: "50vh" }}
        >
          <CircularProgress />
        </div>
      )}
      
      <div className="p-4 grid grid-cols-2 gap-4">
        {list.map((user, i) => (
          <div
            key={i}
            className=" w-full lg:max-w-full lg:flex pt-6 col-span-2 cursor-pointer"
            onClick={(e) => refer(e, user)}
          >
            <div className="lg:mx-10 w-full border border-gray-100 bg-white rounded-xl -none p-2 flex flex-col justify-between leading-normal">
              <div className="lg:flex justify-between">
                <div className="flex">
                  {user.patientPhotoName ? (
                    <div>
                      <img
                        src={
                          user.patientPhoto
                            ? IMG_URL + user.patientPhotoName
                            : vijay
                        }
                        alt="vijay"
                        class="profileRound object-cover"
                      />
                    </div>
                  ) : (
                    <div className="bg-white p-3  flex justify-center font-rubik  rounded-lg">
                      <span className="font-bold text-2xl">
                        {user.patientName?.charAt(0)}
                      </span>
                    </div>
                  )}

                  <div className="mb-4 w-11/12">
                    <div className="pl-3 ">
                      <p o className=" text-sm text-gray-700 font-medium">
                        {user.patientName}
                      </p>
                      <p className="text-xs font-medium w-56 text-gray-700">
                        Symptoms:
                        <span className="text-xs pl-1 font-normal text-gray-500">
                          {user.consultationsReason}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:absolute lg:left-0 lg:ml-auto lg:mr-auto lg:right-0 lg:w-1/6 w-1/6 w-9/12">
                  <p className="text-xs font-medium text-left text-gray-700">
                    Appointment No. :
                    <span className="text-xs pl-1 font-normal text-gray-500">
                      {user.id}
                    </span>
                  </p>
                  <p className="text-xs font-medium text-left text-gray-700">
                    {user.consultationsType == "V"
                      ? "Video"
                      : user.consultationsType == "A"
                      ? "Audio"
                      : user.consultationsType == "I"
                      ? "In Person"
                      : "Quick"}{" "}
                    :{" "}
                    <span className="text-xs pl-1 font-normal text-gray-500">
                      {user.hospitalName}
                    </span>
                  </p>
                </div>
                <hr className="lg:hidden m-3 " />
                <div className="flex mt-3 items-center justify-around space-x-3">
                  <div className="lg:hidden border-l h-8"></div>
                  <button className="lg:bg-brand-secondary text-xs  lg:text-white text-brand-secondary font-medium py-2 px-4 rounded-lg">
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default PatientsListing;
