import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import logo from '../Assets/Images/logo.svg';
import { viewImage, viewPDF } from "../helper/ImageDownload";
import { convertUTCToLocal } from '../helper/time';
import { CheckIcon, EyeIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";

export default function ViewInternalOrder(props) {

    const dispatch = useDispatch();

    const [locationObj, setLocationObj] = useState({});

    useEffect(() => {
        const location = JSON.parse(localStorage.getItem("locationObj"));
        setLocationObj(location)
        console.log(props.data);
    }, []);

    const redirectTo = (event) => {
        event.preventDefault();
        props.onClose();
    };

    const cancel = (e) => {
        props.closePopup(e);
        e.preventDefault();
    };

    const ViewFile = (e) => {
        viewPDF(e);
    }

    return (
        <>
            <div className="inline-block h-auto w-10/12 sm:w-full lg:w-11/48 align-bottom bg-white rounded-lg text-left  overflow-hidden transform transition-all sm:my-8 sm:align-middle">
                <div className="bg-white px-2 pt-5 pb-4 sm:p-6 sm:pb-4 ">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 sm:mt-0 sm:ml-2 sm:text-left ">
                            <div className="flex pt-2 w-full ">
                                <div className="w-full">
                                    <div className="flex justify-between">
                                        <img class="block h-12 w-auto cursor-pointer" src={logo} alt="Workflow" />
                                        {props?.data?.pdfReport && (
                                            <button
                                                onClick={() => {
                                                    if (props?.data?.pdfReport) {
                                                        window.open(props?.data?.pdfReport, "_blank");
                                                    } else {
                                                        toast("Report is not generated yet")
                                                    }
                                                }}
                                                style={{ height: 50, width: 150 }}
                                                className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                            >
                                                Download Report
                                            </button>
                                        )}
                                        <div>
                                            <p class="flex text-medium font-medium text-2xl font-rubik">{locationObj?.hospitalName}</p>
                                            <p class="flex text-medium font-medium text-xs font-rubik">{locationObj?.locationAddress1}</p>
                                            <p class="flex text-medium font-medium text-xs font-rubik">{locationObj?.locationAddress2}</p>
                                            <p class="flex text-medium font-medium text-xs font-rubik">{locationObj?.locationCity} {locationObj?.locationState} {locationObj?.locationPinCode}</p>
                                        </div>
                                    </div>
                                    <table className="min-w-full divide-y mt-5 divide-gray-200">
                                        <thead className="50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                    <b>Patient Name: </b> {props.data.patientName}
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                    <b>Age:</b> {props.data.patientAge}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                    <b>Gender:</b> {props.data.patientGender == 'M' ? 'Male' : props.data.patientGender == 'F' ? 'Female' : 'Others'}
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                    <b>Mobile:</b> {props.data.patientMobile}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                    <b>Patient ID:</b> {props.data.patientId}
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                    <b>Date of Test:</b> {convertUTCToLocal(props.data.createdDate, 'MM/DD/YYYY hh:mm:ss')}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>

                                    {(props.data.data1 > 0 || props.data.data2 > 0 || props.data.data3 > 0 || props.data.data4 > 0 || props.data.data5 > 0 || props.data.data16Photo != null || props.data.data17Photo != null || props.data.data18Photo != null) && (
                                        <table className="min-w-full divide-y mt-5 divide-gray-200">
                                            <thead className="50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                        <b>Test Name</b>
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                        <b>Value</b>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.data.data1 > 0 && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Total Cholesterol</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">{props.data.data1} mg/dL</td>
                                                    </tr>
                                                )}
                                                {props.data.data2 > 0 && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">LDL</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">{props.data.data2} mg/dL</td>
                                                    </tr>
                                                )}
                                                {props.data.data3 > 0 && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">HDL</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">{props.data.data3} mg/dL</td>
                                                    </tr>
                                                )}
                                                {props.data.data4 > 0 && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Triglycerides</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">{props.data.data4} mg/dL</td>
                                                    </tr>
                                                )}
                                                {props.data.data5 > 0 && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">HbA1c</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">{props.data.data5}%</td>
                                                    </tr>
                                                )}
                                                {props.data.data6 > 0 && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Blood Glucose</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">{props.data.data6} mg/dL</td>
                                                    </tr>
                                                )}
                                                {props.data.data7 > 0 && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">BG-PP</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">{props.data.data7} mg/dL</td>
                                                    </tr>
                                                )}
                                                {props.data.data8 > 0 && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">BG-Random</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">{props.data.data8} mg/dL</td>
                                                    </tr>
                                                )}
                                                {props.data.data14 > 0 && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Haemoglobin</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">{props.data.data14} g/dL</td>
                                                    </tr>
                                                )}
                                                {props.data.data16Photo != null && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">ECG</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                            {props.data.data16Photo != null && (
                                                                <EyeIcon onClick={(e) => { ViewFile(props.data.data16Photo) }} className="w-6 h-6 cursor-pointer" />
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                {props.data.data17Photo != null && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Lung Function Test</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                            {props.data.data17Photo != null && (
                                                                <EyeIcon onClick={(e) => { ViewFile(props.data.data17Photo) }} className="w-6 h-6 cursor-pointer" />
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                {props.data.data18Photo != null && (
                                                    <tr>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Breast Screening Test</td>
                                                        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                            {props.data.data18Photo != null && (
                                                                <EyeIcon onClick={(e) => { ViewFile(props.data.data18Photo) }} className="w-6 h-6 cursor-pointer" />
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}

                                    {(props.data.data9 > 0 || props.data.data10 > 0 || props.data.data11 > 0 || props.data.data12 > 0 || props.data.data13 > 0) && (
                                        <table className="min-w-full divide-y mt-5 divide-gray-200">
                                            <thead className="50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                        <b>Test Name</b>
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                        <b>Report Description</b>
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                        <b>Report Tick</b>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.data.data9 > 0 && (
                                                    <>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider" rowSpan="4">Malaria</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Negative</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data9 === 1 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Positive for P.vivax malaria</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data9 === 2 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Positive for P.falciparum malaria</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data9 === 3 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Positive for P.falciparum and P.vivax malaria</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data9 === 4 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                                {props.data.data10 > 0 && (
                                                    <>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider" rowSpan="2">Typhoid</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Negative</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data10 === 1 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Positive</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data10 === 2 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                                {props.data.data11 > 0 && (
                                                    <>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider" rowSpan="4">Dengue</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Negative</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data11 === 1 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Positive - Dengue virus specific LgG and LgM antibodies</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data11 === 2 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Positive - Dengue virus specific LgM antibodies</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data11 === 3 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Positive - Dengue virus specific LgG antibodies</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data11 === 4 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                                {props.data.data12 > 0 && (
                                                    <>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider" rowSpan="5">Anaemia</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Normal</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data12 === 1 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Normal or trait of other hemoglobinopathies</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data12 === 2 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Sickle cell disease</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data12 === 3 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Sickle cell trait or its association with hemoglobinopathies</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data12 === 4 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Other Heloglobinopathies or Thalassemis or other hemoglobinopathies associated with thalassemis</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data12 === 5 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                                {props.data.data13 > 0 && (
                                                    <>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider" rowSpan="2">Covid</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Negative</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data13 === 1 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">Positive</td>
                                                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 border border-black-200 tracking-wider">
                                                                {props.data.data13 === 2 && (
                                                                    <CheckIcon className="w-6 h-6" />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    )}

                                    <p class="flex text-medium font-normal text-xs mt-3 font-rubik">
                                        Disclaimer: This is report of Rapid diagnostic tests (RDTs), which are diagnostic assays designed for use at the point-of-care (POC)
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
