import moment from 'moment';
/* CONVERT UTC DATE FORMAT START */

 export const constconvertUTCDate = (date, format) => {
    if(!format) {
      const d = new Date();
      date = new Date(moment(date, 'MM-DD-YYYY').format('MM/DD/YYYY') + ' ' + d.getHours() + ':' + d.getMinutes());
    }
    format = format || 'MM/DD/YYYY'; // YYYY-MM-DD hh:mm:ss
    return moment(new Date(date)).utc().format(format);
    // return moment(new Date(date)).add(moment(date).utcOffset(), 'm').utc().seconds(30).format(format);
  }

  /* CONVERT UTC DATE FORMAT END */



  export const convertUTCToLocal = (date, format) =>{
    format = format || 'MM/DD/YYYY'; // YYYY-MM-DD hh:mm:ss
    const localTime = moment.utc(date).toDate();
    return moment(localTime).format(format);
  }