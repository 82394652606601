import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import Slotservice from "../Redux/services/Slotservice";
import stamps from '../Assets/Images/stamp.png';
import { encodeBase64File } from "../helper/filebase64";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment, { now } from "moment";
import userprofileservice from "../Redux/services/userprofileservice";
import { data } from "autoprefixer";
import { IMG_URL } from "../config/constant";

export default function AddMapHospital(props) {
    const dispatch = useDispatch();

    const [hospitallist, setHospitalList] = useState([]);
    const [locationList, setLocationList] = useState([]);

    const [hospitalId, setHospitalId] = useState();
    const [locationId, setLocationId] = useState();
    const [stamp, setStamp] = useState();
    const [stampUpdate, setStampNew] = useState(false);


    const [imageUploaded, setImageUploaded] = useState(false);

    const [mapHospital, setMapHospital] = useState({});

    useEffect(() => {
        getHospitalsList();
        if (props.editData) {
            console.log(JSON.stringify(props.editData))
            setHospitalId(props.editData["hospitalId"]);
            console.log(props.editData["hospitalId"]);
            setLocationId(props.editData["locationId"]);

            setStamp(props.editData.stampName ? IMG_URL + props.editData.stampName : stamps);
            getLocationsList(props.editData["hospitalId"]);
            setStampNew(true)
        }
    }, []);

    const redirectTo = (event) => {
        event.preventDefault();
        props.onClose();
    };

    const getHospitalsList = () => {
        let payload = {
            type: 'H',
            status: 1
        }

        Slotservice.getAllHospitalslist(payload).then((res) => {
            if (res.data) {
                setHospitalList(res.data);
                console.log(res.data)
            }
        }, (err) => {
            console.log(err);
        })
    }

    const getLocationsList = (hospitalId) => {
        let payload = {
            hospitalCode: hospitalId,
            status: 1
        }

        Slotservice.getLocationlist(payload).then((res) => {
            if (res.data) {
                setLocationList(res.data);
            }
        }, (err) => {
            console.log(err);
        })
    }

    const save = (e) => {
        let userObj = JSON.parse(localStorage.getItem('userObj'));
        if (!hospitalId) {
            toast("Please Select Hospital");
        }
        if (!locationId) {
            toast("Please Select Location");
        }
        if (!stamp) {
            toast("Please Upload Stamp");
        }

        let payload = {
            userId: userObj.code,
            hospitalType: 'H',
            hospitalId: hospitalId,
            locationId: locationId,
            status: 1,
            stamp: stamp
        }
        console.log(props);
        console.log(JSON.stringify(payload));

        if (!props.editData) {
            payload.createdDate = moment().format("YYYY-MM-DD HH:mm:ss");
            payload.createdBy = userObj.code;
            payload.stamp = stamp;
            console.log(JSON.stringify(payload));
            userprofileservice.saveMappedHospital(payload).then((res) => {
                if (res.data) {
                    if (!res.data.message) {
                        toast('Mapped successfully');
                        setTimeout(() => {
                            // props.updateLoad(locationId,hospitalId)
                            props.closePopup();
                        }, 2500);
                    } else {
                        console.log(res.data.message)
                        toast(res.data.message);
                    }
                }
                console.log(res);
            }, (err) => {
                console.log(err);
            });
        } else {
            payload.id = props.editData.id;
            payload.modifiedDate = moment().format("YYYY-MM-DD HH:mm:ss");
            payload.modifiedBy = userObj.code;
            userprofileservice.updateMappedHospital(payload, payload.id).then((res) => {
                console.log("lolo", res.data)
                if (res.data) {
                    toast('Mapped successfully');
                    setTimeout(() => {
                        props.updateLoad(locationId, hospitalId)
                        props.closePopup();
                    }, 2500);
                }
            }, (err) => {
                console.log(err);
            });
        }


        /*props.filterDoctors(filterlist);
        e.preventDefault();*/
    };
    const cancel = (e) => {
        props.onClose(e);
        e.preventDefault();
    };

    const handleChange = (e) => {
        setMapHospital({ ...mapHospital, [e.target.name]: e.target.value });
        if (e.target.name == 'hospital') {
            setHospitalId(e.target.value);
            getLocationsList(e.target.value);
        }
        if (e.target.name == 'location') {
            setLocationId(e.target.value);
        }
    };

    const hiddenFileInput = React.useRef(null);
    const clicktype = () => {
        hiddenFileInput.current.click();
    };

    const changeHandler = async (file) => {
        console.log("photo", file);
        let b64File = await encodeBase64File(file);
        setImageUploaded(true);
        setStamp(b64File);
        setStampNew(true)
    };

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <ToastContainer />
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 antialiased justify-between border border-gray-200">
                            <div className="flex justify-between">
                                <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">
                                    Map Hospital
                                </h1>
                                {/* <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">Add New Address</h1> */}
                                <XIcon onClick={redirectTo} className="h-5 cursor-pointer" />
                            </div>
                            <hr className="mt-2"></hr>

                            <div className="flex pt-2 w-full ">
                                <div className="w-full">
                                    <div className="pt-4 lg:space-x-10">

                                        <div className="relative">
                                            <div className="flex">
                                                <select
                                                    id="hospital"
                                                    name="hospital"
                                                    value={hospitalId}
                                                    onChange={(handleChange)}
                                                    className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600">
                                                    {
                                                        <>
                                                            <option className="py-1 text-sm text-green-600" value="" selected disabled> Select Hospital </option>
                                                            {hospitallist.map((res, i) => (
                                                                <option key={i} className="py-1 text-sm text-green-600" value={res.code}>{res.name}</option>
                                                            ))}
                                                        </>}
                                                </select>
                                            </div>
                                            <label
                                                for="hospital"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                            >
                                                Hospital
                                            </label>
                                        </div>



                                        {/* {showinput ? <input autocomplete="off" id="email" name="email" type="text" value="hello" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" /> : null}   */}
                                    </div>

                                    <div className="pt-10 lg:space-x-10">

                                        <div className="relative">
                                            <div className="flex">
                                                <select
                                                    id="location"
                                                    name="location"
                                                    onChange={handleChange}
                                                    className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600">
                                                    {
                                                        <>
                                                            <option className="py-1 text-sm text-green-600" value="" selected={!locationId} disabled> Select Location </option>
                                                            {locationList.map((res, i) => (
                                                                <option key={i} className="py-1 text-sm text-green-600" value={res.code} selected={res.code == locationId}>{res.name}</option>
                                                            ))}
                                                        </>}
                                                </select>
                                            </div>
                                            <label
                                                for="doctorLocation"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                            >
                                                Location
                                            </label>
                                        </div>

                                    </div>

                                    <div className="lg:flex justify-center py-1 lg:space-x-10">

                                        <div className="relative mb-1">
                                            <div className="border border-gray rounded-md  bg-white m-3  mx-auto flex gap-2 flex-col items-center justify-center" onClick={clicktype}>
                                                {props.editData && !imageUploaded && (
                                                    <img src={stamp ? stamp : stamps} className="a-center" alt="upload stamp" height="150" width="150" />
                                                )}
                                                {props.editData && imageUploaded && (
                                                    <img src={stamp ? 'data:image;base64,' + stamp : stamps} className="a-center" height="150" width="150" alt="upload stamp" />
                                                )}
                                                {!props.editData && (
                                                    <img src={stamp ? 'data:image;base64,' + stamp : stamps} className="a-center" height="150" width="150" alt="upload stamp" />
                                                )}
                                                {!props.editData && (
                                                    <span> Upload Seal</span>
                                                )}

                                            </div>
                                            <input
                                                ref={hiddenFileInput}
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    changeHandler(e.target.files[0]);
                                                }}
                                                className="hidden"
                                            />
                                        </div>

                                    </div>

                                    <div className="relative ">
                                        <div className="flex"></div>
                                    </div>
                                    <div className="flex justify-end">
                                        <button
                                            type="submit"
                                            onClick={cancel}
                                            className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            onClick={save}
                                            disabled={!stampUpdate}
                                            className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}