import { useDispatch, useSelector } from "react-redux";
import more from "../Assets/Images/More .svg";
import hearts from "../Assets/Images/heart.svg";
import analytics from "../Assets/Images/analytics.png";
import { Bar } from "react-chartjs-2";
import icon from "../Assets/Images/Icon 24px.png";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import Patientprofilevitalsdropdown from "./patientprofilevitalsdropdown";
import { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  BellIcon,
  ShoppingCartIcon,
  MenuIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import Allvitalspopup from "./Allvitalspopup";
import { Button } from "react-bootstrap";
import Addvitalspopup from "./Addvitalspopup";
import Removevitalspopup from "./Removevitalspopup";
import VitalChart from "./VitalsTrendChart";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Weightcard(props) {
  const history = useHistory();
  const location = useLocation();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [showviewall, setshowviewall] = useState(false);
  const [showentervitals, setshowentervitals] = useState(false);
  const [showremovevitals, setshowremovevitals] = useState(false);
  const [data, setData] = useState();
  const [graphPop, setGraphPop] = useState(false);

  const viewDetails = (event, det) => {
    event.preventDefault();
    console.log(det);
    setData(det);
    setshowviewall(true);
    setshowentervitals(false);
    setshowremovevitals(false);
  };

  const addvitals = (event, det) => {
    event.preventDefault();
    console.log(det);
    setData(det);
    setshowviewall(false);
    setshowremovevitals(false);
    setshowentervitals(true);
  };

  const removevitals = (event, det) => {
    event.preventDefault();
    console.log(det);
    setData(det);
    setshowviewall(false);
    setshowentervitals(false);
    setshowremovevitals(true);
  };

  const saveSuccess = (e) => {
    console.log("save success");
    setshowentervitals(false);
    props.saveCallback(e);
  };

  return (
    <>
      {props?.data?.slice(0, 1).map((user, i) => (
        <>
          <div class="w-full p-4 border border-gray-200 rounded-xl justify-between ">
            <ToastContainer />
            <div class="flex justify-between">
              <p class="text-medium font-medium text-xl font-rubik text-brand-secondary">
                Weight
              </p>
              <div class="flex justify-end space-x-2">
                <div
                  className="cursor-pointer"
                  onClick={() => setGraphPop(true)}
                >
                  <img src={analytics} alt="more" className="w-6" />
                </div>

                {/* <img src={more} alt="more" /> */}

                <>
                  <Menu as="div" className="ml-3 relative">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button
                            className={` flex text-sm rounded-full focus:outline-none`}
                          >
                            <img src={more} alt="more" />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-28 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  onClick={(e) => viewDetails(e, user)}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  View All
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  onClick={(e) => addvitals(e, user)}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Update
                                </Link>
                              )}
                            </Menu.Item>

                            {/* <Menu.Item>
                                                            {({ active }) => (
                                                                <Link
                                                                    to="#"
                                                                    onClick={
                                                                        (e) => removevitals(e, user)

                                                                    }
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100' : '',
                                                                        'block px-4 py-2 text-sm text-gray-700'
                                                                    )}
                                                                >
                                                                    Remove
                                                                </Link>
                                                            )}
                                                        </Menu.Item> */}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </>
              </div>
            </div>
            <div class="flex justify-between pt-5">
              <p class="text-sm font-medium text-brand-secondary font-rubik">
                Kilogram
              </p>
              <img src={icon} alt="temp" className="pr-2" />
            </div>
            <div class="flex justify-center mt-4 space-x-20 ">
              {/* <div class="text-center">
                                <p class="lg:text-lg font-medium text-brand-secondary font-rubik">Avg</p>
                                <span class="text-2xl text-brand-secondary text-center">{user.weight} <span className="text-2xl text-brand-secondary text-center">kg</span> </span>
                            </div> */}

              <div class="text-center pl-4">
                <p class="lg:text-lg font-medium text-brand-secondary font-rubik">
                  Latest
                </p>
                <span class="text-2xl text-brand-secondary text-center">
                  {user.weight}
                  <span className="text-2xl text-brand-secondary text-center">
                    {" "}
                    kg
                  </span>{" "}
                </span>
              </div>
            </div>

            {showviewall ? (
              <Allvitalspopup
                data={data}
                title={"Weight"}
                closePopup={() => setshowviewall(!showviewall)}
              ></Allvitalspopup>
            ) : null}

            {showentervitals ? (
              <Addvitalspopup
                data={props.data[0]}
                title={"Weight"}
                closePopup={() => {
                  setshowentervitals(!showentervitals);
                  props.saveCallback();
                }}
              ></Addvitalspopup>
            ) : null}

            {showremovevitals ? (
              <Removevitalspopup
                title={"Weight"}
                closePopup={() => {
                  setshowremovevitals(!showremovevitals);
                  props.saveCallback();
                }}
              ></Removevitalspopup>
            ) : null}

            {graphPop ? (
              <VitalChart
                title={"Weight Graph"}
                data={props.data}
                type="weight"
                closePopup={() => setGraphPop(false)}
              />
            ) : null}
          </div>
        </>
      ))}
    </>
  );
}

export default Weightcard;
