import { DIAG } from "../Constants/DiagnosticsConstants";
import { PAT } from "../Constants/patientConstants";
import { VITALS } from "../Constants/vitalConstants";


const initialState = {
    labtestlistData: [],
    isError: false,
    isLoading: false,
    msg: '',
    errMsg: ''
};


const labtestlistReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DIAG.REQUEST_LABTESTLIST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case DIAG.SUCCESS_LABTESTLIST:
            return {
                ...state,
                isLoading: false,
                labtestlistData : payload,
                isError: false,              
            };
        case DIAG.FAILED_LABTESTLIST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };
        default:
            return state;
    }
};

export default labtestlistReducer;