/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect } from "react";
//import Userprofilesidebar from "../userprofilesidebar";
import { useDispatch, useSelector } from "react-redux";


import { useHistory, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import FamilyDropdown from "./Familydropdown";
//import Patientprofileupbar from "./Patientprofileupbar";
//import AppointmentDetailspopup from "./Appointmentdetailspopup";
//import CancelAppointmentPopup from "./CancelAppointmentPopup";
import { getFilteredAppointmentList } from "../Redux/Actions/patientAction";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import ConsulatationReport from "./ConsultationReport";
import PatientService from "../Redux/services/patientService";
function PatientprofilereactEpresc(props) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showCancelappointmentpopup, setshowCancelappointmentpopup] =
    useState(false);
  const [appointmentdetails, setappointmentdetails] = useState();
  const [isHide, setHide] = useState(true);
  const [visitList, setvisitList] = useState([]);
  const [preView, setPreView] = useState(false)
  const [openPreview, setopenPreview] = useState(false)

  const history = useHistory();
  const location = useLocation();
  const [viewData, setData] = useState();
  const [viewAppData, setAppData] = useState();
  const patientappointmentlist = useSelector(
    (state) => state.patientappointmentlist
  );
  const [appointmentlistData, setappointmentlistData] = useState([]);


  const patientdetails = useSelector((state) => state.particularpatientdetails);

  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
  };

  const viewprofile = (e, doct) => {
    e.preventDefault();
    setShowModal(true);
    setappointmentdetails(doct);
  };

  const openModal = (val) => {
    setappointmentdetails(val);
    setHide(false);
  };

  const getPatientAppointment = () => {
    let patient = localStorage.getItem("patientprofile");
    let payload = {
      patientId: patient,
      status: 2
    }
    dispatch(getFilteredAppointmentList(payload)).then((result) => {
      setappointmentlistData(result);
      //setnumberofappointments(result.length);
      // search();
    })
      .catch((error) => {
      });
    //    let payload = {
    //     patientId: patient,
    //     visitSummary = 'Y',
    //     documentRequired = 'Y'
    //    }
    //    Appointmentsservice.getvisit(payload).then((res1) => {
    //     console.log(res1);
    //     if(res1.data && res1.data.length){
    //         setvisitList(res1.data);
    //     }
    //    });
  };
  //    Diagnosticsservice.getFacilityDiscountPercent(res.locationId).then((res1) => {
  //     console.log(res1);
  //    });
  //   };

  // const getFamilyMemberAppointment = (patient) => {
  //   let payload = {
  //       patientId: patient,
  //       status: 2
  //   }
  //   dispatch(getPatientAppointmentList(patient));
  // }

  useEffect(() => {
    getPatientAppointment();
  }, []);

  useEffect(() => {
    // getPatientAppointment();
  }, [appointmentlistData.length || appointmentlistData])

  const changedPatient = (patient) => {
    history.push({ pathname: `/profile/mydetails` });
  }
  const onViewClick = (user) => {
    if (location.pathname.split('/')[1] == "videoCall") {
      let payload = user.id;
      PatientService.getPostConsultation(payload).then((res) => {
        setAppData(res.data[0])
        setData(user)
        setopenPreview(true);
      })
    } else {
      history.push({ pathname: APP_ROUTES.POST_CONSULTATION, state: user })
    }
  }

  return (
    <>
      {/* <Patientprofileupbar patientChanged={changedPatient}></Patientprofileupbar> */}
      {/* <ul class="lg:flex hidden text-brand-secondary text-sm lg:text-base pl-10 pt-2">
        <li class="inline-flex items-center">
          <a href="/dashboard">Home</a>
          <svg
            class="h-5 w-auto text-brand-secondary"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </li>
        <li class="inline-flex items-center">
          <a href=""> Profile</a>
        </li>
      </ul> */}

      <div class="flex justify-between ">
        {/* <div class="lg:block hidden w-3/12 ml-6 mt-6">
          <Patientprofilesidebar></Patientprofilesidebar>
        </div> */}

        {/* <div class="lg:w-8/12 w-full  md:mr-4 lg:mr-16 lg:ml-0 mt-6 "> */}
        <div className="flex w-full">
          <div className="-my-2 lg:-mx-6 w-full">
            <div className="py-2 px-3 align-middle inline-block w-full sm:px-6 lg:px-8">
              {/* <FamilyDropdown
                  title={"Get Appointments For"}
                  onSelect={getFamilyMemberAppointment}
                /> */}
              {
                appointmentlistData.length === 0 &&
                <div className="flex relative flex-wrap items-center justify-center m-5">
                  <div className="loader " />
                </div>
              }

              {appointmentlistData.length === 0 ? (
                <p className="text-center item-center mt-40 mb-40  ">
                  No Prescription available
                </p>
              ) : (
                <>
                  <div className="shadow w-full  border border-gray-200 sm:rounded-lg mb-8 ">
                    <hr classname="border-dash text-black w-10 mt-4 h-20 my-2"></hr>
                    <div className="h-80 w-full sm:rounded-lg overflow-y-scroll overflow-x-scroll custom-scroll">
                      <table className="max-w-full h-28 divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <div className="flex ">
                              {/* <input type="radio" class="form-radio mt-2 my-2" name="accountType" value="personal" /> */}
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Reason for Consultation
                              </th>

                            </div>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Doctor Name
                            </th>

                            {/* <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Type
                              </th> */}
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Date
                            </th>
                            {/* <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Status
                              </th> */}

                            <th
                              scope="col"
                              className="pr-2 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Action
                            </th>
                            {/* <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Edit</span>
                              </th> */}
                          </tr>
                        </thead>
                        <tbody className=" divide-y divide-gray-200">
                          {appointmentlistData.map((user, i) => (
                            <tr>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className=" flex space-x-2">
                                    <input
                                      type="radio"
                                      class="form-radio mt-q mr-2"
                                      name="accountType"
                                      value="personal"
                                    />
                                    <div className="text-sm font-medium text-gray-500 font-montserrat">
                                      {user.consultationsReason}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className=" flex space-x-2">

                                    <div className="text-sm font-medium text-gray-500 font-montserrat">
                                      Dr. {" "} {user.userName}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              {/* <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-500 font-montserrat">
                                  {user.consultationsType == 'A' ? 'Audio Consultation' : user.consultationsType == 'V' ? 'Video Consultation' : user.consultationsType == 'Q' ? 'Quick Consultation' : 'In-person Consultation' }
                                  </div>
                                </td> */}
                              <td className="px-6 py-4 whitespace-nowrap">
                                <span className="text-sm text-gray-500 font-montserrat">
                                  {moment(user.whenAppointment).format("DD/MM/yyyy")}&nbsp;at&nbsp;
                                  {user.fromTime}
                                </span>
                              </td>
                              {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-montserrat">
                                  
                                  {user.status == 1 ? 'Upcoming' : user.status == 2 ? 'Completed' : user.status == 3 ? 'Cancel By Patient' : user.status == 4 ? 'Cancel By Doctor' : user.status == 5 ? 'Cancel By Admin' : user.status == 6 ? 'Cancel By Customer Care' : user.status == 7 ? 'Cancelled by CHE Centre' : 'Pending'}
                                </td> */}
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <div className="flex space-x-4">
                                  {/* <button
                                      onClick={(e) => viewprofile(e, user)}
                                      className=" mr-2 text-brand-secondary hover:text-brand-secondary"
                                    >
                                      View
                                    </button> */}



                                  {user.status === 2 &&
                                    <button
                                      onClick={() => onViewClick(user)}
                                      // onClick={() => history.push({ pathname: APP_ROUTES.POST_CONSULTATION, state: user })}
                                      className=" mr-2 text-brand-secondary hover:text-brand-secondary"
                                    >
                                      View Prescription
                                    </button>}

                                  {/* {user.status === 1 &&
                                      <button
                                        onClick={() => openModal(user)}
                                        className=" mr-2 text-brand-secondary hover:text-brand-secondary"
                                      >
                                        Reschedule
                                      </button>} */}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}

              {/* <div className="flex lg:justify-end">
                <button
                  onClick={(e) => redirectTo(e, APP_ROUTES.DASHBOARD)}
                  className="bg-brand-secondary font-rubik text-white py-3.5 px-6 font-medium rounded-xl mb-8"
                >
                  Back to Dashboard{" "}
                </button>
              </div> */}
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      {openPreview &&
        (<Dialog
          header="Prescription Preview"
          visible={true}
          // style={{ width: "25vw" }}
          modal
          className="w-11/12 md:w-9/12 lg:w-8/12"
          onHide={() => setopenPreview(false)}>
          <ConsulatationReport patient={viewData} data={viewAppData} open={openPreview} onClose={() => setopenPreview(false)} > </ConsulatationReport>
        </Dialog>)
        // patient={state} data={visitData} onClose={setopenPreview(false)}
      }

      {/* {!isHide && (
        <RescheduleModal
          data={appointmentdetails}
          onClose={() => onRescheduleModelClose()}
        />
      )} */}

      {/* {showModal ? (
        <AppointmentDetailspopup
          appointmentdetails={appointmentdetails}
          closePopup={() => {
            setShowModal(!showModal);
            onRescheduleModelClose();
          }}
        ></AppointmentDetailspopup>
      ) : null} */}

      {/* {
                showCancelappointmentpopup ? <CancelAppointmentPopup closePopup={() => setshowCancelappointmentpopup(!showCancelappointmentpopup)}></CancelAppointmentPopup>
                    : null
            } */}

      {/* {
                showrescheduleappointment ? <AddinsurancePopup closePopup={() => setshowrescheduleappointment(!showrescheduleappointment)}></AddinsurancePopup>
                    : null
            } */}
    </>
  );
}
export default PatientprofilereactEpresc;
