import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import patientService from "../Redux/services/patientService";
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';

export default function Share(props) {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [err, setErr] = useState("");
    const { userData } = useSelector(state => state.authReducer);
    const save = (e) => {

        e.preventDefault();

        console.log(props.data)
        if (name.trim() === "") {
            setErr("Please enter the value")
            //alert("required both field");
        } else {
            var validation = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
            var number = /^\d+$/;
            var data = {};
            let url = (window.location.href.substring(0, window.location.href.lastIndexOf('/')))
            if(url.includes("localhost") ){
                url = process.env.REACT_APP_USER_BASEURL
            }
            if (validation.test(name)) {
                if(number.test(name))
                {
                    
                     data = {
                        patientMobileNo: name,
                        url: url + '/'+APP_ROUTES.SHARECALL.split('/')[1]+'/'+props.data.id+'/'+props.data.consultationsType,
                        patientId: props.data.patientId,
                        patientName:props.data.patientName,
                        userId:userData.code
                    }
                }else{
                     data = {
                        patientEmail: name,
                        url: url + '/'+APP_ROUTES.SHARECALL.split('/')[1]+'/'+props.data.id+'/'+props.data.consultationsType,
                        patientId: props.data.patientId,
                        userId:userData.code
                    }
                }
               
                // alert(JSON.stringify(data))
                patientService.share(data).then((res) => {
                    props.onClose();
                }, (err) => {
                    setErr("Something went wrong")
                });
                props.closePopup(e);
            } else {
                setErr("Please enter the valid email or phone")
            }

        }

    };
    const cancel = (e) => {
        props.closePopup(e);
        e.preventDefault();
    };

    const redirectTo = (event) => {
        event.preventDefault();
        props.onClose();
    };

    useEffect(() => {

    }, []);

    const onChangeHandler = (fieldName, value) => {
        if (fieldName === "name") {
            setName(value);
            setErr("")
        }

    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-5/6 md:w-3/6 lg:w-2/6 my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 antialiased justify-between border border-gray-200">
                            <div className="flex justify-between">
                                <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">
                                    Share
                                </h1>
                                {/* <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">Add New Address</h1> */}
                                <XIcon onClick={redirectTo} className="h-5 cursor-pointer" />
                            </div>
                            <hr className="mt-2"></hr>

                            <div className="flex pt-2 w-full ">
                                <div className="w-full">
                                    <form onSubmit={(e) => { save(e) }}>
                                        <div className={"lg:flex justify-between lg:space-x-10 "+(err?"py-1":"py-4")}>
                                            <input type="text"
                                            placeholder="Enter Email/Mobile"
                                                onChange={(e) => { onChangeHandler("name", e.target.value) }}
                                                className="w-full peer bg-transparent h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                            />

                                        </div>
                                        {err ? <span className="text-red-900">{err}</span> : null}
                                        <div className="flex justify-end">
                                            <button
                                                type="submit"
                                                onClick={cancel}
                                                className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                            >
                                                Cancel
                                            </button>


                                            <input type="submit" value="Send"
                                                className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2 cursor-pointer" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
