import React, { useEffect, useState } from 'react';
import close from '../Assets/Images/closeee.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import DatePicker from "react-datepicker";
import { patientaddAddiction } from "../Redux/Actions/UserprofileActions";
import { getAddictionList } from "../Redux/Actions/UserprofilehealthrecordAction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MultiSelect } from "primereact/multiselect";
const addictionList = [
    { label: 'Alcohol', value: 'Alcohol' },
    { label: 'Tobacco', value: 'Tobacco' },
    { label: 'Sweets/sugary foods', value: 'Sweets/sugary foods' },
    { label: 'Cocaine', value: 'Cocaine' },
    { label: 'LSD', value: 'LSD' },
    { label: 'Marijuana', value: 'Marijuana' },
    { label: 'Others', value: 'Others' },
];
function AddAddiction(props) {
    const goBack = () => {
        props.closePopup();
    }
    const [memberList, setMemberList] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [memberCode, setMemberCode] = useState("");
    const [FamilymemberName, setFamilymemberName] = useState("");
    const [dropDownValue, setdropDownValue] = useState([]);
    const [textValue, settextValue] = useState(false);
    const [addmedicalhistory, setaddmedicalhistory] = useState({});

    const handleChange = (e) => {
        setaddmedicalhistory({
            ...addmedicalhistory,
            [e.target.name]: e.target.value,
        });
    };
    const handledropValue = (value) => {
        setdropDownValue(value)
        if (value.includes('Others')) {
            settextValue(true)
        } else {
            settextValue(false)
        }
    }
    const changeDate = (e) => {
        setaddmedicalhistory({
            ...addmedicalhistory,
            givenDate: moment(e).format("yyyy-MM-DD HH:mm:ss"),
        });
    };

    const patientdata = localStorage.getItem("patientprofile")

    const savemedicalhistorys = (e) => {
        if (!dropDownValue) {
            toast("Select Past Medical history")
        } else if (dropDownValue.includes('Others') && !addmedicalhistory.description) {
            toast("Enter Information")
        } else {
            let patient = localStorage.getItem("patientprofile")
            const givenDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
            const data = {
                "patientId": props.patient ? props.patient : patient,
                "patientCode": props.patient ? props.patient : patient,
                "givenDate": addmedicalhistory.givenDate,
                "status": 1,
                "createdBy": props.patient ? props.patient : patient,
                "modifiedBy": props.patient ? props.patient : patient,
                "description": addmedicalhistory.description ? addmedicalhistory.description : null,
                "alcoholAddiction": dropDownValue.includes('Alcohol') ? "Alcohol" : "",
                "tobaccoAddiction": dropDownValue.includes('Tobacco') ? "Tobacco" : "",
                "sweetsAddiction": dropDownValue.includes('Sweets/sugary foods') ? "Sweets/sugary foods" : "",
                "cocaineAddiction": dropDownValue.includes('Cocaine') ? "Cocaine" : "",
                "lsdAddiction": dropDownValue.includes('LSD') ? "LSD" : "",
                "marijuanaAddiction": dropDownValue.includes('Marijuana') ? "Marijuana" : "",
            }
            dispatch(patientaddAddiction(data)).then((result) => {
                dispatch(getAddictionList(patient));
                props.closePopup()
            })
        }
    };
    return (
        <>
            <ToastContainer />
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative xs-w-80 w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full p-5 bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <div className="am:pl-2 sm:pr-5  flex items-center justify-between">
                            <p className="inline sm:px-2 rounded-full text-md font-rubik font-medium cursor-pointer text-brand-secondary">
                                Add Addictions
                            </p>
                            <div className="flex space-x-6 cursor-pointer">
                                <img src={close} alt="close" className="w-4" onClick={goBack} />
                            </div>
                        </div>
                        <hr className="mt-2" />
                        <div className="lg:flex justify-between pt-5">

                            <div>
                                <div className="flex space-x-12 sm:pt-4 lg:pl-12 sm:p-4">
                                    <div className="relative">
                                        <div className="relative mb-4">
                                            <label
                                                htmlFor="recordOn"
                                                className="block text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                            >
                                                Record On
                                            </label>
                                            <DatePicker
                                                id="recordOn"
                                                name="givenDate"
                                                dropdownMode="select"
                                                showMonthDropdown
                                                showYearDropdown
                                                dateFormat="dd/MM/yyyy"
                                                value={addmedicalhistory.givenDate ? moment(addmedicalhistory.givenDate).format("DD/MM/yyyy") : ""}
                                                onSelect={changeDate}
                                                disabledKeyboardNavigation={true}
                                                autoFocus={false}
                                                placeholderText="Record On"
                                                className={
                                                    "border-b-2 border-gray-300 pt-2 text-gray-900 bg-transparent text-sm"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="flex space-x-12 sm:pt-4 lg:pl-8 sm:px-3">
                                    <p class="text-xs font-rubik ml-4 font-medium text-gray-primary">
                                        Addictions<span className="text-red-500">*</span>
                                    </p>
                                </div>
                                <div className="flex space-x-12 sm:pt-4 lg:pl-8 sm:px-3 mb-4">

                                    <div class="h-10 w-flex border border-gray-200  ml-4  rounded-lg flex space-x-2">
                                        <MultiSelect
                                            value={dropDownValue}
                                            options={addictionList}
                                            // style={{ width: '100% !important', maxWidth: '350px !important' }}
                                            panelClassName={{ width: '100%' }}
                                            panelStyle={{ width: "100%" }}
                                            className="w-full peer maxwidth-panel h-10 border-b-2 text-gray-500 border-black-500 focus:outline-none focus:borer-rose-600"
                                            onChange={(e) => handledropValue(e.value)}
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="Select Addiction options"
                                            filter={true}
                                            filterPlaceholder="Select"
                                        />
                                    </div>
                                </div>
                                {textValue && <>
                                    <div class="flex space-x-12 sm:pt-4 lg:pl-8 sm:px-3">
                                        <p class="text-xs font-rubik ml-4 font-medium text-gray-primary">
                                            Other Addictions<span className="text-red-500">*</span>
                                        </p>
                                    </div>
                                    <div className="flex space-x-12 sm:pt-4 lg:pl-12 sm:px-3 pt-10">
                                        <div className="relative">
                                            <div className="flex md:w-96">
                                                <textarea
                                                    autocomplete="off"
                                                    id="description"
                                                    name="description"
                                                    value={addmedicalhistory.description}
                                                    rows={4}
                                                    type="text"
                                                    onChange={handleChange}
                                                    className="peer w-full text-sm px-1 h-50 rounded-lg border-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    placeholder="Type Here..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }

                                <div className="flex justify-end mt-3">
                                    <button
                                        onClick={savemedicalhistorys}
                                        className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                    >
                                        Save Data{" "}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/*  */}

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default AddAddiction;
