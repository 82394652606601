import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import share from "../Assets/Images/share.svg";
import PatientDetails2 from "./paitentDetails2";
import { Dialog } from "primereact/dialog";
import patientService from "../Redux/services/patientService";
import ViewPEPopup from "./ViewPEPopup";
function Call(props) {
  console.log("Patient Result Details : " + JSON.stringify(props));
  const history = useHistory();
  const [showPatientProfile, setshowPatientProfile] = useState(false);
  const [paramVal, setParamVal] = useState({});
  const [showFilter, setShowFilter] = useState(false);

  const handleClick = (val) => {
    // window.open(`/patient_details/${val.patientId}`);
    history.push(`/patient_details/${val.patientId}`);
  };
  const closePopup = () => {

  }
  // const refer = (val) => {
  //   if (val.patientAccess == 1) {
  //     props.clickPop(true)
  //     console.log(props);

  //     const newval = {
  //      callUrl: props.callUrl, callType: props.callType, patientId: val.patientId
  //     }
  //     setParamVal(newval);
  //     setshowPatientProfile(true);
  //     // history.push({
  //     //   pathname: `/patient_details/${val.patientId}`,
  //     //   state: { callUrl: props.callUrl, callType: props.callType },
  //     // });
  //   } else {
  //     props.requestAccess();
  //   }
  //   // e.preventDefault();
  //   // console.log(doct.code);
  //   // console.log(doct);
  // };

  const refer = (val) => {
    console.log(val);
    patientService.getpatientdetails(val.patientId).then(
      (res) => {
        if (res.data) {
          let patient = res.data[0];
          console.log(JSON.stringify(patient));
          if (patient.access == 1) {
            props.clickPop(true);
            console.log(props);

            const newval = {
              callUrl: props.callUrl,
              callType: props.callType,
              patientId: val.patientId,
            };
            setParamVal(newval);
            setshowPatientProfile(true);
          } else {
            props.requestAccess();
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const closePatientDetails = () => {
    props.clickPop(false);
    setshowPatientProfile(false);
  };

  useEffect(() => {
    //alert(JSON.stringify(props.closePatient))
    if (props.closePatient == false) {
      setshowPatientProfile(false);
    }
  }, [props.closePatient]);

  return (
    <>
      <div className="lg:flex border border-gray-200 my-3 content-center lg:justify-between  rounded-lg p-5 ">
        <div className="flex lg:w-7/12">
          <div className="lg:block hidden">
            {/* <img src={props.patientPhotoName ? props.patientPhotoName : share} alt="" className="h-12 w-12"></img> */}

            {props.patientPhotoName ? (
              <div className="bg-white p-1 w-20 h-20 border-2 border-brand-lightgreen items-center flex justify-center font-rubik rounded-full">
                <img
                  src={`${process.env.REACT_APP_IMG_BASEURL + props.patientPhotoName
                    }`}
                  alt="vijay"
                  class="profileRound"
                />
              </div>
            ) : (
              <div className="bg-white p-1 w-20 h-20 border-2 border-brand-lightgreen items-center flex justify-center font-rubik rounded-full">
                <p
                  className="font-bold text-2xl bg-slate-200 rounded-full w-full h-full  flex justify-center items-center "
                  style={{ backgroundColor: "#ecfaff" }}
                >
                  {props?.patientName?.charAt(0)}
                </p>
              </div>
            )}
          </div>
          <div className="ml-5 text-xs">
            <p className="font-semibold text-xl font-rubik w-full">
              {props?.patientName}
            </p>
            <div className="text-sm mt-2">
              <p className="font-medium mb-2 text-sm">
                {" "}
                {moment(props.whenAppointment, "MM/DD/yyyy").format(
                  "Do MMM"
                )}, {moment(props.fromTime, "hh:mm").format("hh:mm A")}
              </p>
            </div>
            <div className="text-sm">
              <b>Patient ID : </b>
              <span className="font-rubik">{props.patientId}</span>
            </div>
          </div>
        </div>
        <div className="mt-2 lg:w-7/12 flex items-end">
          <div className="ml-2 text-xs">
            <div className="text-sm  mb-2">
              <b>Gender : </b>
              <span>
                {props.patientgender == "M"
                  ? "Male"
                  : props.patientgender == "F"
                    ? "Female"
                    : props.patientgender == "O"
                      ? "Others"
                      : props.patientgender}
              </span>
            </div>
            <div className="text-sm">
              <b>Chief Complaint : </b>
              <span>{props?.consultationsReason}</span>
            </div>
          </div>
        </div>
        <div className="mt-2 lg:w-2/12 flex items-end">
          <div className="text-xs">
            <div className="w-max py-2 mb-2 px-3 bg-brand-secondary text-brand-primary rounded-lg flex">
              <span
                onClick={() => setShowFilter(true)}
                className="text-sm font-medium text-white "
              >
                View Physical Exam
              </span>
            </div>
            <div className="w-max py-2 px-5 bg-brand-white border border-brand-secondary text-brand-primary rounded-lg flex">
              <span
                onClick={() => refer(props)}
                className="text-sm font-medium text-brand-primary "
              >
                Medical Records
              </span>
            </div>
          </div>
        </div>
      </div>

      {showPatientProfile && (
        <Dialog
          header="Patient Medical Records"
          visible={showPatientProfile}
          style={{ width: "98vw" }}
          modal
          onHide={closePatientDetails}
        >
          <PatientDetails2 state={paramVal} onHide={closePatientDetails} />
        </Dialog>
      )}
      {showFilter && (
        <Dialog
          header="Physical Examination"
          visible={showFilter}
          modal={false}
          style={{ width: "80vw" }}
          //  footer={renderPolicyFooter("displayModal")}
          onHide={() => setShowFilter(false)}
        >
          <div className='block mx-0 lg:mx-12 md:mx-6 sm:mx-4 sm:my-2'>
            <ViewPEPopup data={props} close={closePopup} />
          </div>
        </Dialog>
      )}
    </>
  );
}
export default Call;
