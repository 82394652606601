import { Fragment, useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  ExclamationIcon,
  SearchIcon,
  XIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import close from "../Assets/Images/closeee.svg";
import { Medicinedruglist } from "../Redux/Actions/MedicineActions";
import Autocomplete from "react-autocomplete";
import { ValidationError } from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PriscriptionService from "../Redux/services/priscription";

function MedicinePrescription(props) {
  const dispatch = useDispatch();
  const medicinedruglist = useSelector((state) => state.medicinedruglist);
  const { MedicinelistData } = medicinedruglist;
  const [tempDrugList, setTempDrugList] = useState([]);
  const userData = useSelector((state) => state.authReducer.userData);
  const [val1, setVal1] = useState([]);
  const { patient } = props;
  const [errorMsg, seterrorMsg] = useState(false);
  const [qtyErrorMsg, setqtyErrorMsg] = useState(false);
  const [errorMsg2, seterrorMsg2] = useState(false);
  useEffect(() => {
    // dispatch(Medicinedruglist()).then((res) => {
    //     setTempDrugList(res.slice(0, 500));
    // }, (err) => {
    //     console.log(err);
    // })
  }, []);
  const [list, setList] = useState([
    {
      type: "",
      composition: "",
      afterBeforeFood: "-1",
      createdBy: userData.code,
      dosage: "0-0-0",
      drugName: "",
      duration: "0",
      form: "",
      frequency: "",
      fromDate: moment().format("yyyy-MM-DD hh:mm:ss"),
      fromDateTime: moment().format("yyyy-MM-DD hh:mm:ss"),
      givenDate: moment().format("yyyy-MM-DD hh:mm:ss"),
      instruction: "",
      medicineType: "Rx",
      modifiedBy: "TESTDOCTOR",
      patientId: patient?.patientId,
      pharmacyStockId: "",
      prescriptionDoc: "c3RyaW5n",
      prescriptionId: patient?.id,
      quantity: "",
      specialAuthority: "",
      status: 1,
      toDate: "2021-09-21 06:07:59",
      toDateTime: "2021-09-21 06:07:59",
      userId: userData.code,
      visitId: patient?.id,
    },
  ]);

  const addMedicine = () => {
    var data = {
      composition: "",
      afterBeforeFood: "2",
      type: "Rx",
      createdBy: userData.code,
      dosage: "",
      drugName: "",
      drugName1: "",
      duration: "0",
      drugCategory: "",
      form: "",
      frequency: "",
      fromDate: moment().format("yyyy-MM-DD hh:mm:ss"),
      fromDateTime: moment().format("yyyy-MM-DD hh:mm:ss"),
      givenDate: moment().format("yyyy-MM-DD hh:mm:ss"),
      instruction: "",
      medicineType: "Rx",
      modifiedBy: "TESTDOCTOR",
      patientId: patient?.patientId,
      pharmacyStockId: "",
      prescriptionDoc: "c3RyaW5n",
      prescriptionId: "1",
      drugMType: "Medicine",
      quantity: "0",
      specialAuthority: "",
      status: 1,
      toDate: moment().format("yyyy-MM-DD hh:mm:ss"),
      toDateTime: moment().format("yyyy-MM-DD hh:mm:ss"),
      userId: userData.code,
      visitId: patient?.id,
      itemCode: "",
    };
    setList([...list, data]);
  };

  const onRemove = (i) => {
    let data = [...list];
    data.splice(i, 1);
    setList(data);
  };

  const onClose = () => {
    let data = [];
    list.forEach((element) => {
      if (element.drugName) {
        data.push(element);
      }
    });
    let emptyData = data.filter((data) => data.quantity == "0");
    if (emptyData.length == 0) {
      props.onClose(data);
    } else {
      setqtyErrorMsg(true);
    }
  };

  const Validation = () => {
    // let data = [...list];
    // console.log(JSON.stringify(data));
    // let emptyData = data.filter(
    //   (data) => data.drugName == "" || data.quantity == "0"
    // );
    // if (emptyData.length == 0) {
    //   seterrorMsg(false);
    //   props.onClose(list);
    // } else {
    //   seterrorMsg(true);
    // }

    seterrorMsg("");
    let data = [...list];
    let drugemptyData = data.filter((data) => data.drugName == "" && data.drugName1 == "");
    let quantityData = data.filter((data) => data.quantity == "0");
    let durationData = data.filter((data) => data.duration == "0");
    let dosageData = data.filter((data) => data.dosage == "")
    let instructionData = data.filter((data) => data.instruction == "")
    if (data.length) {
      document.getElementById('autoComplete-' + (data.length - 1)).classList.remove("errorBox");
      document.getElementById('quantity-' + (data.length - 1)).classList.remove("errorBox");
      document.getElementById('dosage' + (data.length - 1)).classList.remove("errorBox");
      document.getElementById('duration-' + (data.length - 1)).classList.remove("errorBox");
      document.getElementById('instruction' + (data.length - 1)).classList.remove("errorBox");
    }
    if (drugemptyData.length) {
      document.getElementById('autoComplete-' + (data.length - 1)).className = "errorBox";
      return seterrorMsg("Add/Select Medicine");
    } else if (quantityData.length) {
      document.getElementById('quantity-' + (data.length - 1)).className = "errorBox";
      return seterrorMsg("Enter Quantity");
    } else if (dosageData.length) {
      document.getElementById('dosage' + (data.length - 1)).className = "errorBox";
      return seterrorMsg("Select Dosage");
    } else if (instructionData.length) {
      document.getElementById('instruction' + (data.length - 1)).className = "errorBox";
      return seterrorMsg("Select instructions");
    } else if (durationData.length) {
      document.getElementById('duration-' + (data.length - 1)).className = "errorBox";
      return seterrorMsg("Select Duration");
    } else {
      props.onClose(list);
    }
  };

  const addValidation = () => {
    seterrorMsg("");

    let data = [...list];
    let drugemptyData = data.filter((data) => data.drugName == "" && data.drugName1 == "");
    let quantityData = data.filter((data) => data.quantity == "0");
    let durationData = data.filter((data) => data.duration == "0");
    let dosageData = data.filter((data) => data.dosage == "");
    let instructionData = data.filter((data) => data.instruction == "")

    if (data.length) {
      document.getElementById('autoComplete-' + (data.length - 1)).classList.remove("errorBox");
      document.getElementById('quantity-' + (data.length - 1)).classList.remove("errorBox");
      document.getElementById('dosage' + (data.length - 1)).classList.remove("errorBox");
      document.getElementById('duration-' + (data.length - 1)).classList.remove("errorBox");
      document.getElementById('instruction' + (data.length - 1)).classList.remove("errorBox");

    }


    if (drugemptyData.length) {
      document.getElementById('autoComplete-' + (data.length - 1)).className = "errorBox";
      return seterrorMsg("Add/Select Medicine");
    } else if (quantityData.length) {
      document.getElementById('quantity-' + (data.length - 1)).className = "errorBox";
      return seterrorMsg("Enter Quantity");
    } else if (dosageData.length) {
      document.getElementById('dosage' + (data.length - 1)).className = "errorBox";
      return seterrorMsg("Select Dosage");
    } else if (instructionData.length) {
      document.getElementById('instruction' + (data.length - 1)).className = "errorBox";
      return seterrorMsg("Select instructions");
    } else if (durationData.length) {
      document.getElementById('duration-' + (data.length - 1)).className = "errorBox";
      return seterrorMsg("Select Duration");
    }
    else {
      addMedicine();
    }
    // let emptyData = data.filter((data) => data.drugName == "" || data.quantity == "0");
    // if (emptyData.length == 0) {
    //     seterrorMsg(false);
    //     addMedicine();
    // } else {
    //     seterrorMsg(true);
    // }
  };

  const changeHandler = (e, index) => {
    let data = [...list];
    let name = e.target.name;
    let value = e.target.value;
    console.log(value);
    data[index][name] = value;
    if (name === "duration") {
      data[index]["toDate"] = moment(
        data[index].givenDate,
        "yyyy-MM-DD hh:mm:ss"
      )
        .add(value, "days")
        .format("yyyy-MM-DD hh:mm:ss");
      data[index]["toDateTime"] = moment(
        data[index].givenDate,
        "yyyy-MM-DD hh:mm:ss"
      )
        .add(value, "days")
        .format("yyyy-MM-DD hh:mm:ss");
    }

    if (name === "drugName1") {
      data[index]["drugCode"] = "";
      data[index]["drugType"] = 'U';
    }
    setList(data);
  };

  const onSelect = (e, i) => {
    let data = [...list];
    data[i]["drugName"] = e.drugname;
    data[i]["drugCode"] = e.code;
    data[i]["drugCategory"] = e.drugCategory;
    data[i]["form"] = e.drugForm;
    data[i]["drugType"] = 'S';
    data[i]["pharmacyStockId"] = e.code;
    data[i]["composition"] = e.composition;
    data[i]["rate"] = e.rate;
    data[i]["itemCode"] = e.code;
    data[i]["typeOfSell"] = e.typeofsell;
    console.log("data[i]", data[i]);
    setList(data);
  };

  const onSetVal = (e, i) => {
    console.log(e);
    console.log(i);

    let data = [...list];
    let list1 = [...val1];
    data[i]["drugCode"] = "";
    data[i]["drugType"] = 'U';
    data[i]["drugName1"] = e;
    console.log(MedicinelistData);
    if (e.length >= 3) {
      // console.log(e.toLowerCase());
      // console.log(MedicinelistData.length);
      // let filteredData = MedicinelistData.filter((x) => x.drugname?.toLowerCase().includes(e.toLowerCase()))
      // console.log(filteredData);
      //setTempDrugList(filteredData);
      let payload = {};
      if (data[i]["drugMType"] === 'Composition') {
        payload.composition = e.toLowerCase();
        payload.userDefined = "N";
      } else {
        payload.drugName = e.toLowerCase();
        payload.userDefined = "N";
      }

      console.log("payload", payload)

      PriscriptionService.MedicineDrugListSearch(payload).then(
        (res) => {
          console.log('drug list');
          console.log(res);
          setTempDrugList(res.data);
        }
      );
    } else {
      // setTempDrugList([]);
    }

    data[i]["drugName"] = "";
    data[i]["composition"] = "";
    list1[i] = e;
    setList(data);
    setVal1(list1);
  };

  useEffect(() => {
    if (props.data) {
      setList(props.data);
    }
  }, [props.data]);

  console.log("addmedicine,", list);

  const renderMedicine = (state, val) => {
    return state.drugname.toLowerCase().indexOf(val.toLowerCase()) !== -1;
  };

  return (
    <>
      <ToastContainer />
      {/* <Transition.Root show={props.open} as={Fragment}> */}
      <Dialog
        header="Medicine Prescription"
        visible={props.open}
        modal={true}
        style={{ width: "90vw" }}
        onHide={() => onClose()}
      >
        {/* <div className="flex items-end  justify-center min-h-screen pt-4 px-4 lg:pb-20 pb-96 text-center sm:block sm:p-0"> */}
        {/* <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" /> */}
        {/* <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span> */}
        {/* <div className="inline-block h-auto align-bottom bg-white rounded-lg text-left  overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle "> */}
        {/* <div className="w-full border border-gray-500 rounded overflow-hidden shadow-sm p-3"> */}
        {/* <div className='flex justify-between mt-2 ml-2'>
                                    <p className="text-xl text-gray-900 font-medium font-rubik">Medicine Prescription</p>
                                    <img src={close} onClick={() => props.onClose(list)} alt="close" className="w-4" />
                                </div>
                                <hr className="my-4" /> */}

        <>
          <div className=" hidden md:block w-full mr-12 mt-5 ">
            {/* <div className="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 mb-2 antialiased justify-between border border-gray-200"> */}

            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {/* <div className="flex ">

                                        <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-700  tracking-wider">
                                            Medicine Name
                                        </th>

                                    </div> */}

                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                  >

                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                  >
                    Medicine Name
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                  >
                    Composition
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="px-1 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                  >
                    Dosage
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 ml-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                  >
                    Notes
                  </th>


                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium text-gray-700 tracking-wider"
                  >
                    Instructions
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                  >
                    Duration
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody className="items-baseline divide-x divide-gray-200">
                {list.map((res, i) => (
                  <tr>

                    <td className="align-top px-2 py-3 whitespace-nowrap">
                      <select
                        id="drugMType"
                        onChange={(e) => changeHandler(e, i)}
                        name="drugMType"
                        value={res.drugMType}
                        className="outline-none peer text-gray-500 text-sm font-medium "
                      >
                        <option value="Medicine">Medicine</option>
                        <option value="Composition">Composition</option>
                      </select>
                    </td>

                    <td className="align-top px-5 py-4 whitespace-nowrap">
                      <Autocomplete

                        inputProps={{ placeholder: 'Add/Select Medicine', id: "autoComplete-" + i }}
                        value={res.drugName ? res.drugName : val1[i]}
                        items={tempDrugList}
                        id={"autoCompleteed-" + i}
                        getItemValue={(item) => item.drugname}
                        shouldItemRender={renderMedicine}
                        menuStyle={{ width: "400" }}
                        renderMenu={(item) => (
                          <div
                            key={item}
                            className={
                              tempDrugList.length > 0
                                ? "dropdown flex flex-col h-40 overflow-auto"
                                : "dropdown flex flex-col overflow-auto"
                            }
                          >
                            {item}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${isHighlighted ? "selected-item" : ""
                              }`}
                          >
                            {item.drugname + ' - ' + item.composition + ' : ' + item.manufacturer}
                          </div>
                        )}
                        onChange={(event, val) => {
                          onSetVal(val, i);
                        }}
                        onSelect={(event, obj) => onSelect(obj, i)}
                      />
                      <div className="border-b-2"></div>
                      {/* <br/>
                        <input
                        autocomplete="off"
                        id="drugName1"
                        name="drugName1"
                        value={res.drugName1}
                        onChange={(e) => changeHandler(e, i)}
                        style={{ width: "400" }}
                        type="text"
                        className="outline-none pl-3 peer text-gray-500 text-sm font-medium "
                        placeholder="Type Medicine..."
                      />
                    <div className="border-b-2"></div> */}

                    </td>
                    <td className="align-top px-6 py-4 w-60 text-gray-500 text-sm font-medium">
                      <p>{res.composition}</p>
                    </td>
                    <td className="align-top px-1 py-4 w-25 ">
                      <input
                        autocomplete="off"
                        id={"quantity-" + i}
                        name="quantity"
                        min="1"
                        value={res.quantity}
                        onChange={(e) => changeHandler(e, i)}
                        type="number"
                        className="outline-none w-12 pl-3 peer text-gray-500 text-sm font-medium "
                        placeholder="Quantity"
                      />
                    </td>
                    <td className="align-top px-2 py-3 whitespace-nowrap">
                      <select
                        id={"dosage" + i}
                        onChange={(e) => changeHandler(e, i)}
                        name="dosage"
                        value={res.dosage}
                        className="outline-none peer text-gray-500 text-sm font-medium "
                      >
                        <option value="0-0-0">Select</option>
                        <option value="0-0-1">0-0-1</option>
                        <option value="0-1-1">0-1-1</option>
                        <option value="0-1-0">0-1-0</option>
                        <option value="1-1-1">1-1-1</option>
                        <option value="1-0-0">1-0-0</option>
                        <option value="1-0-1">1-0-1</option>
                        <option value="1-1-0">1-1-0</option>
                        <option value="1-1-1-1">1-1-1-1</option>
                        <option value="1-0-0-0">1-0-0-0</option>
                        <option value="1-1-0-0">1-1-0-0</option>
                        <option value="1-1-1-0">1-1-1-0</option>
                        <option value="0-0-0-1">0-0-0-1</option>
                        <option value="0-0-1-1">0-0-1-1</option>
                        <option value="0-0-1-0">0-0-1-0</option>
                        <option value="0-1-0-0">0-1-0-0</option>
                        <option value="0-1-1-1">0-1-1-1</option>
                        <option value="s.o.s.">s.o.s.</option>
                        <option value="q.wk.">q.wk.</option>
                        <option value="b.i.d.">b.i.d.</option>
                        <option value="t.i.d.">t.i.d.</option>
                        <option value="q.i.d.">q.i.d.</option>
                        <option value="q.h.s.">q.h.s.</option>
                        <option value="5X a day">5X a day</option>
                        <option value="q.4h">q.4h</option>
                        <option value="q.6h">q.6h</option>
                        <option value="q.o.d.">q.o.d.</option>
                        <option value="prn.">prn.</option>
                        <option value="q.d.">q.d.</option>
                        <option value="I.M.">I.M.</option>
                        <option value="Subq.">Subq.</option>
                        <option value="Rectally">Rectally</option>
                        <option value="I.V.">I.V.</option>
                        <option value="O.D.">O.D.</option>
                        <option value="O.S.">O.S.</option>
                        <option value="O.U.">O.U.</option>
                        <option value="2 ml">2 ml</option>
                        <option value="2.5 ml">2.5 ml</option>
                        <option value="5 ml">5 ml</option>
                        <option value="10 ml">10 ml</option>


                        {/* <option value="1-0-0">1-0-0</option> */}
                        {/* <option value="1-1-1">1-1-1</option> */}
                      </select>
                    </td>
                    <td className="align-top px-3 py-4 flex-col  whitespace-nowrap">

                      {/* <div className="mb-2">
                        <select
                          className="  outline-none w-full peer text-gray-500 text-sm font-medium"
                          onChange={(e) => changeHandler(e, i)}
                          name="medTimeParts"
                          id={"medTimeParts" + i}
                          value={res.medTimeParts}
                        >
                          <option value="">
                            Select Time Part
                          </option>
                          <option value="Morning">Morning</option>
                          <option value="Afternoon">Afternoon</option>
                          <option value="Evening">Evening</option>
                          <option value="Night">Night</option>
                        </select>
                      </div>
                      <div className="mb-2">
                        <select
                          className="  outline-none w-full peer text-gray-500 text-sm font-medium"
                          onChange={(e) => changeHandler(e, i)}
                          name="medicineTime"
                          id={"medicineTime" + i}
                          value={res.medicineTime}
                        >
                          <option value="">
                            Select Time
                          </option>
                          <option value="15 mins before Breakfast">15 mins before Breakfast</option>
                          <option value="15 mins after Breakfast">15 mins after Breakfast</option>
                          <option value="20 mins before Breakfast">20 mins before Breakfast</option>
                          <option value="20 mins after Breakfast">20 mins after Breakfast</option>
                          <option value="30 mins before Breakfast">30 mins before Breakfast</option>
                          <option value="30 mins after Breakfast">30 mins after Breakfast</option>
                          <option value="15 mins before Lunch">15 mins before Lunch</option>
                          <option value="15 mins after Lunch">15 mins after Lunch</option>
                          <option value="20 mins before Lunch">20 mins before Lunch</option>
                          <option value="20 mins after Lunch">20 mins after Lunch</option>
                          <option value="30 mins before Lunch">30 mins before Lunch</option>
                          <option value="30 mins after Lunch">30 mins after Lunch</option>
                          <option value="15 mins before Dinner">15 mins before Dinner</option>
                          <option value="15 mins after Dinner">15 mins after Dinner</option>
                          <option value="20 mins before Dinner">20 mins before Dinner</option>
                          <option value="20 mins after Dinner">20 mins after Dinner</option>
                          <option value="30 mins before Dinner">30 mins before Dinner</option>
                          <option value="30 mins after Dinner">30 mins after Dinner</option>
                        </select>
                      </div> */}
                      <div>
                        <input
                          autocomplete="off"
                          id={"notes" + i}
                          name="notes"
                          min="1"
                          maxLength={150}
                          value={res.notes}
                          onChange={(e) => changeHandler(e, i)}
                          type="text"
                          className="outline-none w-full peer text-gray-500 text-sm font-medium "
                          placeholder="notes"
                        />
                      </div>
                    </td>
                    <td className="align-top px-7 py-4 whitespace-nowrap">
                      <select
                        className="  outline-none peer text-gray-500 text-sm font-medium"
                        onChange={(e) => changeHandler(e, i)}
                        name="instruction"
                        id={"instruction" + i}
                        value={res.instruction}
                      >
                        <option value="-1" disabled>
                          Select
                        </option>
                        <option value="2">None</option>
                        <option value="0">After Meal</option>
                        <option value="1">Before Meal</option>
                      </select>
                      {/* <input
                          autocomplete="off"
                          id={"instruction" + i}
                          name="instruction"
                          min="1"
                          maxLength={140}
                          value={res.instruction}
                          onChange={(e) => changeHandler(e, i)}
                          type="text"
                          className="outline-none w-full peer text-gray-500 text-sm font-medium "
                          placeholder="Notes"
                        /> */}
                    </td>
                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-montserrat">Upcoming</td> */}
                    <td className="align-top px-6 py-4 whitespace-nowrap">
                      <select
                        className="  outline-none peer text-gray-500 text-sm font-medium"
                        id={"duration-" + i}
                        onChange={(e) => changeHandler(e, i)}
                        name="duration"
                        value={res.duration}
                      >
                        <option value={"0"}>0 days</option>
                        {[...Array(30)].map((res, i) => (
                          <option value={i + 1}>{i + 1} days</option>
                        ))}
                      </select>
                    </td>

                    <td className="align-top px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-medium">
                      <select
                        className="  outline-none peer text-gray-500 text-sm font-medium"
                        onChange={(e) => changeHandler(e, i)}
                        name="medicineType"
                        value={res.medicineType}
                      >
                        <option value="Select" disabled>
                          Select
                        </option>
                        <option value="Rx">Rx</option>
                        <option value="NRx">NRx</option>
                        <option value="XRx">XRx</option>
                      </select>
                    </td>
                    <td className="align-top px-6 py-4">
                      <TrashIcon
                        onClick={() => onRemove(i)}
                        stroke={"#004171"}
                        className="cursor-pointer h-6 w-6 text-black-600 ml-8"
                        aria-hidden="true"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="block md:hidden w-full mr-0 mt-5 overflow-hidden">
            <table className="">
              <tbody className="">
                {list.map((res, i) => (
                  <>
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 flex text-left text-sm font-medium text-gray-700  tracking-wider"
                      >
                        Medicine Name
                      </th>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Autocomplete
                          className="w-36"
                          value={res.drugName ? res.drugName : val1[i]}
                          items={tempDrugList}
                          getItemValue={(item) => item.drugname}
                          shouldItemRender={renderMedicine}
                          menuStyle={{ width: "100" }}
                          menuWidth="20"
                          renderMenu={(item) => (
                            <div className="dropdown relative  bg-white overflow-scroll max-h-96">
                              {item}
                            </div>
                          )}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={`item ${isHighlighted ? "selected-item" : ""
                                } w-36`}
                            >
                              {item.drugname}
                            </div>
                          )}
                          onChange={(event, val) => {
                            onSetVal(val, i);
                          }}
                          onSelect={(event, obj) => onSelect(obj, i)}
                          wrapperStyle={{
                            width: "9rem",
                            overflow: "auto",
                            scrollbarWidth: "none",
                          }}
                          inputProps={{ style: { width: "9rem" } }}
                        />
                        <div className="border-b-2 w-36"></div>
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                      >
                        Quantity
                      </th>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          autocomplete="off"
                          id="quantity"
                          name="quantity"
                          value={res.quantity}
                          onChange={(e) => changeHandler(e, i)}
                          type="number"
                          className="outline-none peer text-gray-500 text-sm font-medium w-36"
                          placeholder="Quantity"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                      >
                        Dosage
                      </th>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <select
                          id="dosage"
                          onChange={(e) => changeHandler(e, i)}
                          name="dosage"
                          value={res.dosage}
                          className="outline-none peer text-gray-500 text-sm font-medium w-36"
                        >
                          <option value="0-0-0">Select</option>
                          <option value="0-0-1">0-0-1</option>
                          <option value="0-1-1">0-1-1</option>
                          <option value="0-1-0">0-1-0</option>
                          <option value="1-1-1">1-1-1</option>
                          <option value="1-0-0">1-0-0</option>
                          <option value="1-0-1">1-0-1</option>
                          <option value="1-1-0">1-1-0</option>
                          <option value="1-0-0">1-0-0</option>
                          <option value="1-1-1">1-1-1</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                      >
                        Instructions
                      </th>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <select
                          className=" w-36 outline-none peer text-gray-500 text-sm font-medium"
                          onChange={(e) => changeHandler(e, i)}
                          name="instruction"
                          id={"instruction" + i}
                          value={res.instruction}
                        >
                          <option value="-1" disabled>
                            Select
                          </option>
                          <option value="2">None</option>
                          <option value="0">After Meal</option>
                          <option value="1">Before Meal</option>
                          {/* <option className=""></option> */}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-sm font-medium text-gray-700 tracking-wider"
                      >
                        Duration
                      </th>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <select
                          className=" w-36 outline-none peer text-gray-500 text-sm font-medium"
                          id="duration"
                          onChange={(e) => changeHandler(e, i)}
                          name="duration"
                          value={res.duration}
                        >
                          <option value={"0"}>0 days</option>
                          {[...Array(30)].map((res, i) => (
                            <option value={i + 1}>{i + 1} days</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                      >
                        Composition
                      </th>
                      <td className="px-6 py-4 w-60 text-gray-500 text-sm font-medium">
                        <p>{res.composition}</p>
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                      >
                        Type
                      </th>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-medium">
                        <select
                          className=" w-36 outline-none peer text-gray-500 text-sm font-medium"
                          onChange={(e) => changeHandler(e, i)}
                          name="medicineType"
                          value={res.medicineType}
                        >
                          <option value="Select" disabled>
                            Select
                          </option>
                          <option value="Rx">Rx</option>
                          <option value="NRx">NRx</option>
                          <option value="XRx">XRx</option>
                        </select>
                      </td>
                    </tr>
                    <tr className="border-b-2">
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                      >
                        Delete
                      </th>
                      <td className="text-center">
                        <TrashIcon
                          onClick={() => onRemove(i)}
                          stroke={"#004171"}
                          className="cursor-pointer h-6 w-6 text-black-600 ml-8"
                          aria-hidden="true"
                        />
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          <div className='block w-full mr-0 mt-5 overflow-hidden'>
            <div>
              <p
                onClick={addValidation}
                className="cursor-pointer text-sm text-brand-secondary pl-5 pt-6 font-medium"
              >
                + Add Medicine
              </p>
              {/* <p onClick={addValidation} className="cursor-pointer text-sm pl-5 font-medium text-red-600">{errorMsg == true ? 'Please enter all mandatory fields' : ''}</p> */}

              <p
                onClick={addValidation}
                className="cursor-pointer text-sm pl-5 font-medium text-red-600"
              >
                {errorMsg}
              </p>

              <p
                onClick={addValidation}
                className="cursor-pointer text-sm pl-5 font-medium text-red-600"
              >
                {qtyErrorMsg == true ? "Please enter quantity" : ""}
              </p>
            </div>
            <div className="justify-end align-end flex-end float-right overflow-hidden">
              <button
                onClick={Validation}
                className="bg-brand-secondary mr-1 align-left text-white py-2 px-3 font-medium rounded text-sm"
              >
                Save
              </button>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}

          {/* </div> */}
          {/* </div> */}
        </>
      </Dialog>
      {/* </Transition.Root > */}
    </>
  );
}
export default MedicinePrescription;
