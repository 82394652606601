import axios from "axios";
import http from "./http-common";
import store from './../store/store';
import { secureStorage } from "../Reducers/authReducer";
import { encryptData, decryptData } from "../../helper/encryptData";


class AuthDataService {
  sendOTP(data) {
    return http.post(`UserOTP/generateUserOTP/${data.mobileNo}/${data.mobileCheck}`);
  }

  verifyRegOtp(data) {
    return http.get(`PatientOTP/verifyPatientOTP/${data.otp}/${data.mobileNo}/${data.mobileCheck}`);
  }

  verifyLogOtp(data) {
    const encryptedData = encryptData(data);
    return http.post('Login/UserLoginWithMobile', data);
  }

  checkMobile(data) {
    console.log("data", data)
    return http.get(`PatientOTP/patinetMobileExist/${data.mobileNo}`);
  }

  registration(data) {
    return http.post(`user/`, data)
  }

  LoginWithPassword(data) {
    const encryptedData = encryptData(data);
    return http.post('Login/', encryptedData)
  }
  // LoginWithPassword(data) {
  //   return http.post('Login/withOutAd/', data)
  // }
  // LoginWithPasswordAD(data){
  //   return http.post('Login/withAd/', data)
  // }

  getuserType() {
    return http.get(`role/list`)
  }
  getStateList() {
    return http.get(`state/list`)
  }
  changePassword(data) {
    return http.post(`PasswordMaintenance/`, data)
  }

  callToken = async (data) => {
    const encryptedData = encryptData(data)
    try {
      const configurationObject = {
        method: 'POST',
        url: process.env.REACT_APP_BASEURL + 'gcp/oauth/token',
        data: encryptedData,
      };
      const response = await axios(configurationObject);
      return response;
    } catch (error) {
      return error.response;
    }
    // const encryptedData=encryptData(data)
    // return http.post(process.env.REACT_APP_BASEURL + 'gcp/oauth/token', encryptedData)
  }


  logout(data) {
    return http.post(`Login/logout/${data.sessionId}/${data.userCode}`)
  }

  forgotPassword(data) {
    return http.post(`PasswordMaintenance/forgetpassword/`, data)
  }
  async getApiCMS(url) {
    const NewCmsToken = 'Bearer 6f1797939dbb00599d8896d29f4e114b257e1e252d6856294951bdcdd7be292b7b49d6a266f2319d74e024cac8a99de3ab5256b82e25d4bb931a5c44a28f8d5ddcdffc51ee8abad8f62ac14a985397c4636b7575a9709d1cd6ae4bed4419494c2ea96ed3bc6223a5bac20e6a07342f7f17fb1cc7c87dccd85c0de0930896afe1'
    try {
      const configurationObject = {
        method: 'GET',
        url: url,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: NewCmsToken,
        },
      };
      const response = await axios(configurationObject);
      return response.data;
    } catch (error) {
      console.log('Warning', error);
      return error.response;
    }
  }
  async getUserConsent(url) {
    try {
      const configurationObject = {
        method: 'GET',
        url: url,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      const response = await axios(configurationObject);
      return response.data;
    } catch (error) {
      console.log('Warning', error);
      return error.response;
    }
  }
  async userConsentPost(payload, url) {
    try {
      let token = store.getState().authReducer.token;
      let s_token = secureStorage.getItem('token');
      const configurationObject = {
        method: 'POST',
        url: url,
        data: payload,
        headers: {
          Authorization: `Bearer ${s_token ? s_token : token}`
        },
      };
      const response = await axios(configurationObject);
      return response.data;
    } catch (error) {
      console.log('Warning', error);
      return error.response;
    }
  }
}

export default new AuthDataService();