import React, { useEffect, useState } from 'react'
import search from '../Assets/Images/doctsearch.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReferalFrom, ReferHospital } from '../Redux/Actions/RefralAction';
import ProfileSidebar from './profileSidebar';
import ProfileNamebar from './profileNamebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReferSuccessfulpopup from "./ReferSuccessfulpopup";
import {Dropdown} from 'react-bootstrap'
import { MenuIcon} from '@heroicons/react/outline'
function ReferDoctor(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showSucesspopup, setshowSucesspopup] = useState(false)
    const loggedInData = useSelector((state) => state.authReducer);
    const { userData } = loggedInData;
    useEffect(() => {
    }, []);
    const loginObj = JSON.parse(localStorage.getItem("loginObj"));
    const userDataLogin = loginObj.user;
    const [addList, setAddList] = useState({
        "recommendById": userDataLogin.code,
        "qualification": "",
        "email": "",
        "address1": "",
        "address2": "",
        "createdBy": userDataLogin.code,
        "modifiedBy": userDataLogin.code,
        "description": "",
        "recommendType": "S",
        "status": 1,
        "recommendByName": userDataLogin.userConsultationFees[0].userName
    });

    const handleChange = (e) => {
        setAddList({ ...addList, [e.target.name]: e.target.value });
    }
    const onSave = () => {
        if (!addList.doctorName) {
            toast("Please Enter Doctor name");
        } else if (!addList.speciality) {
            toast("Please Enter Location");
        } else if (!addList.hospitalName) {
            toast("Please Enter Hospital name");
        } else if (!addList.locationName) {
            toast("Please Enter speciality");
        } else if (!addList.mobileNumber) {
            toast("Please Enter Mobile Number");
        } else if (addList.mobileNumber.length !== 10) {
            toast("Please Enter valid 10 digit Mobile Number");
        } else {
            dispatch(ReferHospital(addList)).then((result) => {
                if (result === 1) {
                    setshowSucesspopup(true)
                }
            })
        }
    }
    return (
        <>


            <div className="lg:block hidden">
                <ProfileNamebar></ProfileNamebar>
            </div>
            <ToastContainer />
            <div className="flex justify-between ">
                <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">
                    <ProfileSidebar></ProfileSidebar>
                </div>
                <div className="w-11/12 lg:ml-10 ml-4 mt-3 border boder-gray-200 rounded-lg bg-white mr-8">
                    <div className="flex justify-between md:justify-start lg:justify-between">
                    <div className = "hidden md:block lg:hidden relative p-3 top-1" >
                <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
              <MenuIcon className="block h-6 w-6" aria-hidden="true"/>

              </Dropdown.Toggle>

              <Dropdown.Menu className = "z-10" >
                <ProfileSidebar/>
              </Dropdown.Menu>
            </Dropdown>
            </div> 
                        <h1 className="text-medium font-medium text-2xl p-3 font-16 text-brand-secondary font-rubik">
                            Refer Doctor
                        </h1>
                    </div>
                    <hr />
                    <div className="py-8 pr-5 sm:pr-0 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                        <div className="relative pt-2 pl-5">
                            <label for="doctorName" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-2 ">Doctor<span> *</span></label>
                            <div class="flex ">
                                <input autocomplete="off" id="doctorName" name="doctorName" value={addList.doctorName} className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Doctor name" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="relative pt-4 pl-5">
                            <label for="speciality" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-4 ">Speciality<span> *</span></label>
                            <div class="flex ">
                                <input autocomplete="off" id="speciality" name="speciality" value={addList.speciality} className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Speciality" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="relative pt-4 pl-5">
                            <label for="hospitalName" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-4">Hospital<span> *</span></label>
                            <div class="flex ">
                                <input autocomplete="off" id="hospitalName" name="hospitalName" value={addList.hospitalName} className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Hospital Name" onChange={handleChange} />
                            </div>
                        </div>

                        <div className="relative pt-4 pl-5">
                            <label for="locationName" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-4 ">Location<span> *</span></label>
                            <div class="flex ">
                                <input autocomplete="off" id="locationName" name="locationName" value={addList.locationName} className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Location" onChange={handleChange} />
                            </div>
                        </div>

                        <div className="relative pt-4 pl-5">
                            <label for="mobileNumber" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-4 ">Mobile Number<span> *</span></label>
                            <div class="flex ">
                                <input autocomplete="off" id="mobileNumber" name="mobileNumber" type="text"
                                    pattern="[0-9]*"
                                    maxLength="10"
                                    value={addList.mobileNumber}
                                    onKeyPress={(event) => {
                                        if (!/[0-9.]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Mobile Number" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="flex justify-end">

                            <button onClick={onSave} className={`disabled:opacity-50 bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2`}>
                                Send to customer support
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="lg:hidden block">
                <MobileReferFrom />
            </div> */}
            {showSucesspopup ? (
                <ReferSuccessfulpopup
                    closePopup={() => setshowSucesspopup(!showSucesspopup)}
                ></ReferSuccessfulpopup>
            ) : null}
        </>
    )
}
export default ReferDoctor;
