
import { HEALTHRECORD } from "../Constants/Helthrecordconstants";
import HealthRecordService from "../services/HealthRecordService";

export const getPatientallergylist = (data) => async (dispatch) => {
    request();
    const res = await HealthRecordService.getallergieslist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: HEALTHRECORD.REQUEST_ALLERGYLIST });
    };

    function success(res) {
        dispatch({ type: HEALTHRECORD.SUCCESS_ALLERGYLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: HEALTHRECORD.FAILED_ALLERGYLIST,
            payload: err
        });
    };
};




export const getPatientmedicationlist = (data) => async (dispatch) => {
    request();
    const res = await HealthRecordService.getmedicationlist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: HEALTHRECORD.REQUEST_MEDICATIONLIST });
    };

    function success(res) {
        dispatch({ type: HEALTHRECORD.SUCCESS_MEDICATIONLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: HEALTHRECORD.FAILED_MEDICATIONLIST,
            payload: err
        });
    };
};




export const getmedicalhistory = (data) => async (dispatch) => {
    request();
    const res = await HealthRecordService.getmedicalhistorylist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: HEALTHRECORD.REQUEST_MEDICALHISTORYLIST });
    };

    function success(res) {
        dispatch({ type: HEALTHRECORD.SUCCESS_MEDICALHISTORYLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: HEALTHRECORD.FAILED_MEDICALHISTORYLIST,
            payload: err
        });
    };
};

export const getsurgicalhistory = (data) => async (dispatch) => {
    request();
    const res = await HealthRecordService.getsurgicalhistorylist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: HEALTHRECORD.REQUEST_SURGICALHISTORYLIST });
    };

    function success(res) {
        dispatch({ type: HEALTHRECORD.SUCCESS_SURGICALHISTORYLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: HEALTHRECORD.FAILED_SURGICALHISTORYLIST,
            payload: err
        });
    };
};

export const getfamilyhistory = (data) => async (dispatch) => {
    request();
    const res = await HealthRecordService.getfamilyhistorylist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: HEALTHRECORD.REQUEST_FAMILYHISTORYLIST });
    };

    function success(res) {
        dispatch({ type: HEALTHRECORD.SUCCESS_FAMILYHISTORYLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: HEALTHRECORD.FAILED_FAMILYHISTORYLIST,
            payload: err
        });
    };
};

export const getsocialhistory = (data) => async (dispatch) => {
    request();
    const res = await HealthRecordService.getsocialhistorylist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: HEALTHRECORD.REQUEST_SOCIALHISTORYLIST });
    };

    function success(res) {
        dispatch({ type: HEALTHRECORD.SUCCESS_SOCIALHISTORYLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: HEALTHRECORD.FAILED_SOCIALHISTORYLIST,
            payload: err
        });
    };
};

export const gettravelhistory = (data) => async (dispatch) => {
    request();
    const res = await HealthRecordService.gettravelhistorylist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: HEALTHRECORD.REQUEST_TRAVELHISTORYLIST });
    };

    function success(res) {
        dispatch({ type: HEALTHRECORD.SUCCESS_TRAVELHISTORYLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: HEALTHRECORD.FAILED_TRAVELHISTORYLIST,
            payload: err
        });
    };
};

export const getsexualhistory = (data) => async (dispatch) => {
    request();
    const res = await HealthRecordService.getsexualhistorylist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: HEALTHRECORD.REQUEST_SEXUALHISTORYLIST });
    };

    function success(res) {
        dispatch({ type: HEALTHRECORD.SUCCESS_SEXUALHISTORYLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: HEALTHRECORD.FAILED_SEXUALHISTORYLIST,
            payload: err
        });
    };
};
export const getAddictionList = (data) => async (dispatch) => {
    request();
    const res = await HealthRecordService.getAddictionlist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: HEALTHRECORD.REQUEST_ADDICTIONSLIST });
    };

    function success(res) {
        dispatch({ type: HEALTHRECORD.SUCCESS_ADDICTIONSLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: HEALTHRECORD.FAILED_ADDICTIONSLIST,
            payload: err
        });
    };
};
export const getPatientvitallist = (data) => async (dispatch) => {
    request();
    const res = await HealthRecordService.getpatientvitallist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: HEALTHRECORD.REQUEST_VITALSLIST });
    };

    function success(res) {
        dispatch({ type: HEALTHRECORD.SUCCESS_VITALSLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: HEALTHRECORD.FAILED_VITALSLIST,
            payload: err
        });
    };
};




