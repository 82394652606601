import React, { useEffect, useState } from 'react';
import close from '../Assets/Images/closeee.svg';
import Dob from '../Assets/Images/calendar.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PDF from '../Assets/Images/PDF.png'


function Addmedicalcondition(props) {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const goBack = () => {
      props.closePopup();
    }





    const handleChange = (e) => {
        setaddmedicalhistory({ ...addmedicalhistory, [e.target.name]: e.target.value });
    };


    const [addmedicalhistory, setaddmedicalhistory] = useState({


        patientId: "PAT_PAT_NIKH1628852443173",
        givenDate: "2021-08-30 12:29:19",
        description: "",
        status: 1,
        createdBy: "PAT_PAT_NIKH1628852443173",
        modifiedBy: "PAT_PAT_NIKH1628852443173"




    })


    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full p-5 bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <div class="pl-2 pr-5  flex items-center justify-between">
                            <p class="inline px-2 rounded-full text-md font-rubik font-medium cursor-pointer text-brand-secondary">{props?.editmedicalcondition === '' ? `Add ` : 'Edit '} Medical Condition</p>
                            <div className="flex space-x-6 cursor-pointer">
                                <img src={close} alt="close" class="w-4" onClick={goBack} />
                            </div>

                        </div>
                        <hr className="mt-2" />
                        <div class="lg:flex justify-between pt-5">
                            <div class="lg:flex justify-center">
                                <div class="lg:w-52 lg:h-80 bg-green-100 border-dashed border-2  border-gray-400  lg:py-16 py-4  text-center">

                                    <div class="flex justify-center mt-5 space-x-3">
                                        {/* <button class="text-xs bg-brand-secondary text-white font-normal py-2 px-2 rounded">Use camera</button>
                                    <button class="text-xs bg-brand-secondary text-white font-normal py-2 px-2 rounded">Browse File</button> */}
                                        <img src={PDF} alt="close" class="w-6 h-10" />
                                        <p class="text-sm cursor-pointer text-blue-900 font-medium lg:pr-4 my-4">Upload Record</p>
                                    </div>
                                </div>
                            </div>


                            {/*  */}

                            <div>
                                <p class="lg:pl-12 p-4 pb-2 text-sm text-gray-700 font-medium">Personal Details</p>
                                <div class="flex space-x-12 pt-4 lg:pl-12 p-4">
                                    <div class="relative">
                                        <div className="flex">
                                            <select class="w-36 my-2 outline-none peer text-xs text-gray-700  border-b-2 border-gray-300 ">
                                                <option class="py-1" value="father">father</option>
                                                <option class="py-1" value="mother">mother</option>
                                                <option class="py-1" value="daughter">Daughter</option>
                                                <option class="py-1" value="son">Son</option>
                                                <option class="py-1" value="Grandson">Grandson</option>
                                                <option class="py-1" value="Grandfather">Grandfather</option>
                                                <option class="py-1" value="Grandmother">Grandmother</option>
                                            </select>
                                        </div>
                                        <label for="email" class="absolute left-0 -top-3.5 text-gray-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Record For</label>
                                    </div>
                                    <div class="relative">
                                        <div className="flex ">
                                            <input autocomplete="off" id="email" name="email" type="text" class=" text-xs peer w-36  h-8  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="DD/MM/YYYY" />
                                            <img src={Dob} alt="my photo" ></img>
                                        </div>
                                        <label for="email" class="absolute left-0 -top-3.5 text-gray-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Record On</label>
                                    </div>
                                </div>


                                <div class="lg:pl-12 px-4">
                                    <p class="text-gray-700 font-medium text-base ">Are you suffering from any medical condition?</p>
                                </div>
                                <div className="flex space-x-8 lg:pl-12 px-4">

                                    <div className="flex space-x-4 items-center py-2">
                                        <input type="radio" class="form-radio mt-1 mr-2" name="accountType" value="personal" />
                                        <div className="text-sm font-medium text-gray-500 font-montserrat">Yes</div>
                                    </div>

                                    <div className=" flex space-x-4 items-center py-2">
                                        <input type="radio" class="form-radio mt-1 mr-2" name="accountType" value="personal" />
                                        <div className="text-sm font-medium text-gray-500 font-montserrat">No</div>

                                    </div>
                                </div>

                                <div class="flex space-x-12 pt-4 lg:pl-12 px-4">
                                    <div class="relative">
                                        <div className="flex">
                                            <input autocomplete="off" id="description" name="description" type="text" class="peer w-36  h-9  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Medicalconditionname " />

                                        </div>
                                        <label for="description" class="absolute left-0 -top-3.5 text-gray-600 text-xs 
                                            peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 
                                            peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 
                                            peer-focus:text-gray-600 peer-focus:text-sm">Medical Condition name<span class="text-red-500">*</span></label>
                                    </div>

                                    <div class="relative">
                                        <select class="w-36  my-2 outline-none peer text-xs text-gray-700 border-b-2 border-gray-300 ">
                                            <option class="py-1 text-xs ">Please Select </option>
                                            <option class="py-1">Fever</option>
                                            <option class="py-1">Cold</option>
                                        </select>
                                        <label for="email" class="absolute left-0 -top-3.5 text-gray-600 text-xs 
                                            peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 
                                            peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 
                                            peer-focus:text-gray-600 peer-focus:text-sm">Illness Type</label>
                                    </div>



                                </div>
                                <div class="flex space-x-12 pt-4 lg:pl-12 px-4 pt-10">
                                    <div class="relative">
                                        <div className="flex">
                                            <input autocomplete="off" id="email" name="email" type="text" class="peer w-36  h-9  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Doctor Name " />

                                        </div>
                                        <label for="email" class="absolute left-0 -top-3.5 text-gray-600 text-xs 
                                            peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 
                                            peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 
                                            peer-focus:text-gray-600 peer-focus:text-sm">Enter Name<span class="text-red-500">*</span></label>
                                    </div>


                                    <div class="relative">
                                        <div className="flex">
                                            <input autocomplete="off" id="email" name="email" type="text" class="peer w-36  h-9  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter information " />

                                        </div>
                                        <label for="email" class="absolute left-0 -top-3.5 text-gray-600 text-xs 
                                            peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 
                                            peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 
                                            peer-focus:text-gray-600 peer-focus:text-sm">Additional Notes<span class="text-red-500">*</span></label>
                                    </div>
                                </div>




                                <div class="flex space-x-12 pt-4 lg:pl-12 px-4 pt-10">
                                   

                                <div class="relative">
                                        <div className="flex ">
                                            <input autocomplete="off" id="email" name="email" type="text" class=" text-xs peer w-36  h-8  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="DD/MM/YYYY" />
                                            <img src={Dob} alt="my photo" className="relative right-5"></img>
                                        </div>
                                        <label for="email" class="absolute left-0 -top-3.5 text-gray-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Record On</label>
                                    </div>
                                </div>

                                <div className="flex justify-end mt-3">

                                    <button className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2">Save Data </button>
                                </div>

                            </div>


                        </div>

                        {/*  */}

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default Addmedicalcondition;