import React, { useState, useEffect } from 'react'
import { SearchIcon } from '@heroicons/react/outline'
import polygon from '../Assets/Images/Polygon.svg';
import UpcomingAppointment from './UpcomingAppointment';
import { Bar } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentCount, getAppointmentCounts } from '../Redux/Actions/patientAction';
import { useHistory } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2'
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import { getBillingdetails } from '../Redux/Actions/doctAction';
import moment from 'moment';
import patientService from '../Redux/services/patientService';
import { convertUTCToLocal } from '../helper/time';
import ChatBot from "../Assets/Images/CARESATHI.svg";
import "../components/careSathi/careSathi.css";


function PritamPatil() { 
    const history = useHistory();
    const dispatch = useDispatch();
    const { userData } = useSelector(state => state.authReducer);
    const { appointmentlistCount } = useSelector((state) => state.appointmentlist);
    const { billingList } = useSelector((state) => state.doctorconsultationlist);
    const [text, setText] = useState('');
    const [percentage, setpercentage] = useState(1);
    const [data, setData] = useState([])
    const [graphAmount, setGraphAmount] = useState([]);
    const [monthlyAmount, setMonthlyAmount] = useState([]);
    const [weeklyAmount, setWeeklyAmount] = useState(0);
    const [packagelist, setPackage] = useState([])
    const [patientlist, setPatient] = useState([])

    const [doughnut, setdoughnut] = useState([])
    const [genderCount, setgenderCount] = useState({
        male: 0,
        female: 0
    });

    useEffect(() => {
        loadAppointmentCounts();
        getPackage()
        //dispatch(getAppointmentCount({ userCode: userData.code, from: moment().clone().startOf('month').format('MM/DD/yyyy'), to: moment().clone().endOf('month').format('MM/DD/yyyy') }))
        
        dispatch(getBillingdetails(userData.code));
        console.log(userData)
    }, []);

    const loadAppointmentCounts = () => {
        const payload = {
            code: userData.code,
            fromDate: moment().clone().startOf('month').format('MM/DD/yyyy'),
            toDate: moment().clone().endOf('month').format('MM/DD/yyyy'),
            status: 1
        }
        // dispatch(getAppointmentCounts(payload)).then((res) => {
        //     console.log(res);
        // });
        dispatch(getAppointmentCounts(payload));
    }

    useEffect(() => {
        setdoughnut({
            labels: ['Male',
                'Female',
            ],
            datasets: [{
                label: 'Gender',
                data: [genderCount.male, genderCount.female],
                backgroundColor: ["#6970d5", "#ff8397"],
            }]

        })

    }, [setdoughnut, genderCount]);
const getPackage=()=>{
   let payload={
    status:1,
    publishStatus:'Y',
    userId:userData.code
   }
    patientService.getPackages(payload).then((result) => {
    
//   setPackage(result.data)
  setPackage(removeDuplicates(result.data,'packageCode'))
      });
    }

   const removeDuplicates =(array, key)=> {
        const seen = new Set();
        return array.filter(item => {
          const value = item[key];
          if (seen.has(value)) {
            return false;
          }
          seen.add(value);
          return true;
        });
      }
      const handleChange=(event)=>{
        setPatient([])

        let payload={
            packageCode:event.target.value,
            serviceCode:'APPT_INPERSON',
            serviceTaken:'N',
            uniquePatient:'Y'
           }
            patientService.getPatientlist(payload).then((result) => {
            
          setPatient(result.data)
            
              });
      }

      const handleChange1=(event)=>{
let payload1={
    userId:userData.code,
    status:1
}
        patientService.getuserFeesList(payload1).then((result) => {
    console.log(result)
      if(!result.message)    {  //   setPackage(result.data)
        let code =event.target.value
        let patientobj =  patientlist.find((x) => x.patientCode === code) 

        let payload={
            "consultationsReason":patientobj.packagesName,
            "consultationsType": "I",
            "createdBy": userData.code,
            "fromTime": "00:00",
            "hospitalId": "",
            "locationId": "",
            "cheCenterId":patientobj.cheCode,
            "cheBranchId": patientobj.cheLocationCode,
            "cheCenterName":patientobj.cheCenterName ,
            "cheBranchName": patientobj.cheBranchName,
            "modifiedBy": userData.code,
            "patientEmail": patientobj.patientEmail,
            "patientId": patientobj.patientCode,
            "patientMobileNo":patientobj.patientMobileNo,
            "patientName": patientobj.patientName,
            "status": 8,
            "toTime": "00:00",
            "userEmail":userData.email,
            "userId": userData.code,
            "userMobile": userData.mobile,
            "userName": userData.firstName +''+userData.lastName,
            "userQualification": userData.qualification,
            "userSalutation": userData.salutation,
            "whenAppointment": convertUTCToLocal(new Date(), 'MM/DD/YYYY'),
            "quickConsulted": userData.quickConsulted,
            "totalAmount": 0,
            "relation": null,
            "txnId": "",
            "payMode": "FULLDISCOUNT",
            "productinfo": "Appointment",
            "paymentLinkForPatient": "N",
            "availPackage": "P",
            "discountPercentage": 0,
            "discountAmount": result.data[0].inPersonConsFee,
            "paidAmount": 0,
            "serviceCode": patientobj.serviceCode,
            "packageCode": patientobj.packageCode,
            "userRoleCode": "CHE",
            "campYN": "Y",
            "sourceChannel": "ECLINIC",
            "masterServiceType":userData.quickConsulted == 1 ? "Quick Consultation" :'Specialist',
            "curebayFee": result.data[0].inPersonConsFee
        }
        patientService.campAppoinment(payload).then((result) => {
            if(!result.message){
                patientobj.consultationsType= "I"
                patientobj.patientId= patientobj.patientCode
                patientobj.whenAppointment = convertUTCToLocal(new Date(), 'MM/DD/YYYY')
                patientobj.fromTime=convertUTCToLocal(new Date(), 'hh:mm')
                patientobj.consultationsReason=patientobj.packagesName
                patientobj.id = result.data.id
                patientobj.patientgender= patientobj.patientGender
                    history.push({ pathname: APP_ROUTES.VIDEO_CALL, state: patientobj })
            }
         

              
                }
                , (err) => {
                    console.log(JSON.stringify(err));
                  });
                
        // history.push({ pathname: APP_ROUTES.VIDEO_CALL, state: patientobj })
    console.log(payload)  
    }});

          }
    useEffect(() => {
        var earnArr = [];
        var earnMonthArr = [];
        let startDate = moment().subtract(3, 'days').format('MM/DD/YYYY');
        let startMonth = moment().subtract(2, 'month').format('MMMM');
        let startOfWeek = moment().startOf('week').format('MM/DD/YYYY HH:mm:ss');
        let endOfWeek = moment().endOf('week').format('MM/DD/YYYY HH:mm:ss');

        let amount = 0;
        let monthAmount = 0;
        let weekAmount = 0;
        for (var i = 0; i < 4; i++) {
            billingList.filter(x => x.status === 1 && moment(x.paymentDateTime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY') === startDate).map(x => (
                amount += x.totalAmount
            ))
            earnArr.push(amount);
            amount = 0;
            startDate = moment(startDate).add(1, 'days').format('MM/DD/YYYY');
        }
        for (var i = 0; i < 3; i++) {
            billingList.filter(x => x.status === 1 && moment(x.paymentDateTime, 'YYYY-MM-DD HH:mm:ss').format('MMMM') === startMonth).map(x => (
                monthAmount += x.totalAmount
            ))
            earnMonthArr.push({ month: startMonth, amount: monthAmount });
            monthAmount = 0;
            startMonth = moment(startMonth, 'MMMM').add(1, 'month').format('MMMM');
        }

        billingList.filter(x => {
            var date = new Date(x.paymentDateTime);
            var startDate = new Date(startOfWeek);
            var endDate = new Date(endOfWeek);
           return x.status === 1 && (date >= startDate && date <= endDate)
        }).map(xt => (
        weekAmount += xt.totalAmount
    ));
        setWeeklyAmount(weekAmount);
        setGraphAmount(earnArr);
        setMonthlyAmount(earnMonthArr); 
    }, [billingList.length])


    useEffect(() => {
        setpercentage(80);
        let date = moment().subtract(3, 'days').format('DD/MM');
        let arr = [];
        for (var i = 0; i < 4; i++) {
            arr.push(date)
            date = moment(date, 'DD/MM').add(1, 'days').format('DD/MM')
        }
        setData({
            labels: arr,
            datasets: [{
                label: 'Earnings',
                data: graphAmount,
                backgroundColor: [

                    'rgba(54, 162, 235, 0.2)',

                ],
                borderColor: [

                    'rgba(54, 162, 235, 1)',

                ],
                borderWidth: 1,

            }]
        })

    }, [billingList.length, graphAmount]);

    const onSearch = () => {
        if (text)
            history.push({ pathname: APP_ROUTES.PATIENTS_LISTING, text: text })
    }


    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    }

    const SetCountFunc = (val) => {
        setgenderCount(val);
    }
    const navigation=()=>{
        history.push(APP_ROUTES.CARESATHI);
    }

  
    console.log("console.log(", monthlyAmount)
    return (
        <>
            <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 flex-wrap justify-start items-center">
                <div className="overflow-hidden">
                    <div>
                        <p className="text-xl text-gray-900 font-medium font-rubik">Namaste, Dr {userData.firstName} {userData.lastName}</p>
                    </div>
                </div>
                <img src={ChatBot} alt="ChatBot" onClick={(e)=>navigation()} className="cursor-pointer ml-6 mt-4 floating-icon" />
                {/* <div className="flex"> */}
                                             
                                            {/* </div> */}
                <div className=" flex flex-wrap justify-center">
                    <div className="bg-white shadow px-2 py-1 pr-9 rounded-lg flex justify-between w-72 relative top-0 items-center">
                        <input onChange={(e) => { setText(e.target.value) }} onKeyDown={_handleKeyDown} className="w-full rounded text-sm text-gunsmoke pl-2 outline-none" type="text" placeholder="Search for Patient/Hospital" />
                        <button onClick={onSearch} className="bg-brand-secondary  rounded text-white w-6 h-6 p-1 absolute right-2">
                            <SearchIcon color={'white'} />
                        </button>
                    </div>
                </div>
                <div className="overflow-hidden ">
                    <div className="flex flex-wrap justify-end">
                        <p className="text-sm text-gray-900 font-medium font-rubik">{moment().format('Do MMMM, hh:mm A')}</p>
                    </div>
                </div>

                <select
                                                    id="hospital"
                                                    name="hospital"
                                                    // value={hospitalId}
                                                    onChange={(handleChange)}
                                                    className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600">
                                                    {
                                                        <>
                                                            <option className="py-1 text-sm text-green-600" value="" selected disabled> Select Package </option>
                                                            {packagelist.map((res, i) => (
                                                                <option key={i} className="py-1 text-sm text-green-600" value={res.packageCode}>{res.packagesName}</option>
                                                            ))}
                                                        </>}
                                                </select>
                                                <select
                                                    id="hospital"
                                                    name="hospital"
                                                    // value={hospitalId}
                                                    onChange={(handleChange1)}
                                                    className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600">
                                                    {
                                                        <>
                                                            <option className="py-1 text-sm text-green-600" value="" selected disabled> Select Patient</option>
                                                            {patientlist.map((res, i) => (
                                                                <option key={i} className="py-1 text-sm text-green-600" value={res.patientCode}>{res.patientName}</option>
                                                            ))}
                                                        </>}
                                                </select>
            </div>



            <UpcomingAppointment genderCount={SetCountFunc} ></UpcomingAppointment>

            <div className="lg:grid p-10 pt-0 lg:grid-cols-1 lg:col-span-1 lg:grid-cols-1 sm:grid-cols-1 md:grid-cols-1  mb-10">
                {/* <!--Card 1--> */}
                <div className=" overflow-hidden  mb-5 lg:h-52  justify-between space-x-3 font-rubik mb-2">
                    <div className="rounded-lg overflow-hidden lg:w-4/4 lg:h-52 border boder-gray-600  p-4 font-rubik">
                        <div className="flex justify-between">
                            <p className="text-base text-gray-700 font-medium">Appointments</p>
                            <p className="text-xs text-gray-500 ">{moment().format('MMMM')}</p>
                        </div>
                        <div className="pt-3  ">
                            <div className="flex">
                                <p className="text-2xl font-bold text-gray-800 w-12">{appointmentlistCount?.UPCOMINGAPPOINTMENTCOUNT ? appointmentlistCount?.UPCOMINGAPPOINTMENTCOUNT : 0}</p>
                                <img src={polygon} alt="polygon" className="w-3 ml-5" />
                                <div className="pl-3">
                                    {/*<p className="text-xs text-brand-secondary ">0%</p>*/}
                                    <p className="text-xs text-brand-secondary">New Appointments</p>
                                </div>
                            </div>
                            <div className="flex pt-4">
                                <p className="text-2xl font-bold text-gray-800 w-12">{appointmentlistCount?.FOLLOWUPPATIENTCOUNT ? appointmentlistCount?.FOLLOWUPPATIENTCOUNT : 0}</p>
                                <img src={polygon} alt="polygon" className="w-3 ml-5" />
                                <div className="pl-3">
                                    {/*<p className="text-xs text-brand-secondary ">0%</p>*/}
                                    <p className="text-xs text-brand-secondary">Follow up Appointments</p>
                                </div>
                            </div>
                            <div className="flex pt-4">
                                <p className="text-2xl font-bold text-gray-800 w-12">{appointmentlistCount?.REFERRALCOUNT ? appointmentlistCount?.REFERRALCOUNT : 0}</p>
                                <img src={polygon} alt="polygon" className="w-3 ml-5" />
                                <div className="pl-3">
                                    {/*<p className="text-xs text-brand-secondary">0%</p>*/}
                                    <p className="text-xs text-brand-secondary">Referred Appointments</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/*<div className="rounded-lg overflow-hidden lg:w-2/4 h-52 border boder-gray-600  p-4 font-rubik lg:my-0 my-4">
                        <div className="flex justify-between">
                            <div className="text-center ml-12 w-48 mb-4">
                                <Doughnut
                                    data={doughnut}
                                    height={100}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: true,
                                    }}
                                />
                            </div>
                        </div>
                                </div>*/}
                </div>

                {/* <!--Card 3--> */}
                {/* <div className="rounded-lg overflow-scroll hide-scroll-bar lg:h-52 border boder-gray-600 lg:ml-4 p-4 font-rubik">
                    <div className="flex justify-between flex-wrap">
                        <p className="text-base text-gray-700 font-medium">Earnings<span className="text-xs text-gray-600 font-normal pl-1">(This weeks earnings - ₹ {weeklyAmount})</span></p>
                        <p className="text-xs text-gray-500 ">{moment().format('DD/MM/YYYY')}</p>
                    </div>
                    <div className="lg:flex justify-between pt-5">

                        <div className="lg:w-96">
                            <Bar data={data}

                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,

                                    scales: {
                                        yAxes: [
                                            {

                                                gridLines: {
                                                    display: false
                                                }
                                            }
                                        ],
                                        xAxes: [
                                            {
                                                gridLines: {
                                                    display: false
                                                },

                                            }
                                        ]
                                    }
                                }}
                            >
                            </Bar>
                        </div>
                        <div className="lg:block flex lg:mt-0 mt-2 ">
                            {monthlyAmount.length && monthlyAmount.map((x, i) => (
                                <div key={i} className="">
                                    <p className="text-sm">Earnings in {x.month}</p>
                                    <div className="flex mb-2">
                                        <p className="text-xs pr-2">₹ {x.amount}</p>
                                        <img src={polygon} alt="polygon" className="w-2" />
                                    </div>
                                </div>
                            )).reverse()}
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}
export default PritamPatil;