import { USER } from "../Constants/userprofileConstants";
import { PAT } from "../Constants/patientConstants";
import { VITALS } from "../Constants/vitalConstants";


const initialState = {
    doctorconsultationlistData: [],
    postConsultation: {},
    billingList: [],
    isError: false,
    isLoading: false,
    msg: '',
    errMsg: ''
};


const doctorconsultationlistReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER.REQUEST_DOCTCONSULTATIONLIST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case USER.SUCCESS_DOCTCONSULTATIONLIST:
            return {
                ...state,
                isLoading: false,
                doctorconsultationlistData: payload,
                isError: false,
            };
        case USER.FAILED_DOCTCONSULTATIONLIST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };

        case USER.REQUEST_GETBLLING:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case USER.SUCCESS_GETBLLING:
            return {
                ...state,
                isLoading: false,
                billingList: payload,
                isError: false,
            };
        case USER.FAILED_GETBLLING:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };

        case PAT.REQUEST_POST_CONSULT:
            return {
                ...state,
                isLoading: true,
                isError: false,
                success: false
            };
        case PAT.SUCCESS_POST_CONSULT:
            return {
                ...state,
                isLoading: false,
                slotData: payload,
                postConsultation: payload && payload.length > 0 ? payload.pop() : [],
                success: true,
                isError: false,
            };
        case PAT.FAILED_POST_CONSULT:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                success: false,
                isError: true
            };
        default:
            return state;
    }
};

export default doctorconsultationlistReducer;