import React from 'react';
import vc from '../Assets/Images/videoconsult.svg';
import arrow from '../Assets/Images/chevron-forward.svg';
import arrow1 from '../Assets/Images/chevron-forward1.svg'
import arrowalt from '../Assets/Images/arrow.alt.down.svg';
import user from '../Assets/Images/In-person.svg';



function WeekAppointments(){

    return (
      <>
    
        <div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 flex-wrap justify-start">
        </div>
            <div className="flex justify-between pt-5 mx-10">
                <div className="flex">
                    <p className="text-lg text-gray-700 font-medium pb-2">Appointments (60)</p>
                </div>
                <div className="flex space-x-6 ">
                    <div className="flex justify-between px-2 w-40  rounded-lg shadow">
                        <p className="text-xs text-gray-900 font-medium font-rubik pt-3">All</p>
                        <img src={arrowalt} alt="arrow" className="w-3"/>
                    </div>
                    <div className="flex justify-between px-2 w-56  rounded-lg shadow">
                        <p className="text-xs text-gray-600 font-medium px-3 font-rubik pt-3"><span className="text-gray-900 pr-1 font-medium ">Weekly </span>17 Jul- 24 Jul</p>
                        <img src={arrowalt} alt="arrow" className="w-3"/>
                    </div>
                </div>
            </div>


            <div className="grid grid-cols-7 divide-x divide-gray-400 divide-opacity-20 px-10 pt-5">
              
                <div> 
                    <div  className="flex">
                    <img src={arrow} alt="arrow" className="w-3 h-5 mt-3 "/>
                    <div className="ml-2 overflow-hidden bg-gray-200 shadow-sm border border-gray-200 rounded-xl h-12 w-36 flex justify-center">
                    <p className=" text-md font-rubik text-gray-700 pt-3 font-medium">Mon 19 Jul</p>
                    </div>  
                  </div>  

                  <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-5 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>
                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-5 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>
                    <div className=" overflow-hidden shadow-sm bg-green-100 rounded-xl p-2 w-36 ml-5 mt-4">
                    <div className="flex justify-center">
                        <p className="text-xs  text-gray-400 font-rubik text-center">10:00 - 12:30 AM</p>
                               
                    </div>
                    <p className=" text-sm font-rubik pt-2 pb-2 text-gray-400 text-center font-montserrat">Empty Slots</p>
                    </div>
                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-5 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>
                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-5 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                </div>

                <div className="ml-2 ">
                    <div className="mx-2 overflow-hidden bg-green-400 shadow-sm border border-gray-200 rounded-xl h-12 w-36 flex justify-center">
                    <p className=" text-md font-rubik text-white pt-3 font-medium">Tue 20 Jul</p>
                    </div> 

                    <div className="mx-2 mt-4 overflow-hidden shadow-sm border bg-green-100 border-green-500 rounded-xl w-36 p-2">
                        <div className="flex justify-between">
                            <p className="text-xs text-green-500">09:00 AM</p>
                            <img src={vc} alt="video call icon" className="w-6 " />       
                        </div>
                        <p className=" text-xs font-rubik pt-2 text-gray-500 font-montserrat">Vijay Sharma</p>
                        <div>
                            <p className="text-xs font-medium text-gray-700">Appt No. :<br/><span className="text-xs font-normal text-gray-500">5120630</span></p>
                            <p className="text-xs font-medium text-gray-700">Hospital:<br/><span className="text-xs  font-normal text-gray-500">Jupiter Hospital</span></p>
                        </div>
                        <button className="bg-brand-secondary text-xs ml-2 mt-2 flex justify-center text-white font-normal py-2 px-8 rounded-lg">Join Call</button>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-green-400 rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={user} alt="user" className="w-6 " />  
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm bg-green-100 rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-center">
                        <p className="text-xs  text-gray-400 font-rubik text-center">10:00 - 12:30 AM</p>
                               
                    </div>
                    <p className=" text-sm font-rubik pt-2 pb-2 text-gray-400 text-center font-montserrat">Empty Slots</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                </div>

                <div>
                    <div className="mx-2 overflow-hidden bg-gray-200 shadow-sm border border-gray-200 rounded-xl h-12  w-36 flex justify-center">
                    <p className=" text-md font-rubik text-gray-700 pt-3 font-medium">Wed 21 Jul</p>
                    </div> 

                    <div className=" overflow-hidden shadow-sm border border-green-400 rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />  
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm bg-green-100 rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-center">
                        <p className="text-xs  text-gray-400 font-rubik text-center">10:00 - 12:30 AM</p>
                               
                    </div>
                    <p className=" text-sm font-rubik pt-2 pb-2 text-gray-400 text-center font-montserrat">Empty Slots</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>
                </div>

                <div>
                    <div className="mx-2 overflow-hidden bg-gray-200 shadow-sm border border-gray-200 rounded-xl h-12  w-36 flex justify-center">
                    <p className=" text-md font-rubik text-gray-700 pt-3 font-medium">Thu 22 Jul</p>
                    </div> 
                    
                    <div className=" overflow-hidden shadow-sm border border-green-400 rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />  
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={user} alt="user" className="w-6 " />        
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={user} alt="user" className="w-6 " />     
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm bg-green-100 rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-center">
                        <p className="text-xs  text-gray-400 font-rubik text-center">10:00 - 12:30 AM</p>
                               
                    </div>
                    <p className=" text-sm font-rubik pt-2 pb-2 text-gray-400 text-center font-montserrat">Empty Slots</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                </div>

                <div>
                    <div className="mx-2 overflow-hidden bg-gray-200 shadow-sm border border-gray-200 rounded-xl h-12  w-36 flex justify-center">
                    <p className=" text-md font-rubik text-gray-700 pt-3 font-medium">Fri 23 Jul</p>
                    </div> 
                    
                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                </div>

                <div >
                    <div className="mx-2 overflow-hidden bg-gray-200 shadow-sm border border-gray-200 rounded-xl h-12  w-36 flex justify-center">
                    <p className=" text-md font-rubik text-gray-700 pt-3 font-medium">Sat 24 Jul</p>
                    </div> 

                    <div className=" overflow-hidden shadow-sm border border-green-400 rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " /> 
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm bg-green-100 rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-center">
                        <p className="text-xs  text-gray-400 font-rubik text-center">10:00 - 12:30 AM</p>
                               
                    </div>
                    <p className=" text-sm font-rubik pt-2 pb-2 text-gray-400 text-center font-montserrat">Empty Slots</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                </div>

                <div>

                {/* <div  className="flex">
                    <img src={arrow} alt="arrow" className="w-3 h-5 mt-3 "/>
                    <div className="ml-2 overflow-hidden bg-gray-200 shadow-sm border border-gray-200 rounded-xl h-12 w-36 flex justify-center">
                    <p className=" text-md font-rubik text-gray-700 pt-3 font-medium">Mon 19 Jul</p>
                    </div>  
                  </div>   */}
                  <div  className="flex">
                    <div className="mx-2 overflow-hidden bg-gray-200 shadow-sm border border-gray-200 rounded-xl h-12  w-36  flex justify-center">
                    <p className=" text-md font-rubik text-gray-700 pt-3 font-medium">Sun 25 Jul</p>
                    </div> 
                    <img src={arrow1} alt="arrow" className="w-3 h-5 mt-3 "/>
                    </div>

                    <div className=" overflow-hidden shadow-sm bg-green-100 rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-center">
                        <p className="text-xs  text-gray-400 font-rubik text-center">10:00 - 12:30 AM</p>
                               
                    </div>
                    <p className=" text-sm font-rubik pt-2 pb-2 text-gray-400 text-center font-montserrat">Empty Slots</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm bg-green-100 rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-center">
                        <p className="text-xs  text-gray-400 font-rubik text-center">10:00 - 12:30 AM</p>
                               
                    </div>
                    <p className=" text-sm font-rubik pt-2 pb-2 text-gray-400 text-center font-montserrat">Empty Slots</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>

                     <div className=" overflow-hidden shadow-sm bg-green-100 rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-center">
                        <p className="text-xs  text-gray-400 font-rubik text-center">10:00 - 12:30 AM</p>
                               
                    </div>
                    <p className=" text-sm font-rubik pt-2 pb-2 text-gray-400 text-center font-montserrat">Empty Slots</p>
                    </div>

                    <div className=" overflow-hidden shadow-sm border border-brand-secondary rounded-xl p-2 w-36 ml-2 mt-4">
                    <div className="flex justify-between">
                        <p className="text-xs font-medium text-brand-secondary font-rubik">09:00 AM</p>
                        <img src={vc} alt="video call icon" className="w-6 " />       
                    </div>
                    <p className=" text-sm font-rubik pt-2 text-gray-600 font-montserrat">Vijay Sharma</p>
                    </div>
                </div>


            </div>



        
        {/* <div className="pl-10 pr-10 pb-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-7 lg:grid-cols-7 xl:grid-cols-7 gap-4"> */}
          {/* <!--Card 1--> */}
          {/* <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div> */}
          {/* <!--Card 2--> */}
          {/* <div className=" overflow-hidden shadow-sm border bg-green-100 border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-green-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
                <div>
                    <p className="text-xs font-medium text-gray-700">Appt No. :<br/><span className="text-xs pl-1 font-normal text-gray-500">5120630</span></p>
                    <p className="text-xs font-medium text-gray-700">Hospital:<br/><span className="text-xs pl-1 font-normal text-gray-500">Jupiter Hospital</span></p>
                </div>
                <br /><button className="bg-brand-secondary text-xs  text-white font-normal py-2 px-4 rounded-lg">Join Call</button>
          </div> */}
          {/* <!--Card 3--> */}
          {/* <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div> */}
          {/* <!--Card 4--> */}
          {/* <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-green-500">09:00 AM</p>
                    <img src={user} alt="user" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div> */}
          {/* <!--Card 5--> */}
          {/* <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-blue-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div> */}
          {/* <!--Card 6--> */}
          {/* <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-blue-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div> */}
          {/* <!--Card 7--> */}
          {/* <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5  bg-gray-200">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 - 10:00 AM</p>    
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Empty Slots</p>
          </div> */}

          {/* <!--Card 8--> */}
          {/* <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5  bg-gray-200">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 - 10:00 AM</p>    
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Empty Slots</p>
          </div> */}
          
          {/* <!--Card 9--> */}
          {/* <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div> */}
          {/* <!--Card 10--> */}
          {/* <div className=" overflow-hidden shadow-sm border bg-green-100 border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-green-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
                <div>
                    <p className="text-xs font-medium text-gray-700">Appt No. :<br/><span className="text-xs pl-1 font-normal text-gray-500">5120630</span></p>
                    <p className="text-xs font-medium text-gray-700">Hospital:<br/><span className="text-xs pl-1 font-normal text-gray-500">Jupiter Hospital</span></p>
                </div>
                <br /><button className="bg-brand-secondary text-xs  text-white font-normal py-2 px-4 rounded-lg">Join Call</button>
          </div>
          <!--Card 11-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-green-500">09:00 AM</p>
                    <img src={user} alt="user" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div>
          <!--Card 12-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-blue-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div>
          <!--Card 13-->
          <div className=" overflow-hidden shadow-sm border bg-green-100 border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-green-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
                <div>
                    <p className="text-xs font-medium text-gray-700">Appt No. :<br/><span className="text-xs pl-1 font-normal text-gray-500">5120630</span></p>
                    <p className="text-xs font-medium text-gray-700">Hospital:<br/><span className="text-xs pl-1 font-normal text-gray-500">Jupiter Hospital</span></p>
                </div>
                <br /><button className="bg-brand-secondary text-xs  text-white font-normal py-2 px-4 rounded-lg">Join Call</button>
          </div>
          <!--Card 14-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div>
          <!--Card 15-->
          <div className=" overflow-hidden shadow-sm border bg-green-100 border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-green-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
                <div>
                    <p className="text-xs font-medium text-gray-700">Appt No. :<br/><span className="text-xs pl-1 font-normal text-gray-500">5120630</span></p>
                    <p className="text-xs font-medium text-gray-700">Hospital:<br/><span className="text-xs pl-1 font-normal text-gray-500">Jupiter Hospital</span></p>
                </div>
                <br /><button className="bg-brand-secondary text-xs  text-white font-normal py-2 px-4 rounded-lg">Join Call</button>
          </div>
          <!--Card 16-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div>
          <!--Card 17-->
          <div className="">
          </div>
          <!--Card 18-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-green-500">09:00 AM</p>
                    <img src={user} alt="user" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div>
          <!--Card 19-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5  bg-gray-200">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 - 10:00 AM</p>    
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Empty Slots</p>
          </div>
          <!--Card 20-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-blue-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div>
          <!--Card 21-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-blue-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div>
          <!--Card 22-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div>
          <!--Card 23-->
          <div className="">
          </div>
          <!--Card 24-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div>
          <!--Card 25-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div>
          <!--Card 26-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-green-500">09:00 AM</p>
                    <img src={user} alt="user" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div>
          <!--Card 27-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5  bg-gray-200">
                <div className="flex justify-between">
                    <p className="text-sm text-gray-500">09:00 - 10:00 AM</p>    
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Empty Slots</p>
          </div>
          <!--Card 28-->
          <div className="">
          </div>
          <!--Card 29-->
          <div className=" overflow-hidden shadow-sm border border-gray-200 rounded-xl p-5 ">
                <div className="flex justify-between">
                    <p className="text-sm text-blue-500">09:00 AM</p>
                    <img src={vc} alt="video call icon" className="w-6 " />       
                </div>
                <p className=" text-sm font-rubik pt-4 text-gray-700">Vijay Sharma</p>
          </div> */}

        {/* </div> */}
     </>

    )
}
export default WeekAppointments;