import React, { useState, useRef, useEffect } from "react";
import arrowicon from "../../Assets/Images/arrowicon.png";
import attach from "../../Assets/Images/attach.png";
import { InputTextarea } from "primereact/inputtextarea";
import "../../components/careSathi/careSathi.css";
import ChatLoading from "./chatLoading";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { secureStorage } from "../../Redux/Reducers/authReducer";
import { Dialog } from "primereact/dialog";
import "../../Assets/Images/arrowicon.png";
import "../../Assets/Images/attach.png";
import { loginWithPassword } from "../../Redux/Actions/userActions";

const CareSathi = () => {
  const [getAnswerBot, setgetAnswerBot] = useState([]);
  const [message, setMessage] = useState("");
  const [qaHistory, setQaHistory] = useState([]);
  const [extractedData, setExtractedData] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [visible, setVisible] = useState(true);
  const [documentType, setDocumentType] = useState(null);

  const chatContainerRef = useRef(null);
  const fileInputRef = useRef(null);

  const s_token = secureStorage.getItem("token");
  const activePatient = JSON.parse(localStorage.getItem("activePatient"));
  const circleCode = localStorage.getItem("userId");
  const userCode= localStorage.getItem("userCode");
  console.log(userCode,"USERCODEE");
  
  useEffect(() => {
    scrollToBottom();
  }, [qaHistory]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const typeEffect = (text, callback) => {
    let index = 0;
    const delay = 50;

    const typeCharacter = () => {
      if (index < text.length) {
        callback(text.slice(0, index + 1));
        index++;
        setTimeout(typeCharacter, delay);
      } else {
        callback(text); 
      }
    };

    typeCharacter();
  };

  const getAnswer = async (reData, document = null, documentType = null) => {
    try {
      setLoading(true);
      const requestBody = { user_prompt: reData, classify: 1 };
  
      if (document) {
        requestBody.document = document;
      }
  
      if (documentType) {
        requestBody.document_type = documentType;
      }
  
      const response = await fetch(
        `${process.env.REACT_APP_CARE_SATHI}all_in_one/get_answer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${s_token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const contentType = response.headers.get("Content-Type");
  
      let responseText;
      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        responseText = data.response;
      } else {
        responseText = await response.text();
      }
  
      console.log("Response received: ", responseText);
  
      const formattedAnswer = formatAnswer(responseText);
  
      typeEffect(formattedAnswer, (typedText) => {
        updateAnswer(typedText);
      });
  
      finalizeAnswer(formattedAnswer);
  
      setgetAnswerBot((prevAnswers) => [
        ...prevAnswers,
        { response: responseText },
      ]);
      saveMessageToApi({
        question: reData,
        answer: formattedAnswer,
        createdBy: userCode,
        sourceChannel: "DOCTOR",
      });
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setQaHistory((prevHistory) => prevHistory.slice(0, -1));
      setToastMessage("Something went wrong. Please try again.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };
  

  const formatAnswer = (response) => {
    return response
      .split("\n")
      .map((line) => {
        let result = "";
        let inBold = false;

        // Handle the cases for list items and bold formatting
        if (line.startsWith("* **")) {
          const parts = line.split("**");
          return `<li><strong>${parts[1]}</strong>${parts[2]}</li>`;
        }

        // if (line.startsWith("    * **")) {
        //   const parts = line.split("**");
        //   return `<li>&nbsp;&nbsp;&nbsp;&nbsp;<strong>${parts[1]}</strong>${parts[2]}</li>`;
        // }
        if (line.startsWith("    * **")) {
          line = "  • " + line.slice(2);
        }

        // Process bold text within the line
        for (let i = 0; i < line.length; i++) {
          if (line[i] === "*" && line[i + 1] === "*") {
            if (inBold) {
              result += "</strong>";
              inBold = false;
            } else {
              result += "<strong>";
              inBold = true;
            }
            i++; // Skip the next '*'
          } else {
            result += line[i];
          }
        }

        // Close any remaining bold tags
        if (inBold) {
          result += "</strong>";
        }

        // Wrap the result in list item tags if it starts with '• '
        if (line.startsWith("• ")) {
          return `<li>${result}</li>`;
        } else {
          return `<p>${result}</p>`;
        }
      })
      .join("");
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleArrowClick = async () => {
    if (loading) {
      setToastMessage("Please wait till the previous response is loading.");
      setOpenSnackbar(true);
      return;
    }

    if (!message && !extractedData) {
      setToastMessage("Please write a message or upload a file.");
      setOpenSnackbar(true);
      return;
    }

    const newEntry = {
      question: extractedData
        ? `File: ${uploadedFileName} Question: ${message}`
        : message,
      answer: "",
      isLoading: true,
    };

    setQaHistory((prevHistory) => [...prevHistory, newEntry]);

    try {
      await getAnswer(message, extractedData, documentType);
      setUploadedFileName("");
      setExtractedData("");
      setMessage("");
      setDocumentType(null);
      fileInputRef.current.value = "";
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const updateAnswer = (typedText) => {
    setQaHistory((prevHistory) =>
      prevHistory.map((item, index) =>
        index === prevHistory.length - 1
          ? { ...item, answer: typedText }
          : item
      )
    );
  };

  const finalizeAnswer = (formattedAnswer) => {
    setQaHistory((prevHistory) =>
      prevHistory.map((item, index) =>
        index === prevHistory.length - 1
          ? { ...item, answer: formattedAnswer, isLoading: false }
          : item
      )
    );
  };

  const saveMessageToApi = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEW_BASEURL}ChatBotQa/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${s_token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Message saved successfully:", responseData);
    } catch (error) {
      console.error("Failed to save message:", error);
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];

      if (
        selectedFile.type !== "application/pdf" &&
        selectedFile.type !== "image/jpeg" &&
        selectedFile.type !== "image/png"
      ) {
        console.error("File is not a PDF, JPEG, or PNG.");
        return;
      }

      setUploadedFileName(selectedFile.name);

      let fileType = "";
      switch (selectedFile.type) {
        case "application/pdf":
          fileType = "pdf";
          break;
        case "image/jpeg":
          fileType = "jpeg";
          break;
        case "image/png":
          fileType = "png";
          break;
        default:
          fileType = "unknown";
      }

      setDocumentType(fileType);

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setExtractedData(base64String);
        console.log(base64String, "EXTRACTED BASE64");
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
      <Dialog
        header="Disclaimer"
        visible={visible}
        style={{ width: "60vw" }}
        onHide={() => setVisible(false)}
      >
        <p className="p-7">
          CareSathi AI is designed to provide an automatic response with a
          general overview of the user's health status based on the available
          research data. CareSathi AI is not tailored to detect individual
          symptoms and is not a substitute for professional medical diagnosis.
          Users bear sole responsibility for their reliance on the response and
          we expressly disclaim any and all liability arising from the use and
          reliance on CareSathi AI responses. For personalized advice and
          recommendations, pls visit our CureBay clinic.
        </p>
      </Dialog>
      <div>
        <h1 className="sm:text-3xl text-lg text-[#005D8E] text-center font-bold mt-6 sm:mb-11 mb-6">
          Welcome to <span className="text-brand-primary">ChatBot Support</span>
        </h1>
      </div>
      <div className="sm:px-32 px-2">
        <div ref={chatContainerRef} className="chat-container">
          {qaHistory.map((item, index) => (
            <React.Fragment key={index}>
              <div className="p-5">
                <div className="w-full flex justify-between">
                  <div className="flex">
                    <p className="text-brand-primary font-semibold text-sm borderstyle2">
                      QN
                    </p>
                    <p className="sm:pt-1 pl-3">{item.question}</p>
                  </div>
                </div>
              </div>
              <div className="mt-12 flex bgclr px-8 py-3 w-full gap-3 mb-9">
                <div className="w-9">
                  <p className="text-brand-primary font-semibold text-sm borderstyle">
                    CS
                  </p>
                </div>
                <div className="">
                  {item.isLoading ? (
                    <ChatLoading />
                  ) : (
                    <div className="font-normal text-lg mb-6">
                      <div
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="w-full boxposition">
          <div className="border-2 pt-5 rounded-md bg-white">
            {uploadedFileName && (
              <div className="text-sm font-normal mb-2 ml-7">
                <strong>Uploaded File:</strong> {uploadedFileName}
              </div>
            )}
            <div className="w-full flex justify-between px-5">
              <div style={{ width: "96%" }}>
                <InputTextarea
                  value={message}
                  onChange={handleInputChange}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleArrowClick();
                    }
                  }}
                  placeholder="Please type your Medical Problems only ..."
                  rows={5}
                  style={{
                    maxHeight: "200px",
                    height: "52px",
                    width: "100%",
                    border: "none",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
              <div>
                <img
                  src={arrowicon}
                  className="bg-brand-primary arrowicon cursor-pointer"
                  onClick={handleArrowClick}
                />
              </div>
            </div>
            <div
              className="w-full flex sm:mt-8 mt-8 p-4"
              style={{ backgroundColor: "#cccccc26" }}
            >
              <label className="flex text-lg font-normal border-r-2 pr-3 cursor-pointer">
                <img src={attach} className="w-4 h-4 mr-2 mt-2" alt="Attach" />
                Attach
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  accept="application/pdf, image/jpeg, image/png"
                />
              </label>
              <label
                className="flex text-lg font-normal  pr-3 cursor-pointer"
                style={{ marginLeft: "950px" }}
                onClick={(e) => setVisible(true)}
              >
                Disclaimer
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareSathi;
