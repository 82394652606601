import { IMG_URL } from '../config/constant';
import { saveAs } from 'file-saver'
export const DownloadImage = (image) => {
  

    saveAs(IMG_URL+image, image)
};


export const viewImage = (image) => {
    // , 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1'
    window.open(IMG_URL + image, 'Image');
}

export const viewPDF = (pdfFile) => {
    window.open(IMG_URL + pdfFile, 'pdf');
}