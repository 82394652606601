
import { PAT } from "../Constants/patientConstants";
import { USER } from "../Constants/userprofileConstants";
import patientService from "../services/patientService";
import userprofileservice from "../services/userprofileservice";


export const getPatientdetails = (data) => async (dispatch) => {
    request();
    const res = await patientService.getpatientdetails(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_PATIENTLIST });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_PATIENTLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_PATIENTLIST,
            payload: err
        });
    };
};



export const getAppointmentList = (data) => async (dispatch) => {
    request();
    const res = await patientService.getappointmentlist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_APPOINTMENTLIST });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_APPOINTMENTLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_APPOINTMENTLIST,
            payload: err
        });
    };
};

export const getAppointmentCount = (data) => async (dispatch) => {
    request();
    const res = await patientService.getAppointmentCount(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_APPOINTMENTCOUNT });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_APPOINTMENTCOUNT, payload: res });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_APPOINTMENTCOUNT,
            payload: err
        });
    };
};

export const getAppointmentCounts = (data) => async (dispatch) => {
    request();
    const res = await patientService.getAppointmentCounts(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_APPOINTMENTCOUNTS });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_APPOINTMENTCOUNTS, payload: res });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_APPOINTMENTCOUNTS,
            payload: err
        });
    };
};




export const getupcomingAppointmentList = (doct, fromDate, toDate) => async (dispatch) => {
    request();
    const res = await patientService.getupcomingappointmentlist(doct, fromDate, toDate)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_UPCOMINGAPPOINTMENTLIST });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_UPCOMINGAPPOINTMENTLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_UPCOMINGAPPOINTMENTLIST,
            payload: err
        });
    };
};

export const getpatientfamilymemberslist = (data) => async (dispatch) => {
    request();
    const res = await patientService.getPatientfamilymember(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_PATIENTFAMILYMEMBERLIST });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_PATIENTFAMILYMEMBERLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_PATIENTFAMILYMEMBERLIST,
            payload: err
        });
    };
};

export const getFilteredAppointmentList = (data) => async (dispatch) => {
    request();
    const res = await patientService.getFilteredAppointments(data)
    try {
        console.log(JSON.stringify(res));
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        console.log(JSON.stringify(err));
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_UPCOMINGFILTEREDAPPOINTMENTLIST });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_UPCOMINGFILTEREDAPPOINTMENTLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_UPCOMINGFILTEREDAPPOINTMENTLIST,
            payload: err
        });
    };
};


export const getupcomingAppointmentListfilterhospital = (doct, hosp, fromDate, toDate) => async (dispatch) => {
    request();
    const res = await patientService.getupcomingappointmentlistfilterhospital(doct, hosp, fromDate, toDate)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_UPCOMINGAPPOINTMENTLISTFILTERHOSPITAL });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_UPCOMINGAPPOINTMENTLISTFILTERHOSPITAL, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_UPCOMINGAPPOINTMENTLISTFILTERHOSPITAL,
            payload: err
        });
    };
};





export const gethospitalclinicList = (data) => async (dispatch) => {
    request();
    const res = await patientService.gethospitalsandcliniclist(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_HOSPITALCLINICLIST });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_HOSPITALCLINICLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_HOSPITALCLINICLIST,
            payload: err
        });
    };
};


export const getPatientReports = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.getpatientReport()
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_PATIENTREPORT });
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_PATIENTREPORT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_PATIENTREPORT,
            payload: err
        });
    };
};


export const getPatientPHRReportss = (data) => async (dispatch) => {
    request();
    const res = await patientService.getpatientPHRreports(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_PATIENTPHRLIST });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_PATIENTPHRLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_PATIENTPHRLIST,
            payload: err
        });
    };
};

export const getPatientDocuments = (data) => async (dispatch) => {
    request();
    const res = await patientService.getpatientdocuments(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_PATIENTDOCUMENTS });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_PATIENTDOCUMENTS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_PATIENTDOCUMENTS,
            payload: err
        });
    };
};

export const getPostConsultation = (data) => async (dispatch) => {
    request();
    const res = await patientService.getPostConsultation(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_POST_CONSULT });
    };

    function success(res) {
        console.log(JSON.stringify(res))
        dispatch({ type: PAT.SUCCESS_POST_CONSULT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_POST_CONSULT,
            payload: err
        });
    };
};

export const getpatientvitaldetails = (data) => async (dispatch) => {
    request();
    const res = await patientService.getpatientvitalsdetails(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PAT.REQUEST_PATIENTVITALSDETAILS });
    };

    function success(res) {
        dispatch({ type: PAT.SUCCESS_PATIENTVITALSDETAILS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PAT.FAILED_PATIENTVITALSDETAILS,
            payload: err
        });
    };
};