
import { DIAG } from "../Constants/DiagnosticsConstants";
import Diagnosticsservice from "../services/Diagnosticsservice";


export const getlabtestlist = () => async (dispatch) => {
    request();
    const res = await Diagnosticsservice.LabtestList()
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DIAG.REQUEST_LABTESTLIST });
    };

    function success(res) {
        dispatch({ type: DIAG.SUCCESS_LABTESTLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DIAG.FAILED_LABTESTLIST,
            payload: err
        });
    };
}; 
