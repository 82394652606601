import { AUTH } from '../Constants/AuthConstants';
import { CURRENTLOCATION } from '../Constants/locationConstants';
import AuthService from "../services/authService";
import { secureStorage } from '../Reducers/authReducer';
import Geocode from "react-geocode";

export const sendOtp = (data) => async (dispatch) => {
    request();
    const res = await AuthService.sendOTP(data)
    try {
        if (res.data && !res.data.details) {
            success(res);
            return Promise.resolve(res.data);

        } else {
            return Promise.reject(res.data);
        }

    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: AUTH.REQUEST_OTP });
    };

    function success(res) {
        dispatch({ type: AUTH.RESPONSE_OTP, payload: res.data, });
    };
    function failure(err) {
        dispatch({
            type: AUTH.FAILED_OTP,
            payload: err
        });
    };
};

export const forgotPassword = (data) => async (dispatch) => {
    request();
    const res = await AuthService.forgotPassword(data)
    try {
        if (res.data && !res.data.details) {
            success(res);
            return Promise.resolve(res.data);

        } else {
            failure(res.data?.details[0])
            return Promise.reject(res.data);
        }
    } catch (err) {
        console.log("err",err)
        failure(err)
        
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: AUTH.REQUEST_CHANGEPASSWORD });
    };

    function success(res) {
        dispatch({ type: AUTH.SUCCESS_CHANGEPASSWORD, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: AUTH.FAILED_CHANGEPASSWORD,
            payload: err
        });
    };
};

export const verifyLogOtp = (data) => async (dispatch) => {
    request();

    const res = await AuthService.verifyLogOtp(data);
    try {

        if (res.data && !res.data.details) {
            if (!data.typePassword) {
                success(res);
            }
            return Promise.resolve(res.data);

        } else {
            return Promise.reject(res.data);
        }
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: AUTH.REQUEST_PATIENT });
    };

    function success(res) {

        dispatch({ type: AUTH.RESPONSE_PATIENT, payload: res.data, });
    };
    function failure(err) {
        dispatch({
            type: AUTH.FAILURE_PATIENT,
            payload: err
        });
    };
};

export const verifyRegOtp = (data) => async (dispatch) => {
    request();

    const res = await AuthService.verifyRegOtp(data);
    try {
        if (res.data && !res.data.details) {
            success(res);
            return Promise.resolve(res.data);

        } else {
            return Promise.reject(res.data);
        }
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: AUTH.REQUEST_OTP });
    };

    function success(res) {
        dispatch({ type: AUTH.RESPONSE_OTP, payload: res.data, });
    };
    function failure(err) {
        dispatch({
            type: AUTH.FAILED_OTP,
            payload: err
        });
    };
};

export const mobileExist = (data) => async (dispatch) => {
    request();
    const res = await AuthService.checkMobile(data);
    console.log("res", res)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: AUTH.REQUEST_OTP });
    };

    function success(res) {
        dispatch({ type: AUTH.RESPONSE_OTP, payload: res.data, });
    };
    function failure(err) {
        dispatch({
            type: AUTH.FAILED_OTP,
            payload: err
        });
    };
};



export const getCurrentlocation = () => async (dispatch) => {
    dispatch({
        type: CURRENTLOCATION.REQUEST_CURRENTLOCATION
    });
    try {
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position);
            // const lat = position.coords.latitude;
            // const long = position.coords.longitude;
            Geocode.setApiKey("AIzaSyAWa7-RTKOR7BulmJ1PWmDaJ9r2ZB8UqAs");
            Geocode.setLanguage("en");
            Geocode.setRegion("es");
            Geocode.enableDebug();
            Geocode.fromLatLng("19.1974199", "72.9946847").then(
                (response) => {
                    console.log(response)
                    const address = response.results[9].formatted_address;
                    console.log(address);
                    dispatch({ type: CURRENTLOCATION.SUCCESS_CURRENTLOCATION, payload: address })
                },
                (error) => {
                    console.error(error);
                }
            );
        });
    }
    catch (error) {
        dispatch({ type: CURRENTLOCATION.FAILED_CURRENTLOCATION, payload: error });
    }
};


export const getUserType = () => async (dispatch) => {
    request();
    const res = await AuthService.getuserType();
    try {
        console.log("resolve", res.data)
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: AUTH.REQUEST_USERTYPE });
    };

    function success(res) {
        dispatch({ type: AUTH.RESPOSNE_USERTYPE, payload: res.data, });
    };
    function failure(err) {
        dispatch({
            type: AUTH.FAILURE_USERTYPE,
            payload: err
        });
    };
};

export const Logout = (data) => async (dispatch) => {
    dispatch({ type: AUTH.LOGOUT });
    await AuthService.logout(data);
};

export const getToken = (data) => async (dispatch) => {
    request();

    const res = await AuthService.callToken(data);
    try {
        success(res);
        secureStorage.setItem('token', res.data?.access_token)
        localStorage.setItem('access_token', res.data?.access_token)
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: AUTH.REQUEST_PATIENT });
    };

    function success(res) {
        dispatch({ type: AUTH.SETTOKEN, payload: res.data?.access_token, });
    };
    function failure(err) {
        dispatch({
            type: AUTH.FAILURE_PATIENT,
            payload: err
        });
    };
};



export const loginWithPassword = (data) => async (dispatch) => {
    request();

    const res = await AuthService.LoginWithPassword(data);
    try {
        if (res.data && !res.data.details) {
            if (!data.typePassword) {
                success(res);
                localStorage.setItem('userCode', res.data.user.code);
            }
            return Promise.resolve(res.data);

        } else {
            return Promise.reject(res.data);
        }
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: AUTH.REQUEST_PATIENT });
    };

    function success(res) {

        dispatch({ type: AUTH.RESPONSE_PATIENT, payload: res.data, });
    };
    function failure(err) {
        dispatch({
            type: AUTH.FAILURE_PATIENT,
            payload: err
        });
    };
};


export const checkIsLoggedIn = () => {
    const userCode = secureStorage.getItem('userData') ? secureStorage.getItem('userData').code : null
    return userCode;
}

export const setPatientCode = (code) => async (dispatch) => {
    dispatch({ type: AUTH.CODE, payload: code });
};
