import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, SearchIcon, XIcon } from '@heroicons/react/outline'
import { docotorList, saveRefer } from '../Redux/Actions/MedicineActions'
import { useDispatch, useSelector } from 'react-redux'
import ReferalsService from '../Redux/services/referalService';
import moment from 'moment'
import Autocomplete from 'react-autocomplete';

export default function ReferModal(props) {
    const dispatch = useDispatch();
    const { patient } = props;
    //const { doctorListData } = useSelector(state => state.doctorpriscription)
    const [doctorListData, setdoctorList] = useState([]);
    const [hospitalsList, setHospitalsList] = useState([]);
    const [hospitalId, sethospitalId] = useState("");
    const [HosName, setHosName] = useState("");
    const [locationId, setlocationId] = useState("");
    const [hospitallistData, sethospitallistData] = useState([]);
    const [locationsList, setLocationsList] = useState([]);
    const [specialityList, setspecialityList] = useState([]);
    const userData = useSelector(state => state.authReducer.userData)
    const [err, setErr] = useState("");
    const [SetVal, onSetVal] = useState("");
    const [referData, setReferData] = useState({
        "hospitalId": "",
        "locationId": "",
        "patientId": patient.patientId,
        "referTo": patient.patientName,
        "referBy": userData.code,
        "description": "",
        "referDate": moment().format('yyyy-MM-DD hh:mm:ss'),
        "referType": "S",
        "status": 1,
        "createdBy": userData.code,
        "modifiedBy": userData.code
    });
    const onClose = () => {
        props.onClose();
    }

    useEffect(() => {
        ReferalsService.loadHospital().then((res) => {
            if (res.data) {
                sethospitallistData(res.data);
                //hospitallistData = res.data;
                console.log(JSON.stringify(hospitallistData));
            }
        }, (err) => {
            console.log(err);
        });
        let payload = {
            status: 1
        }
        ReferalsService.loadSpeciality(payload).then((res) => {
            if (res.data) {
                setspecialityList(res.data);
                //hospitallistData = res.data;
                console.log(JSON.stringify(specialityList));
            }
        }, (err) => {
            console.log(err);
        });

        //dispatch(docotorList())
    }, [])


    const handleChangeDD = (e) => {
        if (e.target.name == 'hospital') {
            sethospitalId(e.target.value);
            document.getElementById('location').value = "";
            document.getElementById('speciality').value = "";
            document.getElementById('referTo').value = "";
            getHospitalLocations(e.target.value);

        }
    };

    const handleChangeDD1 = (e) => {
        setHosName(e.name)
        sethospitalId(e.code);
        document.getElementById('location').value = "";
        document.getElementById('speciality').value = "";
        document.getElementById('referTo').value = "";
        getHospitalLocations(e.code);
    };
    const handleChangeLoc = (e) => {
        setlocationId(e.target.value);
    };

    const handleChangeSpec = (e) => {
        let payload = {
            hospitalId: hospitalId,
            locationId: locationId,
            specialityCode: e.target.value,
            status: 1
        }
        ReferalsService.loadUserbasedonLocation(payload).then((res) => {
            if (res.data) {
                setdoctorList(res.data);
                //hospitallistData = res.data;
                //console.log(JSON.stringify(hospitallistData));
            }
        }, (err) => {
            console.log(err);
        });;
    };
    const getHospitalLocations = (hospitalId) => {
        console.log(hospitalId);
        ReferalsService.loadLocation(hospitalId).then((res) => {
            if (res.data) {
                setLocationsList(res.data);
            }
        }, (err) => {
            console.log(JSON.stringify(err));
        })
    }
    console.log("doctorList", doctorListData)

    const handleChange = (e, index) => {
        setReferData({ ...referData, [e.target.name]: e.target.value });
    }
    const onDoctorChange = (e) => {
        let data = JSON.parse(e.target.value);
        setReferData({
            ...referData,
            "hospitalId": hospitalId,
            "locationId": locationId,
            "referTo": data.code,
            "referToName": data.userName,
        })
    }


    const onSave = () => {
        if (hospitalId == "") {
            setErr("select hospital")
            return;
        }
        if (locationId == "") {
            setErr("Select Location")
            return;
        }
        if (referData.referTo) {
            dispatch(saveRefer(referData))
            props.onClose();
        }
        else {
            setErr("select Doctor")
        }
    }
    const renderUsernamePD = (state, val) => {
        console.log(val)
        return (
            state.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
        );
    }
    console.log("referData", referData)
    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="fixed z-10  inset-0 overflow-y-auto" onClose={() => onClose()}>
                <div className="flex items-end  justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <div className="inline-block h-auto align-bottom bg-white rounded-lg text-left mt-20 md:mt-0 overflow-hidden shadow-xl transform transition-all sm:my-8 ">
                        <div className="bg-white px-2 pt-5 pb-4 sm:p-6 sm:pb-4 ">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 sm:mt-0 sm:ml-2 sm:text-left ">
                                    <div className="text-lg  font-medium text-brand-primary flex mb-4 border-b-2">
                                        <p>Refer Patient to Doctor</p>
                                        <XIcon onClick={() => onClose([])} className="h-6 w-6 text-black-600 right-3 absolute" aria-hidden="true" />
                                    </div>
                                    <div className="flex justify-between mb-4">
                                        <div className="relative mb-5">
                                            <div className="flex">
                                                <input autocomplete="off" id="patientId" name="patientId" value={patient.patientId} type="text" className="peer h-10 w-4/5 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Patient ID" readOnly />
                                            </div>
                                            {/* <input autocomplete="off" id="email" name="email" type="text" value="Enter Name" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" /> */}
                                            <label for="firstName" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Patient Id</label>
                                        </div>
                                        <div className="relative mb-5">
                                            <div className="flex">
                                                <input autocomplete="off" id="patienpatientNametId" name="patientName" value={patient.patientName} type="text" className="peer h-10 w-5/6 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Name" readOnly />
                                            </div>
                                            {/* <input autocomplete="off" id="email" name="email" type="text" value="Enter Name" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" /> */}
                                            <label for="firstName" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Patient Name</label>
                                        </div>
                                        <div className="relative mb-5 hidden">
                                            <div className="flex">
                                                <input autocomplete="off" id="AppointmentType" name="AppointmentType" value={patient.consultationsType === "V" ? "video" : "In Person"} type="text" className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="AppointmentType" readOnly />
                                            </div>
                                            {/* <input autocomplete="off" id="email" name="email" type="text" value="Enter Name" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" /> */}
                                            <label for="firstName" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">AppointmentType</label>
                                        </div>
                                    </div>
                                    <div className="relative mb-5">
                                        <div className="flex">
                                            <input autocomplete="off" id="complain" name="complain" value={referData.complain ? referData.complain : patient.consultationsReason} type="text" className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Chief Complain" onChange={handleChange} />
                                        </div>
                                        {/* <input autocomplete="off" id="email" name="email" type="text" value="Enter Name" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" /> */}
                                        <label for="firstName" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Symptoms</label>
                                    </div>
                                    <div className="relative mt-3 mb-5">
                                        {/* <div className="flex">
                                            <select
                                                id="hospital"
                                                name="hospital"
                                                onChange={handleChangeDD}
                                                className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600">
                                                {
                                                    hospitallistData && hospitallistData.length === 0 ? <option className="py-1 text-sm text-green-600" value="all"> All Hospital </option> :
                                                        <>
                                                            <option className="py-1 text-sm text-green-600" value=""> Select Hospital </option>
                                                            {hospitallistData.map((res, i) => (
                                                                <option className="py-1 text-sm text-green-600" value={res.code}>{res.name}</option>
                                                            ))}
                                                        </>}
                                            </select>
                                        </div> */}
                                        <div className="flex mt-3 border-b-2 border-gray-300 text-gray-900">
                                            <div className="autocomplete-wrapper w-full custom-width">
                                                <Autocomplete
                                                    id="hospital"
                                                    name="hospital"
                                                    className="peer bg-transparent h-10 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 mt-3"
                                                    // value={addList.patientName ? addList.patientName : val2}
                                                    value={HosName ? HosName : SetVal}
                                                    items={hospitallistData}
                                                    getItemValue={item => item.name}
                                                    shouldItemRender={renderUsernamePD}
                                                    renderMenu={item => (
                                                        <div className="dropdown">
                                                            {item}
                                                        </div>
                                                    )}
                                                    renderItem={(item, isHighlighted) =>
                                                        <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                                                            {item.name}
                                                        </div>
                                                    }
                                                    onChange={(event, val) => { onSetVal(val); setHosName("")}}
                                                    onSelect={(event, obj) => handleChangeDD1(obj)}
                                                // wrapperStyle={{ width: "100%", }}
                                                // inputProps={{ style: { width: "20rem" } }}
                                                // onChange={(handleChange)}
                                                />
                                            </div>
                                            <SearchIcon className="w-5 h-5" />

                                        </div>

                                        <label
                                            for="hospital"
                                            className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                        >
                                            Hospital
                                        </label>
                                    </div>

                                    <div className="relative mb-6">
                                        <div className="flex">
                                            <select
                                                id="location"
                                                name="location"
                                                onChange={handleChangeLoc}
                                                className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600">
                                                {
                                                    locationsList.length === 0 ? <option className="py-1 text-sm text-green-600" value="all"> All Location </option> :
                                                        <>
                                                            <option className="py-1 text-sm text-green-600" value=""> Select Location </option>
                                                            {locationsList.map((res, i) => (
                                                                <option className="py-1 text-sm text-green-600" value={res.code}>{res.name}</option>
                                                            ))}
                                                        </>}
                                            </select>
                                        </div>
                                        <label
                                            for="doctorLocation"
                                            className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                        >
                                            Location
                                        </label>
                                    </div>
                                    <div className="relative mb-5">
                                        <div className="flex">
                                            <select
                                                id="speciality"
                                                name="speciality"
                                                onChange={handleChangeSpec}
                                                className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600">
                                                {
                                                    specialityList && specialityList.length === 0 ? <option className="py-1 text-sm text-green-600" value="all"> All Speciality </option> :
                                                        <>
                                                            <option className="py-1 text-sm text-green-600" value=""> Select Speciality </option>
                                                            {specialityList.map((res, i) => (
                                                                <option className="py-1 text-sm text-green-600" value={res.code}>{res.speciality}</option>
                                                            ))}
                                                        </>}
                                            </select>
                                        </div>
                                        <label
                                            for="speciality"
                                            className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                        >
                                            Speciality
                                        </label>
                                    </div>

                                    <div className="block  md:flex">
                                        <div className="relative mb-5">
                                            <div className="flex">
                                                <select autocomplete="off" id="referTo" name="referTo" type="text" className="peer h-10 w-full md:w-32 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Select Doctor" onChange={onDoctorChange} >
                                                    <option className="py-1 text-sm text-green-600" value=""> Select Doctor </option>
                                                    {doctorListData.map((res, i) => (
                                                        <option value={JSON.stringify(res)}>{res.salutation}{res.firstName}{res.lastName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* <input autocomplete="off" id="email" name="email" type="text" value="Enter Name" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" /> */}
                                            <label for="referTo" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Select Doctor</label>
                                        </div>
                                        <div className="relative mb-5 ml-0 md:ml-10">
                                            <div className="flex">
                                                <input autocomplete="off" id="description" name="description" value={referData.description} type="text" className="peer h-10 w-full md:w-96 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Notes" onChange={handleChange} />
                                            </div>
                                            {/* <input autocomplete="off" id="email" name="email" type="text" value="Enter Name" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" /> */}
                                            <label for="description" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Description</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="text-red-600">{err}</p>
                        </div>

                        <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                className="cursor-pointer w-full inline-flex justify-center rounded-md  shadow-sm px-4 py-2 bg-brand-secondary text-base font-medium text-white  sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={onSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}
