import axios from "axios";
import moment from "moment";
import { secureStorage } from "../Reducers/authReducer";
import store from '../store/store';
// let token = secureStorage.getItem('token') ? secureStorage.getItem('token') : null;
// let s_token = store.getState().authReducer.token;
var axiosInstance = axios.create({

    baseURL: process.env.REACT_APP_BASEURL,
    headers: {
        "Content-type": "application/json",
        // "Authorization": `Bearer ${token ? token : s_token}`
    }
});

axiosInstance.interceptors.request.use(
    config => {
        let token = store.getState().authReducer.token;
        let s_token = secureStorage.getItem('token');
        config.headers["Authorization"] = `Bearer ${s_token ? s_token : token}`;
        return config;
    },
    error => {
        Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return new Promise((resolve) => {
            const originalRequest = error.config
            const refreshToken = localStorage.getItem('token')
            if (error.response && error.response.status === 401) {
                callApi();
                secureStorage.clear()
                // const response = fetch(api.refreshToken, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify({
                //         refresh: refreshToken,
                //     }),
                // })
                //     .then((res) => res.json())
                //     .then((res) => {
                //         localStorage.set(res.access, 'token')
                //         return axios(originalRequest)
                //     })
                // resolve(response)
            }

            return Promise.reject(error)
        })
    },
)

export default axiosInstance;


export async function callApi() {
    var data = new FormData();
    // @ts-ignore
    data.append('username', process.env.REACT_APP_USERNAME);
    // @ts-ignore
    data.append('password', process.env.REACT_APP_PASSWORD);
    // @ts-ignore
    data.append('grant_type', process.env.REACT_APP_GRANT_TYPE);
    // @ts-ignore
    await axios.post(process.env.REACT_APP_BASEURL + 'gcp/oauth/token', data, {
        // @ts-ignore
        auth: { username: process.env.REACT_APP_AUTH_USERNAME, password: process.env.REACT_APP_AUTH_PASSWORD }
    }).then(result => {
        secureStorage.setItem("token", result?.data?.access_token);
    });
}
