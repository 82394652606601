import React, { useState, useEffect } from 'react'
import search from '../Assets/Images/doctsearch.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientdetails, getPatientReports } from '../Redux/Actions/patientAction';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getdoctorprescription, getdoctorconsultationlist } from '../Redux/Actions/UserprofileActions';
import ProfileSidebar from './profileSidebar';
import ProfileNamebar from './profileNamebar';
import MobilePrescription from './mobilePrescription';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import {CircularProgress} from '@material-ui/core'
import moment from 'moment';


function DoctorPrescription() {



  // const history = useHistory();
  // const dispatch = useDispatch();

  // const loggedInData = useSelector((state) => state.authReducer);
  // const { userData } = loggedInData;
  // const doctorprescriptionlist = useSelector((state) => state.doctorpriscription);
  // const { doctorprescriptionData } = doctorprescriptionlist;
  // const [list, setList] = useState([]);
  // useEffect(() => {

  //   console.log(doctorprescriptionData)
  //   dispatch(getdoctorprescription(userData.code))
  //   dispatch(getdoctorconsultationlist(userData.code)).then((result) => {
  //     console.log("lol2", result)
  //   })

  // }, []);

  // const Onsearch = (value) => {
  //   let val = value ? value : '';
  //   // @ts-ignore
  //   var newArr = doctorprescriptionData;
  //   console.log("as", newArr)
  //   // @ts-ignore
  //   let newArray = newArr.filter((data) => JSON.stringify(data).toLowerCase().indexOf(val.toLowerCase()) !== -1);
  //   setList(newArray)
  // }

  // useEffect(() => {
  //   if (doctorprescriptionData.length > 0)
  //     Onsearch();
  // }, [doctorprescriptionData.length])


  const history = useHistory();
  const dispatch = useDispatch();
  const doctorconsultationlist = useSelector((state) => state.doctorconsultationlist);
  const { doctorconsultationlistData } = doctorconsultationlist;
  const [list, setList] = useState([]);
  const loggedInData = useSelector((state) => state.authReducer);
  const { userData } = loggedInData;
  const doctorprescriptionlist = useSelector((state) => state.doctorpriscription);
  const { doctorprescriptionData , isLoading} = doctorprescriptionlist;



  useEffect(() => {

    console.log(doctorconsultationlistData)
    dispatch(getdoctorconsultationlist(userData.code))
  }, []);



  const Onsearch = (text) => {
    let val = text ? text : '';
    // @ts-ignore
    var newArr = doctorconsultationlistData
    console.log("as", newArr)
    // @ts-ignore
    let newArray = newArr.filter((data) => JSON.stringify(data).toLowerCase().indexOf(val.toLowerCase()) !== -1);
    setList(newArray)
  }


  useEffect(() => {
    if (doctorconsultationlistData && doctorconsultationlistData.length > 0) {
      Onsearch();
    }
  }, [doctorconsultationlistData.length])


  return (
    <>



      <div className="lg:block hidden">
        <ProfileNamebar></ProfileNamebar>
      </div>

      {/* 2nd row */}
      <div className="lg:flex hidden justify-between ">
        <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">

          <ProfileSidebar></ProfileSidebar>
        </div>
        {/* table start */}
        <div className="w-11/12 lg:ml-10 ml-4 mt-3 border boder-gray-200 rounded-lg bg-white mr-8">
          <div className="flex space-x-3 pt-5 pl-5 pb-5">
            <img src={search} alt="search" className="w-4" />
            <input type="text" onChange={(e) => { Onsearch(e.target.value) }} className="text-sm text-brand-secondary font-medium" placeholder="Search or Filter" />
          </div>
          <div className="flex flex-col">
            <div className="lg:-my-2 overflow-x-auto sm:-mx-6 lg:-mx-0">
              <div className="lg:py-2 align-middle inline-block min-w-full sm:px-6 lg:px-0">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y ">
                    <thead >
                      <tr>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          {/* <input id="radio2" type="radio" name="radio" className="hidden" /> */}
                          {/* <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 flex-no-shrink"></span> */}
                          Patient Name
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Apt. No
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Type
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Location
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Date
                        </th>

                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Action
                        </th>

                      </tr>
                    </thead>
                    { isLoading && list.length === 0 &&
                <div className = "flex justify-center items-center relative left-80" style ={{height: "50vh"}}>
                <CircularProgress/>
            </div>}
                    {list.length === 0 && !isLoading ? <span>No prescription found</span> :
                      <tbody className=" divide-y divide-gray-200">
                        {list.map((user, i) => (
                            <tr>
                              {user.status === 2 ?
                                <>
                                  <td className="px-4 py-4 flex ">

                                    {/* <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 mt-1"></span> */}
                                    <p className="text-sm font-medium text-gray-600 font-montserrat">{user.patientName}</p>
                                  </td>
                                  <td className="px-4 py-4 whitespace-nowrap">
                                    <p className="text-sm font-medium text-gray-600 font-montserrat">{user.id}</p>
                                  </td>
                                  <td className="px-4 py-4 whitespace-nowrap">
                                    <p className="text-sm font-medium text-gray-600 font-montserrat">{user.consultationsType == "V" ? 'Video' : 'In Person'}</p>
                                  </td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <p className="text-sm font-medium text-gray-600 font-montserrat">{user.hospitalName ? user.hospitalName : 'NA'}</p>
                                  </td>
                                  <td className="px-4 py-4 whitespace-nowrap">
                                    <p className="text-sm font-medium text-gray-600 font-montserrat">{user.whenAppointment}</p>
                                  </td>
                                  {user.status === 2 ?
                                    <td className="px-4 py-4 whitespace-nowrap">
                                      <p onClick={() => history.push({ pathname: APP_ROUTES.POST_CONSULTATION, state: user.id })} className="text-sm font-medium text-brand-secondary font-montserrat">View Prescription</p>
                                    </td> : ''
                                  }
                                </> : null}
                            </tr>
                          ))}
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden block">
        <MobilePrescription />
      </div>
    </>
  )
}
export default DoctorPrescription;
