/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect } from 'react'
import steth from '../Assets/Images/Ellipse 71.png';
import arrow from '../Assets/Images/arrow.alt.down.svg'
import Dob from '../Assets/Images/calendar.svg'
import { useHistory, useLocation } from 'react-router-dom';
import close from '../Assets/Images/closeee.svg'
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import { useSelector } from 'react-redux';

function AddHealthRestriction(props) {
    const location = useLocation();
    const history = useHistory();
    const redirectTo = (event) => {
        event.preventDefault();
        history.push(location)
    }
    const goBack = () => {
       props.closePopup()
    }

    useEffect(() => {

        console.log(props)
        
    }, []);

   

 


  

    
    return (
   


        <>
            <div
                className="justify-center items-center mt-6 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-5/12 my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-5 mb-4">
                      



                    <div class="flex justify-between mb-2 mt-1">
                            <p class="text-medium font-medium text-2xl font-rubik text-brand-secondary cursor-pointer">{props?.edithealthrestrictions === '' ? `Add ` : 'Edit '} Health Restrictions</p>
                            <div className="flex space-x-6 cursor-pointer">
                                <img src={close} alt="close" class="w-4" onClick={goBack} />
                            </div>
                        </div>
                        <hr classname="border-dash text-black w-10 mt-8 h-20 my-2 "></hr>
                        <div>
                            <div >
                                {/* <img src={select} alt="select" class="w-4 ml-3 " /> */}
                                <p class="text-black-900 font-medium text-lg mt-4">Personal Details</p>
                            </div>
                            <div class="flex pt-2 ">
                                <div className="w-11/12 " >
                                    <div class="flex justify-between py-6 space-x-10 ">
                                        <div class="relative">
                                            <div className="flex">
                                                {/* <input autocomplete="off" id="email" name="email" type="text" class="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Name" /> */}
                                                <select class="lg:w-48  my-2 outline-none peer lg:w-full w-40  border-b-2 border-gray-300 ">
                                                    <option class="py-1  ">Enter Relationship</option>
                                                    <option class="py-1">Option 2</option>
                                                    <option class="py-1">Option 3</option>
                                                </select>

                                            </div>
                                            <label for="email" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Record For</label>
                                        </div>
                                        <div class="relative">
                                            <div className="flex ">
                                                <input autocomplete="off" id="email" name="email" type="text" class="peer lg:w-full w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="DD/MM/YYYY" />
                                                <img src={Dob} alt="my photo" className="relative right-5" ></img>
                                            </div>
                                            <label for="password" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Recorded On </label>
                                        </div>
                                    </div>
                                    <div >
                                        <p class="text-black-900 font-medium text-lg mt-2">Do you have any Health Restrictions?</p>
                                    </div>

                                    <div className=" flex space-x-10">
                                        <div className=" flex space-x-4 items-center py-2">
                                            <input type="radio" class="form-radio mt-1 mr-2" name="accountType" value="personal" />
                                            <div className="text-sm font-medium text-gray-500 font-montserrat">Yes</div>

                                        </div>
                                        <div className=" flex space-x-4 items-center py-2">
                                            <input type="radio" class="form-radio mt-1 mr-2" name="accountType" value="personal" />
                                            <div className="text-sm font-medium text-gray-500 font-montserrat">No</div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between py-8 space-x-10 ">
                                        <div class="relative">
                                            <div className="flex ">
                                                <input autocomplete="off" id="email" name="email" type="text" class="peer lg:w-full w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Allergy Name" />
                                            </div>
                                            <label for="password" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Health Restriction Name </label>
                                        </div>

                                        <div class="relative lg:px-2">
                                            <div className="flex">
                                                {/* <input autocomplete="off" id="email" name="email" type="text" class="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Name" /> */}
                                                <select class="lg:w-48 w-40 my-2 outline-none peer border-b-2 border-gray-300 ">
                                                    <option class="py-1 text-xs ">Please Select</option>
                                                    <option class="py-1">Option 2</option>
                                                    <option class="py-1">Option 3</option>
                                                </select>

                                            </div>

                                            <label for="email" class="absolute lg:left-3 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Record For</label>
                                        </div>
                                    </div>

                                    <div class="flex justify-between py-6 space-x-10 ">
                                        <div class="relative">
                                            <div className="flex ">
                                                <input autocomplete="off" id="email" name="email" type="text" class="peer lg:w-full w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Doctor Name" />
                                            </div>
                                            <label for="password" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Doctor Name </label>
                                        </div>
                                        <div class="relative  ">
                                            <div className="flex ">
                                                <input autocomplete="off" id="email" name="email" type="text" class="peer lg:w-full w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="DD/MM/YYYY" />
                                                <img src={Dob} alt="my photo" className="relative right-5" ></img>
                                            </div>
                                            <label for="email" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">End Date</label>
                                        </div>
                                    </div>

                                    <div class="flex space-x-10 py-8  ">

                                        <div class="relative  ">
                                            <div className="flex ">
                                                <input autocomplete="off" id="email" name="email" type="text" class="peer lg:w-full w-40 h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="Enter Information" />

                                            </div>
                                            <label for="password" class=" absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Additonal Notes</label>
                                        </div>





                                    </div>

                                    <div className="flex justify-end">

                                        <button onClick={(e) => redirectTo(e)} className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2">Save Data </button>
                                    </div>








                                </div>












                            </div>





                        </div>


                     
                       
                       
                    </div>


                 
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}
export default AddHealthRestriction;
