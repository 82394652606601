import http from "./http-common";

class ReferalsService {
  referalTo(data) {
    return http.get(`Referal/list?ReferBy=${data}&ReferType=${"H"}`);
  }
  referalToDoctor(data) {
    return http.get(`Referal/list?ReferBy=${data}&ReferType=${"S"}`);
  }
  referalFrom(data) {
    return http.get(`Referal/list?ReferTo=${data}`);
  }
  loadHospital() {
    return http.get(`hospital/list/filter1?type=H&status=1`);
  }
  loadLocation(hosptialId) {
    return http.get(`location/list/filter?status=1&hospitalType=H&hospitalCode=${hosptialId}`)
  };
  loadUserbasedonLocation(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `User_Hospital_Location/Users/list?${queryString}`
    );
  }
  loadSpeciality(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`Speciality/list?${queryString}`)
  }
  referHospitalClinic(data) {
    return http.post(`RecommendContacts/`, data)
  }
  referalClinicList(data) {
    return http.get(`/RecommendContacts/list?recommendById=${data.userCode}&recommendType=${data.type}`);
  }
  referalDoctorList(data) {
    return http.get(`/RecommendContacts/list?recommendById=${data.userCode}&recommendType=${data.type}`);
  }
  referPatientcurebay(data) {
    return http.post(`/ReferToCurebay/`, data)
  }
  referalPatientList(data) {
    return http.get(`/ReferToCurebay/list?ReferById=${data}`)
  }
  refertoadd(data) {
    return http.post(`/Referal/`, data)
  }
}
export default new ReferalsService();