import React, { useState, useEffect } from 'react'
import search from '../Assets/Images/doctsearch.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientdetails, getPatientReports } from '../Redux/Actions/patientAction';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getdoctorconsultationlistWithFilter, getdoctorprescription } from '../Redux/Actions/UserprofileActions';
import ProfileSidebar from './profileSidebar';
import ProfileNamebar from './profileNamebar';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import MobileDoctorConsultation from './mobileDoctorConsultation';
import {CircularProgress} from '@material-ui/core';
import moment from 'moment';
import FilterConsultation from './FilterConsultation';
import { format } from 'date-fns';
import Backdrop from '@mui/material/Backdrop';

function DoctorConsultation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false);
  const doctorconsultationlist = useSelector((state) => state.doctorconsultationlist);
  const { doctorconsultationlistData , isLoading } = doctorconsultationlist;
  const [list, setList] = useState([]);
  const loggedInData = useSelector((state) => state.authReducer);
  const { userData } = loggedInData;
  const [showFilter, setShowFilter] = useState(false)
  useEffect(() => {
    setLoading(true);  // Start loading

    const today = new Date();
    const fromDate = format(today, 'MM/dd/yyyy');
    const toDate = format(new Date(today.setDate(today.getDate() - 7)), 'MM/dd/yyyy'); 

    const data = {
      userId: userData.code,
      fromDate:  toDate,
      toDate: fromDate,
    };

    dispatch(getdoctorconsultationlistWithFilter(data))
      .then(() => {
        setLoading(false);  // Stop loading after data is fetched
      })
      .catch((error) => {
        console.error('Error fetching doctor consultation list:', error);
        setLoading(false);  // Stop loading even if there's an error
      });

  }, [dispatch]);



  const onSearch = (text) => {
    let val = text ? text : '';
    // @ts-ignore
    var newArr = doctorconsultationlistData
    console.log("as", newArr)
    // @ts-ignore
    let newArray = newArr.filter((data) => JSON.stringify(data).toLowerCase().indexOf(val.toLowerCase()) !== -1);
    setList(newArray)
  }


  useEffect(() => {
    // if (doctorconsultationlistData && doctorconsultationlistData.length > 0) {
    //   onSearch();
    // }
    setList(doctorconsultationlistData)
  }, [doctorconsultationlistData.length,doctorconsultationlistData])
  const openFilter = () => {
    setShowFilter(true);
  }
  const loadFilter =(data)=>{
    //  alert(JSON.stringify(data));
     dispatch(getdoctorconsultationlistWithFilter(data))
  }
  return (
    <>
 <Backdrop
        sx={{ color: "#5579C6", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="lg:block hidden">
        <ProfileNamebar></ProfileNamebar>
      </div>

      {/* 2nd row */}
      <div className="lg:flex hidden justify-between ">
        <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">

          <ProfileSidebar></ProfileSidebar>
        </div>
        {/* table start */}
        <div className="w-11/12 lg:ml-10 ml-4 mt-3 border h-screen overflow-scroll boder-gray-200 rounded-lg bg-white mr-8">
          {/* <div className="flex space-x-3 pt-5 pl-5 pb-5"> */}
            {/* <img src={search} alt="search" className="w-4" />
            <input type="text" onChange={(e) => { onSearch(e.target.value) }} className="text-sm text-brand-secondary font-medium" placeholder="Search or Filter" /> */}
           <div className="flex p-3 justify-end">
            {/* <div className="theme-color menuitem cursor-pointer" onClick={openAddMapHospital}>
                <div className="font-14"><span className="icon-new font-14 cursor-pointer">New</span></div>
              </div>
              <div className="theme-color menuitem pl-5" onClick={openEditMapHospital}>
                <div className="font-14"><span className="icon-view font-14">View</span></div>
              </div> */}
            <div className="theme-color menuitem pl-5" 
            onClick={openFilter}
            >
              <div className="font-18 ml-5"><span className="icon-filter font-14">Filter</span></div>
            </div>
          {/* </div> */}
          </div>

          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-0">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-0">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y ">
                    <thead >
                      <tr>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Patient Name
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Apt. No
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Type
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Location
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Date
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Sypmtoms
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                          Action
                        </th>

                      </tr>
                    </thead>
                    { isLoading && list.length === 0 &&
                <div className = "flex justify-center items-center relative left-80" style ={{height: "50vh"}}>
                <CircularProgress/>
            </div>}
                    <tbody className=" divide-y divide-gray-200">

                      {list.map((user, i) => (
                        <tr>
                          <td className="px-4 py-4 flex ">
                            <p className="text-sm font-medium text-gray-600 font-montserrat">{user.patientName}</p>
                          </td>
                          <td className="px-4 py-4 whitespace-nowrap">
                            <p className="text-sm font-medium text-gray-600 font-montserrat">{user.id}</p>
                          </td>
                          <td className="px-4 py-4 whitespace-nowrap">
                            <p className="text-sm font-medium text-gray-600 font-montserrat">
                            {user.consultationsType == 'V' ? 'Video': user.consultationsType == 'A' ? 'Audio' : user.consultationsType == 'I' ? 'In Person' :  'Quick'}
                            </p>
                          </td>
                          <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                            <p className="text-sm font-medium text-gray-600 font-montserrat">{user.hospitalName ? user.hospitalName : 'NA'} </p>
                          </td>
                          <td className="px-4 py-4 whitespace-nowrap">
                            <p className="text-sm font-medium text-gray-600 font-montserrat">{moment(user.whenAppointment).format("DD/MM/yyyy")}</p>
                          </td>
                          <td className="px-4 py-4 flex ">
                            <p className="text-sm font-medium text-gray-600 font-montserrat">{user.consultationsReason}</p>
                          </td>
                          <td className="px-4 py-4 whitespace-nowrap cursor-pointer">
                            {user.status === 2 ? <button onClick={() => { history.push({ pathname: APP_ROUTES.POST_CONSULTATION, state: {id: user.id, hidesubmit: true}},) }} className=" mr-2 text-brand-secondary hover:text-brand-secondary">View Prescription</button> : ''}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showFilter && (
        <FilterConsultation
          closePopup={() => {
            setShowFilter(false);
          }}
          filterData={loadFilter}
          onClose={() => {
            setShowFilter(false);
          }}
        >
        </FilterConsultation>
      )}
      <div  class="lg:hidden block">
      <MobileDoctorConsultation/>
      </div>
    </>
  )
}
export default DoctorConsultation;
