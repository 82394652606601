import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import share from "../Assets/Images/share.svg";
import PatientDetails2 from "./paitentDetails2";
import { Dialog } from "primereact/dialog";
import patientService from "../Redux/services/patientService";

function PrivacyPolicy(props) {

    useEffect(() => {

    }, []);

    return (
        <>
            <p class="flex text-medium font-medium text-2xl ml-5 my-3 font-rubik">Privacy Policy</p>

            <div className="lg:flex border border-gray-200 my-3 content-center lg:justify-between  rounded-lg p-5 ">
                <p>
                    <b>1. INTRODUCTION</b><br /><br />
                    &nbsp; &nbsp; &nbsp;Curebay Technologies Private Limited, a company which owns, manages and operates the <a class='text-blue-400'><u>www.curebay.com</u></a>, <a class='text-blue-400'><u>www.curebay.in</u></a> <b>(“Websites”)</b> or, software applications or mobile applications including the Curebay application <b>(“Apps”)</b> (Websites and Apps shall collectively be the <b>“Portal”</b>) under the brand name ‘Curebay’ (hereinafter referred as <b>“Company”, “We”, “Us” or “Our”</b>).<br /><br />

                    &nbsp; &nbsp; &nbsp;Curebay Technologies Private Limited has created this Privacy Policy <b>(“Policy”)</b> to demonstrate its commitment towards compliance with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information), Rules, 2011 and other applicable laws.<br /><br />

                    &nbsp; &nbsp; &nbsp;For the purposes of this Policy the term <b>“User(s)”</b> and wherever the context so requires <b>“You”, “Your”, “Yourself”</b> shall mean any natural or legal person who accesses or Uses the Portal and in the event that a natural person is representing a business entity or a body corporate, reference to such terms shall include a reference to such business entity and/or body corporate as well and other promoters of such business entity and/or body corporate. All references to <b>“You”</b> shall include Yourself and any other persons You are authorised to and required to provide consent for.<br /><br />

                    &nbsp; &nbsp; &nbsp;The purpose of this Policy is to give You information on how the Company collects, stores, Uses, discloses, transfers and processes Your Personal Information (defined below) including Sensitive Personal Information (defined below) when: (a) You Use the Portal; or (b) You avail any products or services which We may operate or offer to You through the Portal. This Policy also governs Our practices for collecting, Using, maintaining, protecting, and disclosing Your User Information (defined below).<br /><br />

                    &nbsp; &nbsp; &nbsp;The term <b>“Websites”</b> includes all pages that are sub-domains or are associated with or exist within each Website which are hosted by the Company. By accessing the Portal and/or Using products or services provided by Us directly or indirectly, You agree that You have read and understood, and agree to be bound by this Policy and Our Terms and Conditions. By Using the Portal, and/or having Used the products or services offered by Us directly or indirectly, in the past, present or future, You expressly and explicitly consent to Our Use and disclosure of Your User Information in accordance with this Policy. If You do not consent to these, We request that You do not Use or access Our Portal. You further acknowledge and accept that Your consent herein shall also be applicable to Your User Information (defined below) submitted to Us, directly or indirectly, in the past.<br /><br />

                    &nbsp; &nbsp; &nbsp;It is strongly recommended for You to return to this page periodically to review the most current version of this Policy which is amended by Us from time to time.<br /><br />

                    <b><u>Your acknowledgment and consent</u></b><br /><br />

                    &nbsp; &nbsp; &nbsp;This Policy applies only to the User Information collected by Us on the Portal. By Your acceptance of this Policy, You explicitly consent to collection, storage, processing, handling and Use of such User Information, in accordance with this Policy (as amended from time to time).<br /><br />

                    &nbsp; &nbsp; &nbsp;This Policy does not apply to information that You provide to, or that is collected by, any third-party, such as pharmacies, merchants or other partner platforms from which You avail services and social networks that You Use in connection with Our services. We encourage You to consult directly with such third-parties about their privacy practices.<br /><br />

                    &nbsp; &nbsp; &nbsp;Please read this Policy carefully to understand Our policies and practices regarding Your information and how it will be treated in accordance with this Policy.<br /><br />

                    <b>2. INFORMATION THAT WE COLLECT</b><br /><br />

                    &nbsp; &nbsp; &nbsp;We collect various types of information from and about Users of Our Portal, including:<br /><br />

                    &nbsp; &nbsp; &nbsp;<b>“Personal Information” </b>which includes any information concerning the personal or material circumstances of an identified or identifiable individual and is capable of uniquely identifying a person and/or the business entity that a person represents.<br /><br />

                    &nbsp; &nbsp; &nbsp;We may ask You to provide certain additional information about Yourself on a case-to-case basis. All information disclosed by You shall be deemed to be disclosed willingly and without any coercion. No liability pertaining to the authenticity / genuineness / misrepresentation / fraud / negligence of the information disclosed shall lie on the Company nor will the Company be in any way responsible to verify any information obtained from You.<br /><br />

                    &nbsp; &nbsp; &nbsp;As a matter of principle, we do not collect certain special categories of Personal Information about You such as details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, information about your health or any genetic information.<br /><br />

                    &nbsp; &nbsp; &nbsp;<b>“Sensitive Personal Information” </b>means any Personal Information of an individual, which consists of information relating to passwords; financial information such as bank account, credit card, debit card or other payment instrument details; sexual orientation; physical, physiological and mental health condition; and medical records and history. Information freely available or accessible in public domain is not treated as Sensitive Personal Information. Depending on who You are (e.g., a merchant, customer, cardholder, consumer, supplier, or business partner) and how You interact with Us (e.g., through Website, App, telephone, IVR, API, online or offline), We may collect, Use, receive, store, analyze, combine, transfer or otherwise process different categories of Your Personal Information (including Sensitive Personal Information).<br /><br />

                    &nbsp; &nbsp; &nbsp;Any information that is freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any other law for the time being in force shall not be regarded as Sensitive Personal Information for the purposes of this Policy.<br /><br />

                    &nbsp; &nbsp; &nbsp;You provide Your explicit consent for collection of information, on Our Portal (which may be collected from the parties as per this Policy), which includes but is not limited to <b>(“User Information”)</b>:<br /><br />

                    &nbsp; &nbsp; &nbsp; a. Your full name, address, email address, telephone number, date of birth/age and bank or payment details and any proof of Your identity and/or address, postal code, profile picture, password and other information You may provide with Your account (including but not limited to, health records, medical records and history, physical, physiological or mental condition, doctor prescriptions etc.), or any other documents or any information deemed necessary by Us, such as Your gender, mobile phone number and Website and all other information, You may provide Us through third-party sign-in services such as Facebook and Google. In such events/ cases, We fetch and store whatever information is made available to Us by You through these sign-in services or otherwise;<br /><br />

                    &nbsp; &nbsp; &nbsp; b. Your device's camera, microphone, location or any other facility as may be required for KYC purposes and which will be accessed only one time and only for KYC purposes;<br /><br />

                    &nbsp; &nbsp; &nbsp; c. Your preferences including settings such as time zone and language;<br /><br />

                    &nbsp; &nbsp; &nbsp; d. IP addresses, application, device or browser type, versions and configurations, operating systems, device brand and model, time zone setting, content, unique identifiers associated with Your device(s) and pages that You access on the Portal, and the dates and times that You visit the Portal, and paths taken;<br /><br />

                    &nbsp; &nbsp; &nbsp; e. Unique mobile device identifier (e.g., IDFA or other device IDs on Apple devices like the iPhone and iPad), if You are Using Our Portal on a mobile device, We may Use mobile device IDs (the unique identifier assigned to a device by the manufacturer), instead of cookies, to recognize You. Unlike cookies, mobile device IDs cannot be deleted from records. We may share it with advertising companies, and they may Use device IDs to track Your Use of Our applications, track the number of advertisements displayed, measure advertising performance and display advertisements that are more relevant to You. We may also share it with analytics companies which may Use mobile device IDs to track Your Usage of Our applications/ Portal;<br /><br />

                    &nbsp; &nbsp; &nbsp; f. We access Your phone resources or metadata only in compliance with applicable law.<br /><br />

                    &nbsp; &nbsp; &nbsp; g. Details of Your visits to the Portal, including but not limited to, Web logs and other communication data; other information related to the Portal such as Your search queries, comments, domain names, search result selected, number of clicks, pages viewed and order of those pages, how long You visited our Portal, the date and time You used the Portal, error logs and other similar information;<br /><br />

                    &nbsp; &nbsp; &nbsp; h. Your search details such as search terms You have looked up and results You selected;<br /><br />

                    &nbsp; &nbsp; &nbsp; i. The information provided by You such as ratings, reviews, tips, photos, comments, likes, bookmarks, friends, lists, etc. to be published or displayed on publicly accessible areas of Our Portal or transmitted to other Users of Our Portals or third- parties (collectively, "User Inputs");<br /><br />

                    &nbsp; &nbsp; &nbsp; j. Information collected from third party business partners, technical sub-contractors, analytics providers, search information providers, etc. such as delivery address or contact information or other details which may be combined with the User Information collected on the Portal and as provided in this Policy; and<br /><br />

                    &nbsp; &nbsp; &nbsp; k. Information collected through cookies.<br /><br />

                    The User Information includes any Personal Information and Sensitive Personal Information.<br /><br />

                    &nbsp; &nbsp; &nbsp; Where possible, We indicate which fields are mandatory and which fields are optional to be filled on the Portal. You always have the option to not provide information by choosing not to submit particular information or feature on the Portal.<br /><br />

                    &nbsp; &nbsp; &nbsp;Your User Inputs are posted on and transmitted to others at Your own risk. Please be aware that no security/ data protection measures are perfect or impenetrable. Additionally, We cannot control the actions of other Users of Our Portal with whom You may choose to share Your User contributions. Therefore, We cannot and do not guarantee that Your User inputs will not be viewed by unauthorized persons. We may display this information on the Portal, share it with businesses, and further distribute it to a wider audience through third party sites and services. You should be careful about revealing any sensitive details about Yourself in such posting<br /><br />

                    &nbsp; &nbsp; &nbsp;By Using the Portal, You consent to the collection, storage, and Use of the User Information that You provide for any of the services that We offer, and You consent to Our collection of any changes or updates that You may provide to the User Information. We collect only such User Information that We believe to be relevant and necessary for providing the services that You may require or request. We shall not be liable, for any reason whatsoever, for the authenticity of any User information provided by You to Us. You hereby represent, warrant and confirm that the User Information provided by You is and shall continue to be valid, true and accurate.<br /><br />

                    &nbsp; &nbsp; &nbsp;All information gathered by the Company shall be stored on servers, log files and any other storage system owned by the Company or by third parties in India, and such storage will be need-based and to the extent required to render Services. User Information in servers located in India. We do not store any biometric data.<br /><br />

                    <b>3. HOW INFORMATION IS COLLECTED</b><br /><br />

                    We may collect User Information in the following ways:<br /><br />

                    &nbsp; &nbsp; &nbsp; a. directly or indirectly from You when You provide it to Us; and/or

                    &nbsp; &nbsp; &nbsp; b. as You navigate through Our Portal;<br /><br />

                    &nbsp; &nbsp; &nbsp; c. the public domain; and/or<br /><br />

                    &nbsp; &nbsp; &nbsp; d. through independent third-party sources like Our merchants, financial organisations, credit bureaus etc.<br /><br />

                    &nbsp; &nbsp; &nbsp; We collect User Information from You when You share Your Personal and/or Sensitive Personal Information with Us, when You apply for Our products or services directly with Us or through Our third-party partners as per their applicable privacy policies, when You register with Us, when You provide Your information to Us, when You enter a competition or marketing survey, when You give Us information to collect information through cookies, from publicly available sources in accordance with applicable laws, or when You access, Use and/or browse Our Portal. We may also obtain User Information from independent third parties, as governed by their respective privacy policies, including Our business partners, merchants, marketplaces, re-sellers, technology providers, e-commerce platforms, fraud prevention agencies, independent service providers and Our group entities, affiliates, associated financiers etc. We may further obtain User Information from social platforms and networks when You give Us permission to do so either directly or through a cookie, and We may Use, collect, process or disclose this information in accordance with this Policy.<br /><br />



                    <b>4. PAYMENT CARD INFORMATION </b><br /><br />

                    &nbsp; &nbsp; &nbsp;To avail certain services from Us / Our merchants/ Our service providers through Our Portal, We may require account information. Hence, by submitting Your account information through Our Portal, You expressly consent to the sharing of Your information with the sellers/ merchants, third-party payment processors, payment gateways, payment aggregators and other third-party service providers (including to vendors who provide fraud detection services to Us and other third parties).<br /><br />

                    <b>5. USE AND DISCLOSURE OF INFORMATION COLLECTED </b><br /><br />

                    <b>Purpose</b><br /><br />

                    You explicitly provide Your consent for Use of Your User Information as follows:<br /><br />

                    &nbsp; &nbsp; &nbsp; a. To verify and authenticate Your identity;<br /><br />

                    &nbsp; &nbsp; &nbsp; b. To verify, authenticate and authorize Your Use of Our products or services, including for any risk management or portfolio management purposes;<br /><br />

                    &nbsp; &nbsp; &nbsp; c. To facilitate Your Usage of the Portal;<br /><br />

                    &nbsp; &nbsp; &nbsp; d. To do internal risk assessments and analysis;<br /><br />

                    &nbsp; &nbsp; &nbsp; e. To protect Our business and to ensure compliance with the law;<br /><br />

                    &nbsp; &nbsp; &nbsp; f. To facilitate Your access to and Use of Our products and services;<br /><br />

                    &nbsp; &nbsp; &nbsp; g. To connect You with merchants/ or other third parties;<br /><br />

                    &nbsp; &nbsp; &nbsp; h. To send You surveys and marketing communications that We believe may be of interest to You including offers of any third parties;<br /><br />

                    &nbsp; &nbsp; &nbsp; i. To carry out creditworthiness checks;<br /><br />

                    &nbsp; &nbsp; &nbsp; j. To analyse, conduct internal reviews, research, surveys and understand Our Users, improve the content and features of Our Portal,<br /><br />

                    &nbsp; &nbsp; &nbsp; k. To process and complete Your transactions;<br /><br />

                    &nbsp; &nbsp; &nbsp; l. To diagnose technical problems, provide support and help You in addressing troubleshooting problems;<br /><br />

                    &nbsp; &nbsp; &nbsp; m. To send and receive communications from You, show You advertisements and/or notifications;<br /><br />

                    &nbsp; &nbsp; &nbsp; n. To prepare reports, review, etc.;<br /><br />

                    &nbsp; &nbsp; &nbsp; o. To contact You regarding third party services and offers (including offers of any third parties), to understand Your preferences and requirements;<br /><br />

                    &nbsp; &nbsp; &nbsp; p. To permit You to participate in interactive features offered through Our Portal;<br /><br />

                    &nbsp; &nbsp; &nbsp; q. To improve the content and protecting the integrity of the Portal; and<br /><br />

                    &nbsp; &nbsp; &nbsp; r. To otherwise manage Our relationship with You.<br /><br />

                    &nbsp; &nbsp; &nbsp; You agree and acknowledge that collection of Your User Information is necessary for the purposes above and the collection of Your User Information is in furtherance of a lawful purpose.<br /><br />

                    <b>Disclosures</b><br /><br />

                    &nbsp; &nbsp; &nbsp; You explicitly confirm and provide Your consent for disclosure of Your User Information as follows:<br /><br />

                    &nbsp; &nbsp; &nbsp; s. To comply with applicable laws or when required by law enforcement, government officials, fraud detection agencies, financial institutions or other third parties and when We are compelled to do so under applicable laws (such as via a subpoena, court order, regulatory query or similar procedure);<br /><br />

                    &nbsp; &nbsp; &nbsp; t. To Our affiliates, subsidiaries, group companies, related parties and other assigns to allow them to provide support services and technical services to Us and to receive some of these services from them and to provide support services and technical services to them; and to offer their products and services to You including offering combined products and services;<br /><br />

                    &nbsp; &nbsp; &nbsp; u. To Our partners, merchants, contractors, independent service providers, associated entities, and other third-parties and persons who are bound by contractual obligations to keep User Information confidential and Use it only for the purposes for which We disclose it to them which are generally for jointly creating and offer products and services or to assist Us with Our business operations;<br /><br />

                    &nbsp; &nbsp; &nbsp; v. To other third parties and institutions and merchants in accordance with Our service agreements (also referred to as Our terms and conditions or contracts) whether directly or through a third party such as a referral partner;<br /><br />

                    &nbsp; &nbsp; &nbsp; w. To companies that We plan to merge with or entities that We may acquire or be acquired by subject to the provisions of applicable laws, in which situation We will require that the new combined entity or the acquired/ acquiring entity follows this Policy with respect to Your User Information;<br /><br />

                    &nbsp; &nbsp; &nbsp; x. Any person for maintaining security, operating platforms, to enquire or protect from any fraud, hacking;<br /><br />

                    &nbsp; &nbsp; &nbsp; y. To any third-parties for the purpose of enabling such entities to market and offer their products and services to You. We may also share Your User Information with such third parties to assist them in understanding Your interests, habits, and Usage patterns for certain programs, content, services, advertisements, promotions and such third parties may market and offer their products and services to You based on this.;<br /><br />

                    &nbsp; &nbsp; &nbsp; z. For any other purpose in connection with the above;<br /><br />

                    &nbsp; &nbsp; &nbsp; aa. For enforcing or applying Our Terms and Conditions and other agreements, including for billing and collection purposes;<br /><br />

                    &nbsp; &nbsp; &nbsp; bb. To international entities We partner with for the offer and/or development of products and services subject to the requirements under this Policy and applicable laws. We ensure that every third party that is involved in the processing of Your User Information under a contract with Us has the required organizational and technical protections in place as may be required in accordance with applicable laws in India;<br /><br />

                    &nbsp; &nbsp; &nbsp; cc. If the disclosure, in Our opinion, is necessary or appropriate to protect Our rights, property, or safety, customers or others. This will include exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction;<br /><br />

                    &nbsp; &nbsp; &nbsp; dd. To grow Our business/ Portal and/ or help Us serve You and other Users better; and<br /><br />

                    &nbsp; &nbsp; &nbsp; ee. Other than as prescribed under this Policy, We will not disclose any User Information to any external organization unless (i) We have obtained the consent of the User; or (ii) We are required by law to provide such information.<br /><br />

                    &nbsp; &nbsp; &nbsp;You also specifically agree and consent to Us collecting, transferring, and sharing information (including Personal Information) related to You with third parties such as with entities registered under applicable laws, solely for providing Services to You including processing Your transaction requests for the Services.<br /><br />

                    &nbsp; &nbsp; &nbsp;We may store Your User Information only to the extent required for Us to carry out Our operations, as provided herein, and for as long as the same is required for the fulfilment of purposes for which We collected it. The retention of User Information by the Company is determined by considering compliance with legal (contractual, statutory or regulatory requirements), accounting and compliance reporting requirements. We only store Your User Information in servers located in India. We do not store any biometric data.<br /><br />

                    &nbsp; &nbsp; &nbsp;However, when We disclose Your User Information to certain third-party service providers, such third parties have their own privacy policies in respect to the information We are required to provide to them. For these third-party service providers, We recommend that you read their privacy policies to understand the manner in which Your User Information will be handled by them. In particular, remember that certain third-party service providers may be located in or have facilities that are located in a different jurisdiction. So, if You elect to proceed with a transaction that involves the services of a third-party service provider, then Your information may become subject to the laws of the jurisdiction(s) in which such service provider is or its facilities are located. Once You leave the Portal or are redirected to a third-party website or application, You are no longer governed by this Privacy Policy or the Portal's Terms and Conditions and in such circumstances the privacy policy of such other website will apply. We will not be liable for any acts or omissions of the third-party service provider.<br /><br />

                    &nbsp; &nbsp; &nbsp;Once disclosed as per the above, Your User Information will be governed by the applicable third party’s privacy policy and not by this Policy.<br /><br />

                    <b>6. UPDATING YOUR USER INFORMATION </b><br /><br />

                    &nbsp; &nbsp; &nbsp;If Your User Information changes or is amended, You must correct, update or amend the User Information stored with Us, by making the relevant change on Your account or by contacting Our customer care at: [-]<br /><br />

                    <b>7. MARKETING</b><br /><br />

                    &nbsp; &nbsp; &nbsp;In relation to the products and services provided by Us or by third parties with whom We share Your User Information as per this Policy, You may receive marketing communications from Us or such other third parties, by messages, or email or through other digital modes, on the Portal or such other platforms including social media platforms, or on such third party platforms websites and apps including social media platforms, if You have provided Us Your User Information. We may market Our own products or services including the products and services offered by Our group companies, affiliates, subsidiaries and the product and services being offered by third parties Using such communication. The provision of such marketing activities shall be in accordance with applicable laws. For the marketing activities done by Us, We execute contracts with Our merchants, service providers, banks or other third parties. You are entitled to opt out from receiving marketing communication from Us by writing to Us at [-]. When You opt-out from receiving marketing communication from Us, this will not, in any manner whatsoever, impact the marketing communication You receive from other third parties with whom We or You share Your User Information and We do not guarantee or warrant that such third-parties will desist from contacting You. You will be required to comply with the procedures set out by such-third parties to opt out for any marketing communication from them.<br /><br />

                    &nbsp; &nbsp; &nbsp;We may also Use marketing and communications to improve and customize the content of Our ads, promotions and advertising that may be of interest to You.<br /><br />

                    <b>8. ACCOUNT CLOSURE AND INFORMATION </b><br /><br />

                    &nbsp; &nbsp; &nbsp;You can close Your account through a written request addressed to the Company’s representatives (as identified on the ‘Contact Us’ page on the Portal). We will remove Your public posts from view and/or dissociate them from Your account profile. We may retain information about You only as provided below for the purposes authorized under this Policy unless prohibited by law.<br /><br />

                    <b>9. MINORS</b><br /><br />

                    &nbsp; &nbsp; &nbsp;The Portal is not intended for Users under the age of 18 (eighteen) years. We do not knowingly collect any personal information from persons under the age of 18 (eighteen) years or market to or solicit information from anyone under the age of 18 (eighteen) years. If We learn that We collected the User Information of a minor, without first receiving a verifiable parental consent, We will take reasonable steps to delete the information in a manner consistent with applicable laws.<br /><br />

                    <b>10. THIRD PARTY LINKS</b><br /><br />

                    &nbsp; &nbsp; &nbsp;The Portal may contain links to other site, which may not necessarily be under Our control. These third parties may collect, store or otherwise process Your User Information. We do not endorse or make any representations about third-party Websites or services, and We are not responsible for any form of transmission, whatsoever, received by You from any third-party platform. Our Privacy Policy does not cover the information You choose to provide to these third parties or that is collected by these third parties. Under no circumstances shall We be deemed to control or guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, cookies, messages or other materials available on such platforms. We strongly encourage You to read such third parties’ privacy policies.<br /><br />

                    &nbsp; &nbsp; &nbsp;If You decide to access any of the third-party sites linked to the Website, You do so entirely at Your own risk. Any links to any of Our third-party partners is the responsibility of the linking party, and We will not be responsible for notification of any change in name or location of any information on the Website.<br /><br />

                    <b>11. REPRESENTATIONS AND WARRANTIES</b><br /><br />

                    &nbsp; &nbsp; &nbsp;You hereby represent to Us that:<br /><br />

                    &nbsp; &nbsp; &nbsp; a. The User Information You provide to Us from time to time is and shall be authentic, correct, current and updated and You have all the rights, permissions and consents as may be required to provide such information to Us; and<br /><br />

                    &nbsp; &nbsp; &nbsp; b. Your provision of the User Information to Us, and Our consequent storage, collection, Usage, transfer, access or processing of the same shall not be in violation of any third-party agreement, laws, charter documents, judgments, orders and decrees.<br /><br />

                    <b>12. ADVERTISEMENTS </b><br /><br />

                    &nbsp; &nbsp; &nbsp; We Use third-party advertising companies/ entities to serve advertisements when You visit Our Portal. These entities may Use information (not including Your name, address, email address, or telephone number) about Your visits to the Portal and other Websites in order to provide advertisements about goods and services of interest to You.<br /><br />

                    <b>13. DATA PROTECTION</b><br /><br />

                    &nbsp; &nbsp; &nbsp;To prevent any form of unlawful interception or misuse of User Information, We Use reasonable physical, electronic, and managerial procedures to safeguard and secure the User Information collected. We Use reasonable, secure and technologically appropriate measures to protect You against loss or misuse of Your User Information including internal reviews of data collection, storage and processing practices and other reasonable security measures which are equivalent to security measures that Company Uses to protect its own confidential information.<br /><br />

                    &nbsp; &nbsp; &nbsp;We have implemented technical and organizational measures designed to secure Your personal confidential, personal sensitive and commercial information from accidental loss and unauthorized access, Use, alteration or disclosure.<br /><br />

                    &nbsp; &nbsp; &nbsp;However, no method of transmission over the internet or via mobile device, or method of electronic storage, is 100% (one hundred percent) secure. Therefore, while We strive to Use commercially acceptable means to protect Your User information, We cannot guarantee its absolute security.<br /><br />

                    &nbsp; &nbsp; &nbsp;You should not share Your User name, password, OTP, or other security information relating to Your account with anyone. If We receive instructions Using Your User name and password, We will consider that You have authorized the instructions. You agree and confirm that Your User Information may be transferred, processed and stored. You hereby confirm that You have been made aware of the security measures undertaken by Us and You expressly consent to Us storing, handling, Using Your User Information.<br /><br />

                    &nbsp; &nbsp; &nbsp;Further, You agree that You shall not engage in any activity of data scraping or data crawling on the Portal and Usage of Web crawling or Web scrapping software for accessing the Portal would be considered to be unauthorised access.<br /><br />

                    <b>14. INTELLECTUAL PROPERTY RIGHTS</b><br /><br />

                    &nbsp; &nbsp; &nbsp;All content on Our Portal, including graphics, text, icons, interfaces, audio clips, logos, images, reviews, comments and software is the property of the Company and/or its content suppliers and is protected by Indian and international copyright laws and other applicable intellectual property laws. Any Use, including the reproduction, modification, distribution, transmission, republication, display or performance, of the content on this Portal can only be made with the express written permission of the Company. All other trademarks, brands and copyrights other than those belonging to the Company, belong to their respective owners and are their property. You shall not modify the paper or digital copies of any materials You have printed off or downloaded in any way on or from the Portal, and You must not Use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text. You must not Use any part of the content on Our Portal for commercial purposes without obtaining a written licence to do so from Us or Our licensors. If You print off, copy or download any part of Our Portal in breach of the terms hereof, Your right to Use Our Portal will cease immediately and You shall, at Our option, return or destroy any copies of the materials You have made.<br /><br />

                    <b>15. APPLICATION FOR JOB</b><br /><br />

                    &nbsp; &nbsp; &nbsp;If Your information is submitted to Us through Our Portal when applying for a position with the Company, the information will be Used to consider Your application. We may retain Your information for any period of time. This information may be shared with other companies for the purpose of evaluating Your qualifications for the particular position or other available positions, as Well as with third-party service providers retained by Us to collect, maintain and analyze candidate submissions for job postings.<br /><br />

                    <b>16. AMENDMENTS TO THIS PRIVACY POLICY</b><br /><br />

                    &nbsp; &nbsp; &nbsp;We reserve the right, in our sole discretion, to change, modify, add, or delete portions of this Policy at any time without notice, and it is Your responsibility to review this Policy from time to time on the Portal in order to keep track and take note of the changes. Your Use of the Portal following any amendment of this Policy will signify and constitute Your consent to and acceptance of such revised Policy. We will not file or store a copy of this Policy for each interaction on transaction by You through the Portal. We therefore recommend that You save a copy of this Policy for future reference, and ensure that such copies can be reliably verified as being effective (i.e. published on the Portal) on a particular date.<br /><br />

                    <b>17. FORCE MAJEURE</b><br /><br />

                    &nbsp; &nbsp; &nbsp;Notwithstanding anything contained in this Policy or elsewhere, We shall not be held responsible for any loss, damage or misuse of Your User Information, or any failure to perform any of Our obligations, if such loss, damage, failure or misuse is attributable to a Force Majeure Event and in such case, Our obligations shall be suspended for so long as the Force Majeure Event continues.<br /><br />

                    &nbsp; &nbsp; &nbsp;A<b> “Force Majeure Event” </b>shall mean any event that is beyond Our reasonable control and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, pandemic (including the Covid-19 pandemic), computer hacking, technical snags, unauthorized access to computer data and storage device, technical snags and breach of security and encryption.<br /><br />

                    <b>18. GOVERNING LAW AND DISPUTE RESOLUTION</b><br /><br />

                    &nbsp; &nbsp; &nbsp;This Policy shall be governed by and construed in accordance with the laws of India. If any dispute arises between Us and You in connection with or arising out of the validity, interpretation, implementation, or alleged breach of any provision of the Policy, such dispute shall be referred to and finally resolved by arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996 for the time being in force, which rules are deemed to be incorporated by reference in this clause. The dispute shall be resolved through arbitration by 1 (one) arbitrator appointed by the Company and the seat of the arbitration shall be Bhubaneswar, India.<br /><br />

                    &nbsp; &nbsp; &nbsp;The language of the arbitration proceedings and of all written decisions and correspondence relating to the arbitration shall be English.<br /><br />

                    &nbsp; &nbsp; &nbsp;By acceptance of this Policy, You agree to be bound by the governing law and mechanism of dispute resolution above.<br /><br />

                    <b>19. DO NOT TRACK</b><br /><br />

                    &nbsp; &nbsp; &nbsp;Some web browsers have a “Do Not Track” feature. This feature lets You tell websites You visit that You do not want to have Your online activity tracked. These features are not yet uniform across browsers. Our sites are not currently set up to respond to those signals.<br /><br />

                    <b>20. BREACH NOTIFICATION</b><br /><br />

                    &nbsp; &nbsp; &nbsp;Where a User Information breach is likely to result (in Our opinion), in a risk to the rights and freedoms of individuals, it will be reported in compliance with applicable laws.<br /><br />

                    <b>21. PHISHING</b><br /><br />

                    &nbsp; &nbsp; &nbsp;“Phishing” usually occurs when Users of a website are induced by an individual/entity into divulging sensitive User Information by Using fraudulent websites and/ or e-mail addresses. In the event You provide information to a website or respond to an e-mail which does not belong to Us or is not connected with Us in any manner, You will be a victim to Phishing. We do not send e-mails requesting a User for payment information, Username or passwords. However, We may verify the User name, password etc. provided by You from time to time.<br /><br />

                    <b>22. SEVERABILITY AND EXCLUSION</b><br /><br />

                    &nbsp; &nbsp; &nbsp;We have taken every effort to ensure that this Policy adheres with the applicable laws. The invalidity or unenforceability of any part of this Policy shall not prejudice or affect the validity or enforceability of the remainder of this Policy. This Policy does not apply to any information other than the information collected by Us through the Portal. This Policy shall be inapplicable to any unsolicited information You provide Us through the Portal or through any other means. This includes, but is not limited to, information posted in any public areas of the website / app. All unsolicited information shall be deemed to be non-confidential, and We shall be free to Use and/ or disclose such unsolicited information without any limitations.<br /><br />

                    <b>23. FOREIGN JURISDICTION</b><br /><br />

                    &nbsp; &nbsp; &nbsp;The Company makes no representation that the content contained on the Portal is appropriate or to be Used or accessed outside of India. If the Users Use or access the Portal from outside India, they do so at their own risk and are responsible for compliance with the laws of such jurisdiction. The mere fact that the Portal can be accessed through the internet by You in a country other than India will not be interpreted to imply that the laws of the said country govern the terms of this Policy and/or Use of the Portal.<br /><br />

                    <b>24. NO WAIVER</b><br /><br />

                    &nbsp; &nbsp; &nbsp;The rights and remedies available under this Policy may be exercised as often as necessary and are cumulative and not exclusive of rights or remedies provided by law. It may be waived only in writing. Delay in exercising or non-exercise of any such right or remedy does not constitute a waiver of that right or remedy, or any other right or remedy.<br /><br />

                    <b>25. GRIEVANCE OFFICER</b><br /><br />

                    &nbsp; &nbsp; &nbsp;In the event You have any queries regarding this Policy or any grievance, with respect to the User Information or Your privacy shall be addressed to :<br /><br />

                    The Grievance Redressal Officer,<br /><br />
                    &nbsp; &nbsp; &nbsp;Priyadarshi Mohapatra<br />
                    &nbsp; &nbsp; &nbsp;Address: 4th Floor, OCAC Tower, Gajapati Nagar, Bhubaneswar, Odisha<br /><br />

                    Email: governance@curebay.com<br /><br />




                    &nbsp; &nbsp; &nbsp;You may address any grievances You may have in respect of this Policy or Usage of Your User Information to him/her.<br /><br />

                    <b>26. TERMINATION</b><br /><br />

                    &nbsp; &nbsp; &nbsp;Subject to Our Terms and Conditions, We may suspend or terminate Your Account or Your Use of this Portal at any time, for any reason or for no reason. You are personally liable for any charges incurred through Your account prior to termination. We reserve the right to change, suspend, or discontinue all or any aspects of this Portal at any time without notice.<br /><br />

                    &nbsp; &nbsp; &nbsp;Please note that if You revoke any mandatory permissions or revoke the consent to process and store information such as Your Account data and/or any other information needed to facilitate Your access to the services offered by Us, then We may have to cease the provision of services to You.<br /><br />

                    <b>27. INDEMNIFICATION </b><br /><br />

                    &nbsp; &nbsp; &nbsp;We shall not be liable in case You breach any terms or conditions provided under this Policy and will not be obligated to indemnify You for any direct or indirect losses accruing to You. You agree to indemnify, defend and hold harmless Us, Our affiliates, group companies and their directors, officers, employees, agents, third party service providers, and any other third party providing any service to Us in relation to the services whether directly or indirectly, from and against any and all losses, liabilities, claims, damages, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Us that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any terms of this Policy including any representation, warranty, covenant or agreement made or obligation to be performed by You pursuant to the Policy.<br /><br />

                    <b>28. RIGHTS TO REDRESSAL</b><br /><br />

                    &nbsp; &nbsp; &nbsp;We seek to provide reasonable assistance to cater to requests from individuals regarding the processing of their User Information and the right to amend their User Information and withdraw permission to the processing of their User Information. In accordance with applicable laws and as per Our policies, more specifically provided below, You can exercise the following rights:<br /><br />

                    &nbsp; &nbsp; &nbsp; a. Review, Correct or Rectify Your User Information: You can ask Us to have inaccurate User Information We process about You, reviewed, fixed or changed in the time frame as specified in the applicable law.<br /><br />

                    &nbsp; &nbsp; &nbsp; b. Right to withdraw Your consent: You may withdraw a consent to processing that You have given Us and prevent further processing of Your User Information or restricting disclosure of Your User Information to third parties. You may also withhold Your consent prior to the collection and processing of Your User Information. In such a scenario, the Company may delete Your information (personal or otherwise) or de-identify it so that it is anonymous and not attributable to You.<br /><br />

                    &nbsp; &nbsp; &nbsp; c. Delete or forget Your User Information: You may request that the User Information collected by Us, is deleted or forgotten by Us.<br /><br />

                    &nbsp; &nbsp; &nbsp;If You would like to exercise any of the rights above in Clause (a), (b) or (c) You may request by submitting a Request Form – Data Deletion at [-]. These rights are limited in some situations, such as where We are legally or otherwise bound to process or retain Your User Information. Please note that this may also affect Our ability to process or enable Your User Information and may therefore lead to the discontinuation of those services or products for which this User Information was being Used for, at Our sole discretion.<br /><br />

                    &nbsp; &nbsp; &nbsp;Following an account deletion request/deletion of User Information request, We delete Your User Information (as specified in the Request Form), unless such User Information must be retained due to legal or regulatory requirements. If You request that We delete Your User Information or Your account, we will work hard to honor all requests in a timely manner.<br /><br />

                    &nbsp; &nbsp; &nbsp;Upon Your request, Your User Information will be deleted only from Our Portal. We do not undertake any responsibility to ensure deletion of the same by any third party to whom the User Information has been disclosed in accordance with this Policy, including but not limited to Our affiliates, associates, other third parties etc. Your User Information, to the extent disclosed to such third parties, will continue to be processed and governed by the provisions of their respective privacy policies. To clarify, exercising the rights above will only result in deletion, withdrawal of consent etc., from our Portal and not from any third parties. You will continue to be governed by the privacy policies of third parties to the extent applicable to You.<br /><br />

                    &nbsp; &nbsp; &nbsp;Please note that the withdrawal of Your consent does not in any manner affect the lawfulness of processing of User Information which was carried out based on such a consent before its withdrawal.<br /><br />



                    <b>29. DATA STORAGE</b><br /><br />

                    &nbsp; &nbsp; &nbsp;We shall not retain any of Your User Information apart from: (a) the User Information necessary for Us to carry out Our operations; or (b) there is a legal requirement to retain the same. Do note that multiple legal bases may exist in parallel, and We may still have to retain certain User Information at any time.<br /><br />

                    <b>30. DISCLAIMER </b><br /><br />

                    &nbsp; &nbsp; &nbsp;We make no warranties or representations about the accuracy or completeness of this Portal. You understand and expressly agree that to the extent permitted under applicable laws, in no event will the Company or any of its affiliates or group companies or any of their respective officers, employees, directors, shareholders, agents, or licensors be liable to You or anyone else under any theory of liability (whether in contract, tort, statutory, or otherwise) for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of revenues, profits, goodwill, Use, data or other intangible losses (even if such parties Were advised of, knew of or should have known of the possibility of such damages), resulting from: Your Use of the Portal or any parts thereof; (ii) unauthorized access to or alteration of Your transmissions or data; (iii) any other matter relating to the services; including, without limitation, damages for loss of Use, data or profits, arising out of or in any way connected with availing of the services.<br /><br />

                    &nbsp; &nbsp; &nbsp;We have endeavoured to ensure that all the information provided by Us and/or by third party service providers on the Portal is correct, We neither warrant nor makes any representations regarding the quality, accuracy or completeness of any data or information displayed on the Portal. We make no warranty, express or implied, concerning the Portal and/or its contents and disclaim all warranties of fitness for a particular purpose and warranties of merchantability in respect of information displayed and communicated through or on the Portal, including any liability, responsibility or any other claim, whatsoever, in respect of any loss, whether direct or consequential, to You or any other person, arising out of or from the Use of any such information as is displayed or communicated through or on the Portal.<br /><br />

                    &nbsp; &nbsp; &nbsp;Notwithstanding anything to the contrary herein, You hereby irrevocably waive any right or remedy to seek and/or obtain injunctive or other equitable relief or any order with respect to, and/or to enjoin or restrain or otherwise impair in any manner, the production, distribution, exhibition or other exploitation of the Company or any of its affiliate or group company related project, or the Use, publication or dissemination of any advertising in connection with such project.<br /><br />

                    &nbsp; &nbsp; &nbsp;Your sole and exclusive remedy for any dispute with Us shall be to discontinue the Use of the Portal and the services.<br /><br />
                </p>
            </div>
        </>
    );
}
export default PrivacyPolicy;
