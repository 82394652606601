import React, { useEffect, useState } from 'react';
import close from '../Assets/Images/closeee.svg';
import Dob from '../Assets/Images/calendar.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PDF from '../Assets/Images/PDF.png'
import moment from "moment";
import DatePicker from "react-datepicker";
import { encodeBase64File } from '../helper/filebase64';
import { patientaddsocialhistory } from '../Redux/Actions/UserprofileActions';
import FamilyDropdown from "./Familydropdown";
import { getsocialhistory } from '../Redux/Actions/UserprofilehealthrecordAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Addsocialhistory(props) {



    const goBack = () => {
        props.closePopup();
    }

    const history = useHistory();
    const dispatch = useDispatch();


    const location = useLocation();

    const userData = useSelector((state) => state.authReducer.userData);
    console.log("userData", userData);


    const [memberCode, setMemberCode] = useState("");
    const [FamilymemberName, setFamilymemberName] = useState("");


    const addmedicalhistorys = useSelector((state) => state.addmedicalhistory);
    const { addmedicalhistoryData } = addmedicalhistorys;

    const handleChange = (e) => {
        setaddsocialhistory({
            ...addsocialhistory,
            [e.target.name]: e.target.value,
        });
    };

    const changeDate = (e) => {
        setaddsocialhistory({
            ...addsocialhistory,
            givenDate: moment(e).format("yyyy-MM-DD HH:mm:ss"),
        });
    };

    const patientdata = localStorage.getItem("patientprofile")
    console.log("patient", patientdata)

    const handleChangeFamilymem = (e) => {
        setFamilymemberName(e)
    }

    const [addsocialhistory, setaddsocialhistory] = useState({});

    const savemedicalhistorys = (e) => {
        if (!addsocialhistory.givenDate) {
            toast("Select Date")
        } else if (!addsocialhistory.description) {
            toast("Enter Social history")
        } else {
            if (FamilymemberName == "" || FamilymemberName == undefined) {
                let patient = localStorage.getItem("patientprofile")
                const givenDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
                const data = {
                    "patientId": patient,
                    "patientCode": patient,
                    "givenDate": addsocialhistory.givenDate,
                    "status": 1,
                    "createdBy": patient,
                    "modifiedBy": patient,
                    // "recordFor": addsocialhistory.recordFor,
                    "description": addsocialhistory.description,
                    "maritalStatus": addsocialhistory.maritalStatus,
                    "familyRelation": addsocialhistory.familyRelation,
                    "friendsRelation": addsocialhistory.friendsRelation,
                    "communityRelation": addsocialhistory.communityRelation,
                    "colleagueRelation": addsocialhistory.colleagueRelation,
                    "occupation": addsocialhistory.occupation
                    //-- -> P means patient and U means User
                }
                dispatch(patientaddsocialhistory(data)).then((result) => {
                    dispatch(getsocialhistory(patient));
                    props.closePopup()
                })
            } else {
                let patient = localStorage.getItem("patientprofile")
                const givenDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
                const data = {
                    "patientId": patient,
                    "patientCode": patient,
                    "givenDate": givenDate,
                    "status": 1,
                    "createdBy": patient,
                    "modifiedBy": patient,
                    // "recordFor": addsocialhistory.recordFor,
                    "description": addsocialhistory.description,
                    //-- -> P means patient and U means User
                    "maritalStatus": addsocialhistory.maritalStatus,
                    "familyRelation": addsocialhistory.familyRelation,
                    "friendsRelation": addsocialhistory.friendsRelation,
                    "communityRelation": addsocialhistory.communityRelation,
                    "colleagueRelation": addsocialhistory.colleagueRelation
                }
                dispatch(patientaddsocialhistory(data)).then((result) => {
                    dispatch(getsocialhistory(patient));
                    props.closePopup()
                })
            }
        }
    };



    return (
        <>
            <ToastContainer />
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative xs-w-80 w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full p-5 bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="sm:pl-2 sm:pr-5  flex items-center justify-between">
                            <p className="inline sm:px-2 rounded-full text-md font-rubik font-medium cursor-pointer text-brand-secondary">
                                Add Social History
                            </p>
                            <div className="flex space-x-6 cursor-pointer">
                                <img src={close} alt="close" className="w-4" onClick={goBack} />
                            </div>
                        </div>
                        <hr className="mt-2" />
                        <div className="lg:flex justify-between pt-5">
                            <div>
                                <div className="w-full" >
                                    <div class="grid grid-cols-3 gap-5">
                                        <div class="relative">
                                            <label for="drugName" class=" text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Record On</label>
                                            <div className="flex ">
                                                <DatePicker
                                                    id="recordOn"
                                                    name="givenDate"
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dateFormat="DD/MM/yyyy"
                                                    value={addsocialhistory.givenDate ? moment(addsocialhistory.givenDate).format("DD/MM/yyyy") : ""}
                                                    onSelect={changeDate}
                                                    disabledKeyboardNavigation={true}
                                                    autoFocus={false}
                                                    placeholderText="Record On"
                                                    className={
                                                        "peer w-32 lg:w-full md:w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div class="relative">
                                            <label for="description" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Social History <span className="text-red-500">*</span></label>
                                            <div className="flex ">
                                                <input autocomplete="off" id="description" type="text" class="peer w-32 lg:w-full md:w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Social History"
                                                    name="description"
                                                    onChange={handleChange}
                                                    value={addsocialhistory.description} />
                                            </div>
                                        </div>
                                        <div class="relative">
                                            <label for="occupation" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Occupation <span className="text-red-500">*</span></label>
                                            <div className="flex ">
                                                <input autocomplete="off" id="occupation" type="text" class="peer w-32 lg:w-full md:w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Occupation"
                                                    name="occupation"
                                                    onChange={handleChange}
                                                    value={addsocialhistory.occupation} />
                                            </div>
                                        </div>
                                        <div class="relative  border-b-2 border-gray-300">
                                            <label for="maritalStatus" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Marital status</label>
                                            <div className="flex ">
                                                <select
                                                    id="maritalStatus" name="maritalStatus"
                                                    className="peer w-32 lg:w-full md:w-40  h-10  text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    onChange={handleChange}
                                                    value={addsocialhistory.maritalStatus}
                                                >
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={""}>Select Marital Status</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Single"}>Single</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Married"}>Married</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Separated"}>Separated</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Divorced"}>Divorced</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Widowed"}>Widowed</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="relative  border-b-2 border-gray-300">
                                            <label for="familyRelation" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Social relations with family</label>
                                            <div className="flex ">
                                                <select
                                                    id="familyRelation" name="familyRelation"
                                                    className="peer w-32 lg:w-full md:w-40  h-10  text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    onChange={handleChange}
                                                    value={addsocialhistory.familyRelation}
                                                >
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={""}>Select Status</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Normal"}>Normal</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Stressed"}>Stressed</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="relative border-b-2 border-gray-300">
                                            <label for="friendsRelation" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Social relations with friends</label>
                                            <div className="flex ">
                                                <select
                                                    id="friendsRelation" name="friendsRelation"
                                                    className="peer w-32 lg:w-full md:w-40  h-10  text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    onChange={handleChange}
                                                    value={addsocialhistory.friendsRelation}
                                                >
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={""}>Select Status</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Normal"}>Normal</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Stressed"}>Stressed</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="relative border-b-2 border-gray-300">
                                            <label for="communityRelation" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Social relations with community</label>
                                            <div className="flex ">
                                                <select
                                                    id="communityRelation" name="communityRelation"
                                                    className="peer w-32 lg:w-full md:w-40  h-10 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    onChange={handleChange}
                                                    value={addsocialhistory.communityRelation}
                                                >
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={""}>Select Status</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Normal"}>Normal</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Stressed"}>Stressed</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="relative  border-b-2 border-gray-300">
                                            <label for="colleagueRelation" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Social relations with people at work</label>
                                            <div className="flex ">
                                                <select
                                                    id="colleagueRelation" name="colleagueRelation"
                                                    className="peer w-32 lg:w-full md:w-40  h-10  text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    onChange={handleChange}
                                                    value={addsocialhistory.colleagueRelation}
                                                >
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={""}>Select Status</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Normal"}>Normal</option>
                                                    <option
                                                        className="py-1 mx-2 text-sm text-green-600" value={"Stressed"}>Stressed</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <button
                                        onClick={savemedicalhistorys}
                                        className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                    >
                                        Save Data{" "}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/*  */}

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default Addsocialhistory;
