import { DOCSLOTS } from "../Constants/Doctorslotsconstants";
import Slotservice from "../services/Slotservice";

export const createnewdoctorslot = (data) => async (dispatch) => {
    request();
    const res = await Slotservice.createdoctorslots(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DOCSLOTS.REQUEST_CREATEDOCTSLOTS });
    };

    function success(res) {
        dispatch({ type: DOCSLOTS.SUCCESS_CREATEDOCTSLOTS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DOCSLOTS.FAILED_CREATEDOCTSLOTS,
            payload: err
        });
    };
};


export const updateDoctorSlot = (data) => async (dispatch) => {
    request();
    const res = await Slotservice.updateDoctorSlot(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DOCSLOTS.REQUEST_CREATEDOCTSLOTS });
    };

    function success(res) {
        dispatch({ type: DOCSLOTS.SUCCESS_CREATEDOCTSLOTS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DOCSLOTS.FAILED_CREATEDOCTSLOTS,
            payload: err
        });
    };
};



export const getSlotList = (data) => async (dispatch) => {
    request();
    const res = await Slotservice.getdoctorslots(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DOCSLOTS.REQUEST_CREATEDOCTSLOTS });
    };

    function success(res) {
        dispatch({ type: DOCSLOTS.SUCCESS_GETSLOTSLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DOCSLOTS.FAILED_GETSLOTSLIST,
            payload: err
        });
    };
};
