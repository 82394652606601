import profile from '../Assets/Images/profilee.svg';
import camera from '../Assets/Images/camera.svg';
import React, { Component, useState, useEffect } from 'react'
import { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { getparticulardoctordetails } from '../Redux/Actions/doctAction';
import { editProfileDetails } from '../Redux/Actions/doctAction';
import { sendNotification } from '../Redux/Actions/doctAction';
import ProfileSidebar from './profileSidebar';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import { Link } from 'react-router-dom';
import { encodeBase64File } from "../helper/filebase64";
import DatePicker from "react-datepicker";
import ProfileNamebar from './profileNamebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userprofileservice from '../Redux/services/userprofileservice';
import AddMapHospital from './AddMapHospital';
import FilterMapHospital from './FilterMapHospital';
import {Dropdown} from 'react-bootstrap'
import { MenuIcon} from '@heroicons/react/outline'

const MobileMapHospital =  () => {

    const [showAddMapHospital, setShowAddMapHospital] = useState(false);
    const [showEditMapHospital, setShowEditMapHospital] = useState(false);
    const [showFilterMapHospital, setShowFilterMapHospital] = useState(false);
    const [editMapHospital, setEditMapHospital] = useState();

    const [mappedHospitalsList, setMappedHospitalsList] = useState([]);





    useEffect(() => {
        loadMappedHospitals();
    }, []);
    // useEffect(() => {
    //     if (mappedHospitalsList.length) {
    //         selectedMapping()
    //     }
    // }, [mappedHospitalsList.length])
    const loadMappedHospitals = () => {
        let userObj = JSON.parse(localStorage.getItem('userObj'));
        console.log(userObj.code);
        const payload = {
            userId: userObj.code,
            status: 1,
            stampRequired: 'Y'
        }
        console.log(payload);
        userprofileservice.getMappedHospitals(payload).then((res) => {
            if (res.data) {
                console.log(res.data);
                setMappedHospitalsList(res.data);
                console.log(JSON.stringify(res.data));
            }
        }, (err) => {
            console.log(err);
        })
    }

    const openAddMapHospital = () => {
        setShowAddMapHospital(true);
    }

    const openEditMapHospital = (value) => {
        console.log(value, "dsjbvdsjkvdjskbjd")
        var map = JSON.parse(value);
        console.log(map["hospitalId"]);

        mappedHospitalsList.forEach(element => {
            if (element.locationId == map["locationId"] && element.hospitalId == map["hospitalId"]) {
                //element.stampRequired = 'Y';
                setEditMapHospital(element);
                console.log(element)
                //setShowEditMapHospital(true);
            }
        });
        setShowEditMapHospital(true);
    }

    const openFilterMapHospital = () => {
        setShowFilterMapHospital(true);
    }

    // const selectedMapping = (e) => {
    //     console.log(e.target.value)
    //     var map = JSON.parse(e.target.value);
    //     console.log(map["hospitalId"]);

    //     mappedHospitalsList.forEach(element => {
    //         if (element.locationId == map["locationId"] && element.hospitalId == map["hospitalId"]) {
    //             //element.stampRequired = 'Y';
    //             setEditMapHospital(element);
    //             console.log(element)
    //             //setShowEditMapHospital(true);
    //         }
    //     });
    // }

    const loadFilterMapHospital = (data) => {
        console.log(JSON.stringify(data));
        setShowFilterMapHospital(false);
        let userObj = JSON.parse(localStorage.getItem('userObj'));
        let payload = {
            userId: userObj.code,
            stampRequired: 'Y'
        };
        if (data.hospitalId) {
            payload.hospitalId = data.hospitalId;
        }
        if (data.locationId) {
            payload.locationId = data.locationId;
        }
        if (!data.status) {
            payload.status = 1;
        }
        console.log(JSON.stringify(payload));
        userprofileservice.getMappedHospitals(payload).then((res) => {
            console.log(res);
            if (res.data) {
                setMappedHospitalsList(res.data);
                console.log(JSON.stringify(res.data));
            }
        }, (err) => {
            console.log(err);
        })
    }

    const updateLoad = (location, hospital) => {
        let userObj = JSON.parse(localStorage.getItem('userObj'));
        console.log(userObj.code);
        const payload = {
            userId: userObj.code,
            status: 1,
            stampRequired: 'Y',
            // hospitalId: hospital,
            // locationId: location
        }
        console.log(payload);
        userprofileservice.getMappedHospitals(payload).then((res) => {
            if (res.data) {
                res.data.forEach(element => {
                    if (element.locationId == location && element.hospitalId == hospital) {
                        setEditMapHospital(element)
                    }
                });
            }
        }, (err) => {
            console.log(err);
        })


    }

    return (
        <>


            {/* 2nd row */}
            <div className="flex lg:hidden justify-between flex-col">
                <div className = "flex justify-center md:justify-start md:mx-4 " >
                <div className = "hidden md:block lg:hidden relative top-4 mr-4" >
                <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
              <MenuIcon className="block h-6 w-6" aria-hidden="true"/>

              </Dropdown.Toggle>

              <Dropdown.Menu className = "z-10" >
                <ProfileSidebar/>
              </Dropdown.Menu>
            </Dropdown>
            </div>
            <h1 className="text-medium text-center font-medium text-2xl p-3 font-16 text-brand-secondary font-rubik">
                            Mapped Hospitals
                        </h1>
                </div>
           
                          
                        <div className="flex p-3 pt-0 justify-end">
                            <div className="theme-color menuitem cursor-pointer" onClick={openAddMapHospital}>
                                <div className="font-14"><span className="icon-new font-14 cursor-pointer">New</span></div>
                            </div>
                            {/* <div className="theme-color menuitem pl-5" onClick={openEditMapHospital}>
                                <div className="font-14"><span className="icon-view font-14">View</span></div>
                            </div> */}
                            <div className="theme-color menuitem pl-5" onClick={openFilterMapHospital}>
                                <div className="font-14"><span className="icon-filter font-14">Filter</span></div>
                            </div>
                        </div>
                {/* table start */}
                {/* <div className="w-11/12 lg:ml-10 ml-4 mt-3 border boder-gray-200 rounded-lg bg-white mr-8"> */}
                    

                    <div className="mx-4 mt-3 space-y-4 lg:space-y-4 mb-20 md:flex lg:block md:flex-wrap justify-between">
                        {mappedHospitalsList === 0 ?  <div className = "font-medium py-4 text-gray-500 flex justify-center items-center" style ={{height : "50vh"}} >No Data found</div> :
                    mappedHospitalsList.map((res, i) => (
                        <div className=" w-full border-solid border-2 rounded-2xl relative p-4 font-rubik mb-4 md:mb-0 lg:mb-4" onClick = { () => openEditMapHospital(JSON.stringify(res))} >
                            <div className="flex ">
                                <div className="text-left mr-2 w-2/4" >
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Hospital Name</span>
                                        <p className="text-sm font-montserrat text-base">{res.hospitalName ? res.hospitalName : 'NA'}</p>
                                    </div>
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Location Name</span>
                                        <p className="text-sm  font-montserrat text-base">{res.locationName}</p>
                                    </div>
                                   

                                </div>
                                <div className="text-left w-2/4" >
                                <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Date</span>
                                        <p className="text-sm  font-montserrat text-base">{moment(res.createdDate).format('DD/MM/yyyy')}</p>
                                    </div>
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Status</span>
                                        <p className="text-sm font-montserrat text-base">{res.status == 1 ? 'Active' : 'Inactive'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                {/* </div>                          */}

                </div>
            </div>
            {showAddMapHospital && (
                <AddMapHospital
                    closePopup={() => {
                        setShowAddMapHospital(false);
                        loadMappedHospitals();
                    }}
                    onClose={() => {
                        setShowAddMapHospital(false);
                    }}
                >
                </AddMapHospital>
            )}
            {showEditMapHospital && editMapHospital && (
                <AddMapHospital
                    editData={editMapHospital}
                    updateLoad={updateLoad}
                    closePopup={() => {
                        setShowEditMapHospital(false);
                        loadMappedHospitals();
                    }}
                    onClose={() => {
                        setShowEditMapHospital(false);
                        loadMappedHospitals();
                    }}
                >
                </AddMapHospital>
            )}
            {showFilterMapHospital && (
                <FilterMapHospital
                    closePopup={() => {
                        setShowFilterMapHospital(false);
                    }}
                    filterData={loadFilterMapHospital}
                    onClose={() => {
                        setShowFilterMapHospital(false);
                    }}
                >
                </FilterMapHospital>
            )}
        </>
    )
}

export default MobileMapHospital;