/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router';
import patientService from "../Redux/services/patientService";
import Abdomen from "./../Assets/Images/Abdomen.jpg";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import AddPatientProfilePEPopup from './AddPatientProfilePEPopup';
import { IMG_URL } from '../config/constant';
function PatientClinicalImage(props) {
    const history = useHistory();
    const [list, setList] = useState();

    useEffect(() => {
        patientService.getPatientClinicalImage(localStorage.getItem('videoStatusId')).then((res) => {
            if (res.data) {
                setList(res.data)
            }
        })
    }, []);

    return (
        <>
            <div class="flex">

                <div className="flex w-full">
                    <div className="-my-2 mx-4 sm:-mx-4 lg:-mx-6 w-full">
                        <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                            <div class="flex justify-between my-3">
                                <div className='flex'>
                                    <div className="lg:hidden relative  mr-4 ml-2 top-0" >
                                    </div>
                                    <p class="md:flex lg:block text-gray-700 text-base font-medium font-rubik">
                                        Clinical Image </p>
                                </div>

                                <div class="flex justify-between">
                                </div>
                            </div>
                            <div class="w-full h-112 lg:mb-2 border border-gray-200 rounded-xl mb-16 antialiased justify-between">
                                {list &&
                                    <img src={IMG_URL + list} alt="img" />

                                }
                                {!list &&
                                    <div class="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 antialiased justify-between border border-gray-200 mt-2 lg:pb-4 mb-10">
                                        <div class=" w-full align-center text-center ">
                                            <p className="text-center item-center mt-40 mb-40   ">No data available</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PatientClinicalImage;
