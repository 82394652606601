import http from "./http-common";

class UserprofileService {

  getPatientinfo(data) {
    return http.get(`patient/${data}`);
  }

  getNotification(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`notification/list/filter?${queryString}`);
  }

  editPatientinfo(id, data) {
    return http.put(`patient/${id}`, data);
  }


  getpatientReport() {
    return http.get(`LabTestsOrderReport/list/filter?docmentRequired=y`);
  }


  getParticulardoctor(data) {
    return http.get(`user/list/filter1?code=${data}&roleCode=PROV`);
  }

  getConsultationlist(doct) {
    return http.get(`PatientAppointment/list?userId=${doct}`);
  }

  getConsultationlistFilter(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`PatientAppointment/list?${queryString}`
    );
  }

  getpriscriptiondoctor(data) {
    return http.get(`Visit/list?userId=${data}&status=2`);
  }

  getdoctorreferedto(data) {
    return http.get(`RecommendContacts/list?recommendById=${data}&status=1`);
  }


  getBillings(data) {
    // return http.get(`PatientBilling/list?userId=${data}`);

    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`PatientBilling/list?${queryString}`
    );
  }

  getMappedHospitals(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `User_Hospital_Location/list?${queryString}`
    );
  }

  saveMappedHospital(data) {
    return http.post(
      `User_Hospital_Location/`, data
    );
  }

  updateMappedHospital(data, id) {
    return http.put(
      `User_Hospital_Location/` + id + `/`, data
    );
  }

  saveMappedSpeciality(data) {
    return http.post(
      `UserSpeciality/`, data
    );
  }

  updateMappedSpeciality(data, id) {
    return http.put(
      `UserSpeciality/` + id + `/`, data
    );
  }


  getMappedSpecialities(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `UserSpeciality/list?${queryString}`
    );
  }

  saveProfile(data) {
    return http.put(`user/${data.id}`, data);
  }

  sendNotification(data) {
    return http.post(`notification/`, data);
  }

  addpatientallergy(data) {
    return http.post(`PatientAllergies/`, data);
  }
  addpatientaddictions(data) {
    return http.post(`AddictionHistory/`, data);
  }
  editpatientallergy(data) {
    return http.put(`PatientAllergies/`, data)
  }

  addpatientmedication(data) {
    return http.post(`PatientMedication/`, data);
  }
  editpatientmedication(data) {
    return http.put(`PatientMedication/`, data);
  }

  addpatientmedicalhistory(data) {
    return http.post(`PastMedicalHistory/`, data);
  }

  addpatientsurgicalhistory(data) {
    return http.post(`SurgicalHistory/`, data);
  }

  addpatientsocialhistory(data) {
    return http.post(`SocialHistory/`, data);
  }
  addpatienttravelhistory(data) {
    return http.post(`TravelHistory/`, data);
  }
  addpatientsexualhistory(data) {
    return http.post(`SexualHistory/`, data);
  }
  addpatientfamilyhistory(data) {
    return http.post(`FamilyHistory/`, data);
  }
  MedicineDrugListSearch(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`DrugsInfo/list?${queryString}`);
  }
  getDoctorPayemntDetails(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`doctorBilling/getDoctorBilling?${queryString}`);
  }
  getDoctorPaymentAmountDetails(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`backOfficeAutoPayment/getDoctorPaymentDetails?${queryString}`);
  }

}

export default new UserprofileService();