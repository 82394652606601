import React, { useEffect, useState  } from 'react'
import { useSelector, connect , useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import "react-toastify/dist/ReactToastify.css";
import Draggable from 'react-draggable';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import {AddEventLocation} from '../Redux/Actions/videoCallActions'
import { BellIcon, ShoppingCartIcon, MenuIcon, UserCircleIcon , ArrowsExpandIcon } from '@heroicons/react/outline'

const  MinimizeVideoCall = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { state } = location;
    const {callurl , callType , upcomingAppointmentDetail } = useSelector(state => state.videoCall)
    console.log(callurl , callType , "dslkvnbsdjkvbjksdbvkjs")

    const handleExpandClick = () =>{
      dispatch(AddEventLocation());
      history.push({ pathname: APP_ROUTES.VIDEO_CALL, state: upcomingAppointmentDetail })
    }

  //   const saveEPrescription = async () => {
  //     //alert(JSON.stringify(patientLabTestsList))
  //     let data = visitData;
  //     if (prescriptionDoc) {
  //         data.patientDocument = prescriptionDoc;
  //         data.documentType = docType;
  //     }
  //     prescription.diagnositicNotes = visitData.diagnostic;
  //     data["prescription"] = prescription;
  //     data["patientLabTestsList"] = patientLabTestsList;
  //     console.log(patientLabTestsList);
  //     data["patientDrugPrescriptionList"] = patientDrugPrescriptionList;
  //     data["consultReason"] = state.consultationsReason;
  //     dispatch(DeleteVideoUrl())
  //     console.log("data : " + JSON.stringify(data))
  //     dispatch(AddPrescription(data))

  //     setClick(true);
  //     setIsCalldisabled(false)



  // }

    return (
      <>
        <Draggable>
            { location.pathname !== "/videoCall" && callurl ?
                <div
                className =  "pb-16 sm:pb-0 w-full flex px-4 pb-2 justify-end sticky bottom-0 text-right"
              >
                <div className="shadow-md">
                  <div className = " bg-white relative left-0 pl-2 cursor-pointer " >
                  <ArrowsExpandIcon className = "hover:text-xl" height = "20px" width = "20px" onClick = {handleExpandClick} />
                  </div>

                {
                  callType === 1 ?  <iframe  allow="camera; microphone;" style= {{width: "100%", height:"250px"}} src= {`${callurl}`} frameborder="0" ></iframe>
                  :
                  <iframe  allow="microphone;" style= {{width: "100%", height:"250px"}} src= {`${callurl}`} frameborder="0" ></iframe>
}  
</div>
           
              </div> : <div/>
            }
            </Draggable>
            </>
        // </div>
    )
}
export default MinimizeVideoCall;
