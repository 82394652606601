import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { getparticulardoctordetails } from '../Redux/Actions/doctAction';
import ProfileSidebar from './profileSidebar';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import { Link, useHistory, } from 'react-router-dom';
import moment from 'moment';

function ProfileNamebar(props) {



    const history = useHistory();
    const dispatch = useDispatch();
    const particulardoct = useSelector((state) => state.particulardoctor);
    const { particulardoctorData } = particulardoct;

    const { userData } = useSelector(state => state.authReducer);

   

    useEffect(() => {

        console.log(particulardoctorData);
        dispatch(getparticulardoctordetails("WILLARD"))
    }, []);


    return (
        <div>
            <div className="flex justify-between pl-10 p-2 mt-4">
                <div className="overflow-hidden">          
                    <div>
                        <p className="text-xl text-gray-900 font-medium font-rubik">Namaste, Dr {userData.firstName} {userData.lastName}</p>
                    </div>               
                </div>
                <div className="overflow-hidden ">
                    <div className="flex flex-wrap justify-end">
                        <p className="text-sm text-gray-900 font-medium font-rubik">{moment().format('Do MMMM, hh:mm A')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileNamebar;