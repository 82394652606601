export const APP_ROUTES = {
    DASHBOARD: '/dashboard',
    LOGIN: '/login',
    NOT_FOUND: '/not-found',
    SIDEBAR: '/sidebar',
    NOTIFICATIONS: '/notifications',
    PATIENT_DETAILS: '/patientdetails/:patid',
    PATIENT_DETAILS2: '/patient_details/:patid',
    BLOOD_PRESURE: '/bloodpressure',
    DOCTOR_BILLING: '/doctorbillinghistory',
    APPOINTMENT_ONE: '/appointmentone',
    APPOINTMENT_TWO: '/appointmenttwo',
    DOCTOR_CONSULTATION: '/doctorconsultation',
    POST_CONSULTATION: '/view_consultation',
    DOCTOR_PATIENT: '/doctorpatientrefered',
    DOCTOR_PRESCRIPTION: '/doctorprescription',
    DOCTOR_PROFILE: '/doctorprofile',
    PATIENTS_LISTING: '/patientslisting',
    DOCTOR_DETAILS: '/doctordetails',
    MEDICINE_PRESCRIPTION: '/medicineprescription',
    VIDEO_CALL: '/videoCall',
    WEEK_APPOINTMENTS: '/weekappointments',
    DOCTOR_SLOT_STAGE_ONE: '/doctorslotstageone',
    PAYMENT_HISTORY: '/paymenthistory',
    FORGOT_PASSWORD: '/forgot_Password',
    REFEREDTO: '/referedto',
    REFEREDFROM: '/referedfrom',
    REFERCLINIC: '/ReferClinic',
    REFERCLINICLIST: '/ReferClinicList',
    REFERDOCTORLIST: '/ReferDoctorList',
    REFERPATIENTLIST: "/ReferPatientList",
    REFERDOCTOR: '/ReferDoctor',
    REFERPATIENT: "/ReferPatient",
    REFERTOADD: "/ReferToAdd",
    DOWNLOAD: '/download',
    DIAGNOPRESTEP1: '/diagnoprestep1',
    DIAGNOPRESTEP2: '/diagnoprestep2',
    DIAGNOPRESTEP3: '/diagnoprestep3',
    CONSULATATION_REPORT: '/consulatationreport',
    MAP_HOSPITAL: '/maphospital',
    MAP_SPECIALITY: '/mapspeciality',
    CHANGE_PSWD: "/changepassword",
    ADDALLERGY: '/addallergy',
    ADDHEALTHRESTRICTIONS: '/addhealthrestrictions',
    ADDMEDICATIONS: '/addmedication',
    ADDMEDICALHISTORY: '/addmedicalhistory',
    ADDMEDICALCONDITION: '/addmedicalcondition',

    SEEALLALLERGY: '/seeallallergy',
    SEEALLHEALTHRESTRICTIONS: '/seeallhealthrestrictions',
    SEEALLMEDICATION: '/seeallmedication',
    SEEALLMEDICALHISTORY: '/seeallmedicalhistory',
    SEEALLMEDICALCONDITION: '/seeallmedicalcondition',
    SHARECALL: '/sharecall/:id/:type',
    PRIVACYPOLICY: '/privacypolicy',
    TERMSOFUSE: "/termsofuse",

    SEEALLADDICTION: '/profile/seeallAddiction',
    ADDADDICTION: '/addaddictions',
    LEAVEDETAILS: "/leaveDetails",
    CARESATHI: "/caresathi",

}