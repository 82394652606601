
import { USER } from "../Constants/userprofileConstants";
import userprofileservice from "../services/userprofileservice";


export const getparticulardoctordetails = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.getParticulardoctor(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_PARTICULARDOCT });
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_PARTICULARDOCT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_PARTICULARDOCT,
            payload: err
        });
    };
};


export const editProfileDetails = (data) => async (dispatch) => {
    console.log("editCheAdminDetails", data)
    request();
    const res = await userprofileservice.saveProfile(data)
    console.log("editCheAdminDetails", res)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_SAVEPROFILEDETAILS });
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_SAVEPROFILEDETAILS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_SAVEPROFILEDETAILS,
            payload: err
        });
    };
};


export const sendNotification = (data) => async (dispatch) => {
    console.log("sendNotification", data)
    request();
    const res = await userprofileservice.sendNotification(data)
    console.log("sendNotification", res)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_SAVEPROFILEDETAILS });
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_SAVEPROFILEDETAILS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_SAVEPROFILEDETAILS,
            payload: err
        });
    };
};


export const getBillingdetails = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.getBillings(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_GETBLLING });
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_GETBLLING, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_GETBLLING,
            payload: err
        });
    };
};
