import http from "./http-common";

class VitalService {


  getpatientvitallist(data) {
    return http.get(`PatientVitals/list/filter?patientCode=${data}`);
  }

  addpatientvitaldetails(data) {
    return http.post(`PatientVitals/` , data);
  }

  updateVitals(payload) {
    // return http.post(`PatientVitals/` , data);
    return http.put('PatientVitals/' + payload.id, payload)
  }  
}

export default new VitalService();