import React, { useEffect, useState } from 'react';
import icon from './../Assets/Images/tick-1@2x.png';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';


function ReferSuccessfulpopup(props) {

    const location = useLocation();

    const history = useHistory();
    const dispatch = useDispatch();
    const redirectTo = (event, location) => {
        event.preventDefault();
        history.push(location)
        props.closePopup();
    }
    const [FromL, setFrom] = useState("")
    useEffect(() => {
        if (props?.FromLocationID) {
            setFrom(props?.FromLocationID?.fromLocat);
        }
    }, []);

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full p-5 bg-white outline-none focus:outline-none">



                        <div className="flex justify-center">
                            <img class="h-10 w-10" src={icon} alt="pill" />
                        </div>
                        {FromL && FromL === "patient" ? null :
                            <h1 className="text-medium text-center align-center font-medium w-18 text-2xl p-3 font-12 text-brand-secondary font-rubik">
                                Sent Successfully
                            </h1>}
                        {FromL && FromL === "clinic" ?
                            <p className="mt-2 w-80 text-lg font-medium text-blue-900 text-center">You have successfully recommended a Hospital / clinic. We are work on your recommendation.</p>
                            : FromL && FromL === "patient" ?
                                <p className="mt-2 w-80 text-lg font-medium text-blue-900 text-center">Referral Sent Successfully.</p> :
                                FromL && FromL === "referto" ? null :
                                    <p className="mt-2 w-80 text-lg font-medium text-blue-900 text-center">You have successfully recommended a Specialist. We are work on your recommendation.</p>}
                        <div className="flex justify-center">
                            {FromL && FromL === "clinic" ?
                                <button
                                    onClick={(e) => redirectTo(e, APP_ROUTES.REFERCLINICLIST)}
                                    className="bg-brand-secondary font-rubik text-white py-3.5 px-6 font-medium rounded-xl mt-4 mb-2">Ok</button>
                                : FromL && FromL === "patient" ?
                                    <button
                                        onClick={(e) => redirectTo(e, APP_ROUTES.REFERPATIENTLIST)}
                                        className="bg-brand-secondary font-rubik text-white py-3.5 px-6 font-medium rounded-xl mt-4 mb-2">Ok</button> :
                                    FromL && FromL === "referto" ?
                                        <button
                                            onClick={(e) => redirectTo(e, APP_ROUTES.REFEREDTO)}
                                            className="bg-brand-secondary font-rubik text-white py-3.5 px-6 font-medium rounded-xl mt-4 mb-2">Ok</button> :
                                        <button
                                            onClick={(e) => redirectTo(e, APP_ROUTES.REFERDOCTORLIST)}
                                            className="bg-brand-secondary font-rubik text-white py-3.5 px-6 font-medium rounded-xl mt-4 mb-2">Ok</button>
                            }
                        </div>

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

        </>
    );
}

export default ReferSuccessfulpopup;