import React, { useEffect, useState } from "react";
import Loader from "./Loader";
function PatientprofilemyEntry() {
  const [loader, setLoading] = useState(true);
  let patient = localStorage.getItem('patientprofile');


  let loginObj = JSON.parse(localStorage.getItem("loginObj"));
  delete loginObj.menus;
  delete loginObj.userSession;
  loginObj = JSON.stringify(loginObj);

  console.log(loginObj);

  const locationid = localStorage.getItem("locationid");
  const url = process.env.REACT_APP_USER_BASEURL +  `che/MyEntry?from=react&styleType=2&menu=0&patientId=${patient}&loginObj=${loginObj}&locationid=${locationid}&access_token=${localStorage.getItem('access_token')}`;
  console.log(url);

  // const url = `http://localhost:4200/che/MyEntry?from=react&menu=0&patientId=${patient}&loginObj=${localStorage.getItem(
  //   "loginObj"
  // )}&locationid=${locationid}&access_token=${localStorage.getItem('access_token')}`;

  useEffect(() => {
    const hospitalid = localStorage.getItem("hospitalid");
    console.log("hospitalid", hospitalid);
    const locationid = localStorage.getItem("locationid");
    console.log("locationid", locationid);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {/* breadcrumbs */}
      <div>
        <div class="rounded-lg bg-white-600 w-full h-112 lg:mb-2 mb-16 antialiased justify-between">
          <iframe
            src={url}
            className="iframe_full"
            name="billing_iframe"
            title="My Entry"
          ></iframe>
        </div>
      </div>

      {( loader && <Loader></Loader> )} 
    </>
  );
}
export default PatientprofilemyEntry;
