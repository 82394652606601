import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import Slotservice from "../Redux/services/Slotservice";
import stamp from '../Assets/Images/stamp.png';
import { encodeBase64File } from "../helper/filebase64";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment, { now } from "moment";
import userprofileservice from "../Redux/services/userprofileservice";

export default function AddMapSpeciality(props) {
    const dispatch = useDispatch();

    const [specialityList, setSpecialityList] = useState([]);
    const [specialityId, setSpecialityId] = useState();
    const [symptoms, setSymptoms] = useState();

    useEffect(() => {
        loadSpecialities();
        if (props.editData) {
            console.log(JSON.stringify(props.editData))
            setSpecialityId(props.editData.specialityCode);
        }
    }, []);

    const redirectTo = (event) => {
        event.preventDefault();
        props.onClose();
    };

    const loadSpecialities = () => {
        let payload = {
            status: 1
        }

        Slotservice.getAllSpecialities(payload).then((res) => {
            console.log(JSON.stringify(res));
            if (res.data) {
                setSpecialityList(res.data);
            }
        }, (err) => {
            console.log(err);
        })
    }

    const save = (e) => {
        let userObj = JSON.parse(localStorage.getItem('userObj'));
        if (!specialityId) {
            toast("Please Select Sepciality");
            return;
        }

        let payload = {
            userId: userObj.code,
            specialityCode: specialityId,
            symptoms: symptoms,
            createdBy: userObj.code,
            modifiedBy: userObj.code,
            createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            status: 1
        }

        if (!props.editData) {
            payload.createdBy = userObj.code;
            payload.createdDate = moment().format("YYYY-MM-DD HH:mm:ss");
            console.log(JSON.stringify(payload));
            userprofileservice.saveMappedSpeciality(payload).then((res) => {
                console.log(JSON.stringify(res));
                if (res.data) {
                    if(!res.data.message) {
                        toast('Mapped successfully');
                        setTimeout(() => {
                            props.closePopup();
                        }, 2500);
                    } else {
                        console.log(res.data.message)
                        toast(res.data.message);
                    }
                } else {
                    toast(res.data);
                    props.closePopup();
                }
            }, (err) => {
                console.log(err);
            });
        } else {
            payload.id = props.editData.id;
            payload.modifiedBy = userObj.code;
            payload.modifiedDate = moment().format("YYYY-MM-DD HH:mm:ss");
            console.log(JSON.stringify(payload));
            userprofileservice.updateMappedSpeciality(payload, payload.id).then((res) => {
                if (res.data) {
                    toast('Mapped successfully');
                    props.closePopup();
                }
            }, (err) => {
                console.log(err);
            });
        }


        /*props.filterDoctors(filterlist);
        e.preventDefault();*/
    };
    const cancel = (e) => {
        props.onClose(e);
        e.preventDefault();
    };

    const handleChange = (e) => {
        console.log(e.target.value);
        specialityList.forEach(element => {
            if (element.code == e.target.value) {
                setSpecialityId(element.code);
                setSymptoms(element.symptoms);
            }
        });
    };

    const hiddenFileInput = React.useRef(null);
    const clicktype = () => {
        hiddenFileInput.current.click();
    };
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <ToastContainer />
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 antialiased justify-between border border-gray-200">
                            <div className="flex justify-between">
                                <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">
                                    Map Speciality
                                </h1>
                                {/* <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">Add New Address</h1> */}
                                <XIcon onClick={redirectTo} className="h-5 cursor-pointer" />
                            </div>
                            <hr className="mt-2"></hr>

                            <div className="flex pt-2 w-full ">
                                <div className="w-full">
                                    <div className="lg:flex justify-between py-6 lg:space-x-10">

                                        <div className="relative mb-6">
                                            <div className="flex">
                                                <select
                                                    id="speciality"
                                                    name="speciality"
                                                    value={specialityId}
                                                    onChange={handleChange}
                                                    className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600">
                                                    {
                                                        <>
                                                            <option className="py-1 text-sm text-green-600" value="" selected disabled> Select Speciality </option>
                                                            {specialityList.map((res, i) => (
                                                                <option key={i} className="py-1 text-sm text-green-600" value={res.code}>{res.speciality}</option>
                                                            ))}
                                                        </>}
                                                </select>
                                            </div>
                                            <label
                                                for="hospital"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                            >
                                                Speciality
                                            </label>
                                        </div>



                                        {/* {showinput ? <input autocomplete="off" id="email" name="email" type="text" value="hello" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" /> : null}   */}
                                    </div>

                                    {/*<div className="lg:flex justify-between py-6 lg:space-x-10">

                                        <div className="relative mb-6">
                                            <div className="flex">
                                            <input
                                                    autocomplete="off"
                                                    id="symptoms"
                                                    name="symptoms"
                                                    type="text"
                                                    className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    placeholder="Enter Symptoms"
                                                    value={symptoms}
                                                    disabled
                                                />
                                            </div>
                                            <label
                                                for="doctorLocation"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                            >
                                                Symptoms
                                            </label>
                                        </div>

                                                            </div>*/}

                                    <div className="relative ">
                                        <div className="flex"></div>
                                    </div>
                                    <div className="flex justify-end">
                                        <button
                                            type="submit"
                                            onClick={cancel}
                                            className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            onClick={save}
                                            className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}