import React, { useEffect, useState } from "react";
import close from "../Assets/Images/closeee.svg";
import sort from "../Assets/Images/akar-icons_height.svg";
import weight from "../Assets/Images/weight.svg";
import bg from "../Assets/Images/bg.svg";
import bp from "../Assets/Images/bp.svg";
import temp from "../Assets/Images/tepreturee.svg";
import oxygen from "../Assets/Images/oxyy.svg";
import add from "../Assets/Images/addd.svg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import moment from "moment";
import { addPatientvitals, updateVitals } from "../Redux/Actions/vitalsAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import http from "../../src/Redux/services/http-common";
import axios from "axios";
import { secureStorage } from "../Redux/Reducers/authReducer";
import store from "../Redux/store/store";

function Addvitalspopup(props) {
  console.log(http, "fghjfghkjl");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const addvitalsdatas = useSelector((state) => state.addvitalslist);
  const { addvitalsdata } = addvitalsdatas;

  let patient = localStorage.getItem("patientprofile");
  console.log("patient", patient);

  let [addvitals, setaddvitals] = useState({
    patientCode: patient,
    givenDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    createdBy: patient,
    updatedBy: patient,
    status: 1,
    height: "",
    weight: "",
    bloodGroup: "",
    temperature: "",
    systolic: "",
    diastolic: "",
    spo2: "",
    heartRate: "",
    pulseRate: 20,
    respirationRate: "",
    bloodGlucose: "",
    bmi: "",
    bp: 0,
    hdl: 0,
    ldl: 0,
    medication: "string",
    notes: "",
    notes1: "string",
    notes2: "string",
    totalCholesterol: 0,
    triglycerides: 0,
  });

  useEffect(() => {
    // if(props.data) {
    //     addvitals = props.data;
    // }
    setaddvitals(props.data);
  }, []);

  const handleChange = (e) => {
    setaddvitals({ ...addvitals, [e.target.name]: e.target.value });
  };

  const savevitals = (e) => {
    e.preventDefault();
    dispatch(addPatientvitals(addvitals));
    props.closePopup();
  };

  const updateVital = async (e) => {
    e.preventDefault();

    const bmi = (
      addvitals.weight /
      ((addvitals.height / 100) * (addvitals.height / 100))
    ).toFixed(2);
    const token =
      secureStorage.getItem("token") || store.getState().authReducer.token;

    if (!token) {
      alert("No token found, please log in.");
      return;
    }
    console.log(token, "khbjknj");
    const payload = {
      id: addvitals.id,
      patientCode: addvitals.patientCode,
      givenDate: addvitals.givenDate,
      createdBy: addvitals.createdBy,
      updatedBy: addvitals.updatedBy,
      status: addvitals.status,
      height: addvitals.height,
      weight: addvitals.weight,
      bloodGroup: addvitals.bloodGroup,
      temperature: addvitals.temperature,
      systolic: addvitals.systolic,
      diastolic: addvitals.diastolic,
      spo2: addvitals.spo2,
      heartRate: addvitals.heartRate,
      pulseRate: addvitals.pulseRate,
      respirationRate: addvitals.respirationRate,
      bloodGlucose: addvitals.bloodGlucose,
      bmi: bmi,
      bp: addvitals.bp,
      hdl: addvitals.hdl,
      ldl: addvitals.ldl,
      medication: addvitals.medication,
      notes: addvitals.notes,
      notes1: addvitals.notes1,
      notes2: addvitals.notes2,
      totalCholesterol: addvitals.totalCholesterol,
      triglycerides: addvitals.triglycerides,
    };
    // try {

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASEURL}PatientVitals/${addvitals.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data === 1) {
          toast("Vitals Updated successfully");
          props.closePopup();
        } else {
          toast("Something Went Wrong");
          // setModalComponentVisible(true);
        }
      })
      .catch((error) => {
        toast(error.response.data.message);
        props.closePopup();
        // alert("cfghbknjlkm vghbnj jhbjk");
        // setModalComponentText(error.response.data.message);
        // setModalComponentVisible(true);
      });
  };

  const goBack = () => {
    props.closePopup();
  };

  return (
    <>
      <ToastContainer />
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full p-5 bg-white outline-none focus:outline-none">
            {/*body*/}

            <div className="flex justify-between mb-4">
              <p className="text-medium font-medium text-2xl font-rubik   ">
                Enter Vitals
              </p>

              <img src={close} alt="close" class="w-4" onClick={goBack} />
            </div>

            {/* bloodpressure addvital starts here */}

            {props?.title === "Blood Pressure" ? (
              <div>
                {/* <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-3">
                    Type of entry
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="notes"
                        name="notes"
                        type="text"
                        value={addvitals.notes}
                        class="font-montserrat text-sm font-medium peer   h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Type of entry"
                        onChange={handleChange}
                      />
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div> */}

                <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-5">
                    SYS
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="systolic"
                        name="systolic"
                        type="text"
                        value={addvitals.systolic}
                        class="font-montserrat text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="SYS"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <label
                        for="systolic"
                        class="font-montserrat text-xs font-normal text-gray-secondary"
                      >
                        mmHg
                      </label>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
                <div class="w-52">
                  <div class="flex space-x-3 pt-6">
                    <p class="text-sm font-rubik font-medium"></p>
                  </div>
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-2">
                    DIA
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="diastolic"
                        name="diastolic"
                        type="text"
                        value={addvitals.diastolic}
                        class="font-montserrat text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="DIA"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <label
                        for="diastolic"
                        class="font-montserrat text-xs font-normal text-gray-secondary"
                      >
                        mmHg
                      </label>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>
            ) : null}
            {/* bloodpressure addvital ends here */}

            {/* Temperature addvital starts here */}

            {props?.title === "Temperature" ? (
              <div>
                {/* <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-3">
                    Type of entry
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="notes"
                        name="notes"
                        type="text"
                        value={addvitals.notes}
                        class="font-montserrat text-sm font-medium peer   h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Type of entry"
                        onChange={handleChange}
                      />
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div> */}

                <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-5">
                    Temperature
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="temperature"
                        name="temperature"
                        type="text"
                        value={addvitals.temperature}
                        class="font-montserrat text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Temperature"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <label
                        for="temperature"
                        class="font-montserrat text-xs font-normal text-gray-secondary"
                      >
                        °F
                      </label>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>
            ) : null}
            {/* Temperature addvital ends here */}

            {/* heart rate addvitals starts here */}

            {props?.title === "Heart Rate" ? (
              <div>
                {/* <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-3">
                    Type of entry
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="notes"
                        name="notes"
                        type="text"
                        value={addvitals.notes}
                        class="font-montserrat text-sm font-medium peer   h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Type of entry"
                        onChange={handleChange}
                      />
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div> */}

                <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-5">
                    Rate
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="heartRate"
                        name="heartRate"
                        type="text"
                        value={addvitals.heartRate}
                        class="font-montserrat text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="rate"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <label
                        for="email"
                        class="font-montserrat text-xs font-normal text-gray-secondary"
                      >
                        Beats/min
                      </label>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>
            ) : null}
            {/* heart rate addvital ends here */}

            {/* oxygen addvitals starts here */}

            {props?.title === "Oxygen" ? (
              <div>
                {/* <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-3">
                    Type of entry
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="notes"
                        name="notes"
                        type="text"
                        value={addvitals.notes}
                        class="font-montserrat text-sm font-medium peer   h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Type of entry"
                        onChange={handleChange}
                      />
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div> */}

                <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-5">
                    Rate
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="spo2"
                        name="spo2"
                        type="text"
                        value={addvitals.spo2}
                        class="font-montserrat text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Rate"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <label
                        for="email"
                        class="font-montserrat text-xs font-normal text-gray-secondary"
                      >
                        %
                      </label>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>
            ) : null}
            {/* oxygen addvital ends here */}

            {/* resporatoryrate addvitals starts here */}

            {props?.title === "Resporatory Rate" ? (
              <div>
                {/* <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-3">
                    Type of entry
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="notes"
                        name="notes"
                        type="text"
                        value={addvitals.notes}
                        class="font-montserrat text-sm font-medium peer   h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Type of entry"
                        onChange={handleChange}
                      />
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div> */}

                <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-5">
                    Rate
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="respirationRate"
                        name="respirationRate"
                        type="text"
                        value={addvitals.respirationRate}
                        class="font-montserrat text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Rate"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <label
                        for="email"
                        class="font-montserrat text-xs font-normal text-gray-secondary"
                      >
                        Breathes/min
                      </label>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>
            ) : null}
            {/* resporatoryrate addvital ends here */}

            {/* bloodglucose addvitals starts here */}

            {props?.title === "Blood Glucose" ? (
              <div>
                {/* <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-3">
                    Type of entry
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="notes"
                        name="notes"
                        type="text"
                        value={addvitals.notes}
                        class="font-montserrat text-sm font-medium peer   h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Type of entry"
                        onChange={handleChange}
                      />
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div> */}

                <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-5">
                    Sugar
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="bloodGlucose"
                        name="bloodGlucose"
                        type="text"
                        value={addvitals.bloodGlucose}
                        class="font-montserrat text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Sugar"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <label
                        for="email"
                        class="font-montserrat text-xs font-normal text-gray-secondary"
                      >
                        mg/dL
                      </label>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>
            ) : null}
            {/* bloodglucose addvital ends here */}

            {/* bmi addvitals starts here */}

            {props?.title === "BMI" ? (
              <div>
                {/* <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-3">
                    Type of entry
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="notes"
                        name="notes"
                        type="text"
                        value={addvitals.notes}
                        class="font-montserrat text-sm font-medium peer   h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Type of entry"
                        onChange={handleChange}
                      />
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div> */}

                <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-5">
                    Index
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="bmi"
                        name="bmi"
                        type="text"
                        value={addvitals.bmi}
                        class="font-montserrat text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Feet"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <label
                        for="email"
                        class="font-montserrat text-xs font-normal text-gray-secondary"
                      >
                        index{" "}
                      </label>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>
            ) : null}
            {/* bmi addvital ends here */}

            {/* weight addvitals starts here */}

            {props?.title === "Weight" ? (
              <div>
                {/* <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-3">
                    Type of entry
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="notes"
                        name="notes"
                        type="text"
                        value={addvitals.notes}
                        class="font-montserrat text-sm font-medium peer   h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Type of entry"
                        onChange={handleChange}
                      />
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div> */}

                <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-5">
                    Weight
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="weight"
                        name="weight"
                        type="text"
                        value={addvitals.weight}
                        class="font-montserrat text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Kg"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <label
                        for="email"
                        class="font-montserrat text-xs font-normal text-gray-secondary"
                      >
                        Kg{" "}
                      </label>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>
            ) : null}
            {/* weight addvital ends here */}

            {/* height addvitals starts here */}

            {props?.title === "Height" ? (
              <div>
                {/* <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-3">
                    Type of entry
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="notes"
                        name="notes"
                        type="text"
                        value={addvitals.notes}
                        class="font-montserrat text-sm font-medium peer   h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Type of entry"
                        onChange={handleChange}
                      />
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div> */}

                <div class="w-52">
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-5">
                    Height
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <input
                        autocomplete="off"
                        id="height"
                        name="height"
                        type="text"
                        value={addvitals.height}
                        class="font-montserrat text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Height"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <label
                        for="email"
                        class="font-montserrat text-xs font-normal text-gray-secondary"
                      >
                        cm{" "}
                      </label>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>
            ) : null}
            {/* height addvital ends here */}

            <div class="flex justify-center">
              <button
                onClick={updateVital}
                className="bg-brand-secondary w-full font-rubik text-white py-3.5 px-6 font-medium rounded-xl mb-2 mt-4"
              >
                Save Data{" "}
              </button>
            </div>

            {/*  */}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default Addvitalspopup;
