export const AUTH = {
    DOCTOR: "DOCTOR",
    PATIENT: "PATIENT",
    REQUEST_OTP: "REQUEST_OTP",
    RESPONSE_OTP: "REQUEST_OTP",
    FAILED_OTP: "REQUEST_OTP",
    REQUEST_PATIENT: "REQUEST_PATIENT",
    RESPONSE_PATIENT: "RESPONSE_PATIENT",
    FAILURE_PATIENT: "FAILURE_PATIENT",
    SETMODAL: 'SETMODAL',
    REQUEST_USERTYPE: 'REQUEST_USERTYPE',
    RESPOSNE_USERTYPE: 'RESPOSNE_USERTYPE',
    FAILURE_USERTYPE: 'FAILURE_USERTYPE',
    LOGOUT: 'LOGOUT',
    SETMODALSIGNUP: 'SETMODALSIGNUP',
    SETTOKEN: 'SETTOKEN',

    REQUEST_CHANGEPASSWORD: "REQUEST_CHANGEPASSWORD",
    SUCCESS_CHANGEPASSWORD: "SUCCESS_CHANGEPASSWORD",
    FAILED_CHANGEPASSWORD: "FAILED_CHANGEPASSWORD",
}