import { AUTH } from '../Constants/AuthConstants';
import { MSGCONSTANT } from '../Constants/msgConstant';
const CryptoJS = require("crypto-js");
const SecureStorage = require('secure-web-storage')
const SECRET_KEY = 'secureMyData';



export var secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  }
});

const initialState = {
  userData: {},
  isError: false,
  isLoading: false,
  isLoginModal: true,
  token: secureStorage.getItem('token') ? secureStorage.getItem('token') : null,
  otp: '',
  msg: '',
  errMsg: '',
  setModal: false,
  setModalSignup: false,
  userSession: secureStorage.getItem('userSession') ? secureStorage.getItem('userSession') : null,
  userData: secureStorage.getItem('userData') ? secureStorage.getItem('userData') : {},
  userId: secureStorage.getItem('userData') ? secureStorage.getItem('userData').id : null,
  userCode: secureStorage.getItem('userData') ? secureStorage.getItem('userData').code : null
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case AUTH.REQUEST_OTP:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case AUTH.RESPONSE_OTP:
      return {
        ...state,
        isLoading: false,
        msg: 'otp send ' + MSGCONSTANT.success,
        isError: false,
      };
    case AUTH.FAILED_OTP:
      return {
        ...state,
        isLoading: false,
        msg: MSGCONSTANT.error,
        errMsg: payload,
        isError: true
      };
    case AUTH.REQUEST_PATIENT:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case AUTH.RESPONSE_PATIENT:
      secureStorage.setItem('userData', payload.user);
      secureStorage.setItem('userSession', payload.userSession);
      return {
        ...state,
        isLoading: false,
        userSession: payload.userSession,
        userData: payload.user,
        userCode: payload.user.code,
        isError: false,
      };
    case AUTH.FAILURE_PATIENT:
      return {
        ...state,
        isLoading: false,
        msg: MSGCONSTANT.error,
        errMsg: payload,
        isError: true
      };
    case AUTH.RESPOSNE_USERTYPE:
      console.log("function call", payload)
      return {
        ...state,
        isLoading: false,
        msg: MSGCONSTANT.error,
        userType: secureStorage.setItem('userType', payload),
        errMsg: payload,
        isError: true
      }
    case AUTH.FAILURE_USERTYPE:
      return {
        ...state,
        isLoading: false,
        msg: MSGCONSTANT.error,
        errMsg: payload,
        isError: true
      };
    case AUTH.LOGOUT:
      secureStorage.removeItem('userData');
      secureStorage.removeItem('userSession');
      return {
        ...state,
        userSession: {},
        userData: {},
        userCode: null
      }
    default:
      return state;
  }
};



export const currentlocationReducer = (
  state = initialState, location = [],
  action
) => {
  const { type } = action;
  switch (type) {
    // case CURRENTLOCATION.REQUEST_CURRENTLOCATION:
    //   return { ...state, loading: true, isError: false };
    // case CURRENTLOCATION.SUCCESS_CURRENTLOCATION:
    //   return { ...state, loading: false, isError: false, location: action.payload };
    // case CURRENTLOCATION.FAILED_CURRENTLOCATION:
    //   return { ...state, loading: false, isError: false, error: action.payload };
    default:
      return state;
  }
};



export default authReducer;