import http from "./http-common";

class SlotService {

  createdoctorslots(data) {
    return http.post(`AppointmentTemplate/`, data);
  }

  getdoctorslots(data) {
    return http.get(`AppointmentTemplate/list?userId=${data}`);
  }

  updateDoctorSlot(data) {
    return http.put(`AppointmentTemplate/${data.id}`, data)
  }

  getAllHospitalslist(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`hospital/list/filter1?${queryString}`);
  }

  getLocationlist(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`location/list/filter?${queryString}`);
  }

  getAllSpecialities(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`Speciality/list?${queryString}`);
  }
  getDoctorList(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`user/list/filter?${queryString}`);
  }
  getPatientList(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(`patient/list?${queryString}`);
  }
  getUserLeaveDetails(data, pages, year, month) {
    return http.get(`Leave/list?userId=${data}&size=10&page=${pages}&year=${year}&month=${month}`);
  }
  onLeavePost(data) {
    return http.post(`Leave/`, data);
  }
  onLeaveCancel(id, data) {
    return http.put(`Leave/cancell/${id}`, data)
  }
  onLeaveUpdate(id, data) {
    return http.put(`Leave/update/${id}`, data)
  }
  onLeaveStatusUpdate(data) {
    return http.put(`Hold/updateAppointments/`, data)
  }
}

export default new SlotService();