import React, { useEffect, useState } from "react";
import close from "../Assets/Images/closee.svg";
import sort from "../Assets/Images/akar-icons_height.svg";
import weight from "../Assets/Images/weight.svg";
import bg from "../Assets/Images/bg.svg";
import bp from "../Assets/Images/bp.svg";
import temp from "../Assets/Images/tepreturee.svg";
import oxygen from "../Assets/Images/oxyy.svg";
import add from "../Assets/Images/add.svg";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addPatientvitals } from "../Redux/Actions/vitalsAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function AddVital(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
  };
  const [loader, setloader] = useState(false);

  let patient = localStorage.getItem("patientprofile");
  console.log("patient", patient);

  const [addvitals, setaddvitals] = useState({
    patientCode: patient,
    givenDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    createdBy: patient,
    updatedBy: patient,
    status: 1,
    height: "",
    weight: "",
    bloodGroup: "",
    temperature: "",
    systolic: "",
    diastolic: "",
    spo2: "",
    heartRate: "",
    pulseRate: 20,
    respirationRate: "",
    bloodGlucose: "",
    bmi: 0,
    bp: 0,
    hdl: 0,
    ldl: 0,
    medication: "",
    notes: "",
    notes1: "",
    notes2: "",
    totalCholesterol: 0,
    triglycerides: 0,
  });

  useEffect(() => {}, []);

  const handleChange = (e) => {
    setaddvitals({ ...addvitals, [e.target.name]: e.target.value });
    //setaddvitals({ ...addvitals, ['bmi']: '100.10' });
  };

  const savevitals = async (e) => {
    e.preventDefault();
    if (!addvitals.temperature) {
      toast("Enter Temprature");
    } else if (!addvitals.respirationRate) {
      toast("Enter Respiration Rate");
    } else if (!addvitals.systolic) {
      toast("Enter Systolic");
    } else if (!addvitals.diastolic) {
      toast("Enter Diastolic");
    } else if (!addvitals.spo2) {
      toast("Enter Pulse Ox.");
    } else if (!addvitals.heartRate) {
      toast("Enter Heart Rate");
    } else if (!addvitals.height) {
      toast("Enter Height");
    } else if (!addvitals.weight) {
      toast("Enter Weight");
    } else {
      const bmi = (
        addvitals.weight /
        ((addvitals.height / 100) * (addvitals.height / 100))
      ).toFixed(2);
      const payload = {
        patientCode: addvitals.patientCode,
        givenDate: addvitals.givenDate,
        createdBy: addvitals.createdBy,
        updatedBy: addvitals.updatedBy,
        status: addvitals.status,
        height: addvitals.height,
        weight: addvitals.weight,
        bloodGroup: addvitals.bloodGroup,
        temperature: addvitals.temperature,
        systolic: addvitals.systolic,
        diastolic: addvitals.diastolic,
        spo2: addvitals.spo2,
        heartRate: addvitals.heartRate,
        pulseRate: addvitals.pulseRate,
        respirationRate: addvitals.respirationRate,
        bloodGlucose: addvitals.bloodGlucose,
        bmi: bmi,
        bp: addvitals.bp,
        hdl: addvitals.hdl,
        ldl: addvitals.ldl,
        medication: addvitals.medication,
        notes: addvitals.notes,
        notes1: addvitals.notes1,
        notes2: addvitals.notes2,
        totalCholesterol: addvitals.totalCholesterol,
        triglycerides: addvitals.triglycerides,
      };
      console.log(payload);
      setloader(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL}PatientVitals/`,
          payload
        );

        if (response.data === 1) {
          toast("Vitals Added successfully");
          setTimeout(() => {
            setloader(false);
          }, 4000);
          props.closePopup();
        } else {
          toast("Something Went Wrong");
        }
      } catch (error) {
        setTimeout(() => {
          setloader(false);
        }, 4000);
        toast(error.response.data.message);
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
        // Handle error (e.g., show a toast message)
      }

      // dispatch(addPatientvitals(payload))

      //   .then((result) => {
      //     console.log(result, "fvfgbhb");
      //     if (result) {
      //       toast("Vitals Added successfully");

      //       setTimeout(() => {
      //         setloader(false);
      //       }, 4000);
      //       props.closePopup();
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error, "fvfgbhb");
      //     alert("IIInternal Server Error: Please try again later.");
      //     setloader(false); // Ensure to stop the loader

      //   });

      // history.push(PATIENTPROFILE_ROUTES.ALLVITALS);
      // props.closePopup();
    }
  };

  const goBack = () => {
    props.closePopup();
  };

  return (
    <>
      <ToastContainer />
      <div className="border-0 rounded-lg shadow-lg  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="  flex justify-center py-10">
          {/*content*/}
          <div className="border w-11/12 md:w-2/5 bg-white border-0 rounded-lg shadow-lg ">
            <div className="relative p-6 flex-auto">
              <div class="flex justify-between ">
                <p class="text-medium font-medium text-base font-rubik text-brand-secondary ">
                  Add Vitals
                </p>
                <img onClick={goBack} src={close} alt="close" class="w-4 h-4" />
              </div>
              <hr class="my-2" />

              {/* 2nd row of popup */}

              <div class="flex justify-between gap-16 pt-6">
                {/* <div class="w-52">
                  <div class="flex space-x-3">
                    <img src={bg} alt="bg" class="w-4 h-4 mt-1" />
                    <p class="text-sm font-rubik font-medium">Blood Group</p>
                  </div>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      <p class="font-montserrat text-xs font-medium">A</p>
                                            <p class="font-montserrat text-xs font-normal text-gray-secondary">+</p> 
                      <input
                        autocomplete="off"
                        id="bloodGroup"
                        name="bloodGroup"
                        type="text"
                        value={addvitals.bloodGroup}
                        class="font-montserrat text-xs font-medium peer focus:outline-none focus:borer-rose-600  w-full"
                        placeholder=""
                        onChange={handleChange}
                      />
                      <p class="font-montserrat text-xs font-normal text-gray-secondary w-20 pl-16">
                        +
                      </p>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div> */}
                <div class="w-52">
                  <div class="flex space-x-3">
                    <img src={temp} alt="temp" class="w-4 h-4 mt-1" />
                    <p class="text-sm font-rubik font-medium">
                      Temperature{" "}
                      {addvitals.temperature && (
                        <span class="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                          {addvitals.temperature >= 97 &&
                          addvitals.temperature <= 99 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              (Normal)
                            </span>
                          ) : (addvitals.temperature < 97 &&
                              addvitals.temperature >= 94) ||
                            (addvitals.temperature >= 100 &&
                              addvitals.temperature <= 106) ? (
                            <span
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              (Abnormal)
                            </span>
                          ) : addvitals.temperature > 106 ||
                            addvitals.temperature < 94 ? (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              (Critical)
                            </span>
                          ) : null}
                        </span>
                      )}
                    </p>
                  </div>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      {/* <p class="font-montserrat text-xs font-medium">120</p>
                                            <p class="font-montserrat text-xs font-normal text-gray-secondary">Kg</p> */}
                      <input
                        autocomplete="off"
                        id="temperature"
                        name="temperature"
                        type="text"
                        value={addvitals.temperature}
                        class="font-montserrat text-xs font-medium peer focus:outline-none focus:borer-rose-600  w-full"
                        placeholder="Temperature"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <p class="font-montserrat text-xs font-normal text-gray-secondary w-20 pl-12">
                        °F
                      </p>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
                <div class="w-60">
                  <div class="flex space-x-3">
                    <img src={oxygen} alt="oxygen" class="w-4 h-4 mt-1" />
                    <p class="text-sm font-rubik font-medium">
                      Respiration Rate{" "}
                      {addvitals.respirationRate && (
                        <span class="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                          {addvitals.respirationRate >= 12 &&
                          addvitals.respirationRate <= 18 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              (Normal)
                            </span>
                          ) : addvitals.respirationRate >= 19 &&
                            addvitals.respirationRate <= 25 ? (
                            <span
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              (Abnormal)
                            </span>
                          ) : addvitals.respirationRate > 25 ? (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              (Critical)
                            </span>
                          ) : null}
                        </span>
                      )}
                    </p>
                  </div>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      {/* <p class="font-montserrat text-xs font-medium">98</p>
                                            <p class="font-montserrat text-xs font-normal text-gray-secondary">Breathes/min</p> */}

                      <input
                        autocomplete="off"
                        id="respirationRate"
                        name="respirationRate"
                        type="text"
                        value={addvitals.respirationRate}
                        class="font-montserrat text-xs font-medium peer focus:outline-none focus:borer-rose-600  w-full"
                        placeholder=""
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <p class="font-montserrat text-xs font-normal text-gray-secondary w-20 ">
                        breaths/min
                      </p>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>

              {/* 3rd row */}

              <div class="flex justify-between gap-16 pt-6">
                <div class="w-52">
                  <div class="flex space-x-3">
                    <img src={bp} alt="bp" class="w-4 h-4 mt-1" />
                    <p class="text-sm font-rubik font-medium">Blood Pressure</p>
                  </div>
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-3">
                    SYS{" "}
                    {addvitals.systolic && (
                      <span className="font-montserrat text-xs font-normal ml-1">
                        {addvitals.systolic <= 120 ? (
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            Normal
                          </span>
                        ) : addvitals.systolic > 120 &&
                          addvitals.systolic <= 139 ? (
                          <span style={{ color: "orange", fontWeight: "bold" }}>
                            Pre-hypertension
                          </span>
                        ) : addvitals.systolic >= 140 &&
                          addvitals.systolic <= 159 ? (
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Stage-1 Hypertension
                          </span>
                        ) : addvitals.systolic > 159 ? (
                          <span style={{ color: "purple", fontWeight: "bold" }}>
                            Stage-2 Hypertension
                          </span>
                        ) : null}
                      </span>
                    )}
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      {/* <p class="font-montserrat text-xs font-medium">132 </p>
                                            <p class="font-montserrat text-xs font-normal text-gray-secondary">MM/Hg</p> */}

                      <input
                        autocomplete="off"
                        id="systolic"
                        name="systolic"
                        type="text"
                        value={addvitals.systolic}
                        class="font-montserrat text-xs font-medium peer focus:outline-none focus:borer-rose-600  w-full"
                        placeholder="SYS"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <p class="font-montserrat text-xs font-normal text-gray-secondary w-20 pl-4">
                        mmHg
                      </p>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
                <div class="w-60">
                  <div class="flex space-x-3 pt-6">
                    {/* <img src={weight} alt="weight"/> */}
                    <p class="text-sm font-rubik font-medium"></p>
                  </div>
                  <p class="font-montserrat text-xs font-normal text-gray-secondary pt-2">
                    DIA{" "}
                    {addvitals.diastolic && (
                      <span class="font-montserrat text-xs font-normal ml-1">
                        (
                        {addvitals.diastolic <= 80 ? (
                          <span class="text-green-500 font-bold">(Normal)</span>
                        ) : addvitals.diastolic >= 81 &&
                          addvitals.diastolic <= 89 ? (
                          <span class="text-yellow-500 font-bold">
                            (Pre-hypertension)
                          </span>
                        ) : addvitals.diastolic >= 90 &&
                          addvitals.diastolic <= 99 ? (
                          <span class="text-orange-500 font-bold">
                            (Stage-1 Hypertension)
                          </span>
                        ) : addvitals.diastolic > 99 ? (
                          <span class="text-red-500 font-bold">
                            (Stage-2 Hypertension)
                          </span>
                        ) : null}
                        )
                      </span>
                    )}
                  </p>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      {/* <p class="font-montserrat text-xs font-medium">79</p>
                                            <p class="font-montserrat text-xs font-normal text-gray-secondary">MM/Hg</p> */}
                      <input
                        autocomplete="off"
                        id="diastolic"
                        name="diastolic"
                        type="text"
                        value={addvitals.diastolic}
                        class="font-montserrat text-xs font-medium peer focus:outline-none focus:borer-rose-600  w-full"
                        placeholder="DIA"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <p class="font-montserrat text-xs font-normal text-gray-secondary w-20 pl-4">
                        mmHg
                      </p>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>

              {/* 4th row */}
              <div class="flex justify-between gap-16 pt-6">
                <div class="w-52">
                  <div class="flex space-x-3">
                    <img src={oxygen} alt="oxygen" class="w-4 h-4 mt-1" />
                    <p class="text-sm font-rubik font-medium">
                      Pulse Ox.{" "}
                      {addvitals.spo2 && (
                        <span class="font-montserrat text-xs font-normal ml-1">
                          (
                          {addvitals.spo2 >= 95 && addvitals.spo2 <= 100 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Normal
                            </span>
                          ) : addvitals.spo2 >= 85 && addvitals.spo2 <= 94 ? (
                            <span
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              Abnormal
                            </span>
                          ) : addvitals.spo2 < 85 ? (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Critical
                            </span>
                          ) : null}
                          )
                        </span>
                      )}
                    </p>
                  </div>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      {/* <p class="font-montserrat text-xs font-medium">98</p> */}
                      {/* <p class="font-montserrat text-xs font-normal text-gray-secondary ">Percentage</p> */}

                      <input
                        autocomplete="off"
                        id="spo2"
                        name="spo2"
                        type="text"
                        value={addvitals.spo2}
                        class="font-montserrat text-xs font-medium peer focus:outline-none focus:borer-rose-600  w-full"
                        placeholder="SpO2"
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <p class="font-montserrat text-xs font-normal text-gray-secondary w-20 pl-2">
                        %
                      </p>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>

                <div class="w-60">
                  <div class="flex space-x-3">
                    <img src={weight} alt="weight" class="w-4 h-4 mt-1" />
                    <p class="text-sm font-rubik font-medium">
                      Heart Rate{" "}
                      {addvitals.heartRate && (
                        <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                          (
                          {addvitals.heartRate >= 60 &&
                          addvitals.heartRate <= 100 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Normal
                            </span>
                          ) : addvitals.heartRate >= 101 &&
                            addvitals.heartRate <= 119 ? (
                            <span
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              Abnormal
                            </span>
                          ) : addvitals.heartRate >= 120 ? (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Critical
                            </span>
                          ) : null}
                          )
                        </span>
                      )}
                    </p>
                  </div>
                  <div class="pt-4 ">
                    <div class="flex justify-between">
                      {/* <p class="font-montserrat text-xs font-medium">75</p>
                                            <p class="font-montserrat text-xs font-normal text-gray-secondary ">Breathes/min</p> */}
                      <input
                        autocomplete="off"
                        id="heartRate"
                        name="heartRate"
                        type="text"
                        value={addvitals.heartRate}
                        class="font-montserrat text-xs font-medium peer focus:outline-none focus:borer-rose-600  w-full"
                        placeholder=""
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <p class="font-montserrat text-xs font-normal text-gray-secondary w-20 ">
                        Beats/min
                      </p>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>
              <div class="flex justify-between mt-4 mb-2">
                <p class="text-lg font-medium text-brand-secondary">
                  Anthropometry{" "}
                </p>
              </div>
              <div class="flex justify-between gap-16 pt-4">
                <div class="w-52">
                  <div class="flex space-x-1">
                    <img src={sort} alt="sort" class="w-4 h-4 mt-1" />
                    <p class="text-sm font-rubik font-medium">Height</p>
                  </div>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      {/* <p class="font-montserrat text-xs font-medium">6 ,1 </p>
                                            <p class="font-montserrat text-xs font-normal text-gray-secondary">Ft inch</p> */}
                      <input
                        autocomplete="off"
                        id="height"
                        name="height"
                        type="text"
                        value={addvitals.height}
                        class="font-montserrat text-xs font-medium peer focus:outline-none focus:borer-rose-600  w-full"
                        placeholder=""
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <p class="font-montserrat text-xs font-normal text-gray-secondary w-20 pl-4">
                        cm
                      </p>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
                <div class="w-60">
                  <div class="flex space-x-3">
                    <img src={weight} alt="weight" class="w-4 h-4 mt-1" />
                    <p class="text-sm font-rubik font-medium">Weight</p>
                  </div>
                  <div class="pt-4">
                    <div class="flex justify-between ">
                      {/* <p class="font-montserrat text-xs font-medium">120</p>
                                            <p class="font-montserrat text-xs font-normal text-gray-secondary">Kg</p> */}
                      <input
                        autocomplete="off"
                        id="weight"
                        name="weight"
                        type="text"
                        value={addvitals.weight}
                        class="font-montserrat text-xs font-medium peer focus:outline-none focus:borer-rose-600  w-full"
                        placeholder=""
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <p class="font-montserrat text-xs font-normal text-gray-secondary w-20 pl-12">
                        Kg
                      </p>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>
              </div>

              {/* 5th row */}
              {/* <div class="flex justify-between gap-16 pt-6">
                <div class="w-52">
                  <div class="flex space-x-3">
                    <img src={oxygen} alt="oxygen" class="w-4 h-4 mt-1" />
                    <p class="text-sm font-rubik font-medium">
                      Respiration Rate
                    </p>
                  </div>
                  <div class="pt-4">
                    <div class="flex justify-between">
                      {/* <p class="font-montserrat text-xs font-medium">98</p>
                                            <p class="font-montserrat text-xs font-normal text-gray-secondary">Breathes/min</p> */}

              {/* <input *
                        autocomplete="off"
                        id="respirationRate"
                        name="respirationRate"
                        type="text"
                        value={addvitals.respirationRate}
                        class="font-montserrat text-xs font-medium peer focus:outline-none focus:borer-rose-600  w-full"
                        placeholder=""
                        onChange={handleChange}
                        maxLength="6"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <p class="font-montserrat text-xs font-normal text-gray-secondary w-20 ">
                        breaths/min
                      </p>
                    </div>
                    <hr class="bg-gray-primary" />
                  </div>
                </div>

              </div> */}

              <div class="flex justify-end">
                {/* <div class="mt-8 flex space-x-3 ">
                                    <img src={add} alt="add" class="w-5 h-5 mt-1" />
                                    <p class="font-medium text-sm text-brand-secondary">Add More Vitals </p>
                                </div> */}
                <button
                  onClick={savevitals}
                  class="bg-brand-secondary text-sm text-white py-2 px-4 rounded-lg mt-8"
                >
                  Save Data
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      {loader && (
        <div className="loader  ml-3 float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
      )}
    </>
  );
}
export default AddVital;
