
import more from '../Assets/Images/More .svg';
import temp from '../Assets/Images/temp.svg';
import oxy from '../Assets/Images/oxygenn.svg';
import heart from '../Assets/Images/heartt.svg';
import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { getPatientvitallist } from '../Redux/Actions/vitalsAction';
import hearts from '../Assets/Images/heart.svg';
import { Bar } from 'react-chartjs-2'
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

function BloodPresure(props) {


    const params = useParams()
    const dispatch = useDispatch();
    const vitalslist = useSelector((state) => state.vitalslist);
    const { vitalslistData } = vitalslist;

    const [percentage, setpercentage] = useState(1);
    const [data, setData] = useState([])

    useEffect(() => {

        console.log(vitalslistData);
        dispatch(getPatientvitallist(params.patid || props.patientcode));
    }, []);

    useEffect(() => {
        setpercentage(80);
        let date = moment().subtract('3','days').format('DD/MM');
        let arr = [];
        for (var i = 0; i < 4; i++) {
            arr.push(date)
            date = moment(date, 'DD/MM').add(1, 'days').format('DD/MM')
        }
        setData({
            labels: arr,
            datasets: [{
                label: 'Blood Pressure',
                data: [0, 0, 0, 0],
                backgroundColor: [

                    'rgba(54, 162, 235, 0.2)',

                ],
                borderColor: [

                    'rgba(54, 162, 235, 1)',

                ],
                borderWidth: 1,
            }]
        })
    }, []);

    return (
        <>


            <div className="lg:p-10 p-2 lg:grid lg:grid-rows-2 lg:grid-flow-col gap-4">

                <div className="lg:row-span-3  p-4 border border-gray-200 rounded-lg">
                    <div className="flex justify-between">
                        <div className="flex space-x-3">
                            <p className="text-base font-medium text-gray-700 font-montserrat">Blood Pressure</p>
                            {/* <p className="text-xs font-medium text-gray-600  pt-1 font-rubik">RR : 12-18 Breaths/min</p> */}
                        </div>
                    </div>

                    <div className="lg:flex lg:space-x-20 space-x-5">
                        <div className="lg:w-96">
                            <Bar data={data}

                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,

                                    scales: {
                                        yAxes: [
                                            {

                                                gridLines: {
                                                    display: false
                                                }
                                            }
                                        ],
                                        xAxes: [
                                            {
                                                gridLines: {
                                                    display: false
                                                },

                                            }
                                        ]
                                    }
                                }}
                            >
                            </Bar>
                        </div>


                        <div className="lg:block flex space-x-4">
                            <div className="flex space-x-8 lg:pt-8">
                                <h2 className="text-sm font-medium text-brand-secondary font-rubik" >SYS</h2>
                            </div>
                            <div className="flex justify-center">
                                <p className="text-2xl text-brand-secondary text-center lg:pt-10">{vitalslistData.length && vitalslistData[0].systolic ? vitalslistData[0].systolic : 0}<span className="text-base">mm/Hg</span></p>

                            </div>
                        </div>

                    </div>

                    <div className="lg:flex lg:space-x-20 space-x-5">
                        <div className="lg:w-96">
                            <Bar data={data}

                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,

                                    scales: {
                                        yAxes: [
                                            {

                                                gridLines: {
                                                    display: false
                                                }
                                            }
                                        ],
                                        xAxes: [
                                            {
                                                gridLines: {
                                                    display: false
                                                },

                                            }
                                        ]
                                    }
                                }}
                            >
                            </Bar>
                        </div>


                        <div className="lg:block flex space-x-4">
                            <div className="flex space-x-8 lg:pt-8">
                                <h2 className="text-sm font-medium text-brand-secondary font-rubik" >DYS</h2>
                            </div>

                            <div className="flex justify-center">
                                <p className="text-2xl text-brand-secondary text-center lg:pt-10">{vitalslistData.length && vitalslistData[0].diastolic ? vitalslistData[0].diastolic : 0}<span className="text-base">mm/Hg</span></p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-span-2 justify-between mt-2 p-4 border border-gray-200 rounded-lg">
                    <div className="flex justify-between">
                        <p className="text-base font-medium text-gray-700 font-montserrat">Temperature</p>
                    </div>
                    <div className="flex justify-between pt-3">
                        <p className="text-sm font-medium text-brand-secondary font-rubik">Degree Fahrenheit</p>
                        <img src={temp} alt="temp" />
                    </div>
                    <div className="flex justify-between pt-4">
                        <div className="text-center">
                            {/* <p className="text-sm font-medium font-montserrat">Low</p> */}
                            <p className="text-xl text-gray-700 font-montserrat">{vitalslistData.length && vitalslistData[0].temperature ? vitalslistData[0].temperature : 0}°F</p>
                        </div>
                        {/* <div className="text-center">
                            <p className="text-sm font-medium font-montserrat">Avg</p>
                            <p className="text-xl text-gray-700 font-montserrat">{vitalslistData.length && vitalslistData[0].temperature ? vitalslistData[0].temperature : 0}°F</p>
                        </div>
                        <div className="text-center">
                            <p className="text-sm font-medium font-montserrat">High</p>
                            <p className="text-xl text-gray-700 font-montserrat">{vitalslistData.length && vitalslistData[0].temperature ? vitalslistData[0].temperature : 0}°F</p>
                        </div> */}
                    </div>
                </div>

                <div className="lg:row-span-2 lg:col-span-1 mt-2  p-4 border border-gray-200 rounded-lg ">
                    <div className="flex justify-between">
                        <p className="text-base font-medium text-gray-700 font-montserrat">Heart Rate</p>
                    </div>
                    <div className="flex justify-between pt-3">
                        <p className="text-sm font-medium text-brand-secondary font-rubik">Beats</p>
                        <img src={heart} alt="heart" />
                    </div>
                    <p className="text-2xl text-brand-secondary text-center pt-10">{vitalslistData.length && vitalslistData[0].heartRate ? vitalslistData[0].heartRate : 0} <span className="text-base">beats/min</span></p>
                </div>


                <div className="row-span-2 col-span-1 mt-2 p-4 border border-gray-200 rounded-lg">
                    <div className="flex justify-between">
                        <p className="text-base font-medium text-gray-700 font-montserrat">Pulse Ox.</p>
                    </div>
                    <div className="flex justify-between pt-3">
                        <p className="text-sm font-medium text-brand-secondary font-rubik">SpO2</p>
                        <img src={oxy} alt="oxy" />
                    </div>
                    <p className="text-2xl text-brand-secondary text-center pt-10">{vitalslistData.length && vitalslistData[0].respirationRate ? vitalslistData[0].respirationRate : 0}%</p>
                </div>
            </div>
        </>
    );
}

export default BloodPresure;