import React, { useState, useEffect } from 'react'
import search from '../Assets/Images/doctsearch.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientdetails, getPatientReports } from '../Redux/Actions/patientAction';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getdoctorprescription } from '../Redux/Actions/UserprofileActions';
import ProfileSidebar from './profileSidebar';
import ProfileNamebar from './profileNamebar';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import { CircularProgress } from '@material-ui/core';


import moment from 'moment';
import { SearchIcon } from '@heroicons/react/outline'


function MobilePrescription() {
 

    const history = useHistory();
    const dispatch = useDispatch();
  
    const loggedInData = useSelector((state) => state.authReducer);
    const { userData } = loggedInData;
    const doctorprescriptionlist = useSelector((state) => state.doctorpriscription);
    const { doctorprescriptionData , isLoading } = doctorprescriptionlist;
    const [list, setList] = useState([]);
    useEffect(() => {
  
      console.log(doctorprescriptionData)
      dispatch(getdoctorprescription(userData.code))
    }, []);
  
    const Onsearch = (value) => {
      let val = value ? value : '';
      // @ts-ignore
      var newArr = doctorprescriptionData;
      console.log("as", newArr)
      // @ts-ignore
      let newArray = newArr.filter((data) => JSON.stringify(data).toLowerCase().indexOf(val.toLowerCase()) !== -1);
      setList(newArray)
    }
  
    useEffect(() => {
      if (doctorprescriptionData.length > 0)
        Onsearch();
    }, [doctorprescriptionData.length])
  
  
  

    return (
        <>


            <div className="lg:block hidden">
                <ProfileNamebar></ProfileNamebar>
            </div>

            {/* 2nd row */}
            <div className="justify-between ">
                <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">

                    <ProfileSidebar></ProfileSidebar>
                </div>
                {/* table start */}
                <div className="mt-3 mx-12 ml-6 border boder-gray-200 rounded-lg">
                    <div className="flex  pt-5 pl-3 pb-5 bg-white rounded-lg">
                        <div className="bg-brand-secondary rounded p-1 absolute right-14">
                            <SearchIcon className="w-5" stroke={'white'} />
                        </div>
                        <input type="text" onChange={(e) => { Onsearch(e.target.value) }} className="text-sm text-brand-secondary font-medium" placeholder="Search or Filter" />
                    </div>
                </div>
                {isLoading && list.length === 0 &&
                <div className = "flex justify-center items-center" style ={{height: "50vh"}}>
                <CircularProgress/>
            </div>}
                <div className="mx-8 mt-3 space-y-4 md:space-y-0 lg:space-y-4 mb-20 md:flex lg:block md:flex-wrap justify-between">
                    {list.map((res, i) => (
                        <div className=" w-80 border-solid border-2 rounded-2xl relative p-4 font-rubik mb-4 md:mb-0 lg:mb-4"  >
                            <div className="flex gap-16">
                                <div className="text-left" >
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Patient Name</span>
                                        <p className="text-sm font-montserrat text-base">{res.patientName ? res.patientName : 'NA'}</p>
                                    </div>
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Type</span>
                                        <p className="text-sm  font-montserrat text-base">{res.visitType == "V" ? 'Video' : 'In Person'}</p>
                                    </div>
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Date</span>
                                        <p className="text-sm  font-montserrat text-base">{moment(res.visitDate, 'yyyy-MM-DD :mm:ss').format('Do MMM, yyyy')}</p>
                                    </div>
                                 

                                </div>
                                <div className="text-left" >
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Apt. No</span>
                                        <p className="text-sm font-montserrat text-base">{res.appointmentId}</p>
                                    </div>
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Location</span>
                                        <p className="text-sm font-montserrat text-base">{res.hospitalName ? res.hospitalName : 'NA'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className = "text-center" >
                                        <button  onClick={() => history.push({ pathname: APP_ROUTES.POST_CONSULTATION, state: res.appointmentId })} className="bg-brand-secondary font-rubik text-white py-1 px-4 font-small rounded-md my-2 ">View Prescription</button>
                                    </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
export default MobilePrescription;
