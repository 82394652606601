import React from "react";
import DetailCard from "./Detailcards";
import FamilyDropdown from "./Familydropdown";
import BgStetho from "../Assets/Images/bg-stethoscope.svg";
import nurse from "../Assets/Images/nursee.svg";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import { useDispatch, useSelector } from "react-redux";
import {
  getmedicalhistory,
  getPatientallergylist,
  getPatientmedicationlist, getsurgicalhistory, getfamilyhistory, getsocialhistory, gettravelhistory, getAddictionList, getsexualhistory
} from "../Redux/Actions/UserprofilehealthrecordAction";
import { useEffect } from "react";
import userprofileservice from "../Redux/services/userprofileservice";
import HealthRecordService from "../Redux/services/HealthRecordService";
import { useState } from "react";
import { Result } from "postcss";
function PatientProfileMedicalHistory(props) {
  const dispatch = useDispatch();

  const patientallergylist = useSelector((state) => state.allallergylist);
  const { allergyData } = patientallergylist;

  const medicationlist = useSelector((state) => state.allmedicationlist);
  const { medicationData } = medicationlist;

  const medicalhitorylist = useSelector((state) => state.allmedicalhistorylist);
  const { medicalhistoryData } = medicalhitorylist;

  const surgicalhistorylist = useSelector((state) => state.allsurgicalhistorylist);
  const { surgicalhistoryDataList } = surgicalhistorylist;

  const familyhistorylist = useSelector((state) => state.allfamilyHistoryList);
  const { familyhistoryDataList } = familyhistorylist;

  const socialhistoryData = useSelector((state) => state.allsocialHistoryList);
  const { socialhistoryDataList } = socialhistoryData;
  const travelhistoryData = useSelector((state) => state.alltravelHistoryList);
  const { travelhistoryDataList } = travelhistoryData;
  const sexualhistoryData = useSelector((state) => state.allsexualHistoryList);
  const { sexualhistoryDataList } = sexualhistoryData;
  const patientDetails = useSelector((state) => state.particularpatientdetails);
  const addictionListData = useSelector((state) => state.allAddictionsList);
  const { addictionsList } = addictionListData;
  const [selectedChronicConditions, setSelectedChronicConditions] = useState([]);


  const refresh = () => {
    getMedicalHistoryFunc();
    // loadFamilyHistory();
    // loadSocialHistory();
  }

  const getMedicalHistoryFunc = () => {
    let patient = localStorage.getItem("patientprofile");
    console.log("patient", patient);
    dispatch(getPatientallergylist(patient));
    dispatch(getPatientmedicationlist(patient));
    dispatch(getmedicalhistory(patient));
    dispatch(getsurgicalhistory(patient));
    dispatch(getfamilyhistory(patient));
    dispatch(getsocialhistory(patient));
    dispatch(gettravelhistory(patient));
    dispatch(getsexualhistory(patient));
    dispatch(getAddictionList(patient));

    loadPatientChronicConditions(patient);

  };

  const loadPatientChronicConditions = (patient) => {
    HealthRecordService.getpatientchronicconditionslist(patient).then((res) => {
      if (!res.message) {
        console.log(res.data);
        let array = [];
        res.data.forEach(element => {
          if (element.status == 1) {
            array.push(element);
          }
        });
        setSelectedChronicConditions([...array]);
        console.log(selectedChronicConditions);
      }
    }, (err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    getMedicalHistoryFunc();
    // loadSocialHistory();
  }, [dispatch]);

  useEffect(() => {
    getMedicalHistoryFunc();
    // loadSocialHistory();
  }, []);

  useEffect(() => { console.log(selectedChronicConditions) }, [selectedChronicConditions])

  return (
    <>
      <div class="flex justify-between ">
        <div class="w-full lg:mx-10 lg:my-6 ">
          <FamilyDropdown
            title={"Get Medical History For"}
          // onSelect={getMedicalHistory}
          />

          <div className="lg:flex w-full  lg:space-x-8">
            <div className="w-full">
              <DetailCard
                heading={"Allergy Details"}
                data={allergyData}
                seeAll={APP_ROUTES.SEEALLALLERGY}
                location={APP_ROUTES.ADDALLERGY}
                loadData={() => { refresh() }}
              />
            </div>
            <div className="lg:w-full">
              <DetailCard
                heading={"Past Medical History"}
                location={APP_ROUTES.ADDMEDICALHISTORY}
                data={medicalhistoryData}
                seeAll={APP_ROUTES.SEEALLMEDICALHISTORY}
                loadData={() => { refresh() }}
              />
            </div>
          </div>
          <div className="lg:flex w-full  lg:space-x-8">
            <div className="w-full">
              <DetailCard
                heading={"Surgical History"}
                data={surgicalhistoryDataList}
                seeAll={APP_ROUTES.SEEALLALLERGY}
                location={APP_ROUTES.ADDALLERGY}
                loadData={() => { refresh() }}
              />
            </div>
            <div className="lg:w-full">
              <DetailCard
                heading={"Family History"}
                location={APP_ROUTES.ADDMEDICALHISTORY}
                data={familyhistoryDataList}
                seeAll={APP_ROUTES.SEEALLMEDICALHISTORY}
                loadData={() => { refresh() }}
              />
            </div>
          </div>

          <div className="lg:flex w-full  lg:space-x-8">
            <div className="w-full">
              <DetailCard
                heading={"Social History"}
                data={socialhistoryDataList}
                seeAll={APP_ROUTES.SEEALLALLERGY}
                location={APP_ROUTES.ADDALLERGY}
                loadData={() => { refresh() }}
              />
            </div>
            <div className="w-full">
              <DetailCard heading={'Medication'}
                location={APP_ROUTES.ADDMEDICATIONS}
                data={medicationData}
                seeAll={APP_ROUTES.SEEALLMEDICATION}
                loadData={() => { refresh() }} />
            </div>
          </div>


          <div className="lg:flex w-full  lg:space-x-8">
            <div className="w-full">
              <DetailCard
                heading={"Travel History"}
                data={travelhistoryDataList}
                seeAll={APP_ROUTES.SEEALLALLERGY}
                location={APP_ROUTES.ADDALLERGY}
                loadData={() => { refresh() }}
              />
            </div>

            <DetailCard
              heading={"Sexual History"}
              data={sexualhistoryDataList}
              seeAll={APP_ROUTES.SEEALLALLERGY}
              location={APP_ROUTES.ADDALLERGY}
              loadData={() => { refresh() }}
            />
          </div>

          <div className="lg:flex w-full  lg:space-x-8">
            <div className="w-full">
              <DetailCard heading={'Chronic Conditions'}
                location={APP_ROUTES.ADDMEDICATIONS}
                data={selectedChronicConditions}
                seeAll={APP_ROUTES.SEEALLMEDICATION}
                loadData={() => { refresh() }} />
            </div>
            <div className="w-full">
              <DetailCard
                // patient={activePatient}
                heading={"Addictions"}
                data={addictionsList}
                seeAll={APP_ROUTES.SEEALLADDICTION}
                location={APP_ROUTES.ADDADDICTION}
                loadData={() => { refresh() }}
              />
            </div>
          </div>
          {/* <div className="lg:flex w-full  lg:space-x-8">
                        <div className="w-full">
                            <DetailCard heading={'Allergy Details'}  data={allergyData} seeAll={APP_ROUTES.SEEALLALLERGY} location={APP_ROUTES.ADDALLERGY} />
                        </div>
                        <div className="lg:w-full">
                            <DetailCard heading={'Health Restriction'} location={APP_ROUTES.ADDHEALTHRESTRICTIONS} data={allergyData} seeAll={APP_ROUTES.SEEALLHEALTHRESTRICTIONS} />
                        </div>
                    </div>

                    <div className="lg:flex w-full  lg:space-x-8">
                        <div className="w-full">
                            <DetailCard heading={'Medication'} location={APP_ROUTES.ADDMEDICATIONS} data={medicationData} seeAll={APP_ROUTES.SEEALLMEDICATION}  />
                        </div>
                        <div className="lg:w-full">
                            <DetailCard heading={'Medical History'} location={APP_ROUTES.ADDMEDICALHISTORY} data={medicalhistoryData} seeAll={APP_ROUTES.SEEALLMEDICALHISTORY}  />
                        </div>
                    </div> */}
          {/* 
                    <div className="lg:flex w-full  lg:space-x-8">
                        <div className="w-full">
                            <DetailCard heading={'Medical Condition'} location={APP_ROUTES.ADDMEDICALCONDITION} data={MedicalconditionData}  />
                        </div>
                        <div className="lg:w-full">
                            <DetailCard heading={'Careplan'}  icon={nurse} data={CareplanData}  />
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
}

export default PatientProfileMedicalHistory;
