import React from 'react';
import vc from '../Assets/Images/videoconsult.svg';
import vijay from '../Assets/Images/vijy.svg';
import user from '../Assets/Images/userrr.svg';
import dwnld from '../Assets/Images/arrow.downloaded.svg';
import heart from '../Assets/Images/heart.svg';
import arrowalt from '../Assets/Images/arrow.alt.down.svg';


function AppointmentOne(){

  return (
    <>

    <div className="p-10 grid grid-cols-3 gap-4">  
    {/* card 1 */}
      <div className="col-span-2  w-full h-auto rounded-lg overflow-hidden w-2/4 h-52 border boder-gray-600 shadow-sm p-4 font-rubik">
        {/* <div className="flex justify-between">
          <p className="text-base text-gray-900 font-medium pb-2">Appointments(10) </p>
          <p className="text-sm text-gray-900 font-medium font-rubik">All</p><img src={arrowalt} alt="arrow.alt.down" />
          <p className="text-xs font-medium text-gray-700">Daily 24th July, 10:30am</p><img src={arrowalt} alt="arrow alt down"/>
        </div> */}
        <div className="flex justify-between">
                <div className="flex">
                    <p className="text-base text-gray-900 font-medium pb-2">Appointments (10)</p>
                </div>
                <div className="flex space-x-12 px-6">
                    <div className="flex space-x-6">
                        <p className="text-sm text-gray-900 font-medium font-rubik pt-1">All</p><img src={arrowalt} alt="arrow" className="w-5"/>
                    </div>
                    <div className="flex space-x-6">
                        <p className="text-sm text-gray-900 font-medium font-rubik pt-1">Daily 24th July, 10:30am</p><img src={arrowalt} alt="arrow" className="w-5"/>
                    </div>
                </div>
            </div>
      <div className="pb-2 border-t text-xs text-gray-700"></div>
        <div className="flex justify-between">
          <p className="text-sm text-green-500">09:00 AM - 10:30 AM</p>
          <img src={vc} alt="video call icon" className="w-6 " />       
        </div>

        <div className="flex justify-between pt-3">
            <div className="flex">
              <img src={vijay} alt="vijay" className="w-10"/>
              <div className="pl-3">
              <p className="text-sm text-gray-700 font-medium">Vijay Sharma</p>
              <p className="text-xs font-medium text-gray-700">Symptoms:<span className="text-xs pl-1 font-normal text-gray-500">Cough and cold for the past 5 days</span></p>
              </div>
            </div>

            <div>
            <p className="text-xs font-medium text-gray-700">Appt No. :<span className="text-xs pl-1 font-normal text-gray-500">5120630</span></p>
            <p className="text-xs font-medium text-gray-700">Hospital:<span className="text-xs pl-1 font-normal text-gray-500">Jupiter Hospital</span></p>
            </div>
        </div>

        <div className="flex justify-between pt-5">
          <p className="text-sm text-green-800">10:30 AM - 11:00 AM</p>
          <img src={user} alt="user" className="w-6 " />       
        </div>

        <div className="flex justify-between pt-3">
            <div className="flex">
              <img src={vijay} alt="vijay" className="w-10"/>
              <div className="pl-3">
              <p className="text-sm text-gray-700 font-medium">Kapil Desai</p>
              <p className="text-xs font-medium text-gray-700">Symptoms:<span className="text-xs pl-1 font-normal text-gray-500">Cough and cold for the past 5 days</span></p>
              </div>
            </div>

            <div>
            <p className="text-xs font-medium text-gray-700">Appt No. :<span className="text-xs pl-1 font-normal text-gray-500">5120630</span></p>
            <p className="text-xs font-medium text-gray-700">Hospital:<span className="text-xs pl-1 font-normal text-gray-500">Jupiter Hospital</span></p>
            </div>
        </div>
         <br />
         <div className="flex justify-between">
          <p className="text-sm text-green-500">11:00 AM - 11:30 AM</p>
          <img src={user} alt="user" className="w-6 " />
        </div>

        <div className="flex justify-between pt-3">
            <div className="flex">
              <img src={vijay} alt="vijay" className="w-10"/>
              <div className="pl-3">
              <p className="text-sm text-gray-700 font-medium">Vinod Mishra</p>
              <p className="text-xs font-medium text-gray-700">Symptoms:<span className="text-xs pl-1 font-normal text-gray-500">Cough and cold for the past 5 days</span></p>
              </div>
            </div>

            <div>
            <p className="text-xs font-medium text-gray-700">Appt No. :<span className="text-xs pl-1 font-normal text-gray-500">5120630</span></p>
            <p className="text-xs font-medium text-gray-700">Hospital:<span className="text-xs pl-1 font-normal text-gray-500">Jupiter Hospital</span></p>
            </div>
        </div>

        <div className="flex justify-between pt-5">
          <p className="text-sm text-green-800">11:30 AM - 12:00 AM</p>
          <img src={vc} alt="video call icon" className="w-6 " />        
        </div>

        <div className="flex justify-between pt-3">
            <div className="flex">
              <img src={vijay} alt="vijay" className="w-10"/>
              <div className="pl-3">
              <p className="text-sm text-gray-700 font-medium">Sanjeev Kulkarni</p>
              <p className="text-xs font-medium text-gray-700">Symptoms:<span className="text-xs pl-1 font-normal text-gray-500">Cough and cold for the past 5 days</span></p>
              </div>
            </div>

            <div>
            <p className="text-xs font-medium text-gray-700">Appt No. :<span className="text-xs pl-1 font-normal text-gray-500">5120630</span></p>
            <p className="text-xs font-medium text-gray-700">Hospital:<span className="text-xs pl-1 font-normal text-gray-500">Jupiter Hospital</span></p>
            </div>
        </div>
        <br />
        <div className="flex justify-between">
          <p className="text-sm text-green-500">12:00 PM - 12:30 PM</p>
          <img src={vc} alt="video call icon" className="w-6 " />       
        </div>

        <div className="flex justify-between pt-3">
            <div className="flex">
              <img src={vijay} alt="vijay" className="w-10"/>
              <div className="pl-3">
              <p className="text-sm text-gray-700 font-medium">Pramod Deshpande</p>
              <p className="text-xs font-medium text-gray-700">Symptoms:<span className="text-xs pl-1 font-normal text-gray-500">Cough and cold for the past 5 days</span></p>
              </div>
            </div>

            <div>
            <p className="text-xs font-medium text-gray-700">Appt No. :<span className="text-xs pl-1 font-normal text-gray-500">5120630</span></p>
            <p className="text-xs font-medium text-gray-700">Hospital:<span className="text-xs pl-1 font-normal text-gray-500">Jupiter Hospital</span></p>
            </div>
        </div>

        <div className="flex justify-between pt-5">
          <p className="text-sm text-green-800">02:30 PM - 03:00 PM</p>
          <img src={vc} alt="video call icon" className="w-6 " />      
        </div>

        <div className="flex justify-between pt-3">
            <div className="flex">
              <img src={vijay} alt="vijay" className="w-10"/>
              <div className="pl-3">
              <p className="text-sm text-gray-700 font-medium">Pramod Deshpande</p>
              <p className="text-xs font-medium text-gray-700">Symptoms:<span className="text-xs pl-1 font-normal text-gray-500">Cough and cold for the past 5 days</span></p>
              </div>
            </div>

            <div>
            <p className="text-xs font-medium text-gray-700">Appt No. :<span className="text-xs pl-1 font-normal text-gray-500">5120630</span></p>
            <p className="text-xs font-medium text-gray-700">Hospital:<span className="text-xs pl-1 font-normal text-gray-500">Jupiter Hospital</span></p>
            </div>
        </div>
          <br />
          


      </div>
      
    {/* card 2 */}
    <div className=" w-full h-auto rounded-lg overflow-hidden w-2/4 h-52 border boder-gray-600 shadow-sm p-4 font-rubik">
    <div className="flex">
              <img src={vijay} alt="vijay" className="w-10"/>
              <div className="pl-3">
              <p className="text-sm text-gray-700 font-medium">Vijay Sharma</p>
              <p className="text-xs font-medium text-gray-700">Appt No. :<span className="text-xs pl-1 font-normal text-gray-500">5120630</span></p>
              <img src={vc} alt="video call icon" className="w-6 " />
              </div>
            </div>

									<div className="mt-1 justify-end flex">
										<button className="border border-brand-secondary  text-sm text-brand-secondary rounded-md py-2 px-3 mr-2">View Details</button>
										<button className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3">Join Now</button>
									</div>
                  <hr className="mt-4 mb-3" />
            <p className="text-base text-gray-900 font-medium pb-2">General Information</p>
            <div className="flex justify-between">
                <p className="text-sm text-gray-400">Date of birth</p>
                <p className="text-sm text-gray-600">18/06/1995</p>
            </div>
            <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Gender</p>
                <p className="text-sm text-gray-600">Male</p>
            </div>
            <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Location</p>
                <p className="text-sm text-gray-600">Thane</p>
            </div>
            <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Preferred Language</p>
                <p className="text-sm text-gray-600">Hindi</p>
            </div>
            <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Phone Number</p>
                <p className="text-sm text-gray-600">9898989898</p>
            </div>
            <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Email ID</p>
                <p className="text-sm text-gray-600">vijay1806@gmail.com</p>
            </div>
            <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Health Condition</p>
                <p className="text-sm text-gray-600">Healthy</p>
            </div>
            <hr />
            
            <p className="text-base text-gray-900 font-medium pb-2">Selected Reports</p>
            <p className="text-blue-400 pb-2">See all</p>
            <div className="flex justify-between">
                <p className="text-sm text-gray-400">Latest Path tests</p>
                <button className="text-blue-600">View</button>
                <img src={dwnld} alt="download" />
            </div>
            <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Health Reports</p>
                <button className="text-blue-600">View</button>
                <img src={dwnld} alt="download" />
            </div>
            <div className="flex justify-between pt-3">
                <p className="text-sm text-gray-400">Patient History</p>
                <button className="text-blue-600">View</button>
                <img src={dwnld} alt="download" />
            </div>
            <hr />
            
                <div className="flex justify-between">
                    <div className="flex space-x-3">
                      <p className="text-base text-gray-900 font-medium pb-2">Blood Pressure</p>
                      <p className="text-xs font-medium text-gray-600  pt-1 font-rubik">RR : 12-18 Breaths/min</p>
                    </div>
                </div>
                <div className="flex justify-between">
                  <p className="text-blue-600">SYS</p>
                  <img src={heart} alt="heart" />
                  <p className="text-blue-600">132 mm/Hg</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-blue-600">DIA</p> 
                  <img src={heart} alt="heart" />
                  <p className="text-blue-600">79 mm/Hg</p>
                </div>
    </div>
    </div>
    </>

  );

}
export default AppointmentOne;