
import { USER } from "../Constants/userprofileConstants";
import userprofileservice from "../services/userprofileservice";

export const editPatientDetails = (id,data) => async (dispatch) => {
    request();
    const res = await userprofileservice.editPatientinfo(id,data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_USEREDIT });
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_USEREDIT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_PATIENTINFO,
            payload: err
        });
    };
};




export const getdoctorprescription = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.getpriscriptiondoctor(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_DOCTPRESCRIPTION });
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_DOCTPRESCRIPTION, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_DOCTPRESCRIPTION,
            payload: err
        });
    };
};


export const patientEditallergy = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.editpatientallergy(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_ADDALLERGY });
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_ADDALLERGY, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_ADDALLERGY,
            payload: err
        });
    };
};

export const patientaddallergy = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.addpatientallergy(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_ADDALLERGY });
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_ADDALLERGY, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_ADDALLERGY,
            payload: err
        });
    };
};
export const patientaddAddiction = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.addpatientaddictions(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: USER.REQUEST_ADDMEDICALHISTORY });
    };

    function success(res) {
        // dispatch({ type: USER.SUCCESS_ADDMEDICALHISTORY, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: USER.FAILED_ADDMEDICALHISTORY,
        //     payload: err
        // });
    };
};


export const patientaddmedication = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.addpatientmedication(data)

    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: PAT.REQUEST_PATIENTINFO });
    };

    function success(res) {
        // dispatch({ type: PAT.SUCCESS_PATIENTINFO, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: PAT.FAILED_PATIENTINFO,
        //     payload: err
        // });
    };
};

export const patientMedicationEdit = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.editpatientmedication(data)

    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: PAT.REQUEST_PATIENTINFO });
    };

    function success(res) {
        // dispatch({ type: PAT.SUCCESS_PATIENTINFO, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: PAT.FAILED_PATIENTINFO,
        //     payload: err
        // });
    };
};


export const getdoctorconsultationlist = (doct) => async (dispatch) => {
    request();
    const res = await userprofileservice.getConsultationlist(doct)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_DOCTCONSULTATIONLIST});
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_DOCTCONSULTATIONLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_DOCTCONSULTATIONLIST,
            payload: err
        });
    };
};

export const getdoctorconsultationlistWithFilter = (doct) => async (dispatch) => {
    request();
    const res = await userprofileservice.getConsultationlistFilter(doct)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_DOCTCONSULTATIONLIST});
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_DOCTCONSULTATIONLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_DOCTCONSULTATIONLIST,
            payload: err
        });
    };
};

export const getdoctorreferedtolist = (doct) => async (dispatch) => {
    request();
    const res = await userprofileservice.getdoctorreferedto(doct)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: USER.REQUEST_DOCTREFEREDTOLIST});
    };

    function success(res) {
        dispatch({ type: USER.SUCCESS_DOCTREFEREDTOLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: USER.FAILED_DOCTREFEREDTOLIST,
            payload: err
        });
    };
};

export const patientaddmedicalhistory = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.addpatientmedicalhistory(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: USER.REQUEST_ADDMEDICALHISTORY });
    };

    function success(res) {
        // dispatch({ type: USER.SUCCESS_ADDMEDICALHISTORY, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: USER.FAILED_ADDMEDICALHISTORY,
        //     payload: err
        // });
    };
};

export const patientaddsurgicalhistory = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.addpatientsurgicalhistory(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: USER.REQUEST_SURGICALHISTORY });
    };

    function success(res) {
        // dispatch({ type: USER.SUCCESS_SURGICALHISTORY, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: USER.FAILED_SURGICALHISTORY,
        //     payload: err
        // });
    };
};

export const patientaddsocialhistory = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.addpatientsocialhistory(data)
    try {
        success(res);
        console.log(res)
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: USER.REQUEST_ADDMEDICALHISTORY });
    };

    function success(res) {
        // dispatch({ type: USER.SUCCESS_ADDMEDICALHISTORY, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: USER.FAILED_ADDMEDICALHISTORY,
        //     payload: err
        // });
    };
};

export const patientaddtravelhistory = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.addpatienttravelhistory(data)
    try {
        success(res);
        console.log(res)
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: USER.REQUEST_ADDMEDICALHISTORY });
    };

    function success(res) {
        // dispatch({ type: USER.SUCCESS_ADDMEDICALHISTORY, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: USER.FAILED_ADDMEDICALHISTORY,
        //     payload: err
        // });
    };
};
export const patientaddsexualhistory = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.addpatientsexualhistory(data)
    try {
        success(res);
        console.log(res)
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: USER.REQUEST_ADDMEDICALHISTORY });
    };

    function success(res) {
        // dispatch({ type: USER.SUCCESS_ADDMEDICALHISTORY, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: USER.FAILED_ADDMEDICALHISTORY,
        //     payload: err
        // });
    };
};



export const patientaddfamilyhistory = (data) => async (dispatch) => {
    request();
    const res = await userprofileservice.addpatientfamilyhistory(data)
    try {
        success(res);
        console.log(res)
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        // dispatch({ type: USER.REQUEST_ADDMEDICALHISTORY });
    };

    function success(res) {
        // dispatch({ type: USER.SUCCESS_ADDMEDICALHISTORY, payload: res.data });
    };
    function failure(err) {
        // dispatch({
        //     type: USER.FAILED_ADDMEDICALHISTORY,
        //     payload: err
        // });
    };
};