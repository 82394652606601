import React, { useEffect, useState } from 'react'
import search from '../Assets/Images/doctsearch.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReferalToAdd } from '../Redux/Actions/RefralAction';
import ProfileSidebar from './profileSidebar';
import ProfileNamebar from './profileNamebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReferSuccessfulpopup from "./ReferSuccessfulpopup";
import Slotservice from "../Redux/services/Slotservice";
import moment from "moment";
import Autocomplete from 'react-autocomplete';
import { Dropdown } from 'react-bootstrap'
import { MenuIcon } from '@heroicons/react/outline'
import { MinusIcon, PlusIcon, SearchIcon, EyeOffIcon, EyeIcon, ArrowsExpandIcon, ExternalLinkIcon } from "@heroicons/react/outline";


function ReferToAdd(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showSucesspopup, setshowSucesspopup] = useState(false)
    const loggedInData = useSelector((state) => state.authReducer);
    const { userData } = loggedInData;
    const [hospitallist, setHospitalList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [hospitalId, setHospitalId] = useState();
    const [locationId, setLocationId] = useState();
    const [DoctorList, setDoctorList] = useState([]);
    const [PatientListdata, setPatientList] = useState([]);
    const [changeView, setView] = useState(false)
    const [DoctorCode, setDoctorCode] = useState("")
    const [fromLocation, setfromLocation] = useState({
        fromLocat: "referto"
    })

    const [val1, setVal1] = useState('');
    const [val2, setVal2] = useState('');
    const [val3, setVal3] = useState('');
    useEffect(() => {
        getHospitalsList();
        let payload = {
            status: 1,
            roleCode: "PROV"
        }
        Slotservice.getDoctorList(payload).then((res) => {
            setDoctorList(res.data)
        })
        let payloadPat = {
            status: 3,
            //isApproved: 1
        }
        Slotservice.getPatientList(payloadPat).then((res) => {
            setPatientList(res.data)
        })

    }, []);
    const getHospitalsList = () => {
        let payload = {
            type: 'H',
            status: 1
        }

        Slotservice.getAllHospitalslist(payload).then((res) => {
            if (res.data) {
                setHospitalList(res.data);
                console.log(res.data)
            }
        }, (err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        setDoctorList(DoctorList)
    }, [DoctorList.length])
    const getPatientList = () => {
        // let payload = {
        //     status: 1,
        //     roleCode: "PROV"
        // }
        // Slotservice.getDoctorList(payload).then((res) => {
        //     setDoctorList(res.data)
        // })
        // let payloadPat = {
        //     status: 1,
        // }
        // Slotservice.getPatientList(payloadPat).then((res) => {
        //     console.log("lol2", res.data)
        //     for (var i = 0; i < res.data.length; i++) {
        //         setPatientList(res.data[i])
        //     }
        // })
    }

    const getLocationsList = (hospitalId) => {
        let payload = {
            hospitalCode: hospitalId,
            status: 1
        }

        Slotservice.getLocationlist(payload).then((res) => {
            if (res.data) {
                setLocationList(res.data);
            }
        }, (err) => {
            console.log(err);
        })
    }
    const loginObj = JSON.parse(localStorage.getItem("loginObj"));
    const userDataLogin = loginObj.user;
    const [addList, setAddList] = useState({

        // "description":addlist.description

    });

    const handleChange = (e) => {
        setAddList({ ...addList, [e.target.name]: e.target.value });
        if (e.target.name == 'hospital') {
            setHospitalId(e.target.value);
            getLocationsList(e.target.value);
        }
        if (e.target.name == 'location') {
            setLocationId(e.target.value);
        }
    }
    const onSave = () => {
        if (!addList.patientName) {
            toast("Please Enter Patient name");
        }
        // else if (!hospitalId) {
        //     toast("Please Select Hospital");
        // }
        // else if (!locationId) {
        //     toast("Please select Hospital location");
        // } 
        // else if (!addList.doctorName) {
        //     toast("Please Enter Doctor name");
        // } 
        else if (!addList.description) {
            toast("Please Enter Description");
        } else {
            const data = {
                // "recommendById": userDataLogin.code,
                "qualification": "",
                "email": "",
                "address1": "",
                "address2": "",
                "createdBy": userDataLogin.code,
                "modifiedBy": userDataLogin.code,
                "description": "",
                "referType": "H",
                "status": 1,
                "referBy": userDataLogin.code,
                "hospitalId": hospitalId,
                "locationId": locationId,
                "patientId": "",
                "doctorName": addList.doctorName,
                "referTo": addList.patientName,
                "description": addList.description,
                "referDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
                "createddate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
                "modifieddate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),

            }
            dispatch(ReferalToAdd(data)).then((result) => {
                if (result === 1) {
                    setshowSucesspopup(true)
                }
            })
        }
    }
    const onSavePat = () => {
        if (!addList.doctorList) {
            toast("Please Enter Doctor name");
        }
        // else if (!hospitalId) {
        //     toast("Please Select Hospital");
        // }
        // else if (!locationId) {
        //     toast("Please select Hospital location");
        // } 
        else if (!addList.patientNamePat) {
            toast("Please Enter Patient name");
        }
        else if (!addList.descriptionPat) {
            toast("Please Enter Description");
        } else {
            const data = {
                // "recommendById": userDataLogin.code,
                "qualification": "",
                "email": "",
                "address1": "",
                "address2": "",
                "createdBy": userDataLogin.code,
                "modifiedBy": userDataLogin.code,
                "description": "",
                "referType": "S",
                "status": 1,
                "referBy": userDataLogin.code,
                "patientId": addList.patientNamePat,
                "referToName": addList.doctorList,
                "referTo": DoctorCode,
                "description": addList.descriptionPat,
                "referDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
                "createddate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
                "modifieddate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),

            }
            dispatch(ReferalToAdd(data)).then((result) => {
                if (result === 1) {
                    setshowSucesspopup(true)
                }
            })
        }
    }

    const renderUsername = (state, val) => {
        console.log(val)
        return (
            state.userName.toLowerCase().indexOf(val.toLowerCase()) !== -1
        );
    }
    const renderUsernameP = (state, val) => {
        console.log(val)
        return (
            state.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
        );
    }

    const renderUsernamePD = (state, val) => {
        console.log(val)
        return (
            state.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
        );
    }
    const onSetVal = (e, no) => {
        if (no === 1) {
            setVal1(e);
        }
        else if (no === 2) {
            setVal2(e);
        } else if (no === 3) {
            setVal3(e);
        }

    }

    const onChangeHandlerD = (e) => {
        console.log("e", e)
        setAddList({ ...addList, ['doctorList']: e.userName })
        setDoctorCode(e.code)
        //setVisitData({ ...visitData, ['primaryDiagnosis']: e.description, ['primarySymptoms']: e.termCode })
    }
    const onChangeHandlerp = (e) => {
        console.log("e", e)
        setAddList({ ...addList, ['patientName']: e.name })
        //setVisitData({ ...visitData, ['primaryDiagnosis']: e.description, ['primarySymptoms']: e.termCode })
    }

    const onChangeHandlerDP = (e) => {
        console.log("e", e)
        setAddList({ ...addList, ['patientNamePat']: e.code, ['patientNamePatNAME']: e.name })
        //setVisitData({ ...visitData, ['primaryDiagnosis']: e.description, ['primarySymptoms']: e.termCode })
    }

    return (
        <>


            <div className="lg:block hidden">
                <ProfileNamebar></ProfileNamebar>
            </div>
            <ToastContainer />
            <div className="flex justify-between ">
                <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">
                    <ProfileSidebar></ProfileSidebar>
                </div>
                <div className="w-11/12 lg:ml-10 ml-4 mt-3 border boder-gray-200 rounded-lg bg-white mr-8">
                    <div className="flex  justify-between md:justify-start lg:justify-between">
                        <div className="hidden md:block lg:hidden relative p-3 top-1" >
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />

                                </Dropdown.Toggle>

                                <Dropdown.Menu className="z-10" >
                                    <ProfileSidebar />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <h1 className="text-medium font-medium text-2xl p-3 font-16 text-brand-secondary font-rubik">
                            Refer
                        </h1>
                    </div>
                    <hr />

                    <div className="flex space-x-8 lg:pl-12 px-4">

                        <div className="flex space-x-4 items-center py-2">
                            <input type="radio" class="form-radio mt-1 mr-2" defaultChecked name="hospital" value="hospital" onChange={() => setView(false)} />
                            <div className="text-sm font-medium text-gray-500 font-montserrat">Refer to Hospital /Clinic</div>
                        </div>

                        <div className=" flex space-x-4 items-center py-2">
                            <input type="radio" class="form-radio mt-1 mr-2" name="hospital" value="hospital" onChange={() => setView(true)} />
                            <div className="text-sm font-medium text-gray-500 font-montserrat">Refer to Doctor</div>

                        </div>
                    </div>
                    {!changeView ?
                        <div className="py-8 pr-5 sm:pr-0 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                            <div className="relative pt-2 pl-5">
                                <label for="patientName" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-2 ">Patient</label>
                                {/* <div class="flex ">
                                    <input autocomplete="off" id="patientName" name="patientName" value={addList.patientName} className="peer bg-transparent h-10 w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter patient name" onChange={handleChange} />
                                </div> */}

                                <div className="flex border-b-2 w-full sm:w-6/12 justify-between">
                                    <div className="autocomplete-wrapper w-full custom-width">
                                        <Autocomplete
                                            id="patientName"
                                            name="patientName"
                                            className="peer bg-transparent h-10 w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 mt-3"
                                            value={addList.patientName ? addList.patientName : val2}
                                            items={PatientListdata}
                                            getItemValue={item => item.name}
                                            shouldItemRender={renderUsernameP}
                                            renderMenu={item => (
                                                <div className="dropdown">
                                                    {item}
                                                </div>
                                            )}
                                            renderItem={(item, isHighlighted) =>
                                                <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                                                    {item.name}
                                                </div>
                                            }
                                            onChange={(event, val) => { onSetVal(val, 2); setAddList({ ...addList, ['patientName']: '' }); }}
                                            onSelect={(event, obj) => onChangeHandlerp(obj)}
                                        // wrapperStyle={{ width: "100%", overflow: "auto", scrollbarWidth: "none" }}
                                        // inputProps={{ style: { width: "20rem" } }}
                                        // onChange={(handleChange)}
                                        />
                                    </div>
                                    <SearchIcon className="w-5 h-5" />
                                </div>
                            </div>
                            <div className="relative pl-5">
                                <label
                                    for="hospital"
                                    className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-2 "
                                >
                                    Hospital
                                </label>
                                <select
                                    id="hospital"
                                    name="hospital"
                                    value={hospitalId}
                                    onChange={(handleChange)}
                                    className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 mt-3">
                                    {
                                        <>
                                            <option className="py-1 text-sm text-green-600" value="" selected disabled> Select Hospital </option>
                                            {hospitallist.map((res, i) => (
                                                <option key={i} className="py-1 text-sm text-green-600" value={res.code}>{res.name}</option>
                                            ))}
                                        </>}
                                </select>
                            </div>

                            <div className="relative pl-5">
                                <label
                                    for="location"
                                    className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-2 "
                                >
                                    Location
                                </label>
                                <select
                                    id="location"
                                    name="location"
                                    value={hospitalId}
                                    onChange={(handleChange)}
                                    className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 mt-3">
                                    {
                                        <>
                                            <option className="py-1 text-sm text-green-600" value="" selected={!locationId} disabled> Select Location </option>
                                            {locationList.map((res, i) => (
                                                <option key={i} className="py-1 text-sm text-green-600" value={res.code} selected={res.code == locationId}>{res.name}</option>
                                            ))}
                                        </>}
                                </select>
                            </div>

                            <div className="relative pt-2 pl-5">
                                <label for="doctorName" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-2 ">Doctor</label>
                                <div class="flex ">
                                    <input autocomplete="off" id="doctorName" name="doctorName" value={addList.doctorName} className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Doctor name" onChange={handleChange} />
                                </div>
                            </div>


                            <div className="relative pt-4 pl-5">
                                <label for="description" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-4 ">Description</label>
                                <div class="flex ">
                                    <input autocomplete="off" id="description" name="description" type="text"
                                        className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Description" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="flex justify-start ml-5">

                                <button onClick={onSave} className={`disabled:opacity-50 bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2`}>
                                    Send
                                </button>
                            </div>
                        </div>
                        :
                        <div className="py-8 pr-5 sm:pr-0 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                            <div className="relative pl-5">
                                <label
                                    for="patientNamePat"
                                    className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-2 "
                                >
                                    Patient Name
                                </label>
                                {/* <select
                                    id="patientNamePat"
                                    name="patientNamePat"
                                    value={addList.patientNamePat}
                                    onChange={(handleChange)}
                                    className="peer bg-transparent h-10 w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 mt-3">
                                    {
                                        <>
                                            <option className="py-1 text-sm text-green-600" value="" selected disabled> Select patient </option>
                                            {PatientListdata.map((res, i) => (
                                                <option key={i} className="py-1 text-sm text-green-600" value={res.code}>{res.name}</option>
                                            ))}
                                        </>}
                                </select> */}

                                <div className="flex border-b-2  w-full sm:w-6/12 justify-between">
                                    <div className="autocomplete-wrapper w-full custom-width">
                                        <Autocomplete
                                            id="patientNamePat"
                                            name="patientNamePat"
                                            className="peer bg-transparent h-10 w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 mt-3"
                                            value={addList.patientNamePatNAME ? addList.patientNamePatNAME : val3}
                                            items={PatientListdata}
                                            getItemValue={item => item.name}
                                            shouldItemRender={renderUsernamePD}
                                            renderMenu={item => (
                                                <div className="dropdown">
                                                    {item}
                                                </div>
                                            )}
                                            renderItem={(item, isHighlighted) =>
                                                <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                                                    {item.name}
                                                </div>
                                            }
                                            onChange={(event, val) => { onSetVal(val, 3); setAddList({ ...addList, ['patientNamePat']: '', ['patientNamePatNAME']: '' }); }}
                                            onSelect={(event, obj) => onChangeHandlerDP(obj)}
                                        // onChange={(handleChange)}
                                        />
                                    </div>
                                    <SearchIcon className="w-5 h-5" />
                                </div>

                            </div>
                            <div className="relative pl-5">
                                <label
                                    for="doctorList"
                                    className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-2 "
                                >
                                    Doctor
                                </label>
                                <div className="flex border-b-2  w-full sm:w-6/12 justify-between">
                                    <div className="autocomplete-wrapper w-full custom-width">
                                        <Autocomplete
                                            id="doctorList"
                                            name="doctorList"
                                            className="peer bg-transparent h-10 w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 mt-3"
                                            value={addList.doctorList ? addList.doctorList : val1}
                                            items={DoctorList}
                                            getItemValue={item => item.userName}
                                            shouldItemRender={renderUsername}
                                            renderMenu={item => (
                                                <div className="dropdown">
                                                    {item}
                                                </div>
                                            )}
                                            renderItem={(item, isHighlighted) =>
                                                <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                                                    {item.userName}
                                                </div>
                                            }
                                            onChange={(event, val) => { onSetVal(val, 1); setAddList({ ...addList, ['doctorList']: '' }); }}
                                            onSelect={(event, obj) => onChangeHandlerD(obj)}
                                        // onChange={(handleChange)}
                                        />
                                    </div>
                                    <SearchIcon className="w-5 h-5" />
                                </div>
                                {/* <select
                                    id="doctorList"
                                    name="doctorList"
                                    value={addList.doctorList}
                                    onChange={(handleChange)}
                                    className="peer bg-transparent h-10 w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 mt-3">
                                    {
                                        <>
                                            <option className="py-1 text-sm text-green-600" value="" selected disabled> Select Doctor </option>
                                            {DoctorList.map((res, i) => (
                                                <option key={i} className="py-1 text-sm text-green-600" value={res.userName}>{res.userName}</option>
                                            ))}
                                        </>}
                                </select> */}
                            </div>


                            {/* 
                            <div className="relative pt-2 pl-5">
                                <label for="patientNamePat" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-2 ">Patient Name</label>
                                <div class="flex ">
                                    <input autocomplete="off" id="patientNamePat" name="patientNamePat" value={addList.patientNamePat} className="peer bg-transparent h-10 w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Patient name" onChange={handleChange} />
                                </div>
                            </div> */}


                            <div className="relative pt-4 pl-5">
                                <label for="descriptionPat" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-4 ">Description</label>
                                <div class="flex ">
                                    <input autocomplete="off" id="descriptionPat" name="descriptionPat" type="text" value={addList.descriptionPat}
                                        className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Description" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="flex justify-start ml-5">

                                <button onClick={onSavePat} className={`disabled:opacity-50 bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2`}>
                                    Send
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {/* <div className="lg:hidden block">
                <MobileReferFrom />
            </div> */}
            {showSucesspopup ? (
                <ReferSuccessfulpopup FromLocationID={fromLocation}
                    closePopup={() => setshowSucesspopup(!showSucesspopup)}
                ></ReferSuccessfulpopup>
            ) : null}
        </>
    )
}
export default ReferToAdd;
