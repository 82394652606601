
import { PRIS } from "../Constants/PriscriptionConstants";
import priscription from "../services/priscription";


export const Medicinedruglist = () => async (dispatch) => {
    request();
    const res = await priscription.MedicineDrugList()
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PRIS.REQUEST_MEDICINELIST });
    };

    function success(res) {
        dispatch({ type: PRIS.SUCCESS_MEDICINELIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PRIS.FAILED_MEDICINELIST,
            payload: err
        });
    };
};

export const docotorList = () => async (dispatch) => {
    request();
    const res = await priscription.doctorList()
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PRIS.REQUEST_DOCTORLIST });
    };

    function success(res) {
        dispatch({ type: PRIS.SUCCESS_DOCTORLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PRIS.FAILED_DOCTORLIST,
            payload: err
        });
    };
};

export const AddPrescription = (data) => async (dispatch) => {
    request();
    const res = await priscription.AddPrescription(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PRIS.REQUEST_ADDPRESCRIPTION });
    };
    function success(res) {
        dispatch({ type: PRIS.SUCCESS_ADDPRESCRIPTION, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PRIS.FAILED_ADDPRESCRIPTION,
            payload: err
        });
    };
};

export const getLabTest = () => async (dispatch) => {
    request();
    const res = await priscription.LabTestList()
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PRIS.REQUEST_GETLABTEST });
    };
    function success(res) {
        dispatch({ type: PRIS.SUCCESS_GETLABTEST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PRIS.FAILED_GETLABTEST,
            payload: err
        });
    };
};

export const getparentLabTest = () => async (dispatch) => {
    request();
    const res = await priscription.ParentLabTestList()
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PRIS.REQUEST_PARENTGETLABTEST });
    };
    function success(res) {
        dispatch({ type: PRIS.SUCCESS_PARENTGETLABTEST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PRIS.FAILED_PARENTGETLABTEST,
            payload: err
        });
    };
};

export const saveRefer = (data) => async (dispatch) => {
    request();
    const res = await priscription.addRefer(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PRIS.REQUEST_ADDREFERAL });
    };
    function success(res) {
        dispatch({ type: PRIS.SUCCESS_ADDREFERAL, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PRIS.FAILED_ADDREFERAL,
            payload: err
        });
    };
};

export const getDiagnosis = () => async (dispatch) => {
    request();
    const res = await priscription.getDiagnosis()
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: PRIS.REQUEST_GETDIAGNOSIS });
    };
    function success(res) {
        dispatch({ type: PRIS.SUCCESS_GETDIAGNOSIS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: PRIS.FAILED_GETDIAGNOSIS,
            payload: err
        });
    };
};

