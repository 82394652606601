export const USER = {
    REQUEST_USEREDIT: "REQUEST_USEREDIT",
    SUCCESS_USEREDIT: "SUCCESS_USEREDIT",
    FAILED_USEREDIT: "FAILED_USEREDIT",

    REQUEST_PARTICULARDOCT: "REQUEST_PARTICULARDOCT",
    SUCCESS_PARTICULARDOCT: "SUCCESS_PARTICULARDOCT",
    FAILED_PARTICULARDOCT: "FAILED_PARTICULARDOCT",


    REQUEST_PATIENTREPORT: "REQUEST_PATIENTREPORT",
    SUCCESS_PATIENTREPORT: "SUCCESS_PATIENTREPORT",
    FAILED_PATIENTREPORT: "FAILED_PATIENTREPORT",

    REQUEST_DOCTPRESCRIPTION: "REQUEST_DOCTPRESCRIPTION",
    SUCCESS_DOCTPRESCRIPTION: "SUCCESS_DOCTPRESCRIPTION",
    FAILED_DOCTPRESCRIPTION: "FAILED_DOCTPRESCRIPTION",

    
    REQUEST_DOCTCONSULTATIONLIST: "REQUEST_DOCTCONSULTATIONLIST",
    SUCCESS_DOCTCONSULTATIONLIST: "SUCCESS_DOCTCONSULTATIONLIST",
    FAILED_DOCTCONSULTATIONLIST: "FAILED_DOCTCONSULTATIONLIST",

    REQUEST_DOCTREFEREDTOLIST: "REQUEST_DOCTREFEREDTOLIST",
    SUCCESS_DOCTREFEREDTOLIST: "SUCCESS_DOCTREFEREDTOLIST",
    FAILED_DOCTREFEREDTOLIST: "FAILED_DOCTREFEREDTOLIST",


    REQUEST_GETBLLING: "REQUEST_GETBLLING",
    SUCCESS_GETBLLING: "SUCCESS_GETBLLING",
    FAILED_GETBLLING: "FAILED_GETBLLING",

    
    
    REQUEST_SAVEPROFILEDETAILS: "REQUEST_SAVEPROFILEDETAILS",
    SUCCESS_SAVEPROFILEDETAILS: "SUCCESS_SAVEPROFILEDETAILS",
    FAILED_SAVEPROFILEDETAILS: "FAILED_SAVEPROFILEDETAILS",

    REQUEST_ADDALLERGY: "REQUEST_ADDALLERGY",
  SUCCESS_ADDALLERGY: "SUCCESS_ADDALLERGY",
  FAILED_ADDALLERGY: "FAILED_ADDALLERGY",

  REQUEST_ADDMEDICATION: "REQUEST_ADDMEDICATION",
  SUCCESS_ADDMEDICATION: "SUCCESS_ADDMEDICATION",
  FAILED_ADDMEDICATION: "FAILED_ADDMEDICATION",

  REQUEST_ADDMEDICALHISTORY: "REQUEST_ADDMEDICALHISTORY",
  SUCCESS_ADDMEDICALHISTORY: "SUCCESS_ADDMEDICALHISTORY",
  FAILED_ADDMEDICALHISTORY: "FAILED_ADDMEDICALHISTORY",


  
  REQUEST_SURGICALHISTORY: "REQUEST_SURGICALHISTORY",
  SUCCESS_SURGICALHISTORY: "SUCCESS_SURGICALHISTORY",
  FAILED_SURGICALHISTORY: "FAILED_SURGICALHISTORY",
  
  REQUEST_TRAVELHISTORY: "REQUEST_TRAVELHISTORY",
  SUCCESS_TRAVELHISTORY: "SUCCESS_TRAVELHISTORY",
  FAILED_TRAVELHISTORY: "FAILED_TRAVELHISTORY",

  REQUEST_SEXUALHISTORY: "REQUEST_SEXUALHISTORY",
  SUCCESS_SEXUALHISTORY: "SUCCESS_SEXUALHISTORY",
  FAILED_SEXUALHISTORY: "FAILED_SEXUALHISTORY",


  REQUEST_MYREQUESTPATIENT: "REQUEST_MYREQUESTPATIENT",
  SUCCESS_MYREQUESTPATIENT: "SUCCESS_MYREQUESTPATIENT",
  FAILED_MYREQUESTPATIENT: "FAILED_MYREQUESTPATIENT",

  REQUEST_ADDPATIENTADDRESS: "REQUEST_ADDPATIENTADDRESS",
  SUCCESS_ADDPATIENTADDRESS: "SUCCESS_ADDPATIENTADDRESS",
  FAILED_ADDPATIENTADDRESS: "FAILED_ADDPATIENTADDRESS",

  REQUEST_PATIENTADDRESSLIST: "REQUEST_PATIENTADDRESSLIST",
  SUCCESS_PATIENTADDRESSLIST: "SUCCESS_PATIENTADDRESSLIST",
  FAILED_PATIENTADDRESSLIST: "FAILED_PATIENTADDRESSLIST",

  REQUEST_EDITPATIENTADDRESS: "REQUEST_EDITPATIENTADDRESS",
  SUCCESS_EDITPATIENTADDRESS: "SUCCESS_EDITPATIENTADDRESS",
  FAILED_EDITPATIENTADDRESS: "FAILED_EDITPATIENTADDRESS",


}