import React, { useEffect, useState } from 'react'
import search from '../Assets/Images/doctsearch.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReferalTo,ReferalToDoctor } from '../Redux/Actions/RefralAction';
import ProfileSidebar from './profileSidebar';
import ProfileNamebar from './profileNamebar';
import moment from 'moment';
import { SearchIcon } from '@heroicons/react/outline'
import {Dropdown} from 'react-bootstrap'
import { MenuIcon} from '@heroicons/react/outline'
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';

function MobileReferTo() {
    const history = useHistory();
    const dispatch = useDispatch();
    const loggedInData = useSelector((state) => state.authReducer);
    const { userData } = loggedInData;
    const [list, setList] = useState([])
    const [listDoctor, setListDoctor] = useState([])
    const { referredToList, referredToListdoctor } = useSelector((state) => state.referalReducer);;
    const [view, setView] = useState(false)
    useEffect(() => {
        // alert(JSON.stringify(userData.code))
        dispatch(ReferalTo(userData.code))
        dispatch(ReferalToDoctor(userData.code))

    }, []);
    const redirectTo = (event, location) => {
        event.preventDefault();
        history.push(location)
    }
    const onSearch = (text) => {
        let val = text ? text : '';
        // @ts-ignore
        var newArr = referredToList
        // @ts-ignore
        let newArray = newArr.filter((data) => JSON.stringify(data).toLowerCase().indexOf(val.toLowerCase()) !== -1);
        setList(newArray)
    }
    const onSearchDoctor = (text) => {
        let val = text ? text : '';
        // @ts-ignore
        var newArr = referredToListdoctor
        console.log("asap", newArr)
        // @ts-ignore
        let newArray = newArr.filter((data) => JSON.stringify(data).toLowerCase().indexOf(val.toLowerCase()) !== -1);
        setListDoctor(newArray)
    }
    useEffect(() => {
        if (referredToList && referredToList.length > 0) {
            onSearch();
        }
    }, [referredToList.length])
    useEffect(() => {
        if (referredToListdoctor && referredToListdoctor.length > 0) {
            onSearchDoctor();
        }
    }, [referredToListdoctor.length])


    return (
        <>


            <div className="lg:block hidden">
                <ProfileNamebar></ProfileNamebar>
            </div>

            {/* 2nd row */}
            <div className="justify-between ">
                <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">

                    <ProfileSidebar></ProfileSidebar>
                </div>
                {/* table start */}
                <div className="mt-3 mx-6 border boder-gray-200 rounded-lg">
                  { !view ? <div className="flex  pt-0 pl-3 pr-10 pb-0 bg-white rounded-lg relative">
                        <div className="bg-brand-secondary rounded p-1 absolute right-2 top-3">
                            <SearchIcon className="w-5" stroke={'white'} />
                        </div>
                        <input type="text" onChange={(e) => { onSearch(e.target.value) }} className="text-sm text-brand-secondary font-medium pt-4 pb-4 w-full outline-none" placeholder="Search or Filter" />
                    </div> :
                    <div className="flex  pt-0 pl-3 pr-10 pb-0 bg-white rounded-lg relative">
                        <div className="bg-brand-secondary rounded p-1 absolute right-2 top-3">
                            <SearchIcon className="w-5" stroke={'white'} />
                        </div>
                        <input type="text" onChange={(e) => { onSearchDoctor(e.target.value) }} className="text-sm text-brand-secondary font-medium pt-4 pb-4 w-full outline-none" placeholder="Search or Filter" />
                    </div> }
                </div>

                <div className="flex space-x-8 mx-6">

                        <div className="flex space-x-4 items-center py-2">
                            <input type="radio" class="form-radio mt-1 mr-2" defaultChecked name="hospital" value="hospital" onChange={() => setView(false)} />
                            <div className="text-sm font-medium text-gray-500 font-montserrat">Referred to Hospital /Clinic</div>
                        </div>

                        <div className=" flex space-x-4 items-center py-2">
                            <input type="radio" class="form-radio mt-1 mr-2" name="hospital" value="hospital" onChange={() => setView(true)} />
                            <div className="text-sm font-medium text-gray-500 font-montserrat">Referred to Doctor</div>

                        </div>
                    </div>

                    
                
                <div className = "flex justify-end md:justify-start mt-5 mr-6 md:mx-6" >
                <div className = "hidden md:block lg:hidden relative mr-4" >
                <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
              <MenuIcon className="block h-6 w-6" aria-hidden="true"/>

              </Dropdown.Toggle>

              <Dropdown.Menu className = "z-10" >
                <ProfileSidebar/>
              </Dropdown.Menu>
            </Dropdown>
            </div> 
                        <button onClick={(e) => redirectTo(e, APP_ROUTES.REFERTOADD)} className={`disabled:opacity-50 bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2`}>
                            Refer
                        </button>
                   
                </div>
                {!view ? 
                <div className="mx-6 mt-3 space-y-4 mb-20">
                    { list.length === 0 ? <div className = "font-medium py-4 text-gray-500 flex justify-center items-center" style ={{height : "50vh"}} >No Data found</div> :
                    list.map((res, i) => (
                        <div className=" w-full border-solid border-2 rounded-2xl relative p-4 font-rubik mb-4">
                            <div className="flex flex-col ">
                                <div className="flex justify-between 4/5 text-left " >
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Patient Name</span>
                                        <p className="text-sm font-montserrat text-base">{res.referTo ? res.referTo : 'NA'}</p>
                                    </div>
                                    <div >
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Hospital Name</span>
                                        <p className="text-sm font-montserrat text-base">{res.referToName}</p>
                                    </div>
                                   
                                </div>
                                <div className="flex items-center">
                                <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Description</span>
                                        <p className="text-sm font-montserrat text-base">{res.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                :
                <div className="mx-6 mt-3 space-y-4 mb-20">
                    { listDoctor.length === 0 ? <div className = "font-medium py-4 text-gray-500 flex justify-center items-center" style ={{height : "50vh"}} >No Data found</div> :
                    listDoctor.map((res, i) => (
                        <div className=" w-full border-solid border-2 rounded-2xl relative p-4 font-rubik mb-4">
                            <div className="flex flex-col ">
                                <div className="flex justify-between 4/5 text-left " >
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Patient Name</span>
                                        <p className="text-sm font-montserrat text-base">{res.patientName ? res.patientName : 'NA'}</p>
                                    </div>
                                    <div >
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Doctor Name</span>
                                        <p className="text-sm font-montserrat text-base">{res.referTo}</p>
                                    </div>
                                </div>

                                <div className="flex items-center">

                                <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Description</span>
                                        <p className="text-sm font-montserrat text-base">{res.description}</p>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    ))}
                </div>
}
            </div>
        </>
    )
}
export default MobileReferTo;
