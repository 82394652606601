import {SAVE_VIDEO_CALL_DETAILS} from '../Constants/videoCallConstants'
import {DELETE_VIDEO_URL} from '../Constants/videoCallConstants'
import {ADD_EVENT_LOCATION} from '../Constants/videoCallConstants'
export const SaveVideoCall = (data) =>{
    return {
        type: SAVE_VIDEO_CALL_DETAILS,
        payload: data
    }
}

export const DeleteVideoUrl = () =>{
    return {
        type: DELETE_VIDEO_URL
    }
}

export const AddEventLocation = () =>{
    return {
        type: ADD_EVENT_LOCATION
    }
}