/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatientfamilymembers,
  getPatientDetails,
} from "../Redux/Actions/UserprofileActions";
import { addRegistration } from "../Redux/Actions/registrationAction";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import { XIcon } from "@heroicons/react/outline";
import { getpatientfamilymemberslist } from "../Redux/Actions/patientAction";
import { setPatientCode } from "../Redux/Actions/userActions";

function FamilyDropdown(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const UserData = useSelector((state) => state.authReducer.userData);

  console.log("userData", UserData);

  const familymemebersdetails = useSelector((state) => state.familymemberslist);
  const { familymemebersdetailsData } = familymemebersdetails;
  const [memberList, setMemberList] = useState([]);

  // const addSelf = () => {
  //     let data = {};
  //     data.name = UserData.firstName;
  //     data.code = UserData.code;
  //     let members = familymemebersdetailsData;
  //     members.unshift(data);
  //     setMemberList(members);
  // }

  // const addSelf = () => {
  //   let data = {};
  //   data.name = "self";
  //   // data.name = UserData.firstName;
  //   data.code = localStorage.getItem("patientlogginedusercode");
  //   let members = familymemebersdetailsData;
  //   members.unshift(data);
  //   console.log(members);
  //   setMemberList(members);
  // };



  useEffect(() => {
    let patient = localStorage.getItem("patientprofile");
    console.log("patient", patient);
    dispatch(getpatientfamilymemberslist(patient))
      .then((result) => {
        console.log(result);
        setMemberList(result);
      })
      .catch((error) => {
        // setLoader(false)
        // redirectTo();
      });
  }, []);

  const handleChange = (e) => {
    console.log("profile :::>", e.target.value);
    localStorage.setItem("familydropdown", e.target.value);
  };

  const onChange = (code) => {
    // props.onChange(code);
    dispatch(setPatientCode(code));
    localStorage.setItem("patientprofile", code);
    if (props.onSelect) {
      props.onSelect(code);
    }
  };



  return (
    <div class="flex justify-between mt-2 mb-4 px-0 sm:px-2">
      <div class="flex pr-2">
        <p class="text-medium font-medium text-2xl font-rubik text-brand-secondary">
          {props.title}
        </p>
        <div class="h-10 w-40 border border-gray-200 p-2 ml-0 sm:ml-4  rounded-lg flex space-x-6">
          <select
            className="w-full   outline-none"
            value={localStorage.getItem("patientprofile")}
            onChange={(e) => onChange(e.target.value)}
          >
            <option className="py-1 text-sm text-green-600" value={localStorage.getItem("patientprofile")}>
              Self
            </option>

            {memberList.length === 0 ? (
              <p className="text-center item-center mt-20 mb-20  ">
                No members
              </p>
            ) : (
              <>
                {memberList.map((res, i) => (
                  <option
                    className="py-1 text-sm text-green-600"
                    value={res.code}
                  >
                    {res.name}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
      </div>
    </div>
  );
}
export default FamilyDropdown;
