import { PAT } from "../Constants/patientConstants";
import { VITALS } from "../Constants/vitalConstants";


const initialState = {
    patientPHRData: [],
    isError: false,
    isLoading: false,
    msg: '',
    errMsg: ''
};


const patientPHRReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PAT.REQUEST_PATIENTPHRLIST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case PAT.SUCCESS_PATIENTPHRLIST:
            return {
                ...state,
                isLoading: false,
                patientPHRData : payload,
                isError: false,              
            };
        case PAT.FAILED_PATIENTPHRLIST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };
            case PAT.REQUEST_PATIENTDOCUMENTS:
                return {
                    ...state,
                    isLoading: true,
                    isError: false
                };
            case PAT.SUCCESS_PATIENTDOCUMENTS:
                console.log('Called Here : ' + JSON.stringify(payload));
                return {
                    ...state,
                    isLoading: false,
                    patientPHRData : payload,
                    isError: false,              
                };
            case PAT.FAILED_PATIENTDOCUMENTS:
                return {
                    ...state,
                    isLoading: false,
                    errMsg: payload,
                    isError: true
                };
        default:
            return state;
    }
};

export default patientPHRReducer;