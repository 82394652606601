/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect } from 'react'
import eye from '../Assets/Images/eye@2x.svg';
import { useDispatch, useSelector } from 'react-redux';
import { EyeOffIcon, EyeIcon } from '@heroicons/react/outline'
import { useHistory, useLocation } from 'react-router';
import { Base64 } from 'js-base64';
import { Logout, forgotPassword } from "../Redux/Actions/userActions";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileSidebar from './profileSidebar';
import {Dropdown} from 'react-bootstrap'
import { MenuIcon} from '@heroicons/react/outline'
function Changepassword() {


    const [spt1, setpasswordType1] = useState(false)
    const [spt2, setpasswordType2] = useState(false)
    const [spt3, setpasswordType3] = useState(false)


    const { isLoading, errMsg, isError } = useSelector(state => state.authReducer)
    const history = useHistory();
    const location = useLocation();
    const [digit, setDigit] = useState(false);
    const [passLength, setPassLength] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [special, setSpecial] = useState(false);
    const [regexP] = useState(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/)
    const [isDisable, setDisable] = useState(true);
    const dispatch = useDispatch();
    const UserData = useSelector(state => state.authReducer.userData);
    const loggedInData = useSelector((state) => state.authReducer);

    const { patientSession } = loggedInData;
    const [password, setPassword] = useState({
        oldPassword: "",
        newPassword: "",
        rePassword: ""
    })
    const handleChange = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
        if (e.target.name === "newPassword") {
            passwordValidate(e.target.value)
        }
    };

    useEffect(() => {
        setPassword({
            oldPassword: "",
            newPassword: "",
            rePassword: ""
        })

    }, [isError])

    const savechangepassword = (e) => {
        e.preventDefault();
        let data = {
            createdBy: UserData.code,
            currentPasswordVal: Base64.encode(password.oldPassword),
            modifiedBy: UserData.code,
            passWordVal: Base64.encode(password.rePassword),
            userCode: UserData.code,
            status: 1
        }
        dispatch(forgotPassword(data)).then((result) => {
            console.log("lol", result)
            if (result === 1) {
                toast("Password Changed successfully");
                setTimeout(logoutAfter, 3000);
            } else {
                // toast(result[0].details);
            }
        }).catch((error) => {
            console.log("lolerror", error)
            toast(error.details[0]);

        });
        //dispatch(cheadminchangepassword(data))
        // to dispatch newly created object to createlocation action
    }
    const logoutAfter = () => {
        localStorage.clear();
        let dataObj = {
            sessionId: patientSession?.id,
            userCode: patientSession?.patientCode
        }
        dispatch(Logout(dataObj));
        history.push(APP_ROUTES.Login)
    }

    const passwordValidate = value => {
        const regexNum = /\d/;
        const regexUppercase = /[A-Z]/;
        const regexCharLength = /^.{8,72}$/;
        const special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        regexNum.test(value)
            ? setDigit(true)
            : setDigit(false);
        regexUppercase.test(value)
            ? setUpperCase(true)
            : setUpperCase(false);
        regexCharLength.test(value)
            ? setPassLength(true) :
            setPassLength(false);
        special.test(value)
            ? setSpecial(true) :
            setSpecial(false);
    };

    useEffect(() => {
        if (password.rePassword === password.newPassword && regexP.test(password.newPassword) && password.oldPassword) {
            setDisable(false)
        }
        else {
            setDisable(true)
        }
        console.log("sdsf")
    }, [password])

    return (
        <>

            <div class="flex justify-between ">
                <ToastContainer />

                <div className="lg:block hidden w-3/12 ml-6 mt-3">
                    <ProfileSidebar></ProfileSidebar>
                </div>

                <div className="lg:w-8/12 w-full lg:mr-16 lg:mt-5 ">

                    <div>
                        <div className="md:rounded-lg  bg-white-600  m-auto w-full h-112 p-5 antialiased justify-between md:border border-gray-200">
                            <div className = "flex" >
                            <div className = "hidden md:block lg:hidden relative top-1 mr-4" >
                <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
              <MenuIcon className="block h-6 w-6" aria-hidden="true"/>

              </Dropdown.Toggle>

              <Dropdown.Menu className = "z-10" >
                <ProfileSidebar/>
              </Dropdown.Menu>
            </Dropdown>
            </div>
                                <h1 className="text-medium font-medium text-2xl font-rubik">
                           
                                    Change Password </h1>
                            </div>
                            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                <div className="relative pt-2">
                                    <label for="oldPassword" className="absolute left-0 -top-3.5 text-green-600 text-sm ">Old Password <span className="text-brand-star">*</span></label>
                                    <div class="flex ">
                                        <input autocomplete="off" id="oldPassword" name="oldPassword" type={!spt1 ? "password" : "text"} value={password.oldPassword} className="peer bg-transparent h-10 w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Old Password" onChange={handleChange} />
                                       
                                        {!spt1 ? <EyeOffIcon onClick={() => setpasswordType1(!spt1)} className="h-5 m-2 text-brand-manatee relative right-5 cursor-pointer" />
                                            : <EyeIcon onClick={() => setpasswordType1(!spt1)} className="h-5 m-2 text-brand-manatee relative right-5 cursor-pointer" />}
                                    </div>
                                </div>
                                <div className="relative pt-4">

                                    <label for="newPassword" className="absolute left-0 -top-3.5  text-sm text-brand-manatee pt-3">New Password <span className="text-brand-star">*</span></label>
                                    <div class="flex ">
                                        <input autocomplete="off" id="newPassword" name="newPassword" type={!spt2 ? "password" : "text"} value={password.newPassword} className="peer bg-transparent h-10  w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter New Password" onChange={handleChange} />


                                        {!spt2 ? <EyeOffIcon onClick={() => setpasswordType2(!spt2)} className="h-5 m-2 text-brand-manatee relative right-5 cursor-pointer" />
                                            : <EyeIcon onClick={() => setpasswordType2(!spt2)} className="h-5 m-2 text-brand-manatee relative right-5 cursor-pointer" />}
                                    </div>
                                </div>
                                <div className="flex  mt-3  ">
                                    <span disabled className={`${passLength ? "text-green-500 " : "text-gray-400 "}cursor-not-allowed bg-gray-200  text-sm px-2 py-1 p rounded-sm mr-2`}>8 character</span>
                                    <span disabled className={`${special ? "text-green-500 " : "text-gray-400 "}cursor-not-allowed bg-gray-200 text-sm px-2 py-1 p rounded-sm mr-2`}>1 special characters</span>
                                    <span disabled className={`${upperCase ? "text-green-500 " : "text-gray-400 "}cursor-not-allowed bg-gray-200 text-sm px-2 py-1 p rounded-sm mr-2`}>1 uppercase</span>
                                    <span className={`${digit ? "text-green-500 " : "text-gray-400 "} cursor-not-allowed bg-gray-200 text-sm px-2 py-1 p rounded-sm mr-2`}>1 numeric</span>
                                </div>
                                <div className="relative pt-4">
                                    <label for="rePassword" className="absolute left-0 -top-3.5  text-sm text-brand-manatee pt-3">Confirm New Password <span className="text-brand-star">*</span></label>
                                    <div class="flex ">
                                        <input autocomplete="off" id="rePassword" name="rePassword" type={!spt3 ? "password" : "text"} value={password.rePassword} className="peer bg-transparent h-10  w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter New Password" onChange={handleChange} />
                                        {!spt3 ? <EyeOffIcon onClick={() => setpasswordType3(!spt3)} className="h-5 m-2 text-brand-manatee relative right-5 cursor-pointer" />
                                            : <EyeIcon onClick={() => setpasswordType3(!spt3)} className="h-5 m-2 text-brand-manatee relative right-5 cursor-pointer" />}
                                    </div>
                                </div>
                                {password?.rePassword && password?.newPassword !== password?.rePassword && <span className="text-red-500 font-rubik text-xs">Password & confirm doses not match.</span>}
                                <span className="text-red-500 font-rubik text-xs">{errMsg}</span>
                                <div className="flex justify-end">
                                    <button onClick={() => history.goBack()} className="bg-white text-brand-secondary p-2 rounded-xl mr-2">Cancel</button>
                                    <button onClick={savechangepassword} disabled={isDisable} className={`disabled:opacity-50 bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2`}>
                                        Save Changes
                                        {isLoading && <div className="loader float-right ml-2 ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
export default Changepassword;
