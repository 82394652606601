/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useState } from "react";
import LabServices from "../Redux/services/labServices";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { DICOM_URL } from "../config/constant";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import MobileLabReport from "./mobileLabReports";
import { Modal, CircularProgress } from '@material-ui/core'
import ViewInternalOrder from "./ViewInternalOrder";

function LabReport(props) {
  const [labReportList, setLabReport] = useState([]);
  const [diagnositcDs, setdiagnositcDs] = useState([]);
  const [patientDs, setPatientDs] = useState([]);
  const [diagnositcLocationDs, setdiagnositcLocationDs] = useState([]);
  const [filterPopup, setFilterPopup] = useState(false);
  const [filePopup, setfilePopup] = useState(false);
  const [dicomPopup, setdicomPopup] = useState(false);
  const [dicomURL, setdicomURL] = useState("");
  const [dicomToken, setDicomToken] = useState("");
  const [labObj, setLabObj] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsloading] = useState(false)

  
  const [internalLabReportList, setInternalLabReportList] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [showInternalOrder, setShowInternalOrder] = useState(false);
  const [openTab, setOpenTab] = useState(1);

  useEffect(() => {
    const payload = {
      patientId: localStorage.getItem("patientprofile"),
      docmentRequired: 'Y'
    };
    loadLabOrders(payload);
    loadInternalLabOrders(payload);
    loadDiagnosticCenter();
    loadPatient();
    getDicomToken();
  }, []);

  const getDicomToken = () => {
    console.log("dicom enter");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: "integration",
        password: "integration",
      }),
    };
    fetch(DICOM_URL + "authenticate", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setDicomToken(data.token);
      });
  };

  const viewDicomFile = (payload) => {
    console.log("dicom file");
    const requestOptions = {
      method: "POST",
      headers: { Authorization: "Bearer " + dicomToken },
      // body: JSON.stringify({
      //   username: 'integration',
      //   password: 'integration'
      // })
    };
    fetch(DICOM_URL + "viewer/" + payload, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setdicomURL(data.details);
        setdicomPopup(true);
        // setDicomToken(data.token);
      });
  };

  const loadPatient = () => {
    const payload = {
      status: 1,
    };
    LabServices.getPatientList(payload).then(
      (res) => {
        console.log(res);
        if (res.data) {
          setPatientDs(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const changeTab = (i) => {
    setOpenTab(i);
  }

  const loadDiagnosticCenter = () => {
    const payload = {
      status: 1,
      type: "D",
    };
    LabServices.getDiagnosticCenter(payload).then(
      (res) => {
        console.log(res);
        if (res.data) {
          setdiagnositcDs(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const loadLabOrders = (payload) => {
    setIsloading(true)
    LabServices.getLabOrderDetails(payload).then(
      (res) => {
        setIsloading(false)
        console.log(res.data);
        setFilterPopup(false);
        if (res.data) {
          setLabReport(res.data);
        }
      },
      (err) => {
        setIsloading(false)
        console.log(err);
      }
    );
  };

  const loadInternalLabOrders = (payload) => {
    console.log(payload);
    LabServices.getInternalLabOrderDetails(payload).then(
      (res) => {
        console.log(res.data);
        setFilterPopup(false);
        if (res.data) {
          setInternalLabReportList(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getDiagnosticLocation = () => {
    const payload = {
      hospitalCode: labObj.hospitalId,
      status: 1,
    };
    LabServices.getDiagnosticLocation(payload).then(
      (res) => {
        console.log(res);
        if (res.data) {
          setdiagnositcLocationDs(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    setLabObj({ ...labObj, [e.target.name]: e.target.value });
    if (e.target.name == "hospitalId") {
      getDiagnosticLocation();
    }
  };

  const openFilter = (e) => {
    console.log(e);
    setFilterPopup(true);
    console.log(diagnositcDs);
  };

  const okFilter = (e) => {
    console.log("Filter Click");
    let payload = {};
    if (fromDate) {
      payload.fromDate = fromDate;
    }
    if (toDate) {
      payload.toDate = toDate;
    }
    if (labObj.patientId) {
      payload.patientId = labObj.patientId;
    }
    if (labObj.orderId) {
      payload.orderId = labObj.orderId;
    }
    if (labObj.hospitalId) {
      payload.hospitalId = labObj.hospitalId;
    }
    if (labObj.locationId) {
      payload.locationId = labObj.locationId;
    }

    payload.docmentRequired = 'Y';
    console.log(payload);
    loadLabOrders(payload);
  };

  const onHide = (name) => {
    console.log(name);
    setFilterPopup(false);
  };

  const onCancelDocument = (name) => {
    console.log(name);
    setfilePopup(false);
  };

  const changeFromDate = (e) => {
    setFromDate(moment(e).format("MM/DD/yyyy"));
  };

  const changeToDate = (e) => {
    setToDate(moment(e).format("MM/DD/yyyy"));
  };

  const viewInternalOrder = (order) => {
    setSelectedItem(order);
    setShowInternalOrder(true);
  }

  const viewOrder = (e) => {
    console.log(e);
    setSelectedRow(e);
    e.type =
      e.type.toLowerCase() === "pdf"
        ? "application/pdf"
        : e.type.toLowerCase() === "jpg"
          ? "image/jpeg"
          : e.type.toLowerCase() === "jpeg"
            ? "image/jpeg"
            : e.type.toLowerCase() === "png"
              ? "image/png"
              : e.type;
    if (e.type.toLowerCase() == "dicom") {
      viewDicomFile(e.dicomId);
    } else {
      setfilePopup(true);
    }
  };



  const renderFooter = (name) => {
    return (
      <div>
        <button
          onClick={() => onHide(name)}
          className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
        >
          Cancel
        </button>

        <button
          onClick={() => okFilter(name)}
          className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
        >
          Ok
        </button>
      </div>
    );
  };


  const renderDocumentFooter = (name) => {
    return (
      <div>
        <button
          onClick={() => onCancelDocument(name)}
          className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
        >
          Cancel
        </button>
      </div>
    );
  };
  const onCancelDicom = (name) => {
    console.log(name);
    setdicomPopup(false);
  };

  const renderDicomFooter = (name) => {
    return (
      <div>
        <button
          onClick={() => onCancelDicom(name)}
          className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
        >
          Cancel
        </button>
      </div>
    );
  };

  const iframeStyle = { border: 'none', width: '95vw', height: '75vh' };

  return (
    <>
      <div className="lg:block hidden" >
        <ToastContainer />


        <div className="flex justify-between w-full">


          <div className="w-full mt-2 ">
            <div className="flex flex-col">
              <div className="">
                <div className=" align-middle inline-block w-full ">
                  <div class="flex justify-between mt-0 my-3">
                    <p class="text-medium font-medium text-2xl font-rubik ">
                      My Lab Report
                    </p>
                    <p
                      onClick={(e) => openFilter(e)}
                      class="text-sm cursor-pointer text-blue-900 font-medium pr-4"
                    >
                      {" "}
                      Filter
                    </p>
                  </div>

                  <ul class="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4" id="tabs-tabFill"
                  role="tablist">
                  <li class="nav-item flex-auto text-center" role="presentation">
                    <a onClick={(e) => { changeTab(1) }} className={openTab === 1 ? 'nav-link block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent bg-gray-100 cursor-pointer' : 'nav-link block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent cursor-pointer'} id="tabs-home-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-diagnostics" role="tab"
                      aria-controls="tabs-diagnostics" aria-selected="true">Diagnostics</a>
                  </li>
                  <li class="nav-item flex-auto text-center" role="presentation">
                    <a onClick={(e) => { changeTab(2) }} className={openTab === 2 ? 'nav-link block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent bg-gray-100 cursor-pointer' : 'nav-link block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent cursor-pointer'} id="tabs-profile-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-eClinicTests" role="tab"
                      aria-controls="tabs-eClinicTests" aria-selected="false">eClinic Tests</a>
                  </li>
                </ul>

                

                  <div class="w-full h-112 lg:mb-2 mb-16 tab-pane fade show active antialiased border-gray-200 sm:rounded-lg justify-between">
                    {isLoading ?
                      <div className="flex justify-center items-center  left-0 right-0 m-auto" >
                        <CircularProgress />
                      </div>
                      : null}

{openTab === 1 && (
                  <>
                  {labReportList.length === 0 ? (
                        <p className="text-center item-center mt-40 mb-40  ">
                          {!isLoading ? "No Order available" : " "}
                        </p>
                    ) : (
                      <>
                        <div className="shadow w-full px-1 border border-gray-200 sm:rounded-lg">
                          {/* <hr classname="border-dash text-black w-10 mt-4 h-20 my-2"></hr> */}
                          <div className="h-80 w-full lg:mx-1  overflow-y-scroll overflow-x-scroll custom-scroll">
                            {/* <div className="flex"> */}
                              <table className="max-w-full h-28 divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <div className="flex ">
                                      {/* <input type="radio" class="form-radio mt-2 my-2" name="accountType" value="personal" /> */}
                                      <th
                                        scope="col"
                                        className="px-6 py-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Order ID
                                      </th>
                                    </div>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Date
                                    </th>

                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Diagnostics
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Diagnostics Location
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Document Title
                                    </th>

                                    <th
                                      scope="col"
                                      className="pr-2 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      View Document
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className=" divide-y divide-gray-200">
                                  {labReportList.map((lab, i) => (
                                    <tr>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                          <div className=" flex space-x-2">
                                            {/* <input
                                          type="radio"
                                          class="form-radio mt-q mr-2"
                                          name="accountType"
                                          value="personal"
                                        /> */}
                                            <div className="text-sm font-medium text-gray-500 font-montserrat">
                                              {lab.orderId}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                          <div className=" flex space-x-2">
                                            <div className="text-sm font-medium text-gray-500 font-montserrat">
                                              {moment(lab.createdDate).format("DD/MM/yyyy")}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-500 font-montserrat">
                                          {lab.hospitalName}
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="text-sm text-gray-500 font-montserrat">
                                          {lab.locationName}
                                        </span>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-montserrat">
                                        {lab.type}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <div className="flex space-x-4">
                                          <button
                                            className=" mr-2 text-brand-secondary hover:text-brand-secondary"
                                            onClick={() => viewOrder(lab)}
                                          >
                                            View
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            {/* </div> */}

                          </div>
                        </div>
                      </>
                    )}
                  </>
                  )}

{openTab === 2 && (
  <>
  {internalLabReportList.length === 0 ? (
                        <p className="text-center item-center mt-40 mb-40  ">
                          {!isLoading ? "No Order available" : " "}
                        </p>
                    ) : (
                      <>
                        <div className="shadow w-full px-1 border border-gray-200 sm:rounded-lg">
                          {/* <hr classname="border-dash text-black w-10 mt-4 h-20 my-2"></hr> */}
                          <div className="h-80 w-full lg:mx-1  overflow-y-scroll overflow-x-scroll custom-scroll">
                            {/* <div className="flex"> */}
                              <table className="max-w-full h-28 divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <div className="flex ">
                                      {/* <input type="radio" class="form-radio mt-2 my-2" name="accountType" value="personal" /> */}
                                      <th
                                        scope="col"
                                        className="px-6 py-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Order ID
                                      </th>
                                    </div>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Date
                                    </th>

                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Diagnostics
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Diagnostics Location
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Document Title
                                    </th>

                                    <th
                                      scope="col"
                                      className="pr-2 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      View Document
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className=" divide-y divide-gray-200">
                                  {internalLabReportList.map((lab, i) => (
                                    <tr>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                          <div className=" flex space-x-2">
                                            {/* <input
                                          type="radio"
                                          class="form-radio mt-q mr-2"
                                          name="accountType"
                                          value="personal"
                                        /> */}
                                            <div className="text-sm font-medium text-gray-500 font-montserrat">
                                              {lab.orderId}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                          <div className=" flex space-x-2">
                                            <div className="text-sm font-medium text-gray-500 font-montserrat">
                                              {moment(lab.createdDate).format("DD/MM/yyyy")}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-500 font-montserrat">
                                          {lab.hospitalName}
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="text-sm text-gray-500 font-montserrat">
                                          {lab.locationName}
                                        </span>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-montserrat">
                                        {lab.type}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <div className="flex space-x-4">
                                        <button
                                        className=" mr-2 text-brand-secondary hover:text-brand-secondary"
                                        onClick={() => viewInternalOrder(lab)}
                                      >
                                        View
                                      </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            {/* </div> */}

                          </div>
                        </div>
                      </>
                    )}
  </>
)}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showInternalOrder && (
          <Dialog
          header="View Lab Report"
          visible={true}
          // style={{ width: "25vw" }}
          modal
          className="w-11/12 md:w-9/12 lg:w-8/12"
          onHide={() => setShowInternalOrder(false)}
        >
          <ViewInternalOrder
          data={selectedItem}></ViewInternalOrder>
          </Dialog>
        
      )}

        {filterPopup && (
          <Dialog
            header="Filter"
            visible={filterPopup}
            modal={false}
            style={{ width: "50vw" }}
            footer={renderFooter("displayModal")}
            onHide={() => onHide("displayModal")}
          >
            <p className="p-m-0">
              <div class="lg:flex pt-2 ">
                <div className="lg:w-6/6">
                  <div class="lg:flex lg:pt-1 mt-5 lg:space-x-10 ">

                    

                    <div class="relative w-4/12 mb-5">
                      <label
                        for="fromDate"
                        class="left-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        From Date
                      </label>

                      <DatePicker
                        id="fromDate"
                        name="fromDate"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        value={fromDate ? moment(fromDate).format("DD/MM/yyyy") : ""}
                        onSelect={changeFromDate}
                        disabledKeyboardNavigation={true}
                        autoFocus={false}
                        placeholderText="From Date"
                        className={
                          "peer -mx-1 w-full  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        }
                      />
                    </div>

                    <div class="relative w-4/12 mb-5">
                      <label
                        for="toDate"
                        class="left-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        To Date
                      </label>

                      <DatePicker
                        id="toDate"
                        name="toDate"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        value={toDate ? moment(toDate).format("DD/MM/yyyy") : ""}
                        onSelect={changeToDate}
                        disabledKeyboardNavigation={true}
                        autoFocus={false}
                        placeholderText="To Date"
                        className={
                          "peer -mx-1  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        }
                      />
                    </div>

                    <div class="relative w-4/12 mb-5">
                      <label
                        for="orderId"
                        class="left-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Order ID
                      </label>
                      <input
                        autocomplete="off"
                        id="orderId"
                        name="orderId"
                        type="text"
                        value={labObj.orderId}
                        className={
                          "peer -mx-1 w-full h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        }
                        placeholder="Order ID"
                        onChange={handleChange}
                      />
                    </div>

                  </div>

                  <div class="lg:flex lg:pt-1 mt-5 lg:space-x-10 ">
                   

                    <div class="relative w-4/12 mb-5">
                      <label
                        for="relation"
                        class="left-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Diagnosic Center
                      </label> <br></br>
                      <Dropdown
                        value={labObj.hospitalId}
                        options={diagnositcDs}
                        onChange={handleChange}
                        optionLabel="name"
                        optionValue="code"
                        className="w-full"
                        filter
                        showClear
                        id="hospitalId"
                        name="hospitalId"
                        filterBy="name"
                        placeholder="Select Diagnosic Center"
                      />
                    </div>

                    <div class="relative w-4/12 mb-5">
                      <label
                        for="locationId"
                        class="left-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Location
                      </label> <br></br>
                      <Dropdown
                        value={labObj.locationId}
                        options={diagnositcLocationDs}
                        onChange={handleChange}
                        optionLabel="name"
                        optionValue="code"
                        filter
                        showClear
                        id="locationId"
                        name="locationId"
                        className="w-full"
                        filterBy="name"
                        placeholder="Select Location"
                      />
                    </div>

                    <div class="relative w-4/12 mb-5"></div>
                  </div>
                </div>
              </div>
            </p>
          </Dialog>
        )}

        {filePopup && (
          <Dialog
            header="Document"
            visible={filePopup}
            modal={false}
            style={{ width: "98vw", height: '100vh' }}
            footer={renderDocumentFooter("displayModal")}
            onHide={() => onCancelDocument("displayModal")}
          >
            <p className="p-m-0">
              <div class="lg:flex pt-2 ">
                <div className="lg:w-6/6">
                  <div class="lg:flex lg:pt-1 mt-5 lg:space-x-10 ">
                    <embed class="box target" width="1000" height="375" src={process.env.REACT_APP_IMG_BASEURL + selectedRow.photoName} type={selectedRow.type} />
                  </div>
                </div>
              </div>
            </p>
          </Dialog>
        )}

        {dicomPopup && (
          <Dialog
            header="Dicom Viewer"
            visible={dicomPopup}
            modal={false}
            style={{ width: "98vw", height: '100vh' }}
            footer={renderDicomFooter("displayModal")}
            onHide={() => onCancelDicom("displayModal")}
          >
            <p className="p-m-0">
              <div class="lg:flex pt-2 ">
                <div className="lg:w-6/6">
                  <div class="lg:flex lg:pt-1 mt-5 lg:space-x-10 ">
                    <iframe
                      src={dicomURL}
                      title="Dicom Viewer"
                      style={iframeStyle}
                    ></iframe>
                  </div>
                </div>
              </div>
            </p>
          </Dialog>
        )}
      </div>
      <div className="block lg:hidden" >
        <MobileLabReport />
      </div>
    </>
  );
}
export default LabReport;
