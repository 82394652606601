import React, { useState, useEffect } from "react";
import vc from "../Assets/Images/videoconsult.svg";
import ip from "../Assets/Images/In-person.svg";
import ad from "../Assets/Images/audiocall.svg";
import vijay from "../Assets/Images/vijy.svg";
import chat from "../Assets/Images/chath.svg";
import user from "../Assets/Images/userrr.svg";
import dwnld from "../Assets/Images/arrow.downloaded.svg";
import heart from "../Assets/Images/heart.svg";
import refresh from "../Assets/Images/refresh-button.png";
import arrowalt from "..//Assets/Images/arrow.alt.down.svg";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilteredAppointmentList,
  gethospitalclinicList,
  getPatientdetails,
  getPatientPHRReportss,
  getPatientReports,
} from "../Redux/Actions/patientAction";
import { Link, useHistory, useParams } from "react-router-dom";
import { getPatientvitallist } from "../Redux/Actions/vitalsAction";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import patientService from "../Redux/services/patientService";
import { IMG_URL } from "../config/constant";
import { Modal, CircularProgress } from "@material-ui/core";
import { Dialog } from "@headlessui/react";
import RequestAccess from "./RequestAccess";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qf from "../Assets/Images/quickFill.png";

function AppointmentTwo() {
  const [startDate, setStartDate] = useState(moment().format("MM/DD/yyyy"));
  const [endDate, setEndDate] = useState(moment().format("MM/DD/yyyy"));

  const history = useHistory();
  const dispatch = useDispatch();

  const [numberofappointments, setnumberofappointments] = useState();
  const [hospitalnumberofappointments, sethospitalnumberofappointments] =
    useState();
  const [hospitalname, sethospitalname] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [showLeft, setShowLeft] = useState(false);
  const [screen, setScreen] = useState({
    isMobile: false,
    isTablet: false,
    isLaptop: false,
  });
  const [list, setList] = useState([]);
  const [result, setComplain] = useState({});
  const [showRequestAccess, setShowRequestAccess] = useState(false);
  const upcomingappointmentlist = useSelector(
    (state) => state.UpcomingAppointment
  );

  const loggedInData = useSelector((state) => state.authReducer);
  const { userData } = loggedInData;
  const { UpcomingappointmentlistData, isError } = upcomingappointmentlist;

  const patientdetailslist = useSelector((state) => state.patientdetails || []);
  const { patientdetailsData } = patientdetailslist;

  const vitalslist = useSelector((state) => state.vitalslist);
  const { vitalslistData } = vitalslist;

  const hospitalcliniclist = useSelector((state) => state.hospitalcliniclist);
  const { hospitalcliniclistData } = hospitalcliniclist;
  const [lengthdata, setLength] = useState();
  const [appointmentStatus, setAppointmentStatus] = useState(1);
  const [appointmentType, setappointmentType] = useState("");
  const [statuses, setStatuses] = useState([
    { name: "Upcoming", value: 1 },
    { name: "Completed", value: 2 },
    { name: "Cancel By Patient", value: 3 },
    { name: "Cancel By Doctor", value: 4 },
    { name: "Cancel By Admin/Backoffice", value: 5 },
    { name: "Cancel By Customer Care", value: 6 },
    { name: "Cancelled by eClinic Centre", value: 7 },
  ]);
  const [apptype, setapptype] = useState([
    { name: "Audio", value: "A" },
    { name: "Video", value: "V" },
    { name: "InPerson", value: "I" },
    { name: "Quick", value: "Q" },
    { name: "InClinic", value: "IC" },
  ]);

  const [pages, setPages] = useState([1, 2, 3]);
  const [currentPageNumber, setcurrentPageNumber] = useState(1);

  const getcurrentPageNumber = (data) => {
    //setcurrentPageNumber(data);
    //getcurrentPageNumberData();
  };

  const changePage = (val) => {
    if (val === "next" && currentPageNumber < pages[pages.length - 1]) {
      setcurrentPageNumber(currentPageNumber + 1);
      //getcurrentPageNumberData();
    }
    if (val === "previous" && currentPageNumber > 1) {
      setcurrentPageNumber(currentPageNumber - 1);
      //getcurrentPageNumberData();
    }
  };

  const refer = (e, doct) => {
    e.preventDefault();
    console.log(doct.code);
    console.log(doct);
    if (doct.access == 1) {
      //history.push({ pathname: `/patientdetails/${doct.code}`, state: result });
      history.push({ pathname: `/patient_details/${doct.code}` });
    } else {
      setShowRequestAccess(true);
    }
  };

  const closeRequestAccess = () => {
    setShowRequestAccess(false);
  };

  const requestedAccess = () => {
    toast("Request sent to patient successfully");
    setShowRequestAccess(false);
  };

  const handleChange = (e) => {
    console.log("hello");
    sethospitalname(e.target.value);
    // search(e.target.value === "all" ? "" : e.target.value);
  };

  const handleChangeApptype = (e) => {
    setappointmentType(e.target.value);
    searchAppStatus(e.target.value);
    //search(e.target.value === "all" ? "" : e.target.value)
  };

  const searchAppStatus = (status) => {
    let payload = {
      userId: userData.code,
      fromDate: moment(startDate).format("MM/DD/yyyy"),
      toDate: moment(endDate).format("MM/DD/yyyy"),
      photoRequired: "Y",
      status: appointmentStatus,
      consultationsType: status,
    };
    if (payload.consultationsType == "") {
      delete payload.consultationsType;
    }
    appointmentFilter(payload);
  };

  const handleChangeStatus = (e) => {
    if (e.target.value == 1) {
      loadAppointmentsUpcoming();
      setAppointmentStatus(e.target.value);
      // searchStatuses(e.target.value);
      setShowLeft(false);
    } else {
      setAppointmentStatus(e.target.value);
      searchStatuses(e.target.value);
      setShowLeft(false);
    }
    // console.log(e.target.value)
    // setAppointmentStatus(e.target.value);
    // searchStatuses(e.target.value);
    // setShowLeft(false);
    // //search(e.target.value === "all" ? "" : e.target.value)
  };

  const appointmentFilter = (payload) => {
    dispatch(getFilteredAppointmentList(payload))
      .then((result) => {
        setList(result);
        setnumberofappointments(result.length);
        // search();
        if (appointmentStatus == 1) {
          const comments = result.filter(
            (val) => val.status === 1 || val.status === 8
          );
          const commentlength = comments.length;
          setLength(commentlength);
          return;
        }
      })
      .catch((error) => {});

    // fetchPatientData(data);
  };

  const searchStatuses = (status) => {
    let payload = {
      userId: userData.code,
      fromDate: moment(startDate).format("MM/DD/yyyy"),
      toDate: moment(endDate).format("MM/DD/yyyy"),
      photoRequired: "Y",
      status: status,
      consultationsType: appointmentType,
    };
    if (payload.consultationsType == "") {
      delete payload.consultationsType;
    }

    appointmentFilter(payload);
  };

  const searchDate = (value) => {
    console.log(value);
    let payload = {
      userId: userData.code,
      fromDate: moment(startDate).format("MM/DD/yyyy"),
      toDate: moment(endDate).format("MM/DD/yyyy"),
      photoRequired: "Y",
      status: appointmentStatus,
      consultationsType: appointmentType,
    };
    if (payload.consultationsType == "") {
      delete payload.consultationsType;
    }
    appointmentFilter(payload);
  };

  const handleResize = () => {
    console.log("slkcldsjbcvkdsv");
    if (window.innerWidth >= 1030) {
      setScreen({
        isLaptop: true,
        isMobile: false,
        isTablet: false,
      });
    } else if (window.innerWidth >= 767 && window.innerWidth <= 1023) {
      setScreen({
        isLaptop: false,
        isMobile: false,
        isTablet: true,
      });
    } else {
      setScreen({
        isLaptop: false,
        isMobile: true,
        isTablet: false,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  useEffect(() => {
    loadAppointmentsUpcoming();
    setAppointmentStatus(1);
    // searchStatuses(e.target.value);
    setShowLeft(false);
    dispatch(gethospitalclinicList(userData.code));
  }, []);
  const loadAppointmentsUpcoming = () => {
    let payload = {
      userId: userData.code,
      photoRequired: "Y",
      status: "",
      fromDate: moment(startDate).format("MM/DD/yyyy"),
      toDate: moment(endDate).format("MM/DD/yyyy"),
      // consultationsType: appointmentType,
      //pageNumber: 10
    };
    // if (payload.consultationsType == "") {
    //   delete payload.consultationsType;
    // }
    appointmentFilter(payload);
  };
  useEffect(() => {
    // loadAppointments();
    dispatch(gethospitalclinicList(userData.code));
    let data = {
      patientId: 0,
    };

    // fetchPatientData(data);
    /*dispatch(getupcomingAppointmentList(userData.code, "", ""))
    setnumberofappointments(UpcomingappointmentlistData.length)*/
  }, [UpcomingappointmentlistData.length]);

  const loadAppointments = () => {
    let payload = {
      userId: userData.code,
      photoRequired: "Y",
      status: appointmentStatus,
      fromDate: moment(startDate).format("MM/DD/yyyy"),
      toDate: moment(endDate).format("MM/DD/yyyy"),
      consultationsType: appointmentType,
      //pageNumber: 10
    };
    if (payload.consultationsType == "") {
      delete payload.consultationsType;
    }
    appointmentFilter(payload);
  };

  const viewPrescription = (e, user) => {
    e.preventDefault();
    console.log(user);
    user.hidesubmit = true;
    history.push({ pathname: APP_ROUTES.CONSULATATION_REPORT, state: user });
  };

  const editPrescription = (e, user) => {
    e.preventDefault();
    let currentDate = new Date();
    // let appointmentDate = new Date(user.whenAppointment);
    // if (
    //   appointmentDate.getDate() === currentDate.getDate() &&
    //   appointmentDate.getMonth() === currentDate.getMonth() &&
    //   appointmentDate.getFullYear() === currentDate.getFullYear()
    // ) {
    setShowLeft(false);
    console.log(user);
    history.push({ pathname: APP_ROUTES.VIDEO_CALL, state: user });
    // } else {
    //   toast("Not Today's Consultation");
    //   return;
    // }
    // e.preventDefault();
    // console.log(user);
    // user.hidesubmit = true;
    // history.push({ pathname: APP_ROUTES.CONSULATATION_REPORT, state: user });
  };

  const filterAppointments = (payload) => {};

  const fetchPatientData = (data) => {
    console.log(data);
    localStorage.setItem("activeAppointment", JSON.stringify(data));
    console.log("hello", data.patientId);
    setComplain(data);
    dispatch(getPatientReports(data.patientId));
    dispatch(getPatientdetails(data.patientId));
    dispatch(getPatientvitallist(data.patientId));
    dispatch(getPatientPHRReportss(data.patientId));
    setOpenModal(true);
    if (data.status == 1 || data.status == 2) {
      setShowLeft(true);
    } else {
      setShowLeft(false);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const redirectTo = (e, user) => {
    if (user.status == "3" || user.status == "2") {
    } else {
      e.preventDefault();
      let currentDate = new Date();
      let appointmentDate = new Date(user.whenAppointment);
      // if (
      //   appointmentDate.getDate() === currentDate.getDate() &&
      //   appointmentDate.getMonth() === currentDate.getMonth() &&
      //   appointmentDate.getFullYear() === currentDate.getFullYear()
      // ) {
      setShowLeft(false);
      console.log(user);
      history.push({ pathname: APP_ROUTES.VIDEO_CALL, state: user });
      // } else {
      //   toast("Not Today's Consultation");
      //   return;
      // }
    }
  };

  // const search = (value) => {
  //   let val = value ? value : "";
  //   // @ts-ignore
  //   var newArr = list;
  //   console.log("newAR", newArr);

  //   // @ts-ignore
  //   let newArray = newArr.filter(
  //     (data) =>
  //       JSON.stringify(data).toLowerCase().indexOf(val.toLowerCase()) !== -1
  //   );

  //   setList(newArray);
  // };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    searchDate();
  }, [startDate, endDate]);

  useEffect(() => {
    //if (UpcomingappointmentlistData.length > 0)
    // search();
  }, [UpcomingappointmentlistData.length]);

  const patientDataDetails = (user) => {
    return (
      <>
        <ToastContainer />
        {result && (
          <div
            className={`lg:block  ${
              screen.isLaptop ? "w-1/4" : "w-full"
            }   border boder-gray-600 shadow-sm p-4 font-rubik bg-white`}
          >
            <div className="flex">
              {user.photoName ? (
                <img
                  src={IMG_URL + user.photoName}
                  alt="profile image"
                  className="w-10 rounded-full h-10 mb-2"
                />
              ) : (
                <div className="bg-white p-3 w-20  flex justify-center    font-rubik  rounded-lg">
                  <span className="font-bold text-2xl">
                    {user?.firstName?.charAt(0)}
                  </span>
                </div>
              )}

              <div>
                <div className="pl-3 flex space-x-20">
                  <p className="text-sm text-gray-700 font-medium">
                    {user.firstName} {user.lastName}
                  </p>
                  {result.consultationsType === "V" ? (
                    <img
                      src={vc}
                      alt="video call icon"
                      className="w-6 cursor-pointer "
                    />
                  ) : (
                    ""
                  )}
                  {result.consultationsType === "I" ? (
                    <img
                      src={ip}
                      alt="inperson icon"
                      className="w-6 cursor-pointer "
                    />
                  ) : (
                    ""
                  )}
                  {result.consultationsType === "A" ? (
                    <img
                      src={ad}
                      alt="audio call icon"
                      className="w-6 cursor-pointer "
                    />
                  ) : (
                    ""
                  )}

                  {result.consultationsType === "Q" ? (
                    <img
                      src={qf}
                      alt="audio call icon"
                      className="w-6 cursor-pointer "
                    />
                  ) : (
                    ""
                  )}
                </div>
                <p className="text-xs font-medium text-gray-700 pl-3">
                  Appt No. :
                  <span className="text-xs pl-1 font-normal text-gray-500">
                    {result.id}
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-3 flex justify-between">
              <button
                onClick={(e) => refer(e, user)}
                className="border border-brand-secondary  text-xs text-brand-secondary rounded-md py-2 px-6"
              >
                Medical Records
              </button>

              <button
                onClick={(e) => redirectTo(e, result)}
                disabled={result.status == "3" || result.status == "2"}
                className="bg-brand-secondary text-xs text-white font-normal rounded-md py-2 px-6"
              >
                Join Now
              </button>
            </div>
            <hr className="mt-4 mb-3" />
            <p className="text-base text-gray-700 font-medium pb-2">
              General Information
            </p>
            <div className="flex justify-between">
              <p className="text-sm text-gray-400">Date of birth</p>
              <p className="text-sm text-gray-500">
                {moment(user.dob).format("DD/MM/yyyy")}
              </p>
            </div>
            <div className="flex justify-between pt-3">
              <p className="text-sm text-gray-400">Gender</p>
              <p className="text-sm text-gray-500">
                {user.gender == "M"
                  ? "Male"
                  : user.gender == "F"
                  ? "Female"
                  : "Others"}
              </p>
            </div>
            <div className="flex justify-between pt-3">
              <p className="text-sm text-gray-400">Location</p>
              <p className="text-sm text-gray-500">{user.city}</p>
            </div>
            {/* <div className="flex justify-between pt-3">
                    <p className="text-sm text-gray-400">Preferred Language</p>
                    <p className="text-sm text-gray-500">{user.pLanguage}</p>
                  </div> */}
            <div className="flex justify-between pt-3">
              <p className="text-sm text-gray-400">Phone Number</p>
              <p className="text-sm text-gray-500">{user.mobile}</p>
            </div>
            <div className="flex justify-between pt-3">
              <p className="text-sm text-gray-400">Email ID</p>
              <p className="text-sm text-gray-500">{user.email}</p>
            </div>
            <div className="flex justify-between pt-3">
              <p className="text-sm text-gray-400">Chief Complaint</p>
              <p className="text-sm text-gray-500">
                {result.consultationsReason}
              </p>
            </div>
            <hr className="m-2" />
            {/* <div className="flex justify-between pt-4">
                    <p className="text-base text-gray-700 font-medium pb-2 font-rubik">Selected Reports</p>
                    <p className="text-brand-secondary text-xs pb-2">See all</p>
                  </div> */}
            {/* {patientPHRData.slice(0, 3).map((lab, i) => (
                    <div>
                      <div className="flex justify-between">
                        <p className="text-xs text-gray-400">{lab.reportType}</p>
                        <div className="flex space-x-4">
                          <button className="text-brand-secondary text-xs font-medium">View</button>
                          <img src={dwnld} alt="download" className="w-5" />
                        </div>
                      </div>
                    </div>
                  ))} */}
            {/* <hr className="m-2" /> */}

            {/* {patientFromLs
                    .slice(0, 1)
                    .map((user, i) => ( */}
            {vitalslistData.slice(0, 1).map((vitals, i) => (
              <div key={i}>
                <div className="flex justify-between pt-5">
                  <div className="flex space-x-1">
                    <p className="text-base text-gray-900 font-medium pb-2 font-montserrat">
                      Blood Pressure
                    </p>
                  </div>
                </div>
                <div className="flex justify-between">
                  <p className="text-brand-secondary text-sm font-medium">
                    SYS
                  </p>
                  <img src={heart} alt="heart" />
                  <p className="text-brand-secondary text-sm font-medium">
                    DIA
                  </p>
                  <img src={heart} alt="heart" />
                </div>
                <div className="flex justify-between pt-3">
                  <p className="text-brand-secondary text-lg">
                    {vitals.systolic}{" "}
                    <span className="text-brand-secondary text-base">
                      mm/Hg
                    </span>
                  </p>
                  <p className="text-brand-secondary text-lg">
                    {vitals.diastolic}{" "}
                    <span className="text-brand-secondary text-base">
                      mm/Hg
                    </span>{" "}
                  </p>
                </div>

                <div className="flex justify-between pt-5">
                  <p className="text-base text-gray-900 font-medium pb-2 font-montserrat">
                    Height
                  </p>
                  <p className="text-brand-secondary text-lg">
                    {vitals.height}{" "}
                    <span className="text-brand-secondary text-base">cm</span>
                  </p>
                </div>

                <div className="flex justify-between pt-3">
                  <p className="text-base text-gray-900 font-medium pb-2 font-montserrat">
                    Weight
                  </p>
                  <p className="text-brand-secondary text-lg">
                    {vitals.weight}{" "}
                    <span className="text-brand-secondary text-base">Kg</span>{" "}
                  </p>
                </div>

                <div className="flex justify-between pt-3">
                  <p className="text-base text-gray-900 font-medium pb-2 font-montserrat">
                    Heart Rate
                  </p>
                  <p className="text-brand-secondary text-lg">
                    {vitals.heartRate}{" "}
                    <span className="text-brand-secondary text-base">
                      Beats/min
                    </span>
                  </p>
                </div>

                <div className="flex justify-between pt-3">
                  <p className="text-base text-gray-900 font-medium pb-2 font-montserrat">
                    Respiration Rate
                  </p>
                  <p className="text-brand-secondary text-lg">
                    {vitals.respirationRate}{" "}
                    <span className="text-brand-secondary text-base">
                      Breaths/min
                    </span>{" "}
                  </p>
                </div>

                <div className="flex justify-between pt-3">
                  <p className="text-base text-gray-900 font-medium pb-2 font-montserrat">
                    Pulse Ox.
                  </p>
                  <p className="text-brand-secondary text-lg">
                    {vitals.spo2}{" "}
                    <span className="text-brand-secondary text-base">
                      Percentage
                    </span>
                  </p>
                </div>

                {/* <div className="flex justify-between pt-3">
                <p className="text-base text-gray-900 font-medium pb-2 font-montserrat">
                  Blood Glucose
                </p>
                <p className="text-brand-secondary text-lg">
                  {vitals.bloodGlucose}{" "}
                  <span className="text-brand-secondary text-base">
                    mg/dL
                  </span>{" "}
                </p>
              </div> */}

                <div className="flex justify-between pt-3">
                  <p className="text-base text-gray-900 font-medium pb-2 font-montserrat">
                    Temperature
                  </p>
                  <p className="text-brand-secondary text-lg">
                    {vitals.temperature}{" "}
                    <span className="text-brand-secondary text-base">°F</span>
                  </p>
                </div>
              </div>
            ))}
            {showRequestAccess && (
              <RequestAccess
                onClose={closeRequestAccess}
                closePopup={closeRequestAccess}
                requestedAccess={requestedAccess}
                appointmentObj={result}
              ></RequestAccess>
            )}
          </div>
        )}
      </>
    );
  };
  const onAppointemntRefresh = (e) => {
    e.preventDefault();
    loadAppointmentsUpcoming();
    setAppointmentStatus(1);
    // searchStatuses(e.target.value);
    setShowLeft(false);
    dispatch(gethospitalclinicList(userData.code));
  };
  console.log("newAR123", list);
  if (
    loggedInData.isLoading ||
    upcomingappointmentlist.isLoading ||
    patientdetailslist.isLoading ||
    vitalslist.isLoading ||
    hospitalcliniclist.isLoading
  ) {
    return (
      <div
        className="flex justify-center items-center"
        style={{ height: "50vh" }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className="lg:flex justify-between mx-10 mt-5">
        <div className="flex">
          {appointmentStatus == 1 ? (
            <p className="text-lg text-gray-900 font-medium pb-2 font-rubik">
              Appointments ({lengthdata})
            </p>
          ) : (
            <p className="text-lg text-gray-900 font-medium pb-2 font-rubik">
              Appointments ({list.length})
            </p>
          )}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 sm:space-x-2 gap-4">
          <div className="grid-flow-row auto-rows-max">
            <p className="px-2 text-sm">Consultation</p>
            <div className="flex justify-between px-2 w-30 md:w-40 py-2 bg-white rounded-lg shadow">
              <select
                className="w-full outline-none bg-transparent"
                id="greet"
                onChange={handleChangeApptype}
                value={appointmentType}
              >
                <option className="py-1 text-sm text-green-600" value={""}>
                  All
                </option>

                {apptype.map((user, i) => (
                  <>
                    <option
                      className="py-1 text-sm"
                      value={user.value}
                      //selected={appointmentStatus == user.value ? true : false}
                    >
                      {user.name}
                    </option>
                  </>
                ))}
              </select>
            </div>
          </div>
          <div className="grid-flow-row auto-rows-max">
            <p className="px-2 text-sm">Status</p>
            <div className="flex justify-between px-2 w-30 md:w-40 py-2 bg-white rounded-lg shadow">
              <select
                className="w-full outline-none bg-transparent"
                id="greet"
                onChange={handleChangeStatus}
              >
                <option className="py-1 text-sm text-green-600" value={""}>
                  All
                </option>

                {statuses.map((user, i) => (
                  <>
                    <option
                      className="py-1 text-sm"
                      value={user.value}
                      selected={appointmentStatus == user.value ? true : false}
                    >
                      {user.name}
                    </option>
                  </>
                ))}
              </select>
            </div>
          </div>
          <div className="grid-flow-row auto-rows-max">
            <p className="px-2 text-sm">Hospital/Clinic</p>
            <div className="flex justify-between px-2 w-30 md:w-40 py-2 bg-white rounded-lg shadow">
              <select
                className="w-full outline-none bg-transparent"
                id="greet"
                onChange={handleChange}
              >
                <option
                  className="py-1 text-sm text-green-600"
                  value={"all"}
                  selected
                >
                  All
                </option>

                {hospitalcliniclistData.map((user, i) => (
                  <>
                    <option className="py-1 text-sm" value={user.hospitalName}>
                      {user.hospitalName}
                    </option>
                  </>
                ))}
              </select>
            </div>
          </div>

          <div className="grid-flow-row auto-rows-max pt-4">
            <div className="lg:flex justify-between lg:w-54 bg-white rounded-lg shadow">
              <DatePicker
                minDate={moment().format("DD/MM/yyyy")}
                // dateFormat={"DD/MM/yyyy"}
                placeholderText="select Date"
                value={startDate ? moment(startDate).format("DD/MM/yyyy") : ""}
                className="bg-transparent text-center p-2 w-full"
                onChange={(date) => {
                  setStartDate(moment(date).format("MM/DD/yyyy"));
                }}
              />

              <DatePicker
                minDate={moment().format("DD/MM/yyyy")}
                // dateFormat={"DD/MM/yyyy"}
                placeholderText="select Date"
                value={endDate ? moment(endDate).format("DD/MM/yyyy") : ""}
                className="bg-transparent text-center p-2 w-full"
                onChange={(date) => {
                  setEndDate(moment(date).format("MM/DD/yyyy"));
                }}
              />

              <img
                onClick={onAppointemntRefresh}
                src={refresh}
                alt="refresh"
                className="w-8 h-6 pl-2 mt-2 rounded-full  mb-2"
              />
            </div>
            {/* <div className="grid-flow-row auto-rows-max pt-4">
              <img onClick={onAppointemntRefresh} src={refresh} alt="refresh" className="w-8 h-6 pl-2 mt-2 rounded-full  mb-2" />
            </div> */}
          </div>
        </div>
      </div>
      <div className="px-10 py-5 flex space-x-4 justify-center sm:justify-start">
        {appointmentStatus == 1 ? (
          <div className="w-full lg:w-3/4">
            {list.filter((x) => x.status === 1 || x.status === 8).length ===
            0 ? (
              <p className="text-center item-center mt-10 ">
                No appointments available
              </p>
            ) : (
              <div>
                <div
                  className={`${
                    screen.isTablet ? "flex justify-start flex-wrap" : "block"
                  }`}
                >
                  {list
                    .filter((x) => x.status === 1 || x.status === 8)
                    .map((user, i) => (
                      // list.map((user, i) => (
                      <div
                        onClick={() => fetchPatientData(user)}
                        className=" ml-0 sm:ml-2 m-2  col-span-2 cursor-pointer w-full h-auto rounded-lg overflow-hidden w-3/4 md:w-2/5 lg:w-full  h-52 border border-green-500 shadow-sm p-4 font-rubik mb-6"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm text-green-500">
                            {user.fromTime} - {user.toTime} ({" "}
                            {moment(user.whenAppointment).format("DD/MM/yyyy")}{" "}
                            )
                          </p>
                          {user.consultationsType === "V" ? (
                            <img
                              onClick={(e) => redirectTo(e, user)}
                              src={vc}
                              alt="video call icon"
                              className="w-6 cursor-pointer "
                            />
                          ) : (
                            ""
                          )}
                          {user.consultationsType === "Q" ? (
                            <img
                              onClick={(e) => redirectTo(e, user)}
                              src={qf}
                              alt="quick call icon"
                              className="w-6 cursor-pointer "
                            />
                          ) : (
                            ""
                          )}
                          {user.consultationsType === "I" ? (
                            <img
                              onClick={(e) => redirectTo(e, user)}
                              src={ip}
                              alt="in-person icon"
                              className="w-6 cursor-pointer "
                            />
                          ) : (
                            ""
                          )}
                          {user.consultationsType === "A" ? (
                            <img
                              onClick={(e) => redirectTo(e, user)}
                              src={ad}
                              alt="in-person icon"
                              className="w-6 cursor-pointer "
                            />
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="lg:flex justify-between pt-3 ">
                          <div className="flex">
                            {user.patientPhotoName ? (
                              <img
                                src={IMG_URL + user.patientPhotoName}
                                alt="profile image"
                                className="w-10 rounded-full h-10 mb-2"
                              />
                            ) : (
                              <div className="bg-white w-10  flex justify-center rounded-full font-rubik  rounded-lg">
                                <span className="font-bold text-2xl">
                                  {user?.patientName?.charAt(0)}
                                </span>
                              </div>
                            )}
                            {/* <img
                          src={
                            user.patientPhotoName
                              ? IMG_URL + user.patientPhotoName
                              : vijay
                          }
                          alt="profile image"
                          className="w-10 rounded-full h-10 mb-2"
                        /> */}
                            <div className="pl-3">
                              <p className="text-sm text-gray-700 font-medium">
                                {user.patientName}
                              </p>
                              <p className="text-xs font-medium text-gray-700">
                                Chief Complaint:
                                <span className="text-xs pl-1 font-normal text-gray-500">
                                  {user.consultationsReason}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div>
                            <p className="text-xs font-medium text-gray-700">
                              Appt No. :
                              <span className="text-xs pl-1 font-normal text-gray-500">
                                {user.id}
                              </span>
                            </p>
                            <p className="text-xs font-medium text-gray-700">
                              Hospital:
                              <span className="text-xs pl-1 font-normal text-gray-500">
                                {user.hospitalName}
                              </span>
                            </p>
                            {user.status == "2" ? (
                              <p className="text-xs font-medium text-gray-700">
                                <span
                                  onClick={(e) => viewPrescription(e, user)}
                                  className="text-xs  font-bold curser"
                                >
                                  View Prescription
                                </span>
                                <span
                                  onClick={(e) => editPrescription(e, user)}
                                  className="text-xs  font-bold curser ml-3"
                                >
                                  Edit Prescription
                                </span>
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            <br />
          </div>
        ) : (
          <div className="w-full lg:w-3/4">
            {list.length === 0 ? (
              <p className="text-center item-center mt-10 ">
                No appointments available
              </p>
            ) : (
              <div>
                <div
                  className={`${
                    screen.isTablet ? "flex justify-start flex-wrap" : "block"
                  }`}
                >
                  {
                    //list.filter(x => x.status === 1 || x.status === 8).map((user, i) => (
                    list.map((user, i) => (
                      <div
                        onClick={() => fetchPatientData(user)}
                        className=" ml-0 sm:ml-2 m-2  col-span-2 cursor-pointer w-full h-auto rounded-lg overflow-hidden w-3/4 md:w-2/5 lg:w-full  h-52 border border-green-500 shadow-sm p-4 font-rubik mb-6"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm text-green-500">
                            {user.fromTime} - {user.toTime} ({" "}
                            {moment(user.whenAppointment).format("DD/MM/yyyy")}{" "}
                            )
                          </p>
                          {user.consultationsType === "V" ? (
                            <img
                              onClick={(e) => redirectTo(e, user)}
                              src={vc}
                              alt="video call icon"
                              className="w-6 cursor-pointer "
                            />
                          ) : (
                            ""
                          )}
                          {user.consultationsType === "Q" ? (
                            <img
                              onClick={(e) => redirectTo(e, user)}
                              src={qf}
                              alt="quick call icon"
                              className="w-6 cursor-pointer "
                            />
                          ) : (
                            ""
                          )}
                          {user.consultationsType === "I" ? (
                            <img
                              onClick={(e) => redirectTo(e, user)}
                              src={ip}
                              alt="in-person icon"
                              className="w-6 cursor-pointer "
                            />
                          ) : (
                            ""
                          )}
                          {user.consultationsType === "A" ? (
                            <img
                              onClick={(e) => redirectTo(e, user)}
                              src={ad}
                              alt="in-person icon"
                              className="w-6 cursor-pointer "
                            />
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="lg:flex justify-between pt-3 ">
                          <div className="flex">
                            {user.patientPhotoName ? (
                              <img
                                src={IMG_URL + user.patientPhotoName}
                                alt="profile image"
                                className="w-10 rounded-full h-10 mb-2"
                              />
                            ) : (
                              <div className="bg-white w-10  flex justify-center rounded-full font-rubik  rounded-lg">
                                <span className="font-bold text-2xl">
                                  {user?.patientName?.charAt(0)}
                                </span>
                              </div>
                            )}
                            {/* <img
                            src={
                              user.patientPhotoName
                                ? IMG_URL + user.patientPhotoName
                                : vijay
                            }
                            alt="profile image"
                            className="w-10 rounded-full h-10 mb-2"
                          /> */}
                            <div className="pl-3">
                              <p className="text-sm text-gray-700 font-medium">
                                {user.patientName}
                              </p>
                              <p className="text-xs font-medium text-gray-700">
                                Chief Complaint:
                                <span className="text-xs pl-1 font-normal text-gray-500">
                                  {user.consultationsReason}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div>
                            <p className="text-xs font-medium text-gray-700">
                              Appt No. :
                              <span className="text-xs pl-1 font-normal text-gray-500">
                                {user.id}
                              </span>
                            </p>
                            <p className="text-xs font-medium text-gray-700">
                              Hospital:
                              <span className="text-xs pl-1 font-normal text-gray-500">
                                {user.hospitalName}
                              </span>
                            </p>
                            {user.status == "2" ? (
                              <p className="text-xs font-medium text-gray-700">
                                <span
                                  onClick={(e) => viewPrescription(e, user)}
                                  className="text-xs  font-bold curser"
                                >
                                  View Prescription
                                </span>
                                <span
                                  onClick={(e) => editPrescription(e, user)}
                                  className="text-xs  font-bold curser ml-3"
                                >
                                  Edit Prescription
                                </span>
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            )}
            <br />
          </div>
        )}
        {/* card 2 */}
        {numberofappointments === 0
          ? null
          : showLeft && (
              <>
                {patientdetailsData.slice(0, 1).map((user, i) => (
                  <>
                    {screen.isMobile || screen.isTablet ? (
                      <Dialog
                        className="absolute overflow-scroll w-full h-3/4 pr-3 pl-3"
                        style={{ top: "15%" }}
                        open={openModal}
                        onClose={handleClose}
                        // aria-labelledby="simple-modal-title"
                        // aria-describedby="simple-modal-description"
                      >
                        {/* {body} */}
                        {patientDataDetails(user)}
                      </Dialog>
                    ) : (
                      patientDataDetails(user)
                    )}
                  </>
                ))}
              </>
            )}
      </div>
      {/*true && (
            <div className="my-6 flex lg:flex-wrap pb-4 ">
            <button
              onClick={() => changePage("previous")}
              disabled={currentPageNumber === 1}
              type="button"
              className="disabled:opacity-50 border rounded-lg w-20 h-12 pl-5">
              <ChevronLeftIcon className="w-8" />
            </button>
            {pages.map((res, i) => (
              <button
                onClick={() => {
                  getcurrentPageNumber(res);
                }}
                key={i}
                type="button"
                className={`${currentPageNumber == res
                  ? "bg-green-500"
                  : "border border-brand-biscay2"
                  } rounded-lg border w-28 h-12 mx-3`}
              >
                <p
                  className={`font-rubik ${currentPageNumber != res
                    ? "text-brand-biscay2"
                    : "text-white"
                    } font-medium text-sm`}
                >
                  {res}
                </p>
              </button>
            ))}
            <button
              onClick={() => changePage("next")}
              type="button"
              className="disabled:opacity-50 border rounded-lg w-20 h-12 pl-5">
              <ChevronRightIcon className="w-8" />
            </button>
          </div>
  
          )*/}
    </>
  );
}
export default AppointmentTwo;
