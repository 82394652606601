import React, { useEffect, useReducer, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import newLogo from '../Assets/Images/newLogo.png'
import Sign from '../Assets/Images/signature.webp'
import Stamp from '../Assets/Images/stamp.webp'
import { jsPDF } from "jspdf";
import moment from 'moment';
import { IMG_URL } from "../config/constant";
import PriscriptionService from '../Redux/services/priscription';

function PostConsultation() {
    const { postConsultation } = useSelector(state => state.doctorconsultationlist);
    const { patientLabTestsList, patientDrugPrescriptionList } = postConsultation;
    const [labTestList, setLabTestList] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const [stamp, setStamp] = useState([]);
    const [signature, setSignature] = useState([]);
    const download = () => {
        const input = document.getElementById("abc");
        const pdf = new jsPDF({ orientation: 'portrait', unit: "px", format: "A4", userUnit: "px" });
        pdf.html(input, { html2canvas: { scale: 0.38 } }).then(() => {
            pdf.save("test.pdf");
            history.goBack();
        });
    }
    useEffect(() => {
        // data:image/jpg;base64,
        // `${"data:image;base64,"}` + profile.photo

        PriscriptionService.getBase64Img(postConsultation?.userSignature).then(
            (res) => {
                if (res.data) {
                    setSignature('data:image/jpg;base64,' + res.data.fileData);
                    console.log(signature)
                }
            },
            (err) => {
                console.log(err);
            }
        );

        PriscriptionService.getBase64Img(postConsultation?.hospitalStamp).then(
            (res) => {
                if (res.data) {
                    setStamp('data:image/jpg;base64,' + res.data.fileData);
                }
            },
            (err) => {
                console.log(err);
            }
        );

    }, [])

    useEffect(() => {
        if(patientLabTestsList && patientLabTestsList.length) {
            const labData = patientLabTestsList;
            const unique = [...new Set(labData.map(item => item.labParentName))];
            console.log(unique);
            const labArray = [];
            for(let s=0; s < unique.length; s++) {
              const labFilter = labData.filter(
                (x) => x.labParentName == unique[s]
              );
              const payload = {
                parent: unique[s],
                data: labFilter
              }
              labArray.push(payload);
            }
    
            console.log(labArray);
            setLabTestList(labArray);
            
          }
     
    }, [patientLabTestsList])

    useEffect(() => {
        setTimeout(() => {
             download()
        }, 1000)
    }, [])


    return (
        <>

            <div id="abc" className="bg-white-100 my-10 justify-center mb-24">
                <div className="px-2 lg:px-80">
                    <div className="flex justify-between mb-4 mr-20 -ml-1">
                        <img onClick={download} src={newLogo} width={230} />
                        <p className="text-lg font-semibold mt-3">Date - {moment(postConsultation?.prescription?.createdDate).format('DD/MM/yyyy')}</p>
                    </div>

                    <div className="lg:flex mb-3 justify-between">
                        <h1 className="text-2xl font-semibold ">E- Prescription -  {postConsultation.userSalutation} {postConsultation.userName}</h1>

                    </div>
                    <div className="">
                        <div className="lg:w-4/6 lg:mr-12">
                            <div className="mb-5">
                                <p className="text-lg font-medium mb-3">{postConsultation.patientName}’s Consultation Summary</p>
                            </div>
                            <div>
                                <p className="text-lg font-medium mb-3">Cheif Complaint</p>
                                <p className="text-base font-montserrat  font-thin mb-3">{postConsultation.consultReason}</p>
                            </div>
                            <div>
                                <p className="text-lg font-medium mb-3">Primary Diagnosis</p>
                                <p className="text-base font-montserrat  font-thin mb-3">ICD-10 : {postConsultation.primarySymptoms} </p>
                                <p className="text-base font-montserrat  font-thin mb-3">Description : {postConsultation.primaryDiagnosis} </p>
                            </div>
                            <div>
                                <p className="text-lg font-medium mb-3">Secondary Diagnosis</p>
                                <p className="text-base font-montserrat  font-thin mb-3">ICD-10 : {postConsultation.secondarySymptoms} </p>
                                <p className="text-base font-montserrat  font-thin mb-3">Description : {postConsultation.secondaryDiagnosis} </p>
                            </div>
                            <div>
                                <p className="text-lg font-medium mb-3">Notes and recommendation</p>
                                <p className="text-base font-montserrat  font-thin mb-3">{postConsultation.recommendation}</p>
                            </div>
                        </div>
                        <div>
                            <p className="text-lg font-medium mb-3">
                                Scheduled follow up visit date
                            </p>
                            {postConsultation?.followUpVisitDate &&
                                <p className="text-base font-montserrat  font-thin mb-3">
                                    {moment(postConsultation?.followUpVisitDate).format('DD/MM/YYYY')}
                                </p>}
                        </div>
                        <div className="">
                            <p className="text-lg font-rubik font-medium mb-1">Medicines Prescribed</p>
                            {patientDrugPrescriptionList && patientDrugPrescriptionList.length > 0 && patientDrugPrescriptionList.map((res, i) => (
                                <div className='w-7/12' key={i}>
                                    <div >
                                        <div className="text-xs text-gray-primary font-medium pt-3">{res.medicineType !== "Rx" && res.medicineType !== "" ? (
                                            <div class="h-6 w-10 pl-2 pr-1 bg-green-400 rounded-2xl flex">
                                                <p className='text-xs text-white'>{res.medicineType}</p>

                                            </div>) : <span></span>}</div>

                                    </div>
                                    <div >
                                        <div className="text-md text-gray-primary font-medium pt-1">{res.drugName}</div>

                                    </div>
                                    <div className='flex justify-between pb-2 border-b-2'>
                                        <div className="text-md text-gray-primary font-medium pt-3">Qty : {res.quantity}</div>
                                        <div className="text-md text-gray-primary font-medium pt-3">{res.dosage} </div>
                                        <div className="text-md text-gray-primary font-medium pt-3">{res.afterBeforeFood === "0" ? 'After Meal' : 'Before Meal'} </div>
                                        <div className="text-md text-gray-primary font-medium pt-3">{res.duration} days</div>

                                    </div></div>



                            ))}
                            {/* <div className="mt-2 w-1/2 ">
                                <p className="text-base font-rubik font-medium mb-2">Medicines Prescribed</p>
                                <div className="grid grid-cols-5 lg:space-x-8  lg:justify-start justify-around" style={{ width: 520 }}>
                                    <div className="text-sm font-medium font-rubik text-brand-manatee ">Medicine Name</div>
                                    <div className="text-sm font-medium font-rubik text-brand-manatee">Dosage</div>
                                    <div className="text-sm font-medium font-rubik text-brand-manatee">Instructions</div>
                                    <div className="text-sm font-medium font-rubik text-brand-manatee">Durations</div>
                                    <div className="text-sm font-medium font-rubik text-brand-manatee">Quantity</div>
                                </div>
                                {patientDrugPrescriptionList && patientDrugPrescriptionList.length > 0 && patientDrugPrescriptionList.map((res, i) => (
                                    <div className="grid grid-cols-5 lg:space-x-8  lg:justify-start justify-around mt-2" style={{ width: 520 }}>
                                        <div className="text-xs  font-medium font-montserrat text-gray-primary">{res.type != "Rx" ? ( <div class="h-6 w-10 pl-2 pr-1 bg-green-400 rounded-2xl flex">
                                        <p class="text-xs text-white pt-1">
                            {res.medicineType} 
                            </p>
                          </div>) : <span></span> }{res.drugName} </div>
                                        <div className="text-xs font-medium tracking-widest font-montserrat text-gray-primary">{res.dosage}</div>
                                        <div className="text-xs font-medium w-full font-montserrat text-gray-primary">{res.afterBeforeFood === "1" ? "Before Meal" : 'After Meal'}</div>
                                        <div className="text-xs font-medium font-montserrat text-gray-primary">{res.duration} days</div>
                                        <div className="text-xs font-medium font-montserrat text-gray-primary">{res.duration} Quantity</div>
                                    </div>
                                ))}
                            </div> */}
                            {/* <table className="border-collapse mt-7 table-auto">
                            <thead >
                                <tr >
                                    <th className="text-xs text-gray-400 pr-7">Medicine </th>
                                    <th className="text-xs text-gray-400  pl-12">Dosage </th>
                                    <th className="text-xs text-gray-400  pl-12">Instructions </th>
                                    <th className="text-xs text-gray-400  pl-12">Duration </th>
                                    <th className="text-xs text-gray-400  pl-12">Quantity </th>
                                </tr>
                            </thead>
                            <tbody>
                                {patientDrugPrescriptionList && patientDrugPrescriptionList.length > 0 && patientDrugPrescriptionList.map((res, i) => (
                                    <tr className='border-b-2 pb-2' key={i}>
                                        <td className="text-xs text-gray-primary font-medium pt-3 pl-4">{res.medicineType !== "Rx" ? ( <div class="h-6 w-12 pl-2 pb-7 pt-0 border border-2 border-green-500 rounded-2xl flex">
                                        <p class="text-xs text-black">
                            {res.medicineType} 
                            </p>
                          </div>) : <span></span> }{res.drugName}</td>
                                        <td className="text-xs text-gray-primary font-medium pt-10 pl-12">{res.dosage} </td>
                                        <td className="text-xs text-gray-primary font-medium pt-10 pl-12">{res.afterBeforeFood == "0" ? 'After Meal' : 'Before Meal'} </td>
                                        <td className="text-xs text-gray-primary font-medium pt-10 pl-12">{res.duration} days</td>
                                        <td className="text-xs text-gray-primary font-medium pt-10 pl-12">{res.quantity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
                            <div className="mt-2 w-2/3">
                                <p className="text-lg  font-rubik font-medium mb-2">Lab Test Prescribed</p>
                                {/* <div className="grid grid-cols-2  justify-start ">
                                    <div className="text-sm font-medium font-rubik text-brand-manatee">Test Name</div>
                                    <div className="text-sm font-medium font-rubik text-brand-manatee">Test Type</div>
                                </div>
                                {patientLabTestsList && patientLabTestsList.length > 0 && patientLabTestsList.map((res, i) => (
                                    <div className='border-b-2' key={i} className="grid grid-cols-2 border-b-2 justify-start mt-1">
                                        <div className="text-xs font-medium font-montserrat text-gray-primary">{res.labTestDescription}</div>
                                        <div className="text-xs font-medium font-montserrat text-gray-primary">Pathalogy</div>
                                    </div>
                                    
                                ))} */}
                                {/* <table className="w-full">
                                    <thead >
                                        <tr>
                                            <th className="text-md text-gray-400 text-left">Test Name</th>
                                            <th className="text-md text-gray-400 text-right">Test Type </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {patientLabTestsList && patientLabTestsList.length > 0 && patientLabTestsList.map((res, i) => (
                                            <tr className='border-b-2' key={i}>
                                                <td className="pb-3 text-md text-gray-primary font-medium pt-3">{res.labTestDescription}</td>
                                                <td className="pb-3 text-md text-gray-primary font-medium pt-3 text-right">Pathology </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table> */}


{labTestList &&
    labTestList.length > 0 &&
    labTestList.map((labres, i) => (  <div>
  <div className="grid grid-cols-2  justify-start ">
    <div className="text-md font-medium font-rubik text-brand-manatee">
     {labres.parent}
    </div>
    <div className="text-md font-medium font-rubik text-brand-manatee text-right">
      Test Type
    </div>
  </div>
  {labres &&
    labres.data.length > 0 &&
    labres.data.map((res, i) => (
      <div
        className="grid grid-cols-2 border-b-1 justify-start mt-1 pb-2 border-b-2 flex justify-between"
        key={i}
      >
        <div className="text-sm font-medium font-montserrat text-gray-primary">
          {res?.labTestDescription}
        </div>
        <div className="text-sm font-medium font-montserrat text-gray-primary text-right">
         Pathology
        </div>
      </div>
    ))}
    
  </div>

  ))}
  
                            </div>

                            <div className="flex justify-between  mr-20 -ml-1">
                                <div className="mt-6">
                                    <img src={signature} alt="" width={150} />
                                    <p className="font-rubik text-gray-primary text-base">Dr.{postConsultation.userName}</p>
                                    <p className="font-rubik text-gray-primary text-base">MCI No. {postConsultation.userMCIVerification}</p>
                                </div>
                                <div className="mt-10">
                                    <img src={stamp} alt="" width={150} />
                                </div>
                            </div>
                            <div className="mt-5">   
                                <p className="text-gray-500 text-sm "><b>Disclaimer :</b> This is an ONLINE consultation response. The patient has not been physically examined. The prescriptionor advice is based on the patient's description of the problem which is given above and also explained over video consultation </p>
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}
export default PostConsultation;
