export const PRIS = {
    REQUEST_MEDICINELIST: "REQUEST_MEDICINELIST",
    SUCCESS_MEDICINELIST: "SUCCESS_MEDICINELIST",
    FAILED_MEDICINELIST: "FAILED_MEDICINELIST",

    REQUEST_ADDPRESCRIPTION: "REQUEST_ADDPRESCRIPTION",
    SUCCESS_ADDPRESCRIPTION: "SUCCESS_ADDPRESCRIPTION",
    FAILED_ADDPRESCRIPTION: "FAILED_ADDPRESCRIPTION",

    REQUEST_GETLABTEST: "REQUEST_GETLABTEST",
    SUCCESS_GETLABTEST: "SUCCESS_GETLABTEST",
    FAILED_GETLABTEST: "FAILED_GETLABTEST",

    REQUEST_ADDREFERAL: "REQUEST_ADDREFERAL",
    SUCCESS_ADDREFERAL: "SUCCESS_ADDREFERAL",
    FAILED_ADDREFERAL: "FAILED_ADDREFERAL",

    REQUEST_DOCTORLIST: "REQUEST_DOCTORLIST",
    SUCCESS_DOCTORLIST: "SUCCESS_DOCTORLIST",
    FAILED_DOCTORLIST: "FAILED_DOCTORLIST",

    REQUEST_GETDIAGNOSIS: "REQUEST_GETDIAGNOSIS",
    SUCCESS_GETDIAGNOSIS: "SUCCESS_GETDIAGNOSIS",
    FAILED_GETDIAGNOSIS: "FAILED_GETDIAGNOSIS",

    REQUEST_PARENTGETLABTEST: "REQUEST_PARENTGETLABTEST",
    SUCCESS_PARENTGETLABTEST: "SUCCESS_PARENTGETLABTEST",
    FAILED_PARENTGETLABTEST: "FAILED_PARENTGETLABTEST"
}