import React, { useState, useEffect } from 'react'
import vc from '../Assets/Images/videoconsult.svg';
import inPerson from '../Assets/Images/chath.svg';
import vijay from '../Assets/Images/vijy.svg';
import audio from '../Assets/Images/audiocall.svg';
import refresh from '../Assets/Images/refresh-button.png';
import ip from '../Assets/Images/In-person.svg';
import hospclinic1 from '../Assets/Images/hc1.svg';
import { useDispatch, useSelector } from 'react-redux';
import { gethospitalclinicList, getAppointmentList, getupcomingAppointmentList } from '../Redux/Actions/patientAction';
import { Link, useHistory, useParams } from 'react-router-dom';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import moment from "moment";
import { IMG_URL } from '../config/constant';
import qf from "../Assets/Images/quickFill.png";

function UpcomingAppointment(props) {

  const history = useHistory();
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.authReducer);
  const upcomingappointmentlist = useSelector((state) => state.UpcomingAppointment);
  const { appointmentlistData } = useSelector((state) => state.appointmentlist);
  const { UpcomingappointmentlistData } = upcomingappointmentlist;
  const hospitalcliniclist = useSelector((state) => state.hospitalcliniclist);
  const { hospitalcliniclistData } = hospitalcliniclist;
  const [lengthdata, setLength] = useState("")

  useEffect(() => {

    var newDate = new Date();
    dispatch(getupcomingAppointmentList(userData.code, moment().format('MM/DD/yyyy'), moment().format('MM/DD/yyyy'))).then((result) => {
      const comments = result.filter(
        val => val.status === 1 || val.status === 8
      )
      const commentlength = comments.length
      setLength(commentlength)
    })
    dispatch(gethospitalclinicList(userData.code))
    // const comments = UpcomingappointmentlistData.filter(
    //   val => val.status === 1 || val.status === 8
    // )
    // const commentlength = comments.length
    // setLength(commentlength)
    // return;
    // dispatch(getAppointmentList(userData.code))
  }, []);

  const redirectTo = () => {
    history.push(APP_ROUTES.DOCTOR_SLOT_STAGE_ONE)
  }

  useEffect(() => {
    let female = appointmentlistData.filter(x => x.patientgender?.toLowerCase() === "female" || x.patientgender?.toLowerCase() === "f").length;
    let male = appointmentlistData.filter(x => x.patientgender?.toLowerCase() === "male" || x.patientgender?.toLowerCase() === "m").length;
    props.genderCount({ male: male, female: female });
    dispatch(getupcomingAppointmentList(userData.code, moment().format('MM/DD/yyyy'), moment().format('MM/DD/yyyy'))).then((result) => {
      const comments = result.filter(
        val => val.status === 1 || val.status === 8
      )
      const commentlength = comments.length
      setLength(commentlength)
    })
    dispatch(gethospitalclinicList(userData.code))
    // const comments = UpcomingappointmentlistData.filter(
    //   val => val.status === 1 || val.status === 8
    // )
    // const commentlength = comments.length
    // setLength(commentlength)
    // return;
  }, [appointmentlistData.length || UpcomingappointmentlistData.length || dispatch])

  useEffect(() => {

    if (UpcomingappointmentlistData.length == 0) {
      return (
        <div className="loader" />
      )
    }
  }, [UpcomingappointmentlistData.length || dispatch]);

  const onAppointemntRefresh = (e) => {
    e.preventDefault()
    var newDate = new Date();
    dispatch(getupcomingAppointmentList(userData.code, moment().format('MM/DD/yyyy'), moment().format('MM/DD/yyyy'))).then((result) => {
      const comments = result.filter(
        val => val.status === 1 || val.status === 8
      )
      const commentlength = comments.length
      setLength(commentlength)
    })
    dispatch(gethospitalclinicList(userData.code))
  }

  return (
    <>
      <div className="p-10 pb-0 pt-0 lg:pt-10 mb-5 lg:grid grid-cols-3 gap-4">
        {/* card 1 */}
        <div className="lg:col-span-2 w-full mb-5 h-auto rounded-2xl overflow-hidden border boder-gray-600 shadow-sm p-4 font-rubik">
          <div className="flex justify-between">
            <div className="flex justify-between">
              <p className="text-base text-gray-900 font-medium pb-2">{`Upcoming Appointments(${lengthdata})`} </p>
              {/* <img onClick={onAppointemntRefresh} src={refresh} alt="refresh" className="w-8 h-6 pl-2 rounded-full  mb-2" /> */}

            </div>
            <div className="flex justify-between">
              <p className="text-xs font-medium min-w-max text-gray-700 pb-2 self-center">{moment().format('DD/MM/yyyy')}</p>
              <img onClick={onAppointemntRefresh} src={refresh} alt="refresh" className="w-8 h-6 pl-2 rounded-full  mb-2" />
            </div>
          </div>

          {UpcomingappointmentlistData
            .filter((x) => x.status === 1 || x.status === 8).length === 0 ? <p className="text-center item-center mt-10">No upcoming appointments</p> :
            <div className="overflow-auto hide-scroll-bar h-52 ">
              {UpcomingappointmentlistData
                .filter((x) => x.status === 1 || x.status === 8)
                .map((user, i) => (
                  // user.status == 1 && user.status == 8 && (
                  <div className="pb-2 border-t text-xs text-gray-700">
                    <div className="flex justify-between mt-4">
                      <p className="text-sm text-green-500">{user.fromTime} - {user.toTime}</p>
                      <img onClick={() => history.push({ pathname: APP_ROUTES.VIDEO_CALL, state: user })} src={user.consultationsType === "V" ? vc : user.consultationsType === "A" ? audio : user.consultationsType === "I" ? ip : qf} alt="video call icon" className="w-6 cursor-pointer" />
                    </div>
                    <div className="flex justify-between pt-3">
                      <div className="flex">
                        {user.patientPhotoName ?
                          <img src={user.patientPhotoName ? IMG_URL + user.patientPhotoName : vijay} alt="profile image" className="w-10 rounded-full h-10 mb-2" /> :
                          <div className="bg-brand-indigo p-1 w-14 flex justify-center    font-rubik text-white rounded-lg">
                            <span className="font-bold text-2xl">{user.patientName.charAt(0)}</span>
                          </div>
                        }
                        <div className="pl-3">
                          <p className="text-sm text-gray-700 font-medium">{user.patientName}</p>
                          <p className="text-xs font-medium text-gray-700">Symptoms:<span className="text-xs pl-1 font-normal text-gray-500">{user.consultationsReason}</span></p>
                          <span className="block md:hidden" >
                            <p className="text-xs font-medium text-gray-700">Appt No. :<span className="text-xs pl-1 font-normal text-gray-500">{user.id}</span></p>
                            <p className="text-xs font-medium text-gray-700">Hospital:<span className="text-xs pl-1 font-normal text-gray-500">{user.hospitalName}</span></p>
                          </span>
                        </div>
                      </div>
                      <div className="hidden md:block" >
                        <p className="text-xs font-medium text-gray-700">Appt No. :<span className="text-xs pl-1 font-normal text-gray-500">{user.id}</span></p>
                        <p className="text-xs font-medium text-gray-700">Hospital:<span className="text-xs pl-1 font-normal text-gray-500">{user.hospitalName}</span></p>
                      </div>
                    </div>
                  </div>
                  // )
                ))}
            </div>
          }


        </div>

        {/* card 2 */}
        <div className=" w-full h-auto rounded-2xl mb-5 overflow-auto hide-scroll-bar  h-28 border boder-gray-600 shadow-sm p-4 font-rubik">
          <div className="flex justify-between">
            <p className="text-base text-gray-900 font-medium pb-2">Hospitals/Clinic</p>
            <p onClick={redirectTo} className="text-xs font-medium text-brand-secondary underline">Create Slots</p>
          </div>

          {hospitalcliniclistData.map((user, i) => (
            <div className="flex mb-4">
              {/* <img src={hospclinic1} alt="hospclinic1" className="w-14" /> */}
              <div className="bg-brand-indigo p-3 w-14 flex justify-center    font-rubik text-white rounded-lg">
                <span className="font-bold text-2xl">H</span>
              </div>
              <div className="pl-4 mt-2">
                <p className="text-xs font-medium text-gray-800">{user.hospitalName}</p>
                {/* <p className="text-xs text-gray-600 pt-1">Total Number of Patients - 1340</p> */}
                {/* <p className="text-xs text-gray-600 pt-1">Online - {user.videoConsFee}<spna className="pl-5">In-Person - {user.inPersonConsFee}</spna></p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default UpcomingAppointment;