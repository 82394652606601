import { combineReducers } from 'redux'
import authReducer from './authReducer'
import RegistrationReducer from "./registrationReducer";
import editpatientinfoReducer from './editpatientinfoReducer';
import vitalsReducer from './vitalsReducer';
import patientdetailsReducer from './patientdetailsReducer';
import particulardoctorReducer from './particulardoctorReducer';
import AppointmentlistReducer from './AppointmentlistReducer';
import patientreportReducer from './patientreportReducer';
import doctorprescriptionReducer from './DoctorPrescriptionReducer';
import UpcomingAppointmentlistReducer from './UpcomingappointmentlistReducer';
import HospitalcliniclistReducer from './hospitalcliniclistReducer';
import doctorconsultationlistReducer from './DoctorconsultationlistReducer';
import MedicinelistReducer from './medicinedruglistReducer';
import DoctorReferredtoReducer from './DoctorReferedToReducer';
import patientPHRReducer from './patientPHRReducer';
import labtestlistReducer from './labtestReducer';
import AddingdoctslotReducer from './AddingdoctslotReducer';
import UpcomingappointmenthospitalfilterReducer from './UpcomingappointmenthospitalfilterReducer';
import ReferalReducer from './referalReducer';
import changePasswordReducer from './changePasswordReducer';
import PatientvitalsdetailsReducer from './Patientvitalsdetailsreducer';
import familymemberslistReducer from './familymemberslistReducer';
import allergylistReducer from './allergylistReducer';
import medicationlistReducer from './medicationlistReducer';
import medicalhistorylistReducer from './medicalhistorylistReducer';
import surgicalHistoryReducer from './surgicalHistoryReducer';
import familyHistoryReducer from './familyHistoryReducer';
import socialHistoryReducer from './socialHistoryReducer';
import addmedicalhistorypatientReducer from './addmedicalhistorypatientReducer';
import addallergypatientReducer from './addallergypatientReducer';
import addvitalsReducer from './addvitalsReducer';
import VideoCallReducer from './videoCallReducer';
import travelHistoryReducer from './travelHistoryReducer'
import sexualHistoryReducer from './sexualHistoryReducer'
import addictionsListReducer from './addictionsListReducer';

export default combineReducers({
    authReducer: authReducer,
    RegistrationReducer: RegistrationReducer,
    editpatientinfo: editpatientinfoReducer,
    vitalslist: vitalsReducer,
    patientdetails: patientdetailsReducer,
    particulardoctor: particulardoctorReducer,
    appointmentlist: AppointmentlistReducer,
    patientreport: patientreportReducer,
    doctorpriscription: doctorprescriptionReducer,
    UpcomingAppointment: UpcomingAppointmentlistReducer,
    hospitalcliniclist: HospitalcliniclistReducer,
    doctorconsultationlist: doctorconsultationlistReducer,
    medicinedruglist: MedicinelistReducer,
    referredtolist: DoctorReferredtoReducer,
    patientphr: patientPHRReducer,
    labtestlist: labtestlistReducer,
    addingdoctorslots: AddingdoctslotReducer,
    upcomingappointmenthospitalfilter: UpcomingappointmenthospitalfilterReducer,
    referalReducer: ReferalReducer,
    changepassword: changePasswordReducer,
    patientvitalsdetail: PatientvitalsdetailsReducer,
    familymemberslist: familymemberslistReducer,
    allallergylist: allergylistReducer,
    allmedicationlist: medicationlistReducer,
    allmedicalhistorylist: medicalhistorylistReducer,
    allsurgicalhistorylist: surgicalHistoryReducer,
    allfamilyHistoryList: familyHistoryReducer,
    allsocialHistoryList: socialHistoryReducer,
    addallergy: addallergypatientReducer,
    addvitalslist: addvitalsReducer,
    videoCall: VideoCallReducer,
    alltravelHistoryList: travelHistoryReducer,
    allsexualHistoryList: sexualHistoryReducer,
    
    addmedicalhistory: addmedicalhistorypatientReducer,
    allAddictionsList: addictionsListReducer,

})