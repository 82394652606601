import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, SearchIcon, XIcon } from '@heroicons/react/outline'
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";

import close from '../Assets/Images/closeee.svg';
import { Medicinedruglist } from '../Redux/Actions/MedicineActions';
import Autocomplete from 'react-autocomplete';


function MedicinePrescription(props) {

    const dispatch = useDispatch();
    const medicinedruglist = useSelector((state) => state.medicinedruglist);
    const { MedicinelistData } = medicinedruglist;
    const userData = useSelector(state => state.authReducer.userData)
    const [val1, setVal1] = useState([]);
    const { patient } = props;
    useEffect(() => {
        dispatch(Medicinedruglist())
    }, []);
    const [list, setList] = useState([{
        "composition": "",
        "afterBeforeFood": "",
        "createdBy": userData.code,
        "dosage": "0-1-1",
        "drugName": "",
        "duration": "",
        "form": "string",
        "frequency": "string",
        "fromDate": moment().format("yyyy-MM-DD hh:mm:ss"),
        "fromDateTime": moment().format("yyyy-MM-DD hh:mm:ss"),
        "givenDate": moment().format("yyyy-MM-DD hh:mm:ss"),
        "instruction": "string",
        "medicineType": "string",
        "modifiedBy": "TESTDOCTOR",
        "patientId": patient?.patientId,
        "pharmacyStockId": "string",
        "prescriptionDoc": "c3RyaW5n",
        "prescriptionId": patient?.id,
        "quantity": 10,
        "specialAuthority": "string",
        "status": 1,
        "toDate": "2021-09-21 06:07:59",
        "toDateTime": "2021-09-21 06:07:59",
        "userId": userData.code,
        "visitId": patient?.id
    }]);

    const addMedicine = () => {
        var data = {
            "composition": "",
            "afterBeforeFood": "0",
            "createdBy": userData.code,
            "dosage": "0-1-1",
            "drugName": "",
            "duration": "string",
            "form": "string",
            "frequency": "string",
            "fromDate": moment().format("yyyy-MM-DD hh:mm:ss"),
            "fromDateTime": moment().format("yyyy-MM-DD hh:mm:ss"),
            "givenDate": moment().format("yyyy-MM-DD hh:mm:ss"),
            "instruction": "string",
            "medicineType": "string",
            "modifiedBy": "TESTDOCTOR",
            "patientId": patient?.patientId,
            "pharmacyStockId": "string",
            "prescriptionDoc": "c3RyaW5n",
            "prescriptionId": "1",
            "quantity": 10,
            "specialAuthority": "string",
            "status": 1,
            "toDate": "2021-09-21 06:07:59",
            "toDateTime": "2021-09-21 06:07:59",
            "userId": userData.code,
            "visitId": patient?.id
        }
        setList([...list, data])
    }


    const changeHandler = (e, index) => {
        let data = [...list];
        let name = e.target.name;
        let value = e.target.value;
        data[index][name] = value;
        if (name === "duration") {
            data[index]["toDate"] = moment(data[index].givenDate, "yyyy-MM-DD hh:mm:ss").add(value, 'days').format('yyyy-MM-DD hh:mm:ss');
            data[index]["toDateTime"] = moment(data[index].givenDate, "yyyy-MM-DD hh:mm:ss").add(value, 'days').format('yyyy-MM-DD hh:mm:ss');
        }
        setList(data);
    }

    const onSelect = (e, i) => {
        let data = [...list];
        data[i]['drugName'] = e.drugname;
        data[i]['drugCode'] = e.drugname;
        data[i]['composition'] = e.composition;
        setList(data);
    }

    const onSetVal = (e, i) => {
        let data = [...list];
        let list1 = [...val1];

        data[i]['drugName'] = "";
        data[i]['composition'] = "";
        list1[i] = e;
        setList(data);
        setVal1(list1);
    }

    useEffect(() => {
        if (props.data) {
            setList(props.data)
        }
    }, [props.data])

    const renderMedicine = (state, val) => {
        return (
            state.drugname.toLowerCase().indexOf(val.toLowerCase()) !== -1
        );
    }

    return (
        <>
          <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10  inset-0 overflow-y-auto" onClose={() => props.onClose(list)}>
        <div className="flex items-end  justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="inline-block h-auto align-bottom bg-white rounded-lg text-left  overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ">
                            <div className="w-full border border-gray-500 rounded overflow-hidden shadow-sm p-3">
                                <div className='flex justify-between mt-2 ml-2'>
                                    <p className="text-xl text-gray-900 font-medium font-rubik">Medicine Prescription</p>
                                    <img src={close} alt="close" className="w-4" />
                                </div>
                                <hr className="my-4" />


                                <div className="w-full mr-12 mt-5 ">
                                    {/* <div className="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 mb-2 antialiased justify-between border border-gray-200"> */}

                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                {/* <div className="flex ">

                                        <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-700  tracking-wider">
                                            Medicine Name
                                        </th>

                                    </div> */}
                                                <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-700  tracking-wider">
                                                    Medicine Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-sm font-medium text-gray-700  tracking-wider">
                                                    Dosage
                                                </th>


                                                <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-700 tracking-wider">
                                                    Instructions
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-sm font-medium text-gray-700  tracking-wider"
                                                >
                                                    Duration
                                                </th>
                                                <th
                                                    scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-700  tracking-wider">Composition
                                                </th>
                                                <th
                                                    scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-700  tracking-wider">Type
                                                </th>


                                            </tr>
                                        </thead>
                                        <tbody className=" divide-y divide-gray-200">
                                            {list.map((res, i) => (
                                                <tr >
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <Autocomplete
                                                            className="w-32"
                                                            value={res.drugName ? res.drugName : val1[i]}
                                                            items={MedicinelistData}
                                                            getItemValue={item => item.drugname}
                                                            shouldItemRender={renderMedicine}
                                                            menuStyle={{ width: '400' }}
                                                            renderMenu={item => (
                                                                <div className="dropdown">
                                                                    {item}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) =>
                                                                <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                                                                    {item.drugname}
                                                                </div>
                                                            }
                                                            onChange={(event, val) => { onSetVal(val, i) }}
                                                            onSelect={(event, obj) => onSelect(obj, i)}
                                                        />
                                                        <div className="border-b-2" ></div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <select id="dosage" onChange={(e) => changeHandler(e, i)} name="dosage" value={res.dosage} className="outline-none peer text-gray-500 text-sm font-medium ">
                                                            <option value="0-0-0">Select</option>
                                                            <option value="0-0-1">0-0-1</option>
                                                            <option value="0-1-1">0-1-1</option>
                                                            <option value="0-1-0">0-1-0</option>
                                                            <option value="1-1-1">1-1-1</option>
                                                            <option value="1-0-0">1-0-0</option>
                                                            <option value="1-0-1">1-0-1</option>
                                                            <option value="1-1-0">1-1-0</option>
                                                            <option value="1-0-0">1-0-0</option>
                                                            <option value="1-1-1">1-1-1</option>
                                                        </select>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <select className="  outline-none peer text-gray-500 text-sm font-medium" onChange={(e) => changeHandler(e, i)} name="afterBeforeFood" value={res.afterBeforeFood}>
                                                            <option value="0" disabled>Select</option>
                                                            <option value="0">After Meal</option>
                                                            <option value="1">Before Meal</option>
                                                            {/* <option className=""></option> */}
                                                        </select>
                                                    </td>
                                                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-montserrat">Upcoming</td> */}
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-medium" >
                                                        <select className="  outline-none peer text-gray-500 text-sm font-medium" id="duration" onChange={(e) => changeHandler(e, i)} name="duration" value={res.duration}>
                                                            {[...Array(30)].map((res, i) => (
                                                                <option value={i}>{i + 1} days</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-gray-500 text-sm font-medium">
                                                        <p>{res.composition}</p>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-medium">
                                                        <select className="  outline-none peer text-gray-500 text-sm font-medium  " onChange={(e) => changeHandler(e, i)} name="type" value={res.type}>
                                                            <option value="Select">Select</option>
                                                            <option value="Rx">Rx</option>
                                                            <option value="NRx">NRx</option>
                                                            <option value="XRx">XRx</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <p onClick={addMedicine} className="cursor-pointer text-sm text-brand-secondary pl-5 font-medium">+ Add Another medicine</p>

                                </div>
                                <button onClick={() => props.onClose(list)} className="bg-brand-secondary float-right mr-10 text-white font-medium py-2 px-4 rounded text-sm">Save</button>
                            </div>

                            {/* </div> */}











                        </div>
                    </div>
                </Dialog>
            </Transition.Root >
        </>
    );
}
export default MedicinePrescription;