import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import Slotservice from "../Redux/services/Slotservice";
import stamps from '../Assets/Images/stamp.png';
import { encodeBase64File } from "../helper/filebase64";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment, { now } from "moment";
import userprofileservice from "../Redux/services/userprofileservice";
import DatePicker from "react-datepicker";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
export default function FilterConsultation(props) {
    const dispatch = useDispatch();

    const [map, setMap] = useState({
        fromDate: "",
        toDate:"",
    });
    const [PatientListdata, setPatientList] = useState([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [loading,setLoading]=useState(false);

    // useEffect(() => {
    //     setLoading(true); 
    //     let payloadPat = {
    //         status: 3,
    //     };

    //     Slotservice.getPatientList(payloadPat).then((res) => {
    //         setPatientList(res.data);
    //         setLoading(false); 
    //     }).catch((error) => {
    //         console.error("Error fetching patient list:", error);
    //         setLoading(false); 
    //     });
    // }, []);

    useEffect(() => {
        setLoading(true); 
        let payloadPat = {
            status: 3,
        }
        Slotservice.getPatientList(payloadPat).then((res) => {
            setPatientList(res.data);
            setLoading(false); 
        }).catch((error)=>{
            setLoading(false); 
        });
    }, []);

    const redirectTo = (event) => {
        event.preventDefault();
        props.onClose();
    };

    const cancel = (e) => {
        props.onClose(e);
        e.preventDefault();
    };

    const handleChange = (e) => {
        e.preventDefault();
        setMap({ ...map, [e.target.name]: e.target.value });
    };

    const filter = (e) => {
        e.preventDefault();
        props.filterData(map);
        props.onClose();
    }

    return (
        <>
         <Backdrop
        sx={{ color: "#5579C6", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 antialiased justify-between border border-gray-200">
                            <div className="flex justify-between">
                                <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">
                                    Filter Billing
                                </h1>
                                {/* <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">Add New Address</h1> */}
                                <XIcon
                                    onClick={redirectTo}
                                    className="h-5 cursor-pointer" />
                            </div>
                            <hr className="mt-2"></hr>

                            <div className="w-full">
                                <div className="lg:flex justify-between py-6 lg:space-x-10">
                                    <div className="relative mb-6">
                                        <div className="flex">
                                            <input
                                                autocomplete="off"
                                                id="id"
                                                name="id"
                                                type="text"
                                                className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                placeholder="Enter Apt. ID"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {/* <input autocomplete="off" id="email" name="email" type="text" value="Enter Name" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" /> */}

                                        <label
                                            for="txnId"
                                            className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                        >
                                            Appointment ID
                                        </label>
                                    </div>
                                    <div className="relative mb-6">
                                        <div className="flex">
                                            <select
                                                id="patientId"
                                                name="patientId"
                                                value={map.patientId}
                                                onChange={(handleChange)}
                                                className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600">
                                                {
                                                    <>
                                                        <option className="py-1 text-sm text-green-600" value="" selected disabled> Select Patient </option>
                                                        {PatientListdata.map((res, i) => (
                                                            <option key={i} className="py-1 text-sm text-green-600" value={res.code}>{res.name}</option>
                                                        ))}
                                                    </>}
                                            </select>
                                            <label
                                                for="patientId"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm "
                                            >
                                                Patient Name
                                            </label>
                                        </div>
                                        {/* <input autocomplete="off" id="email" name="email" type="text" value="Enter Name" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" /> */}
                                    </div>
                                </div>
                                <div className="w-full">
                                    <div className="lg:flex justify-between py-6 lg:space-x-10">
                                        <div className="relative mb-6">
                                            <div className="flex">
                                                <DatePicker
                                                    dateFormat={"DD/MM/yyyy"}
                                                    placeholderText="Select From Date"
                                                    name="fromDate"
                                                    className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    onChange={(date) => {
                                                        // setFromDate(moment(date).format("MM/DD/yyyy"));
                                                        setMap({ ...map, "fromDate": moment(date).format("MM/DD/yyyy") })
                                                    }}
                                                    value={map.fromDate ? moment(map.fromDate).format('DD/MM/yyyy') : ''}
                                                />
                                            </div>
                                            <label
                                                for="firstName"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                            >
                                                From Date
                                            </label>
                                        </div>

                                        <div className="relative mb-6">
                                            <div className="flex">
                                                <DatePicker
                                                    dateFormat={"DD/MM/yyyy"}
                                                    placeholderText="Select To Date"
                                                    name="toDate"
                                                    className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    onChange={(date) => {
                                                        // setFromDate(moment(date).format("MM/DD/yyyy"));
                                                        setMap({ ...map, "toDate": moment(date).format("MM/DD/yyyy") })
                                                    }}
                                                    value={map.toDate ? moment(map.toDate).format('DD/MM/yyyy') : ''}
                                                />
                                            </div>
                                            <label
                                                for="firstName"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                            >
                                                To Date
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative ">
                                    <div className="flex"></div>
                                </div>
                                <div className="flex mt-10 justify-end">
                                    <button
                                        type="submit"
                                        onClick={cancel}
                                        className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        onClick={filter}
                                        className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}