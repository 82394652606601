import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router';
import patientService from "../Redux/services/patientService";
import Abdomen from "./../Assets/Images/Abdomen.jpg";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
function AddPatientProfilePEPopup(props) {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [addPhysicalExam, setAddPhysicalExam] = useState({});
    const [isLoading, setLoad] = useState(false)
    const goBack = () => {
        history.goBack();
    }
    const userData = useSelector((state) => state.authReducer.userData);
    const handleChangePE = (e) => {
        setAddPhysicalExam({ ...addPhysicalExam, [e.target.name]: e.target.value });
    };
    const savevitals = (e) => {
        e.preventDefault();
        const activePatient = JSON.parse(localStorage.getItem('activePatient'));

        if (!addPhysicalExam.eye) {
            return toast("Please Select Eyes")
        } else if (!addPhysicalExam.mouth) {
            return toast("Please Select Mouth")
        } else if (!addPhysicalExam.hands) {
            return toast("Please Select Hands")
        } else if (!addPhysicalExam.feet) {
            return toast("Please Select Feet")
        } else if (!addPhysicalExam.head) {
            return toast("Please Select Head & Neck")
        } else if (!addPhysicalExam.lymphnodes) {
            return toast("Please Select Lymph Nodes")
        } else if (!addPhysicalExam.frontal) {
            return toast("Please Select Frontal")
        } else if (!addPhysicalExam.ethmoid) {
            return toast("Please Select Ethmoid")
        } else if (!addPhysicalExam.maxillary) {
            return toast("Please Select Maxillary")
        } else if (!addPhysicalExam.sphenoid) {
            return toast("Please Select Sphenoid")
        } else if (!addPhysicalExam.nasalCavity) {
            return toast("Please Select Nasal cavity")
        } else if (!addPhysicalExam.nasalSeptum) {
            return toast("Please Select Nasal septum")
        } else if (!addPhysicalExam.pharynx) {
            return toast("Please Select Pharynx")
        } else if (!addPhysicalExam.digitalStethoscope) {
            return toast("Please Select Digital Stethoscope")
        } else if (!addPhysicalExam.lips) {
            return toast("Please Select Lips")
        } else if (!addPhysicalExam.buccalMucosa) {
            return toast("Please Select Buccal mucosa")
        } else if (!addPhysicalExam.teeth) {
            return toast("Please Select Teeth")
        } else if (!addPhysicalExam.tongue) {
            return toast("Please Select Tongue")
        } else if (!addPhysicalExam.floorOfTheTongue) {
            return toast("Please Select Floor of the tongue")
        } else if (!addPhysicalExam.gingivolabialSurface) {
            return toast("Please Select Gums - Gingivolabial surface")
        } else if (!addPhysicalExam.gingivobuccalSurface) {
            return toast("Please Select Gums - Gingivobuccal surface")
        } else if (!addPhysicalExam.hardPalate) {
            return toast("Please Select Hard palate")
        } else if (!addPhysicalExam.lineaAlba) {
            return toast("Please Select Linea alba")
        } else if (!addPhysicalExam.abdomenSkin) {
            return toast("Please Select Skin of the abdomen")
        } else if (!addPhysicalExam.surgicalScars) {
            return toast("Please Select Surgical Scars")
        } else if (!addPhysicalExam.umblicus) {
            return toast("Please Select Umblicus")
        } else if (!addPhysicalExam.abdominalDistension) {
            return toast("Please Select Abdominal Distension")
        } else if (!addPhysicalExam.palpatePain) {
            return toast("Please Select  Palpate for pain, tenderness or local organs")
        } else if (!addPhysicalExam.rightHypochondriac) {
            return toast("Please Select  Right Hypochondriac (RH)")
        } else if (!addPhysicalExam.rightLumbar) {
            return toast("Please Select Right Lumbar (RL)")
        } else if (!addPhysicalExam.rightLliac) {
            return toast("Please Select Right Iliac (RI)")
        } else if (!addPhysicalExam.epigastric) {
            return toast("Please Select Epigastric (E)")
        } else if (!addPhysicalExam.umbilical) {
            return toast("Please Select Umbilical (U)")
        } else if (!addPhysicalExam.hypogastric) {
            return toast("Please Select Hypogastric (H)")
        } else if (!addPhysicalExam.leftHypochondriac) {
            return toast("Please Select Left Hypochondriac (LH)")
        } else if (!addPhysicalExam.leftLumbar) {
            return toast("Please Select Left Lumbar (LL)")
        } else if (!addPhysicalExam.leftLliac) {
            return toast("Please Select Left Iliac (LI)")
        } else if (!addPhysicalExam.abdominalSound) {
            return toast("Please Select Abdominal Sounds/Aortic Bruit")
        } else if (!addPhysicalExam.cardiacAuscultationS1) {
            return toast("Please Select S1 (1st heart sound)")
        } else if (!addPhysicalExam.cardiacAuscultationS2) {
            return toast("Please Select S2 (2nd heart sound)")
        } else if (!addPhysicalExam.mood) {
            return toast("Please Select Orientation / Mood (on Observation)")
        } else {
            const pEpayload1 = {
                "patientId": activePatient.code,
                "appointmentId": 0,
                "visitId": 0,
                "createdBy": userData.code,
                "updatedBy": userData.code,
                "general": {
                    "eye": addPhysicalExam.eye,
                    "mouth": addPhysicalExam.mouth,
                    "hands": addPhysicalExam.hands,
                    "feet": addPhysicalExam.feet,
                    "head": addPhysicalExam.head,
                    "lymphnodes": addPhysicalExam.lymphnodes
                },
                "respiratory":
                {
                    "frontal": addPhysicalExam.frontal,
                    "ethmoid": addPhysicalExam.ethmoid,
                    "maxillary": addPhysicalExam.maxillary,
                    "sphenoid": addPhysicalExam.sphenoid,
                    "nasalCavity": addPhysicalExam.nasalCavity,
                    "nasalSeptum": addPhysicalExam.nasalSeptum,
                    "pharynx": addPhysicalExam.pharynx,
                    "digitalStethoscope": addPhysicalExam.digitalStethoscope
                },
                "abdomen": {
                    "oralCavity": {
                        "lips": addPhysicalExam.lips,
                        "buccalMucosa": addPhysicalExam.buccalMucosa,
                        "teeth": addPhysicalExam.teeth,
                        "tongue": addPhysicalExam.tongue,
                        "floorOfTheTongue": addPhysicalExam.floorOfTheTongue
                    },
                    "gums": {
                        "gingivolabialSurface": addPhysicalExam.gingivolabialSurface,
                        "gingivobuccalSurface": addPhysicalExam.gingivobuccalSurface,
                        "hardPalate": addPhysicalExam.hardPalate
                    },
                    "abdomenInspection": {
                        "lineaAlba": addPhysicalExam.lineaAlba,
                        "abdomenSkin": addPhysicalExam.abdomenSkin,
                        "surgicalScars": addPhysicalExam.surgicalScars,
                        "umblicus": addPhysicalExam.umblicus,
                        "abdominalDistension": addPhysicalExam.abdominalDistension
                    },
                    "abdomenPalpation": {
                        "palpatePain": addPhysicalExam.palpatePain,
                        "rightHypochondriac": addPhysicalExam.rightHypochondriac,
                        "rightLumbar": addPhysicalExam.rightLumbar,
                        "rightLliac": addPhysicalExam.rightLliac,
                        "epigastric": addPhysicalExam.epigastric,
                        "umbilical": addPhysicalExam.umbilical,
                        "hypogastric": addPhysicalExam.hypogastric,
                        "leftHypochondriac": addPhysicalExam.leftHypochondriac,
                        "leftLumbar": addPhysicalExam.leftLumbar,
                        "leftLliac": addPhysicalExam.leftLliac
                    },
                    "abdominalSound": addPhysicalExam.abdominalSound
                },
                "cardiacAuscultationS1": addPhysicalExam.cardiacAuscultationS1,
                "cardiacAuscultationS2": addPhysicalExam.cardiacAuscultationS2,
                "mood": addPhysicalExam.mood
            }
            patientService.addPhysicalExamination(pEpayload1).then((resPE) => {
                props.close();
            })
        }
    }

    return (
        <>
            <ToastContainer />

            {/* <div class="flex justify-between mt-10 mb-10">
                <p class="text-lg font-medium text-brand-secondary">Physical Examination</p>
            </div> */}
            <div class="flex justify-between my-3">
                <p class="text-lg font-small text-brand-secondary">General</p>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Eyes
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="eye"
                                name="eye"
                                value={addPhysicalExam.eye}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Pallor">Pallor</option>
                                <option class="py-1" value="Redness">Redness</option>
                                <option class="py-1" value="Itching">Itching</option>
                                <option class="py-1" value="Swelling">Swelling</option>
                                <option class="py-1" value="Icterus">Icterus</option>
                                <option class="py-1" value="Cyanosis">Cyanosis</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Mouth
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="mouth"
                                name="mouth"
                                value={addPhysicalExam.mouth}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options   "}</option>
                                <option class="py-1" value="Pallor">Pallor</option>
                                <option class="py-1" value="Redness">Redness</option>
                                <option class="py-1" value="Itching">Itching</option>
                                <option class="py-1" value="Swelling">Swelling</option>
                                <option class="py-1" value="Icterus">Icterus</option>
                                <option class="py-1" value="Cyanosis">Cyanosis</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Hands
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="hands"
                                name="hands"
                                value={addPhysicalExam.hands}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options  "}</option>
                                <option class="py-1" value="Pallor">Pallor</option>
                                <option class="py-1" value="Redness">Redness</option>
                                <option class="py-1" value="Itching">Itching</option>
                                <option class="py-1" value="Swelling">Swelling</option>
                                <option class="py-1" value="Icterus">Icterus</option>
                                <option class="py-1" value="Cyanosis">Cyanosis</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Feet
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="feet"
                                name="feet"
                                value={addPhysicalExam.feet}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options  "}</option>
                                <option class="py-1" value="Pallor">Pallor</option>
                                <option class="py-1" value="Redness">Redness</option>
                                <option class="py-1" value="Itching">Itching</option>
                                <option class="py-1" value="Swelling">Swelling</option>
                                <option class="py-1" value="Icterus">Icterus</option>
                                <option class="py-1" value="Cyanosis">Cyanosis</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {/* <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Head & Neck
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="head"
                                name="head"
                                value={addPhysicalExam.head}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Pallor">Pallor</option>
                                <option class="py-1" value="Redness">Redness</option>
                                <option class="py-1" value="Itching">Itching</option>
                                <option class="py-1" value="Swelling">Swelling</option>
                                <option class="py-1" value="Icterus">Icterus</option>
                                <option class="py-1" value="Cyanosis">Cyanosis</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div> */}
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Lymph nodes of head & neck
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="lymphnodes"
                                name="lymphnodes"
                                value={addPhysicalExam.lymphnodes}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options   "}</option>
                                <option class="py-1" value="Swollen">Swollen</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>

            </div>

            <div class="flex justify-between my-3">
                <p class="text-lg font-small text-brand-secondary">Respiratory</p>
            </div>
            <div class="flex justify-between my-3 mb-3">
                <p class="text-lg font-small text-black">Upper Respiratory </p>
            </div>
            <div class="flex space-x-3 mb-3">
                <p class="text-xs font-rubik font-medium text-gray-primary">
                    Sinuses :
                </p>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Frontal
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="frontal"
                                name="frontal"
                                value={addPhysicalExam.frontal}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Painful">Painful</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Ethmoid
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="ethmoid"
                                name="ethmoid"
                                value={addPhysicalExam.ethmoid}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Painful">Painful</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Maxillary
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="maxillary"
                                name="maxillary"
                                value={addPhysicalExam.maxillary}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Painful">Painful</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Sphenoid
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="sphenoid"
                                name="sphenoid"
                                value={addPhysicalExam.sphenoid}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Painful">Painful</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Nasal cavity
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="nasalCavity"
                                name="nasalCavity"
                                value={addPhysicalExam.nasalCavity}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Congested">Congested</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Nasal septum
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="nasalSeptum"
                                name="nasalSeptum"
                                value={addPhysicalExam.nasalSeptum}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Central">Central</option>
                                <option class="py-1" value="Deviated">Deviated</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Pharynx
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="pharynx"
                                name="pharynx"
                                value={addPhysicalExam.pharynx}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Congested">Congested</option>
                                <option class="py-1" value="Normal">Normal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div class="flex justify-between my-3 mb-3">
                <p class="text-lg font-small text-black">Lower Respiratory </p>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Digital Stethoscope
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="digitalStethoscope"
                                name="digitalStethoscope"
                                value={addPhysicalExam.digitalStethoscope}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>

            <div class="flex justify-between my-3">
                <p class="text-lg font-small text-brand-secondary">Gastrointestinal System</p>
            </div>
            <div class="flex justify-between my-3 mb-3">
                <p class="text-lg font-small text-black">Oral Cavity </p>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Lips
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="lips"
                                name="lips"
                                value={addPhysicalExam.lips}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Buccal mucosa
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="buccalMucosa"
                                name="buccalMucosa"
                                value={addPhysicalExam.buccalMucosa}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Gums - Gingivolabial surface
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="gingivolabialSurface"
                                name="gingivolabialSurface"
                                value={addPhysicalExam.gingivolabialSurface}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Gums - Gingivobuccal surface
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="gingivobuccalSurface"
                                name="gingivobuccalSurface"
                                value={addPhysicalExam.gingivobuccalSurface}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Teeth
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="teeth"
                                name="teeth"
                                value={addPhysicalExam.teeth}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Tongue
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="tongue"
                                name="tongue"
                                value={addPhysicalExam.tongue}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Hard palate
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="hardPalate"
                                name="hardPalate"
                                value={addPhysicalExam.hardPalate}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Floor of the tongue
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="floorOfTheTongue"
                                name="floorOfTheTongue"
                                value={addPhysicalExam.floorOfTheTongue}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div class="flex justify-between my-3 mb-3">
                <p class="text-lg font-small text-black">Inspect the Abdomen </p>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Linea alba
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="lineaAlba"
                                name="lineaAlba"
                                value={addPhysicalExam.lineaAlba}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Skin of the abdomen
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="abdomenSkin"
                                name="abdomenSkin"
                                value={addPhysicalExam.abdomenSkin}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Surgical Scars
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="surgicalScars"
                                name="surgicalScars"
                                value={addPhysicalExam.surgicalScars}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Present">Present</option>
                                <option class="py-1" value="Absent">Absent</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Umblicus
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="umblicus"
                                name="umblicus"
                                value={addPhysicalExam.umblicus}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Everted">Everted</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Abdominal Distension
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="abdominalDistension"
                                name="abdominalDistension"
                                value={addPhysicalExam.abdominalDistension}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Present">Present</option>
                                <option class="py-1" value="Absent">Absent</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div class="flex justify-between my-3 mb-3">
                <p class="text-lg font-small text-black">Abdominal Aucultation</p>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Abdominal Sounds/Aortic Bruit
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="abdominalSound"
                                name="abdominalSound"
                                value={addPhysicalExam.abdominalSound}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div class="flex justify-between my-3 mb-3">
                <p class="text-lg font-small text-black">Abdominal Palpation - </p>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Palpate for pain, tenderness or local organs
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="palpatePain"
                                name="palpatePain"
                                value={addPhysicalExam.palpatePain}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Soft">Soft</option>
                                <option class="py-1" value="Tender">Tender</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div className='grid md:grid-cols-2 lg:grid-cols-4'>
                <div className='col-span-3'>
                    <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                        <div class="w-11/12 mb-4">
                            <div class="flex space-x-3">
                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                    Right Hypochondriac (RH)
                                </p>
                            </div>
                            <div class="pt-4">
                                <div class="flex justify-between h-3  h-full">
                                    <select
                                        className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        id="rightHypochondriac"
                                        name="rightHypochondriac"
                                        value={addPhysicalExam.rightHypochondriac}
                                        onChange={handleChangePE} >
                                        <option class="" value="">{" Select options"}</option>
                                        <option class="py-1" value="Soft">Soft</option>
                                        <option class="py-1" value="Tender">Tender</option>
                                    </select>
                                </div>
                                <hr class=" bg-gray-secondary" />
                            </div>
                        </div>
                        <div class="w-11/12 mb-4">
                            <div class="flex space-x-3">
                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                    Epigastric (E)
                                </p>
                            </div>
                            <div class="pt-4">
                                <div class="flex justify-between h-3  h-full">
                                    <select
                                        className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        id="epigastric"
                                        name="epigastric"
                                        value={addPhysicalExam.epigastric}
                                        onChange={handleChangePE} >
                                        <option class="" value="">{" Select options"}</option>
                                        <option class="py-1" value="Soft">Soft</option>
                                        <option class="py-1" value="Tender">Tender</option>
                                    </select>
                                </div>
                                <hr class=" bg-gray-secondary" />
                            </div>
                        </div>
                        <div class="w-11/12 mb-4">
                            <div class="flex space-x-3">
                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                    Left Hypochondriac (LH)
                                </p>
                            </div>
                            <div class="pt-4">
                                <div class="flex justify-between h-3  h-full">
                                    <select
                                        className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        id="leftHypochondriac"
                                        name="leftHypochondriac"
                                        value={addPhysicalExam.leftHypochondriac}
                                        onChange={handleChangePE} >
                                        <option class="" value="">{" Select options"}</option>
                                        <option class="py-1" value="Soft">Soft</option>
                                        <option class="py-1" value="Tender">Tender</option>
                                    </select>
                                </div>
                                <hr class=" bg-gray-secondary" />
                            </div>
                        </div>
                        <div class="w-11/12 mb-4">
                            <div class="flex space-x-3">
                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                    Right Lumbar (RL)
                                </p>
                            </div>
                            <div class="pt-4">
                                <div class="flex justify-between h-3  h-full">
                                    <select
                                        className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        id="rightLumbar "
                                        name="rightLumbar"
                                        value={addPhysicalExam.rightLumbar}
                                        onChange={handleChangePE} >
                                        <option class="" value="">{" Select options"}</option>
                                        <option class="py-1" value="Soft">Soft</option>
                                        <option class="py-1" value="Tender">Tender</option>
                                    </select>
                                </div>
                                <hr class=" bg-gray-secondary" />
                            </div>
                        </div>

                        <div class="w-11/12 mb-4">
                            <div class="flex space-x-3">
                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                    Umbilical (U)
                                </p>
                            </div>
                            <div class="pt-4">
                                <div class="flex justify-between h-3  h-full">
                                    <select
                                        className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        id="umbilical"
                                        name="umbilical"
                                        value={addPhysicalExam.umbilical}
                                        onChange={handleChangePE} >
                                        <option class="" value="">{" Select options"}</option>
                                        <option class="py-1" value="Soft">Soft</option>
                                        <option class="py-1" value="Tender">Tender</option>
                                    </select>
                                </div>
                                <hr class=" bg-gray-secondary" />
                            </div>
                        </div>
                        <div class="w-11/12 mb-4">
                            <div class="flex space-x-3">
                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                    Left Lumbar (LL)
                                </p>
                            </div>
                            <div class="pt-4">
                                <div class="flex justify-between h-3  h-full">
                                    <select
                                        className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        id="leftLumbar"
                                        name="leftLumbar"
                                        value={addPhysicalExam.leftLumbar}
                                        onChange={handleChangePE} >
                                        <option class="" value="">{" Select options"}</option>
                                        <option class="py-1" value="Soft">Soft</option>
                                        <option class="py-1" value="Tender">Tender</option>
                                    </select>
                                </div>
                                <hr class=" bg-gray-secondary" />
                            </div>
                        </div>
                        <div class="w-11/12 mb-4">
                            <div class="flex space-x-3">
                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                    Right Iliac (RI)
                                </p>
                            </div>
                            <div class="pt-4">
                                <div class="flex justify-between h-3  h-full">
                                    <select
                                        className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        id="rightLliac"
                                        name="rightLliac"
                                        value={addPhysicalExam.rightLliac}
                                        onChange={handleChangePE} >
                                        <option class="" value="">{" Select options"}</option>
                                        <option class="py-1" value="Soft">Soft</option>
                                        <option class="py-1" value="Tender">Tender</option>
                                    </select>
                                </div>
                                <hr class=" bg-gray-secondary" />
                            </div>
                        </div>


                        <div class="w-11/12 mb-4">
                            <div class="flex space-x-3">
                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                    Hypogastric (H)
                                </p>
                            </div>
                            <div class="pt-4">
                                <div class="flex justify-between h-3  h-full">
                                    <select
                                        className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        id="hypogastric"
                                        name="hypogastric"
                                        value={addPhysicalExam.hypogastric}
                                        onChange={handleChangePE} >
                                        <option class="" value="">{" Select options"}</option>
                                        <option class="py-1" value="Soft">Soft</option>
                                        <option class="py-1" value="Tender">Tender</option>
                                    </select>
                                </div>
                                <hr class=" bg-gray-secondary" />
                            </div>
                        </div>

                        <div class="w-11/12 mb-4">
                            <div class="flex space-x-3">
                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                    Left Iliac (LI)
                                </p>
                            </div>
                            <div class="pt-4">
                                <div class="flex justify-between h-3  h-full">
                                    <select
                                        className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        id="leftLliac "
                                        name="leftLliac"
                                        value={addPhysicalExam.leftLliac}
                                        onChange={handleChangePE} >
                                        <option class="" value="">{" Select options"}</option>
                                        <option class="py-1" value="Soft">Soft</option>
                                        <option class="py-1" value="Tender">Tender</option>
                                    </select>
                                </div>
                                <hr class=" bg-gray-secondary" />
                            </div>
                        </div>
                        <div class="w-11/12 mb-4">
                            <div class="flex space-x-3">
                                <p class="text-xs font-rubik font-medium text-gray-primary">
                                    Enlarged organs( if any )
                                </p>
                            </div>
                            <div class="pt-4">
                                <div class="flex justify-between h-3  h-full">
                                    <select
                                        className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        id="enlOrgan"
                                        name="enlOrgan"
                                        value={addPhysicalExam.enlOrgan}
                                        onChange={handleChangePE} >
                                        <option class="" value="">{" Select options"}</option>
                                        <option class="py-1" value="Yes">Yes</option>
                                        <option class="py-1" value="No">No</option>
                                    </select>
                                </div>
                                <hr class=" bg-gray-secondary" />
                            </div>
                        </div>
                        {addPhysicalExam && addPhysicalExam.enlOrgan === "Yes" &&
                            <div class="w-11/12 mb-4">
                                <div class="flex space-x-3">
                                    <p class="text-xs font-rubik font-medium text-gray-primary">
                                        Enter Enlarged organs
                                    </p>
                                </div>
                                <div class="pt-4">
                                    <div class="flex justify-between h-3  h-full">
                                        <input
                                            id="enlOrganDesc"
                                            name="enlOrganDesc"
                                            type="text"
                                            value={addPhysicalExam.enlOrganDesc}
                                            onChange={handleChangePE}
                                            class="peer h-10 w-full border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                            placeholder="Enter Enlarged organs"
                                        />
                                    </div>
                                    <hr class=" bg-gray-secondary" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <img src={Abdomen} />
                </div>
            </div>
            <div class="flex justify-between my-3">
                <p class="text-lg font-small text-brand-secondary">Cardiovascular</p>
            </div>
            <div class="flex justify-between my-3 mb-3">
                <p class="text-lg font-small text-black">Cardiac Auscultation</p>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            S1 (1st heart sound)
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="cardiacAuscultationS1"
                                name="cardiacAuscultationS1"
                                value={addPhysicalExam.cardiacAuscultationS1}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            S2 (2nd heart sound)
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="cardiacAuscultationS2"
                                name="cardiacAuscultationS2"
                                value={addPhysicalExam.cardiacAuscultationS2}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div class="flex justify-between my-3">
                <p class="text-lg font-small text-brand-secondary">Central Nervous System (CNS)</p>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Orientation / Mood of the patient(on Observation)
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <select
                                className="peer h-10 w-65 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                id="mood"
                                name="mood"
                                value={addPhysicalExam.mood}
                                onChange={handleChangePE} >
                                <option class="" value="">{" Select options"}</option>
                                <option class="py-1" value="Normal">Normal</option>
                                <option class="py-1" value="Abnormal">Abnormal</option>
                            </select>
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="w-11/12 mb-4">
                    <div class="flex space-x-3">
                        <p class="text-xs font-rubik font-medium text-gray-primary">
                            Orientation / Mood of the patient(on Observation)
                        </p>
                    </div>
                    <div class="pt-4">
                        <div class="flex justify-between h-3  h-full">
                            <input
                                id="moodDesc"
                                name="moodDesc"
                                type="text"
                                value={addPhysicalExam.moodDesc}
                                onChange={handleChangePE}
                                class="peer h-10 w-full border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                placeholder="Enter Orientation"
                            />
                        </div>
                        <hr class=" bg-gray-secondary" />
                    </div>
                </div>
            </div>
            <div class="mb-10 my-4">

                <button
                    disabled={isLoading}
                    onClick={savevitals}
                    class="bg-brand-secondary mt-6 float-right hover:bg-brand-secondary text-white text-sm mb-10 py-2 px-4 rounded disabled:opacity-50"
                >
                    Save Data{" "}
                    {isLoading && (
                        <div className="loader float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5" />
                    )}
                </button>
            </div>
            <div class="h-10 my-4">

            </div>
        </>
    );
}

export default AddPatientProfilePEPopup;