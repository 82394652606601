export const DOCSLOTS = {
   
    REQUEST_CREATEDOCTSLOTS: "REQUEST_CREATEDOCTSLOTS",
    SUCCESS_CREATEDOCTSLOTS: "SUCCESS_CREATEDOCTSLOTS",
    FAILED_CREATEDOCTSLOTS: "FAILED_CREATEDOCTSLOTS",
    
    REQUEST_GETSLOTSLIST: "REQUEST_GETSLOTSLIST",
    SUCCESS_GETSLOTSLIST: "SUCCESS_GETSLOTSLIST",
    FAILED_GETSLOTSLIST: "FAILED_GETSLOTSLIST",


   
}
