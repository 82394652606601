
import { USER } from "../Constants/userprofileConstants";
import { VITALS } from "../Constants/vitalConstants";
import userprofileservice from "../services/userprofileservice";
import vitalService from "../services/vitalService";


export const getPatientvitallist = (data) => async (dispatch) => {
    request();
    const res = await vitalService.getpatientvitallist(data)
    try {
        console.log(JSON.stringify(res.data));
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: VITALS.REQUEST_VITALSLIST });
    };

    function success(res) {
        dispatch({ type: VITALS.SUCCESS_VITALSLIST, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: VITALS.FAILED_VITALSLIST,
            payload: err
        });
    };
};


export const addPatientvitals = (data) => async (dispatch) => {
    request();
    try {
    const res = await vitalService.addpatientvitaldetails(data)
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: VITALS.REQUEST_ADDVITALS });
    };

    function success(res) {
        dispatch({ type: VITALS.SUCCESS_ADDVITALS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: VITALS.FAILED_ADDVITALS,
            payload: err
        });
    };
};


export const updateVitals = (data) => async (dispatch) => {
    request();
    try {
    const res = await vitalService.updateVitals(data)
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: VITALS.REQUEST_ADDVITALS });
    };

    function success(res) {
        dispatch({ type: VITALS.SUCCESS_ADDVITALS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: VITALS.FAILED_ADDVITALS,
            payload: err
        });
    };
};
