import { BrowserRouter as Router, } from "react-router-dom";
import Routes from './application/Router/components';
import MobileFooter from './components/mobileFooter';
import React from "react";
import ErrorBoundary from "./components/ErrorBoundary";
import store from "./Redux/store/store";
import { Provider } from "react-redux";
import MinimizeVideoCall from "./components/MinimizeVideoCall";
function App(props) {
  console.log("prios",props)
  return (
    <Provider store={store}>
      <React.Fragment>
        <ErrorBoundary>
          <Router>
            <Routes />
            <MinimizeVideoCall/>
            <MobileFooter />
          </Router>
        </ErrorBoundary>
      </React.Fragment>
    </Provider>
  );
}

export default App;
