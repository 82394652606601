import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import close from '../Assets/Images/closeee.svg';
import search from '../Assets/Images/search.svg';
import { getlabtestlist } from '../Redux/Actions/DiagnosticsAction';

function DiagnopreStep2() {

  const params = useParams()
  const history = useHistory();
  const dispatch = useDispatch();
  const labtestlist = useSelector((state) => state.labtestlist);
  const { labtestlistData } = labtestlist;


  useEffect(() => {

    console.log(labtestlistData)
    dispatch(getlabtestlist())
  }, []);







  return (
    <>
      <div className="flex justify-center mt-5">
        <div className="rounded overflow-hidden shadow-sm border border-gray-200 p-5 w-1/3">

          <div className="flex justify-between">
            <p className="text-base font-medium text-gray-700">Diagnostics Prescription</p>
            <img src={close} alt="close" className="w-4" />
          </div>

          <hr className="my-3" />

          <div className="mt-3 border boder-gray-500 p-3 rounded-lg">
            <div className="flex justify-between">
              <p className="text-sm text-gray-400 font-medium font-montserrat">Blood test</p>
              <img src={search} alr="seacrh" />
            </div>
            {labtestlistData.slice(0, 4).map((user, i) => (
              <div className="flex justify-between pt-4">
                <p className="text-sm text-gray-600 font-medium font-montserrat">{user.name}</p>
                <p className="text-sm text-brand-secondary font-medium font-rubik">+Add Test</p>
              </div>
            ))}


          </div>


          <button className="bg-brand-secondary mt-52 float-right text-white font-medium py-2 px-4 rounded text-sm">Save Changes</button>
        </div>

      </div>
    </>
  )
}
export default DiagnopreStep2;