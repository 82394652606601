import { PAT } from "../Constants/patientConstants";
import { VITALS } from "../Constants/vitalConstants";


const initialState = {
    hospitalcliniclistData: [],
    isError: false,
    isLoading: false,
    msg: '',
    errMsg: ''
};


const HospitalcliniclistReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PAT.REQUEST_HOSPITALCLINICLIST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case PAT.SUCCESS_HOSPITALCLINICLIST:
            return {
                ...state,
                isLoading: false,
                hospitalcliniclistData : payload,
                isError: false,              
            };
        case PAT.FAILED_HOSPITALCLINICLIST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };
        default:
            return state;
    }
};

export default HospitalcliniclistReducer;