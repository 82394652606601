import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default function FilterDocument(props) {
    const dispatch = useDispatch();


    const documentsList = [{ name: 'All', value: '' }, { name: 'Lab Report', value: 'Lab Report' }];
    const [filterlist, setfilterlist] = useState({});
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const handleChange = (e) => {
        setfilterlist({ ...filterlist, [e.target.name]: e.target.value });
    };

    const style = {
        marginLeft: "-140px",
    };

    const redirectTo = (event) => {
        event.preventDefault();
        props.onClose();
    };

    const save = (e) => {
        props.filterDocuments(filterlist, fromDate, toDate);
        e.preventDefault();
    };
    const cancel = (e) => {
        props.closePopup(e);
        e.preventDefault();
    };

    useEffect(() => {

    }, []);

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 antialiased justify-between border border-gray-200">
                            <div className="flex justify-between">
                                <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">
                                    Filter Documents
                                </h1>
                                {/* <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">Add New Address</h1> */}
                                <XIcon onClick={redirectTo} className="h-5 cursor-pointer" />
                            </div>
                            <hr className="mt-2"></hr>
                            <div className="flex pt-2 w-80 ">
                                <div className="w-full">
                                    <div className="lg:flex justify-between py-6 lg:space-x-10">
                                        <div className="relative mb-6">
                                            <div className="flex">
                                                <select
                                                    id="reportType"
                                                    name="reportType"
                                                    onChange={handleChange}
                                                    className="peer  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600">
                                                    {
                                                        documentsList.length === 0 ? <option className="py-1 text-sm text-green-600" value=""> All Documents </option> :
                                                            <>
                                                                {documentsList.map((res, i) => (
                                                                    <option className="py-1 text-sm text-green-600" value={res.value}>{res.name}</option>
                                                                ))}
                                                            </>}
                                                </select>
                                            </div>
                                            <label
                                                for="hospital"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                            >
                                                Type
                                            </label>
                                        </div>
                                    </div>

                                    <div className="lg:flex justify-between py-6 lg:space-x-10">
                                        <div className="relative">
                                            <div className="flex">
                                            <DatePicker minDate={moment().format('DD/MM/yyyy')} dateFormat={'DD/MM/yyyy'} placeholderText="From Date" value={fromDate?moment(fromDate).format("DD/MM/yyyy"):""} className="bg-transparent" onChange={(date) => { setFromDate(moment(date).format('MM/DD/yyyy')); }} />
                                            </div>
                                            <label
                                                for="hospital"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                            >
                                                From Date
                                            </label>
                                        </div>
                                    </div>

                                    <div className="lg:flex justify-between py-6 lg:space-x-10">
                                        <div className="relative">
                                            <div className="flex">
                                            <DatePicker minDate={moment().format('MM/DD/yyyy')} dateFormat={'MM/DD/yyyy'} placeholderText="To Date" value={moment(toDate).format("DD/MM/yyyy")} className="bg-transparent" onChange={(date) => { setToDate(moment(date).format('MM/DD/yyyy')); }} />
                                            </div>
                                            <label
                                                for="hospital"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                                            >
                                                To Date
                                            </label>
                                        </div>
                                    </div>



                                    <div className="flex justify-end">
                                        <button
                                            type="submit"
                                            onClick={cancel}
                                            className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            onClick={save}
                                            className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
