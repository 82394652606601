import React, { useEffect, useState } from "react";
import calender from "../Assets/Images/calendar.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getFilteredAppointmentList } from "../Redux/Actions/patientAction";
import SlotService from '../Redux/services/Slotservice'
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Timeline } from 'primereact/timeline';
import './TimelineDemo.css';
import axios from 'axios'
import { secureStorage } from "../Redux/Reducers/authReducer";
import store from '../Redux/store/store';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

function UserLeaveDetails() {
    const history = useHistory();
    const dispatch = useDispatch();
    const loggedInData = useSelector((state) => state.authReducer);
    const { userData } = loggedInData;
    const [showLeavePopup, setShowLP] = useState(false);
    const [showAppList, setShowAppList] = useState(false);
    const [showAppListUpdate, setShowAppListUpdate] = useState(false);
    const [leaveApptDetailsUpdate, setLeaveApptdetailsUpdate] = useState([]);
    const [index, setIndex] = useState();
    const [leaveApptDetails, setLeaveApptdetails] = useState([]);
    const [leaveFromDate, setLeaveFromDate] = useState();
    const [leaveToDate, setLeaveToDate] = useState();
    const [leaveReason, setLeaveReason] = useState();
    const [leaveSuccessView, setLeaveSuccess] = useState();
    const [cardData, setCardsData] = useState();
    const [timeLineData, setTimelineData] = useState();
    const [updateLeaveData, setUpdateDetails] = useState();
    const [updateView, setUpdateView] = useState(false);
    const [cancelConfrim, setcancelConfrim] = useState(false)
    const [cancelConfrimData, setcancelConfrimData] = useState()
    const [pageNo, setPageNo] = useState(1)
    const [pageDetails, setPageDetails] = useState()
    const [leaveNotes, setLeaveNotes] = useState()
    const [selectedYM, setSelectedYM] = useState(moment(new Date()).format("MM/YYYY"))
    const [filterYear, setfilterYear] = useState(moment(new Date()).format("YYYY"))
    const [filterMonth, setfilterMonth] = useState(moment(new Date()).format("MM"))

    useEffect(() => {
        viewLeaveDetails()
    }, [userData.code, pageNo, filterMonth, filterYear]);
    const viewLeaveDetails = () => {
        SlotService.getUserLeaveDetails(userData.code, pageNo - 1, filterYear, filterMonth).then((response) => {
            if (response && response.data) {
                const totalLeaves = response.data.summary.totalLeavePoint;
                const leaveAvailed = response.data.summary.totalLeaveDays;
                const leaveRemaining = totalLeaves > leaveAvailed ? totalLeaves - leaveAvailed : 0;
                const lop = response.data.summary.DoctorType !== 'Empaneled' ? 0 : (totalLeaves < leaveAvailed ? leaveAvailed - totalLeaves : 0);
                const newCardsData = {
                    countTotal: totalLeaves,
                    countLOP: lop,
                    countRemaining: leaveRemaining,
                    countAvailed: leaveAvailed
                }
                const formattedTimelineData = response.data?.leaveRecords?.map(item => {
                    return {
                        id: item.id,
                        time: moment(item.fromDate).format('ddd, DD MMM'),
                        reason: item.reason,
                        description: `Total Days: ${item.noLeaveDays}`,
                        status: item.status,
                        fromDate: item.fromDate,
                        toDate: item.toDate
                    };
                });
                let leaveNoteSentence = '';
                if (leaveAvailed === 0) {
                    leaveNoteSentence = 'You have not taken any leaves till now.';
                } else if (leaveAvailed > totalLeaves) {
                    leaveNoteSentence = `All your leaves have been exhausted. These leaves will be considered Leave On Pay (LOP)`;
                } else {
                    leaveNoteSentence = `You have already taken ${leaveAvailed} leaves. If you exceed more than ${totalLeaves}, it will be considered as Leave on Pay (LOP).`;
                }
                setLeaveNotes(leaveNoteSentence)
                setTimelineData(formattedTimelineData);
                setCardsData(newCardsData);
                setPageDetails(response.data.pagination.totalPages)
            } else {
            }
        }).catch((er) => {
        })
    }
    const onLeaveClick = (tab) => {
        setShowLP(true);
        setIndex(tab)
    }
    const changeDate = (e) => {
        setLeaveFromDate(moment(e).format("MM/DD/yyyy"));
    };
    const changeDateTo = (e) => {
        let ft = moment(e).format("MM/DD/yyyy")
        let fromDate1 = new Date(leaveFromDate);
        let toDate1 = new Date(ft);
        if (toDate1 < fromDate1) {
            return toast("Please select After From date")
        } else {
            setLeaveToDate(moment(e).format("MM/DD/yyyy"));
        }
    };
    const handleChangeReason = (e) => {
        setLeaveReason(e.target.value)
    }
    const onSelectedDate = (e) => {
        e.preventDefault()
        if (!leaveFromDate) {
            toast("Please select From date")
        } else if (!leaveToDate) {
            toast("Please select To date")
        } else if (!leaveReason) {
            toast("Please select Leave Reason")
        } else {
            let payload = {
                userId: userData.code,
                fromDate: moment(leaveFromDate).format("MM/DD/yyyy"),
                toDate: moment(leaveToDate).format("MM/DD/yyyy"),
                photoRequired: "Y",
                status: 1,
            };
            dispatch(getFilteredAppointmentList(payload)).then((result) => {
                setShowLP(false);
                setIndex(1)
                setShowAppList(true)
                setLeaveApptdetails(result)
            }).catch((error) => {
                toast("Something went wrong")
            });
        }
    }
    const onApplyLeave = async (tab) => {
        let reqData = {
            "userID": userData.code,
            "fromDate": moment(leaveFromDate).format("YYYY-MM-DD 00:mm:ss"),
            "toDate": moment(leaveToDate).format("YYYY-MM-DD 00:mm:ss"),
            "reason": leaveReason,
            "status": "applied"
        }
        try {
            let token = store.getState().authReducer.token;
            let s_token = secureStorage.getItem('token');
            const configurationObject = {
                method: 'POST',
                url: process.env.REACT_APP_BASEURL + `Leave/`,
                data: reqData,
                headers: {
                    Authorization: `Bearer ${s_token ? s_token : token}`,
                },
            };
            const response = await axios(configurationObject);
            if (response.data) {
                if (tab === 2) {
                    const idArray = leaveApptDetails.map(item => ({ id: item.id }));
                    SlotService.onLeaveStatusUpdate(idArray).then((res) => { setLeaveApptdetails([]) })
                }
                setLeaveFromDate()
                setLeaveToDate()
                setLeaveReason()
                setIndex(1)
                setShowAppList(false)
                setShowLP(false);
                setLeaveSuccess(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                return toast(error.response.data.message)
            }
        }
        // SlotService.onLeavePost(payload).then((res) => {
        //     if (res.data) {
        //         setLeaveFromDate()
        //         setLeaveToDate()
        //         setLeaveReason()
        //         setIndex(1)
        //         setShowAppList(false)
        //         setShowLP(false);
        //         setLeaveSuccess(true);
        //     }
        // }).catch((er)=>{
        // })
    }
    const onCloseAll = () => {
        setLeaveFromDate()
        setLeaveToDate()
        setLeaveReason()
        setIndex(1)
        setShowAppList(false)
        setShowLP(false);
        setLeaveSuccess(false);
        setUpdateView(false)
        viewLeaveDetails()
        setcancelConfrim(false)
        setShowAppListUpdate(false);
    }
    const onCancelLeave = (tab) => {
        let payload = {
            "status": "Cancelled"
        }
        SlotService.onLeaveCancel(tab.id, payload).then((res) => {
            toast("Leave cancelled successfully.")
            setcancelConfrim(false)
            viewLeaveDetails()
        })
    }
    const onCancelselect = (tab) => {
        setcancelConfrimData(tab)
        setcancelConfrim(true)
    }
    const onUpdateLeave = (tab) => {
        setUpdateDetails(tab)
        setUpdateView(true)
    }
    const updateLeave = async (e, tab) => {
        let payload = {
            "id": updateLeaveData?.id,
            "userID": userData.code,
            "fromDate": moment(updateLeaveData.fromDate).format("YYYY-MM-DD 00:mm:ss"),
            "toDate": moment(updateLeaveData.toDate).format("YYYY-MM-DD 00:mm:ss"),
            "reason": updateLeaveData.reason,
        }
        try {
            let token = store.getState().authReducer.token;
            let s_token = secureStorage.getItem('token');
            const configurationObject = {
                method: 'PUT',
                url: process.env.REACT_APP_BASEURL + `Leave/update/${updateLeaveData.id}`,
                data: payload,
                headers: {
                    Authorization: `Bearer ${s_token ? s_token : token}`,
                },
            };
            const response = await axios(configurationObject);
            if (response.data) {
                if (tab === 2) {
                    const idArray = leaveApptDetailsUpdate.map(item => ({ id: item.id }));
                    SlotService.onLeaveStatusUpdate(idArray).then((res) => { setLeaveApptdetailsUpdate([]) })
                }
                toast("Leave Details Updated")
                onCloseAll()
                setUpdateDetails()
                viewLeaveDetails()
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                return toast(error.response.data.message)
            }
        }
        // SlotService.onLeaveUpdate(updateLeaveData.id, payload).then((res) => {
        //     toast("Leave Details Updated")
        //     onCloseAll()
        //     setUpdateDetails()
        //     viewLeaveDetails()
        // })
    }
    const changeReasonUpdate = (e) => {
        setUpdateDetails({ ...updateLeaveData, [e.target.name]: e.target.value });
    }
    const fromDateUpdate = (date) => {
        setUpdateDetails({ ...updateLeaveData, ['fromDate']: moment(date).format("MM/DD/yyyy") });
    }
    const toDateUpdate = (date) => {
        let ft = moment(date).format("MM/DD/yyyy")
        let fromDate1 = new Date(updateLeaveData.fromDate);
        let toDate1 = new Date(ft);
        if (toDate1 < fromDate1) {
            return toast("Please select After From date")
        } else {
            setUpdateDetails({ ...updateLeaveData, ['toDate']: moment(date).format("MM/DD/yyyy") });
        }
    }
    const onUpdateApptList = () => {
        let payload = {
            userId: userData.code,
            fromDate: moment(updateLeaveData.fromDate).format("MM/DD/yyyy"),
            toDate: moment(updateLeaveData.toDate).format("MM/DD/yyyy"),
            photoRequired: "Y",
            status: 1,
        };
        dispatch(getFilteredAppointmentList(payload)).then((result) => {
            setLeaveApptdetailsUpdate(result)
            setShowAppListUpdate(true)
        }).catch((error) => {
            toast("Something went wrong")
        });
    }
    const customizedContent = (item) => {
        const fromDate = moment(item.fromDate);
        const isFutureOrToday = fromDate.isSameOrAfter(moment(), 'day');
        return (
            <div class="w-2/3 p-2 mt-4 py-4 px-3 mx-4 mr-4 bg-gray-100 rounded-xl border border-gray-200">
                <div className="flex flex-between mt-2 justify-between">
                    <p className="flex">{moment(item.fromDate).format("MM-DD-yyyy")}{" "} - {" "}{moment(item.toDate).format("MM-DD-yyyy")}</p>
                    <div className="flex">
                        {item.status.charAt(0).toUpperCase() + item.status.slice(1)}{" "}
                    </div>
                </div>
                <p className="flex text-gray mt-2 text-xl">{item.description}</p>
                <div className="flex flex-between mt-2 justify-between">
                    <div>{item.reason}</div>
                    <div className="flex">
                        {item.time}{" "}
                    </div>
                </div>
                {item.status != "Cancelled" && isFutureOrToday ?
                    <div className="flex flex-between justify-between">
                        <button
                            onClick={() => onUpdateLeave(item)}
                            className={`w-32 p-1 px-2 border border-brand-secondary bg-white mt-2 flex-end align-end justify-end text-black rounded-lg text-center text-base font-normal}`}
                        >
                            Update
                        </button>

                        <button
                            onClick={() => onCancelselect(item)}
                            className={`w-32 p-1 px-2 bg-brand-white border border-green-600 mt-2 ml-5 flex-end align-end justify-end  text-brand-primary  rounded-lg text-center text-base font-normal}`}
                        >
                            Cancel
                        </button>
                    </div>
                    : null
                }

            </div>
        );
    };
    const handlePrevious = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (pageNo > 1) {
            setPageNo(pageNo - 1);
        }
    };
    const handleNext = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (pageNo < pageDetails) {
            setPageNo(pageNo + 1);
        }
    };
    const filterByDate = (date) => {
        const year = moment(date).format("YYYY"); // Extract year
        const month = moment(date).format("MM"); // Extract month
        const YM = moment(date).format("MM/YYYY")
        setfilterYear(year)
        setfilterMonth(month)
        setSelectedYM(YM)
        setPageNo(1)
    }
    return (
        <>
            <ToastContainer />
            <>
                <div className="border border-gray-200 my-3 p-5  rounded-xl">
                    <div className="flex flex-between justify-between">
                        <div><b>Leave Status</b></div>
                        <div className="w-max py-2 px-5 bg-green-400 text-brand-primary rounded-lg flex">
                            <span
                                onClick={() => onLeaveClick(1)}
                                className="text-sm font-medium text-white "
                            >
                                Apply leave
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-center gap-12 justify-center mb-4 overflow-x-hidden">
                        <div class="w-72 mt-2 py-8  bg-gray-100 border border-gray-400 rounded-xl">
                            <div className="flex flex-center justify-center">Total Leaves</div>
                            <div className="flex flex-center mt-3 justify-center text-xl font-medium font-rubik"><b>{cardData?.countTotal}</b></div>
                        </div>
                        <div class="w-72 mt-2 py-8  bg-gray-100 border border-gray-400 rounded-xl">
                            <div className="flex flex-center justify-center">Leave Availed</div>
                            <div className="flex flex-center mt-3 justify-center text-xl font-medium font-rubik"><b>{cardData?.countAvailed}</b></div>
                        </div>
                        <div class="w-72 mt-2 py-8  bg-gray-100 border border-gray-400 rounded-xl">
                            <div className="flex flex-center justify-center">Leave Remaining</div>
                            <div className="flex flex-center mt-3 justify-center text-xl font-medium font-rubik"><b>{cardData?.countRemaining}</b></div>
                        </div>
                        <div class="w-72 mt-2 py-8  bg-gray-100 border border-gray-400 rounded-xl">
                            <div className="flex flex-center justify-center">LOP(Loss Of Pay)</div>
                            <div className="flex flex-center mt-3 justify-center text-xl font-medium font-rubik"><b>{cardData?.countLOP}</b></div>
                        </div>
                    </div>
                    {/* {cardData?.countRemaining === 0 &&
                        <div className="p-2 mt-7 mb-2 mx-10 bg-gray border border-gray-400 rounded-xl">
                            <p className="mt-2"><b>Note :</b></p>
                            <p className="mt-2"> You have used all your 3 leaves. so you can apply for the leave with <b>LOP (Loss of Payment)</b></p>
                        </div>
                    } */}
                    <div className="flex mt-4 flex-between justify-between">
                        <div><b>Leave History</b></div>
                        <div>{moment(new Date()).format('ddd, DD MMM')}</div>
                    </div>

                    <div className="flex mt-4 flex-between justify-between">
                        <div></div>
                        <div className="flex">
                            <div className="mt-4 mr-2"><p>Selected Month</p></div>
                            <div className="w-32 p-2 overflow-hidden bg-white shadow-sm border border-gray-400 rounded-lg mt-2">
                                <DatePicker
                                    showMonthYearPicker
                                    showYearDropdown
                                    className="bg-transparent"
                                    dateFormat={"MM/YYYY"}
                                    value={selectedYM ? selectedYM : null}
                                    onChange={date => filterByDate(date)}
                                    placeholderText="Filter by Month/Year"
                                />
                            </div>
                        </div>

                    </div>
                    <div className="mt-10 align-left">
                        {timeLineData && timeLineData.length ?
                            <Timeline value={timeLineData} icon={'pi pi-shopping-cart'}
                                opposite={(item) => item.time}
                                align='left' className="customized-timeline" content={customizedContent} />
                            :
                            <div class="font-medium py-4 text-center text-gray-500" colspan="5">No Data found</div>
                        }
                    </div>
                    <div className="flex mt-4 flex-center justify-center">
                        <button
                            onClick={(e) => handlePrevious(e)}
                            disabled={pageNo === 1}
                            type="button"
                            className="disabled:opacity-50 border border-gray-400 rounded-lg sm:w-20 w-10 h-12 pl-0 sm:pl-5"
                        >
                            <ChevronLeftIcon className="w-8" />
                        </button>
                        <div className="p-2 mx-3">Page {pageNo} of {pageDetails}</div>
                        <button
                            onClick={(e) => handleNext(e)} disabled={pageNo === pageDetails}
                            type="button"
                            className="disabled:opacity-50 border border-gray-200 rounded-lg sm:w-20 w-10 h-12 pl-0 sm:pl-5"
                        >
                            <ChevronRightIcon className="w-8" />
                        </button>
                    </div>
                </div>
            </>
            <Dialog
                header={index === 1 ? "Apply Leave" : "Select Leave Days"}
                visible={showLeavePopup}
                modal={true}
                style={{ width: "30vw", height: index === 1 ? "13vw" : "20vw" }}
                onHide={() => onCloseAll()}
            >
                <>
                    {index === 1 ?
                        <div className="border border-gray-200 my-3 p-5">
                            <p className=''>
                                <b>Do you want to apply for a leave?</b>
                            </p>
                            <div className="flex justify-center">
                                <button
                                    onClick={() => onLeaveClick(2)}
                                    className={`p-2 w-28 bg-green-400 mt-5 flex-end align-end justify-end  text-white  rounded-lg text-center text-base font-normal}`}
                                >
                                    Proceed
                                </button>
                                <button
                                    onClick={() => onCloseAll()}
                                    className={`p-2 w-28 bg-brand-white border border-green-600 mt-5 ml-5 flex-end align-end justify-end  text-brand-primary  rounded-lg text-center text-base font-normal}`}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        :
                        <div className="border border-gray-200 my-3 p-5">
                            <p className=''>
                                <b>Select Date</b>
                            </p>
                            <div className="flex justify-between">
                                <div className="w-48 p-2 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl mt-2">
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        minDate={moment().add(1, 'day').toDate()}
                                        className="bg-transparent"
                                        dateFormat={"MM/DD/yyyy"}
                                        value={leaveFromDate ? leaveFromDate : ""}
                                        onChange={changeDate}
                                        placeholderText="Start Date"
                                    />
                                </div>
                                <div className="w-48 p-2 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl mt-2">
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        minDate={moment().add(1, 'day').toDate()}
                                        dateFormat={"MM/DD/yyyy"}
                                        value={leaveToDate ? leaveToDate : ""}
                                        className="bg-transparent"
                                        onChange={changeDateTo}
                                        placeholderText="End Date"
                                    />
                                </div>
                            </div>
                            <p className='mt-2'>
                                <b>Select Reason</b>
                            </p>
                            <div className="w-48 p-2 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl mt-2">
                                <select
                                    id="reason"
                                    name="reason"
                                    value={leaveReason}
                                    className={`w-11/12`}
                                    onChange={handleChangeReason}
                                >
                                    <option value="">Select Reason</option>
                                    <option value="Emergency">Emergency</option>
                                    <option value="Casual">Casual</option>
                                    <option value="Sick">Sick</option>
                                    <option value="Travelling">Travelling</option>
                                </select>
                            </div>
                            <div className="flex justify-center">
                                <button
                                    onClick={(e) => onSelectedDate(e)}
                                    className={`p-2 w-28 bg-green-400 mt-5 flex-end align-end justify-end  text-white  rounded-lg text-center text-base font-normal}`}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    }
                </>
            </Dialog>
            <Dialog
                header={"Appointment List"}
                visible={showAppList}
                modal={true}
                style={{ width: "58vw", height: "34vw" }}
                onHide={() => onCloseAll()}
            >
                <>
                    <div className="border border-gray-200 my-3 p-5">
                        <div className="flex flex-between justify-between">
                            <div><b>Appointment List</b></div>
                            <div className="flex">
                                <p className="ml-2">{moment(leaveFromDate).format('ddd, DD MMM')}</p>
                            </div>
                        </div>
                        {leaveApptDetails && leaveApptDetails.length ?
                            <>
                                <div className="flex gap-3 overflow-x-hidden">
                                    {leaveApptDetails.map((res, i) => (
                                        <div class="w-80 mt-2 border border-gray-400 rounded-xl justify-between">

                                            <div className="p-2">
                                                <div class="flex justify-left">
                                                    <img src={calender} alt="more" className="w-8 h-8" />
                                                    <p class="text-medium font-medium text-xl ml-2 mt-1 font-rubik text-brand-secondary">
                                                        {res.patientName}
                                                    </p>
                                                </div>
                                                <div class="flex mt-2 justify-left">
                                                    <b>Appt Id:</b>
                                                    <p className="ml-2">{res.id}</p>
                                                </div>
                                                <div class="flex mt-2 justify-left">
                                                    <b>Cheif complaint:</b>
                                                    <p className="ml-2">{res.consultationsReason}</p>
                                                </div>
                                            </div>
                                            <div className="flex mt-2 p-2 bg-brand-secondary rounded-bl-lg rounded-br-lg justify-between">
                                                <p className="text-white">{moment(res.whenAppointment).format("DD/MM/yyyy")}</p>
                                                <p className="text-white">{res.fromTime} - {res.toTime}{" "}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                            :
                            <div class="font-medium mt-5 py-4 text-center text-gray-500" colspan="5">You don't have any appointment on selected date</div>
                        }
                        {leaveApptDetails && leaveApptDetails.length ?
                            <p className='mt-4'>
                                <b>Appointment Message</b>
                                <p className="mt-2">Please look that there are appointments scheduled for the dates of the requested leave.</p>
                            </p>
                            : null
                        }
                        {leaveApptDetails && leaveApptDetails.length ?
                            <div className="flex justify-center">
                                <button
                                    onClick={() => onApplyLeave(2)}
                                    className={`p-2 w-40 bg-green-400 mt-5 flex-end align-end justify-end  text-white  rounded-lg text-center text-base font-normal}`}
                                >
                                    Apply Leave
                                </button>
                            </div>
                            :
                            <div className="flex justify-center">
                                <button
                                    onClick={() => onApplyLeave(1)}
                                    className={`p-2 w-40 bg-green-400 mt-5 flex-end align-end justify-end  text-white  rounded-lg text-center text-base font-normal}`}
                                >
                                    Apply Leave
                                </button>
                            </div>
                        }
                        <div className="p-2 mt-3 border border-gray-400">
                            <p className="mt-2">{leaveNotes ? leaveNotes : ""}</p>
                        </div>
                    </div>
                </>
            </Dialog>
            <Dialog
                header={"Leave Request"}
                visible={leaveSuccessView}
                modal={true}
                style={{ width: "30vw", height: "13vw" }}
                onHide={() => onCloseAll()}
            >
                <>
                    <div className="border border-gray-200 my-3 p-5">
                        <p className=''>
                            Your Request has been sent to the back office team, they will reach out to you shortly.
                        </p>
                        <div className="flex justify-center">
                            <button
                                onClick={() => onCloseAll()}
                                className={`p-2 w-28 bg-brand-white border border-green-600 mt-5 ml-5 flex-end align-end justify-end  text-brand-primary  rounded-lg text-center text-base font-normal}`}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </>
            </Dialog>
            <Dialog
                header={"Update Leave"}
                visible={updateView}
                modal={true}
                style={{ width: "30vw", height: "20vw" }}
                onHide={() => onCloseAll()}
            >
                <>
                    <div className="border border-gray-200 my-3 p-5">
                        <p className=''>
                            <b>Select Date</b>
                        </p>
                        <div className="flex justify-between">
                            <div className="w-48 p-2 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl mt-2">
                                <DatePicker
                                    showMonthDropdown
                                    showYearDropdown
                                    minDate={moment().add(1, 'day').toDate()}
                                    className="bg-transparent"
                                    dateFormat={"MM/DD/yyyy"}
                                    value={moment(updateLeaveData?.fromDate).format("MM/DD/yyyy")}
                                    onChange={fromDateUpdate}
                                    placeholderText="Start Date"
                                />
                            </div>
                            <div className="w-48 p-2 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl mt-2">
                                <DatePicker
                                    showMonthDropdown
                                    showYearDropdown
                                    minDate={moment().add(1, 'day').toDate()}
                                    dateFormat={"MM/DD/yyyy"}
                                    value={moment(updateLeaveData?.toDate).format("MM/DD/yyyy")}
                                    className="bg-transparent"
                                    onChange={toDateUpdate}
                                    placeholderText="End Date"
                                />
                            </div>
                        </div>
                        <p className='mt-2'>
                            <b>Select Reason</b>
                        </p>
                        <div className="w-48 p-2 overflow-hidden bg-white shadow-sm border border-gray-200 rounded-xl mt-2">
                            <select
                                id="reason"
                                name="reason"
                                value={updateLeaveData?.reason}
                                className={`w-11/12`}
                                onChange={changeReasonUpdate}
                            >
                                <option value="">Select Reason</option>
                                <option value="Emergency">Emergency</option>
                                <option value="Casual">Casual</option>
                                <option value="Sick">Sick</option>
                                <option value="Travelling">Travelling</option>
                            </select>
                        </div>
                        <div className="flex justify-center">
                            <button
                                onClick={(e) => onUpdateApptList(e)}
                                className={`p-2 w-28 bg-green-400 mt-5 flex-end align-end justify-end  text-white  rounded-lg text-center text-base font-normal}`}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </>
            </Dialog>
            <Dialog
                header={"Cancel Leave"}
                visible={cancelConfrim}
                modal={true}
                style={{ width: "30vw", height: "13vw" }}
                onHide={() => onCloseAll()}
            >
                <>
                    <div className="border border-gray-200 my-3 p-5">
                        <p className=''>
                            Are you sure you want to cancel your leave?
                        </p>
                        {cancelConfrimData &&
                            <div className="flex justify-center">
                                <button
                                    onClick={() => onCancelLeave(cancelConfrimData)}
                                    className={`p-2 w-28 bg-green-400 mt-5 flex-end align-end justify-end  text-white  rounded-lg text-center text-base font-normal}`}
                                >
                                    Yes
                                </button>
                                <button
                                    onClick={() => onCloseAll()}
                                    className={`p-2 w-28 bg-brand-white border border-green-600 mt-5 ml-5 flex-end align-end justify-end  text-brand-primary  rounded-lg text-center text-base font-normal}`}
                                >
                                    No
                                </button>
                            </div>
                        }
                    </div>
                </>
            </Dialog>
            <Dialog
                header={"Appointment List"}
                visible={showAppListUpdate}
                modal={true}
                style={{ width: "58vw", height: "34vw" }}
                onHide={() => onCloseAll()}
            >
                <>
                    <div className="border border-gray-200 my-3 p-5">
                        <div className="flex flex-between justify-between">
                            <div><b>Appointment List</b></div>
                            <div className="flex">
                                <p className="ml-2">{moment(updateLeaveData?.fromDate).format('ddd, DD MMM')}</p>
                            </div>
                        </div>
                        {leaveApptDetailsUpdate && leaveApptDetailsUpdate.length ?
                            <>
                                <div className="flex gap-3 overflow-x-hidden">
                                    {leaveApptDetailsUpdate.map((res, i) => (
                                        <div class="w-80 mt-2 border border-gray-400 rounded-xl justify-between">

                                            <div className="p-2">
                                                <div class="flex justify-left">
                                                    <img src={calender} alt="more" className="w-8 h-8" />
                                                    <p class="text-medium font-medium text-xl ml-2 mt-1 font-rubik text-brand-secondary">
                                                        {res.patientName}
                                                    </p>
                                                </div>
                                                <div class="flex mt-2 justify-left">
                                                    <b>Appt Id:</b>
                                                    <p className="ml-2">{res.id}</p>
                                                </div>
                                                <div class="flex mt-2 justify-left">
                                                    <b>Cheif complaint:</b>
                                                    <p className="ml-2">{res.consultationsReason}</p>
                                                </div>
                                            </div>
                                            <div className="flex mt-2 p-2 bg-brand-secondary rounded-bl-lg rounded-br-lg justify-between">
                                                <p className="text-white">{moment(res.whenAppointment).format("DD/MM/yyyy")}</p>
                                                <p className="text-white">{res.fromTime} - {res.toTime}{" "}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                            :
                            <div class="font-medium mt-5 py-4 text-center text-gray-500" colspan="5">You don't have any appointment on selected date</div>
                        }
                        {leaveApptDetailsUpdate && leaveApptDetailsUpdate.length ?
                            <p className='mt-4'>
                                <b>Appointment Message</b>
                                <p className="mt-2">Please look that there are appointments scheduled for the dates of the requested leave.</p>
                            </p>
                            : null
                        }
                        <div className="flex justify-center">
                            {leaveApptDetailsUpdate && leaveApptDetailsUpdate.length ?
                                <button
                                    onClick={(e) => updateLeave(e, 2)}
                                    className={`p-2 w-40 bg-green-400 mt-5 flex-end align-end justify-end  text-white  rounded-lg text-center text-base font-normal}`}
                                >
                                    Update Leave
                                </button>
                                :
                                <button
                                    onClick={(e) => updateLeave(e, 1)}
                                    className={`p-2 w-40 bg-green-400 mt-5 flex-end align-end justify-end  text-white  rounded-lg text-center text-base font-normal}`}
                                >
                                    Update Leave
                                </button>
                            }
                        </div>
                        <div className="p-2 mt-3 border border-gray-400">
                            <p className="mt-2">{leaveNotes ? leaveNotes : ""}</p>
                        </div>
                    </div>
                </>
            </Dialog>
        </>
    );
}
export default UserLeaveDetails;
