import React, { useEffect, useState } from 'react'
import search from '../Assets/Images/doctsearch.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReferalFrom, ReferHospital, ReferPatientcurebay } from '../Redux/Actions/RefralAction';
import ProfileSidebar from './profileSidebar';
import ProfileNamebar from './profileNamebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReferSuccessfulpopup from "./ReferSuccessfulpopup";
import moment from "moment";
import { Dropdown } from 'react-bootstrap'
import { MenuIcon } from '@heroicons/react/outline'
function ReferPatient(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showSucesspopup, setshowSucesspopup] = useState(false);
    const [loading, setLoading] = useState(false)

    const loggedInData = useSelector((state) => state.authReducer);
    const { userData } = loggedInData;
    const [fromLocation, setfromLocation] = useState({
        fromLocat: "patient"
    })

    useEffect(() => {
    }, []);
    const loginObj = JSON.parse(localStorage.getItem("loginObj"));
    const userDataLogin = loginObj.user;
    const [addList, setAddList] = useState({
        "referId": userDataLogin.code,
        "createdBy": userDataLogin.code,
        "modifiedBy": userDataLogin.code,
        "status": 1,
        "referByName": userDataLogin.userConsultationFees[0].userName,
        "referType": "P",
        "referDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "createddate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "modifieddate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "referById": userDataLogin.code
    });
    const onSave = (e) => {
        setLoading(true)
        if (!addList.referToName) {
            setLoading(false)
            toast("Please Enter Patient name");
        } else if (!addList.mobile) {
            setLoading(false)
            toast("Please Enter Mobile Number");
        } else if (addList.mobile.length !== 10) {
            setLoading(false)
            toast("Please Enter valid 10 digit Mobile Number");
        } else {
            dispatch(ReferPatientcurebay(addList)).then((result) => {
                if (result === 1) {
                    setLoading(false)
                    setshowSucesspopup(true)
                }
            })
        }
    }
    const handleChange = (e) => {
        setAddList({ ...addList, [e.target.name]: e.target.value });
    }
    return (
        <>


            <div className="lg:block hidden">
                <ProfileNamebar></ProfileNamebar>
            </div>
            <ToastContainer />
            <div className="flex justify-between ">
                <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">
                    <ProfileSidebar></ProfileSidebar>
                </div>
                <div className="w-11/12 lg:ml-10 ml-4 mt-3 border boder-gray-200 rounded-lg bg-white mr-8">
                    {/* <div className="w-11/12 lg:ml-10 ml-4 mt-3 border boder-gray-200 rounded-lg bg-white mr-8"> */}
                    <div className="flex justify-between md:justify-start lg:justify-between">
                        <div className="hidden md:block lg:hidden relative p-3 top-1" >
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />

                                </Dropdown.Toggle>

                                <Dropdown.Menu className="z-10" >
                                    <ProfileSidebar />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <h1 className="text-medium font-medium text-2xl p-3 font-16 text-brand-secondary font-rubik">
                            Refer a Patient
                        </h1>
                    </div>
                    <hr />
                    <div className="py-8 pr-5 sm:pr-0 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                        <div className="relative pt-2 pl-5">
                            <label for="referToName" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-2 ">Patient<span> *</span></label>
                            <div class="flex ">
                                <input autocomplete="off" id="referToName" name="referToName" value={addList.referToName} className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter patient name" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="relative pt-4 pl-5">
                            <label for="mobile" className="absolute left-0 -top-3.5 text-green-600 text-sm pl-5 pt-4 ">Mobile Number<span> *</span></label>
                            <div class="flex ">
                                <input autocomplete="off" id="mobile" name="mobile" type="text"
                                    pattern="[0-9]*"
                                    maxLength="10"
                                    onKeyPress={(event) => {
                                        if (!/[0-9.]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={addList.mobile}
                                    className="peer bg-transparent h-10 w-full sm:w-6/12 border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Mobile Number" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="flex justify-start pl-5">

                            <button disabled={loading} onClick={onSave} className={`disabled:opacity-50 bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md  mt-25 py-2 px-3 mr-2`}>
                                Send
                            </button>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
            {/* <div className="lg:hidden block">
                <MobileReferFrom />
            </div> */}
            {showSucesspopup ? (
                <ReferSuccessfulpopup FromLocationID={fromLocation}
                    closePopup={() => setshowSucesspopup(!showSucesspopup)}
                ></ReferSuccessfulpopup>
            ) : null}
        </>
    )
}
export default ReferPatient;
