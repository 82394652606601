
import { REFER } from "../Constants/ReferalConstant";



const initialState = {
    referredToList: [],
    referredToListdoctor: [],
    referredFromList: [],
    referredClinicList: [],
    referredDoctorList: [],
    isError: false,
    isLoading: false,
    msg: '',
    errMsg: '',
    referredPatientList: [],
};


const ReferalReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case REFER.REQUEST_REFERFROM:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case REFER.SUCCESS_REFERFROM:
            return {
                ...state,
                isLoading: false,
                referredFromList: payload,
                isError: false,
            };
        case REFER.FAILED_REFERFROM:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };

        case REFER.REQUEST_REFERTO:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case REFER.SUCCESS_REFERTO:
            return {
                ...state,
                isLoading: false,
                referredToList: payload,
                isError: false,
            };
        case REFER.FAILED_REFERTO:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };


        case REFER.REQUEST_REFERTODOCTOR:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case REFER.SUCCESS_REFERTODOCTOR:
            return {
                ...state,
                isLoading: false,
                referredToListdoctor: payload,
                isError: false,
            };
        case REFER.FAILED_REFERTODOCTOR:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };

        case REFER.REQUEST_REFERCLINICLIST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case REFER.SUCCESS_REFERCLINICLIST:
            return {
                ...state,
                isLoading: false,
                referredClinicList: payload,
                isError: false,
            };
        case REFER.FAILED_REFERCLINICLIST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };
        case REFER.REQUEST_REFERDOCTORLIST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case REFER.SUCCESS_REFERDOCTORLIST:
            return {
                ...state,
                isLoading: false,
                referredDoctorList: payload,
                isError: false,
            };
        case REFER.FAILED_REFERDOCTORLIST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };

        case REFER.REQUEST_REFERPATIENTLIST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case REFER.SUCCESS_REFERPATIENTLIST:
            return {
                ...state,
                isLoading: false,
                referredPatientList: payload,
                isError: false,
            };
        case REFER.FAILED_REFERPATIENTLIST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };
        default:
            return state;
    }
};

export default ReferalReducer;