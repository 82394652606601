import React from 'react';
import { useSelector } from 'react-redux';
import search from '../Assets/Images/doctsearch.svg';




function DoctorPatientrefered() {
  const {userData}=useSelector(state=>state.userData)
    return (
      <>
{/* 1st row */}
        <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5 flex-wrap justify-start"> 
                <div className="overflow-hidden">
                    <div>
                        <p className="text-xl text-gray-900 font-medium font-rubik">Namaste, Dr {userData.username}</p>
                    </div>
                </div>
                <div className="overflow-hidden ">               
                    <div className="flex flex-wrap justify-end">
                        <p className="text-sm text-gray-900 font-medium font-rubik">24th July, 10:30am</p>
                    </div>
                </div>
        </div>





{/* 2nd row */}
<div className="flex justify-between ">
<div className="w-1/4 ml-10 mt-3 ">
        <div className="rounded-lg shadow-lg bg-white-600 w-full px-2  p-3 mb-2 antialiased border border-gray-200">


            <div className="flex justify-between content-center ">
                <p  className='text-brand-secondary text-medium font-medium text-sm font-rubik my-2' >My Profile</p>
                <svg
                    className="mt-2 h-5 w-auto text-brand-secondary"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                    ></path>
                </svg>

            </div>
            <hr />



            <div className="flex justify-between content-center ">
                <p  className='text-brand-secondary text-medium font-medium text-sm font-rubik my-2'>Prescriptions</p>
                <svg
                    className="mt-2 h-5 w-auto text-brand-secondary"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                    ></path>
                </svg>

            </div>
            <hr />



            <div className="flex justify-between content-center ">
                <p  className='text-brand-secondary text-medium font-medium text-sm font-rubik my-2'>Billing History</p>

                <svg
                    className="mt-2 h-5 w-auto text-brand-secondary"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                    ></path>
                </svg>



            </div>
            <hr />

            <div className="flex justify-between content-center ">
                <p className='text-brand-secodary text-medium font-medium text-sm font-rubik my-2'>Consultations</p>

                <svg
                    className="mt-2 h-5 w-auto text-brand-secodary"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                    ></path>
                </svg>

            </div>
            <hr />


            <div className="flex justify-between content-center ">
                <p className='text-brand-secondary text-medium font-medium text-sm font-rubik my-2'>Referrals</p>
                <svg className="h-5 w-5 text-brand-secondary"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" 
                stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  
                <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="6 15 12 9 18 15" />
                </svg>

            </div>
            <hr />
            <div className="relative pl-8">
                                <div x-show="dropdownOpen" className="">
                                    <p className='text-green-400 font-montserrat text-medium font-medium text-xs my-2'>Referred to</p>
                                    <p className='text-brand-secodary font-montserrat text-medium font-medium text-xs my-2'>Referred From</p>
                                 

                                </div>
                            </div>

           
            <div className="flex justify-between content-center ">
                <p className='text-red-600 text-medium font-medium text-sm font-rubik my-2'>Logout </p>
                
            </div>
            

        </div>
    </div>
{/* table start */}
                <div className="w-3/4 ml-10 mt-3 border boder-gray-200 rounded-lg">
                <div className="flex space-x-3 pt-5 pl-5 pb-5">
                    <img src={search} alt="search" className="w-4"/>
                    <p className="text-sm text-brand-secondary font-medium">Search or Filter </p>
                </div>

                <div className="flex flex-col">
  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-0">
    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-0">
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y ">
          <thead >
            <tr>
            <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
                <input id="radio2" type="radio" name="radio" className="hidden" />
                <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 flex-no-shrink"></span>
                Patient Name
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
              Apt. No
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
              Type
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
              Location
              </th>
             
              <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
              Sypmtoms
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 font-montserrat tracking-wider">
              Referred To
              </th>
              
            </tr>
          </thead>
          <tbody className=" divide-y divide-gray-200">
            <tr>
              <td className="px-4 py-4 flex ">
             
                <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 mt-1"></span>
                <p className="text-sm font-medium text-gray-600 font-montserrat">Ahmed Khan</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">523130</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">In-Person</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
              <p className="text-sm font-medium text-gray-600 font-montserrat">Jupiter</p>
              </td>
              
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">Cough and cold</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600  font-montserrat">Dr. Priya Sharma</p>
              </td>
            </tr>

            <tr>
              <td className="px-4 py-4 flex ">
             
                <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 mt-1"></span>
                <p className="text-sm font-medium text-gray-600 font-montserrat">Ahmed Khan</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">523130</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">In-Person</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
              <p className="text-sm font-medium text-gray-600 font-montserrat">Jupiter</p>
              </td>
              
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">Cough and cold</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600  font-montserrat">Dr. Priya Sharma</p>
              </td>
            </tr>

            <tr>
              <td className="px-4 py-4 flex ">
             
                <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 mt-1"></span>
                <p className="text-sm font-medium text-gray-600 font-montserrat">Ahmed Khan</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">523130</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">In-Person</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
              <p className="text-sm font-medium text-gray-600 font-montserrat">Jupiter</p>
              </td>
              
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">Cough and cold</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600  font-montserrat">Dr. Priya Sharma</p>
              </td>
            </tr>

            <tr>
              <td className="px-4 py-4 flex ">
             
                <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 mt-1"></span>
                <p className="text-sm font-medium text-gray-600 font-montserrat">Ahmed Khan</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">523130</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">In-Person</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
              <p className="text-sm font-medium text-gray-600 font-montserrat">Jupiter</p>
              </td>
              
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">Cough and cold</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600  font-montserrat">Dr. Priya Sharma</p>
              </td>
            </tr>


            <tr>
              <td className="px-4 py-4 flex ">
             
                <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 mt-1"></span>
                <p className="text-sm font-medium text-gray-600 font-montserrat">Ahmed Khan</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">523130</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">In-Person</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
              <p className="text-sm font-medium text-gray-600 font-montserrat">Jupiter</p>
              </td>
              
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">Cough and cold</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600  font-montserrat">Dr. Priya Sharma</p>
              </td>
            </tr>

            <tr>
              <td className="px-4 py-4 flex ">
             
                <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 mt-1"></span>
                <p className="text-sm font-medium text-gray-600 font-montserrat">Ahmed Khan</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">523130</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">In-Person</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
              <p className="text-sm font-medium text-gray-600 font-montserrat">Jupiter</p>
              </td>
              
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">Cough and cold</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600  font-montserrat">Dr. Priya Sharma</p>
              </td>
            </tr>

            <tr>
              <td className="px-4 py-4 flex ">
             
                <span className="w-3 h-3 inline-block mr-2 rounded-full border border-gray-500 mt-1"></span>
                <p className="text-sm font-medium text-gray-600 font-montserrat">Ahmed Khan</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">523130</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">In-Person</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
              <p className="text-sm font-medium text-gray-600 font-montserrat">Jupiter</p>
              </td>
              
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600 font-montserrat">Cough and cold</p>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <p className="text-sm font-medium text-gray-600  font-montserrat">Dr. Priya Sharma</p>
              </td>
            </tr>

            

         

          
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>




                </div>

</div>
</>
)
}
export default DoctorPatientrefered;
