import React, { useEffect, useState } from 'react';
import search from '../Assets/Images/doctsearch.svg';
import ProfileSidebar from './profileSidebar';
import ProfileNamebar from './profileNamebar';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingdetails } from '../Redux/Actions/doctAction';
import moment from 'moment';
import { SearchIcon } from '@heroicons/react/outline'
import {CircularProgress} from '@material-ui/core'
import {Dropdown} from 'react-bootstrap'
import { MenuIcon} from '@heroicons/react/outline'
function MobileBillingHistory() {
    const dispatch = useDispatch();
    const loggedInData = useSelector((state) => state.authReducer);
    const { billingList , isLoading } = useSelector((state) => state.doctorconsultationlist);
    const [list, setList] = useState([]);
    console.log("billingList", billingList)
    const { userData } = loggedInData;
  
    useEffect(() => {
      dispatch(getBillingdetails(userData.code));
    }, [])
  
  
    const onSearch = (text) => {
      let val = text ? text : '';
      // @ts-ignore
      var newArr = billingList
      console.log("as", newArr)
      // @ts-ignore
      let newArray = newArr.filter((data) => JSON.stringify(data).toLowerCase().indexOf(val.toLowerCase()) !== -1);
      setList(newArray)
    }
  
  
  
  
    useEffect(() => {
      if (billingList && billingList.length > 0) {
        onSearch();
      }
    }, [billingList.length])


    return (
        <>


            <div className="lg:block hidden">
                <ProfileNamebar></ProfileNamebar>
            </div>

            {/* 2nd row */}
            <div className="justify-between ">
                <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">

                    <ProfileSidebar></ProfileSidebar>
                </div>
                {/* table start */}
                <div className="mt-3 mx-6 border boder-gray-200 rounded-lg">
                    <div className="flex  pt-0 pl-3 pr-10 pb-0 bg-white rounded-lg relative ">
                        <div className="bg-brand-secondary rounded p-1 absolute right-2 top-3">
                            <SearchIcon className="w-5" stroke={'white'} />
                        </div>
                        <input type="text" onChange={(e) => { onSearch(e.target.value) }} className="text-sm text-brand-secondary font-medium  pt-4 pb-4 w-full outline-none" placeholder="Search or Filter" />
                    </div>
                </div>
                <div className = "hidden md:flex lg:hidden mx-6 mt-3" >
                <div className = "hidden md:block lg:hidden relative mr-4" >
                <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
              <MenuIcon className="block h-6 w-6" aria-hidden="true"/>

              </Dropdown.Toggle>

              <Dropdown.Menu className = "z-10" >
                <ProfileSidebar/>
              </Dropdown.Menu>
            </Dropdown>
            </div> <h1>Billing History</h1>
                </div>
                {isLoading && list.length === 0 && 
                <div className = "flex justify-center items-center" style ={{height: "50vh"}}>
                <CircularProgress/>
            </div>}
                <div className="mx-6 mt-3 space-y-4 space-y-4 mb-20 md:flex lg:block md:flex-wrap justify-between">
                { list.length === 0 ? <div className = "font-medium py-4 text-gray-500 flex justify-center items-center" style ={{height : "50vh"}} >No Data found</div> :
                    list.map((res, i) => (
                        <div className=" w-full border-solid border-2 rounded-2xl relative p-4 font-rubik mb-4 md:mb-0 lg:mb-4">
                            <div className="flex ">
                                <div className="text-left mr-2 w-2/4" >
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Patient Name</span>
                                        <p className="text-sm font-montserrat text-base">{res.patientName ? res.patientName : 'NA'}</p>
                                    </div>
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Type</span>
                                        <p className="text-sm  font-montserrat text-base">{res.serviceName == "Video Appointment" ? 'Video' : 'In Person'}</p>
                                    </div>
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Date</span>
                                        <p className="text-sm  font-montserrat text-base">{moment(res.paymentDateTime).format('DD/MM/yyyy')}</p>
                                    </div>

                                </div>
                                <div className="text-left w-2/4" >
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Apt. No</span>
                                        <p className="text-sm font-montserrat text-base">{res.appointmentId}</p>
                                    </div>
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Location</span>
                                        <p className="text-sm font-montserrat text-base">{res.hospitalName ? res.hospitalName : 'NA'}</p>
                                    </div>
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Bill AMount</span>
                                        <p className="text-sm  font-montserrat text-base">{res.totalAmount}/-</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
export default MobileBillingHistory;
