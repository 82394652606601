/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect, dispatch } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes'
import {
    sendOtp, verifyLogOtp, loginWithPassword,
    // loginWithPasswordAD, 
    getToken
} from '../Redux/Actions/userActions';
import { connect, useSelector } from 'react-redux';
import { Base64 } from 'js-base64';
import banner from '../Assets/Images/loginImage.png';
import logo from '../Assets/Images/logo.svg';
import { EyeOffIcon, EyeIcon } from '@heroicons/react/outline'
import { Logout } from '../Redux/Actions/userActions';
import AuthDataService from '../Redux/services/authService';
import TermsAndConditions from './TermsAndConditions';
import { Dialog } from "primereact/dialog";
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
const Seperateor = () => {
    return (
        <div className="flex items-center my-5 mx-auto w-3/4">
            <div className="border border-gray-6 flex flex-1"></div>
            <div className="text-xs font-medium text-gray-secondary mx-2">OR</div>
            <div className="border border-gray-6 flex flex-1"></div>
        </div>
    )
}

const FormFooter = (props) => {
    return (
        <div>
            {/* <div className="text-center text-brand-secondary mt-5 font-medium">Need Help? Get In Touch</div> */}
        </div>
    )
}


const LoginForm = (props) => {
    const [isSent, setSent] = useState(false);
    const LoginData = useSelector(state => state.authReducer);
    const loggedInData = useSelector((state) => state.authReducer);
    const { patientSession } = loggedInData;
    const { userCode } = LoginData;
    const [isOTPType, setType] = useState('');
    const [err, setErr] = useState('');
    const [errUser, setErrUser] = useState("")
    const history = useHistory();
    const [seconds, setTimer] = useState(60);
    const [showLoader, setLoader] = useState(false);
    const [termsData, setTermsData] = useState();
    const [termsView, setTermsView] = useState(false);
    const [privacyData, setPrivacyData] = useState();
    const [privacyView, setPrivacyView] = useState(false);
    const [termsAccept, setTermsAccept] = useState(false);
    const [policyAccept, setPolicyAccept] = useState(false);
    const [SPdetails, setSPDetails] = useState();
    const [consentVersionT, setConsentVersionT] = useState();
    const [consentVersionP, setConsentVersionP] = useState();
    const [showConsentView, setShowConsentView] = useState(false);
    const [versionTextChange, setVersionText] = useState(false);

    const [user, setuser] = useState({
        userName: '',
        email: '',
        otp: '',
        password: ''
    })

    const handleChange = (e) => {
        setErrUser("")
        setErr("")
        setuser({ ...user, [e.target.name]: e.target.value });
    };



    const setSeconds = async () => {
        setTimer(60);
        let dataObj = {
            mobileNo: user.email,
            mobileCheck: 2,
        }
        await props.sendOTP(dataObj).then((result) => {
            setErr('Otp sent');
        }).catch((error) => {
            setLoader(false);
            if (error?.details?.length > 0) {
                setErr(error.details[0]);
            }
        })
    }

    useEffect(() => {
        let consentVersion = ""
        if (process.env.REACT_APP_BASEURL === "https://api-stage.curebay.in/dhp/") {
            consentVersion = `https://common-stage.curebay.in/cs/ConsentVersion/list/filter?userType=SP&status=1`
        } else if (process.env.REACT_APP_BASEURL === "https://doctorapi.curebay.com/doctor/") {
            consentVersion = `https://common.curebay.com/cs/ConsentVersion/list/filter?userType=SP&status=1`
        } else {
            consentVersion = `https://common-stage.curebay.in/cs/ConsentVersion/list/filter?userType=SP&status=1`
        }
        AuthDataService.getUserConsent(consentVersion).then((res) => {
            const policy = res.data.filter(val => val.constentName === "PrivacyPolicy")
            setConsentVersionP(policy[0])
            const terms = res.data.filter(val => val.constentName === "TermsAndCondition")
            setConsentVersionT(terms[0])
        })
    }, [])
    const termsCheck = (e) => {
        setTermsAccept(e.target.checked)
        setTermsView(false)
    }
    const policyCheck = (e) => {
        setPolicyAccept(e.target.checked)
        setPrivacyView(false)
    }
    const renderPolicyFooter = (name) => {
        return (
            <div className="flex items-center mt-5 mb-5">
                <div className=" flex space-x-2">
                    <input
                        type="checkbox"
                        onChange={(e) => policyCheck(e)}
                        class="form-radio  mr-2"
                        name="accountType"
                        value="personal"
                        checked={policyAccept}
                    />
                    <div className="text-sm sm:text-md font-medium  font-montserrat">
                        <b>I UNDERSTAND THAT THE TERMS OF USE
                            CONTAINED HEREIN ALONG WITH ITS CONSTITUENT PRIVACY POLICY</b>
                    </div>
                </div>
            </div>
        );
    };
    const renderTermsFooter = (name) => {
        return (
            <div className="flex items-center mt-5 mb-5">
                <div className=" flex space-x-2">
                    <input
                        type="checkbox"
                        onChange={(e) => termsCheck(e)}
                        class="form-radio  mr-2"
                        name="accountType"
                        value="personal"
                        checked={termsAccept}
                    />
                    <div className="text-sm sm:text-md font-medium  font-montserrat">
                        <b>I UNDERSTAND THAT THE TERMS OF USE
                            CONTAINED HEREIN ALONG WITH ITS CONSTITUENT PRIVACY POLICY</b>
                    </div>
                </div>
            </div>
        );
    };
    useEffect(() => {
        if (isSent) {
            if (!seconds) return;
            const interval = setInterval(() => {
                const newTime = seconds - 1
                setTimer(newTime);
            }, 1000)
            return () => {
                clearInterval(interval);
            }
        }
    }, [seconds, isSent])

    const sendOTPMobile = async (e) => {
        e.preventDefault();

        if (isNaN(user.email)) {
            setErrUser("")
            setErr('please use password')
            return;
        }
        else if (!user.email) {
            setErrUser("Enter your Phone No")
            return;
        }
        setLoader(true);
        setErr('');
        let dataObj = {
            mobileNo: user.email,
            mobileCheck: 2,
        }
        if (!isSent) {
            await props.sendOTP(dataObj).then((result) => {
                setSent(true);
                setErr('');
                setLoader(false);
            }).catch((error) => {
                setLoader(false);
                localStorage.clear();
                if (error?.details?.length > 0) {
                    setErr(error.details[0]);
                }
            })
        }
        else {
            let data = {
                "userMobileNumber": user.email,
                "userOTP": parseInt(user.otp),
                "channel": "W",
                // "isLogin": 1
            }
            await props.getToken().then(async (result) => {
                await props.verifyOTP(data).then((result) => {
                    setLoader(false);
                    if (result) {
                        localStorage.setItem('userObj', JSON.stringify(result.user));
                        history.push(APP_ROUTES.DASHBOARD)
                        let status = "true"
                        localStorage.setItem("loginstatus", status)
                        localStorage.setItem("loginObj", JSON.stringify(result))
                    }
                }).catch((error) => {
                    setLoader(false);
                    localStorage.clear();
                    if (error?.details?.length > 0) {
                        setErr(error.details[0]);
                    }
                })
            })
        }
    }

    const LoginWithPassword = async (e) => {
        e.preventDefault();
        let phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
        var validation = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

        if (!user.email) {
            setErrUser("Enter your UserID or Phone No")
        } else if (!validation.test(user.email)) {
            setErrUser("Enter valid Email Id or Mobile Number")
        } else if (user.email.match(phoneRegex)) {
            setErrUser("Use OTP for Login")
        } else if (!user.password) {
            setErrUser("")
            setErr("Enter Password")
        } else {
            // if (user.email.includes("@curebay.com")) {
            //     try {
            //         let userADloginURL = ''
            //         if (process.env.REACT_APP_BASEURL === "https://api-stage.curebay.in/dhp/") {
            //             userADloginURL = `https://adapi-stage.curebay.in/commonlogin/api/validateUser`
            //         } else if (process.env.REACT_APP_BASEURL === "https://doctorapi.curebay.com/doctor/") {
            //             userADloginURL = `https://adapi.curebay.in/commonlogin/api/validateUser`
            //         } else {
            //             userADloginURL = `https://adapi-stage.curebay.in/commonlogin/api/validateUser`
            //         }
            //         let payload = {
            //             "password": user.password,
            //             "username": user.email
            //             // "username": "cb-test-user@curebay.com"
            //             // "password": "TestCbUser@123",
            //         }
            //         AuthDataService.userConsentPost(payload, userADloginURL).then((res) => {
            //             if (res.message != "Valid User") {
            //                 setLoader(false);
            //                 localStorage.clear();
            //                 setErr(res.message);
            //                 return false;
            //             } else {
            //                 loginwithAD();
            //             }
            //         })
            //     } catch (error) {
            //         console.log('Warning', error);
            //     }
            // } else {
            setLoader(true);
            e.preventDefault();
            const data = {
                "channel": "W",
                "passWord": Base64.encode(user.password),
                "userCode": user.email,
                // "roleCode": "PROV",
            }
            await props.getToken(data).then((result) => {
                if (result?.message) {
                    setLoader(false);
                    localStorage.clear();
                    setErr('Invalid Login Details');
                    return false;
                } else {
                    props.loginWithPassword(data).then((result) => {
                        if (result) {
                            // const userLog = result.user.userRolesList && result.user.userRolesList.filter(val => val.roleCode === "PROV" || val.roleCode === "AH" || val.roleCode === "SPECIALIST")
                            // if (userLog && userLog.length) {
                            if (result.user.roleCode === 'PROV' || result.user.roleCode === 'AH' || result.user.roleCode === 'SPECIALIST') {
                                setSPDetails(result)
                                let consentVersionUrl = ''
                                if (process.env.REACT_APP_BASEURL === "https://api-stage.curebay.in/dhp/") {
                                    consentVersionUrl = `https://common-stage.curebay.in/cs/UserConsent/list/filter?userId=${result.user.code}&status=1`
                                } else if (process.env.REACT_APP_BASEURL === "https://doctorapi.curebay.com/doctor/") {
                                    consentVersionUrl = `https://common.curebay.com/cs/UserConsent/list/filter?userId=${result.user.code}&status=1`
                                } else {
                                    consentVersionUrl = `https://common-stage.curebay.in/cs/UserConsent/list/filter?userId=${result.user.code}&status=1`
                                }
                                AuthDataService.getApiCMS(consentVersionUrl).then((res) => {
                                    setLoader(false);
                                    if (res.data && res.data.message === "Data not found") {
                                        setShowConsentView(true);
                                    } else {
                                        if (res.data[0].privacyVersionNumber != consentVersionP.versionNumber || res.data[0].tcVersionNumber != consentVersionT.versionNumber || res.data[0].privacyPolicyConsent == 0 || res.data[0].termsAndConditionConsent == 0) {
                                            setShowConsentView(true);
                                            if (res.data[0].privacyVersionNumber != consentVersionP.versionNumber || res.data[0].tcVersionNumber != consentVersionT.versionNumber) {
                                                setVersionText(true)
                                            }
                                        } else {
                                            localStorage.setItem('userObj', JSON.stringify(result.user));
                                            history.push(APP_ROUTES.DASHBOARD)
                                            let status = "true"
                                            localStorage.setItem("loginstatus", status)
                                            localStorage.setItem("loginObj", JSON.stringify(result))
                                        }
                                    }
                                }).catch((er) => {
                                    setLoader(false);
                                })
                            } else {
                                setLoader(false);
                                localStorage.clear();
                                setErr('Invalid Login Details');
                                return false;
                            }

                        }
                    }).catch((error) => {
                        setLoader(false);
                        localStorage.clear();
                        if (error?.details?.length > 0) {
                            setErr(error.details[0]);
                        }
                    })
                }
            }).catch((error) => {
                setLoader(false);
                localStorage.clear();
                setErr("Invalid information");
            });
            // }
        }
    }
    const loginwithAD = async () => {
        setLoader(true);
        const data = {
            "channel": "W",
            "passWord": Base64.encode(user.password),
            "userCode": user.email,
            "roleCode": "PROV",
        }
        // await props.getToken().then((result) => {
        //     props.loginWithPasswordAD(data).then((result) => {
        //         if (result) {
        //             const userLog = result.user && result.user.userRolesList && result.user.userRolesList.filter(val => val.roleCode === "PROV" || val.roleCode === "AH" || val.roleCode === "SPECIALIST")
        //             if (userLog && userLog.length) {
        //                 setSPDetails(result)
        //                 let consentVersionUrl = ''
        //                 if (process.env.REACT_APP_BASEURL === "https://api-stage.curebay.in/dhp/") {
        //                     consentVersionUrl = `https://common-stage.curebay.in/cs/UserConsent/list/filter?userId=${result.user.code}&status=1`
        //                 } else if (process.env.REACT_APP_BASEURL === "https://doctorapi.curebay.com/doctor/") {
        //                     consentVersionUrl = `https://common.curebay.com/cs/UserConsent/list/filter?userId=${result.user.code}&status=1`
        //                 } else {
        //                     consentVersionUrl = `https://common-stage.curebay.in/cs/UserConsent/list/filter?userId=${result.user.code}&status=1`
        //                 }
        //                 AuthDataService.getApiCMS(consentVersionUrl).then((res) => {
        //                     setLoader(false);
        //                     if (res.data && res.data.message === "Data not found") {
        //                         setShowConsentView(true);
        //                     } else {
        //                         if (res.data[0].privacyVersionNumber != consentVersionP.versionNumber || res.data[0].tcVersionNumber != consentVersionT.versionNumber || res.data[0].privacyPolicyConsent == 0 || res.data[0].termsAndConditionConsent == 0) {
        //                             setShowConsentView(true);
        //                             if (res.data[0].privacyVersionNumber != consentVersionP.versionNumber || res.data[0].tcVersionNumber != consentVersionT.versionNumber) {
        //                                 setVersionText(true)
        //                             }
        //                         } else {
        //                             localStorage.setItem('userObj', JSON.stringify(result.user));
        //                             history.push(APP_ROUTES.DASHBOARD)
        //                             let status = "true"
        //                             localStorage.setItem("loginstatus", status)
        //                             localStorage.setItem("loginObj", JSON.stringify(result))
        //                         }
        //                     }
        //                 }).catch((er) => {
        //                     setLoader(false);
        //                 })
        //             } else {
        //                 setLoader(false);
        //                 localStorage.clear();
        //                 setErr('Invalid Login Details');
        //                 return false;
        //             }

        //         }
        //     }).catch((error) => {
        //         setLoader(false);
        //         localStorage.clear();
        //         if (error?.details?.length > 0) {
        //             setErr(error.message);
        //         }
        //     })
        // })
    }

    const reEnterMobile = () => {
        setSent(false);
        setLoader(false);
        setType('')
        setErr('')
    }

    const [spt1, setpasswordType1] = useState(false)


    useEffect(() => {
        if (userCode && localStorage.getItem('userObj')) {
            history.push(APP_ROUTES.DASHBOARD);
        }
    }, [userCode])
    const onConsentAgree = () => {
        if (!termsAccept) {
            return toast("Please agree Terms of use")
        } else if (!policyAccept) {
            return toast("Please agree Privacy Policy")
        } else {
            const payload = {
                "createdBy": SPdetails.user.code,
                "createdDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
                "modifiedBy": SPdetails.user.code,
                "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
                "privacyPolicyConsent": 1,
                "sourceChannel": "DOCTER",
                "status": 1,
                "termsAndConditionConsent": 1,
                "type": "S",
                "userId": SPdetails.user.code,
                "privacyVersionNumber": consentVersionP.versionNumber,
                "tcVersionNumber": consentVersionT.versionNumber
            }
            let url = ''
            if (process.env.REACT_APP_BASEURL === "https://api-stage.curebay.in/dhp/") {
                url = `https://common-stage.curebay.in/cs/PatientConsent/addPatientConsent`
            } else if (process.env.REACT_APP_BASEURL === "https://doctorapi.curebay.com/doctor/") {
                url = `https://common.curebay.com/cs/PatientConsent/addPatientConsent`
            } else {
                url = `https://common-stage.curebay.in/cs/PatientConsent/addPatientConsent`
            }
            AuthDataService.userConsentPost(payload, url).then((res) => {
                if (res.data === 1) {
                    setShowConsentView(false)
                    loginToDashboard()
                }
            })
        }
    }
    const loginToDashboard = () => {
        localStorage.setItem('userObj', JSON.stringify(SPdetails.user));
        history.push(APP_ROUTES.DASHBOARD)
        let status = "true"
        localStorage.setItem("loginstatus", status)
        localStorage.setItem("loginObj", JSON.stringify(SPdetails))
    }
    return (
        <>
            <ToastContainer />
            <form onSubmit={LoginWithPassword}>
                <div className="">
                    <div className="hidden md:flex lg:hidden justify-center  relative  w-full h-96 mt-4" style={{ background: '#f0f8ff00' }}>
                        <img
                            className="w-96 h-full object-fill"
                            src={banner}
                            draggable={false}
                            alt="banner"
                        />
                    </div>
                    <div className="flex font-rubik lg:border ">
                        <div className="lg:flex hidden w-full h-screen" style={{ background: '#d7e3e0' }}>
                            <img
                                className="w-full h-full object-fill"
                                src={banner}
                                draggable={false}
                                alt="banner"
                            />
                        </div>
                        <div className="items-center pt-28  lg:pt-44   w-full h-screen">
                            <img
                                className="md:hidden mb-10 text-center items-center m-auto object-fill"
                                src={logo}
                                draggable={false}
                                alt="banner"
                            />
                            <div className="lg:block hidden text-center lg:px-28 font-rubik text-4xl">
                                <p className="font-medium text-brand-primary text-center">{'Help CureBay Build a'}
                                </p>
                                <p className="font-medium text-brand-primary text-center">Healthier Community </p>
                            </div>
                            <div className="lg:hidden block text-center lg:px-28 font-rubik text-4xl ">
                                <p className="font-medium text-brand-primary text-center">{'Welcome to CureBay '}
                                </p>
                            </div>
                            <div className="flex flex-col lg:px-36 px-10">
                                {!isSent ?
                                    <>
                                        <div className="lg:block hidden text-sm pt-4 mb-5 font-normal font-rubik text-gray-primary text-center">
                                            Doctors Login
                                        </div>
                                        <div className="lg:hidden block text-xl pt-4 mb-5 font-normal font-rubik text-gray-primary text-center">
                                            Doctor's Login
                                        </div>
                                    </> : <div className="text-sm pt-4 flex font-normal font-rubik text-gray-primary">
                                        <p className="ml-5">{"Provide 6 digit OTP is send to "}</p>
                                        <p className={'ml-1 font-semibold'}>{user.email}</p>
                                        <p onClick={reEnterMobile} className={'cursor-pointer ml-10 font-semibold text-brand-primary'}>{'Edit'}</p>
                                    </div>}
                                <div className="ml-5">
                                    {!isSent && isOTPType == "" &&
                                        <div className="flex flex-col mb-6">
                                            {user.email && <span className={`${user.email.length == 10 ? 'text-brand-lightgreen ' : 'text-brand-lightgreen'} font-rubik font-light text-xs  tracking-widest`}>{'Enter Your UserID or phone No'}</span>}
                                            <div className="flex">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="sign-in-email-register"
                                                    className={`appearance-none text-sm font-montserrat border-b  w-full py-1 bg-transparent placeholder-gray-secondary border-gray-highlight focus:outline-none 'border-gray-highlight'}`}
                                                    placeholder="Enter Your UserID or phone No"
                                                    value={user.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {errUser && <span className="text-red-600 font-rubik font-light text-xs  tracking-widest">{errUser}</span>}
                                        </div>
                                    }
                                    {isOTPType === "" &&
                                        <div>
                                            {isSent &&
                                                <div className={"flex flex-col mb-6"}>
                                                    {user.otp && <span className={`${user.otp.length == 6 ? 'text-brand-lightgreen ' : ''} text-red-600 font-rubik font-light text-xs  tracking-widest`}>{'Enter OTP'}</span>}
                                                    <div className="flex relative ">
                                                        <input
                                                            name="otp"
                                                            id="sign-in-email-password"
                                                            value={user.otp}
                                                            maxLength={6}
                                                            minLength={6}
                                                            onChange={handleChange}
                                                            className={`appearance-none text-sm font-montserrat border-b  w-full py-1 bg-transparentwhite placeholder-gray-secondary  focus:outline-none ${'border-gray-highlight'
                                                                }`}
                                                            placeholder="Enter OTP"
                                                        />
                                                        <span onClick={() => { seconds <= 0 && setSeconds() }} className={`${seconds == 0 ? "text-brand-secondary cursor-pointer " : 'text-gray-secondary cursor-not-allowed '} font-rubik font-medium text-sm absolute right-1 mt-2 `}>{`Resend ${seconds > 0 ? "in 00:" + seconds : ''}`} </span>
                                                    </div>
                                                    {err && <span className={`${err === "Otp sent" ? "text-green-500 " : "text-red-600 "} font-rubik font-light text-xs  tracking-widest`}>{err}</span>}

                                                    <div className="flex w-full mt-8">
                                                        <button
                                                            onClick={sendOTPMobile}
                                                            className={`p-2 bg-brand-secondary  text-white w-full  rounded-lg text-center text-base font-normal}`}
                                                            disabled={user.otp.length !== 6}>
                                                            Verify OTP
                                                            {showLoader && <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>}
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            {/* {!isSent && <div className="flex w-full mt-8">
                                            <button
                                                onClick={sendOTPMobile}
                                                className={`p-2 bg-brand-secondary  text-white w-full  rounded-lg text-center text-base font-normal}`}
                                                disabled={!user.email}>
                                                Use OTP
                                                {showLoader && <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>}
                                            </button>
                                        </div>
                                        } */}
                                        </div>
                                    }
                                    {/* {isOTPType === "password" && */}
                                    {!isSent && isOTPType == "" &&
                                        <div>
                                            <div className={"flex flex-col mb-6"}>
                                                <div className="flex relative ">
                                                    <input
                                                        name="password"
                                                        type={!spt1 ? "password" : "text"}
                                                        id="sign-in-email-password"
                                                        value={user.password}
                                                        onChange={handleChange}
                                                        className={`appearance-none text-sm font-montserrat border-b  w-full py-1 bg-transparent placeholder-gray-secondary  focus:outline-none ${'border-gray-highlight'
                                                            }`}
                                                        placeholder="Enter Password"
                                                    />
                                                    {!spt1 ? <EyeOffIcon onClick={() => setpasswordType1(!spt1)} className="h-5 text-brand-manatee relative right-5 cursor-pointer" />
                                                        : <EyeIcon onClick={() => setpasswordType1(!spt1)} className="h-5 text-brand-manatee relative right-5 cursor-pointer" />}
                                                </div>
                                                {err && <span className="text-red-600 font-rubik font-light text-xs  tracking-widest">{err}</span>}
                                                <div className="text-right justify-end cursor-pointer">
                                                    <span className="text-brand-primary font-medium relative right-5 text-sm cursor-pointer" onClick={(e) => { history.push(APP_ROUTES.FORGOT_PASSWORD) }} >Don’t remember your password ?</span>
                                                </div>

                                                <div className="flex w-full mt-8">
                                                    <button
                                                        type="submit"
                                                        // onClick={LoginWithPassword}
                                                        className={`p-2 bg-brand-secondary  text-white w-full  rounded-lg text-center text-base font-normal}`}
                                                    >
                                                        Login
                                                        {showLoader && <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>}
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                    {/* } */}
                                    {!isSent && isOTPType == "" &&
                                        <Seperateor />
                                    }
                                    {/* <div className="flex w-full mb-4">
                                    <button
                                        onClick={() => { setType(isOTPType == "" ? "password" : ""); setErr('') }}
                                        disabled={!user.email}
                                        className={`p-2 border border-brand-secondary  text-brand-secondary w-full rounded-lg  text-center text-base font-normal`}
                                    >
                                        {isOTPType == "" ? 'Use Password' : 'Login with OTP'}
                                    </button>

                                </div> */}

                                    {!isSent && <div className="flex w-full mt-8">
                                        <button
                                            onClick={sendOTPMobile}
                                            className={`p-2 bg-brand-secondary  text-white w-full  rounded-lg text-center text-base font-normal}`}
                                        >
                                            Use OTP
                                            {showLoader && <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>}
                                        </button>
                                    </div>
                                    }
                                    <FormFooter {...props} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form >
            <Dialog
                header="Terms of use"
                visible={termsView}
                modal={true}
                style={{ width: "70vw" }}
                onHide={() => setTermsView(false)}
                footer={renderTermsFooter("displayModal")}
            >
                <div className="border border-gray-200 my-3 rounded-lg p-5 ">
                    {consentVersionT &&
                        <iframe src={`${process.env.REACT_APP_IMG_BASEURL + consentVersionT.consentFile
                            }`}
                            height="2100px"
                            width="100%" />
                    }
                </div >
            </Dialog>
            <Dialog
                header="Privacy Policy"
                visible={privacyView}
                modal={true}
                style={{ width: "70vw" }}
                footer={renderPolicyFooter("displayModal")}
                onHide={() => setPrivacyView(false)}
            >
                <div className="border border-gray-200 my-3 rounded-lg p-5 ">
                    {consentVersionP &&
                        <iframe src={`${process.env.REACT_APP_IMG_BASEURL + consentVersionP.consentFile
                            }`}
                            height="2100px"
                            width="100%" />
                    }
                </div>
            </Dialog>
            <Dialog
                header="User Consent"
                visible={showConsentView}
                modal={true}
                style={{ width: "30vw", height: "17vw" }}
                onHide={() => setShowConsentView(false)}
            >
                <>
                    <div className="border border-gray-200 my-3 p-5">
                        <p className=''>
                            {versionTextChange ?
                                <b>Privacy Policy and Terms and conditions have been changed please check again and give your consent .</b>
                                :
                                <b>Please Accept Terms of use and Privacy Policy to continue</b>
                            }
                        </p>
                        <div className="flex cursor-pointer mt-4">
                            <div className=" flex space-x-2">
                                <input
                                    type="checkbox"
                                    onChange={(e) => setTermsView(true)}
                                    class="form-radio"
                                    name="accountType"
                                    value="personal"
                                    checked={termsAccept}
                                />
                                <div className="text-xs font-medium  font-montserrat">
                                    <p className="text-brand-primary font-medium text-sm cursor-pointer" onClick={() => setTermsView(true)} ><u>Terms and conditions</u></p>
                                </div>
                            </div>
                        </div>
                        <div className="flex cursor-pointer mt-4">

                            <div className=" flex space-x-2">
                                <input
                                    type="checkbox"
                                    onChange={(e) => setPrivacyView(true)}
                                    class="form-radio"
                                    name="accountType"
                                    value="personal"
                                    checked={policyAccept}
                                />
                                <div className="text-xs font-medium  font-montserrat">
                                    <p className="text-brand-primary font-medium text-sm cursor-pointer" onClick={(e) => setPrivacyView(true)} ><u>Privacy policy</u></p>
                                </div>
                            </div>
                        </div>
                        <button
                            // type="submit"
                            onClick={() => onConsentAgree()}
                            className={`p-2 bg-brand-secondary mt-5 flex-end align-end justify-end  text-white w-48  rounded-lg text-center text-base font-normal}`}
                        >
                            Agree and continue
                        </button>
                    </div>

                </>
            </Dialog>
        </>
    )
}



const mapDispatchToProps = (dispatch) => ({
    sendOTP: (data) => dispatch(sendOtp(data)),
    verifyOTP: (data) => dispatch(verifyLogOtp(data)),
    loginWithPassword: (data) => dispatch(loginWithPassword(data)),
    // loginWithPasswordAD: (data) => dispatch(loginWithPasswordAD(data)),
    getToken: (data) => dispatch(getToken(data))

});

export default connect(null, mapDispatchToProps,)(LoginForm);