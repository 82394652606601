import React, { useEffect } from "react";

import { PlusIcon, DotsVerticalIcon } from "@heroicons/react/outline";

import { APP_ROUTES } from "../application/Router/constants/AppRoutes";

// import NoAppointmentData from '../NoAppointmentData';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import AddAllergy from "./Addallergy";
import AddHealthRestriction from "./Addhealthrestrictions";
import Addmedication from "./Addmedication";
import Addmedicalhistory from "./Addmedicalhistory";
import Addfamilyhistory from "./Addfamilyhistory";
import Addsurgicalhistory from "./Addsurgicalhistory";
import Addsocialhistory from "./Addsocialhistory";
import Addtravelhistory from "./Addtravelhistory";
import Addmedicalcondition from "./AddmedicalCondition.";
import moment from 'moment';
import Seeallallergy from "./Seeallallergy";
import AddChronicalHistory from "./AddChronicalHistory";
import Addsexualhistory from "./Addsexualhistory";
import AddAddiction from "./AddAddiction";

function DetailCard(props) {
  const history = useHistory();
  const location = useLocation();
  const redirectTo = (event, location) => {
    event.preventDefault();
    if (location == APP_ROUTES.SEEALLALLERGY) {
      setShowAll(true);
      setShowAllAllergy(true);
    }
    //history.push(location);
  };

  const [showaddallergypopup, setshowaddallergypopup] = useState(false);
  const [showhealthrestrictionpopup, setshowhealthrestrictionpopup] =
    useState(false);
  const [showmedicationpopup, setshowmedicationpopup] = useState(false);
  const [showmedicalhistorypopup, setshowmedicalhistorypopup] = useState(false);
  const [showsurgicalhistorypopup, setshowsurgicalhistorypopup] = useState(false);
  const [showfamilyhistorypopup, setshowfamilyhistorypopup] = useState(false);
  const [showsocialhistorypopup, setshowsocialhistorypopup] = useState(false);
  const [showtravelhistorypopup, setshowtravelhistorypopup] = useState(false);
  const [showsexualhistorypopup, setshowsexualhistorypopup] = useState(false);

  const [showmedicalconditionpopup, setshowmedicalconditionpopup] =
    useState(false);

  const [showAll, setShowAll] = useState(false);
  const [showAllAllergy, setShowAllAllergy] = useState(false);

  const [editallergy, seteditallergy] = useState();
  const [edithealthrestrictions, setedithealthrestrictions] = useState();
  const [editmedication, seteditmedication] = useState();
  const [editmedicalhistory, seteditmedicalhistory] = useState();
  const [editsurgicalhistory, seteditsurgicalhistory] = useState();
  const [editfamilyhistory, seteditfamilyhistory] = useState();
  const [editsocialhistory, seteditsocialhistory] = useState();
  const [editmedicalcondition, seteditmedicalcondition] = useState();
  const [edittravelhistory, setedittravelhistory] = useState();
  const [editsexualhistory, seteditsexualhistory] = useState();

  const [addTitle, setAddTitle] = useState('');
  const [addType, setAddType] = useState('');

  const [medicalcondition, setmedicalcondtion] = useState(true);

  const [showchronicalhistorypopup, setshowchronicalhistorypopup] = useState(false);
  const [editchronicalhistory, seteditchronicalhistory] = useState();
  const [showsAddictionpopup, setshowAddictionpopup] = useState(false);

  const addallergy = (event, data) => {
    event.preventDefault();
    setshowaddallergypopup(true);
    seteditallergy(data);
  };

  const addhealthrestrictions = (event, data) => {
    event.preventDefault();
    setshowhealthrestrictionpopup(true);
    setedithealthrestrictions(data);
  };

  const addmedication = (event, data) => {
    event.preventDefault();
    setshowmedicationpopup(true);
    seteditmedication(data);
  };

  const addmedicalhistory = (event, data) => {
    event.preventDefault();
    setshowmedicalhistorypopup(true);
    seteditmedicalhistory(data);
  };

  const addsurgicalhistory = (event, data) => {
    event.preventDefault();
    setshowsurgicalhistorypopup(true);
    seteditmedicalhistory(data);
  };

  const addfamilyhistory = (event, data) => {
    event.preventDefault();
    setshowfamilyhistorypopup(true);
    seteditfamilyhistory(data);
  };

  const addsocialhistory = (event, data) => {
    event.preventDefault();
    setshowsocialhistorypopup(true);
    seteditsocialhistory(data);
  };
  const addtravelhistory = (event, data) => {
    event.preventDefault();
    setshowtravelhistorypopup(true);
    setedittravelhistory(data);
  };
  const addsexualhistory = (event, data) => {
    event.preventDefault();
    setshowsexualhistorypopup(true);
    seteditsexualhistory(data);
  };

  const addmedicalcondition = (event, data) => {
    event.preventDefault();
    setshowmedicalconditionpopup(true);
    seteditmedicalcondition(data);
  };

  const addchronicalhistory = (event, data) => {
    event.preventDefault();
    setshowchronicalhistorypopup(true);
    seteditchronicalhistory(data);
  };
  const addAddictions = (event, data) => {
    event.preventDefault();
    setshowAddictionpopup(true);
    // seteditsexualhistory(data);
  }

  const loadData = () => {
    props.loadData();
  }

  return (
    <div class="w-full p-4 border border-gray-200 rounded-xl justify-between my-3">
      <div class="flex justify-between mb-2 px-1">
        <p class="text-md font-rubik font-medium text-gray-primary cursor-pointer">
          {props.heading}
        </p>

        {props?.seeAll ? (
          <div className="flex space-x-3 -mr-1">
            {/*props?.data?.length === 0 ? null : (
              <p
                onClick={(e) => redirectTo(e, props.seeAll)}
                class="inline px- rounded-full text-sm font-rubik text-brand-secondary  font-medium cursor-pointer"
              >
                See all
              </p>
            )*/}

            {false ? null : (
              <>
                {props?.heading == "Allergy Details" ? (
                  <PlusIcon
                    onClick={(e) => addallergy(e, "")}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}

            {false ? null : (
              <>
                {props?.heading === "Health Restriction" ? (
                  <PlusIcon
                    onClick={(e) => addhealthrestrictions(e, "")}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}

            {false ? null : (
              <>
                {props?.heading === "Medication" ? (
                  <PlusIcon
                    onClick={(e) => addmedication(e, "")}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}

            {false ? null : (
              <>
                {props?.heading === "Past Medical History" ? (
                  <PlusIcon
                    onClick={(e) => addmedicalhistory(e, "Medical History", 2)}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}

            {false ? null : (
              <>
                {props?.heading === "Medical Condition" ? (
                  <PlusIcon
                    onClick={(e) => addmedicalcondition(e, "")}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}

            {false ? null : (
              <>
                {props?.heading === "Surgical History" ? (
                  <PlusIcon
                    onClick={(e) => addsurgicalhistory(e, "Surgical History", 3)}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}

            {false ? null : (
              <>
                {props?.heading === "Family History" ? (
                  <PlusIcon
                    onClick={(e) => addfamilyhistory(e, "Family History", 4)}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}
            {false ? null : (
              <>
                {props?.heading === "Addictions" ? (
                  <PlusIcon
                    onClick={(e) => addAddictions(e, "")}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}
            {false ? null : (
              <>
                {props?.heading === "Social History" ? (
                  <PlusIcon
                    onClick={(e) => addsocialhistory(e, "Social History", 5)}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}
            {false ? null : (
              <>
                {props?.heading === "Travel History" ? (
                  <PlusIcon
                    onClick={(e) => addtravelhistory(e, "")}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}

            {false ? null : (
              <>
                {props?.heading === "Sexual History" ? (
                  <PlusIcon
                    onClick={(e) => addsexualhistory(e, "")}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}
            {false ? null : (
              <>
                {props?.heading === "Chronic Conditions" ? (
                  <PlusIcon
                    onClick={(e) => addchronicalhistory(e, "")}
                    className="h-5  fill-brand-secondary cursor-pointer"
                  />
                ) : null}
              </>
            )}

            {/* <PlusIcon onClick={(e) => redirectTo(e, props.location)} className="h-5  fill-brand-secondary cursor-pointer" /> */}
          </div>
        ) : null}
      </div>
      <hr className="mt-2" />

      {/* allergy card starts here */}

      {
        props?.data?.length === 0 && props?.heading === "Allergy Details" ? <p className="text-center item-center mt-16 mb-20  ">No Allergy Details</p> :

          <>
            {
              props?.data?.length > 0 && props?.heading === "Allergy Details" &&

              <div className="overflow-auto hide-scroll-bar h-40 ">
                {props?.data?.
                  sort((a, b) => {
                    return (
                      moment(b.createdDate, "yyyy-MM-DD HH:mm:ss") - moment(a.createdDate, "yyyy-MM-DD HH:mm:ss")
                    );
                  })
                  .map((user, i) => (
                    <>
                      <div class="flex py-2 px-2">
                        <div
                          className={`flex ${props.icon ? " " : "justify-between "
                            }flex-wrap  my-1 font-montserrat font-normal w-full`}
                        >
                          {props.icon && (
                            <div>
                              <img src={props.icon} />
                            </div>
                          )}
                          {!props.icon ? (
                            <div>
                              {/* <div className="mt-1 p-1">
                                <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">
                                  Chemical Allergy : {user.chemicalAllergy}
                                </p>
                              </div>
                              <div className="mt-1 p-1 align-left">
                                <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">
                                  Food Allergy : {user.foodAllergy}
                                </p>
                              </div> */}
                              {user.drugAllergy ?
                                <span class="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Drug Allergy :{user.drugAllergy}
                                </span> : null}
                              {user.chemicalAllergy ?
                                < span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Chemical Allergy : {user.chemicalAllergy}
                                </span> : null}
                              {user.foodAllergy ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Food Allergy : {user.foodAllergy}
                                </span> : null}

                              {user.otherAllergy ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Other Allergy : {user.otherAllergy}
                                </span> : null}
                              <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                              </span>
                            </div>
                          ) : null}
                          <div className="flex">
                            <div className=" font-semibold font-montserrat text-right text-sm">

                              {/* <div className="mt-1 p-1">
                                <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">
                                  Drug Allergy:{user.drugAllergy}
                                </p>
                              </div> */}

                              {/* <div className="">
                                <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                  Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                                </span>
                              </div> */}
                            </div>
                            {/* <div>
                              <DotsVerticalIcon
                                onClick={(e) => addallergy(e, user)}
                                className="cursor-pointer text-gray-secondary h-6 mt-2 relative left-3"
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                      {i !== 1 && <hr className="mt-2" />}
                    </>

                  ))
                }
              </div>}
          </>
      }









      {/* allergy card ends here */}

      {/* health restrictioncard starts here */}

      {
        props?.data?.length === 0 && props?.heading === "Health Restriction" ? (
          <p className="text-center item-center mt-20 mb-20  ">
            No Health Restriction
          </p>
        ) : (
          <>
            {props?.heading === "Health Restriction"
              ? props?.data?.slice(0, 2).map((user, i) => (
                <>
                  <div class="flex py-2 px-2">
                    <div
                      className={`flex ${props.icon ? " " : "justify-between "
                        }flex-wrap  my-1 font-montserrat font-normal w-full`}
                    >
                      {props.icon && (
                        <div>
                          <img src={props.icon} />
                        </div>
                      )}
                      {!props.icon ? (
                        <div>
                          <span class="text-base text-gray-primary normal font-montserrat ">
                            {user.drugAllergy}
                          </span>
                          <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                            Mild
                          </span>
                        </div>
                      ) : null}
                      <div className="flex">
                        <div className=" font-semibold font-montserrat text-right text-sm">
                          <div className="">
                            <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                              Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                            </span>
                          </div>
                          <div className="mt-1 p-1">
                            <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">
                              Reaction Type:{user.foodAllergy}
                            </p>
                          </div>
                        </div>
                        <div>
                          <DotsVerticalIcon
                            onClick={(e) => addhealthrestrictions(e, user)}
                            className="cursor-pointer text-gray-secondary h-6 mt-2 relative left-3"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {i !== 1 && <hr className="mt-2" />}
                </>
              ))
              : null}
          </>
        )
      }
      {/* health restrictioncard ends here */}

      {/* medication card starts here */}

      {props?.data?.length === 0 && props?.heading === "Medication" ?
        <p className="text-center item-center mt-20 mb-20  ">No Medication</p>
        :
        <>
          {
            props?.data?.length > 0 && props?.heading === "Medication" &&
            <div className="overflow-auto hide-scroll-bar h-40 ">
              {props?.heading === "Medication"
                ? props?.data?.slice(0, 2)
                  .sort((a, b) => {
                    return (
                      moment(b.createdDate, "yyyy-MM-DD HH:mm:ss") - moment(a.createdDate, "yyyy-MM-DD HH:mm:ss")
                    );
                  }).map((user, i) => (
                    <>
                      <div class="flex py-2 px-2">
                        <div
                          className={`flex ${props.icon ? " " : "justify-between "
                            }flex-wrap  my-1 font-montserrat font-normal w-full`}
                        >
                          {props.icon && (
                            <div>
                              <img src={props.icon} />
                            </div>
                          )}
                          {!props.icon ? (
                            <div>
                              {user.drugName ?
                                <span class="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Drug Name :{user.drugName}
                                </span> : null}
                              {user.dosage ?
                                <span class="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Dosage :{user.dosage}
                                </span> : null}
                              {user.frequency ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Frequency: {user.frequency}
                                </span> : null}
                              {user.dose ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Duration : {user.dose}
                                </span> : null}
                              {user.indication ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Indication : {user.indication}
                                </span> : null}
                              {user.sideEffects ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Side Effects : {user.sideEffects}
                                </span> : null}
                              {user.compliance ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Compliance : {user.compliance}
                                </span> : null}
                              {user.startCreatedTime ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  From Date : {moment(user.startCreatedTime).format("DD/MM/yyyy")}
                                </span> : null}
                              {user.endCreatedTime ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  To Date : {moment(user.endCreatedTime).format("DD/MM/yyyy")}
                                </span> : null}
                              {user.adverseEffects ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Adverse Effects : {user.adverseEffects}
                                </span> : null}
                              {user.adviceRelatedMedicine ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Advice related to medicines: {user.adviceRelatedMedicine}
                                </span> : null}
                              <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                              </span>
                            </div>
                          ) : null}
                          <div className="flex">
                            <div className=" font-semibold font-montserrat text-right text-sm">
                              <div className="mt-1 p-1">
                                {user.strength ?
                                  <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">
                                    Strength:
                                    {/* {user.dosage ? user.dosage : `Morning & Noon`} */}
                                    {user.strength}
                                  </p>
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {i !== 1 && <hr className="mt-2" />}
                    </>
                  ))
                : null}
            </div>
          }
        </>
      }
      {/* medication card ends here */}

      {/* medical history card starts here */}



      {
        props?.data?.length === 0 && props?.heading === "Past Medical History" ? <p className="text-center item-center mt-16 mb-20  ">No Medical History</p> :


          <>
            {
              props?.data?.length > 0 && props?.heading === "Past Medical History" &&

              <div className="overflow-auto hide-scroll-bar h-40 ">
                {props?.data?.
                  sort((a, b) => {
                    return (
                      moment(b.createdDate, "yyyy-MM-DD HH:mm:ss") - moment(a.createdDate, "yyyy-MM-DD HH:mm:ss")
                    );
                  }).

                  map((user, i) => (
                    <>
                      <div class="flex py-2 px-2">
                        <div
                          className={`flex ${props.icon ? " " : "justify-between "
                            }flex-wrap  my-1 font-montserrat font-normal w-full`}
                        >
                          {props.icon && (
                            <div>
                              <img src={props.icon} />
                            </div>
                          )}
                          {!props.icon ? (
                            <div>
                              <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                {user.pastInfo}
                              </span>
                              <span class="text-base text-gray-primary normal font-montserrat ">
                                {user.description}
                              </span>
                            </div>
                          ) : null}
                          <div className="flex">
                            <div className=" font-semibold font-montserrat text-right text-sm">
                              <div className="">
                                <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                  Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                                </span>
                              </div>
                              <div className="mt-1 p-1">
                                {/* <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">
                                {user.modifiedDate
                                  ? user.modifiedDate
                                  : `23 July`}
                              </p> */}
                                {/* <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                Modified Date : {moment(user.modifiedDate).format("MM/DD/yyyy")}
                              </span> */}
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      {i !== 1 && <hr className="mt-2" />}
                    </>
                  ))
                }
              </div>}
          </>
      }
      {
        props?.data?.length === 0 && props?.heading === "Addictions" ? <p className="text-center item-center mt-16 mb-20  ">No Addictions Available</p> :
          <>
            {
              props?.data?.length > 0 && props?.heading === "Addictions" &&

              <div className="overflow-auto hide-scroll-bar h-40 ">
                {props?.data?.
                  sort((a, b) => {
                    return (
                      moment(b.createdDate, "yyyy-MM-DD HH:mm:ss") - moment(a.createdDate, "yyyy-MM-DD HH:mm:ss")
                    );
                  }).

                  map((user, i) => (
                    <>
                      <div class="flex py-2 px-2">
                        <div
                          className={`flex ${props.icon ? " " : "justify-between "
                            }flex-wrap  my-1 font-montserrat font-normal w-full`}
                        >
                          {props.icon && (
                            <div>
                              <img src={props.icon} />
                            </div>
                          )}
                          {!props.icon ? (
                            <div>
                              <div>
                                <span class="text-base text-gray-primary normal font-montserrat ">
                                  {user.alcoholAddiction != "" ? user.alcoholAddiction : null}
                                </span>
                              </div>
                              <div>
                                <span class="text-base text-gray-primary normal font-montserrat ">
                                  {user.cocaineAddiction != "" ? user.cocaineAddiction : null}
                                </span>
                              </div>
                              <div>
                                <span class="text-base text-gray-primary normal font-montserrat ">
                                  {user.tobaccoAddiction != "" ? user.tobaccoAddiction : null}
                                </span>
                              </div>
                              <div>
                                <span class="text-base text-gray-primary normal font-montserrat ">
                                  {user.sweetsAddiction != "" ? user.sweetsAddiction : null}
                                </span>
                              </div>
                              <div>
                                <span class="text-base text-gray-primary normal font-montserrat ">
                                  {user.lsdAddiction != "" ? user.lsdAddiction : null}
                                </span>
                              </div>
                              <div>
                                <span class="text-base text-gray-primary normal font-montserrat ">
                                  {user.marijuanaAddiction != "" ? user.marijuanaAddiction : null}
                                </span>
                              </div>
                              <div>
                                <span class="text-base text-gray-primary normal font-montserrat ">
                                  {user.description != "" ? user.description : null}
                                </span>
                              </div>
                              <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                              </span>
                            </div>
                          ) : null}

                        </div>
                      </div>
                      {i !== 1 && <hr className="mt-2" />}
                    </>
                  ))
                }
              </div>}
          </>
      }
      {
        props?.data?.length === 0 && props?.heading === "Family History" ? <p className="text-center item-center mt-16 mb-20  ">No Family History</p> :


          <>
            {
              props?.data?.length > 0 && props?.heading === "Family History" &&

              <div className="overflow-auto hide-scroll-bar h-40 ">
                {props?.data?.
                  sort((a, b) => {
                    return (
                      moment(b.createdDate, "yyyy-MM-DD HH:mm:ss") - moment(a.createdDate, "yyyy-MM-DD HH:mm:ss")
                    );
                  }).

                  map((user, i) => (
                    <>
                      <div class="flex py-2 px-2">
                        <div
                          className={`flex ${props.icon ? " " : "justify-between "
                            }flex-wrap  my-1 font-montserrat font-normal w-full`}
                        >
                          {props.icon && (
                            <div>
                              <img src={props.icon} />
                            </div>
                          )}

                          {!props.icon ? (
                            <div>
                              {user.description ?
                                <span class="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Drug Name :{user.description}
                                </span> : null}
                              {/* {user.maritalStatus ?
                                <span class="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  MaritalStatus :{user.maritalStatus=== "Y" ? "Married" : "Unmarried"}
                                </span> : null}
                              {user.numberOfChildren ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Number of Children: {user.numberOfChildren === -1  ? "NA" : user.numberOfChildren}
                                </span> : null} */}
                              {user.dose ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Dose : {user.description}
                                </span> : null}
                              {user.adviceRelatedMedicine ?
                                <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                  Advice related to medicines: {user.adviceRelatedMedicine}
                                </span> : null}
                              <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                              </span>
                            </div>
                          ) : null}
                          {/* {!props.icon ? (
                            <div>
                              <span class="text-base text-gray-primary normal font-montserrat ">
                                {user.description}
                              </span> */}
                          {/* <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                Relationship
                              </span> */}
                          {/* </div>
                          ) : null} */}
                          {/* <div className="flex">
                            <div className=" font-semibold font-montserrat text-right text-sm">
                              <div className="">
                                <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                  Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                                </span>
                              </div>
                              <div className="mt-1 p-1"> */}
                          {/* <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">
                                  {user.modifiedDate
                                    ? user.modifiedDate
                                    : `23 July`}
                                </p> */}
                          {/* </div>
                            </div>

                          </div> */}
                        </div>
                      </div>
                      {i !== 1 && <hr className="mt-2" />}
                    </>
                  ))
                }
              </div>}
          </>
      }


      {
        props?.data?.length === 0 && props?.heading === "Surgical History" ? <p className="text-center item-center mt-16 mb-20  ">No Surgical History</p> :


          <>
            {
              props?.data?.length > 0 && props?.heading === "Surgical History" &&

              <div className="overflow-auto hide-scroll-bar h-40 ">
                {props?.data?.
                  sort((a, b) => {
                    return (
                      moment(b.createdDate, "yyyy-MM-DD HH:mm:ss") - moment(a.createdDate, "yyyy-MM-DD HH:mm:ss")
                    );
                  }).

                  map((user, i) => (
                    <>
                      <div class="flex py-2 px-2">
                        <div
                          className={`flex ${props.icon ? " " : "justify-between "
                            }flex-wrap  my-1 font-montserrat font-normal w-full`}
                        >
                          {props.icon && (
                            <div>
                              <img src={props.icon} />
                            </div>
                          )}
                          {!props.icon ? (
                            <div>
                              <div>
                                {user.surgicalInfo ?
                                  <span class="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                    <b>Surgical History</b> : {user.surgicalInfo}
                                  </span> : null}
                                {user.description ?
                                  <span class="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                    Description : {user.description}
                                  </span> : null}
                                <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                  Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                                </span>
                              </div>
                            </div>
                          ) : null}
                          {/* <div className="flex">
                            <div className=" font-semibold font-montserrat text-right text-sm">
                              <div className="">
                                <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                  Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                                </span>
                              </div>
                              {/* <div className="mt-1 p-1">
                                <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">
                                  {user.modifiedDate
                                    ? user.modifiedDate
                                    : `23 July`}
                                </p>
                              </div> 
                            </div> */}

                          {/* </div> */}
                        </div>
                      </div>
                      {i !== 1 && <hr className="mt-2" />}
                    </>
                  ))
                }
              </div>}
          </>
      }



      {
        props?.data?.length === 0 && props?.heading === "Social History" ? <p className="text-center item-center mt-16 mb-20  ">No Social History</p> :


          <>
            {
              props?.data?.length > 0 && props?.heading === "Social History" &&

              <div className="overflow-auto hide-scroll-bar h-40 ">
                {props?.data?.
                  sort((a, b) => {
                    return (
                      moment(b.createdDate, "yyyy-MM-DD HH:mm:ss") - moment(a.createdDate, "yyyy-MM-DD HH:mm:ss")
                    );
                  }).

                  map((user, i) => (
                    <>
                      <div class="flex py-2 px-2">
                        <div
                          className={`flex ${props.icon ? " " : "justify-between "
                            }flex-wrap  my-1 font-montserrat font-normal w-full`}
                        >
                          {props.icon && (
                            <div>
                              <img src={props.icon} />
                            </div>
                          )}
                          {!props.icon ? (
                            <div>
                              <div>
                                {user.description ?
                                  <span class="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                    <b>Social History</b> : {user.description}
                                  </span> : null}
                                {user.maritalStatus ?
                                  <span class="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                    Marital Status : {user.maritalStatus}
                                  </span> : null}
                                {user.familyRelation ?
                                  <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                    Social relations with family : {user.familyRelation}
                                  </span> : null}
                                {user.friendsRelation ?
                                  <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                    Social relations with friends : {user.friendsRelation}
                                  </span> : null}
                                {user.communityRelation ?
                                  <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                    Social relations with community : {user.communityRelation}
                                  </span> : null}
                                {user.colleagueRelation ?
                                  <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                    Social relations with people at work : {user.colleagueRelation}
                                  </span> : null}

                                <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                  Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {i !== 1 && <hr className="mt-2" />}
                    </>
                  ))
                }
              </div>}
          </>
      }
      {
        props?.data?.length === 0 && props?.heading === "Travel History" ? <p className="text-center item-center mt-16 mb-20  ">No Travel History</p> :
          <>
            {
              props?.data?.length > 0 && props?.heading === "Travel History" &&

              <div className="overflow-auto hide-scroll-bar h-40 ">
                {props?.data?.
                  sort((a, b) => {
                    return (
                      moment(b.createdDate, "yyyy-MM-DD HH:mm:ss") - moment(a.createdDate, "yyyy-MM-DD HH:mm:ss")
                    );
                  }).

                  map((user, i) => (
                    <>
                      <div class="flex py-2 px-2">
                        <div
                          className={`flex ${props.icon ? " " : "justify-between "
                            }flex-wrap  my-1 font-montserrat font-normal w-full`}
                        >
                          {props.icon && (
                            <div>
                              <img src={props.icon} />
                            </div>
                          )}
                          {!props.icon ? (
                            <div>
                              <span class="text-base text-gray-primary normal font-montserrat ">
                                {user.travelHistory}
                              </span>
                              {/* <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                            Relationship
                          </span> */}
                            </div>
                          ) : null}
                          <div className="flex">
                            {/* <div className=" font-semibold font-montserrat text-right text-sm">
                              <div className="">
                                <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                  Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                                </span>
                              </div>
                              <div className="mt-1 p-1"> */}
                            {/* <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">
                              {user.modifiedDate
                                ? user.modifiedDate
                                : `23 July`}
                            </p> */}
                            {/* </div>
                            </div> */}

                          </div>
                        </div>
                      </div>
                      {i !== 1 && <hr className="mt-2" />}
                    </>
                  ))
                }
              </div>}
          </>
      }
      {
        props?.data?.length === 0 && props?.heading === "Sexual History" ? <p className="text-center item-center mt-16 mb-20  ">No Sexual History</p> :
          <>
            {
              props?.data?.length > 0 && props?.heading === "Sexual History" &&

              <div className="overflow-auto hide-scroll-bar h-40 ">
                {props?.data?.
                  sort((a, b) => {
                    return (
                      moment(b.createdDate, "yyyy-MM-DD HH:mm:ss") - moment(a.createdDate, "yyyy-MM-DD HH:mm:ss")
                    );
                  }).

                  map((user, i) => (
                    <>
                      <div class="flex py-2 px-2">
                        <div
                          className={`flex ${props.icon ? " " : "justify-between "
                            }flex-wrap  my-1 font-montserrat font-normal w-full`}
                        >
                          {props.icon && (
                            <div>
                              <img src={props.icon} />
                            </div>
                          )}
                          {!props.icon ? (
                            <div>
                              <span class="text-base text-gray-primary normal font-montserrat ">
                                {user.sexualHistory}
                              </span>
                              {/* <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                            Relationship
                          </span> */}
                            </div>
                          ) : null}
                          <div className="flex">
                            {/* <div className=" font-semibold font-montserrat text-right text-sm">
                              <div className="">
                                <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                                  Check up Date : {moment(user.givenDate).format("DD/MM/yyyy")}
                                </span>
                              </div>
                              <div className="mt-1 p-1"> */}
                            {/* <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">
                              {user.modifiedDate
                                ? user.modifiedDate
                                : `23 July`}
                            </p> */}
                            {/* </div>
                            </div> */}

                          </div>
                        </div>
                      </div>
                      {i !== 1 && <hr className="mt-2" />}
                    </>
                  ))
                }
              </div>}
          </>
      }

      {
        props?.data?.length === 0 && props?.heading === "Chronic Conditions" ? <p className="text-center item-center mt-16 mb-20  ">No Chronic Conditions</p> :
          <>
            {
              props?.data?.length > 0 && props?.heading === "Chronic Conditions" &&

              <div className="overflow-auto hide-scroll-bar h-40 ">
                {props?.data?.
                  map((user, i) => (
                    <>
                      <div class="flex py-2 px-2">
                        <div
                          className={`flex ${props.icon ? " " : "justify-between "
                            }flex-wrap  my-1 font-montserrat font-normal w-full`}
                        >
                          {props.icon && (
                            <div>
                              <img src={props.icon} />
                            </div>
                          )}
                          {!props.icon ? (
                            <div>
                              <span class="text-base text-gray-primary normal font-montserrat ">
                                {user.chronicDescription}
                              </span>
                              {/* <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                                Relationship
                              </span> */}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {i !== 1 && <hr className="mt-2" />}
                    </>
                  ))
                }
              </div>}
          </>
      }









      {/* medical history card ends here */}

      {/* medical condition card starts here */}

      {
        props?.data?.length === 0 && props?.heading === "Medical Condition" ? (
          <p className="text-center item-center mt-20 mb-20  ">Not available</p>
        ) : (
          <>
            {props?.heading === "Medical Condition"
              ? props?.data?.slice(0, 2).map((user, i) => (
                <>
                  <div class="flex py-2 px-2">
                    <div
                      className={`flex ${props.icon ? " " : "justify-between "
                        }flex-wrap  my-1 font-montserrat font-normal w-full`}
                    >
                      {props.icon && (
                        <div>
                          <img src={props.icon} />
                        </div>
                      )}
                      {!props.icon ? (
                        <div>
                          <span class="text-base text-gray-primary normal font-montserrat ">
                            Medical Condition
                          </span>
                          <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                            Illness Type
                          </span>
                        </div>
                      ) : null}
                      <div className="flex">
                        <div className=" font-semibold font-montserrat text-right text-sm">
                          <div className="">
                            <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                              Check up Date : 23 July 21
                            </span>
                          </div>
                          <div className="mt-1 p-1">
                            <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">
                              End Date:{`23 August 21`}
                            </p>
                          </div>
                        </div>
                        <div>
                          <DotsVerticalIcon
                            onClick={(e) => addmedicalcondition(e, user)}
                            className="cursor-pointer text-gray-secondary h-6 mt-2 relative left-3"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {i !== 1 && <hr className="mt-2" />}
                </>
              ))
              : null}
          </>
        )
      }
      {/* medical condition card ends here */}

      {/* careplan card starts here */}

      {
        props?.data?.length === 0 && props?.heading === "Careplan" ? (
          <p className="text-center item-center mt-20 mb-20  ">Not available</p>
        ) : (
          <>
            {props?.heading === "Careplan"
              ? props?.data?.slice(0, 2).map((user, i) => (
                <>
                  <div class="flex py-2 px-2">
                    <div
                      className={`flex ${props.icon ? " " : "justify-between "
                        }flex-wrap  my-1 font-montserrat font-normal w-full`}
                    >
                      {props.icon && (
                        <div>
                          <img src={props.icon} />
                        </div>
                      )}
                      {!props.icon ? (
                        <div>
                          <span class="text-base text-gray-primary normal font-montserrat ">
                            {user.description}
                          </span>
                          <span className="mt-1 font-montserrat text-gray-primary font-thin  text-sm content-center flex flex-wrap ">
                            Relationship
                          </span>
                        </div>
                      ) : (
                        <div className="px-1 py-1 lg:mr-0 mr-10">
                          <span className="px-1  font-montserrat font-thin text-sm  content-center flex flex-wrap ">
                            Full time nurse
                          </span>
                          <span className="mt-1 px-1 font-montserrat text-gray-primary font-thin  text-sm  content-center flex flex-wrap ">
                            24*7
                          </span>
                        </div>
                      )}
                      <div className="flex">
                        <div className=" font-semibold font-montserrat text-right text-sm">
                          <div className="ml-40">
                            <span className="text-brand-lightgreen text-right font-semibold font-montserrat text-xs ">
                              Expires
                            </span>
                          </div>
                          <div className="mt-1 p-1">
                            <p className="text-gray-700 text-right font-semibold font-montserrat text-xs ">{`23 July`}</p>
                          </div>
                        </div>
                        <div>
                          {/* <DotsVerticalIcon onClick={(e) => redirectTo(e, APP_ROUTES.POST_CONSULTATION)} className="cursor-pointer text-gray-secondary h-6 mt-2 relative left-3" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {i !== 1 && <hr className="mt-2" />}
                </>
              ))
              : null}
          </>
        )
      }

      {/* careplan card ends here */}

      {/* all add popups starts here  */}

      {
        showaddallergypopup ? (
          <AddAllergy
            editallergy={editallergy}
            closePopup={() => setshowaddallergypopup(!showaddallergypopup)}
          ></AddAllergy>
        ) : null
      }

      {
        showhealthrestrictionpopup ? (
          <AddHealthRestriction
            edithealthrestrictions={edithealthrestrictions}
            closePopup={() =>
              setshowhealthrestrictionpopup(!showhealthrestrictionpopup)
            }
          ></AddHealthRestriction>
        ) : null
      }

      {
        showmedicationpopup ? (
          <Addmedication
            editmedication={editmedication}
            closePopup={() => setshowmedicationpopup(!showmedicationpopup)}
          ></Addmedication>
        ) : null
      }

      {
        showmedicalhistorypopup ? (
          <Addmedicalhistory
            editmedicalhistory={editmedicalhistory}
            closePopup={() =>
              setshowmedicalhistorypopup(false)
            }
          ></Addmedicalhistory>
        ) : null
      }

      {
        showsurgicalhistorypopup ? (
          <Addsurgicalhistory
            editmedicalhistory={editsurgicalhistory}
            loadData={() => {
              loadData()
            }}
            closePopup={() =>
              setshowsurgicalhistorypopup(false)
            }
          ></Addsurgicalhistory>
        ) : null
      }

      {
        showfamilyhistorypopup ? (
          <Addfamilyhistory
            editmedicalhistory={editfamilyhistory}
            loadData={() => {
              loadData()
            }}
            closePopup={() =>
              setshowfamilyhistorypopup(false)
            }
          ></Addfamilyhistory>
        ) : null
      }
      {showsAddictionpopup ? (
        <AddAddiction
          // patient={props.patient}
          loadData={() => {
            loadData()
          }}
          closePopup={() =>
            setshowAddictionpopup(false)
          }
        ></AddAddiction>
      ) : null}
      {
        showsocialhistorypopup ? (
          <Addsocialhistory
            editmedicalhistory={editfamilyhistory}
            loadData={() => {
              loadData()
            }}
            closePopup={() =>
              setshowsocialhistorypopup(false)
            }
          ></Addsocialhistory>
        ) : null
      }

      {showtravelhistorypopup ? (
        <Addtravelhistory
          patient={props.patient}
          edittravelhistory={edittravelhistory}
          loadData={() => {
            loadData()
          }}
          closePopup={() =>
            setshowtravelhistorypopup(false)
          }
        ></Addtravelhistory>
      ) : null}

      {showsexualhistorypopup ? (
        <Addsexualhistory
          patient={props.patient}
          editsexualhistory={editsexualhistory}
          loadData={() => {
            loadData()
          }}
          closePopup={() =>
            setshowsexualhistorypopup(false)
          }
        ></Addsexualhistory>
      ) : null}

      {
        showmedicalconditionpopup ? (
          <Addmedicalcondition
            editmedicalcondition={editmedicalcondition}
            closePopup={() =>
              setshowmedicalconditionpopup(!showmedicalconditionpopup)
            }
          ></Addmedicalcondition>
        ) : null
      }

      {
        showchronicalhistorypopup ? (
          <AddChronicalHistory
            patient={props.patient}
            //editsocialhistory={editchronicalhistory}
            loadData={() => {
              loadData()
            }}
            closePopup={() =>
              setshowchronicalhistorypopup(false)
            }
          ></AddChronicalHistory>
        ) : null
      }
    </div >
  );
}
export default DetailCard;
