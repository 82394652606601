import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import patientService from "../Redux/services/patientService";

export default function RequestAccess(props) {
    const dispatch = useDispatch();

    const save = (e) => {
        e.preventDefault();
        console.log(JSON.stringify(props.appointmentObj));
        patientService.requestaccess(props.appointmentObj).then((res) => {
            if(res.data === 1) {
                props.requestedAccess();
            }
        }, (err) => {
            console.log(err);
        });

        return;
        //props.filterDoctors(filterlist);
        //props.requestedAccess(e);
    };
    const cancel = (e) => {
        props.closePopup(e);
        e.preventDefault();
    };

    const redirectTo = (event) => {
        event.preventDefault();
        props.onClose();
    };

    useEffect(() => {
        
    }, []);

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 antialiased justify-between border border-gray-200">
                            <div className="flex justify-between">
                                <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">
                                    Request Access
                                </h1>
                                {/* <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">Add New Address</h1> */}
                                <XIcon onClick={redirectTo} className="h-5 cursor-pointer" />
                            </div>
                            <hr className="mt-2"></hr>

                            <div className="flex pt-2 w-full ">
                                <div className="w-full">
                                    <div className="lg:flex justify-between py-6 lg:space-x-10">
                                        <label>Do you need to view the patient medical records?</label>
                                    </div>
                                    
                                    <div className="flex justify-end">
                                        <button
                                            type="submit"
                                            onClick={cancel}
                                            className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            onClick={save}
                                            className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
