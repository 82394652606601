import { PAT } from "../Constants/patientConstants";
import { VITALS } from "../Constants/vitalConstants";


const initialState = {
    patientdetailsData: [],
    isError: false,
    isLoading: false,
    msg: '',
    errMsg: ''
};


const patientdetailsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PAT.REQUEST_PATIENTLIST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case PAT.SUCCESS_PATIENTLIST:
            return {
                ...state,
                isLoading: false,
                patientdetailsData : payload,
                isError: false,              
            };
        case PAT.FAILED_PATIENTLIST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };
        default:
            return state;
    }
};

export default patientdetailsReducer;