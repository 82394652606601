import profile from '../Assets/Images/profilee.svg';
import camera from '../Assets/Images/camera.svg';
import React, { Component, useState, useEffect } from 'react'
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { getparticulardoctordetails } from '../Redux/Actions/doctAction';
import { editProfileDetails } from '../Redux/Actions/doctAction';
import { sendNotification } from '../Redux/Actions/doctAction';
import ProfileSidebar from './profileSidebar';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import { Link, useHistory, } from 'react-router-dom';
import { encodeBase64File } from "../helper/filebase64";
import DatePicker from "react-datepicker";
import ProfileNamebar from './profileNamebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userprofileservice from '../Redux/services/userprofileservice';
import AddMapSepciality from './AddMapSpeciality';
import FilterMapSpeciality from './FilterMapSpeciality';
import {Dropdown} from 'react-bootstrap'
import { MenuIcon} from '@heroicons/react/outline'

const MobileMapSpeciality = () => {

  const [showAddMapSpeciality, setShowAddMapSpeciality] = useState(false);
  const [showEditMapSpeciality, setShowEditMapSpeciality] = useState(false);
  const [showFilterMapSpeciality, setShowFilterMapSpeciality] = useState(false);

  const [mappedSpecialityList, setMappedSpecialityList] = useState([]);
  const [editMapSpeciality, setEditMapSpeciality] = useState();

  useEffect(() => {
    loadMappedSpecialities();
  }, []);




  const loadMappedSpecialities = () => {
    let userObj = JSON.parse(localStorage.getItem('userObj'));
    console.log(userObj.code);
    const payload = {
      userId: userObj.code,
      status: 1
    }

    userprofileservice.getMappedSpecialities(payload).then((res) => {
      if (res.data) {
        setMappedSpecialityList(res.data);
        console.log('Speciality List ---> ' + JSON.stringify(res.data));
      }
    }, (err) => {
      console.log(err);
    })
  }

  const loadFilterMapSpeciality = (data) => {
    console.log(JSON.stringify(data));
    setShowFilterMapSpeciality(false);
    let userObj = JSON.parse(localStorage.getItem('userObj'));
    let payload = {
        userId: userObj.code
    };
    if(data.specialityCode) {
        payload.specialityCode = data.specialityCode;
    }
    if(data.symptoms) {
        payload.symptoms = data.symptoms;
    }
    if(!data.status) {
        payload.status = 1;
    }
    console.log(JSON.stringify(payload));
    userprofileservice.getMappedSpecialities(payload).then((res) => {
        console.log(res);
        if (res.data) {
            setMappedSpecialityList(res.data);
            console.log(JSON.stringify(res.data));
        }
    }, (err) => {
        console.log(err);
    })
} 

  const openAddMapSpeciality = () => {
    setShowAddMapSpeciality(true);
  }

  const openEditMapSpeciality = (value) => {
    mappedSpecialityList.forEach(element => {
      if(element.specialityCode == value) {
        console.log(element);
          setEditMapSpeciality(element);
          //setShowEditMapSpeciality(true);
      }
  });
    setShowEditMapSpeciality(true);
}

  const openFilterMapSpeciality = () => {
    setShowFilterMapSpeciality(true);
  }





  return (
    <>
     <div className="flex lg:hidden justify-between flex-col">
     <div className = "flex justify-center md:justify-start md:mx-4 " >
                <div className = "hidden md:block lg:hidden relative top-4 mr-4" >
                <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
              <MenuIcon className="block h-6 w-6" aria-hidden="true"/>

              </Dropdown.Toggle>

              <Dropdown.Menu className = "z-10" >
                <ProfileSidebar/>
              </Dropdown.Menu>
            </Dropdown>
            </div>
            <h1 className="text-medium text-center font-medium text-2xl p-3 font-16 text-brand-secondary font-rubik">
                            Mapped Speciality
                        </h1>
                </div>
                          
                        <div className="flex p-3 pt-0 justify-end">
                            <div className="theme-color menuitem cursor-pointer" onClick={openAddMapSpeciality}>
                                <div className="font-14"><span className="icon-new font-14 cursor-pointer">New</span></div>
                            </div>
                            {/* <div className="theme-color menuitem pl-5" onClick={openEditMapSpeciality}>
                                <div className="font-14"><span className="icon-view font-14">View</span></div>
                            </div> */}
                            <div className="theme-color menuitem pl-5" onClick={openFilterMapSpeciality}>
                                <div className="font-14"><span className="icon-filter font-14">Filter</span></div>
                            </div>
                        </div>
                {/* table start */}
                {/* <div className="w-11/12 lg:ml-10 ml-4 mt-3 border boder-gray-200 rounded-lg bg-white mr-8"> */}
                    

                    <div className="mx-4 mt-3 space-y-4 lg:space-y-4 mb-20 md:flex lg:block md:flex-wrap justify-between">
                    {mappedSpecialityList === 0 ?  <div className = "font-medium py-4 text-gray-500 flex justify-center items-center" style ={{height : "50vh"}} >No Data found</div> :
                    mappedSpecialityList.map((res, i) => (
                        <div className=" w-full border-solid border-2 rounded-2xl relative p-4 font-rubik mb-4 md:mb-0 lg:mb-4" onClick = { () => openEditMapSpeciality(res.specialityCode)} >
                            <div className="flex ">
                                <div className="text-left mr-2 w-full" >
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Speciality</span>
                                        <p className="text-sm font-montserrat text-base">{res.speciality}</p>
                                    </div>
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Symptoms</span>
                                        <p className="text-sm  font-montserrat text-base">{res.specialitySymptoms}</p>
                                    </div>
                                    <div>
                                        <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Status</span>
                                        <p className="text-sm font-montserrat text-base">{res.status == 1 ? 'Active' : 'Inactive'}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))}
                {/* </div>                          */}

                </div>
            </div>
      {showAddMapSpeciality && (
        <AddMapSepciality
          closePopup={() => {
            setShowAddMapSpeciality(false);
            loadMappedSpecialities();
          }}
          onClose={() => {
            setShowAddMapSpeciality(false);
          }}
        >
        </AddMapSepciality>
      )}
      {showEditMapSpeciality && editMapSpeciality && (
        <AddMapSepciality
          editData={editMapSpeciality}
          closePopup={() => {
            setShowEditMapSpeciality(false);
            loadMappedSpecialities();
          }}
          onClose={() => {
            setShowEditMapSpeciality(false);
          }}
        >
        </AddMapSepciality>
      )}
      {showFilterMapSpeciality && (
        <FilterMapSpeciality
          closePopup={() => {
            setShowFilterMapSpeciality(false);
            loadMappedSpecialities();
          }}
          filterData={loadFilterMapSpeciality}
          onClose={() => {
            setShowFilterMapSpeciality(false);
          }}
        >
        </FilterMapSpeciality>
      )}
    </>
  )


}
export default MobileMapSpeciality;