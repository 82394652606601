import { PAT } from "../Constants/patientConstants";
import { VITALS } from "../Constants/vitalConstants";


const initialState = {
    appointmentlistData: [],
    appointmentlistCount: 0,
    isError: false,
    isLoading: false,
    msg: '',
    errMsg: ''
};


const AppointmentlistReducer = (state = initialState, action) => {
    const { type, payload } = action;
    console.log("payload",payload)
    switch (type) {
        case PAT.REQUEST_APPOINTMENTLIST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case PAT.SUCCESS_APPOINTMENTLIST:
            return {
                ...state,
                isLoading: false,
                appointmentlistData: payload,
                isError: false,
            };
        case PAT.FAILED_APPOINTMENTLIST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };

        case PAT.REQUEST_APPOINTMENTCOUNT:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case PAT.SUCCESS_APPOINTMENTCOUNT:
            return {
                ...state,
                isLoading: false,
                appointmentlistCount: payload.data['appointment count'],
                isError: false,
            };
        case PAT.FAILED_APPOINTMENTCOUNT:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };

            case PAT.REQUEST_APPOINTMENTCOUNTS:
                return {
                    ...state,
                    isLoading: true,
                    isError: false
                };
            case PAT.SUCCESS_APPOINTMENTCOUNTS:
                return {
                    ...state,
                    isLoading: false,
                    appointmentlistCount: payload.data,
                    isError: false,
                };
            case PAT.FAILED_APPOINTMENTCOUNTS:
                return {
                    ...state,
                    isLoading: false,
                    errMsg: payload,
                    isError: true
                };
        default:
            return state;
    }
};

export default AppointmentlistReducer;