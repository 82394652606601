import React, { useState, useEffect } from "react";
import close from "../Assets/Images/closeee.svg";
import FamilyDropdown from "./Familydropdown";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import DatePicker from "react-datepicker";
import { patientaddallergy, patientEditallergy } from "../Redux/Actions/UserprofileActions";
import { getPatientallergylist } from "../Redux/Actions/UserprofilehealthrecordAction";
import { ToastContainer, toast } from 'react-toastify';
import { MultiSelect } from 'primereact/multiselect';
const drugAllergyList = [
  { label: 'Penicillin', value: 'Penicillin' },
  { label: 'Sulfonamide', value: 'Sulfonamide' },
  { label: 'ASA', value: 'ASA' },
  { label: 'NSAIDs', value: 'NSAIDs' },
  { label: 'Aspirin', value: 'Aspirin' },
  { label: 'Ibuprofen', value: 'Ibuprofen' },
  { label: 'Naproxen', value: 'Naproxen' },
];
const foodAllergyList = [
  { label: 'Egg', value: 'Egg' },
  { label: 'Peanut', value: 'Peanut' },
  { label: 'Peanut butter', value: 'Peanut butter' },
  { label: 'Milk', value: 'Milk' },
  { label: 'Nuts', value: 'Nuts' },
  { label: 'Soy', value: 'Soy' },
  { label: 'Fish', value: 'Fish' },
  { label: 'Animal meat', value: 'Animal meat' },
  { label: 'Wheat', value: 'Wheat' },
  { label: 'Animal Protein', value: 'Animal Protein' },
];
const chemicalAllergyList = [
  { label: 'Dyes', value: 'dyes' },
  { label: 'Colour Additives', value: 'colour_additives' },
  { label: 'Cosmetics', value: 'cosmetics' },
  { label: 'Soap', value: 'soap' },
  { label: 'Moisturiser', value: 'moisturiser' },
  { label: 'Perfume', value: 'perfume' },
];
const otherAllergyList = [
  { label: 'Animal Dander', value: 'Animal Dander' },
  { label: 'Insect Bite', value: 'Insect Bite' },
  { label: 'Insect Sting', value: 'Insect Sting' },
  { label: 'Pollen', value: 'Pollen' },
  { label: 'Insect Faeces', value: 'Insect Faeces' },
  { label: 'House Dust Mites', value: 'House Dust Mites' },
  { label: 'Mite Faeces', value: 'Mite Faeces' },
  { label: 'Natural Rubber Latex', value: 'Natural Rubber Latex' },

];
function AddAllergy(props) {
  const dispatch = useDispatch();
  // const addallergy = useSelector((state) => state.addallergy);
  const [addallergies, setaddallergy] = useState([]);
  const [givenDate, setGivenDate] = useState("")
  const [givenDateShow, setGivenDateShow] = useState("")
  const [memberCode, setMemberCode] = useState("");
  const [drugallergieslist, setDrugallergieslist] = useState([]);
  const [foodallergieslist, setFoodallergieslist] = useState([]);
  const [chemicalallergieslist, setChemicalallergieslist] = useState([]);
  const [otherallergieslist, setOtherallergieslist] = useState([]);
  const goBack = () => {
    props.closePopup();
  };
  // editallergy
  useEffect(() => {
    if (props?.editallergy) {
      setaddallergy(props?.editallergy)
      setGivenDateShow(moment(props?.editallergy?.givenDate).format("MM/DD/yyyy"))
    }
  }, []);
  const userData = useSelector((state) => state.authReducer.userData);

  const handleChange = (e) => {
    setaddallergy({ ...addallergies, [e.target.name]: e.target.value });
  };

  const changeDate = (e) => {
    setGivenDate(moment(e).format("YYYY-MM-DD hh:mm:ss"))
    setGivenDateShow(moment(e).format("MM/DD/yyyy"))
  };

  const patientdata = localStorage.getItem("patientprofile");
  const saveallergy = (e) => {

    if (!addallergies.drugAllergy && drugallergieslist.length == 0) {
      return toast("Please Enter Drug Allergy")
    }
    if (!addallergies.foodAllergy && foodallergieslist.length == 0) {
      return toast("Please Enter Food Allergy")
    }
    if (!addallergies.chemicalAllergy && chemicalallergieslist.length == 0) {
      return toast("Please Enter Chemical Allergy")
    }
    if (!addallergies.otherAllergy && otherallergieslist == 0) {
      return toast("Please Enter Any Other Allergy")
    }
    e.preventDefault();

    if (props?.editallergy) {
      let patient = localStorage.getItem("patientprofile");
      const data = [{
        "otherAllergy": !addallergies.otherAllergy ? props?.editallergy?.otherAllergy : addallergies.otherAllergy,
        "chemicalAllergy": !addallergies.chemicalAllergy ? props?.editallergy?.chemicalAllergy : addallergies.chemicalAllergy,

        "createdBy": patient,
        "createdDate": "",
        "drugAllergy": !addallergies.drugAllergy ? props?.editallergy?.drugAllergy : addallergies.drugAllergy,
        "foodAllergy": !addallergies.foodAllergy ? props?.editallergy?.foodAllergy : addallergies.foodAllergy,
        "fromDate": "",
        "givenDate": !givenDate ? props?.editallergy?.givenDate : givenDate,
        "id": props?.editallergy?.id,
        "modifiedBy": patient,
        "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "patientCode": patient,
        "status": 1,
        "toDate": ""
      }]
      dispatch(patientEditallergy(data)).then((result) => {
        dispatch(getPatientallergylist(patient));
        props.closePopup()
      })
    }
    else {
      const drugAllergyString = `${drugallergieslist.join(', ')}, ${addallergies.drugAllergy}`;
      const foodAllergyString = `${foodallergieslist.join(', ')}, ${addallergies.drugAllergy}`;
      const chemicalAllergyString = `${chemicalallergieslist.join(', ')}, ${addallergies.drugAllergy}`;
      const otherAllergyString = `${otherallergieslist.join(', ')}, ${addallergies.drugAllergy}`;
      const cleanedStringDrug = removeUndefined(drugAllergyString);
      const cleanedStringFood = removeUndefined(foodAllergyString);
      const cleanedStringChe = removeUndefined(chemicalAllergyString);
      const cleanedStringOther = removeUndefined(otherAllergyString);
      let patient = localStorage.getItem("patientprofile");
      const data = [{
        "chemicalAllergy": cleanedStringChe,
        "otherAllergy": cleanedStringOther,
        "drugAllergy": cleanedStringDrug,
        "foodAllergy": cleanedStringFood,
        "createdBy": props.patient ? props.patient : patient,
        "createdDate": "",
        "fromDate": "",
        "givenDate": givenDate,
        "modifiedBy": props.patient ? props.patient : patient,
        "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "patientCode": props.patient ? props.patient : patient,
        "status": 1,
        "toDate": ""
      }]
      dispatch(patientaddallergy(data)).then((result) => {
        dispatch(getPatientallergylist(patient));
        props.closePopup()
      })
    }
  };
  const removeUndefined = (str) => {
    const words = str.split(',').map((word) => word.trim());
    const filteredWords = words.filter((word) => word !== 'undefined');
    return filteredWords.join(', ');
  };

  return (
    <>
      <ToastContainer />

      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 left-4 sm:left-0 rounded-lg shadow-lg relative flex flex-col w-11/12 sm:w-full p-5 bg-white outline-none focus:outline-none">
            {/*body*/}

            <div className="lg:pl-2 lg:pr-5  flex items-center justify-between">
              <p className="inline px-2 rounded-full text-md font-rubik font-medium cursor-pointer text-brand-secondary">
                {props?.editallergy ? "Edit Allergy Details" : "Add Allergy Details"}
              </p>
              <div className="flex space-x-6 cursor-pointer">
                <img src={close} alt="close" className="w-4" onClick={goBack} />
              </div>
            </div>
            <hr className="mt-2" />
            <div className="lg:flex  justify-between pt-5">
              <div>
                {/* <p className="px-3 pb-2 text-sm text-gray-700 font-medium">
                  Personal Details
                </p> */}
                {/* <div className="flex space-x-12 pt-4 lg:pl-12 px-3"> */}
                {/* <div className="relative">
                    <div className="flex">
                      {/* <div className="w-36  py-2 outline-none peer text-xs text-gray-700  border-b-2 border-gray-300 "> */}
                {/* <FamilyDropdown title={""} onSelect={(code)=>{setMemberCode(code)}} /> */}
                {/* </div> */}
                {/* </div>
                    <label
                      for="email"
                      className="absolute left-0 -top-3.5 text-gray-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Record For
                    </label>
                  </div> */}

                {/* <div className="flex space-x-12 pt-4 lg:pl-12 px-3 pt-10">
                  <div className="relative">
                    <div className="flex w-96">
                      <textarea
                        autocomplete="off"
                        id="notes"
                        name="chemicalAllergy"
                        rows={4}
                        type="text"
                        onChange={handleChange}
                        className="peer w-full  h-50  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Enter Information"
                      />
                    </div>
                    <label
                      htmlFor="notes"
                      className="absolute left-0 -top-3.5 text-gray-600 text-xs 
                                            peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 
                                            peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 
                                            peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Additional Notes<span className="text-red-500">*</span>
                    </label>
                  </div>
                </div> */}
                <div class="grid grid-cols-2 gap-3 ">
                  <div class="relative">
                    <label
                      htmlFor="recordOn"
                      className="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Record On
                    </label>
                    <div className="flex ">
                      <DatePicker
                        id="givenDate"
                        name="givenDate"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        // className="pt-2 text-gray-900 "
                        dateFormat="DD/MM/yyyy"
                        value={givenDateShow ? moment(givenDateShow).format("DD/MM/yyyy") : ""}
                        onSelect={changeDate}
                        disabledKeyboardNavigation={true}
                        autoFocus={false}
                        placeholderText="Record On"
                        className={
                          "border-b-2 border-gray-300 pt-2 text-gray-900 bg-transparent"
                        }
                      />
                    </div>
                  </div>
                  <div class="relative">
                  </div>
                  <div class="relative">
                    <label for="drugAllergy" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Drug Allergies</label>
                    <div className="flex mt-2">
                      <MultiSelect
                        value={drugallergieslist}
                        options={drugAllergyList}
                        // style={{ width: '100% !important', maxWidth: '350px !important' }}
                        panelClassName={{ width: '100%' }}
                        panelStyle={{ width: "100%" }}
                        className="w-full peer maxwidth-panel h-10 border-b-2 text-gray-500 border-black-500 focus:outline-none focus:borer-rose-600"
                        onChange={(e) => setDrugallergieslist(e.value)}
                        // onHide={addLabTests}
                        optionLabel="label"
                        // selectedItemTemplate={(option) => <span style={{ marginRight: '8px' }}>{option.name}</span>}
                        // menuStyle={{ width: '100%' }}
                        optionValue="value"
                        placeholder="Select options"
                        // filter placeholder="Select"
                        filter={true}
                        filterPlaceholder="Select"
                      />
                    </div>
                  </div>
                  <div class="relative">
                    <label for="drugAllergy" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Drug Allergies</label>
                    <div className="flex ">
                      <input autocomplete="off" id="drugAllergy" name="drugAllergy" type="text" class="peer lg:w-full w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Drug Allergies"
                        onChange={handleChange}
                        value={addallergies.drugAllergy}
                      />
                    </div>
                  </div>
                  <div class="relative">
                    <label for="foodAllergy" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Food Allergies</label>
                    <div className="flex mt-2">
                      <MultiSelect
                        value={foodallergieslist}
                        options={foodAllergyList}
                        // style={{ width: '100% !important', maxWidth: '350px !important' }}
                        panelClassName={{ width: '100%' }}
                        panelStyle={{ width: "100%" }}
                        className="w-full peer maxwidth-panel h-10 border-b-2 text-gray-500 border-black-500 focus:outline-none focus:borer-rose-600"
                        onChange={(e) => setFoodallergieslist(e.value)}
                        // onHide={addLabTests}
                        optionLabel="label"
                        // selectedItemTemplate={(option) => <span style={{ marginRight: '8px' }}>{option.name}</span>}
                        // menuStyle={{ width: '100%' }}
                        optionValue="value"
                        // filter placeholder="Select"
                        placeholder="Select options"
                        filter={true}
                        filterPlaceholder="Select"
                      />
                    </div>
                  </div>
                  <div class="relative">
                    <label for="foodAllergy" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Food Allergies</label>
                    <div className="flex">
                      <input autocomplete="off" id="foodAllergy" name="foodAllergy" type="text" class="peer lg:w-full w-40 h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="Enter Food Allergies"
                        onChange={handleChange}
                        value={addallergies.foodAllergy}
                      />
                    </div>
                  </div>
                  <div class="relative">
                    <label for="chemicalAllergy" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Chemical Allergies</label>
                    <div className="flex mt-2">
                      <MultiSelect
                        value={chemicalallergieslist}
                        options={chemicalAllergyList}
                        // style={{ width: '100% !important', maxWidth: '350px !important' }}
                        panelClassName={{ width: '100%' }}
                        panelStyle={{ width: "100%" }}
                        className="w-full peer maxwidth-panel h-10 border-b-2 text-gray-500 border-black-500 focus:outline-none focus:borer-rose-600"
                        onChange={(e) => setChemicalallergieslist(e.value)}
                        // onHide={addLabTests}
                        optionLabel="label"
                        // selectedItemTemplate={(option) => <span style={{ marginRight: '8px' }}>{option.name}</span>}
                        // menuStyle={{ width: '100%' }}
                        optionValue="value"
                        placeholder="Select options"
                        // filter placeholder="Select"
                        filter={true}
                        filterPlaceholder="Select"
                      />
                    </div>
                  </div>
                  <div class="relative">
                    <label for="chemicalAllergy" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Chemical Allergies</label>
                    <div className="flex ">
                      <input autocomplete="off" id="chemicalAllergy" name="chemicalAllergy" type="text" class="peer lg:w-full w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter Chemical Allergies"
                        onChange={handleChange}
                        value={addallergies.chemicalAllergy}
                      />
                    </div>
                  </div>
                  <div class="relative">
                    <label for="otherAllergy" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Other Allergies</label>
                    <div className="flex mt-2">
                      <MultiSelect
                        value={otherallergieslist}
                        options={otherAllergyList}
                        // style={{ width: '100% !important', maxWidth: '350px !important' }}
                        panelClassName={{ width: '100%' }}
                        panelStyle={{ width: "100%" }}
                        className="w-full peer maxwidth-panel h-10 border-b-2 text-gray-500 border-black-500 focus:outline-none focus:borer-rose-600"
                        onChange={(e) => setOtherallergieslist(e.value)}
                        // onHide={addLabTests}
                        optionLabel="label"
                        // selectedItemTemplate={(option) => <span style={{ marginRight: '8px' }}>{option.name}</span>}
                        // menuStyle={{ width: '100%' }}
                        optionValue="value"
                        placeholder="Select options"
                        // filter placeholder="Select"
                        filter={true}
                        filterPlaceholder="Select"
                      />
                    </div>
                  </div>
                  <div class="relative">
                    <label for="OtherAllergy" class="text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Other Allergies</label>
                    <div className="flex ">
                      <input autocomplete="off" id="otherAllergy" name="otherAllergy" type="text" class="peer lg:w-full w-40  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter other Allergies"
                        onChange={handleChange}
                        value={addallergies.otherAllergy}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-end mt-3">
                  <button
                    onClick={saveallergy}
                    className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                  >
                    Save Data{" "}
                  </button>
                </div>
              </div>
            </div>

            {/*  */}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
export default AddAllergy;
