
import React, { useState } from 'react'
import { sendOtp, verifyLogOtp, loginWithPassword, getToken, forgotPassword } from '../Redux/Actions/userActions';
import { NOEMAIL, FORGOTPASSWORDLINK } from '../config/constant';
import { connect, useDispatch, useSelector } from 'react-redux';
import banner from '../Assets/Images/loginImage.png';
import logo from '../Assets/Images/logo.svg';
import { useHistory } from "react-router-dom";



const FormFooter = (props) => {
    const history = useHistory();
    const redirect = () =>{
        history.push("/login");
    }
    return (
        <div>
            <div className="text-center text-brand-secondary mt-5 font-medium cursor-pointer" onClick = {redirect} >Go to Login Page</div>
        </div>
    )
}


const ForgotPassword = (props) => {
    const { isLoading, isError, changepasswordData } = useSelector(state => state.changepassword)
    const dispatch = useDispatch();
    const [re] = useState(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
    const [userCode, setUserCode] = useState('');
    const [show, setshow] = useState(false)
    const [err, setErr] = useState('');

    const handleChange = (val) => {
        setUserCode(val)
    }
    const sendEmail = (e) => {
        e.preventDefault();
        var validation = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
        if (!validation.test(userCode)) {
            setErr("Enter valid Email Id or Mobile Number")
        }
        else {
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
            if (userCode.match(regexEmail)) {
                setErr("")
                let data = {
                    "email": userCode
                }
                setshow(true)
                dispatch(forgotPassword(data))
            } else if (userCode.match(phoneRegex)) {
                setErr("")
                let data = {
                    "mobile": userCode
                }
                setshow(true)
                dispatch(forgotPassword(data))
            } else {
                setErr("")
                let data = {
                    "userCode": userCode
                }
                setshow(true)
                dispatch(forgotPassword(data))
            }
            // let data = {
            //     "userCode": userCode
            // }
            // setshow(true)
            // dispatch(forgotPassword(data))
        }
        // let data = {
        //     "userCode": userCode
        // }
        // dispatch(forgotPassword(data))
    }


    return (
        <>
            <form onSubmit={sendEmail}>
                <div className="">
                    <div className="flex font-rubik lg:border ">
                        <div className="lg:flex hidden w-full " style={{ background: '#d7e3e0' }}>
                            <img
                                className="w-full h-full"
                                src={banner}
                                draggable={false}
                                alt="banner"
                            />
                        </div>
                        <div className="items-center lg:pt-44 pt-32  w-full ">
                            <img
                                className="lg:hidden mb-10 text-center items-center m-auto"
                                src={logo}
                                draggable={false}
                                alt="banner"
                            />
                            <div className="lg:block hidden text-center lg:px-28 font-rubik text-4xl">
                                <p className="font-medium text-brand-primary text-center">{'Help CureBay Build a'}
                                </p>
                                <p className="font-medium text-brand-primary text-center">Healthier Community </p>
                            </div>
                            <div className="lg:hidden block text-center lg:px-28 font-rubik text-4xl">
                                <p className="font-medium text-brand-primary text-center">{'Welcome to CureBay '}
                                </p>
                            </div>
                            <div className="flex flex-col lg:px-36 px-10">
                                {/* <div className="lg:block hidden text-sm pt-4 mb-5 font-normal font-rubik text-gray-primary text-center">
                                Curebay Doctors
                            </div> */}
                                <div className=" block text-xl pt-4 mb-5 font-normal font-rubik text-gray-primary text-center">
                                    Forgot Password
                                </div>
                                <div className="ml-5">
                                    <div>
                                        <div className={"flex flex-col mb-6"}>
                                            <span className={`text-gray-600 font-rubik font-light text-xs  tracking-widest`}>{'Enter Email Id or Mobile Number'}</span>
                                            <div className="flex relative ">
                                                <input
                                                    name="usercode"
                                                    id="sign-in-email-password"
                                                    placeholder='Enter Email Id or Mobile Number'
                                                    onChange={(e) => { handleChange(e.target.value) }}
                                                    className={`appearance-none text-sm font-montserrat border-b  w-full py-1 bg-transparentwhite placeholder-gray-secondary  focus:outline-none ${'border-gray-highlight'}`}
                                                />
                                            </div>
                                            {err && <span className="text-red-600 font-rubik font-light text-xs  tracking-widest">{err}</span>}
                                            <div className=" w-full mt-8">
                                                <button
                                                    type="submit"
                                                    // onClick={sendEmail}
                                                    disabled={isLoading || !userCode}
                                                    className={`p-2 bg-brand-secondary  text-white w-full  rounded-lg text-center text-base font-normal}`}
                                                >
                                                    Submit
                                                    {isLoading && <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>}
                                                </button>
                                                {isError && show ? <span className={`text-red-600  font-rubik font-light text-xs  tracking-widest`}>{NOEMAIL}</span> : null}
                                                {changepasswordData && show ? <span className={`text-green-600 font-rubik font-light text-xs mt-2 tracking-widest`}>{FORGOTPASSWORDLINK}</span> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <FormFooter {...props} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    sendOTP: (data) => dispatch(sendOtp(data)),
    verifyOTP: (data) => dispatch(verifyLogOtp(data)),
    loginWithPassword: (data) => dispatch(loginWithPassword(data)),
    getToken: () => dispatch(getToken())

});

export default connect(null, mapDispatchToProps,)(ForgotPassword);
