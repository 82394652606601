export const HEALTHRECORD = {
    REQUEST_ALLERGYLIST: "REQUEST_ALLERGYLIST",
    SUCCESS_ALLERGYLIST: "SUCCESS_ALLERGYLIST",
    FAILED_ALLERGYLIST: "FAILED_ALLERGYLIST",

    REQUEST_MEDICATIONLIST: "REQUEST_MEDICATIONLIST",
    SUCCESS_MEDICATIONLIST: "SUCCESS_MEDICATIONLIST",
    FAILED_MEDICATIONLIST: "FAILED_MEDICATIONLIST",

    REQUEST_MEDICALHISTORYLIST: "REQUEST_MEDICALHISTORYLIST",
    SUCCESS_MEDICALHISTORYLIST: "SUCCESS_MEDICALHISTORYLIST",
    FAILED_MEDICALHISTORYLIST: "FAILED_MEDICALHISTORYLIST",

    REQUEST_SURGICALHISTORYLIST: "REQUEST_SURGICALHISTORYLIST",
    SUCCESS_SURGICALHISTORYLIST: "SUCCESS_SURGICALHISTORYLIST",
    FAILED_SURGICALHISTORYLIST: "FAILED_SURGICALHISTORYLIST",


    REQUEST_FAMILYHISTORYLIST: "REQUEST_FAMILYHISTORYLIST",
    SUCCESS_FAMILYHISTORYLIST: "SUCCESS_FAMILYHISTORYLIST",
    FAILED_FAMILYHISTORYLIST: "FAILED_FAMILYHISTORYLIST",
    
    REQUEST_SOCIALHISTORYLIST: "REQUEST_SOCIALHISTORYLIST",
    SUCCESS_SOCIALHISTORYLIST: "SUCCESS_SOCIALHISTORYLIST",
    FAILED_SOCIALHISTORYLIST: "FAILED_SOCIALHISTORYLIST",

        
    REQUEST_TRAVELHISTORYLIST: "REQUEST_TRAVELHISTORYLIST",
    SUCCESS_TRAVELHISTORYLIST: "SUCCESS_TRAVELHISTORYLIST",
    FAILED_TRAVELHISTORYLIST: "FAILED_TRAVELHISTORYLIST",

    REQUEST_SEXUALHISTORYLIST: "REQUEST_SEXUALHISTORYLIST",
    SUCCESS_SEXUALHISTORYLIST: "SUCCESS_SEXUALHISTORYLIST",
    FAILED_SEXUALHISTORYLIST: "FAILED_SEXUALHISTORYLIST",

    REQUEST_ADDICTIONSLIST: "REQUEST_ADDICTIONSLIST",
    SUCCESS_ADDICTIONSLIST: "SUCCESS_ADDICTIONSLIST",
    FAILED_ADDICTIONSLIST: "FAILED_ADDICTIONSLIST",

    REQUEST_MEDICATIONHISTORYLIST: "REQUEST_MEDICATIONHISTORYLIST",
    SUCCESS_MEDICATIONHISTORYLIST: "SUCCESS_MEDICATIONHISTORYLIST",
    FAILED_MEDICATIONHISTORYLIST: "FAILED_MEDICATIONHISTORYLIST",

    REQUEST_VITALSLIST: "REQUEST_VITALSLIST",
    SUCCESS_VITALSLIST: "SUCCESS_VITALSLIST",
    FAILED_VITALSLIST: "FAILED_VITALSLIST",



}
