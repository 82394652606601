import profile from "../Assets/Images/profilee.svg";
import camera from "../Assets/Images/camera.svg";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getparticulardoctordetails } from "../Redux/Actions/doctAction";
import { editProfileDetails } from "../Redux/Actions/doctAction";
import { sendNotification } from "../Redux/Actions/doctAction";
import ProfileSidebar from "./profileSidebar";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import { Link, useHistory } from "react-router-dom";
import { encodeBase64File } from "../helper/filebase64";
import DatePicker from "react-datepicker";
import ProfileNamebar from "./profileNamebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Logout } from "../Redux/Actions/userActions";
import { Dropdown } from "react-bootstrap";
import Sidebar from "./profileSidebar";
import { MenuIcon } from "@heroicons/react/outline";
import AuthDataService from "../Redux/services/authService";
import { Dialog } from "primereact/dialog";
function DoctorProfile() {
  const history = useHistory();
  const dispatchd = useDispatch();
  const [stateList, setStateList] = useState([]);
  const dispatch = useDispatch();

  const [termsData, setTermsData] = useState();
  const [termsView, setTermsView] = useState(false);
  const [privacyData, setPrivacyData] = useState();
  const [privacyView, setPrivacyView] = useState(false);
  const [termsAccept, setTermsAccept] = useState(true);
  const [policyAccept, setPolicyAccept] = useState(true);
  const [SPdetails, setSPDetails] = useState();
  const [consentVersionT, setConsentVersionT] = useState();
  const [consentVersionP, setConsentVersionP] = useState();
  const [showConsentView, setShowConsentView] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  useEffect(() => {
    let consentVersion = "";
    if (process.env.REACT_APP_BASEURL === "https://api-stage.curebay.in/dhp/") {
      consentVersion = `https://common-stage.curebay.in/cs/ConsentVersion/list/filter?userType=SP&status=1`;
    } else if (
      process.env.REACT_APP_BASEURL === "https://doctorapi.curebay.com/doctor/"
    ) {
      consentVersion = `https://common.curebay.com/cs/ConsentVersion/list/filter?userType=SP&status=1`;
    } else {
      consentVersion = `https://common-stage.curebay.in/cs/ConsentVersion/list/filter?userType=SP&status=1`;
    }
    AuthDataService.getUserConsent(consentVersion).then((res) => {
      const policy = res.data.filter(
        (val) => val.constentName === "PrivacyPolicy"
      );
      setConsentVersionP(policy[0]);
      const terms = res.data.filter(
        (val) => val.constentName === "TermsAndCondition"
      );
      setConsentVersionT(terms[0]);
    });
    let consentVersionUrl = "";
    if (process.env.REACT_APP_BASEURL === "https://api-stage.curebay.in/dhp/") {
      consentVersionUrl = `https://common-stage.curebay.in/cs/UserConsent/list/filter?userId=${loggedInData.userData.code}&status=1`;
    } else if (
      process.env.REACT_APP_BASEURL === "https://doctorapi.curebay.com/doctor/"
    ) {
      consentVersionUrl = `https://common.curebay.com/cs/UserConsent/list/filter?userId=${loggedInData.userData.code}&status=1`;
    } else {
      consentVersionUrl = `https://common-stage.curebay.in/cs/UserConsent/list/filter?userId=${loggedInData.userData.code}&status=1`;
    }
  }, []);
  const termsCheck = (e) => {
    setTermsAccept(e.target.checked);
    setTermsView(false);
  };
  const policyCheck = (e) => {
    setPolicyAccept(e.target.checked);
    setPrivacyView(false);
  };
  const renderPolicyFooter = (name) => {
    return (
      <div className="flex items-center mt-5 mb-5">
        <div className=" flex space-x-2">
          <input
            type="checkbox"
            onChange={(e) => policyCheck(e)}
            class="form-radio  mr-2"
            name="accountType"
            value="personal"
            checked={policyAccept}
          />
          <div className="text-sm sm:text-md font-medium  font-montserrat">
            <b>
              I UNDERSTAND THAT THE TERMS OF USE CONTAINED HEREIN ALONG WITH ITS
              CONSTITUENT PRIVACY POLICY
            </b>
          </div>
        </div>
      </div>
    );
  };
  const renderTermsFooter = (name) => {
    return (
      <div className="flex items-center mt-5 mb-5">
        <div className=" flex space-x-2">
          <input
            type="checkbox"
            onChange={(e) => termsCheck(e)}
            class="form-radio  mr-2"
            name="accountType"
            value="personal"
            checked={termsAccept}
          />
          <div className="text-sm sm:text-md font-medium  font-montserrat">
            <b>
              I UNDERSTAND THAT THE TERMS OF USE CONTAINED HEREIN ALONG WITH ITS
              CONSTITUENT PRIVACY POLICY
            </b>
          </div>
        </div>
      </div>
    );
  };
  const disAgreeConfirm = () => {
    if (!policyAccept || !termsAccept) {
      setLogoutConfirm(true);
    } else {
      setShowConsentView(false);
    }
  };
  const onConsentAgree = () => {
    const payload = {
      createdBy: loggedInData.userData.code,
      createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      modifiedBy: loggedInData.userData.code,
      modifiedDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      privacyPolicyConsent: policyAccept ? 1 : 0,
      sourceChannel: "DOCTER",
      status: 1,
      termsAndConditionConsent: termsAccept ? 1 : 0,
      type: "S",
      userId: loggedInData.userData.code,
      privacyVersionNumber: consentVersionP.versionNumber,
      tcVersionNumber: consentVersionT.versionNumber,
    };
    let url = "";
    if (process.env.REACT_APP_BASEURL === "https://api-stage.curebay.in/dhp/") {
      url = `https://common-stage.curebay.in/cs/PatientConsent/addPatientConsent`;
    } else if (
      process.env.REACT_APP_BASEURL === "https://doctorapi.curebay.com/doctor/"
    ) {
      url = `https://common.curebay.com/cs/PatientConsent/addPatientConsent`;
    } else {
      url = `https://common-stage.curebay.in/cs/PatientConsent/addPatientConsent`;
    }
    AuthDataService.userConsentPost(payload, url).then((res) => {
      if (res.data === 1) {
        setShowConsentView(false);
        if (
          payload.termsAndConditionConsent === 0 ||
          payload.privacyPolicyConsent === 0
        ) {
          logout();
        }
      }
    });
  };
  // const history = useHistory();
  // const dispatch = useDispatch();
  // const particulardoct = useSelector((state) => state.particulardoctor);
  // const { particulardoctorData } = particulardoct;

  // const loggedInData = useSelector((state) => state.authReducer);
  // const { userData } = loggedInData;

  const particulardoct = useSelector((state) => state.particulardoctor);
  //const loginObj = JSON.parse(localStorage.getItem('loginObj'));
  let { particulardoctorData } = particulardoct;
  // const  particulardoctorData =  loginObj.user;
  const loggedInData = useSelector((state) => state.authReducer);
  const { userData, patientSession } = loggedInData;

  useEffect(() => {
    // fetch(process.env.REACT_APP_BASEURL + "state/list")
    //     .then((res) => res.json())
    //     .then((result) => setStateList(result));
    AuthDataService.getStateList().then((res) => {
      setStateList(res.data);
    });
    dispatchd(getparticulardoctordetails(loggedInData.userData.code))
      .then((result) => {
        particulardoctorData = result[0];
        setProfile(particulardoctorData);
        setShowOTP(false);
      })
      .catch((error) => {
        // setLoader(false)
        // redirectTo();
      });

    //   dispatch(getparticulardoctordetails(loggedInData.userData.code));
    //   console.log(particulardoctorData);
    setShowOTP(false);
  }, []);

  const [showinput, setshowinput] = useState(false);
  const [showOTP, setShowOTP] = useState();
  const [dropdown, setdropdown] = useState(false);
  const [profile, setProfile] = useState({
    id: particulardoctorData.id || "",
    code: particulardoctorData.code || "",
    firstName: particulardoctorData.firstName || "",
    lastName: particulardoctorData.lastName || "",
    name: particulardoctorData.name || "",
    dob: particulardoctorData.dob || moment().format("DD/MM/YYYY"),
    salutation: particulardoctorData.salutation || "",
    //drugAllergies: particularpatientdetailsData.drugAllergies,
    //pLanguage: particularpatientdetailsData.pLanguage,
    gender: particulardoctorData.gender || "",
    bloodGroup: particulardoctorData.bloodGroup || "",
    //maritalStatus: particularpatientdetailsData.maritalStatus,
    email: particulardoctorData.email || "",
    photo: particulardoctorData.photo || "",
    photoName: particulardoctorData.photoName || "",
    contactNo: particulardoctorData.contactNo || "",
    mobile: particulardoctorData.mobile || "",
    registrationNo: particulardoctorData.registrationNo || "",
    maritalStatus: particulardoctorData.maritalStatus || "",
    roleName: particulardoctorData.roleName || "",
    speciality: particulardoctorData.speciality || "",
    address1: particulardoctorData.address1 || "",
    address2: particulardoctorData.address2 || "",
    city: particulardoctorData.city || "",
    state: particulardoctorData.state || "",
    country: particulardoctorData.country || "",
    //role: particularpatientdetailsData.role,
    pinCode: particulardoctorData.pinCode || "",
    // referredBy: particularpatientdetailsData.referredBy,
    //source: particularpatientdetailsData.source,
    status: particulardoctorData.status || "",
    aboutMe: particulardoctorData.aboutMe || "",
    isHospitalManagement: particulardoctorData.isHospitalManagement || "",
    //access: particularpatientdetailsData.access,
    //insuranceId: particularpatientdetailsData.insuranceId,
    //insuranceName: particularpatientdetailsData.insuranceName,
    //insuranceAmount: particularpatientdetailsData.insuranceAmount,
    qualification: particulardoctorData.qualification || "",
    address1: particulardoctorData.address1 || "",
    isApproved: particulardoctorData.isApproved || "",
    status: 1,
    roleCode: particulardoctorData.roleCode || "PROV",
    //mciverificationDisplay: new FormControl({ value: this.userObj.user.mciverification, disabled: true }),
    mciverification: particulardoctorData.mciverification || "",
    //modifiedDate: this.utill.convertUTCDate(new Date(), 'YYYY-MM-DD hh:mm:ss'),
    modifiedBy: localStorage.getItem("patientlogginedusercode"),
    createdBy: particulardoctorData.createdBy || "",
    //modifiedBy: particulardoctorData.modifiedBy,
  });
  const salutationData = [
    {
      sal: "Dr.",
    },
    {
      sal: "Mr.",
    },
    {
      sal: "Ms.",
    },
  ];
  const Genderdata = [
    {
      gender: "M",
      genderName: "Male",
    },
    {
      gender: "F",
      genderName: "Female",
    },
  ];

  const changeDate = (e) => {
    setProfile({ ...profile, ["dob"]: moment(e).format("DD/MM/yyyy") });
  };
  const handleChange = (e) => {
    console.log("profile :::>", e.target.value);
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };
  const hiddenFileInput = React.useRef(null);
  const clicktype = () => {
    console.log(showinput);
    if (showinput) {
      hiddenFileInput.current.click();
    }
  };
  const Editprofiledetails = (e) => {
    e.preventDefault();
    let notificationData = {
      userCode: particulardoctorData.code,
      patientName: " ",
      patientCode: "",
      date: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      subject: "Request for Edit",
      content:
        particulardoctorData.salutation +
        particulardoctorData.firstName +
        " " +
        particulardoctorData.lastName +
        " has requested to edit his profile.  Please contact the doctor and get the details to be changed, which should be approved by the Super Admin.",
      status: 1,
      createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      fromDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      updatedDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      createdBy: particulardoctorData.code,
      updatedBy: particulardoctorData.code,
      trigeredBy: particulardoctorData.code,
      trigeredTo: "DHPADMIN",
      trigeredUsertype: "U",
      fromName:
        particulardoctorData.salutation +
        particulardoctorData.firstName +
        " " +
        particulardoctorData.lastName,
    };
    dispatchd(sendNotification(notificationData))
      .then((result) => {
        console.log(result);
        if (!result.message) {
          toast(
            "Your request has been sent to our Customer Care Executive successfully.  Our executive will contact you shortly."
          );
        }
      })
      .catch((error) => {
        // setLoader(false)
        // redirectTo();
      });
    //console.log(showinput);
    //setshowinput(true);
  };
  const onCancel = () => {
    setProfile(particulardoctorData);
    setshowinput(false);
  };
  const changeHandler = async (file) => {
    console.log("photo", file);
    let b64File = await encodeBase64File(file);
    setProfile({ ...profile, ["photo"]: b64File });
  };
  const savechangesprofile = (e) => {
    e.preventDefault();

    profile.modifiedBy = particulardoctorData.code;
    if (particulardoctorData.mobile != profile.mobile) {
      sentOTP();
      return false;
    } else {
      dispatchd(editProfileDetails(profile))
        .then((result) => {
          if (result) {
            toast("updated successfully");
            //alert("updated successfully");
            setshowinput(false);
          }
        })
        .catch((error) => {
          // setLoader(false)
          // redirectTo();
        });
    }
    console.log("axsha", profile);
    // ;
    // to dispatch newly created object to createlocation action
  };

  const sentOTP = () => {
    setShowOTP(true);
  };

  const otpCancel = (e) => {
    console.log(e);
    e.preventDefault();
    setShowOTP(false);
  };

  const otpSuccess = (e) => {
    dispatchd(editProfileDetails(profile))
      .then((result) => {
        if (result) {
          toast("updated successfully");
          //alert("updated successfully");
          setShowOTP(false);
        }
      })
      .catch((error) => {
        // setLoader(false)
        // redirectTo();
      });
  };

  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
  };

  const showdropdown = (event) => {
    event.preventDefault();
    setdropdown(true);
  };
  const logout = (e) => {
    localStorage.clear();
    let dataObj = {
      sessionId: patientSession?.id,
      userCode: patientSession?.patientCode,
    };
    dispatch(Logout(dataObj));
    history.push(APP_ROUTES.Login);
  };

  return (
    <>
      <div className="flex justify-between ">
        <ToastContainer />
        <div className="lg:block hidden w-3/12 ml-6 mt-3">
          <ProfileSidebar></ProfileSidebar>
        </div>

        <div className="lg:w-8/12 w-full lg:mr-16 lg:mt-5 ">
          <div className="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 lg:mb-2 mb-16 antialiased justify-between border border-gray-200 mt-4">
            <div className="flex justify-between">
              <div className="flex">
                {/* <img src={select} alt="select" className="w-4 ml-3 " /> */}
                {/* <p className="font-montserrat font-normal text-sm leading-6 mb-3 mr-3 pl-2 mt-2">3 Items</p> */}
              </div>
              <div className="flex">
                {showinput ? null : (
                  <p
                    onClick={Editprofiledetails}
                    className="text-sm cursor-pointer text-brand-secondary font-medium pr-4"
                  >
                    Request for Edit
                  </p>
                )}
                <p
                  onClick={() => setShowConsentView(true)}
                  className="text-sm cursor-pointer text-brand-secondary font-medium pr-4"
                >
                  Update Consent
                </p>
              </div>
            </div>

            <div className="flex lg:flex md:flex sm-flex justify-start">
              <div className="hidden md:block lg:hidden relative top-2 mr-4">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="z-10">
                    <Sidebar />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="relative">
                {/* <img src={steth} alt="stethescope" className="w-24 h-24 mt-2 ml-4" /> */}
                {/* <div className="w-8 h-8 rounded-full bg-brand-secondary absolute bottom-0 right-0 p-2">
                                        <input autoComplete="off" id="photo" name="photo" type="file" value={profile.photo} onChange={handleChange} />
                                        <img src={camera} alt="camera" className="w-5" />
                                    </div> */}

                {particulardoctorData.photoName ? (
                  <img
                    src={`${
                      profile.photo
                        ? `${"data:image;base64,"}` + profile.photo
                        : `${
                            process.env.REACT_APP_IMG_BASEURL +
                            particulardoctorData.photoName
                          }`
                    }`}
                    alt="profile img"
                    className="profileRound"
                    style={{ borderRadius: "50%" }}
                    onClick={clicktype}
                    width="100"
                    height="100"
                  />
                ) : (
                  <div className="bg-white p-3 w-20 mt-6  flex justify-center    font-rubik  rounded-lg">
                    <span className="font-bold text-2xl">
                      {particulardoctorData?.firstName?.charAt(0)}
                    </span>
                  </div>
                )}
                {showinput ? (
                  <input
                    ref={hiddenFileInput}
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      changeHandler(e.target.files[0]);
                    }}
                    className="hidden"
                  />
                ) : // <div className="w-8 h-8 rounded-full bg-brand-secondary absolute bottom-0 right-0 p-2">

                //   <img src={camera} alt="camera" className="w-5" />
                // </div>
                null}
              </div>
              <div>
                <div className="flex justify-between">
                  <p className="ml-4 text-medium font-sm text-2xl font-rubik">
                    Dr. {particulardoctorData.firstName}{" "}
                    {particulardoctorData.lastName}
                  </p>
                </div>
                <div className="flex justify-between">
                  <div className=" flex ">
                    <p className="text-xs  text-center font-montserrat font-medium ml-5">
                      Admin ID : {particulardoctorData.code}
                    </p>
                  </div>
                  {/* <p className="text-xs font-medium">3:30 PM</p> */}
                </div>
              </div>
            </div>

            <div className="flex lg:flex md:flex justify-between content-center ">
              <div className="flex">
                {/* <img src={select} alt="select" className="w-4 ml-3 " /> */}
                <p className="text-black-900 font-medium text-lg mt-4">
                  Personal Details
                </p>
              </div>
            </div>

            <div className="grid md:grid-cols-3 lg:grid-cols-3 gap-6 xl:grid-cols-4 mt-4 justify-between content-center ">
              <div className="relative mb-4 w-11/12">
                <input
                  autoComplete="off"
                  id="UserId"
                  name="UserId"
                  type="text"
                  value={particulardoctorData.code || ""}
                  className={` border-0 pt-2 text-gray-900 bg-transparent`}
                  readOnly={true}
                  placeholder="user Code"
                />
                <label
                  htmlFor="firstName"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  User ID
                </label>
              </div>

              {/* style="margin-right: 68px;" */}
              <div className="relative mb-4 w-11/12">
                <select
                  autoComplete="off"
                  id="salutation"
                  name="salutation"
                  value={profile.salutation || ""}
                  disabled={!showinput}
                  className={`${
                    showinput ? "border-b-2 border-gray-300 " : "border-0 "
                  } mt-2 w-11/12 text-gray-900 pl-1 -ml-1 `}
                  placeholder="salutation"
                  onChange={handleChange}
                >
                  {salutationData.map((cit, i) => (
                    <option value={cit.sal}> {cit.sal}</option>
                  ))}
                </select>
                <label
                  htmlFor="salutation"
                  className="absolute   sm:ml-10  md:ml-0 left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  Salutation
                </label>
              </div>

              <div className="relative mb-4 w-11/12">
                <input
                  autoComplete="off"
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={profile.firstName || ""}
                  className={`${
                    showinput ? "w-25 border-b-2 border-gray-300 " : "border-0 "
                  }  sm:ml-10 md:ml-0  pt-2 text-gray-900 bg-transparent`}
                  readOnly={!showinput}
                  onChange={handleChange}
                  placeholder="First Name"
                />
                <label
                  htmlFor="firstName"
                  className="absolute   sm:ml-10  md:ml-0 left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  First Name
                </label>
              </div>

              <div className="relative mb-4 w-11/12">
                <input
                  autoComplete="off"
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={profile.lastName || ""}
                  className={`${
                    showinput ? "w-25 border-b-2 border-gray-300 " : "border-0 "
                  } pt-2 text-gray-900 bg-transparent`}
                  readOnly={!showinput}
                  onChange={handleChange}
                  placeholder="Last Name"
                />
                <label
                  htmlFor="lastName"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  Last Name
                </label>
              </div>

              <div className="relative mb-4 w-11/12">
                <input
                  autoComplete="off"
                  id="roleName"
                  name="roleName"
                  type="text"
                  value={particulardoctorData.roleName || ""}
                  className={`${
                    showinput ? "w-25 border-b-2 border-gray-300 " : "border-0 "
                  } pt-2 text-gray-900 bg-transparent`}
                  readOnly={true}
                  placeholder="Profession"
                />
                <label
                  htmlFor="roleName"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  Profession
                </label>
              </div>

              <div className="relative mb-4 w-11/12">
                <DatePicker
                  id="dob"
                  name="dob"
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  value={
                    profile.dob
                      ? moment(profile.dob, "DD/MM/YYYY").format("DD/MM/yyyy")
                      : ""
                  }
                  onSelect={changeDate}
                  disabledKeyboardNavigation={true}
                  maxDate={new Date()}
                  autoFocus={false}
                  readOnly={!showinput}
                  placeholder="Date of Birth"
                  className={`${
                    showinput ? "border-b-2 border-gray-300 " : "border-0 "
                  } pt-2 text-gray-900 bg-transparent`}
                />

                <label
                  htmlFor="dob"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  Date of Birth
                </label>
              </div>

              <div className="relative mb-4 w-11/12">
                <input
                  autoComplete="off"
                  id="email"
                  name="email"
                  type="email"
                  value={profile.email || ""}
                  className={`${
                    showinput ? "border-b-2 border-gray-300 " : "border-0 "
                  } w-72 pt-2 text-gray-900 bg-transparent`}
                  readOnly={!showinput}
                  placeholder="Email ID"
                  onChange={handleChange}
                />
                <label
                  htmlFor="email"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  Email ID
                </label>
              </div>

              {/* style="margin-left: 85px;" */}
              <div className="relative mb-4 w-11/12">
                <select
                  autoComplete="off"
                  id="gender"
                  name="gender"
                  value={profile.gender || ""}
                  disabled={!showinput}
                  className={`${
                    showinput ? "border-b-2 border-gray-300 " : "border-0 "
                  } mt-2 pl-1 bg-transparent text-gray-900 -ml-1`}
                  placeholder="Gender"
                  onChange={handleChange}
                >
                  {Genderdata.map((cit, i) => (
                    <option value={cit.gender}> {cit.genderName}</option>
                  ))}
                </select>
                <label
                  htmlFor="gender"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  Gender
                </label>
              </div>

              {/* style="margin-left: 103px;" */}
              <div className="relative mb-4  w-11/12">
                <input
                  autoComplete="off"
                  id="mobile"
                  name="mobile"
                  type="number"
                  value={profile.mobile || ""}
                  className={`${
                    showinput ? "border-b-2 border-gray-300 " : "border-0 "
                  } pt-2 text-gray-900 bg-transparent`}
                  readOnly={!showinput}
                  onChange={handleChange}
                  placeholder="Mobile Number"
                />
                <label
                  htmlFor="mobile"
                  className="absolute  left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  Mobile number
                </label>
              </div>

              <div className="relative mb-4 w-11/12">
                <input
                  autoComplete="off"
                  id="qualification"
                  name="qualification"
                  type="text"
                  value={profile.qualification || ""}
                  className={`${
                    showinput ? "w-25 border-b-2 border-gray-300 " : "border-0 "
                  } pt-2 text-gray-900 bg-transparent`}
                  readOnly={!showinput}
                  placeholder="Qualification"
                />
                <label
                  htmlFor="qualification"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  Qualification
                </label>
              </div>
            </div>
            <div className="flex lg:flex md:flex justify-between content-center ">
              <div className="flex">
                {/* <img src={select} alt="select" className="w-4 ml-3 " /> */}
                <p className="text-black-900 font-medium text-lg ">
                  Address Details
                </p>
              </div>
            </div>

            <div className="grid md:grid-cols-3 lg:grid-cols-3 gap-6 xl:grid-cols-4 mt-4 justify-between content-center ">
              <div className="relative mb-4 w-11/12">
                <input
                  autoComplete="off"
                  id="address1"
                  name="address1"
                  value={profile.address1 || ""}
                  type="text"
                  className={`${
                    showinput ? "border-b-2 border-gray-300 " : "border-0 "
                  } pt-2 text-gray-900 bg-transparent`}
                  readOnly={!showinput}
                  onChange={handleChange}
                  placeholder="House No /Street Name"
                />

                <label
                  htmlFor="address1"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  House No /Street Name
                </label>
              </div>
              <div className="relative mb-4 w-11/12">
                <input
                  autoComplete="off"
                  id="address2"
                  name="address2"
                  value={profile.address2 || ""}
                  type="text"
                  className={`${
                    showinput ? "border-b-2 border-gray-300 " : "border-0 "
                  } pt-2 text-gray-900 bg-transparent`}
                  readOnly={!showinput}
                  onChange={handleChange}
                  placeholder="Address"
                />
                <label
                  htmlFor="address2"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  Address
                </label>
              </div>
              <div className="relative mb-4 w-11/12">
                <input
                  autoComplete="off"
                  id="pinCode"
                  name="pinCode"
                  value={profile.pinCode || ""}
                  type="number"
                  className={`${
                    showinput ? "border-b-2 border-gray-300 " : "border-0 "
                  } pt-2 text-gray-900 bg-transparent`}
                  readOnly={!showinput}
                  onChange={handleChange}
                  placeholder="Zipcode"
                />
                <label
                  htmlFor="pinCode"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  Zipcode
                </label>
              </div>
              <div className="relative mb-4 w-11/12">
                <input
                  autoComplete="off"
                  id="city"
                  name="city"
                  type="text"
                  value={profile.city || ""}
                  className={`${
                    showinput ? "border-b-2 border-gray-300 " : "border-0 "
                  } pt-2 text-gray-900 bg-transparent`}
                  readOnly={!showinput}
                  placeholder="City"
                  onChange={handleChange}
                />
                <label
                  htmlFor="city"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  City
                </label>
              </div>

              <div className="relative mb-4 w-11/12">
                {/* <select
                        autoComplete="off"
                        id="gender"
                        name="gender"
                        className="pt-2 text-gray-900 bg-transparent"
                        value={profile.gender}
                        disabled={!showinput}
                        className={`${
                          showinput ? "border-b-2 border-gray-300 " : "border-0 "
                        } pt-2 bg-transparent text-gray-900`}
                        placeholder="Gender"
                        onChange={handleChange}
                      >
                        {Genderdata.map((cit, i) => (
                          <option value={cit.gender}> {cit.genderName}</option>
                        ))}
                      </select> */}

                <select
                  id="state"
                  name="state"
                  value={profile.state || ""}
                  disabled={!showinput}
                  className={`${
                    showinput ? "border-b-2 border-gray-300 " : "border-0 "
                  } mt-2 bg-transparent text-gray-900 w-11/12 -ml-1`}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {stateList.length &&
                    stateList.map((singleState, s) => (
                      <option key={s} value={singleState.code}>
                        {singleState.description}
                      </option>
                    ))}
                </select>

                <label
                  htmlFor="state"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                >
                  State
                </label>
              </div>
            </div>

            {/* {
                                <div className="flex justify-end">
                                    <button className="bg-white text-brand-secondary p-2 rounded-xl mr-2">Cancel</button>
                                    <button type="submit" className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2">Save Changes</button>
                                </div>
                            } */}
            {showinput && (
              <div className="flex justify-end">
                <button
                  onClick={onCancel}
                  className="bg-white text-brand-secondary p-2 rounded-xl mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={savechangesprofile}
                  className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
                >
                  Save Changes
                </button>
              </div>
            )}
          </div>
        </div>
        <Dialog
          header="Terms of use"
          visible={termsView}
          modal={true}
          style={{ width: "70vw" }}
          onHide={() => setTermsView(false)}
          footer={renderTermsFooter("displayModal")}
        >
          <div className="border border-gray-200 my-3 rounded-lg p-5 ">
            {consentVersionT && (
              <iframe
                src={`${
                  process.env.REACT_APP_IMG_BASEURL +
                  consentVersionT.consentFile
                }`}
                height="2100px"
                width="100%"
              />
            )}
          </div>
        </Dialog>
        <Dialog
          header="Privacy Policy"
          visible={privacyView}
          modal={true}
          style={{ width: "70vw" }}
          footer={renderPolicyFooter("displayModal")}
          onHide={() => setPrivacyView(false)}
        >
          <div className="border border-gray-200 my-3 rounded-lg p-5 ">
            {consentVersionP && (
              <iframe
                src={`${
                  process.env.REACT_APP_IMG_BASEURL +
                  consentVersionP.consentFile
                }`}
                height="2100px"
                width="100%"
              />
            )}
          </div>
        </Dialog>
        <Dialog
          header="Update User Consent"
          visible={showConsentView}
          modal={true}
          style={{ width: "30vw", height: "17vw" }}
          onHide={() => setShowConsentView(false)}
        >
          <>
            <div className="border border-gray-200 my-3 p-5">
              <p className="">
                <b>Terms of use and Privacy Policy</b>
              </p>
              <div className="flex cursor-pointer mt-4">
                <div className=" flex space-x-2">
                  <input
                    type="checkbox"
                    onChange={(e) => setTermsView(true)}
                    class="form-radio"
                    name="accountType"
                    value="personal"
                    checked={termsAccept}
                  />
                  <div className="text-xs font-medium  font-montserrat">
                    <p
                      className="text-brand-primary font-medium text-sm cursor-pointer"
                      onClick={() => setTermsView(true)}
                    >
                      <u>Terms and conditions</u>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex cursor-pointer mt-4">
                <div className=" flex space-x-2">
                  <input
                    type="checkbox"
                    onChange={(e) => setPrivacyView(true)}
                    class="form-radio"
                    name="accountType"
                    value="personal"
                    checked={policyAccept}
                  />
                  <div className="text-xs font-medium  font-montserrat">
                    <p
                      className="text-brand-primary font-medium text-sm cursor-pointer"
                      onClick={(e) => setPrivacyView(true)}
                    >
                      <u>Privacy policy</u>
                    </p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => disAgreeConfirm()}
                className={`p-2 bg-brand-secondary mt-5 flex-end align-end justify-end  text-white w-48  rounded-lg text-center text-base font-normal}`}
              >
                Update
              </button>
            </div>
          </>
        </Dialog>
        <Dialog
          header="Confirmation"
          visible={logoutConfirm}
          modal={true}
          style={{ width: "30vw", height: "16vw" }}
          onHide={() => setLogoutConfirm(false)}
        >
          <>
            <div className="border border-gray-200 my-3 p-5">
              <p className="">
                <b>Are you sure want to disagree the consent?</b>
                <p className="mt-4">
                  Note : if u disagree the consent, the app will logout
                  automatically!
                </p>
              </p>
              <div className="flex justify-end">
                <button
                  onClick={() => setLogoutConfirm(false)}
                  className={`p-2 w-28 bg-brand-secondary mt-5 flex-end align-end justify-end  text-white  rounded-lg text-center text-base font-normal}`}
                >
                  No
                </button>
                <button
                  onClick={() => onConsentAgree()}
                  className={`p-2 w-28 bg-brand-secondary mt-5 ml-5 flex-end align-end justify-end  text-white  rounded-lg text-center text-base font-normal}`}
                >
                  Yes
                </button>
              </div>
            </div>
          </>
        </Dialog>

        {/* {showOTP && (
              <OTPDetails
                profile={profile}
                otpSuccess={otpSuccess}
                cancelCallback={otpCancel}
              ></OTPDetails>
            )} */}
      </div>
    </>
  );

  // const history = useHistory();
  // const dispatch = useDispatch();
  // const particulardoct = useSelector((state) => state.particulardoctor);
  // const { particulardoctorData } = particulardoct;

  // const loggedInData = useSelector((state) => state.authReducer);
  // const { userData } = loggedInData;

  // const redirectTo = (event, location) => {
  //     event.preventDefault();
  //     history.push(location)
  // }

  // useEffect(() => {

  //     console.log(particulardoctorData);
  //     dispatch(getparticulardoctordetails(userData.code))
  // }, []);

  // const [showinput, setshowinput] = useState(false);

  // return (
  //     <>
  //         <div className="lg:block hidden">
  //             <ProfileNamebar></ProfileNamebar>
  //         </div>
  //         <div className="flex lg:space-x-4 ">
  //             <div className="lg:block hidden w-1/4 ml-10 ">
  //                 <ProfileSidebar></ProfileSidebar>
  //             </div>

  //             {particulardoctorData.slice(0, 1).map((user, i) => (
  //                 <div className="lg:w-3/4 mt-3 p-2 ">
  //                     <div className="lg:rounded-lg bg-white-600 w-full p-5 mb-2 antialiased justify-between lg:border lg:border-gray-200">
  //                         <div className="flex justify-end">
  //                             <p className="text-xs text-brand-primary font-medium font-rubik">Request Edit</p>
  //                         </div>
  //                         <div className="flex justify-between">
  //                             <div className="flex space-x-5 ">
  //                                 <div className="relative">
  //                                     <img src={profile} alt="profile" className="w-28" />
  //                                 </div>

  //                                 <div className="pt-2">
  //                                     <p className="text-xl text-gray-700 font-medium font-rubik">{userData.firstName} {userData.lastName}</p>
  //                                     <p className="text-xs text-gray-700 font-normal font-montserrat pt-1">Medical Registration no. : {user.mciverification} </p>
  //                                     <div className="lg:flex hidden space-x-4">
  //                                         <p className="text-xs text-gray-700 font-normal font-montserrat pt-1">Qualification : {user.qualification} </p>
  //                                         <p className="text-xs text-gray-700 font-normal font-montserrat pt-1">Speciality : {user.speciality}</p>
  //                                         <p className="text-xs text-gray-700 font-normal font-montserrat pt-1">Hospitals / Clinics : {user.userHospitalLocationlist}</p>
  //                                     </div>
  //                                 </div>
  //                             </div>

  //                         </div>
  //                         <div className="lg:hidden flex justify-between grid grid-cols-2">
  //                             <p className="text-sm text-gray-400 font-medium font-montserrat pt-1">Qualification : {user.qualification} </p>
  //                             <p className="text-sm text-gray-400 font-medium font-montserrat pt-1">Speciality : {user.speciality}</p>
  //                             <p className="text-sm text-gray-400 font-medium font-montserrat pt-1">Hospitals / Clinics : {user.userHospitalLocationlist}</p>
  //                         </div>
  //                         <div className="pt-8 ">
  //                             <p className="text-sm text-gray-700 font-medium font-rubik">Personal Details </p>
  //                             <div className="lg:flex lg:space-x-10 ">
  //                                 <div className="mb-2">
  //                                     <label className="block text-gray-600 text-sm font-medium " for="username">
  //                                         Mobile No
  //                                     </label>
  //                                     <span className="pt-1  text-xs bg-transparent" >{user.mobile ? user.mobile : 'NA'}</span>
  //                                 </div>
  //                                 <div className="mb-2">
  //                                     <label className="block text-gray-600 text-sm font-medium" for="username">
  //                                         Email
  //                                     </label>
  //                                     <span className="pt-1 bg-transparent  text-xs">{user.email}</span>

  //                                 </div>

  //                                 <div className="mb-2">
  //                                     <label className="block text-gray-600 text-sm font-medium" for="username">
  //                                         Date of Birth
  //                                     </label>
  //                                     <span className="pt-1 bg-transparent text-xs"  >{user.dob}</span>

  //                                 </div>

  //                                 <div className="mb-2">
  //                                     <label className="block text-gray-600 text-sm font-medium" for="username">
  //                                         Gender
  //                                     </label>
  //                                     <span className="pt-1 bg-transparent text-xs" >{user.gender}</span>
  //                                 </div>
  //                             </div>
  //                         </div>

  //                         <div className="pt-4">
  //                             <p className="text-sm text-gray-700 font-medium font-rubik">About Me</p>

  //                             <p className="text-xs text-gray-600 pt-6 font-rubik">
  //                                 {user.aboutMe}
  //                             </p>

  //                         </div>

  //                     </div>

  //                 </div>
  //             ))}
  //         </div>

  //     </>
  // );
}
export default DoctorProfile;
