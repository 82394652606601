/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useState } from "react";
import LabServices from "../Redux/services/labServices" ;
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { DICOM_URL } from "../config/constant";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const  MobileLabReport = (props) => { 
  const [labReportList, setLabReport] = useState([]);
  const [diagnositcDs, setdiagnositcDs] = useState([]);
  const [patientDs, setPatientDs] = useState([]);
  const [diagnositcLocationDs, setdiagnositcLocationDs] = useState([]);
  const [filterPopup, setFilterPopup] = useState(false);
  const [filePopup, setfilePopup] = useState(false);
  const [dicomPopup, setdicomPopup] = useState(false);
  const [dicomURL, setdicomURL] = useState("");
  const [dicomToken, setDicomToken] = useState("");
  const [labObj, setLabObj] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    const payload = {
      patientId: localStorage.getItem("patientprofile"),
      docmentRequired: 'Y' 
    };
    loadLabOrders(payload);
    loadDiagnosticCenter();
    loadPatient();
    getDicomToken();
  }, []);

  const getDicomToken = () => {
    console.log("dicom enter");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: "integration",
        password: "integration",
      }),
    };
    fetch(DICOM_URL + "authenticate", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setDicomToken(data.token);
      });
  };

  const viewDicomFile = (payload) => {
    console.log("dicom file");
    const requestOptions = {
      method: "POST",
      headers: { Authorization: "Bearer " + dicomToken },
      // body: JSON.stringify({
      //   username: 'integration',
      //   password: 'integration'
      // })
    };
    fetch(DICOM_URL + "viewer/" + payload, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setdicomURL(data.details);
        setdicomPopup(true);
        // setDicomToken(data.token);
      });
  };

  const loadPatient = () => {
    const payload = {
      status: 1,
    };
    LabServices.getPatientList(payload).then(
      (res) => {
        console.log(res);
        if (res.data) {
          setPatientDs(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const loadDiagnosticCenter = () => {
    const payload = {
      status: 1,
      type: "D",
    };
    LabServices.getDiagnosticCenter(payload).then(
      (res) => {
        console.log(res);
        if (res.data) {
          setdiagnositcDs(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const loadLabOrders = (payload) => {
    LabServices.getLabOrderDetails(payload).then(
      (res) => {
        console.log(res);
        setFilterPopup(false);
        if (res.data) {
          setLabReport(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getDiagnosticLocation = () => {
    const payload = {
      hospitalCode: labObj.hospitalId,
      status: 1,
    };
    LabServices.getDiagnosticLocation(payload).then(
      (res) => {
        console.log(res);
        if (res.data) {
          setdiagnositcLocationDs(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    setLabObj({ ...labObj, [e.target.name]: e.target.value });
    if (e.target.name == "hospitalId") {
      getDiagnosticLocation();
    }
  };

  const openFilter = (e) => {
    console.log(e);
    setFilterPopup(true);
    console.log(diagnositcDs);
  };

  const okFilter = (e) => {
    console.log("Filter Click");
    let payload = {};
    if (fromDate) {
      payload.fromDate = fromDate;
    }
    if (toDate) {
      payload.toDate = toDate;
    }
    if (labObj.patientId) {
      payload.patientId = labObj.patientId;
    }
    if (labObj.orderId) {
      payload.orderId = labObj.orderId;
    }
    if (labObj.hospitalId) {
      payload.hospitalId = labObj.hospitalId;
    }
    if (labObj.locationId) {
      payload.locationId = labObj.locationId;
    }

    payload.docmentRequired = 'Y';
    console.log(payload);
    loadLabOrders(payload);
  };

  const onHide = (name) => {
    console.log(name);
    setFilterPopup(false);
  };

  const onCancelDocument = (name) => {
    console.log(name);
    setfilePopup(false);
  };

  const changeFromDate = (e) => {
    setFromDate(moment(e).format("MM/DD/yyyy"));
  };

  const changeToDate = (e) => {
    setToDate(moment(e).format("MM/DD/yyyy"));
  };

  const viewOrder = (e) => {
    console.log(e);
    setSelectedRow(e);
    e.type =
      e.type.toLowerCase() === "pdf"
        ? "application/pdf"
        : e.type.toLowerCase() === "jpg"
        ? "image/jpeg"
        : e.type.toLowerCase() === "jpeg"
        ? "image/jpeg"
        : e.type.toLowerCase() === "png"
        ? "image/png"
        : e.type;
    if (e.type.toLowerCase() == "dicom") {
      viewDicomFile(e.dicomId);
    } else {
      setfilePopup(true);
    }
  };



  const renderFooter = (name) => {
    return (
      <div>
        <button
          onClick={() => onHide(name)}
          className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
        >
          Cancel
        </button>

        <button
          onClick={() => okFilter(name)}
          className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
        >
          Ok
        </button>
      </div>
    );
  };


  const renderDocumentFooter = (name) => {
    return (
      <div>
        <button
          onClick={() => onCancelDocument(name)}
          className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
        >
          Cancel
        </button>
      </div>
    );
  };
  const onCancelDicom = (name) => {
    console.log(name);
    setdicomPopup(false);
  };

  const renderDicomFooter = (name) => {
    return (
      <div>
        <button
          onClick={() => onCancelDicom(name)}
          className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2"
        >
          Cancel
        </button>
      </div>
    );
  };

  const iframeStyle = {border:'none', width: '95vw',height: '75vh'};

  return (
    <>
      <div className="flex justify-between w-full">
      

        <div className="w-full lg:mr-12 mt-2 ">
          <div className="flex flex-col">
            <div className="-my-2 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                <div class="flex justify-between mt-0 my-3">
                  <p class="text-medium font-medium text-2xl font-rubik ">
                    My Lab Report
                  </p>
                  <p
                    onClick={(e) => openFilter(e)}
                    class="text-sm cursor-pointer text-blue-900 font-medium pr-4"
                  >
                    {" "}
                    Filter
                  </p>
                </div>

                <div class="w-full h-112 lg:mb-2 mb-16 antialiased justify-between">
                  {labReportList.length === 0 ? (
                    <p className="text-center item-center mt-40 mb-40  ">
                      No Order available
                    </p>
                  ) : (
                    <>      
                <div className=" mt-3 space-y-4 mb-20 md:flex lg:block md:flex-wrap justify-between">
                {labReportList.map((lab , i) =>(
                     <div className=" w-full border-solid border-2 rounded-2xl relative p-4 font-rubik mb-4" onClick={() => viewOrder(lab)}>
                     <div className="flex ">
                         <div className="text-left mr-2 w-2/4" >
                             <div>
                                 <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Order ID</span>
                                 <p style ={{overflowWrap: 'break-word'}} className="text-sm font-montserrat text-base">{lab.orderId}</p>
                             </div>
                             <div>
                                 <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Date</span>
                                 <p className="text-sm  font-montserrat text-base">{moment(lab.createdDate).format('DD/MM/yyyy HH:mm:ss')}</p>
                             </div>
                             <div>
                                 <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Diagnostics</span>
                                 <p className="text-sm  font-montserrat text-base">{lab.hospitalName}</p>
                             </div>

                         </div>
                         <div className="text-left w-2/4" >
                             <div>
                                 <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base"> Diagnostics Location</span>
                                 <p className="text-sm font-montserrat text-base">{lab.locationName}</p>
                             </div>
                             <div>
                                 <span className="text-sm opacity-60 text-brand-manatee font-rubik text-base">Document Title</span>
                                 <p className="text-sm font-montserrat text-base">{lab.type}</p>
                             </div>
                         </div>
                     </div>
                 </div>
                ))}
                                 
                                        </div>
                    </>

                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {filterPopup && (
        <Dialog
          header="Filter"
          visible={filterPopup}
          modal={false}
          style={{ width: "90vw" }}
          footer={renderFooter("displayModal")}
          onHide={() => onHide("displayModal")}
        >
          <p className="p-m-0">
            <div class="lg:flex pt-2 ">
              <div className="lg:full">
                <div class="lg:flex lg:pt-1 mt-5 lg:space-x-10 ">

                <div class="relative w-full mb-5">
                    <label
                      for="orderId"
                      class="left-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Patient
                    </label>
                    <Dropdown
                      value={labObj.patientId}
                      options={patientDs}
                      onChange={handleChange}
                      optionLabel="name"
                      optionValue="code"
                      className="w-full"
                      filter
                      showClear
                      id="patientId"
                      name="patientId"
                      filterBy="name"
                      placeholder="Select Patient"
                    />
                  </div>

                  <div class="relative w-full mb-5">
                    <label
                      for="fromDate"
                      class="left-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      From Date
                    </label>

                    <DatePicker
                      id="fromDate"
                      name="fromDate"
                      dropdownMode="select"
                      showMonthDropdown
                      showYearDropdown
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      value={fromDate ? moment(fromDate).format("DD/MM/yyyy"): ""}
                      onSelect={changeFromDate}
                      disabledKeyboardNavigation={true}
                      autoFocus={false}
                      placeholderText="From Date"
                      className={
                        "peer -mx-1 w-full  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                      }
                    />
                  </div>

                  <div class="relative w-full mb-5">
                    <label
                      for="toDate"
                      class="left-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      To Date
                    </label>

                    <DatePicker
                      id="toDate"
                      name="toDate"
                      dropdownMode="select"
                      showMonthDropdown
                      showYearDropdown
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      value={toDate ? moment(toDate).format("DD/MM/yyyy") : ""}
                      onSelect={changeToDate}
                      disabledKeyboardNavigation={true}
                      autoFocus={false}
                      placeholderText="To Date"
                      className={
                        "peer -mx-1  h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                      }
                    />
                  </div>

                 
                </div>

                <div class="lg:flex lg:pt-1 mt-5 lg:space-x-10 ">
                <div class="relative w-full mb-5">
                    <label
                      for="orderId"
                      class="left-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Order ID
                    </label>
                    <input
                      autocomplete="off"
                      id="orderId"
                      name="orderId"
                      type="text"
                      value={labObj.orderId}
                      className={
                        "peer -mx-1 w-full h-10  border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                      }
                      placeholder="Order ID"
                      onChange={handleChange}
                    />
                  </div>

                  <div class="relative w-full mb-5">
                    <label
                      for="relation"
                      class="left-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Diagnosic Center
                    </label> <br></br>
                    <Dropdown
                      value={labObj.hospitalId}
                      options={diagnositcDs}
                      onChange={handleChange}
                      optionLabel="name"
                      optionValue="code"
                      className="w-full"
                      filter
                      showClear
                      id="hospitalId"
                      name="hospitalId"
                      filterBy="name"
                      placeholder="Select Diagnosic Center"
                    />
                  </div>

                  <div class="relative w-full mb-5">
                    <label
                      for="locationId"
                      class="left-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Location
                    </label> <br></br>
                    <Dropdown
                      value={labObj.locationId}
                      options={diagnositcLocationDs}
                      onChange={handleChange}
                      optionLabel="name"
                      optionValue="code"
                      filter
                      showClear
                      id="locationId"
                      name="locationId"
                      className="w-full"
                      filterBy="name"
                      placeholder="Select Location"
                    />
                  </div>
                </div>
              </div>
            </div>
          </p>
        </Dialog>
      )}

    {filePopup && (
        <Dialog
          header="Document"
          visible={filePopup}
          modal={false}
          style={{ width: "98vw", height: '100vh' }}
          footer={renderDocumentFooter("displayModal")}
          onHide={() => onCancelDocument("displayModal")}
        >
          <p className="p-m-0">
            <div class="lg:flex pt-2 ">
              <div className="lg:w-6/6">
                <div class="lg:flex lg:pt-1 mt-5 lg:space-x-10 ">
                  <embed class="box target" width="1000" height="375"  src={process.env.REACT_APP_IMG_BASEURL + selectedRow.photoName}  type={selectedRow.type} />
                </div>
              </div>
            </div>
          </p>
        </Dialog>
      )}

{dicomPopup && (
        <Dialog
          header="Dicom Viewer"
          visible={dicomPopup}
          modal={false}
          style={{ width: "98vw", height: '100vh' }}
          footer={renderDicomFooter("displayModal")}
          onHide={() => onCancelDicom("displayModal")}
        >
          <p className="p-m-0">
            <div class="lg:flex pt-2 ">
              <div className="lg:w-6/6">
                <div class="lg:flex lg:pt-1 mt-5 lg:space-x-10 ">
                  <iframe
                    src={dicomURL}
                    title="Dicom Viewer"
                    style={iframeStyle}
                  ></iframe>
                </div>
              </div>
            </div>
          </p>
        </Dialog>
      )}
 
    </>
  );
}
export default MobileLabReport;
