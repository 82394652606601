

import vijay from '../Assets/Images/vijy.svg';
import prescription from '../Assets/Images/prescip.svg';
import React, { useState, useEffect } from 'react'
import Call from './userVideoCall';
import { useHistory, useLocation } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { getparticulardoctordetails } from '../Redux/Actions/doctAction';


function DoctorDetails() {

const location = useLocation();
const { state } = location;




    return (
        <>

            <div className="p-10 grid  gap-4">
                {/* card 1 */}
                <Call {...state} />
            </div>







            <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5">
                {/* <!--Card 1--> */}
                <div className="rounded overflow-hidden shadow-sm border border-gray-200 p-4">
                    <div className="flex justify-between">
                        <div className="mb-4">
                            <label className="block text-gray-600 text-sm font-bold mb-2" for="number">
                                Primary Diagnosis
                            </label>
                            <input className=" appearance-none border border-gray-600 rounded w-96 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" id="number" type="text" />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-600 text-sm font-bold mb-2" for="id">
                                ICD code
                            </label>
                            <input className=" appearance-none border border-gray-600 rounded w-32 py-2 px-3  leading-tight focus:outline-none focus:shadow-outline" id="id" type="text" />
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <div className="mb-4">
                            <label className="block text-gray-600 text-sm font-bold mb-2" for="number">
                                Secondary Diagnosis
                            </label>
                            <input className=" appearance-none border border-gray-600 rounded w-96 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" id="number" type="text" />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-600 text-sm font-bold mb-2" for="id">
                                ICD code
                            </label>
                            <input className=" appearance-none border border-gray-600 rounded w-32 py-2 px-3  leading-tight focus:outline-none focus:shadow-outline" id="id" type="text" />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-600 text-sm font-bold mb-2" for="name">
                            Recommendations
                        </label>
                        <input className=" appearance-none border border-gray-600 rounded w-full h-32 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-600 text-sm font-bold mb-2" for="name">
                            General Notes
                        </label>
                        <input className=" appearance-none border border-gray-600 rounded w-full h-32 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" />
                    </div>
                </div>





                {/* <!--Card 2--> */}
                <div className="overflow-hidden ">
                    <div className="flex justify-between pt-3 shadow-sm rounded-lg border border-gray-200 p-4">
                        <div className="flex">
                            <div>
                                <img src={prescription} alt="prescription" className="w-10" />
                            </div>
                            <div className="pl-3">
                                <p className="text-sm text-gray-700 font-medium">Medicine Prescription - Vijay Sharma</p>
                                <p className="text-xs font-normal text-gray-500 pt-1">Add an E-prescription for Medicines</p>
                            </div>
                        </div>

                        <div>
                            <button className="bg-brand-secondary  text-white font-normal py-2 px-4 rounded text-xs">Add Prescription</button>
                        </div>
                    </div>
                    <div className="flex justify-between pt-3 shadow-sm rounded-lg border border-gray-200 p-4 mt-5">
                        <div className="flex">
                            <div>
                                <img src={prescription} alt="prescription" className="w-10" />
                            </div>
                            <div className="pl-3">
                                <p className="text-sm text-gray-700 font-medium">Medicine Prescription - Vijay Sharma</p>
                                <p className="text-xs font-normal text-gray-500 pt-1">Add an E-prescription for Medicines</p>
                            </div>
                        </div>

                        <div>
                            <button className="bg-brand-secondary  text-white font-normal py-2 px-4 rounded text-xs">Add Prescription</button>
                        </div>
                    </div>

                    <button className="bg-brand-secondary mt-56 float-right text-white font-normal py-2 px-4 rounded text-xs">Publish consultation summary</button>
                </div>
            </div>



        </>
    );
}
export default DoctorDetails;