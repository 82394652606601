export const REFER = {
    REQUEST_REFERTO: "REQUEST_REFERTO",
    SUCCESS_REFERTO: "SUCCESS_REFERTO",
    FAILED_REFERTO: "FAILED_REFERTO",

    REQUEST_REFERTODOCTOR: "REQUEST_REFERTODOCTOR",
    SUCCESS_REFERTODOCTOR: "SUCCESS_REFERTODOCTOR",
    FAILED_REFERTODOCTOR: "FAILED_REFERTODOCTOR",

    REQUEST_REFERFROM: "REQUEST_REFERFROM",
    SUCCESS_REFERFROM: "SUCCESS_REFERFROM",
    FAILED_REFERFROM: "FAILED_REFERFROM",

    REQUEST_REFERCLINICLIST: "REQUEST_REFERCLINICLIST",
    SUCCESS_REFERCLINICLIST: "SUCCESS_REFERCLINICLIST",
    FAILED_REFERCLINICLIST: "FAILED_REFERCLINICLIST",

    REQUEST_REFERDOCTORLIST: "REQUEST_REFERDOCTORLIST",
    SUCCESS_REFERDOCTORLIST: "SUCCESS_REFERDOCTORLIST",
    FAILED_REFERDOCTORLIST: "FAILED_REFERDOCTORLIST",

    REQUEST_REFERPATIENTLIST: "REQUEST_REFERPATIENTLIST",
    SUCCESS_REFERPATIENTLIST: "SUCCESS_REFERPATIENTLIST",
    FAILED_REFERPATIENTLIST: "FAILED_REFERPATIENTLIST",

}