import { PAT } from "../Constants/patientConstants";
import { USER } from "../Constants/userprofileConstants";
import { VITALS } from "../Constants/vitalConstants";


const initialState = {
    referredtoData: [],
    isError: false,
    isLoading: false,
    msg: '',
    errMsg: ''
};


const DoctorReferredtoReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER.REQUEST_DOCTREFEREDTOLIST:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case USER.SUCCESS_DOCTREFEREDTOLIST:
            return {
                ...state,
                isLoading: false,
                referredtoData : payload,
                isError: false,              
            };
        case USER.FAILED_DOCTREFEREDTOLIST:
            return {
                ...state,
                isLoading: false,
                errMsg: payload,
                isError: true
            };
        default:
            return state;
    }
};

export default DoctorReferredtoReducer;