import profile from '../Assets/Images/profilee.svg';
import camera from '../Assets/Images/camera.svg';
import React, { Component, useState, useEffect } from 'react'
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { getparticulardoctordetails } from '../Redux/Actions/doctAction';
import { editProfileDetails } from '../Redux/Actions/doctAction';
import { sendNotification } from '../Redux/Actions/doctAction';
import ProfileSidebar from './profileSidebar';
import { APP_ROUTES } from '../application/Router/constants/AppRoutes';
import { Link, useHistory, } from 'react-router-dom';
import { encodeBase64File } from "../helper/filebase64";
import DatePicker from "react-datepicker";
import ProfileNamebar from './profileNamebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userprofileservice from '../Redux/services/userprofileservice';
import AddMapSepciality from './AddMapSpeciality';
import FilterMapSpeciality from './FilterMapSpeciality';
import MobileMapSpeciality from './mobileMapSpeciality';


function MapSpeciality() {

  const [showAddMapSpeciality, setShowAddMapSpeciality] = useState(false);
  const [showEditMapSpeciality, setShowEditMapSpeciality] = useState(false);
  const [showFilterMapSpeciality, setShowFilterMapSpeciality] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [mappedSpecialityList, setMappedSpecialityList] = useState([]);
  const [editMapSpeciality, setEditMapSpeciality] = useState();

  useEffect(() => {
    loadMappedSpecialities();
  }, []);




  const loadMappedSpecialities = () => {
    let userObj = JSON.parse(localStorage.getItem('userObj'));
    console.log(userObj.code);
    const payload = {
      userId: userObj.code,
      status: 1
    }

    userprofileservice.getMappedSpecialities(payload).then((res) => {
      if (res.data) {
        setMappedSpecialityList(res.data);
        console.log('Speciality List ---> ' + JSON.stringify(res.data));
      }
      setIsLoading(false);
    }, (err) => {
      setIsLoading(false);
      console.log(err);
    })
  }

  const loadFilterMapSpeciality = (data) => {
    setIsLoading(true);
    console.log(JSON.stringify(data));
    setShowFilterMapSpeciality(false);
    let userObj = JSON.parse(localStorage.getItem('userObj'));
    let payload = {
        userId: userObj.code
    };
    if(data.specialityCode) {
        payload.specialityCode = data.specialityCode;
    }
    if(data.symptoms) {
        payload.symptoms = data.symptoms;
    }
    if(!data.status) {
        payload.status = 1;
    }
    console.log(JSON.stringify(payload));
    userprofileservice.getMappedSpecialities(payload).then((res) => {
        console.log(res);
        if (res.data) {
            setMappedSpecialityList(res.data);
            console.log(JSON.stringify(res.data));
        }
        setIsLoading(false);
    }, (err) => {
      setIsLoading(false);
        console.log(err);
    })
} 

  const openAddMapSpeciality = () => {
    setShowAddMapSpeciality(true);
  }

  const openEditMapSpeciality = () => {
    setShowEditMapSpeciality(true);
}

  const openFilterMapSpeciality = () => {
    setShowFilterMapSpeciality(true);
  }

  const selectedSpeciality = (e) => {
    console.log(e.target.value)
    mappedSpecialityList.forEach(element => {
      if(element.specialityCode == e.target.value) {
        console.log(element);
          setEditMapSpeciality(element);
          //setShowEditMapSpeciality(true);
      }
  });
  }




  return (
    <>
      <div className="lg:block hidden">
        <ProfileNamebar></ProfileNamebar>
      </div>

      {/* 2nd row */}
      <div className="lg:flex hidden justify-between ">
        <div className="lg:block hidden lg:w-1/4 lg:ml-8  ">

          <ProfileSidebar></ProfileSidebar>
        </div>
        {/* table start */}
        <div className="w-11/12 lg:ml-10 ml-4 mt-3 border boder-gray-200 rounded-lg bg-white mr-8">
          <div className="flex justify-between">
            <h1 className="text-medium font-medium text-2xl p-3 font-16 text-brand-secondary font-rubik">
              Mapped Speciality
            </h1>
            <div className="flex p-3 justify-end">
              <div className="theme-color menuitem cursor-pointer" onClick={openAddMapSpeciality}>
                <div className="font-14"><span className="icon-new font-14 cursor-pointer">New</span></div>
              </div>
              <div className="theme-color menuitem pl-5" onClick={openEditMapSpeciality}>
                <div className="font-14"><span className="icon-view font-14">View</span></div>
              </div>
              <div className="theme-color menuitem pl-5" onClick={openFilterMapSpeciality}>
                <div className="font-14"><span className="icon-filter font-14">Filter</span></div>
              </div>
            </div>

          </div>
          <div className="flex flex-col">
            <div className="lg:-my-2 overflow-x-auto sm:-mx-6 lg:-mx-0">
              <div className="lg:py-2 align-middle inline-block min-w-full sm:px-6 lg:px-0">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y ">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="py-3">
                          <input id="radio2" type="radio" name="radio" className="hidden" />
                        </th>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Speciality
                        </th>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Symptoms
                        </th>
                        <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {mappedSpecialityList.length === 0 ?  <td class="font-medium py-4 text-center text-gray-500" colspan="5">No Data found</td> :
                      mappedSpecialityList.map((res, i) => {
                        return (
                          <tr key={i} className="border-b border-gray-200 pt-">
                            <td className="py-2 px-2">
                              <input id="radio2" type="radio" name="radio" value={res.specialityCode} onChange={selectedSpeciality} />
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm break-words break-normal text-gray-500 font-montserrat">
                                {res.speciality}
                              </div>
                            </td>
                            <td className="px-6 py-2 break-all">
                              <div className="text-sm  text-gray-500 font-montserrat">
                                {res.specialitySymptoms}
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-500 font-montserrat">
                                {res.status == 1 ? 'Active' : 'Inactive'}
                              </div>
                            </td>
                          </tr>

                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {isLoading && (
                        <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                    )}
        </div>
      </div>
      <MobileMapSpeciality/>
      {showAddMapSpeciality && (
        <AddMapSepciality
          closePopup={() => {
            setShowAddMapSpeciality(false);
            loadMappedSpecialities();
          }}
          onClose={() => {
            setShowAddMapSpeciality(false);
          }}
        >
        </AddMapSepciality>
      )}
      {showEditMapSpeciality && editMapSpeciality && (
        <AddMapSepciality
          editData={editMapSpeciality}
          closePopup={() => {
            setShowEditMapSpeciality(false);
            loadMappedSpecialities();
          }}
          onClose={() => {
            setShowEditMapSpeciality(false);
          }}
        >
        </AddMapSepciality>
      )}
      {showFilterMapSpeciality && (
        <FilterMapSpeciality
          closePopup={() => {
            setShowFilterMapSpeciality(false);
            loadMappedSpecialities();
          }}
          filterData={loadFilterMapSpeciality}
          onClose={() => {
            setShowFilterMapSpeciality(false);
          }}
        >
        </FilterMapSpeciality>
      )}
    </>
  )


}
export default MapSpeciality;