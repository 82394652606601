import React from 'react';
import close from '../Assets/Images/closeee.svg';
import search from '../Assets/Images/search.svg';
import delet from '../Assets/Images/delete_black.svg';
import { SearchIcon } from '@heroicons/react/outline'

function DiagnopreStep3() {
    return (
      <>
      <div className="flex justify-center mt-5">
      <div className="rounded overflow-hidden shadow-sm border border-gray-200 p-5 w-1/3">
            
            <div className="flex justify-between">
            <p className="text-base font-medium text-gray-700">Diagnostics Prescription</p>
            <img src={close} alt="close" className="w-4" />
            </div>

            <hr className="my-3"/>

            <div className="bg-white shadow p-1 rounded-md flex">
              
            <SearchIcon color={'bg-brand-secondary'} className="w-5 ml-2" />
                <input className="w-full rounded p-2 font-montserrat text-sm font-medium" type="text" placeholder="Search for a Pathology or Radiology test"/>   
            </div>
            <div className="mt-3">
                <label className="inline-flex items-center">
                <input type="radio" className="form-radio" name="accountType" value="personal"/>
                <span className="ml-2 font-montserrat text-sm font-medium text-gray-600">Pathology </span>
                </label>
                <label className="inline-flex items-center ml-6">
                <input type="radio" className="form-radio" name="accountType" value="busines"/>
                <span className="ml-2 font-montserrat text-sm font-medium text-gray-600">Radiology</span>
                </label>
            </div>
            <p className="text-sm text-gray-400 pt-5">Test name</p>

            <div className="flex justify-between pt-4">
                <p className="text-sm text-gray-600 font-medium font-montserrat">CBC test</p>
               <img src={delet} alt="delete"/>
                </div>

            <button className="bg-brand-secondary mt-56 float-right text-white font-medium py-2 px-4 rounded text-sm">Save Changes</button>
        </div> 
            
      </div>
      </>
)
}
export default DiagnopreStep3;